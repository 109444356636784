import styled from 'styled-components';
import { SIDEBAR_WIDTH } from 'components/Sidebars/OrganizationSidebar/styles';
import { from } from 'styles/responsive';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const SidebarSpacer = styled.div`
  display: none;
  width: ${SIDEBAR_WIDTH};

  ${from.tabletLandscape`
    display: initial;
  `};
`;

export const Content = styled.div`
  flex: 1;

  ${from.tabletLandscape`
    box-sizing: content-box;
    flex-direction: column;
    margin: 0 auto;
    max-width: 84.5rem;
    padding: 0 2rem;
  `};
`;
