import styled from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey200};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
  padding: 1.0625rem 0 1.1875rem 0;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.date};
  overflow: hidden;
  white-space: pre-wrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
  line-height: 1.2;

  ${from.tabletLandscape`
    font-size: 1rem;
 `}
`;

export const EndSession = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.endSession};
  overflow: hidden;
  white-space: pre-wrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
  line-height: 1.2;

  ${from.tabletLandscape`
    font-size: 1rem;
 `}
`;
