import { useCallback, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import { organizationConfigVar } from 'apollo/reactive';
import usePartnerRequest from 'apollo/hooks/organization/usePartnerRequest';
import useModal from 'apollo/hooks/useModal';
import validateRemovedPartner from 'apollo/requests/validateRemovedPartner';
import usePartnerFormActions from 'apollo/hooks/organization/usePartnerFormActions';
import {
  ActivateTemporaryPartnerDto,
  PartnerUsage,
  UserGender,
} from 'apollo/graphql.types';
import { formatErrors } from 'utils/errors/formatErrors';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import type { Partner } from 'model/Partner';

const useConnect = () => {
  const { activateRfidReader } = useReactiveVar(organizationConfigVar);
  const navigate = useNavigate();
  const { requestId } = useParams<{ requestId: string }>();
  const { partnerRequest, getPartnerRequestLoading } =
    usePartnerRequest(requestId);
  const {
    activatePartnerRequest,
    removePartnerRequest,
    partnerFormConfigLoading,
  } = usePartnerFormActions();
  const { openPartners, openReactivatePartner } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedHostPartner, setSelectedHostPartner] =
    useState<Partner | null>(null);

  const initialValues = useMemo<ActivateTemporaryPartnerDto>(
    () => ({
      address: partnerRequest?.address || '',
      document: partnerRequest?.document || '',
      email: partnerRequest?.email || '',
      firstName: partnerRequest?.firstName || '',
      lastName: partnerRequest?.lastName || '',
      phoneNumber: partnerRequest?.phoneNumber || '',
      usage: partnerRequest?.usage || PartnerUsage.Playful,
      maxConsumeMonth: '' as unknown as number,
      memberNum: '',
      birthDate: partnerRequest?.birthDate || undefined,
      gender: partnerRequest?.gender || UserGender.Other,
      credits: 0,
      rfidCode: '',
      hostMemberNum: '',
    }),
    [partnerRequest],
  );

  const onSelectPartner = useCallback((hostPartner: Partner) => {
    if (hostPartner?.id) {
      setSelectedHostPartner(hostPartner);
    }
  }, []);

  const handleOnRemove = useCallback(async () => {
    try {
      if (partnerRequest) {
        await removePartnerRequest(partnerRequest.id);
        enqueueSnackbar('La solicitud se ha eliminado correctamente', {
          variant: 'success',
        });
        navigate('/organization/partners-requests');
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('temporaryPartner', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [partnerRequest, removePartnerRequest, enqueueSnackbar, navigate]);

  const handleSelectHostPartner = useCallback(() => {
    openPartners({
      onSelect: onSelectPartner,
    });
  }, [openPartners, onSelectPartner]);

  const handleSubmit = useCallback<
    FormikConfig<ActivateTemporaryPartnerDto>['onSubmit']
  >(
    async (data: ActivateTemporaryPartnerDto) => {
      if (partnerRequest) {
        try {
          const existsRemovedPartner = await validateRemovedPartner(
            partnerRequest.document,
          );

          if (existsRemovedPartner) {
            // @ts-ignore
            openReactivatePartner({ data });
            return;
          }

          if (selectedHostPartner) {
            const partnerId = await activatePartnerRequest(partnerRequest.id, {
              ...data,
              hostMemberNum: selectedHostPartner.memberNum,
            });

            enqueueSnackbar(
              `El socio ${data.firstName} ${data.lastName} ha sido creado correctamente`,
              { variant: 'success' },
            );

            setSelectedHostPartner(null);
            if (partnerId) {
              navigate(`/partners/${partnerId}`, { replace: true });
            }
          }
        } catch (e) {
          enqueueSnackbar(
            formatErrors('temporaryPartner', e.message, 'crear'),
            {
              variant: 'error',
              action: () => <NotifySnackbarErrorButton error={e} />,
            },
          );
        }
      }
    },
    [
      partnerRequest,
      selectedHostPartner,
      openReactivatePartner,
      activatePartnerRequest,
      enqueueSnackbar,
      navigate,
    ],
  );

  return {
    activateRfidReader,
    handleSubmit,
    isLoading: getPartnerRequestLoading || partnerFormConfigLoading,
    handleSelectHostPartner,
    initialValues,
    selectedHostPartner,
    handleOnRemove,
    partnerRequest,
  };
};

export default useConnect;
