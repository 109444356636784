import { useCallback, useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { subscriptionStateVar } from 'apollo/reactive/subscriptionState';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useQuotas from 'apollo/hooks/quota/useQuotas';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import RevertSnackbarButton from 'components/RevertSnackbarButton';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { DiscountType } from 'apollo/graphql.types';
import type { Discount } from 'model/Discount';
import type { Quota } from 'model/Quota';

const useConnect = () => {
  const { quotas, loading: quotasLoading } = useQuotas();
  const {
    assignPartnerQuota,
    cancelQuotaTransaction,
    loading: partnerActionsLoading,
  } = usePartnerActions();
  const {
    assignQuotaPayload,
    close,
    openManageCredits,
    openAssignDiscounts,
    openAssignQuota,
    openQuota,
    type,
  } = useModal();
  const subscription = useReactiveVar(subscriptionStateVar);
  const { partner, selectedDiscount, onRefetchPartner } =
    assignQuotaPayload || {};
  const [discount, setDiscount] = useState<Partial<Discount> | undefined>(
    selectedDiscount || undefined,
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (partner?.discounts && partner?.discounts?.length > 0) {
      const quotaDiscounts = partner.discounts.filter(
        (discount) => discount.type === DiscountType.Quota,
      );

      if (quotaDiscounts?.[0]) {
        setDiscount(quotaDiscounts[0]);
      }
    }
  }, [partner]);

  const partnerCredits = useMemo(() => partner?.credits || 0, [partner]);

  const handleClose = useCallback(() => {
    setDiscount(undefined);
    close();
  }, [close]);

  const handleRevertTransaction = useCallback(
    async (transactionId: string) => {
      try {
        await cancelQuotaTransaction(transactionId);

        if (onRefetchPartner) {
          onRefetchPartner();
        }

        closeSnackbar();
        enqueueSnackbar('Se ha cancelado la transacción', {
          variant: 'info',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('transaction', e.message, 'cancelar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [onRefetchPartner, cancelQuotaTransaction, closeSnackbar, enqueueSnackbar],
  );

  const handleAssignQuota = useCallback(
    async (quotaId: Quota['id']) => {
      if (partner?.id) {
        try {
          const id = await assignPartnerQuota({
            id: partner.id,
            quotaId,
            discountId: discount?.id,
          });

          if (!id) {
            throw new Error();
          }

          handleClose();
          if (onRefetchPartner) {
            onRefetchPartner();
          }
          enqueueSnackbar('La cuota se ha asignado correctamente', {
            variant: 'success',
            action: () => (
              <RevertSnackbarButton
                onClickBar={() => handleRevertTransaction(id)}
              />
            ),
          });
        } catch (e) {
          enqueueSnackbar(formatErrors('partner', e.message, 'actualizar'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      }
    },
    [
      onRefetchPartner,
      partner,
      assignPartnerQuota,
      discount,
      handleClose,
      enqueueSnackbar,
      handleRevertTransaction,
    ],
  );

  const handleSetDiscount = useCallback(async (discounts: Array<Discount>) => {
    setDiscount(discounts[0]);
  }, []);

  const handleAddDiscount = useCallback(() => {
    const checkedDiscountsIds = [];
    if (discount?.id) {
      checkedDiscountsIds.push(discount.id);
    }

    if (partner) {
      openAssignDiscounts({
        oneSelection: true,
        checkedDiscountsIds,
        onSubmit: handleSetDiscount,
        selectedDiscount: partner.discounts?.[0] || undefined,
        type: DiscountType.Quota,
        onReturn: () => {
          openAssignQuota({ partner, selectedDiscount: discount });
        },
      });
    }
  }, [
    discount,
    handleSetDiscount,
    openAssignDiscounts,
    openAssignQuota,
    partner,
  ]);

  const handleOpenCreditsModal = useCallback(async () => {
    if (partner) {
      openManageCredits({
        action: 'added',
        partner,
        allowCloseModal: false,
        onReturn: () => {
          if (onRefetchPartner) {
            onRefetchPartner();
          }
          openAssignQuota({ partner });
        },
      });
    }
  }, [onRefetchPartner, openManageCredits, openAssignQuota, partner]);

  const handleOpenQuotaModalModal = useCallback(async () => {
    if (partner) {
      openQuota({
        onReturn: () => openAssignQuota({ partner }),
      });
    }
  }, [openAssignQuota, openQuota, partner]);

  return {
    discount,
    handleAddDiscount,
    handleAssignQuota,
    handleClose,
    handleOpenCreditsModal,
    handleOpenQuotaModalModal,
    isLoading: quotasLoading || partnerActionsLoading,
    isOpen: type === ModalType.ASSIGN_QUOTA,
    partnerCredits,
    quotas,
    subscription,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
