import styled from 'styled-components';
import DefaultTextField from '@mui/material/TextField';
import DefaultSearchIcon from '@mui/icons-material/Search';

export const SearchIcon = styled(DefaultSearchIcon)``;

export const TextField = styled(DefaultTextField)`
  .MuiInputBase-root {
    background: #ffffff !important;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;
    outline: none;
    transition: border 0.2s ease;
    &.Mui-focused {
      border: ${({ theme }) => `1px solid ${theme.colors.accent}`};
      ${SearchIcon} {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    display: none;
  }
`;
