import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';

export const Container = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  ${from.tabletPortrait`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  `};
`;

export const SectionName = styled.h4`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 14px;
  text-transform: capitalize;
  transition: color 200ms ease;
`;

export const Section = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  padding: 0.625rem 1.8rem;
  transition: all 200ms ease-out;
  margin-bottom: 0.5rem;

  ${from.tabletPortrait`
    margin-bottom: 0;
  `};

  ${({ theme, $isSelected }) =>
    $isSelected
      ? css`
          border-color: ${theme.colors.accent};
          cursor: unset;

          ${SectionName} {
            color: ${theme.colors.accent};
          }
        `
      : css`
          &:hover {
            border-color: ${({ theme }) => theme.colors.grey900};
            ${SectionName} {
              color: ${({ theme }) => theme.colors.primary};
            }
          }
        `}
`;
