import styled, { css } from 'styled-components';
import DefaultCameraIcon from 'components/Icon/Icons/Camera';
import DefaultCloseIcon from 'components/Icon/Icons/Close';
import { IconBoxProps, ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px dashed ${({ theme }) => theme.colors.grey400};
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  transition: background-color 200ms ease;
  width: 100%;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.blue700};
    outline: none;
  }

  ${({ isDragActive, isDragReject, theme }) =>
    isDragActive &&
    css`
      background-color: ${isDragReject
        ? theme.colors.danger
        : theme.colors.grey400};
    `}
`;

export const IconBox = styled.div<IconBoxProps>`
  color: ${({ theme }) => theme.colors.blue900};
  margin-bottom: 0.6rem;
`;

export const CameraIcon = styled(DefaultCameraIcon).attrs(() => ({
  size: 22,
}))``;

export const InvalidIcon = styled(DefaultCloseIcon).attrs(() => ({
  size: 22,
}))`
  background: ${({ theme }) => theme.colors.blue900};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  padding: 0.25rem;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.875rem;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const ActiveText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;
