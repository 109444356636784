import { FC, memo } from 'react';

import type { Props } from './types';

const OrdersBig: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      transform="translate(3 1)"
      stroke="currentcolor"
      fill="none"
      fillRule="evenodd"
    >
      <rect x=".5" y="3.5" width="17" height="17" rx="1.5" />
      <path d="M11.5.5a.997.997 0 011 1h0v2h-7v-2a.997.997 0 011-1h0z" />
    </g>
  </svg>
);

export default memo(OrdersBig);
