import { FC, memo } from 'react';
import { formatDateToTableExtended } from 'utils/dates/formatDates';
import { Container, CreationDate, LastUpdateDate, Name } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, document }) => {
  return (
    <Container
      to={`/organization/documents/${document.id}`}
      className={className}
    >
      <Name>{document.name}</Name>
      <CreationDate>
        {formatDateToTableExtended(document.createdAt)}
      </CreationDate>
      <LastUpdateDate>
        {formatDateToTableExtended(document.updatedAt)}
      </LastUpdateDate>
    </Container>
  );
};

export default memo(Row);
