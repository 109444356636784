import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './Image';
import {
  ORGANIZATION_CONFIG_FRAGMENT,
  ORGANIZATION_SUBSCRIPTION_FRAGMENT,
} from './Organization';
import { PERMISSIONS_GROUP_FRAGMENT } from './PermissionsGroup';

export const USER_FRAGMENT = gql`
  fragment UserData on User {
    address
    avatar {
      ...ImageData
    }
    birthDate
    city
    country
    createdAt
    document
    email
    firstName
    gender
    id
    lastName
    phoneNumber
    role
    zipCode
    employee {
      ... on Employee {
        permissions {
          ...PermissionsGroupData
        }
      }
    }
    organization {
      ... on Organization {
        config {
          ...OrganizationConfigData
        }
        subscription {
          ...OrganizationSubscriptionData
        }
        document
        id
        isActive
        isOrdersActive
        name
        state
        email
        zipCode
        locality
        city
        address
        hasDriveIntegration
        logo {
          ...ImageData
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${ORGANIZATION_CONFIG_FRAGMENT}
  ${ORGANIZATION_SUBSCRIPTION_FRAGMENT}
  ${PERMISSIONS_GROUP_FRAGMENT}
`;
