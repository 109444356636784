import styled from 'styled-components';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultOrdersIcon from 'components/Icon/Icons/Orders';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import { from } from 'styles/responsive';

export const Container = styled(DefaultModal)`
  ${Content} {
    ${from.tabletPortrait`
      max-width: 21.5rem;
    `}
  }
`;

export const OrdersIcon = styled(DefaultOrdersIcon)``;

export const Header = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  padding: 0 0 2.5rem 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
`;

export const IconCircle = styled(DefaultIconCircle)`
  margin-right: 1rem;
`;

export const Label = styled.span`
  font-family: 'Inter';
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.primary};
`;

export const MenuButton = styled.button`
  cursor: pointer;
  padding: 1rem 0;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  &:hover {
    ${Label} {
      color: ${({ theme }) => theme.colors.accent};
      transition: color 200ms ease;
    }
  }
`;

export const ChevronRightIcon = styled(DefaultArrowRightIcon).attrs(() => ({
  size: 6.4,
}))`
  color: ${({ theme }) => theme.colors.grey500};
  margin-left: auto;
`;

export const Button = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    margin-top: 1rem;
  }
`;
