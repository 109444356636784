import { useCallback } from 'react';
import useModal from 'apollo/hooks/useModal';
import type { Partner } from 'model/Partner';

const useConnect = () => {
  const { openPartnerDetails } = useModal();

  const handleOpenPartnerDetailsModal = useCallback(
    async (
      partner: Partial<Partner> & {
        isDeleted: boolean;
      },
    ) => {
      if (partner) {
        openPartnerDetails({ partner: partner as unknown as Partner });
      }
    },
    [openPartnerDetails],
  );

  return {
    handleOpenPartnerDetailsModal,
  };
};

export default useConnect;
