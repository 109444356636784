import { FC, memo } from 'react';
import { translateQuotaType } from 'utils/quotas';
import { applyDiscount } from 'utils/discounts';
import { Container, Name, OriginalPrice, Price, Type } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  discount,
  name,
  onSelectQuota,
  price,
  type,
  value,
}) => (
  <Container className={className} onClick={onSelectQuota}>
    <Name>{name}</Name>
    <Type>{translateQuotaType(type, value)}</Type>
    <Price>
      {discount && <OriginalPrice>{price}</OriginalPrice>}
      {discount
        ? applyDiscount({
            discount,
            price,
          })
        : price}{' '}
      cr
    </Price>
  </Container>
);

export default memo(Row);
