import { FC, memo } from 'react';
import formatNumber from 'utils/formatNumber';
import {
  Container,
  FirstPage,
  IconButton,
  Info,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from './styles';
import { Props } from './types';
import useLogic from './logic';

const Pagination: FC<Props> = ({
  className,
  onPageChange,
  page,
  pageSize,
  total,
}) => {
  const {
    handleFirstPage,
    handlePreviousPage,
    handleNextPage,
    handleLastPage,
    nPages,
  } = useLogic({ onPageChange, page, pageSize, total });

  if (nPages <= 1) {
    return null;
  }

  return (
    <Container className={className}>
      <IconButton disabled={page === 1} onClick={handleFirstPage}>
        <FirstPage />
      </IconButton>
      <IconButton disabled={page === 1} onClick={handlePreviousPage}>
        <NavigateBefore />
      </IconButton>
      <Info>
        {formatNumber(page)} of {formatNumber(nPages)}
      </Info>
      <IconButton disabled={page === nPages} onClick={handleNextPage}>
        <NavigateNext />
      </IconButton>
      <IconButton disabled={page === nPages} onClick={handleLastPage}>
        <LastPage />
      </IconButton>
    </Container>
  );
};

export default memo(Pagination);
