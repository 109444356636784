import { useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import useMe from 'apollo/hooks/user/useMe';
import useModal from 'apollo/hooks/useModal';
import useOrganizationActions from 'apollo/hooks/organization/useOrganizationActions';
import { permissionsStateVar } from 'apollo/reactive/permissionsState';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';

const useConnect = () => {
  const { openCreditConcept, openDialog, close } = useModal();
  const { data: me, loading: getMeLoading } = useMe({
    fetchPolicy: 'cache-first',
  });
  const {
    setOrganizationCreditConcepts,
    loading: setOrganizationConfigLoading,
  } = useOrganizationActions();
  const { enqueueSnackbar } = useSnackbar();
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const { organization } = me || {};

  const conceptCredits = useMemo<string[]>(
    () => organization?.config?.creditConcepts || [],
    [organization],
  );

  const handleRemoveCreditConcept = useCallback(
    async (concept: string) => {
      try {
        await setOrganizationCreditConcepts({ creditConcept: concept });
        enqueueSnackbar('El concepto se ha eliminado correctamente.', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('organization', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
      close();
    },
    [close, enqueueSnackbar, setOrganizationCreditConcepts],
  );

  const handleOpenRemoveCreditConceptDialog = useCallback(
    async (concept: string) => {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar un concepto de creditos y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: () => handleRemoveCreditConcept(concept),
        title: 'Eliminar concepto',
        variant: 'danger',
      });
    },
    [handleRemoveCreditConcept, openDialog],
  );

  return {
    employeePermissions,
    handleOpenCreditConceptModal: openCreditConcept,
    handleOpenRemoveCreditConceptDialog,
    conceptCredits,
    isLoading: getMeLoading || setOrganizationConfigLoading,
    organization: me?.organization,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
