import type { FC } from 'react';
import {
  ActionButton,
  Buttons,
  CancelButton,
  Container,
  IconCircle,
  Icon,
  Title,
} from './styles';
import useConnect from './connect';

const UploadImageDialogModal: FC = () => {
  const { handleAcceptCamera, handleAcceptComputer, isOpen, handleClose } =
    useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>Método de carga</Title>
      <Buttons>
        <ActionButton onClick={handleAcceptComputer}>
          Desde el ordenador
        </ActionButton>
        <ActionButton onClick={handleAcceptCamera}>
          Desde la camara
        </ActionButton>
      </Buttons>
      <CancelButton onClick={handleClose}>Cancelar</CancelButton>
    </Container>
  );
};

export default UploadImageDialogModal;
