import { FC } from 'react';
import useLogic from './logic';
import { Container, InputContainer, MaterialCheckbox, Label } from './styles';
import { ErrorMessage } from '../styles';
import type { Props } from './types';

const Checkbox: FC<Props> = ({ className, disabled, id, label, name }) => {
  const { value, handleChange, error } = useLogic({ name });

  return (
    <Container className={className}>
      <InputContainer
        $isDisabled={disabled}
        $hasError={!!error}
        onClick={() => handleChange('' as any, !value)}
      >
        {label && <Label>{label}</Label>}

        <MaterialCheckbox
          id={id}
          name={name}
          checked={value}
          onChange={handleChange}
          disabled={disabled}
        />
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default Checkbox;
