import { FC, memo } from 'react';
import { Button, Container, Inputs, DatePickerTime } from './styles';
import { Props } from './types';
import useLogic from './logic';

const DateTimeFilter: FC<Props> = ({
  className,
  onSearch,
  fromDate,
  toDate,
}) => {
  const {
    handleChangeFromDate,
    handleChangeToDate,
    handleSubmit,
    savedFromDate,
    savedToDate,
  } = useLogic({
    fromDate,
    toDate,
    onSearch,
  });

  return (
    <Container className={className}>
      <Inputs>
        <DatePickerTime
          onChange={handleChangeFromDate}
          value={savedFromDate}
          label="Desde"
        />
        <DatePickerTime
          onChange={handleChangeToDate}
          value={savedToDate}
          label="Hasta"
        />
      </Inputs>
      <Button variant="light" onClick={handleSubmit}>
        Aplicar
      </Button>
    </Container>
  );
};

export default memo(DateTimeFilter);
