import { FC, memo } from 'react';
import { formatDateToTableExtended } from 'utils/dates/formatDates';
import { Container, CreationDate, LastUpdateDate, Name } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, menu }) => {
  return (
    <Container to={`/organization/menus/${menu.id}`} className={className}>
      <Name>{menu.name}</Name>
      <CreationDate>{formatDateToTableExtended(menu.createdAt)}</CreationDate>
      <LastUpdateDate>
        {formatDateToTableExtended(menu.updatedAt)}
      </LastUpdateDate>
    </Container>
  );
};

export default memo(Row);
