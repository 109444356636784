import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CREATE_PERMISSIONS_GROUP,
  EDIT_PERMISSIONS_GROUP,
  REMOVE_PERMISSIONS_GROUP,
} from 'apollo/mutations/permissionsGroup';
import type {
  CreatePermissionsGroup,
  CreatePermissionsGroupVariables,
} from 'apollo/generated/CreatePermissionsGroup';
import type {
  EditPermissionsGroup,
  EditPermissionsGroupVariables,
} from 'apollo/generated/EditPermissionsGroup';
import type {
  RemovePermissionsGroupMutation,
  RemovePermissionsGroupMutationVariables,
} from 'apollo/graphql.types';
import type { PermissionsFormFields } from 'model/PermissionsGroup';

const usePermissionsGroupActions = () => {
  const [
    createPermissionsGroupMutation,
    { loading: createPermissionsGroupIsLoading },
  ] = useMutation<CreatePermissionsGroup, CreatePermissionsGroupVariables>(
    CREATE_PERMISSIONS_GROUP,
  );
  const [
    editPermissionsGroupMutation,
    { loading: editPermissionsGroupIsLoading },
  ] = useMutation<EditPermissionsGroup, EditPermissionsGroupVariables>(
    EDIT_PERMISSIONS_GROUP,
  );
  const [
    removePermissionsGroupMutation,
    { loading: removePermissionsGroupIsLoading },
  ] = useMutation<
    RemovePermissionsGroupMutation,
    RemovePermissionsGroupMutationVariables
  >(REMOVE_PERMISSIONS_GROUP);

  const normalizeFormValues = (values: PermissionsFormFields) => ({
    name: values.name || '',

    // Products
    allowCreateProducts: values.allowCreateProducts || false,
    allowEditProducts: values.allowEditProducts || false,
    allowAdjustProducts: values.allowAdjustProducts || false,
    allowShowAdjustProducts: values.allowShowAdjustProducts || false,
    allowRemoveProducts: values.allowRemoveProducts || false,

    // Partners
    allowCreatePartners: values.allowCreatePartners || false,
    allowEditPartners: values.allowEditPartners || false,
    allowRemovePartners: values.allowRemovePartners || false,

    // Expenses
    allowShowExpenses: values.allowShowExpenses || false,
    allowCreateExpenses: values.allowCreateExpenses || false,
    allowEditExpenses: values.allowEditExpenses || false,
    allowRemoveExpenses: values.allowRemoveExpenses || false,

    // Reports
    allowShowReports: values.allowShowReports || false,

    // Metrics
    allowShowMetrics: values.allowShowMetrics || false,

    // Cashier
    allowShowCashier: values.allowShowCashier || false,
    allowShowCashierOneDay: values.allowShowCashierOneDay || false,
    allowDownloadCashierData: values.allowDownloadCashierData || false,
    allowShowProductsCashier: values.allowShowProductsCashier || false,
    allowShowPartnersCashier: values.allowShowPartnersCashier || false,

    // Calendar
    allowShowCalendars: values.allowShowCalendars || false,
    allowCreateCalendars: values.allowCreateCalendars || false,
    allowEditCalendars: values.allowEditCalendars || false,
    allowRemoveCalendars: values.allowRemoveCalendars || false,

    // Files
    allowShowFiles: values.allowShowFiles || false,
    allowCreateFiles: values.allowCreateFiles || false,
    allowRemoveFiles: values.allowRemoveFiles || false,

    // Providers
    allowShowProviders: values.allowShowProviders || false,
    allowCreateProviders: values.allowCreateProviders || false,
    allowEditProviders: values.allowEditProviders || false,
    allowRemoveProviders: values.allowRemoveProviders || false,

    // Discounts
    allowShowDiscounts: values.allowShowDiscounts || false,
    allowCreateDiscounts: values.allowCreateDiscounts || false,
    allowAssignDiscounts: values.allowAssignDiscounts || false,
    allowEditDiscounts: values.allowEditDiscounts || false,
    allowRemoveDiscounts: values.allowRemoveDiscounts || false,

    // Quotas
    allowShowQuotas: values.allowShowQuotas || false,
    allowCreateQuotas: values.allowCreateQuotas || false,
    allowAssignQuotas: values.allowAssignQuotas || false,
    allowEditQuotas: values.allowEditQuotas || false,
    allowRemoveQuotas: values.allowRemoveQuotas || false,

    // Credits concepts
    allowShowCreditsConcept: values.allowShowCreditsConcept || false,
    allowCreateCreditsConcept: values.allowCreateCreditsConcept || false,
    allowEditCreditsConcept: values.allowEditCreditsConcept || false,
    allowRemoveCreditsConcept: values.allowRemoveCreditsConcept || false,

    // Categories
    allowCreateCategories: values.allowCreateCategories || false,
    allowEditCategories: values.allowEditCategories || false,
    allowRemoveCategories: values.allowRemoveCategories || false,

    // Contract
    allowShowContract: values.allowShowContract || false,
    allowEditContract: values.allowEditContract || false,
    allowAssignContract: values.allowAssignContract || false,

    // Organization
    allowShowTransactionsHistory: values.allowShowTransactionsHistory || false,
  });

  const createPermissionsGroup = useCallback(
    async (data: PermissionsFormFields) => {
      const normalizedData = normalizeFormValues(data);
      await createPermissionsGroupMutation({
        variables: {
          data: normalizedData,
        },
      });
    },
    [createPermissionsGroupMutation],
  );

  const editPermissionsGroup = useCallback(
    async ({ id, data }: { id: string; data: PermissionsFormFields }) => {
      const normalizedData = normalizeFormValues(data);
      await editPermissionsGroupMutation({
        variables: {
          id,
          data: normalizedData,
        },
      });
    },
    [editPermissionsGroupMutation],
  );

  const removePermissionsGroup = useCallback(
    async (id: string) => {
      await removePermissionsGroupMutation({
        variables: {
          id,
        },
      });
    },
    [removePermissionsGroupMutation],
  );

  return {
    createPermissionsGroup,
    editPermissionsGroup,
    removePermissionsGroup,
    loading:
      createPermissionsGroupIsLoading ||
      editPermissionsGroupIsLoading ||
      removePermissionsGroupIsLoading,
  };
};

export default usePermissionsGroupActions;
