import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS, TABLE_MOBILE_METRICS } from '../constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  padding: 1rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey700}1A;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.medium};
  ${TextEllipsis};

  ${from.tabletPortrait`
    font-size: 1rem;
  `};
`;

export const Name = styled(ColumnTitle)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_MOBILE_METRICS.name};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.name};
  `};
`;

export const Quantity = styled(ColumnTitle)`
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.quantity};
`;

export const Purchases = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_MOBILE_METRICS.purchases};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.purchases};
  `};
`;

export const Discounts = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_MOBILE_METRICS.discounts};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.discounts};
  `};
`;

export const Costs = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_MOBILE_METRICS.costs};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.costs};
  `};
`;

export const Benefit = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_MOBILE_METRICS.benefit};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.benefit};
  `};
`;

export const RemainingQuantity = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_METRICS.remainingQuantity};
  white-space: pre-wrap;
`;
