import { gql } from '@apollo/client';
import { DISCOUNT_FRAGMENT } from 'apollo/fragments/Discount';

export const GET_DISCOUNTS = gql`
  query GetDiscounts($filter: String) {
    discounts(filter: $filter) {
      ...DiscountData
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const GET_DISCOUNT = gql`
  query GetDiscount($id: ID!) {
    discount(id: $id) {
      ...DiscountData
    }
  }
  ${DISCOUNT_FRAGMENT}
`;
