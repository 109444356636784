import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { GET_ORGANIZATION_DOCUMENTS } from 'apollo/queries';
import { permissionsStateVar, subscriptionStateVar } from 'apollo/reactive';
import { normalizeOrganizationDocument } from 'model/Organization';
import type { GetDocumentsQuery } from 'apollo/graphql.types';

const useOrganizationDocuments = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const subscription = useReactiveVar(subscriptionStateVar);

  const { data, loading } = useQuery<GetDocumentsQuery>(
    GET_ORGANIZATION_DOCUMENTS,
    {
      skip:
        !subscription.allowedGenerateFiles ||
        !employeePermissions.allowShowContract,
    },
  );

  const documents = useMemo(
    () =>
      data?.getDocuments && data?.getDocuments?.length > 0
        ? data.getDocuments.map(normalizeOrganizationDocument)
        : [],
    [data],
  );

  return {
    getDocumentsLoading: loading,
    documents,
  };
};

export default useOrganizationDocuments;
