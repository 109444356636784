import { FC, memo } from 'react';
import type { Props } from './types';

const Reports: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>reports icon</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Group"
        stroke="currentColor"
        transform="translate(4.000000, 5.000000)"
      >
        <rect id="Rectangle" x="0.5" y="0.5" width="9" height="13" rx="1" />
        <rect
          id="Rectangle-Copy-4"
          x="9.5"
          y="2.5"
          width="3"
          height="9"
          rx="1"
        />
        <rect
          id="Rectangle-Copy-5"
          x="12.5"
          y="4.5"
          width="3"
          height="5"
          rx="1"
        />
      </g>
    </g>
  </svg>
);

export default memo(Reports);
