import { BaseMutationOptions } from '@apollo/client';
import { apolloClient } from 'services/api';
import {
  GetImageSignedUrlTemporaryPartnerMutation,
  GetImageSignedUrlTemporaryPartnerMutationVariables,
} from 'apollo/graphql.types';
import { GET_IMAGE_SIGNED_URL_TEMPORARY } from 'apollo/mutations/images';

const getImageSignedUrlTemporary = async (
  options: BaseMutationOptions<
    GetImageSignedUrlTemporaryPartnerMutation,
    GetImageSignedUrlTemporaryPartnerMutationVariables
  >,
) => {
  const { data } = await apolloClient.mutate<
    GetImageSignedUrlTemporaryPartnerMutation,
    GetImageSignedUrlTemporaryPartnerMutationVariables
  >({
    mutation: GET_IMAGE_SIGNED_URL_TEMPORARY,
    ...options,
  });

  const uploadUrl = data?.getImageSignedUrlTemporaryPartner.uploadUrl;
  const {
    content_type: contentType,
    id,
    file_name: fileName,
    file_size: size,
  } = data?.getImageSignedUrlTemporaryPartner.image || {};

  if (!contentType || !fileName || !id || !size || !uploadUrl) {
    throw new Error('There has been a problem getting the upload url');
  }

  return {
    uploadUrl,
    contentType,
    id,
    fileName,
    size,
  };
};

export default getImageSignedUrlTemporary;
