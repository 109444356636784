import { FC, memo } from 'react';
import AdjustProductQuantityModal from '../../../Modals/AdjustProductQuantity';
import MoveStoreToStockModal from '../../../Modals/MoveStoreToStock';
import { SectionTitle, Header, BackLink, HeaderContent } from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import {
  Container,
  EmptyState,
  Main,
  FiltersContainer,
  Filters,
  CategoriesFilter,
  CategoryFilter,
  CategoryName,
} from './styles';
import useConnect from './connect';

const OrganizationStock: FC = () => {
  const {
    employeePermissions,
    products,
    handleOpenAdjustProduct,
    handleOpenNewProductModal,
    handleChangeOrder,
    orderBy,
    mostUsedCategories,
    isSelectedCategory,
    isRefetching,
    filter,
    handleSearch,
    handleForceSearch,
    handleToggleCategory,
  } = useConnect();
  const hasProducts = products.length > 0;
  const hasMostUsedCategories = mostUsedCategories.length > 0;

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <BackLink to="/organization" />
            <SectionTitle>Ajuste de Stock</SectionTitle>
          </HeaderContent>
        </Header>
        <FiltersContainer>
          <Filters
            isLoading={isRefetching}
            onSearchChange={handleSearch}
            filter={filter}
            nResults={0}
            onForceSearch={handleForceSearch}
          />
          {hasMostUsedCategories && (
            <CategoriesFilter>
              {mostUsedCategories.map((category) => (
                <CategoryFilter
                  key={category.name}
                  $selected={isSelectedCategory(category.name)}
                  onClick={() => handleToggleCategory(category.name)}
                >
                  <CategoryName>{category.name}</CategoryName>
                </CategoryFilter>
              ))}
            </CategoriesFilter>
          )}
        </FiltersContainer>
        <Main>
          {!hasProducts && employeePermissions.allowCreateProducts && (
            <EmptyState
              title="No hay productos"
              iconName="products"
              buttonLabel="Crear Producto"
              onClickButton={handleOpenNewProductModal}
            >
              Crea tu primera Producto
            </EmptyState>
          )}
          {hasProducts && (
            <>
              <TableHead
                onChangeOrder={handleChangeOrder}
                currentOrder={orderBy}
              />
              {products.map((product) => (
                <Row
                  key={product.id}
                  product={product}
                  onClick={handleOpenAdjustProduct}
                />
              ))}
            </>
          )}
        </Main>
      </Container>
      <AdjustProductQuantityModal />
      <MoveStoreToStockModal />
    </>
  );
};

export default memo(OrganizationStock);
