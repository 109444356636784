import { gql } from '@apollo/client';
import {
  PRODUCT_FRAGMENT,
  PRODUCT_QUANTITY_HISTORY_FRAGMENT,
  PRODUCT_STATISTICS_FRAGMENT,
  PRODUCT_TRANSACTIONS_FRAGMENT,
} from 'apollo/fragments/Product';

export const GET_PRODUCTS = gql`
  query GetProducts($filters: ProductsFilter) {
    products(filters: $filters) {
      ...ProductData
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCTS_GROUPED_BY_NAME = gql`
  query GetProductsGroupedByName($filters: DatesFilters) {
    productsGroupedByName(filters: $filters) {
      name
      products {
        ...ProductStatisticsData
      }
    }
  }
  ${PRODUCT_STATISTICS_FRAGMENT}
`;

export const GET_PRODUCT = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      ...ProductData
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_TRANSACTIONS = gql`
  query GetProductTransactions($id: ID!, $filters: DatesFilters) {
    productTransactions(id: $id, filters: $filters) {
      ...ProductTransactionsData
    }
  }
  ${PRODUCT_TRANSACTIONS_FRAGMENT}
`;

export const GET_PRODUCT_STATISTICS = gql`
  query GetProductsStatistics($id: ID!, $filters: DatesFilters) {
    productsStatistics(id: $id, filters: $filters) {
      ...ProductStatisticsData
    }
  }
  ${PRODUCT_STATISTICS_FRAGMENT}
`;

export const GET_PRODUCT_QUANTITY_HISTORY = gql`
  query GetProductHistoricAdjusts(
    $id: ID!
    $filters: ProductsHistoricAdjustsFilters
  ) {
    productHistoricAdjusts(id: $id, filters: $filters) {
      ...ProductQuantityHistoryData
    }
  }
  ${PRODUCT_QUANTITY_HISTORY_FRAGMENT}
`;

export const GET_PRODUCTS_QUANTITY_HISTORY_BY_DATES = gql`
  query GetProductsHistoricAdjustsByDates(
    $filters: ProductsHistoricAdjustsFilters
  ) {
    productsHistoricAdjustsByDates(filters: $filters) {
      ...ProductQuantityHistoryData
    }
  }
  ${PRODUCT_QUANTITY_HISTORY_FRAGMENT}
`;
