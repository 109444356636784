import type { FC } from 'react';
import Loader from 'components/Loader';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import AddWorkSchedule from '../../Modals/AddWorkSchedule';
import EmployeeAdvancedMenu from '../../Modals/EmployeeAdvancedMenu';
import UpdatePasswordForm from '../../Modals/UpdatePassword';
import ProfileForm from './ProfileForm';
import useConnect from './connect';
import { Container, Content, Section } from './styles';
import EmployeeHeader from './Header';
import EmployeeNavigationSidebar from './NavigationSidebar';
import EmployeeTransactions from './Transactions';
import WorkSchedulesForm from './WorkSchedules';
import EmployeeLogins from './Logins';
import { EmployeeSections } from './constants';

const Employee: FC = () => {
  const {
    employeeId,
    handleOpenAdvancedEmployeeMenuModal,
    handleOpenPartnerDetailsModal,
    handleSelectedSection,
    handleSubmit,
    handleSubmitAvatar,
    initialProfileFormValues,
    isLoading,
    partnerNum,
    selectedSection,
    workSchedules,
    permissionsGroupsOptions,
    logins,
    isAdmin,
    isActive,
  } = useConnect();

  const fullName = `${initialProfileFormValues.firstName}${
    initialProfileFormValues.lastName
      ? ` ${initialProfileFormValues.lastName}`
      : ''
  }`;
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <EmployeeHeader
          currentAvatar={initialProfileFormValues.avatarImageUrl}
          fromTabletPortrait={fromTabletPortrait}
          fullName={fullName}
          onClickAdvancedActions={handleOpenAdvancedEmployeeMenuModal}
          onSubmitAvatar={handleSubmitAvatar}
          openPartnerDetails={handleOpenPartnerDetailsModal}
          partnerNum={partnerNum}
          isActive={isActive}
        />
        <Content>
          <EmployeeNavigationSidebar
            onSelectedSection={handleSelectedSection}
            employeeId={employeeId}
          />
          <Section>
            {selectedSection === EmployeeSections.PROFILE && (
              <ProfileForm
                initialValues={initialProfileFormValues}
                onSubmit={handleSubmit}
                permissionsGroupsOptions={permissionsGroupsOptions}
                isAdmin={isAdmin}
              />
            )}
            {selectedSection === EmployeeSections.TRANSACTIONS && (
              <EmployeeTransactions />
            )}
            {selectedSection === EmployeeSections.SCHEDULES && (
              <WorkSchedulesForm
                employeeId={employeeId || ''}
                workSchedules={workSchedules}
              />
            )}
            {selectedSection === EmployeeSections.LOGINS && (
              <EmployeeLogins logins={logins} employeeName={fullName} />
            )}
          </Section>
        </Content>
      </Container>
      <AddWorkSchedule />
      <EmployeeAdvancedMenu />
      <UpdatePasswordForm />
    </>
  );
};

export default Employee;
