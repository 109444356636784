import { apolloClient } from 'services/api';
import { Employee, normalizeEmployee } from 'model/Employee';
import {
  GetEmployeesQuery,
  GetEmployeesQueryVariables,
} from 'apollo/graphql.types';
import { GET_EMPLOYEES } from 'apollo/queries/employees';

const getEmployee = async (id: Employee['id']) => {
  const { data } = await apolloClient.query<
    GetEmployeesQuery,
    GetEmployeesQueryVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    query: GET_EMPLOYEES,
  });

  const employees = data?.employees;
  if (!employees) {
    return undefined;
  }

  const selectedEmployee = employees.find((employee) => employee.id === id);

  if (!selectedEmployee) {
    return undefined;
  }

  return normalizeEmployee(selectedEmployee);
};

export default getEmployee;
