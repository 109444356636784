import { FC, memo } from 'react';
import { Formik } from 'formik';
import Switch from 'components/Inputs/Switch';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  Row,
  TextField,
  Icon,
  SelectOptions,
  SelectOptionsTitle,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const Form: FC<Props> = ({
  activateProductReference,
  className,
  onSubmit,
  onCancel,
  initialValues,
  isLoading,
  id = 'new',
  onSelectCategories,
  formikRef,
}) => {
  const formId = `product_${id}`;
  const hasCategories = Boolean(
    initialValues?.categoryIds && initialValues.categoryIds.length > 0,
  );

  return (
    <Formik
      innerRef={formikRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <Row>
            <TextField
              placeholder="Lemon Haze"
              name="name"
              label="Nombre *"
              id={`${formId}_name`}
            />
            <TextField
              placeholder="5.75"
              type="number"
              name="price"
              label="Precio *"
              id={`${formId}_price`}
            />
          </Row>
          <Row>
            <TextField
              placeholder="870"
              type="number"
              name="quantity"
              label="Stock *"
              id={`${formId}_quantity`}
            />
            <TextField
              placeholder="800"
              type="number"
              name="storeQuantity"
              label="Almacén"
              id={`${formId}_storeQuantity`}
            />
          </Row>
          <Row>
            <TextField
              placeholder="200"
              type="number"
              name="baseWeight"
              label="Peso base"
              id={`${formId}_baseWeight`}
            />
            <TextField
              placeholder="3.5"
              type="number"
              name="costs"
              label="Coste"
              id={`${formId}_costs`}
            />
          </Row>
          <Row>
            {activateProductReference && (
              <TextField
                name="referenceCode"
                label="Referencia"
                id={`${formId}_referenceCode`}
              />
            )}
          </Row>
          <br />
          <TextField
            placeholder="..."
            type="number"
            name="description"
            label="Descripción"
            id={`${formId}_description`}
            rows={3}
            isMultiline
          />
          <Row>
            <Switch id={`${formId}_isActive`} label="Visible" name="isActive" />
            <Switch
              id={`${formId}_countToMaxConsume`}
              label="Añadir al consumo max"
              name="countToMaxConsume"
            />
          </Row>
          <SelectOptions onClick={onSelectCategories} $hasItems={hasCategories}>
            {hasCategories ? (
              <SelectOptionsTitle>
                Categorías Seleccionadas ({initialValues.categoryIds?.length})
              </SelectOptionsTitle>
            ) : (
              <SelectOptionsTitle>Seleccionar categorías</SelectOptionsTitle>
            )}
            <Icon name="arrowRight" />
          </SelectOptions>
          <Buttons>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || !isFormValid}
            >
              Crear
            </CreateButton>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(Form);
