import styled from 'styled-components';
import { DatePicker as DefaultDatePicker } from '@mui/x-date-pickers/DatePicker';
import { motion } from 'framer-motion';

export const Container = styled.div``;

export const DatePickerInput = styled(DefaultDatePicker)`
  &&& {
    width: 100%;
  }
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.01rem;
  margin-top: 0.25rem;
  text-align: left;
`;

export const Label = styled.label`
  font-size: 0.9rem;
  text-align: left;
`;
