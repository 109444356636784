import { FC, memo } from 'react';
import Loader from 'components/Loader';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import ProfileForm from './ProfileForm';
import { useConnect } from './connect';
import { Container, Content, Section } from './styles';
import ProductHeader from './Header';
import ProductNavigationSidebar from './NavigationSidebar';
import ProductTransactions from './Transactions';
import ProductImagesForm from './ImagesForm';
import CategoriesForm from './CategoriesForm';
import { ProductSections } from './constants';
import AdjustsHistory from './AdjustsHistory';
import StoreHistory from './StoreHistory';
import ProductAdvancedMenuModal from '../../Modals/ProductAdvancedMenu';
import NewReferencedProductModal from '../../Modals/NewReferencedProduct';
import AdjustProductOptionsDialogModal from '../../Modals/StoreProductOptionsDialog';
import AdjustProductOptionsModal from '../../Modals/AdjustProductOptionsDialog';
import AdjustProductStoreModal from '../../Modals/AdjustProductStore';
import MoveStoreToStockModal from '../../Modals/MoveStoreToStock';
import AdjustProductDetailsModal from '../../Modals/AdjustProductDetails';
import AdjustProductQuantityModal from '../../Modals/AdjustProductQuantity';

const ProductDetail: FC = () => {
  const {
    categories,
    handleOnSubmit,
    handleOnSubmitCategories,
    handleOnSubmitImages,
    handleOpenAdjustModal,
    handleOpenRemoveDialog,
    handleSelectedSection,
    handleToggleCategory,
    initialCategoriesValues,
    initialFormValues,
    initialImagesValues,
    isLoading,
    selectedSection,
    subscription,
    handleOpenProductAdvancedModal,
    productId,
    handleOpenDiscounts,
  } = useConnect();
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <ProductHeader
          fromTabletPortrait={fromTabletPortrait}
          name={initialFormValues.name}
          referenceCode={initialFormValues.referenceCode}
          onAdjust={handleOpenAdjustModal}
          onDelete={handleOpenRemoveDialog}
          onOpenAdvancedMenu={handleOpenProductAdvancedModal}
        />
        <Content>
          <ProductNavigationSidebar
            productId={productId}
            onSelectedSection={handleSelectedSection}
          />
          <Section>
            {selectedSection === ProductSections.PROFILE && (
              <ProfileForm
                initialValues={initialFormValues}
                onSubmit={handleOnSubmit}
                onClickDiscount={handleOpenDiscounts}
              />
            )}
            {selectedSection === ProductSections.IMAGES && (
              <ProductImagesForm
                initialValues={initialImagesValues}
                onSubmit={handleOnSubmitImages}
              />
            )}
            {selectedSection === ProductSections.CATEGORIES && (
              <CategoriesForm
                categories={categories}
                initialValues={initialCategoriesValues}
                onSubmit={handleOnSubmitCategories}
                onToggleCategory={handleToggleCategory}
              />
            )}
            {selectedSection === ProductSections.TRANSACTIONS &&
              subscription.allowedProductTransactions && (
                <ProductTransactions />
              )}
            {selectedSection === ProductSections.HISTORIC_ADJUST && (
              <AdjustsHistory />
            )}
            {selectedSection === ProductSections.HISTORIC_STORE && (
              <StoreHistory />
            )}
          </Section>
        </Content>
      </Container>
      <ProductAdvancedMenuModal />
      <NewReferencedProductModal />
      <AdjustProductOptionsDialogModal />
      <AdjustProductStoreModal />
      <MoveStoreToStockModal />
      <AdjustProductDetailsModal />
      <AdjustProductOptionsModal />
      <AdjustProductQuantityModal />
    </>
  );
};

export default memo(ProductDetail);
