import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultIcon from 'components/Icon';
import DefaultTextField from 'components/Inputs/TextField';

export const Container = styled(DefaultModal)`
  ${Content} {
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TextField = styled(DefaultTextField)`
  margin-top: 1rem;

  &&& {
    input[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  ${from.tabletLandscape`
    margin-top: 0;
  `}
`;

export const Button = styled(DefaultButton)``;

export const IconCircle = styled(DefaultIconCircle)`
  margin-left: auto;
  margin-right: auto;
`;

export const GreenIconCircle = styled(IconCircle)`
  background-color: ${({ theme }) => theme.colors.green}0A;
  color: ${({ theme }) => theme.colors.green};
`;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 20,
}))``;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const RequestMsg = styled.h3`
  margin: 1rem 0 2rem 0;
  font-weight: normal;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  margin-top: 2.5rem;

  ${from.tabletPortrait`
    flex-flow: row nowrap;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const AcceptButton = styled(DefaultButton)`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-right: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    ${from.tabletPortrait`
      margin-right: 0.5rem;
    `}
  }
`;
