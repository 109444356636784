import { gql } from '@apollo/client';
import { EXPENSE_FRAGMENT } from 'apollo/fragments/Expense';

export const GET_EXPENSES = gql`
  query GetExpenses($filters: ExpensesFilter) {
    expenses(filters: $filters) {
      ...ExpenseData
    }
  }
  ${EXPENSE_FRAGMENT}
`;

export const GET_EXPENSE = gql`
  query GetExpense($id: ID!) {
    expense(id: $id) {
      ...ExpenseData
    }
  }
  ${EXPENSE_FRAGMENT}
`;

export const GET_EXPENSES_TAGS = gql`
  query GetExpensesTags {
    expensesTags
  }
`;
