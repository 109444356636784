import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { Container } from './styles';
import type { Props } from './types';

const Logo: FC<Props> = ({ className, abilityNavigation }) => {
  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    if (abilityNavigation) {
      navigate('/');
    }
  }, [abilityNavigation, navigate]);

  return (
    <Container
      $abilityNavigation={abilityNavigation}
      alt="Inditas"
      src={logo}
      className={className}
      onClick={handleNavigate}
    />
  );
};

export default memo(Logo);
