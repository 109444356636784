import styled from 'styled-components';
import DefaultButton from 'components/Button';
import DefaultCameraIcon from 'components/Icon/Icons/Camera';
import DefaultTrashIcon from 'components/Icon/Icons/Trash';
import { DEFAULT_IMAGE_WIDTH } from './constants';

export const Container = styled.div<{
  $headerPosition?: 'top' | 'bottom';
}>`
  align-items: stretch;
  display: flex;
  flex-flow: ${({ $headerPosition }) =>
    $headerPosition === 'bottom' ? 'column-reverse nowrap' : 'column nowrap'};
  justify-content: flex-start;
  position: relative;
  width: ${DEFAULT_IMAGE_WIDTH};
`;

export const Header = styled.div<{ $headerPosition?: 'top' | 'bottom' }>`
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-flow: row-reverse nowrap;
  justify-content: flex-start;
  margin: ${({ $headerPosition }) =>
    $headerPosition === 'top' ? '0 0 0.6rem 0' : '0.6rem 0 0 0'};
`;

export const Text = styled.div`
  flex: 1 1 auto;
`;

export const Label = styled.label`
  display: block;
`;

export const Dimensions = styled.div`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-top: 0.1rem;
  line-height: 0.75;
`;

export const Buttons = styled.div<{ $hasImageLoaded: boolean }>`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  visibility: ${({ $hasImageLoaded }) =>
    $hasImageLoaded ? 'visible' : 'hidden'};
`;

// TODO: Optimize this and create a reusable IconButton component.
export const IconButton = styled(DefaultButton).attrs(() => ({
  type: 'button',
}))`
  &&& {
    background: transparent;
    border-radius: 50%;
    margin-left: 0.8rem;
    line-height: 1;
    min-height: auto;
    min-width: auto;
    padding: 0;
    height: 2rem;
    width: 2rem;
    transition: background-color 300ms ease;

    .MuiButton-label {
      line-height: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey200};
    }
  }
`;

export const CameraIcon = styled(DefaultCameraIcon).attrs(() => ({
  size: 22,
}))`
  color: ${({ theme }) => theme.colors.blue900};
`;

export const TrashIcon = styled(DefaultTrashIcon).attrs(() => ({
  size: 18,
}))`
  color: ${({ theme }) => theme.colors.danger};
`;

export const Content = styled.div<{
  $height?: number;
}>`
  border-radius: 0.5rem;
  flex: 1 1 auto;
  overflow-y: hidden;
  position: relative;
  height: ${({ $height }) => `${$height}px`};
  width: 100%;
`;

export const Img = styled.img`
  border-radius: inherit;
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;
