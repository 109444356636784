import styled from 'styled-components';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey900}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

export const Content = styled.div`
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  height: 100%;
  width: 100%;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.name};
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
`;
