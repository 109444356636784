import { FC, memo } from 'react';
import {
  Container,
  Name,
  Number,
  Type,
  EditIcon,
  Icons,
  TrashIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  addedBy,
  className,
  employeePermissions,
  name,
  onEdit,
  onOpenExpensesModal,
  onRemove,
  totalExpenses,
}) => {
  return (
    <Container className={className}>
      <Name>{name}</Name>
      <Number onClick={onOpenExpensesModal} $hasExpenses={totalExpenses > 0}>
        {totalExpenses}
      </Number>
      <Type>{addedBy}</Type>
      <Icons>
        {employeePermissions.allowEditProviders && (
          <EditIcon onClick={onEdit} />
        )}
        {employeePermissions.allowRemoveProviders && (
          <TrashIcon onClick={onRemove} />
        )}
      </Icons>
    </Container>
  );
};

export default memo(Row);
