import { gql } from '@apollo/client';
import { PERMISSIONS_GROUP_FRAGMENT } from '../fragments/PermissionsGroup';

export const GET_PERMISSIONS_GROUPS = gql`
  query GetPermissionsGroups {
    getPermissionsGroups {
      ...PermissionsGroupData
    }
  }

  ${PERMISSIONS_GROUP_FRAGMENT}
`;

export const GET_PERMISSIONS_GROUP = gql`
  query GetPermissionsGroup($id: ID!) {
    getPermissionsGroup(id: $id) {
      ...PermissionsGroupData
    }
  }

  ${PERMISSIONS_GROUP_FRAGMENT}
`;
