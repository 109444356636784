import type { FC } from 'react';
import {
  Container,
  Logo,
  Title,
  Header,
  Content,
  Subtitle,
  Text,
  Highlight,
  Link,
  TextTitle,
  Options,
  Option,
  PendingInfo,
} from './styles';

const PrivacyPolicy: FC = () => {
  return (
    <Container>
      <Header>
        <Logo abilityNavigation />
      </Header>
      <Content>
        <Title>POLÍTICA DE PRIVACIDAD</Title>
        <Subtitle>Última actualización 20 de Mayo de 2024</Subtitle>
        <Text>
          <Highlight>INDITAS</Highlight>, consciente de la importancia de
          mantener la privacidad de los datos de carácter personal de sus
          usuarios y clientes, ha implementado una política de tratamiento de
          datos orientada a proveer la máxima seguridad en el tratamiento de los
          mismos, garantizando el cumplimiento de la normativa vigente en la
          materia y configurando dicha política como uno de los pilares básicos
          en las líneas de actuación de la entidad.
        </Text>
        <Text>
          <Highlight>INDITAS</Highlight>, con CIF número{' '}
          <PendingInfo>XXX</PendingInfo> y con domicilio social en{' '}
          <PendingInfo>CXXXX</PendingInfo>, como responsable del tratamiento de
          sus datos personales informa a los usuarios del sitio{' '}
          <Link href="www.inditas.com">www.inditas.com</Link> que cumple
          íntegramente con lo dispuesto en la normativa aplicable de protección
          de datos de carácter personal, más en concreto Reglamento (UE)
          2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016,
          y la Ley Orgánica 3/2018, de Protección de Datos Personales y garantía
          de los derechos digitales. Para ello ha establecido medidas técnicas y
          organizativas que garantizan la confidencialidad, integridad,
          disponibilidad, autenticidad y trazabilidad de los datos personales
          que recoge, informando al usuario que el tratamiento de sus datos
          seguirá lo siguiente:
        </Text>

        <TextTitle>
          Cómo recogemos sus datos y con qué finalidad los tratamos.
        </TextTitle>
        <Text>
          Durante el uso de la web podremos solicitarles sus datos personales
          con la finalidad de comunicarse con nosotros a través del formulario
          de contacto, siempre bajo su consentimiento expreso y libre,
          facilitándonos de esta forma sus datos de carácter personal y el uso y
          tratamiento de los mismos. Existen unos datos mínimos que cumplimentar
          Nombre, Apellidos, Correo electrónico, Organización y teléfono, siendo
          el resto de los datos que nos aporte opcionales.
        </Text>
        <Text>
          Estos datos serán incorporados al Registro de tratamiento de INDITAS,
          con el propósito de gestionar la solicitud de información. Igualmente
          le solicitaremos su consentimiento expreso, para mandarle información
          que puede ser de su interés sobre nuestros servicios y productos a
          través de los medios que nos facilite (email, teléfono). Por tanto, en
          el momento de aceptar, usted como interesado otorga su consentimiento
          de forma expresa e inequívoca para el tratamiento de sus datos
          personales con la finalidad de envió de información comercial.
        </Text>
        <Text>
          Si el usuario contrata nuestros servicios también trataremos otros
          datos de carácter personal que nos facilite en el momento de
          contratación, además de los indicados, para llevar a cabo la
          consecución del servicio contratado. Estos datos pertenecen
          principalmente a las categorías de datos identificativos y económicos.
        </Text>

        <TextTitle>Con qué legitimación tratamos sus datos</TextTitle>
        <Text>
          Los datos deben de ser tratados bajo una legitimación necesaria para
          el cumplimiento de los fines establecidos y las bases de legitimación
          con las que nosotros tratamos sus datos son las siguientes;
        </Text>
        <Options>
          <Option>
            Consentimiento expreso e interés legítimo de la empresa para
            resolver las solicitudes de información de los Usuarios
          </Option>
          <Option>
            Consentimiento expreso de los usuarios para recibir información
            comercial a través de los medios anteriormente indicados.
          </Option>
          <Option>
            Consentimiento expreso de los clientes y/o usuarios de la Empresa
            para gestionar la prestación del servicio.
          </Option>
          <Option>
            Contrato celebrado entre la Empresa y sus clientes para la
            prestación del servicio.
          </Option>
        </Options>
        <Text>
          En base al principio de minimización, le informamos que sus datos
          serán tratados de forma adecuada y pertinente, limitándose a los datos
          necesarios para el cumplimiento de los fines del tratamiento.
        </Text>

        <TextTitle>Plazo de conservación</TextTitle>
        <Text>
          En cumplimento del principio de limitación del plazo le informamos que
          el plazo de conservación de los datos será el necesario para cumplir
          con los fines del tratamiento para los que han sido recabados,
          mientras no ejerza su derecho de supresión, cancelación y/o limitación
          del tratamiento de los mismos.
        </Text>
        <Text>
          Los datos recabados para la{' '}
          <Highlight>formalización y ejecución del contrato</Highlight> serán
          conservados durante el plazo en el que dure la relación contractual,
          así como durante el periodo necesario para la formulación, ejercicio o
          defensa de reclamaciones, como mínimo cinco años.
        </Text>
        <Text>
          No obstante, por disposición legal obligatoria se puede establecer un
          plazo de conservación superior por motivos de seguridad jurídica y
          para atender a las reclamaciones de los usuarios.
        </Text>

        <TextTitle>Cesiones y destinatarios de los datos personales</TextTitle>
        <Text>
          Los destinatarios de sus datos serán aquellos que estén directamente
          relacionados con la finalidad para la que hayamos recogido sus datos,
          en concreto, realizaremos transferencia de datos con nuestros
          proveedores de servicios de infraestructura. Puede conocer más sobre
          sus políticas de privacidad de datos en{' '}
          <Link href="https://www.salesforce.com/company/privacy/">
            https://www.salesforce.com/company/privacy/
          </Link>
        </Text>
        <Text>
          El usuario será el único responsable a causa de la cumplimentación de
          los formularios con datos falsos, inexactos, incompletos o no
          actualizados.
        </Text>

        <TextTitle>Derechos del solicitante</TextTitle>
        <Text>
          Le informamos que puede retirar en cualquier momento el consentimiento
          dado para el tratamiento de sus datos. La retirada de este no implica
          la invalidez de los tratamientos realizados previamente al momento de
          retirada.
        </Text>
        <Text>
          En el supuesto de producirse alguna modificación de sus datos
          personales, le solicitamos, nos lo comunique por escrito con la
          finalidad de mantener actualizados sus datos
        </Text>
        <Text>
          El solicitante podrá ejercitar ante INDITAS, en el caso en el que
          resulten de aplicación, los derechos de acceso, rectificación y/o
          supresión, limitación de su tratamiento, oposición,{' '}
        </Text>
        <Text>
          portabilidad y a oponerse a decisiones individuales automatizadas.
          Asimismo, podrá revocar su consentimiento otorgado para alguna
          finalidad específica, pudiendo modificar sus preferencias en todo
          momento.
        </Text>
        <Text>
          El solicitante podrá ejercitar sus derechos en la dirección de correo
          electrónico:{' '}
          <Link href="mailto:contacto@inditas.com">contacto@inditas.com</Link>,
          o en el domicilio social de <PendingInfo>XXXX</PendingInfo> a través
          de escrito escaneado acompañado de un documento acreditativo de su
          identidad.
        </Text>
        <Text>
          Se informa a los solicitantes de empleo que pueden plantear
          reclamaciones a la Agencia Española de Protección de Datos{' '}
          <Link href="https://www.aepd.es/es">https://www.aepd.es/es</Link>.
        </Text>
        <Text>
          Como parte del tratamiento descrito en este documento, se toman todas
          las medidas técnicas, organizativas y de seguridad adecuadas contra
          cualquier acceso no autorizado o cualquier alteración, divulgación,
          destrucción o pérdida de sus Datos alojados en los centros de datos de
          la Unión Europea durante todo el tratamiento
        </Text>

        <TextTitle>Actualizaciones y modificaciones</TextTitle>
        <Text>
          <Highlight>INDITAS</Highlight> se reserva el derecho de modificar y/o
          actualizar la información sobre protección de datos cuando sea
          necesario para el correcto cumplimiento del Reglamento de Protección
          de Datos o de la Ley Orgánica de Protección de Datos y Garantías de
          Derechos Digitales. Si se produjera alguna modificación, el nuevo
          texto será publicado en esta página, donde podrá tener acceso a la
          política actual.
        </Text>
        <Text>
          Estas condiciones de uso se rigen por la Legislación Europea y
          Española.
        </Text>
      </Content>
    </Container>
  );
};

export default PrivacyPolicy;
