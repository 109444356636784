import { FC, memo } from 'react';
import { FieldArray, Formik } from 'formik';
import useConnect from './connect';
import { validationSchema } from './constants';
import {
  Container,
  Title,
  IconCircle,
  Icon,
  Header,
  TextField,
  HiddenTextField,
  Row,
  Buttons,
  CancelButton,
  CreateButton,
  TypesOfCashContent,
  Form,
} from './styles';

const SelectTypeOfCashModal: FC = () => {
  const { handleClose, initialValues, isOpen, handleSave } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="cashier" size={24} />
        </IconCircle>
        <Title>Tipo de efectivo</Title>
      </Header>
      <Formik
        onSubmit={handleSave}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {(formikHelpers) => (
          <Form onSubmit={formikHelpers.handleSubmit}>
            <TypesOfCashContent>
              <FieldArray
                name="customData"
                render={() =>
                  formikHelpers.values?.typesOfCash?.length > 0 &&
                  formikHelpers.values.typesOfCash.map((item, index) => {
                    return (
                      <Row key={item.key}>
                        <HiddenTextField
                          type="hidden"
                          name={`typesOfCash[${index}].key`}
                          id={`typesOfCash[${index}].key`}
                        />
                        <TextField
                          label={item.key}
                          type="number"
                          name={`typesOfCash[${index}].value`}
                          id={`typesOfCash[${index}].value`}
                          min={0}
                        />
                      </Row>
                    );
                  })
                }
              />
            </TypesOfCashContent>
            <Buttons>
              <CreateButton type="submit">Guardar</CreateButton>
              <CancelButton onClick={handleClose}>Cancelar</CancelButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default memo(SelectTypeOfCashModal);
