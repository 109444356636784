import type { FC } from 'react';
import useConnect from './connect';
import {
  Container,
  EmptyState,
  PartnersList,
  ReturnButton,
  Filters,
  ButtonContent,
} from './styles';
import Row from './Row';
import TableHead from './TableHead';

const Partners: FC = () => {
  const {
    handleClose,
    handleSelect,
    handleSearch,
    handleCreatePartner,
    isOpen,
    isRefetching,
    partners,
    filter,
    handleForceSearch,
  } = useConnect();

  const hasResults = partners?.length > 0;

  return (
    <Container open={isOpen} onClose={handleClose}>
      <Filters
        isLoading={isRefetching}
        onSearchChange={handleSearch}
        filter={filter}
        nResults={partners?.length || 0}
        onForceSearch={handleForceSearch}
      />
      {!hasResults && (
        <EmptyState
          title="No hay socios"
          iconName="users"
          buttonLabel="Crear socio"
          onClickButton={handleCreatePartner}
        >
          Aún no has creado ningún socio, crea al menos uno para mostrar la
          información
        </EmptyState>
      )}

      {hasResults && (
        <PartnersList>
          <TableHead />
          {partners.map((partner) => (
            <Row
              key={partner.id}
              partner={partner}
              onSelect={() => handleSelect(partner)}
            />
          ))}
        </PartnersList>
      )}
      <ButtonContent>
        <ReturnButton onClick={handleClose}>Volver</ReturnButton>
      </ButtonContent>
    </Container>
  );
};

export default Partners;
