import type { FC } from 'react';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import AddCreditsModal from '../../Modals/ManageCredits';
import PartnerMenuModal from '../../Modals/PartnerMenu';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { Container, EmptyState, Filters, Main, ListContent } from './styles';

const Partners: FC = () => {
  const {
    partners,
    openNewPartnerModal,
    isLoading,
    filter,
    isRefetching,
    handleSearch,
    handleOpenPartnerMenuModal,
    handleForceSearch,
  } = useConnect();

  const hasResults = partners?.length > 0;

  return (
    <>
      <Container>
        <Header title="Socios">
          <Filters
            isLoading={isRefetching}
            onSearchChange={handleSearch}
            filter={filter}
            nResults={partners?.length || 0}
            onForceSearch={handleForceSearch}
          />
        </Header>
        <Main>
          {isLoading && <Loader />}
          {!isLoading && !hasResults && (
            <EmptyState
              title="No hay socios"
              iconName="users"
              buttonLabel="Crear socio"
              onClickButton={openNewPartnerModal}
            >
              Aún no has creado ningún socio, crea al menos uno para mostrar la
              información
            </EmptyState>
          )}

          {!isLoading && hasResults && (
            <ListContent>
              <TableHead />
              {partners.map((partner) => (
                <Row
                  key={partner.id}
                  onClickActions={handleOpenPartnerMenuModal}
                  partner={partner}
                />
              ))}
            </ListContent>
          )}
        </Main>
      </Container>
      <AddCreditsModal />
      <PartnerMenuModal />
    </>
  );
};

export default Partners;
