import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { Container, Content, Name, Number, IsDeletedFlag } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, partner, openPartnerDetails, style }) => {
  const { fullName, memberNum, isDeleted } = partner;

  const handleOpenPartnerDetails = useCallback(() => {
    openPartnerDetails(partner);
  }, [openPartnerDetails, partner]);

  return (
    <Container className={className} style={style}>
      <Content onClick={handleOpenPartnerDetails}>
        <Number>{memberNum}</Number>
        <Name>
          {fullName} {isDeleted && <IsDeletedFlag>(Eliminado)</IsDeletedFlag>}
        </Name>
      </Content>
    </Container>
  );
};

export default memo(Row);
