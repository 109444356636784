import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, Icon, IconCircle, Header } from './styles';

const QuotaDetails: FC = () => {
  const { handleClose, isOpen, initialValues, handleSubmit } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="quota" />
        </IconCircle>
        <Title>Cuota Asignada '{initialValues.name}'</Title>
      </Header>
      <Form
        onSubmit={handleSubmit}
        onCancel={handleClose}
        initialValues={initialValues}
      />
    </Container>
  );
};

export default QuotaDetails;
