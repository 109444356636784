import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useOrganizationActions from 'apollo/hooks/organization/useOrganizationActions';
import useMe from 'apollo/hooks/user/useMe';
import useModal from 'apollo/hooks/useModal';
import useBookings from 'apollo/hooks/useBookings';
import useBookingActions from 'apollo/hooks/useBookingActions';
import { BookingState } from 'apollo/generated/globalTypes';

const useConnect = () => {
  const { bookings, loading: loadingGetBookings, refetch } = useBookings();
  const { toggleOrganizationOrder, loading: toggleOrganizationOrderLoading } =
    useOrganizationActions();
  const { updateBooking, loading: bookingActionsLoading } = useBookingActions();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useMe({ fetchPolicy: 'cache-first' });
  const [activeOrders, setActiveOrders] = useState(false);
  const { close, openCancelOrder } = useModal();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (data?.organization) {
      setActiveOrders(data.organization.isOrdersActive);
    }
  }, [data]);

  const handleSearch = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value;

      setIsRefetching(true);
      try {
        await refetch({ filter: newSearch });
        setFilter(newSearch);
        setIsRefetching(false);
      } catch (error) {
        setIsRefetching(false);
        enqueueSnackbar('Unable to load search', { variant: 'error' });
      }
    },
    [enqueueSnackbar, refetch],
  );

  const toggleOrdersStatus = useCallback(
    async (value: boolean) => {
      const status = value ? 'activado' : 'desactivado';
      try {
        await toggleOrganizationOrder({ value });
        setActiveOrders(value);
        enqueueSnackbar(`Se han ${status} los pedidos correctamente`, {
          variant: 'success',
        });
      } catch {
        enqueueSnackbar(
          `Ha ocurrido algún problema al ${status} de lo pedidos`,
          {
            variant: 'error',
          },
        );
      }
    },
    [toggleOrganizationOrder, enqueueSnackbar],
  );

  const activeOrdersStatus = useCallback(async () => {
    await toggleOrdersStatus(true);
  }, [toggleOrdersStatus]);

  const disableOrdersStatus = useCallback(async () => {
    await toggleOrdersStatus(false);
  }, [toggleOrdersStatus]);

  const acceptOrder = useCallback(
    async (bookingId: string) => {
      try {
        await updateBooking({
          id: bookingId,
          data: {
            state: BookingState.REVIEWED,
          },
        });
        await refetch();
        enqueueSnackbar('Pedido aceptado', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar('No se ha podido aceptar el pedido', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, refetch, updateBooking],
  );

  const completeOrder = useCallback(
    async (bookingId: string) => {
      try {
        await updateBooking({
          id: bookingId,
          data: {
            state: BookingState.CLOSED,
          },
        });
        await refetch();
        enqueueSnackbar('Pedido completado', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar('No se ha podido completar el pedido', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, refetch, updateBooking],
  );

  const cancelOrder = useCallback(
    async (reason: string, bookingId: string) => {
      await updateBooking({
        id: bookingId,
        data: {
          state: BookingState.CANCEL,
          cancelReason: reason,
        },
      });
      close();
    },
    [close, updateBooking],
  );

  const rejectOrder = useCallback(
    (bookingId: string) => {
      openCancelOrder({ onSelectReason: cancelOrder, bookingId });
    },
    [cancelOrder, openCancelOrder],
  );

  return {
    acceptOrder,
    activeOrdersStatus,
    activeOrders,
    bookings,
    completeOrder,
    disableOrdersStatus,
    isLoading:
      toggleOrganizationOrderLoading ||
      loadingGetBookings ||
      bookingActionsLoading,
    rejectOrder,
    handleSearch,
    isRefetching,
    filter,
  };
};

export default useConnect;
