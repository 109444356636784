import { FC, memo } from 'react';
import {
  Billing,
  ChevronSpace,
  Container,
  Date,
  Movements,
  Name,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Name>Producto</Name>
      <Billing>Facturación</Billing>
      <Movements>Movimiento</Movements>
      <Date>Fecha</Date>
      <ChevronSpace />
    </Container>
  );
};

export default memo(Row);
