import { FC, memo } from 'react';

import type { Props } from './types';

const Add: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g transform="translate(-4 -4)" fill="currentColor" fillRule="evenodd">
      <rect x="11" y="4" width="2" height="16" rx="1" />
      <rect
        transform="rotate(90 12 12)"
        x="11"
        y="4"
        width="2"
        height="16"
        rx="1"
      />
    </g>
  </svg>
);

export default memo(Add);
