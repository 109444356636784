import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_TEMPORARY_PARTNER } from 'apollo/queries/organizations';
import { normalizeOrganizationPartnerRequest } from 'model/Organization';
import type {
  GetTemporaryPartnerQuery,
  GetTemporaryPartnerQueryVariables,
} from 'apollo/graphql.types';

const usePartnerRequest = (id?: string) => {
  const { data, loading } = useQuery<
    GetTemporaryPartnerQuery,
    GetTemporaryPartnerQueryVariables
  >(GET_TEMPORARY_PARTNER, {
    skip: !id,
    variables: {
      id: id!,
    },
  });

  const partnerRequest = useMemo(
    () =>
      data?.getTemporaryPartner
        ? normalizeOrganizationPartnerRequest(data.getTemporaryPartner)
        : undefined,
    [data],
  );

  return {
    getPartnerRequestLoading: loading,
    partnerRequest,
  };
};

export default usePartnerRequest;
