import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  cursor: pointer;
  border-radius: 1rem;
  display: flex;
  flex-flow: column;
  padding: 1.4rem;
  transition: all 200ms ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Content = styled.div`
  max-width: 14.25rem;
  width: 14.25rem;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  margin-top: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
