import { useCallback, useState } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import useModal from 'apollo/hooks/useModal';
import { QuantityMovementType } from 'apollo/graphql.types';
import useProductsQuantityHistoryByDates from 'apollo/hooks/product/useProductsQuantityHistoryByDates';
import type { ProductQuantityHistory } from 'model/Product';

const dataTypeFilter = [
  QuantityMovementType.Stock,
  QuantityMovementType.FromStore,
];

const useConnect = () => {
  const {
    productsQuantityHistoryByDates,
    getProductsQuantityHistoryByDatesLoading,
    refetch,
  } = useProductsQuantityHistoryByDates({ dataType: dataTypeFilter });
  const { openAdjustProductDetails } = useModal();
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  });

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      setFilterDate({ from, to });
      await refetch({
        filters: {
          dates: {
            fromDate: from || null,
            toDate: to || null,
          },
          dataType: dataTypeFilter,
        },
      });
    },
    [refetch],
  );

  const handleOpenDetailsModal = useCallback(
    async (productQuantityAdjust: ProductQuantityHistory) => {
      openAdjustProductDetails({ productQuantityAdjust });
    },
    [openAdjustProductDetails],
  );

  return {
    productsQuantityHistoryByDates,
    filterDates,
    handleOnSearch,
    handleOpenDetailsModal,
    isLoading: getProductsQuantityHistoryByDatesLoading,
  };
};

export default useConnect;
