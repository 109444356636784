import { useCallback, useMemo } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { FormikConfig } from 'formik';
import type { EmployeeFormFields } from 'model/Employee';

const useConnect = () => {
  const { close, type, employeeDetailsPayload } = useModal();
  const { employee } = employeeDetailsPayload || {};

  const initialValues = useMemo<EmployeeFormFields>(
    () => ({
      avatarImageId: employee?.avatarImageId || '',
      avatarImageIsUploading: false,
      avatarImageUrl: employee?.avatarImageUrl || '',
      avatarImageSize: employee?.avatarImageSize || 0,
      permissionsGroupId: employee?.permissions?.id || '',
      document: employee?.document || '',
      email: employee?.email || '',
      firstName: employee?.firstName || '',
      lastName: employee?.lastName || '',
      address: employee?.address || '',
      phoneNumber: employee?.phoneNumber || '',
      password: '',
      isActive: employee?.isActive || false,
    }),
    [employee],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<EmployeeFormFields>['onSubmit']
  >(async (data: EmployeeFormFields) => {
    console.log(data);
  }, []);

  return {
    handleSubmit,
    handleClose,
    initialValues,
    isOpen: type === ModalType.EMPLOYEE_DETAILS,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
