import type { FC } from 'react';
import { Container } from './styles';
import type { Props } from './types';

const Link: FC<Props> = ({ className, children, ...rest }) => (
  <Container className={className} {...rest}>
    {children}
  </Container>
);

export default Link;
