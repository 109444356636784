import { gql } from '@apollo/client';
import { EVENT_FRAGMENT } from 'apollo/fragments/Event';

export const CREATE_EVENT = gql`
  mutation CreateEvent($data: OrganizationEventDto!) {
    createOrganizationEvent(data: $data) {
      ...OrganizationEventData
    }
  }
  ${EVENT_FRAGMENT}
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent(
    $organizationEventId: ID!
    $data: OrganizationEventDto!
  ) {
    updateOrganizationEvent(
      organizationEventId: $organizationEventId
      data: $data
    ) {
      ...OrganizationEventData
    }
  }
  ${EVENT_FRAGMENT}
`;

export const REMOVE_EVENT = gql`
  mutation RemoveEvent($organizationEventId: ID!) {
    removeOrganizationEvent(organizationEventId: $organizationEventId)
  }
`;
