import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, IconCircle, Icon, Header } from './styles';

const NewProvider: FC = () => {
  const { creationLoading, handleClose, handleSubmit, initialValues, isOpen } =
    useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="cart" size={24} />
        </IconCircle>
        <Title>{initialValues.id ? 'Editar' : 'Nuevo'} Proveedor</Title>
      </Header>
      <Form
        creationLoading={creationLoading}
        initialValues={initialValues}
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default NewProvider;
