import type { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  subscriptionStateVar,
  permissionsStateVar,
  userRoleVar,
} from 'apollo/reactive';
import { UserRole } from 'apollo/generated/globalTypes';
import {
  Container,
  Heading,
  Icon,
  Label,
  Link,
  Logo,
  LogoLink,
  Logout,
  Navigation,
  OrderButton,
  OrdersLink,
  TopLinks,
} from './styles';
import { Props } from './types';

const Sidebar: FC<Props> = ({ onLogout, organization }) => {
  const subscription = useReactiveVar(subscriptionStateVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const userRole = useReactiveVar(userRoleVar);

  return (
    <Container>
      <Heading>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        {organization.totalOrders > 0 && (
          <OrdersLink to="/orders">
            <OrderButton>{organization.totalOrders}</OrderButton>
          </OrdersLink>
        )}
      </Heading>
      <Navigation>
        <TopLinks>
          <Link to="/organization">
            <Icon name="organization" size={20} />
            <Label>{organization.name}</Label>
          </Link>
          <Link to="/">
            <Icon name="products" size={20} />
            <Label>Productos</Label>
          </Link>
          <Link to="/partners">
            <Icon name="users" size={20} />
            <Label>Socios</Label>
          </Link>
          {/* {employee?.allowOrdersActions && (
          <DisabledLink>
            <DisabledLabel>próximamente</DisabledLabel>
            <Icon name="orders" />
            <Label>Pedidos</Label>
          </DisabledLink>
        )} */}

          {employeePermissions?.allowShowCashier &&
            subscription.allowedCashier && (
              <Link to="/cashier">
                <Icon name="cashier" size={16} />
                <Label>Caja</Label>
              </Link>
            )}
          {employeePermissions?.allowShowMetrics &&
            subscription.allowedMetrics && (
              <Link to="/metrics">
                <Icon name="char" size={20} />
                <Label>Métricas</Label>
              </Link>
            )}
          {employeePermissions?.allowShowExpenses &&
            subscription.allowedExpenses && (
              <Link to="/expenses">
                <Icon name="expenses" size={19} />
                <Label>gastos</Label>
              </Link>
            )}
          {userRole === UserRole.ADMIN && subscription.allowedEmployees && (
            <Link to="/employees">
              <Icon name="employees" size={20} />
              <Label>Empleados</Label>
            </Link>
          )}
          {/* {employee?.allowSendCommunication && (
          <DisabledLink>
            <DisabledLabel>próximamente</DisabledLabel>
            <Icon name="event" size={20} />
            <Label>Eventos</Label>
          </DisabledLink>
        )} */}
          {employeePermissions.allowShowCalendars && (
            <Link to="/calendar">
              <Icon name="calendar" size={17} />
              <Label>Calendario</Label>
            </Link>
          )}
        </TopLinks>
        <Link to="/support">
          <Icon name="help" />
          <Label>Soporte</Label>
        </Link>
        <Logout onLogout={onLogout} />
      </Navigation>
    </Container>
  );
};

export default Sidebar;
