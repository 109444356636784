import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultFilters from 'components/Filters';
import DefaultLink from 'components/Link';

export const Container = styled.div``;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 7rem;

  ${from.tabletLandscape`
  padding-top: 2rem;
`}
`;

export const Filters = styled(DefaultFilters)`
  width: 100%;
  flex: 0 1 auto;

  ${from.tabletLandscape`
  padding: 0;
`}
`;

export const ReadonlyField = styled.div`
  border-bottom: solid 2px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-flow: row nowrap;
  padding: 1.1875rem 0;
`;

export const Label = styled.label`
  font-weight: ${({ theme }) => theme.weights.regular};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey500};
`;

export const Value = styled.div`
  margin-left: auto;
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Sections = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  flex: 1 1 auto;

  ${from.tabletLandscape`
  justify-content: unset;
`}
`;

export const Section = styled.div``;

export const Footer = styled.div`
  flex: 0 1 auto;
  padding: 4rem 1.5rem;
`;

export const Row = styled.div`
  display: block;
  ${from.tabletLandscape`
  display: flex;
  > * {
    flex: 1 1 50%;
  }
`}
`;

export const Disclaimer = styled.p`
  color: ${({ theme }) => theme.colors.grey500};
  font-weight: ${({ theme }) => theme.weights.regular};
  font-size: 0.8125rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  padding: 1.1875rem 0;
`;

export const SelectedSection = styled.div`
  margin-top: 2rem;
  padding: 0 1.5rem;
`;

export const SectionNav = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  display: flex;
  flex-flow: row;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const Link = styled(DefaultLink)`
  margin-right: 0.5rem;
  &:visited {
    color: ${({ theme }) => theme.colors.grey700};
  }
`;

export const CurrentSectionLink = styled.span`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
`;
