import styled from 'styled-components';
import DefaultProgressBar from 'components/ProgressBar';

export const Container = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.colors.grey400};
  border-radius: inherit;
`;

export const ProgressBar = styled(DefaultProgressBar)`
  flex: 1 1 auto;
`;

export const Percent = styled.p`
  flex: 0 0 3rem;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.blue900};
  font-size: 0.85rem;
  text-align: right;
  white-space: nowrap;
`;
