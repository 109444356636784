import { createTheme } from '@mui/material/styles';
import { SnackbarOrigin, CombinedClassKey } from 'notistack';
import breakpoints from './breakpoints';
import shadows from './shadows';
import typography from './typography';
import { Theme, themes } from '../themes';
import { lightVariant } from './variants';

export const materialUITheme = createTheme(
  {
    spacing: 4,
    breakpoints,
    typography,
    shadows,
    palette: lightVariant.palette,
  },
  {
    name: lightVariant.name,
    body: lightVariant.body,
    header: lightVariant.header,
    sidebar: lightVariant.sidebar,
  },
);

export const notistackConfig: {
  maxSnack: number;
  anchorOrigin: SnackbarOrigin;
  classes: Partial<Record<CombinedClassKey, string>>;
} = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  classes: {
    variantSuccess: 'in-snackbar-success',
    variantError: 'in-snackbar-error',
    variantInfo: 'in-snackbar-info',
  },
};

export default themes as { [key in keyof typeof themes]: Theme };
