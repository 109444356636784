import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import {
  Benefit,
  Container,
  Costs,
  Name,
  Orders,
  Quantity,
  RealQuantity,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, productStatistics }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className} to={`/products/${productStatistics.id}`}>
      <Name>#{productStatistics.referenceCode}</Name>
      <Benefit>
        <Price>{productStatistics.totalPurchases || 0}</Price>
      </Benefit>
      <Costs>
        <Price>{productStatistics.totalCosts || 0}</Price>
      </Costs>
      <Quantity>{productStatistics.consumedQuantity}</Quantity>
      <RealQuantity>{productStatistics.consumedExtraQuantity}</RealQuantity>
      {fromTabletPortrait && <Orders>{productStatistics.totalOrders}</Orders>}
    </Container>
  );
};

export default memo(Row);
