import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive/permissionsState';
import useModal from 'apollo/hooks/useModal';
import useCategories from 'apollo/hooks/category/useCategories';
import useCategoriesActions from 'apollo/hooks/category/useCategoriesActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { CategoryType } from 'apollo/generated/globalTypes';
import type { Category } from 'model/Category';

const useConnect = () => {
  const {
    categories,
    loading: categoriesLoading,
    refetch: refetchCategories,
  } = useCategories();
  const { removeCategory, loading: categoryActionsLoading } =
    useCategoriesActions();
  const { openDialog, openCategory, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const handleRemoveCategory = useCallback(
    async (category: Category) => {
      try {
        await removeCategory(category.id);
        await refetchCategories();
        close();
        enqueueSnackbar('La category se ha eliminado correctamente.', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('category', e.message, 'eliminar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [close, removeCategory, enqueueSnackbar, refetchCategories],
  );

  const handleOpenRemoveDiscountDialog = useCallback(
    async (category: Category) => {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar una categoría y es una acción que no se puede deshacer, ¿quieres continuar?',
        onAccept: () => handleRemoveCategory(category),
        title: 'Eliminar categoría',
        variant: 'danger',
      });
    },
    [handleRemoveCategory, openDialog],
  );

  const handleGoToDetails = useCallback(
    async (category: Category) => {
      if (category.type === CategoryType.PRODUCT) {
        navigate(`/categories/${category.id}?section=profile`);
      }
    },
    [navigate],
  );

  return {
    employeePermissions,
    categories,
    handleOpenCategoryModal: openCategory,
    handleOpenRemoveDiscountDialog,
    handleGoToDetails,
    isLoading: categoriesLoading || categoryActionsLoading,
  };
};

export default useConnect;
