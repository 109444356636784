import styled from 'styled-components';
import { Form as DefaultForm } from 'formik';
import { SectionContainer } from 'components/Layout';
import DefaultSwitch from 'components/Inputs/Switch';
import { from } from 'styles/responsive';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultButton from 'components/Button';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  position: relative;
  padding-bottom: 3.6rem;
  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const Form = styled(DefaultForm)`
  padding: 5.5rem 0 4rem 0;
  ${from.tabletLandscape`
    padding: 0;
  `}
`;

export const UrlTextField = styled(DefaultTextField)`
  width: 100%;

  &&& {
    .MuiInputBase-root {
      .MuiInputBase-input,
      .MuiInputFilledInput-input {
        padding-right: 2rem;
      }
    }
  }
`;

export const UrlGeneratorContent = styled.div`
  align-items: center;
  display: flex;
  margin-top: 1rem;
`;

export const Switch = styled(DefaultSwitch)`
  margin-top: 1rem;
  margin-bottom: 2rem;

  ${from.tabletLandscape`
      max-width: 20%;
  `}
`;

export const Row = styled.div`
  display: block;
  margin-bottom: 1rem;

  ${from.tabletLandscape`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;

export const ContentTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: 500;
  margin: 2rem 0 1rem 0;
`;

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 2rem;
`;

export const UrlButton = styled(DefaultButton).attrs(() => ({
  variant: 'light',
}))`
  &&& {
    margin-left: 1rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row;
  margin-top: 1rem;

  > * {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
