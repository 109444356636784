import { FC, memo } from 'react';
import Loader from 'components/Loader';
import { SectionTitle, Header, BackLink, HeaderContent } from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const NewPartnersRequests: FC = () => {
  const { partnersRequests, isLoading } = useConnect();

  const hasRequests = partnersRequests.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header>
        <HeaderContent>
          <BackLink to="/organization" />
          <SectionTitle>Solicitudes de nuevos socios</SectionTitle>
        </HeaderContent>
      </Header>
      <Main>
        {!hasRequests && (
          <EmptyState title="No hay solicitudes" iconName="users">
            No se han registrado nuevas solicitudes de alta
          </EmptyState>
        )}

        {hasRequests && (
          <>
            <TableHead />
            {partnersRequests.map((partnerRequest) => (
              <Row key={partnerRequest.id} partnerRequest={partnerRequest} />
            ))}
          </>
        )}
      </Main>
    </Container>
  );
};

export default memo(NewPartnersRequests);
