import type { QuotaData } from 'apollo/generated/QuotaData';

export enum QuotaType {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export const quotaTypeOptions = [
  { label: 'Anual', value: QuotaType.ANNUAL },
  { label: 'Mensual', value: QuotaType.MONTHLY },
  { label: 'Semanal', value: QuotaType.WEEKLY },
];

export type QuotaFormFields = Readonly<{
  id?: string;
  value: number;
  type: QuotaType;
  name: string;
  price: number;
}>;

export const normalizeQuota = (input: QuotaData) => ({
  id: input.id || '',
  name: input.name || '',
  price: Number(input.price) || 0,
  type: input.type,
  value: input.value || 0,
  partnersIds: input.partners?.map((partner) => partner.id) || [],
});

export type Quota = ReturnType<typeof normalizeQuota>;
