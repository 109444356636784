import { useCallback, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCategory from 'apollo/hooks/category/useCategory';
import useModal from 'apollo/hooks/useModal';
import useProducts from 'apollo/hooks/product/useProducts';
import useCategoriesActions from 'apollo/hooks/category/useCategoriesActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import { formatErrors } from 'utils/errors/formatErrors';
import { CategoryType } from 'apollo/generated/globalTypes';
import type { CategoryFormFields } from 'model/Category';

export const useConnect = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { category, loading: getCategoryLoading } = useCategory(categoryId);
  const { products } = useProducts({ fetchPolicy: 'cache-first' });
  const {
    updateCategory,
    toggleCategoryProducts,
    removeCategory,
    loading: categoryActionsLoading,
  } = useCategoriesActions();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, close: closeModal } = useModal();
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedSection, setSelectedSection] = useState('profile');

  const { initialFormValues, initialProductsValues } = useMemo(() => {
    const mappedProductsIds = removeOptionalsFromArray(
      category?.products ?? [],
    ).map((c) => c.id);

    setSelectedProducts(mappedProductsIds);

    const initialFormValues = {
      name: category?.name || '',
      type: category?.type || CategoryType.PRODUCT,
      description: category?.description || '',
    };

    return {
      initialProductsValues: mappedProductsIds,
      initialFormValues,
    };
  }, [category]);

  const handleToggleProduct = useCallback(
    async (id: string) => {
      const selected = selectedProducts.find((c) => c === id);
      if (selected) {
        setSelectedProducts((list) => list.filter((c) => c !== id));
      } else {
        setSelectedProducts((list) => [...list, id]);
      }
    },
    [selectedProducts],
  );

  const handleOnSubmit = useCallback(
    async (values: CategoryFormFields) => {
      try {
        if (categoryId) {
          await updateCategory({ id: categoryId, data: values });
          enqueueSnackbar('La categoría se ha actualizado correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('category', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, categoryId, updateCategory],
  );

  const handleOnSubmitProducts = useCallback(async () => {
    try {
      if (categoryId) {
        await toggleCategoryProducts({
          id: categoryId,
          productsIds: selectedProducts,
        });
        enqueueSnackbar('Los productos se han actualizado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('category', e.message, 'actualizar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [enqueueSnackbar, categoryId, selectedProducts, toggleCategoryProducts]);

  const handleOnDelete = useCallback(async () => {
    try {
      if (categoryId) {
        await removeCategory(categoryId);
        closeModal();
        navigate('/organization/categories');
        enqueueSnackbar('La categoría se ha eliminado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('category', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [categoryId, removeCategory, closeModal, navigate, enqueueSnackbar]);

  const handleOpenRemoveDialog = useCallback(() => {
    openDialog({
      acceptButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      description:
        'Vas a eliminar esta categoría, se borrarán todos sus datos y es una acción que no se puede deshacer, ¿quieres eliminarla?',
      onAccept: handleOnDelete,
      title: 'Eliminar categoría',
      variant: 'danger',
      iconName: 'categories',
    });
  }, [openDialog, handleOnDelete]);

  const handleSelectedSection = useCallback((section: string) => {
    setSelectedSection(section);
  }, []);

  return {
    products,
    handleOnSubmit,
    handleOnSubmitProducts,
    handleOpenRemoveDialog,
    handleSelectedSection,
    handleToggleProduct,
    initialProductsValues,
    initialFormValues,
    isLoading: getCategoryLoading || categoryActionsLoading,
    selectedSection,
    categoryId,
  };
};
