import { memo, FC } from 'react';
import Price from 'components/Price';
import {
  AddedCredits,
  Benefit,
  Container,
  Name,
  Number,
  Products,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, partner, onSelectProducts }) => {
  const {
    fullName,
    memberNum,
    products,
    totalPurchases,
    totalDiscounts,
    addedCredits,
  } = partner;

  return (
    <Container className={className}>
      <Number>{memberNum}</Number>
      <Name>{fullName}</Name>
      <Products
        $hasProducts={products.length > 0}
        onClick={() => onSelectProducts(products, totalDiscounts)}
      >
        {products.length || 0}
      </Products>
      <Benefit>
        <Price>{totalPurchases}</Price>
      </Benefit>
      <AddedCredits>
        <Price>{addedCredits}</Price>
      </AddedCredits>
    </Container>
  );
};

export default memo(Row);
