import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const Name = styled(ColumnTitle)`
  margin-left: 1rem;
  flex: 0 1 ${TABLE_METRICS.name};
`;

export const Employee = styled(ColumnTitle)`
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.employee};
`;

export const Price = styled(ColumnTitle)`
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.price};
`;

export const Quantity = styled(ColumnTitle)`
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.quantity};
`;

export const CreatedDate = styled(ColumnTitle)`
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.createdDate};
`;

export const IconsSpace = styled.div`
  text-align: right;
  flex: 0 0 ${TABLE_METRICS.chevron};
`;
