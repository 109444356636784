export const TABLE_METRICS = {
  name: '15%',
  quantity: '10%',
  discounts: '15%',
  purchases: '15%',
  costs: '15%',
  benefit: '15%',
  remainingQuantity: '15%',
};

export const TABLE_MOBILE_METRICS = {
  name: '25%',
  discounts: '20%',
  purchases: '18%',
  costs: '18%',
  benefit: '18%',
};
