import { FC, memo } from 'react';
import { Formik } from 'formik';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  Row,
  TextField,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const Form: FC<Props> = ({
  className,
  onSubmit,
  onCancel,
  initialValues,
  isLoading,
  id = 'new',
}) => {
  const formId = `product_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <Row>
            <TextField
              placeholder="5.75"
              type="number"
              name="price"
              label="Precio (venta) *"
              id={`${formId}_price`}
            />
            <TextField
              placeholder="3.5"
              type="number"
              name="costs"
              label="Precio (compra)"
              id={`${formId}_costs`}
            />
          </Row>
          <Row>
            <TextField
              placeholder="200"
              type="number"
              name="baseWeight"
              label="Peso base"
              id={`${formId}_baseWeight`}
            />
            <TextField
              placeholder="870"
              type="number"
              name="quantity"
              label="Stock *"
              id={`${formId}_quantity`}
            />
          </Row>
          <Row>
            <TextField
              name="referenceCode"
              label="Referencia"
              id={`${formId}_referenceCode`}
            />
          </Row>

          <Buttons>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || !isFormValid}
            >
              Crear
            </CreateButton>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(Form);
