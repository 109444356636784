import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 1rem 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  margin-top: 2rem;
  ${from.tabletLandscape`
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
`}
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey500};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${TextEllipsis};
`;

export const State = styled(ColumnTitle)`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.state};
`;

export const PartnerNum = styled(ColumnTitle)`
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 1 ${TABLE_METRICS.partnerNum};
  ${TextEllipsis};
`;

export const Identifier = styled(ColumnTitle)`
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.identifier};
  ${TextEllipsis};
`;

export const Balance = styled(ColumnTitle)`
  margin-left: auto;
  margin-right: 1rem;
  text-align: right;
  flex: 0 0 ${TABLE_METRICS.balance};
  ${TextEllipsis};
`;
