import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import notEmpty from 'utils/notEmpty';
import { permissionsStateVar, subscriptionStateVar } from 'apollo/reactive';
import { GET_EXPENSES } from 'apollo/queries/expenses';
import type { GetExpenses } from 'apollo/generated/GetExpenses';
import { normalizeExpense } from 'model/Expense';

const useExpenses = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const subscription = useReactiveVar(subscriptionStateVar);

  const { loading, data, refetch } = useQuery<GetExpenses>(GET_EXPENSES, {
    skip:
      !subscription.allowedExpenses || !employeePermissions.allowShowExpenses,
  });

  const { expenses } = useMemo(
    () => ({
      expenses: data?.expenses
        ? data.expenses.filter(notEmpty).map(normalizeExpense)
        : [],
    }),
    [data],
  );

  return {
    loading,
    expenses,
    refetch,
  };
};

export default useExpenses;
