import { FC, memo } from 'react';

import type { Props } from './types';

const Categories: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    xmlns="http://www.w3.org/2000/svg"
    id="icon"
    viewBox="0 0 32 32"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    {...rest}
  >
    <defs xmlns="http://www.w3.org/2000/svg" />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M21 10h-4V6h-2v4h-4v2h4v4h2v-4h4v-2z"
      fill="currentColor"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M28 24H17v-4h6a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2H9a2.002 2.002 0 0 0-2 2v14a2.002 2.002 0 0 0 2 2h6v4H4a2 2 0 0 0-2 2v4h2v-4h11v4h2v-4h11v4h2v-4a2 2 0 0 0-2-2zM9 4h14l.002 14H9z"
      fill="currentColor"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      id="_Transparent_Rectangle_"
      d="M0 0h32v32H0z"
      fill="none"
    />
  </svg>
);

export default memo(Categories);
