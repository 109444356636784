import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { subscriptionStateVar, permissionsStateVar } from 'apollo/reactive';
import { PartnerSections } from '../constants';
import type { Props } from './types';

type Section = {
  key: string;
  value: string;
};

const useLogic = ({ onSelectedSection, partnerId }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const subscription = useReactiveVar(subscriptionStateVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const PartnerSectionsMap = useMemo(
    () => [
      {
        key: PartnerSections.PROFILE,
        value: 'Perfil',
        isAllow: true,
      },
      {
        key: PartnerSections.TRANSACTIONS,
        value: 'Transacciones',
        isAllow: subscription.allowedPartnerTransactions,
      },
      {
        key: PartnerSections.FILES,
        value: 'Archivos',
        isAllow: employeePermissions.allowShowFiles,
      },
      {
        key: PartnerSections.PARTNERS,
        value: 'Socios Avalados',
        isAllow: true,
      },
      {
        key: PartnerSections.NOTES,
        value: 'Notas',
        isAllow: true,
      },
      {
        key: PartnerSections.REGISTERS,
        value: 'Registros',
        isAllow: true,
      },
    ],
    [employeePermissions, subscription],
  );

  useEffect(() => {
    const queryStringSection = searchParams.get('section');
    const sectionData = PartnerSectionsMap.find(
      (section) => section.key === queryStringSection,
    );
    if (sectionData) {
      setSelectedSection(sectionData);
    }
  }, [PartnerSectionsMap, onSelectedSection, searchParams]);

  useEffect(() => {
    if (selectedSection) {
      onSelectedSection(selectedSection.key);
    }
  }, [onSelectedSection, selectedSection]);

  const handleToggleSection = useCallback(
    (section: Section) => {
      setSelectedSection(section);
      if (partnerId) {
        navigate(`/partners/${partnerId}?section=${section.key}`);
      }
    },
    [navigate, partnerId],
  );

  return { handleToggleSection, selectedSection, sections: PartnerSectionsMap };
};

export default useLogic;
