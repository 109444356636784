import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useProviders from 'apollo/hooks/provider/useProviders';
import useProviderActions from 'apollo/hooks/provider/useProviderActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { ProviderFormFields } from 'model/Provider';

const useConnect = () => {
  const { close, type, newProviderPayload } = useModal();
  const { refetch } = useProviders();
  const {
    createProvider,
    updateProvider,
    loading: createProviderLoading,
  } = useProviderActions();
  const { enqueueSnackbar } = useSnackbar();

  const { provider } = newProviderPayload || {};

  const initialValues = useMemo<ProviderFormFields>(
    () => ({
      id: provider?.id || '',
      description: provider?.description || '',
      name: provider?.name || '',
    }),
    [provider],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<ProviderFormFields>['onSubmit']
  >(
    async (data) => {
      try {
        if (provider?.id) {
          await updateProvider(provider.id, data);
        } else {
          await createProvider(data);
        }
        enqueueSnackbar(
          `El proveedor ${data.name} ha sido ${
            provider?.id ? 'actualizado' : 'creado'
          } correctamente`,
          {
            variant: 'success',
          },
        );
        await refetch();
        handleClose();
      } catch (e) {
        enqueueSnackbar(
          formatErrors(
            'provider',
            e.message,
            provider?.id ? 'actualizar' : 'crear',
          ),
          {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          },
        );
      }
    },
    [
      provider,
      enqueueSnackbar,
      refetch,
      handleClose,
      updateProvider,
      createProvider,
    ],
  );

  return {
    handleClose,
    handleSubmit,
    initialValues,
    isOpen: type === ModalType.NEW_PROVIDER,
    creationLoading: createProviderLoading,
  };
};

export default useConnect;
