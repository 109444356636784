import notNullable from 'utils/notNullable';

const configVar = (variable: string = '', optIn = true) =>
  variable ? JSON.parse(variable) : !optIn;

export const ApiConfig = {
  API: notNullable(process.env.REACT_APP_API),
  API_SECRET: notNullable(process.env.REACT_APP_API_SECRET),
};

export const AppConfig = {
  appName: 'Inditas',
  publicUrl: notNullable(process.env.PUBLIC_URL),
  supportEmail: 'support@inditas.com',
};

export const DevConfig = {
  debugSection: configVar(process.env.REACT_APP_DEBUG_SECTION),
};

export const isProd = process.env.NODE_ENV === 'production';

export const APP_BUILD = process.env.REACT_APP_BUILD || 'development';
export const isDevelopment = APP_BUILD === 'development';
export const isProduction = APP_BUILD === 'production';
export const isLocal = process.env.NODE_ENV !== 'production';

export const S3Url = notNullable(process.env.REACT_APP_S3_URL);
