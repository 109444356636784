import { FC, memo } from 'react';
import FileInput from 'components/Inputs/FileInput';
import { Content, Document, DocumentTitle } from './styles';
import { SectionContainer, SectionTitle } from '../styles';
import type { Props } from './types';

const DocumentsForm: FC<Props> = ({
  handleOnGetFile,
  selectedDocument1,
  selectedSelfie,
  selectedDocument2,
}) => {
  return (
    <SectionContainer>
      <SectionTitle>Subida de Documentos</SectionTitle>
      <Content>
        <Document>
          <DocumentTitle>Selfie</DocumentTitle>
          <FileInput
            buttonText="seleccionar"
            selectedFileName={selectedSelfie?.name}
            onGetFile={(file) => handleOnGetFile(file, 'selfie')}
            acceptedFiles="image/*"
          />
        </Document>
        <Document>
          <DocumentTitle>DNI Delantero</DocumentTitle>
          <FileInput
            buttonText="seleccionar"
            selectedFileName={selectedDocument1?.name}
            onGetFile={(file) => handleOnGetFile(file, 'document1')}
            acceptedFiles="image/*, application/pdf"
          />
        </Document>
        <Document>
          <DocumentTitle>DNI Trasero</DocumentTitle>
          <FileInput
            buttonText="seleccionar"
            selectedFileName={selectedDocument2?.name}
            onGetFile={(file) => handleOnGetFile(file, 'document2')}
            acceptedFiles="image/*, application/pdf"
          />
        </Document>
      </Content>
    </SectionContainer>
  );
};

export default memo(DocumentsForm);
