import { gql } from '@apollo/client';
import { QUOTA_FRAGMENT } from 'apollo/fragments/Quota';

export const CREATE_QUOTA = gql`
  mutation CreateQuota($data: CreateQuotaDto!) {
    createQuota(data: $data) {
      ...QuotaData
    }
  }
  ${QUOTA_FRAGMENT}
`;

export const UPDATE_QUOTA = gql`
  mutation UpdateQuota($id: ID!, $data: UpdateQuotaDto!) {
    updateQuota(id: $id, data: $data) {
      ...QuotaData
    }
  }
  ${QUOTA_FRAGMENT}
`;

export const REMOVE_QUOTA = gql`
  mutation RemoveQuota($id: ID!) {
    removeQuota(id: $id)
  }
`;
