import { FC, memo } from 'react';
import {
  Billing,
  ChevronSpace,
  Container,
  Name,
  Position,
  Sales,
  State,
} from './styles';
import type { Props } from './types';

const TableHead: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <State>Estado</State>
      <Position>Posición</Position>
      <Name>Producto</Name>
      <Billing>Facturación</Billing>
      <Sales>Ventas</Sales>
      <ChevronSpace />
    </Container>
  );
};

export default memo(TableHead);
