import styled from 'styled-components';

export const Image = styled.img`
  border-radius: 50%;
  display: block;
  height: 6rem;
  width: 6rem;
`;

export const Initials = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.orangePale};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.orange};
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  justify-content: center;
  height: 6rem;
  width: 6rem;
`;
