import type { FC } from 'react';
import Loader from 'components/Loader';
import Form from './Form';
import { useConnect } from './connect';
import { Container, FormContainer } from './styles';

const ExpenseDetail: FC = () => {
  const {
    getSelectedCategories,
    initialValues,
    isLoading,
    handleOnSubmit,
    handleOnDelete,
  } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <FormContainer>
        <Form
          getSelectedCategories={getSelectedCategories}
          initialValues={initialValues}
          onChange={handleOnSubmit}
          onDelete={handleOnDelete}
        />
      </FormContainer>
    </Container>
  );
};

export default ExpenseDetail;
