import { useMemo } from 'react';
import { QueryHookOptions, useQuery, useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import { GET_PRODUCTS_GROUPED_BY_NAME } from 'apollo/queries';
import { normalizeProductByName } from 'model/Product';
import notEmpty from 'utils/notEmpty';
import type {
  GetProductsGroupedByNameQuery,
  GetProductsGroupedByNameQueryVariables,
} from 'apollo/graphql.types';

const useProductsGroupedByName = ({
  filters,
  fetchPolicy = 'cache-and-network',
}: {
  filters?: { from?: Date; to?: Date };
  fetchPolicy?: QueryHookOptions<GetProductsGroupedByNameQueryVariables>['fetchPolicy'];
} = {}) => {
  const { activateProductReference } = useReactiveVar(organizationConfigVar);

  const { loading, data, refetch } = useQuery<
    GetProductsGroupedByNameQuery,
    GetProductsGroupedByNameQueryVariables
  >(GET_PRODUCTS_GROUPED_BY_NAME, {
    fetchPolicy,
    skip: !activateProductReference,
    variables: {
      filters: filters
        ? {
            fromDate: filters.from || null,
            toDate: filters.to || null,
          }
        : null,
    },
  });

  const groupedProducts = useMemo(
    () =>
      data?.productsGroupedByName
        ? data.productsGroupedByName
            .filter(notEmpty)
            .map(normalizeProductByName)
        : [],

    [data],
  );

  return {
    loading,
    groupedProducts,
    refetch,
  };
};

export default useProductsGroupedByName;
