import { gql } from '@apollo/client';
import { EVENT_FRAGMENT } from 'apollo/fragments/Event';

export const GET_EVENTS = gql`
  query GetEvents {
    organizationEvents {
      ...OrganizationEventData
    }
  }

  ${EVENT_FRAGMENT}
`;
