import type { FC } from 'react';
import {
  Container,
  Logo,
  Title,
  Header,
  Content,
  Subtitle,
  Text,
  Highlight,
  Link,
  TextTitle,
  Options,
  Option,
  PendingInfo,
} from './styles';

const LegalNotice: FC = () => {
  return (
    <Container>
      <Header>
        <Logo abilityNavigation />
      </Header>
      <Content>
        <Title>AVISO LEGAL</Title>
        <Subtitle>Última actualización 20 de Mayo de 2024</Subtitle>
        <Text>
          En cumplimiento del deber de información estipulado en el artículo 10
          de la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la
          Información y de Comercio electrónico (En adelante, LSSI-CE), el
          presente aviso legal tiene por función regular el uso del sitio web{' '}
          <Link href="www.inditas.com">www.inditas.com</Link> siendo el titular:
        </Text>
        <Text>
          a) Denominación Social: <Highlight>INDITAS</Highlight>
        </Text>
        <Text>
          b) Domicilio Social: <PendingInfo>XXX</PendingInfo>
        </Text>
        <Text>
          c) NIF: <PendingInfo>XXX</PendingInfo>
        </Text>
        <Text>
          d) Teléfono: <PendingInfo>XXX</PendingInfo>
        </Text>
        <Text>
          e) Datos de inscripción en el Registro Mercantil{' '}
          <PendingInfo>XXX</PendingInfo>
        </Text>
        <Text>
          La dirección de correo electrónico de contacto para cuestiones
          referidas a la protección de datos de carácter personal y su
          privacidad es{' '}
          <Link href="mailto:contacto@inditas.com">contacto@inditas.com</Link>.
        </Text>
        <Text>
          El sitio web está dirigido a cualquier persona (en lo sucesivo, el
          “Usuario”) que desee consultar los servicios ofrecidos por la Empresa,
          así como lo relativo a información médica y sobre nuestros centros y
          profesionales.
        </Text>
        <Text>
          Por tanto, para un correcto uso de la web les recomendamos que lean
          con detenimiento las mencionadas cláusulas siempre que desee acceder y
          hacer uso de la información y de los servicios ofrecidos en el sitio
          web.
        </Text>

        <TextTitle>Compromiso del Usuario</TextTitle>
        <Text>
          Mediante el acceso a este Sitio Web, el Usuario acepta expresamente
          todas las cláusulas de la presente información legal, de su{' '}
          <Highlight>política de privacidad</Highlight>, y{' '}
          <Highlight>su política de cookies</Highlight>, así como de todas
          aquellas condiciones particulares recogidas para la utilización de
          determinados servicios. En caso de no aceptar alguna de las citadas
          cláusulas, el Usuario deberá abstenerse de acceder a este Sitio Web.
        </Text>
        <Text>
          El usuario se compromete expresamente al uso correcto de la web,
          absteniéndose de usar el presente sitio web de forma que pueda
          impedir, dañar o deteriorar el normal funcionamiento de la web y de
          los bienes y servicios de Inditas y del resto de usuarios. Queda
          prohibido lo siguiente, sin ser limitativo:
        </Text>
        <Text>
          - Difundir contenidos violentos, delictivos, pornográficos, racistas,
          en general, contrarios a la ley.
        </Text>
        <Text>
          - Introducir elementos de malware o realizar actuaciones que puedan
          suponer una alteración, interrupción y/o daños y errores en los datos
          y/o archivos propiedad de la empresa o terceras personas; Así como la
          obstaculización al acceso de otros usuarios al sitio web y a sus
          servicios
        </Text>
        <Text>
          - Intentos de acceso a los datos de otros usuarios, incluidos datos de
          acceso para acceder a zonas restringidas
        </Text>
        <Text>
          - Vulneración de los derechos de propiedad intelectual, así como
          violar la confidencialidad de la información de la prestadora o de
          terceros.
        </Text>
        <Text>- Suplantar la identidad de terceras personas.</Text>
        <Text>
          - Recabar datos con finalidad publicitaria y de remitir publicidad de
          cualquier clase y comunicaciones con fines de venta u otras de
          naturaleza comercial sin que medie previo consentimiento
        </Text>
        <Text>
          El usuario se compromete a tomar todas las medidas pertinentes para
          asegurar la confidencialidad y la conservación de su código de acceso
          y no se lo comunicará a nadie.
        </Text>
        <Text>
          Toda operación efectuada en el Sitio web con las claves de acceso del
          usuario se estimará realizada en nombre de este bajo su
          responsabilidad. La empresa o cualquier otra empresa del Grupo usuaria
          del Sitio web no podrá ser considerada responsable de un uso
          fraudulento del código de acceso.
        </Text>

        <TextTitle>Compromiso de la empresa</TextTitle>
        <Text>
          El Sitio web está destinado a poner en conocimiento de los usuarios la
          información relativa a los servicios ofrecidos por la empresa.
          Igualmente, a través de la web se facilita al Usuario medios para
          ponerse en comunicación con la Empresa
        </Text>
        <Text>
          <Highlight>* Accesibilidad a los servicios del sitio web</Highlight>
        </Text>
        <Text>
          La empresa se compromete a instaurar todos los medios de que dispone
          con el fin de ofrecer los mejores servicios al usuario y a facilitar
          su acceso.
        </Text>
        <Text>
          Para ello, la empresa se esforzará por mantener el Sitio web accesible
          las 24 horas del día, 365 días al año. No obstante, el acceso se puede
          ver interrumpido por iniciativa de la empresa o de sus proveedores
          encargados de la aplicación y el alojamiento del Sitio web por motivos
          de mantenimiento o por cualquier otra razón técnica. En estos casos
          específicos de interrupción, la empresa velará por informar al
          respecto previamente al usuario y por limitar la duración de dicha
          interrupción.
        </Text>
        <Text>
          La empresa se reserva igualmente el derecho a interrumpir el acceso a
          los servicios, y excluye y limita su responsabilidad, siempre dentro
          de los límites permitidos en el ordenamiento jurídico vigente,
          cualquier tipo de responsabilidad por daños y perjuicios de toda
          naturaleza derivados de:
        </Text>
        <Text>
          - Los daños y perjuicios causados por el mal uso que el Usuario pueda
          realizar de su web.
        </Text>
        <Text>
          - Las alteraciones en el servicio que se produzcan por fallos en la
          red eléctrica, en la red de conexión de datos, en el servidor o en
          cualquiera de las prestaciones, además de los fallos en caso de
          ataques provocados denegación de servicio, malware, etc. y que puedan
          causar daños en los sistemas informáticos, en los documentos
          electrónicos, o en los ficheros de los Usuarios de su web.
        </Text>
        <Text>
          - Las actuaciones de terceros que vulneren derechos de propiedad
          intelectual e industrial, así como la normativa en materia de
          competencia desleal y publicidad ilícita.
        </Text>
        <Text>
          - La imposibilidad de acceso al sitio web o la falta de veracidad,
          exactitud, exhaustividad y/o actualidad de los contenidos, así como la
          existencia de vicios y defectos de toda clase de los contenidos
          transmitidos, difundidos, almacenados, puestos a disposición, a los
          que se haya accedido a través del sitio web o de los servicios que se
          ofrecen.
        </Text>
        <Text>
          - La recepción por parte de la empresa de una solicitud de suspensión
          o de restricción de acceso al Sitio web formulada por una autoridad
          administrativa o judicial o por el requerimiento de un tercero
        </Text>
        <Text>
          <Highlight>* Seguridad y confidencialidad de los datos</Highlight>
        </Text>
        <Text>
          La Empresa cumple con las exigencias establecidas por la normativa en
          protección de datos, comprometiéndose, en particular, a garantizar la
          seguridad de los datos, manteniendo la confidencialidad, integridad y
          disponibilidad de los mismos, así como a no utilizarlos para fines
          distintos de los previstos en la política de privacidad.
        </Text>

        <TextTitle>Copyright y propiedad intelectual</TextTitle>
        <Text>
          Los derechos de propiedad intelectual del contenido de la página web,
          su diseño gráfico y códigos son de <Highlight>INDITAS</Highlight> en
          exclusiva o cuenta con los oportunos derechos y/o autorizaciones para
          su publicación en la Página y, por tanto, queda prohibida su
          reproducción, distribución, comunicación pública, transformación o
          cualquier otra actividad que se pueda realizar con los contenidos de
          sus páginas web ni aún citando las fuentes, salvo consentimiento por
          escrito de INDITAS o del titular en exclusiva del derecho.
        </Text>
        <Text>
          Queda también terminantemente prohibido recopilar y utilizar la
          información disponible en el Sitio web con fines comerciales.
        </Text>
        <Text>
          Esta prohibición se extiende en especial, a título meramente
          ilustrativo, a todo elemento de redacción presente en el Sitio web,
          presentación de las pantallas, programas informáticos necesarios para
          la navegación, logos, imágenes, fotos y gráficos de cualquier índole.
        </Text>

        <TextTitle>Política de Privacidad</TextTitle>
        <Text>
          De acuerdo con la normativa vigente en materia de Protección de Datos
          de Carácter Personal, le informamos que los datos personales aportados
          serán tratados por <Highlight>INDITAS</Highlight>.
        </Text>
        <Text>
          Los datos aportados por usted en la web son necesarios para poder
          responder de forma correcta a su solicitud de información, y serán
          tratados para ponernos en contacto con Ud. lo antes posible.
          Igualmente, si así lo consciente, usaremos sus datos para el envío de
          publicidad comercial de nuestros productos.
        </Text>
        <Text>
          Puede consultar más detalles sobre el tratamiento y la protección de
          datos en nuestra política de privacidad.
        </Text>

        <TextTitle>Utilización de Hiperenlaces</TextTitle>
        <Text>
          Aquellos Usuarios que quieran enlazar la presente web desde sus
          propios sitios web deberán cumplir con las siguientes condiciones sin
          que el desconocimiento de las mismas evite las responsabilidades
          derivadas de la Ley.
        </Text>
        <Text>
          El enlace únicamente se vinculará con la homepage o página principal
          de la página web, pero no podrá reproducirla de ninguna forma. Quedará
          en todo caso prohibido, establecer marcos de cualquier tipo que
          envuelvan a la página web o permitan la visualización de los
          contenidos a través de direcciones de Internet distintas a las del
          sitio web y, en cualquier caso, cuando se visualicen juntamente con
          contenidos ajenos al sitio web de forma que:
        </Text>
        <Options>
          <Option>
            Produzca, o pueda producir, error, confusión o engaño en los
            usuarios sobre la verdadera procedencia del servicio o Contenidos;
          </Option>
          <Option>Suponga un acto de comparación o imitación desleal;</Option>
          <Option>
            Sirva para aprovechar la reputación de la marca y prestigio de
            INDITAS
          </Option>
        </Options>
        <Text>
          De cualquier otra forma que resulte prohibido por la legislación
          vigente.
        </Text>
        <Text>
          No se realizará desde la página que introduce el enlace ningún tipo de
          manifestación falsa, inexacta o incorrecta sobre la prestadora, su
          dirección, empleados, clientes o sobre la calidad de los servicios que
          suministra.
        </Text>
        <Text>
          En ningún caso, se expresará en la página donde se ubique el enlace
          que la prestadora ha facilitado su consentimiento para la inserción
          del enlace o que de otra forma patrocina, colabora, verifica o
          supervisa los servicios del remitente.
        </Text>
        <Text>
          Está prohibida la utilización de cualquier marca denominativa, gráfica
          o mixta o cualquier otro signo distintivo de la prestadora dentro de
          la página del remitente salvo en los casos permitidos por la ley o
          expresamente autorizados por la prestadora y siempre que se permita,
          en estos casos, un enlace directo con la página Web en la forma
          establecida en esta cláusula.
        </Text>
        <Text>
          La página que establezca el enlace deberá cumplir fielmente con la ley
          y no podrá en ningún caso disponer o enlazar con contenidos propios o
          de terceros que:
        </Text>
        <Options>
          <Option>
            Sean ilícitos, nocivos o contrarios a la moral y a las buenas
            costumbres (pornográficos, violentos, racistas, etc.);
          </Option>
          <Option>
            Induzcan o puedan inducir en el Usuario la falsa concepción de que
            la prestadora suscribe, respalda, se adhiere o de cualquier manera
            apoya, las ideas, manifestaciones o expresiones, lícitas o ilícitas,
            del remitente;
          </Option>
          <Option>
            Resulten inapropiados o no pertinentes con la actividad de la
            prestadora en atención al lugar, contenidos y temática de la página
            web del remitente. Igualmente, el Usuario se abstendrá de incluir en
            la página Web cualquier hiperenlace (en adelante, “link”) dirigido a
            una página web que contenga información o contenidos ilícitos,
            contrarios a la moral y buenas costumbres generalmente aceptadas, y
            al orden público.
          </Option>
        </Options>

        <TextTitle>Fuero</TextTitle>
        <Text>
          El presente Aviso Legal se encuentra sometido a la legislación
          Española vigente. Para cualquier tipo de controversia derivada de la
          utilización de los servicios ofrecidos o de los contenidos propios de
          la Página las partes, con la aceptación del clausulado establecido en
          el presente Aviso Legal se someterán a los Tribunales y Juzgados
          competentes de Sevilla.
        </Text>
        <Text>
          Si alguna de las cláusulas descritas en el Aviso Legal fuese declarada
          nula y sin efecto, continuarán siendo de aplicación las restantes, sin
          que deban quedar afectadas por la declaración de nulidad.
        </Text>
      </Content>
    </Container>
  );
};

export default LegalNotice;
