import styled from 'styled-components';
import DefaultButton from 'components/Button';
import DefaultIcon from 'components/Icon';

export const Icon = styled(DefaultIcon)`
  margin-top: 3px;
`;

export const Button = styled(DefaultButton)`
  &&& {
    max-width: 13rem;
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ErrorMsg = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
  text-align: left;
`;

export const InputContent = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
  margin-top: 1rem;
`;

export const FileName = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 16px;
  font-weight: 400;
  margin-left: 16px;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TrashIcon = styled(DefaultIcon).attrs({
  name: 'trash',
  size: 16,
})`
  color: ${({ theme }) => theme.colors.grey700};
  cursor: pointer;
  margin-left: 8px;
  transition: color 200ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.danger};
  }
`;
