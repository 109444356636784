import usePreviousBookings from 'apollo/hooks/usePreviousBookings';
import { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const useConnect = () => {
  const { previousBookings, loading, refetch } = usePreviousBookings();
  const navigate = useNavigate();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleSearch = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value;

      setIsRefetching(true);
      try {
        await refetch({ filter: newSearch });
        setFilter(newSearch);
        setIsRefetching(false);
      } catch (error) {
        setIsRefetching(false);
        enqueueSnackbar('Unable to load search', { variant: 'error' });
      }
    },
    [enqueueSnackbar, refetch],
  );

  const handleReturn = useCallback(() => {
    setIsRefetching(false);
    setFilter('');
    navigate('/orders');
  }, [navigate]);

  return {
    isLoading: loading,
    previousBookings,
    handleReturn,
    handleSearch,
    isRefetching,
    filter,
  };
};

export default useConnect;
