import { useQuery } from '@apollo/client';
import { GET_CATEGORY } from 'apollo/queries/categories';
import { useMemo } from 'react';
import { normalizeCategory } from 'model/Category';
import type {
  GetCategoryQuery,
  GetCategoryQueryVariables,
} from 'apollo/graphql.types';

const useCategories = (id?: string) => {
  const { loading, data } = useQuery<
    GetCategoryQuery,
    GetCategoryQueryVariables
  >(GET_CATEGORY, { skip: !id, variables: { id: id! } });

  const category = useMemo(
    () => (data?.category ? normalizeCategory(data.category) : undefined),
    [data],
  );

  return {
    loading,
    category,
  };
};

export default useCategories;
