import { FC, memo, useCallback } from 'react';
import { applyDiscount } from 'utils/discounts';
import {
  Container,
  DoneIcon,
  InfoProduct,
  Label,
  Price,
  Thumbnail,
  Image,
  ImageContainer,
  ReferenceCode,
  OriginalPrice,
  PriceContent,
} from './styles';
import type { Props } from './types';

const LandscapeFormat: FC<Props> = ({
  isSelected,
  isEditingMode,
  isReverse,
  onClick,
  product,
  activateProductReference,
}) => {
  const { abbreviation, id, name, price, discount } = product;
  const originalPrice = price;
  const finalPrice = !discount
    ? originalPrice
    : applyDiscount({
        discount,
        price: Number(originalPrice),
      });

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(id);
    }
  }, [id, onClick]);

  return (
    <Container
      $isSelected={isSelected}
      $isEditingMode={isEditingMode}
      $isReverse={isReverse}
      onClick={handleClick}
    >
      {!product.mainImageUrl ? (
        <Thumbnail>{isSelected ? <DoneIcon /> : abbreviation}</Thumbnail>
      ) : (
        <ImageContainer>
          <Image src={product.mainImageUrl} />
        </ImageContainer>
      )}

      <InfoProduct>
        <Label>
          {name} ({product.quantity.toFixed(2)})
        </Label>
        {activateProductReference && product.referenceCode && (
          <ReferenceCode>#{product.referenceCode}</ReferenceCode>
        )}
        <PriceContent>
          {discount && (
            <OriginalPrice>
              <Price>{originalPrice}</Price>
            </OriginalPrice>
          )}{' '}
          <Price>{finalPrice}</Price>
        </PriceContent>
      </InfoProduct>
    </Container>
  );
};

export default memo(LandscapeFormat);
