import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { normalizePartner } from 'model/Partner';
import { normalizePartnerTransaction } from 'model/Transaction';
import { GET_PARTNER_TRANSACTIONS } from 'apollo/queries/partners';
import type {
  GetPartnerTransactionsQuery,
  GetPartnerTransactionsQueryVariables,
} from 'apollo/graphql.types';

const usePartnerTransactions = ({
  id,
  filters,
}: {
  id?: string;
  filters?: { from?: Date; to?: Date };
}) => {
  const { loading, data, refetch } = useQuery<
    GetPartnerTransactionsQuery,
    GetPartnerTransactionsQueryVariables
  >(GET_PARTNER_TRANSACTIONS, {
    skip: !id,
    variables: {
      id: id!,
      filters: filters
        ? {
            dates: {
              fromDate: filters.from || null,
              toDate: filters.to || null,
            },
          }
        : null,
    },
  });

  const { partner, transactions } = useMemo(
    () => ({
      partner: data?.partnerTransactions.partner
        ? // @ts-ignore
          normalizePartner(data.partnerTransactions.partner)
        : null,
      transactions: data?.partnerTransactions.transactions
        ? // @ts-ignore
          normalizePartnerTransaction(data.partnerTransactions.transactions)
        : [],
    }),
    [data],
  );

  return {
    loading,
    partner,
    refetch,
    transactions,
  };
};

export default usePartnerTransactions;
