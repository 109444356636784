import { FC } from 'react';
import Loader from 'components/Loader';
import CancelOrderModal from '../../Modals/CancelOrder';
import BookingButtons from './BookingButtons';
import {
  Container,
  Main,
  OrderCard,
  OrdersContainer,
  Header,
  EmptyState,
  Filters,
} from './styles';
import useConnect from './connect';

const BookingsView: FC = () => {
  const {
    acceptOrder,
    completeOrder,
    activeOrders,
    activeOrdersStatus,
    bookings,
    disableOrdersStatus,
    isLoading,
    isRefetching,
    rejectOrder,
    filter,
    handleSearch,
  } = useConnect();

  const title =
    bookings?.length > 0 ? `${bookings.length} Pedidos pendientes` : 'Pedidos';

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <Header
          title={title}
          buttonText="Desactivar pedidos"
          buttonVariant="secondary"
          onClickButton={disableOrdersStatus}
        >
          <Filters
            isLoading={isRefetching}
            onSearchChange={handleSearch}
            filter={filter}
            nResults={bookings?.length || 0}
          />
        </Header>
        <Main>
          {activeOrders ? (
            <>
              <OrdersContainer>
                {bookings &&
                  bookings.length > 0 &&
                  bookings.map((booking: any) => (
                    <OrderCard
                      key={booking.id}
                      partnerNum={booking.partnerNum}
                      items={booking.orders}
                      total={booking.total}
                      approveOrder={() => acceptOrder(booking.id)}
                      completeOrder={() => completeOrder(booking.id)}
                      rejectOrder={() => rejectOrder(booking.id)}
                      isReviewed={booking.state === 'REVIEWED'}
                    />
                  ))}
              </OrdersContainer>
              {(!bookings || bookings.length === 0) && (
                <EmptyState title="No hay pedidos" iconName="orders">
                  No hay ningún pedido pendiente
                </EmptyState>
              )}
              <BookingButtons disableOrders={disableOrdersStatus} />
            </>
          ) : (
            <EmptyState
              title="Pedidos desactivados"
              iconName="orders"
              onClickButton={activeOrdersStatus}
              buttonLabel="Activar pedidos"
            >
              Si los activas, tus clientes podrán hacer pedidos desde cualquier
              lugar
            </EmptyState>
          )}
        </Main>
      </Container>
      <CancelOrderModal />
    </>
  );
};

export default BookingsView;
