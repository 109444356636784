import type { FC } from 'react';
import { Formik } from 'formik';
import Switch from 'components/Inputs/Switch';
import useConnect from './connect';
import { validationSchema } from './constants';
import {
  Button,
  Buttons,
  CancelButton,
  Container,
  IconCircle,
  Icon,
  Title,
  Form,
  TextField,
  Row,
  Space,
} from './styles';

const EventModal: FC = () => {
  const {
    employeePermissions,
    initialValues,
    event,
    handleDelete,
    isOpen,
    handleClose,
    handleSubmit,
    isLoading,
  } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>{event?.id ? 'Actualizar evento' : 'Crear evento'}</Title>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ handleSubmit, isValid, values }) => (
          <Form onSubmit={handleSubmit}>
            <TextField id="title" label="Titulo" name="title" autoFocus />
            <Row>
              <TextField
                id="startDate"
                label="Fecha inicio"
                name="startDate"
                type="datetime-local"
              />
              <TextField
                id="endDate"
                label="Fecha fin"
                name="endDate"
                type="datetime-local"
              />
            </Row>
            <Row>
              <Switch id="allDay" label="Día completo" name="allDay" />
              <Switch id="notify" label="Notificar" name="notify" />
            </Row>
            {values.notify && (
              <Space>
                <TextField
                  id="emails"
                  label="Emails separados por coma"
                  name="emails"
                />
              </Space>
            )}
            <Buttons>
              <CancelButton onClick={handleClose}>Cerrar</CancelButton>
              {event?.id && employeePermissions.allowRemoveCalendars && (
                <Button variant="danger" onClick={handleDelete}>
                  Eliminar
                </Button>
              )}
              <Button type="submit" disabled={!isValid} isLoading={isLoading}>
                {event?.id &&
                (employeePermissions.allowCreateCalendars ||
                  employeePermissions.allowEditCalendars)
                  ? 'Actualizar'
                  : 'Crear'}
              </Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EventModal;
