import { FC, memo } from 'react';
import { Formik } from 'formik';
import useConnect from './connect';
import {
  Container,
  Title,
  IconCircle,
  Icon,
  Header,
  FormContainer,
  Buttons,
  Button,
  Row,
  TextField,
} from './styles';

const CashierDetailsModal: FC = () => {
  const { handleClose, initialValues, isOpen } = useConnect();

  const formId = `cashierDetails`;
  const showExpenses =
    initialValues?.totalExpenses && initialValues.totalExpenses !== 0;
  const showLastRestCredits =
    initialValues?.lastRestRealCredits &&
    initialValues.lastRestRealCredits !== 0;
  const showRestCredits =
    initialValues?.restRealCredits && initialValues.restRealCredits !== 0;
  const showTotalCreditAdded = showExpenses || showLastRestCredits;

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="cashier" size={24} />
        </IconCircle>
        <Title>Detalles de caja</Title>
      </Header>
      <Formik onSubmit={() => console.log('ok')} initialValues={initialValues}>
        <FormContainer>
          <Row>
            <TextField
              variant="standard"
              readOnly
              type="text"
              name="startDate"
              label="Fecha inicio"
              id={`${formId}_startDate`}
            />
            <TextField
              variant="standard"
              readOnly
              type="text"
              name="endDate"
              label="Fecha fin"
              id={`${formId}_endDate`}
            />
          </Row>
          <Row>
            <TextField
              variant="standard"
              readOnly
              type="text"
              name="openedBy"
              label="Abierta por"
              id={`${formId}_closedBy`}
            />
            <TextField
              variant="standard"
              readOnly
              type="text"
              name="closedBy"
              label="Cerrada por"
              id={`${formId}_closedBy`}
            />
          </Row>
          <Row>
            <TextField
              variant="standard"
              readOnly
              type="number"
              name="totalCashier"
              label="Total ingresado"
              id={`${formId}_totalCashier`}
            />
            <TextField
              variant="standard"
              readOnly
              type="number"
              name="realCreditsAdded"
              label="Total real en caja"
              id={`${formId}_realCreditsAdded`}
            />
          </Row>
          {showTotalCreditAdded ? (
            <Row>
              <TextField
                variant="standard"
                readOnly
                type="number"
                name="totalCreditsAdded"
                label="Créditos ingresados"
                id={`${formId}_totalCreditsAdded`}
              />
              {showRestCredits ? (
                <TextField
                  variant="standard"
                  readOnly
                  type="number"
                  name="restRealCredits"
                  label="Resto de caja"
                  id={`${formId}_restRealCredits`}
                />
              ) : null}
            </Row>
          ) : null}

          {!showTotalCreditAdded && showRestCredits ? (
            <>
              <TextField
                variant="standard"
                readOnly
                type="number"
                name="restRealCredits"
                label="Resto de caja"
                id={`${formId}_restRealCredits`}
              />
              <br />
            </>
          ) : null}

          {showExpenses ? (
            <Row>
              <TextField
                variant="standard"
                readOnly
                type="number"
                name="totalExpenses"
                label="Gastos"
                id={`${formId}_totalExpenses`}
              />
            </Row>
          ) : null}
          {showLastRestCredits ? (
            <Row>
              <TextField
                variant="standard"
                readOnly
                type="number"
                name="lastRestRealCredits"
                label="Resto de caja previo"
                id={`${formId}_lastRestRealCredits`}
              />
            </Row>
          ) : null}
          <TextField
            variant="standard"
            readOnly
            type="number"
            name="adjust"
            label="Ajuste"
            id={`${formId}adjust`}
          />

          {initialValues?.typesOfCash && (
            <>
              <br />
              <TextField
                readOnly
                isMultiline
                type="text"
                name="typesOfCash"
                label="Tipos de moneda"
                id={`${formId}_typesOfCash`}
                rows={2}
              />
              <br />
            </>
          )}

          {initialValues?.note && (
            <>
              <br />
              <TextField
                readOnly
                isMultiline
                type="text"
                name="note"
                label="Nota"
                id={`${formId}_note`}
                rows={3}
              />
            </>
          )}

          <Buttons>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Buttons>
        </FormContainer>
      </Formik>
    </Container>
  );
};

export default memo(CashierDetailsModal);
