import type { FC } from 'react';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  Container,
  Description,
  IconCircle,
  Icon,
  Title,
} from './styles';

const ExpiredQuotaDialog: FC = () => {
  const { isOpen, handleClose, handleAccept } = useConnect();

  return (
    <Container
      disableBackdropClick
      disableEscapeKeyDown
      disableFullScreen
      open={isOpen}
      onClose={handleClose}
    >
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>No hay una cuota activa</Title>
      <Description>El socio debe asignar una nueva cuota</Description>
      <Buttons>
        <CancelButton onClick={handleClose}>Cerrar</CancelButton>
        <AcceptButton onClick={handleAccept}>Asignar Cuota</AcceptButton>
      </Buttons>
    </Container>
  );
};

export default ExpiredQuotaDialog;
