import { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useModal from 'apollo/hooks/useModal';
import SECTIONS from './sections';
import { Section, SectionName } from './types';

const useLogic = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState('');
  const [isRefetching, setIsRefetching] = useState(false);
  const [sections, setSections] = useState(SECTIONS);
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const { openNewTicketRequest } = useModal();

  useEffect(() => {
    const queryStringSection = searchParams.get('section');
    const sectionData = SECTIONS.find(
      (section) => section.sectionName === queryStringSection,
    );
    setSelectedSection(sectionData || null);
  }, [searchParams]);

  const handleSearch = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;

    setIsRefetching(true);
    try {
      setSections(
        SECTIONS.filter(
          (item) =>
            item.title.includes(newSearch) || item.tags.includes(newSearch),
        ),
      );
      setFilter(newSearch);
      setIsRefetching(false);
    } catch (error) {
      setIsRefetching(false);
    }
  }, []);

  const handleChange = useCallback(
    (sectionName: SectionName) => {
      const section = SECTIONS.find(
        (section) => section.sectionName === sectionName,
      );
      if (section) {
        navigate(`/support?section=${section.sectionName}`);
      }
    },
    [navigate],
  );

  return {
    filter,
    handleChange,
    handleSearch,
    isRefetching,
    openNewTicketRequestModal: openNewTicketRequest,
    sections,
    selectedSection,
  };
};

export default useLogic;
