import type { FC } from 'react';
import { Formik, FieldArray } from 'formik';
import { DocumentCustomDataInputType } from 'apollo/graphql.types';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import TextEditor from 'components/TextEditor';
import TableHead from './TableHead';
import Row from './Row';
import {
  validationSchema,
  hostPartnerData,
  organizationData,
  partnerData,
} from './constants';
import useConnect from './connect';
import {
  Container,
  Main,
  InfoContainer,
  InfoContainerDescription,
  InfoTables,
  InfoContent,
  TextField,
  Form,
  InfoContentTitle,
  Switch,
  Select,
  RemoveIcon,
  AddIcon,
  Inputs,
  CustomDataRow,
  CustomDataContainer,
  CustomDataTitle,
} from './styles';

const Document: FC = () => {
  const {
    isLoading,
    initialValues,
    handleSaveText,
    employeePermissions,
    isNew,
    handleOnSubmit,
    handleRemoveDocument,
  } = useConnect();
  const formId = 'document';
  const title = !isNew ? `Documento "${initialValues.name}"` : 'Documento';

  const showAddIcon = ({
    index,
    customData,
  }: {
    index: number;
    customData: { key: string }[];
  }) => {
    return (
      customData.length < 15 &&
      customData[customData.length - 1]?.key &&
      ((index === 0 && customData.length === 1) ||
        index === customData.length - 1)
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header
        title={title}
        buttonText={!isNew ? 'Eliminar' : ''}
        buttonVariant="danger"
        onClickButton={handleRemoveDocument}
        showGlobalCreateButton={false}
        showRfidButton={false}
      />
      <Main>
        <InfoContainer>
          <InfoContainerDescription>
            {
              'En el texto, los valores entre llaves {} corresponden a variables que representan datos específicos, como el nombre del socio en este caso {nombre_socio}.'
            }
          </InfoContainerDescription>
          <InfoTables>
            <InfoContent>
              <InfoContentTitle>Datos del socio</InfoContentTitle>
              <TableHead />
              {partnerData.map((item) => (
                <Row key={item.value} data={item.key} value={item.value} />
              ))}
            </InfoContent>
            <InfoContent>
              <InfoContentTitle>Datos del avalador</InfoContentTitle>
              <TableHead />
              {hostPartnerData.map((item) => (
                <Row key={item.value} data={item.key} value={item.value} />
              ))}
            </InfoContent>
            <InfoContent>
              <InfoContentTitle>Datos de la Organización</InfoContentTitle>
              <TableHead />
              {organizationData.map((item) => (
                <Row key={item.value} data={item.key} value={item.value} />
              ))}
            </InfoContent>
          </InfoTables>
        </InfoContainer>
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
        >
          {(formikHelpers) => (
            <Form>
              <TextField name="name" label="Nombre *" id={`${formId}_name`} />
              <Switch
                id={`${formId}_hasSignature`}
                label="Contiene firma"
                name="hasSignature"
              />
              <CustomDataTitle>Crea campos específicos</CustomDataTitle>
              <CustomDataContainer>
                <FieldArray
                  name="customData"
                  render={(arrayHelpers) =>
                    formikHelpers.values?.customData?.length > 0 &&
                    formikHelpers.values.customData.map((item, index) => {
                      return (
                        <CustomDataRow>
                          <Inputs>
                            <TextField
                              name={`customData[${index}].key`}
                              label="Valor"
                              id={`customData[${index}].key`}
                            />
                            <Select
                              name={`customData[${index}].inputType`}
                              label="Tipo"
                              id={`customData[${index}].inputType`}
                              options={[
                                {
                                  value: DocumentCustomDataInputType.String,
                                  label: 'Texto',
                                },
                                {
                                  value: DocumentCustomDataInputType.Number,
                                  label: 'Número',
                                },
                                {
                                  value: DocumentCustomDataInputType.Date,
                                  label: 'Fecha',
                                },
                                {
                                  value: DocumentCustomDataInputType.Boolean,
                                  label: 'Si/No',
                                },
                              ]}
                            />
                            <TextField
                              $long
                              name={`customData[${index}].label`}
                              label="Título"
                              id={`customData[${index}].label`}
                            />
                            <RemoveIcon
                              onClick={() => {
                                if (
                                  formikHelpers.values.customData.length > 1
                                ) {
                                  arrayHelpers.remove(index);
                                } else {
                                  formikHelpers.setFieldValue(
                                    `customData[${index}].key`,
                                    '',
                                  );
                                  formikHelpers.setFieldValue(
                                    `customData[${index}].inputType`,
                                    '',
                                  );
                                  formikHelpers.setFieldValue(
                                    `customData[${index}].label`,
                                    '',
                                  );
                                }
                              }}
                              name="trash"
                              size={16}
                            />

                            {showAddIcon({
                              index,
                              customData: formikHelpers.values.customData,
                            }) && (
                              <AddIcon
                                onClick={() =>
                                  arrayHelpers.push({
                                    key: '',
                                    label: '',
                                    inputType:
                                      DocumentCustomDataInputType.String,
                                  })
                                }
                                name="add"
                              />
                            )}
                          </Inputs>
                        </CustomDataRow>
                      );
                    })
                  }
                />
              </CustomDataContainer>
              <TextEditor
                allowSave={employeePermissions.allowEditContract}
                onSave={(text: string) =>
                  handleSaveText({ text, formikHelpers })
                }
                defaultContent={initialValues.text}
                disableButton={!formikHelpers.isValid}
                isLoadingButton={isLoading}
              />
            </Form>
          )}
        </Formik>
      </Main>
    </Container>
  );
};

export default Document;
