import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import useFileActions from 'apollo/hooks/file/useFileActions';
import useFileSignedUrlActions from 'apollo/hooks/upload/useFileSignedUrlActions';
import { formatErrors } from 'utils/errors/formatErrors';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';

const useUploadFile = (partnerId: string) => {
  const { uploadFile, fileActionsLoading } = useFileActions(partnerId);
  const { uploadFileAction } = useFileSignedUrlActions();
  const { hasDriveIntegration } = useReactiveVar(organizationConfigVar);
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useState<number | undefined>(undefined);

  const uploadFileToS3 = useCallback(
    async ({ fileName, file }: { fileName: string; file: File }) => {
      await uploadFileAction({
        name: fileName,
        file,
        partnerId,
        setProgress,
      });
      enqueueSnackbar(
        'El archivo se ha subido a inditas. Te recomendamos activar la integración con drive',
        {
          variant: 'info',
        },
      );
    },
    [enqueueSnackbar, uploadFileAction, partnerId],
  );

  const uploadFileFlow = useCallback(
    async ({ fileName, file }: { fileName: string; file: File }) => {
      try {
        if (hasDriveIntegration) {
          await uploadFile({ file, fileName, partnerId });
        } else {
          await uploadFileToS3({ file, fileName });
        }
        enqueueSnackbar('Se ha cargado el archivo correctamente.', {
          variant: 'success',
        });
      } catch (error) {
        if (
          error.message === 'Response not successful: Received status code 413'
        ) {
          enqueueSnackbar('El archivo no puede superar los 10 MB.', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(formatErrors('file', error.message, 'crear'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={error} />,
          });
        }
      }
    },
    [
      enqueueSnackbar,
      hasDriveIntegration,
      partnerId,
      uploadFile,
      uploadFileToS3,
    ],
  );

  return {
    uploadFile: uploadFileFlow,
    isUploadFileLoading: fileActionsLoading,
    progress,
  };
};

export default useUploadFile;
