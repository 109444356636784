export const TABLE_METRICS = {
  name: '16.6%',
  benefit: '16.6%',
  costs: '16.6%',
  quantity: '16.6%',
  realQuantity: '16.6%',
  orders: '16.6%',
};

export const TABLE_MOBILE_METRICS = {
  name: '20%',
  benefit: '20%',
  costs: '20%',
  quantity: '20%',
  realQuantity: '20%',
};
