import { FC, memo } from 'react';
import { formatDateToTableExtended } from 'utils/dates/formatDates';
import { Container, Date, EndSession } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, date, endSession }) => {
  return (
    <Container className={className}>
      <Date>{formatDateToTableExtended(date)}</Date>
      <EndSession>
        {endSession ? formatDateToTableExtended(endSession) : 'En curso'}
      </EndSession>
    </Container>
  );
};

export default memo(Row);
