import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import notEmpty from 'utils/notEmpty';
import { GET_QUOTAS } from 'apollo/queries/quotas';
import { permissionsStateVar } from 'apollo/reactive';
import { normalizeQuota } from 'model/Quota';
import type { GetQuotas } from 'apollo/generated/GetQuotas';

const useQuotas = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const { loading, data, refetch } = useQuery<GetQuotas>(GET_QUOTAS, {
    skip: !employeePermissions.allowShowQuotas,
  });

  const { quotas } = useMemo(
    () => ({
      quotas: data?.quotas
        ? data.quotas.filter(notEmpty).map(normalizeQuota)
        : [],
    }),
    [data],
  );

  return {
    loading,
    quotas,
    refetch,
  };
};

export default useQuotas;
