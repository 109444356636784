import { FC, memo } from 'react';
import { Formik } from 'formik';
import useConnect from './connect';
import {
  Container,
  Title,
  IconCircle,
  Icon,
  Header,
  Buttons,
  CancelButton,
  CreateButton,
  Row,
  SaveMessage,
  SaveMessageText,
  TextField,
  Form,
} from './styles';
import { validationSchema } from './constants';

const MoveStoreToStockModal: FC = () => {
  const {
    showAdjust,
    handleClose,
    handleSubmit,
    initialValues,
    isFirstSave,
    isLoading,
    isOpen,
    handleChangeNewQuantity,
  } = useConnect();

  const formId = `storeProduct_${initialValues.name}`;

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="store" size={24} />
        </IconCircle>
        <Title>Transferir del almacén al stock {initialValues.name}</Title>
      </Header>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isValid: isFormValid, values }) => (
          <Form>
            <Row>
              <TextField
                readOnly
                type="number"
                name="total"
                label="Almacén Actual"
                value={Number((values?.quantity || 0).toFixed(2))}
              />
              <TextField
                type="number"
                name="newQuantity"
                label="Transferir"
                onLocalChange={handleChangeNewQuantity}
                id={`${formId}_newQuantity`}
              />
            </Row>
            <Row>
              <TextField
                readOnly
                type="number"
                name="currentProductStock"
                label="Stock Actual"
              />
              {values?.newQuantity && values?.currentProductStock && (
                <TextField
                  readOnly
                  type="number"
                  name="newProductStock"
                  label="Nuevo Stock"
                  value={Number(
                    (
                      Number(values.newQuantity) +
                      Number(values.currentProductStock)
                    ).toFixed(2),
                  )}
                />
              )}
            </Row>

            <TextField
              type="text"
              name="note"
              label="Nota"
              id={`${formId}_note`}
            />
            {showAdjust && (
              <SaveMessage>
                <SaveMessageText>
                  Se transfieren {values.newQuantity} unidades del almacén al
                  inventario, quedando un saldo de{' '}
                  {(values?.quantity || 0) - (values.newQuantity || 0)} unidades{' '}
                  en el almacén.
                </SaveMessageText>
              </SaveMessage>
            )}
            <Buttons>
              <CreateButton
                type="submit"
                isLoading={isLoading}
                disabled={
                  isLoading || !isFormValid || values?.newQuantity === 0
                }
              >
                {isFirstSave ? 'Validar' : 'Guardar'}
              </CreateButton>
              <CancelButton onClick={handleClose}>Cancelar</CancelButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default memo(MoveStoreToStockModal);
