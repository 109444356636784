import { FC, memo } from 'react';

import type { Props } from './types';

const Products: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      transform="translate(5 5)"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
    >
      <rect x=".5" y=".5" width="5" height="5" rx="1" />
      <rect x="8.5" y=".5" width="5" height="5" rx="1" />
      <rect x=".5" y="8.5" width="5" height="5" rx="1" />
      <rect x="8.5" y="8.5" width="5" height="5" rx="1" />
    </g>
  </svg>
);

export default memo(Products);
