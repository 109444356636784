import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CREATE_ORGANIZATION_DOCUMENT,
  REMOVE_ORGANIZATION_DOCUMENT,
  UPDATE_ORGANIZATION_DOCUMENT,
} from 'apollo/mutations/organizations';
import {
  DocumentFormFields,
  normalizeOrganizationDocument,
} from 'model/Organization';
import type {
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
  EditDocumentMutation,
  EditDocumentMutationVariables,
  RemoveDocumentMutation,
  RemoveDocumentMutationVariables,
} from 'apollo/graphql.types';

const useDocumentActions = () => {
  const [createDocumentMutation, { loading: isLoadingCreateDocument }] =
    useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(
      CREATE_ORGANIZATION_DOCUMENT,
    );
  const [updateDocumentMutation, { loading: isLoadingUpdateDocument }] =
    useMutation<EditDocumentMutation, EditDocumentMutationVariables>(
      UPDATE_ORGANIZATION_DOCUMENT,
    );
  const [removeDocumentMutation, { loading: isLoadingRemoveDocument }] =
    useMutation<RemoveDocumentMutation, RemoveDocumentMutationVariables>(
      REMOVE_ORGANIZATION_DOCUMENT,
    );

  const normalizeValues = (data: DocumentFormFields) => ({
    name: data.name,
    hasSignature: data.hasSignature,
    text: data.text,
    customData: data.customData
      .filter((item) => item.key && item.inputType && item.label)
      .map((item) => ({
        key: `{${item.key}}`,
        label: item.label,
        inputType: item.inputType,
      })),
  });

  const createDocument = useCallback(
    async (data: DocumentFormFields) => {
      const dto = normalizeValues(data);
      const res = await createDocumentMutation({
        variables: {
          data: dto,
        },
      });

      if (res?.data?.createDocument) {
        return normalizeOrganizationDocument(res.data.createDocument);
      }
    },
    [createDocumentMutation],
  );

  const updateDocument = useCallback(
    async ({ id, data }: { id: string; data: DocumentFormFields }) => {
      const dto = normalizeValues(data);
      const res = await updateDocumentMutation({
        variables: {
          id,
          data: dto,
        },
      });

      if (res?.data?.editDocument) {
        return normalizeOrganizationDocument(res.data.editDocument);
      }
    },
    [updateDocumentMutation],
  );

  const removeDocument = useCallback(
    async (id: string) => {
      await removeDocumentMutation({
        variables: {
          id,
        },
      });
    },
    [removeDocumentMutation],
  );

  return {
    createDocument,
    updateDocument,
    removeDocument,
    loading:
      isLoadingCreateDocument ||
      isLoadingUpdateDocument ||
      isLoadingRemoveDocument,
  };
};

export default useDocumentActions;
