import { Form } from 'formik';
import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultTextField from 'components/Inputs/TextField';

export const Container = styled(Form)``;

export const SubmitButton = styled(DefaultButton)`
  &&& {
    margin-bottom: 3rem;
    margin-top: 1rem;
    width: 100%;

    ${from.tabletPortrait`
      float: right;
      width: unset;
  `}
  }
`;

export const Row = styled.div`
  display: block;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${from.tabletLandscape`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;

export const TextField = styled(DefaultTextField)`
  margin-bottom: 0.5rem;
  ${from.tabletLandscape`
    margin-bottom: 0;
  `}
`;
