import styled from 'styled-components';
import { Form as DefaultForm } from 'formik';
import { from } from 'styles/responsive';
import { SectionContainer, SectionHeader } from 'components/Layout';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultButton from 'components/Button';
import DefaultAvatar from 'components/Avatar';
import DefaultBackLink from 'components/BackLink';
import DefaultDatePicker from 'components/Inputs/DatePicker';

export const Container = styled.div``;

export const Header = styled(SectionHeader)`
  align-items: center;
  padding-top: 1rem;
  ${from.tabletLandscape`
    margin-bottom: 5rem;
  `}
`;

export const BackLink = styled(DefaultBackLink)``;

export const AvatarContainer = styled.div`
  margin: 0 1rem;
`;

export const HeaderInfo = styled.div`
  flex: 1 0 auto;
  margin-left: 1rem;
`;

export const HeaderName = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: 0;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Content = styled(SectionContainer)`
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const Form = styled(DefaultForm)``;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2rem;

  ${from.tabletPortrait`
    flex-flow: row nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const TextField = styled(DefaultTextField)`
  margin-top: 1rem;
  ${from.tabletPortrait`
    margin-top: 0;
  `}
`;

export const Avatar = styled(DefaultAvatar)``;

export const DatePicker = styled(DefaultDatePicker)`
  margin-top: 1rem;
  ${from.tabletPortrait`
    margin-top: 0;
  `}
`;

export const CreateButton = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  &&& {
    ${from.tabletPortrait`
      margin-left: 0.5rem;
    `}
  }
`;

export const Row = styled.div`
  display: block;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${from.tabletLandscape`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;

export const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-left: auto;

  > :not(:last-child) {
    margin-right: 1rem !important;
  }
  > * {
    flex: 0 0 8rem;

    ${from.desktop`
      flex: 0 1 12.5rem;
    `}

    ${from.tabletPortrait`
      justify-content: flex-start;
    `}
  }
`;

export const RemoveButton = styled(DefaultButton).attrs(() => ({
  variant: 'danger',
}))``;
