import { memo } from 'react';
import type { FC } from 'react';
import { Container, NextPayment, Name, Number } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  memberNum,
  name,
  partnerId,
  nextPayment,
}) => {
  return (
    <Container className={className} to={`/partners/${partnerId}`}>
      <Number>{memberNum}</Number>
      <Name>{name}</Name>
      <NextPayment>{nextPayment}</NextPayment>
    </Container>
  );
};

export default memo(Row);
