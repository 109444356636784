import { useCallback, useMemo } from 'react';
import useAuthActions from 'apollo/hooks/useAuthActions';
import getMe from 'apollo/hooks/user/useMe';
import { useReactiveVar } from '@apollo/client';
import {
  userRoleVar,
  permissionsStateVar,
  subscriptionStateVar,
} from 'apollo/reactive';

const useConnect = () => {
  const { logout, loading: logoutLoading } = useAuthActions();
  const { data, loading: getMeLoading } = getMe({ fetchPolicy: 'cache-first' });
  const userRole = useReactiveVar(userRoleVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const subscription = useReactiveVar(subscriptionStateVar);

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  const organizationName = useMemo<string>(
    () => data?.organization?.name || 'organización',
    [data],
  );

  return {
    handleLogout,
    isLoading: logoutLoading || getMeLoading,
    organizationName,
    userRole,
    employeePermissions,
    subscription,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
