import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useMenusConfig from 'apollo/hooks/organization/useMenusConfig';

const useConnect = () => {
  const { menus, getMenusLoading } = useMenusConfig();
  const navigate = useNavigate();

  const handleNavigateToCreationView = useCallback(() => {
    navigate('/organization/menus/new');
  }, [navigate]);

  return {
    isLoading: getMenusLoading,
    menus,
    handleNavigateToCreationView,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
