import Switch from '@mui/material/Switch';
import styled, { css } from 'styled-components';
import { Label as DefaultLabel } from '../styles';

export const Container = styled.div``;

export const MaterialSwitch = styled(Switch)``;

export const Label = styled(DefaultLabel)<{ $checked: boolean }>`
  font-size: 0.9rem;
  ${({ $checked }) =>
    $checked &&
    css`
      color: ${({ theme }) => theme.colors.accent};
    `}
`;

export const InputContainer = styled.div`
  border-bottom: solid 2px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 6.45px 0 0.5rem;

  :focus-within {
    border-bottom: solid 2px ${({ theme }) => theme.colors.accent};
    ${Label} {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;
