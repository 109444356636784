import { FC, memo } from 'react';
import DiscountModal from '../../../Modals/Discount';
import {
  BackLink,
  Header,
  SectionTitle,
  HeaderContent,
  CreateButton,
} from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const OrganizationDiscounts: FC = () => {
  const {
    discounts,
    employeePermissions,
    handleOpenDiscountModal,
    handleOpenRemoveDiscountDialog,
  } = useConnect();

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <BackLink to="/organization" />
            <SectionTitle>Descuentos</SectionTitle>
          </HeaderContent>
          {employeePermissions.allowCreateDiscounts && (
            <CreateButton onClick={() => handleOpenDiscountModal()}>
              Crear
            </CreateButton>
          )}
        </Header>
        <Main>
          {discounts.length > 0 ? (
            <>
              <TableHead />
              {discounts.map((discount) => (
                <Row
                  key={discount.id}
                  employeePermissions={employeePermissions}
                  name={discount.name}
                  type={discount.type}
                  price={discount.price}
                  percentage={discount.percentage}
                  onEdit={() => handleOpenDiscountModal({ discount })}
                  onRemove={() => handleOpenRemoveDiscountDialog(discount)}
                />
              ))}
            </>
          ) : (
            <EmptyState
              title="No hay descuentos"
              iconName="discount"
              buttonLabel="Crear Descuento"
              onClickButton={handleOpenDiscountModal}
            >
              Crea tu primer descuento
            </EmptyState>
          )}
        </Main>
      </Container>
      <DiscountModal />
    </>
  );
};

export default memo(OrganizationDiscounts);
