import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const EmployeeContent = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.employee};
  text-transform: capitalize;
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const Partner = styled.div`
  margin-right: 0.5rem;
  flex: 0 0 10%;
  margin-bottom: 0.4rem;
  font-size: 0.8rem;
  text-transform: capitalize;
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
  flex: 0 0 ${TABLE_METRICS.partner};
`}
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  flex: 0 0 ${TABLE_METRICS.date};
  ${TextEllipsis};

  &:first-letter {
    text-transform: uppercase;
  }

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const Quantity = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: 1rem;
  flex: 0 0 ${TABLE_METRICS.quantity};
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const ExtraQuantity = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_METRICS.extraQuantity};
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const Credits = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  margin-left: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 15%;
  ${TextEllipsis};

  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.credits};
  font-size: 1rem;
  margin-left: auto;
`}
`;

export const Employee = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
