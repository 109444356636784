import styled, { css } from 'styled-components';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import DefaultOrdersIcon from 'components/Icon/Icons/Orders';
import { from } from 'styles/responsive';

import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

export const Content = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  height: 100%;
  width: 100%;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;
`;

export const Number = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 18%;
  ${TextEllipsis};

  ${from.tabletPortrait`
  font-size: 1rem;
  flex: 0 0 ${TABLE_METRICS.number};
`}
`;

export const ImageContainer = styled.div`
  flex: 0 0 ${TABLE_METRICS.image};
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;

  ${from.tabletPortrait`
     width: 3.3rem;
     height: 3.3rem;
  `};
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  margin-right: 2rem;
  flex: 0 1 25%;
  ${TextEllipsis};

  ${from.tabletPortrait`
  font-size: 1rem;
  flex: 0 0 ${TABLE_METRICS.name};
`}
`;

export const WithoutQuota = styled.span`
  color: ${({ theme }) => theme.colors.danger};
`;

export const Identifier = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.identifier};
  ${TextEllipsis};
`;

export const Balance = styled.div<{ $credits: number }>`
  color: ${({ $credits, theme }) =>
    $credits >= 0 ? theme.colors.primary : theme.colors.danger};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-right: 1rem;
  text-align: right;
  flex: 0 0 20%;
  ${TextEllipsis};

  ${from.tabletPortrait`
  font-size: 1rem;
  flex: 0 0 ${TABLE_METRICS.balance};
`}
`;

export const Chevron = styled.div`
  align-items: center;
  display: flex;
  text-align: right;
  flex: 0 0 ${TABLE_METRICS.chevron};
`;

export const ChevronRightIcon = styled(DefaultArrowRightIcon).attrs(() => ({
  size: 6.4,
}))`
  color: ${({ theme }) => theme.colors.grey500};
`;

export const OrdersIcon = styled(DefaultOrdersIcon)`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
`;
