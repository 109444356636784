import { useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, cashierProductsPayload, type } = useModal();
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const { products, totalDiscounts } = cashierProductsPayload || {};

  const totalPurchase = useMemo(
    () =>
      (products || [])?.reduce((a, b) => a + b.totalPurchases, 0).toFixed(2),
    [products],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  return {
    activateProductReference: organizationConfig.activateProductReference,
    handleClose,
    isOpen: type === ModalType.CASHIER_PRODUCTS,
    products,
    totalPurchase,
    totalDiscounts,
  };
};

export default useConnect;
