import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, IconCircle, Icon, Header } from './styles';

const NewReferencedProductModal: FC = () => {
  const {
    handleClose,
    handleSubmit,
    isOpen,
    initialValues,
    isLoading,
    productName,
  } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="products" size={24} />
        </IconCircle>
        <Title>Nueva referencia de {productName}</Title>
      </Header>
      <Form
        onSubmit={handleSubmit}
        onCancel={handleClose}
        initialValues={initialValues}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default NewReferencedProductModal;
