import { FC, memo } from 'react';
import { Formik } from 'formik';
import TextField from 'components/Inputs/TextField';
import { initialValues, validationSchema } from './constants';
import { Row, SubmitButton, Form } from '../styles';
import type { Props } from './types';

const PinConfig: FC<Props> = ({ onSubmit, isLoading }) => {
  const formId = 'organizationConfig';

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {({ isValid }) => (
        <Form>
          <Row>
            <TextField
              id={`${formId}_currentPin`}
              label="Pin actual *"
              name="currentPin"
              type="password"
            />
          </Row>
          <Row>
            <TextField
              id={`${formId}_newPin`}
              label="Nuevo Pin *"
              name="newPin"
              type="password"
            />
            <TextField
              id={`${formId}_newPin2`}
              label="Confirmar Pin *"
              name="newPin2"
              type="password"
            />
          </Row>
          <SubmitButton type="submit" disabled={!isValid} isLoading={isLoading}>
            Cambiar Pin
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default memo(PinConfig);
