import { useNavigate } from 'react-router-dom';
import usePermissionsGroups from 'apollo/hooks/permissionsGroup/usePermissionsGroups';
import { useCallback } from 'react';

const useConnect = () => {
  const { permissionsGroups, getPermissionsGroupsLoading } =
    usePermissionsGroups();
  const navigate = useNavigate();

  const handleNavigateToCreationView = useCallback(() => {
    navigate('/organization/permissions/new');
  }, [navigate]);

  return {
    isLoading: getPermissionsGroupsLoading,
    permissionsGroups,
    handleNavigateToCreationView,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
