import { FC, memo } from 'react';
import { Container, Point } from './styles';
import { Props } from './types';

const ListLoader: FC<Props> = ({
  className,
  pointSeparation = '12px',
  pointSize = '8px',
}) => (
  <Container
    $pointSeparation={pointSeparation}
    $pointSize={pointSize}
    className={className}
  >
    <Point />
    <Point />
    <Point />
  </Container>
);

export default memo(ListLoader);
