import { FC, memo } from 'react';
import useLogic from './logic';
import { OTPContainer, StyledInput } from './styles';
import type { Props } from './types';

const OTPInput: FC<Props> = ({
  length = 4,
  onComplete,
  className,
  hasError,
}) => {
  const {
    otp,
    handleInputPaste,
    handleInputChange,
    handleKeyDown,
    isCompleted,
    inputRefs,
  } = useLogic({ length, onComplete, hasError });

  return (
    <OTPContainer onPaste={handleInputPaste} className={className}>
      {otp.map((digit, index) => (
        <StyledInput
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          $hasError={hasError}
          $isComplete={isCompleted}
          type="password"
          value={digit}
          maxLength={1}
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={inputRefs[index]}
        />
      ))}
    </OTPContainer>
  );
};

export default memo(OTPInput);
