import type { FC } from 'react';
import Filters from 'components/Filters';
import useConnect from './connect';
import {
  ButtonContainer,
  Container,
  FiltersContainer,
  Partners,
  ReturnButton,
} from './styles';
import Row from './Row';

const PartnersQuota: FC = () => {
  const { handleClose, handleSearch, isOpen, isRefetching, partners, filter } =
    useConnect();

  return (
    <Container open={isOpen} onClose={handleClose}>
      <FiltersContainer>
        <Filters
          isLoading={isRefetching}
          onSearchChange={handleSearch}
          filter={filter}
          nResults={partners?.length || 0}
        />
      </FiltersContainer>

      <Partners>
        {partners.map((partner) => (
          <Row
            key={partner.id}
            memberNum={partner.memberNum}
            name={partner.fullName}
            nextPayment={partner.quota.nextPayment}
            partnerId={partner.id}
          />
        ))}
      </Partners>
      <ButtonContainer>
        <ReturnButton onClick={handleClose}>Cerrar</ReturnButton>
      </ButtonContainer>
    </Container>
  );
};

export default PartnersQuota;
