import { useCallback } from 'react';
import useAuthActions from 'apollo/hooks/useAuthActions';
import type { FormikConfig } from 'formik';
import { initVar } from 'apollo/reactive';
import type { FormFields } from './types';

const useConnect = () => {
  const { login, loading } = useAuthActions();

  const handleSignIn = useCallback<FormikConfig<FormFields>['onSubmit']>(
    async ({ email, password }, { setErrors }) => {
      try {
        await login({ email, password });
      } catch (error) {
        initVar(true);
        if (error.networkError) {
          setErrors({ submitError: 'Error de conexión' });
        }

        if (error.message.includes('block')) {
          setErrors({ submitError: 'Cuenta bloqueada' });
        } else if (error.message.includes('schedule')) {
          setErrors({ submitError: 'Cuenta fuera de horario' });
        } else {
          setErrors({ submitError: 'Las credenciales no son validas' });
        }
      }
    },
    [login],
  );

  return {
    handleSignIn,
    loading,
  };
};

export default useConnect;
