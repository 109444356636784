import { FC, memo } from 'react';
import Loader from 'components/Loader';
import CategoryModal from '../../../Modals/Category';
import {
  BackLink,
  Header,
  SectionTitle,
  HeaderContent,
  CreateButton,
} from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const OrganizationCategories: FC = () => {
  const {
    employeePermissions,
    categories,
    handleOpenCategoryModal,
    handleOpenRemoveDiscountDialog,
    handleGoToDetails,
    isLoading,
  } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <BackLink to="/organization" />
            <SectionTitle>Categorías</SectionTitle>
          </HeaderContent>
          {employeePermissions.allowCreateCategories && (
            <CreateButton onClick={() => handleOpenCategoryModal()}>
              Crear
            </CreateButton>
          )}
        </Header>
        <Main>
          {categories.length > 0 ? (
            <>
              <TableHead />
              {categories.map((category) => (
                <Row
                  key={category.id}
                  employeePermissions={employeePermissions}
                  name={category.name}
                  type={category.type}
                  onEdit={() => handleOpenCategoryModal({ category })}
                  onRemove={() => handleOpenRemoveDiscountDialog(category)}
                  onClickRow={() => handleGoToDetails(category)}
                />
              ))}
            </>
          ) : (
            <EmptyState
              title="No hay categorías"
              iconName="categories"
              buttonLabel="Crear Categoría"
              onClickButton={handleOpenCategoryModal}
            >
              Crea tu primera categoría
            </EmptyState>
          )}
        </Main>
      </Container>
      <CategoryModal />
    </>
  );
};

export default memo(OrganizationCategories);
