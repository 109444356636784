import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { subscriptionStateVar } from 'apollo/reactive/subscriptionState';
import { ProductSections } from '../constants';
import type { Props } from './types';

type Section = {
  key: string;
  value: string;
  isAllow: boolean;
};

const useLogic = ({ onSelectedSection, productId }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const subscription = useReactiveVar(subscriptionStateVar);

  const sections = useMemo(
    () => [
      {
        key: ProductSections.PROFILE,
        value: 'Información',
        isAllow: true,
      },
      {
        key: ProductSections.CATEGORIES,
        value: 'Categorías',
        isAllow: true,
      },
      {
        key: ProductSections.IMAGES,
        value: 'Imágenes',
        isAllow: true,
      },
      {
        key: ProductSections.TRANSACTIONS,
        value: 'Transacciones',
        isAllow: subscription.allowedProductTransactions,
      },
      {
        key: ProductSections.HISTORIC_ADJUST,
        value: 'Ajustes',
        isAllow: true,
      },
      {
        key: ProductSections.HISTORIC_STORE,
        value: 'Almacén',
        isAllow: true,
      },
    ],
    [subscription],
  );

  useEffect(() => {
    const queryStringSection = searchParams.get('section');
    const sectionData = sections.find(
      (section) => section.key === queryStringSection,
    );
    if (sectionData) {
      setSelectedSection(sectionData);
    }
  }, [sections, onSelectedSection, searchParams]);

  useEffect(() => {
    if (selectedSection) {
      onSelectedSection(selectedSection.key);
    }
  }, [onSelectedSection, selectedSection]);

  const handleToggleSection = useCallback(
    (section: Section) => {
      setSelectedSection(section);
      if (productId) {
        navigate(`/products/${productId}?section=${section.key}`);
      }
    },
    [productId, navigate],
  );

  return { handleToggleSection, selectedSection, sections };
};

export default useLogic;
