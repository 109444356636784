import { memo } from 'react';
import type { FC } from 'react';
import Price from 'components/Price';
import { ProductRankingState } from 'model/Metric';
import {
  Billing,
  Chevron,
  Container,
  DownIcon,
  EqualIcon,
  Flag,
  Name,
  Position,
  Sales,
  State,
  UpIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, item, style }) => {
  const { product, position, totalPurchases, totalSales, state, isNew } = item;

  return (
    <Container
      className={className}
      to={`/products/${product.id}/transactions`}
      style={style}
    >
      <State>
        {state === ProductRankingState.UP && <UpIcon />}
        {state === ProductRankingState.DOWN && <DownIcon />}
        {state === ProductRankingState.EQUAL && <EqualIcon />}
      </State>
      <Position>{position}</Position>
      <Name>
        {product.name} {isNew && <Flag>Nuevo</Flag>}
      </Name>
      <Billing>
        <Price>{totalSales}</Price>
      </Billing>
      <Sales>{totalPurchases} pedidos</Sales>
      <Chevron />
    </Container>
  );
};

export default memo(Row);
