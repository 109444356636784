import { memo, FC } from 'react';
import { Container, Content, Logo } from './styles';

const FullscreenStandby: FC = () => (
  <Container>
    <Content>
      <Logo />
    </Content>
  </Container>
);

export default memo(FullscreenStandby);
