import styled from 'styled-components';

export const IconCircle = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent}0A;
  border-radius: 50%;
  color: #0490ff;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  width: 3.5rem;
`;
