import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { QuantityMovementType } from 'apollo/graphql.types';
import { formatDateToTable } from 'utils/dates/formatDates';
import type { ProductAdjustQuantityFormFields } from 'model/Product';

const useConnect = () => {
  const { close, type, adjustProductDetailsPayload } = useModal();
  const { productQuantityAdjust } = adjustProductDetailsPayload || {};

  const initialValues = useMemo(() => {
    const isStoreToStockMovement =
      productQuantityAdjust?.movementType === QuantityMovementType.FromStore;

    const lastQuantity = isStoreToStockMovement
      ? (productQuantityAdjust?.lastStock || 0).toFixed(2)
      : (productQuantityAdjust?.lastQuantity || 0).toFixed(2);

    const currentQuantity = isStoreToStockMovement
      ? (productQuantityAdjust?.currentStock || 0).toFixed(2)
      : (productQuantityAdjust?.currentQuantity || 0).toFixed(2);

    const adjustDifference = isStoreToStockMovement
      ? (productQuantityAdjust?.adjustDifference || 0) * -1
      : productQuantityAdjust?.adjustDifference;

    return {
      name: productQuantityAdjust?.product.name || '',
      employee: `${productQuantityAdjust?.editedBy?.firstName || ''} ${
        productQuantityAdjust?.editedBy?.lastName || ''
      }`,
      date:
        (productQuantityAdjust?.createdAt &&
          formatDateToTable(productQuantityAdjust.createdAt)) ||
        '',
      note: productQuantityAdjust?.note || '',
      lastQuantity,
      currentQuantity,
      lastBaseWeight: productQuantityAdjust?.lastBaseWeight || '0',
      currentBaseWeight: productQuantityAdjust?.currentBaseWeight || '0',
      adjustDifference: adjustDifference?.toFixed(2) || '0',
      movementType:
        productQuantityAdjust?.movementType || QuantityMovementType.Stock,
    };
  }, [productQuantityAdjust]);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<ProductAdjustQuantityFormFields>['onSubmit']
  >(async (data) => {
    console.log(data);
  }, []);

  return {
    handleClose,
    handleSubmit,
    initialValues,
    isOpen: type === ModalType.ADJUST_PRODUCT_DETAILS,
  };
};

export default useConnect;
