import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { useSnackbar } from 'notistack';
import getPartners from 'apollo/requests/getPartners';
import type { PartnerToList } from 'model/Partner';

const useConnect = () => {
  const { close, type, partnersQuotaPayload } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');
  const [filteredPartners, setFilteredPartners] = useState<PartnerToList[]>([]);
  const { partnersIds } = partnersQuotaPayload || {};

  useMemo(async () => {
    const partners = await getPartners(partnersIds || []);
    if (partners) {
      setFilteredPartners(partners);
    }
  }, [partnersIds]);

  const handleSearch = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value;

      setIsRefetching(true);
      try {
        setFilteredPartners((list) =>
          list.filter(
            (item) =>
              item.firstName.includes(newSearch) ||
              item.lastName.includes(newSearch) ||
              item.document.includes(newSearch) ||
              item.email.includes(newSearch) ||
              item.memberNum === newSearch,
          ),
        );
        setFilter(newSearch);
        setIsRefetching(false);
      } catch (error) {
        setIsRefetching(false);
        enqueueSnackbar('Unable to load search', { variant: 'error' });
      }
    },
    [enqueueSnackbar],
  );

  const handleClose = useCallback(async () => {
    close();
    setIsRefetching(false);
    setFilter('');
  }, [close]);

  return {
    handleClose,
    handleSearch,
    partners: filteredPartners,
    isOpen: type === ModalType.PARTNERS_QUOTA,
    isRefetching,
    filter,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
