import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { permissionsStateVar } from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import useOrganizationDocument from 'apollo/hooks/organization/useOrganizationDocument';
import useDocumentActions from 'apollo/hooks/organization/useDocumentActions';
import { DefaultContractTextV2 } from 'utils/texts/document';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { DocumentCustomDataInputType } from 'apollo/graphql.types';
import type { FormikHelpers } from 'formik';
import type { DocumentFormFields } from 'model/Organization';

const useConnect = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const isNew = documentId === 'new';
  const { document, getDocumentLoading } = useOrganizationDocument(
    !isNew ? documentId : undefined,
  );
  const {
    createDocument,
    updateDocument,
    removeDocument,
    loading: documentActionsLoading,
  } = useDocumentActions();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { openDialog, close: closeModal } = useModal();

  const initialValues = useMemo(
    () => ({
      name: document?.name || '',
      text: document?.text || DefaultContractTextV2,
      hasSignature: document?.hasSignature || false,
      customData: (document?.customData &&
        document?.customData?.length > 0 &&
        document.customData) || [
        { key: '', label: '', inputType: DocumentCustomDataInputType.String },
      ],
    }),
    [document],
  );

  const handleOnSubmit = useCallback(
    async (values: DocumentFormFields) => {
      if (isNew) {
        try {
          await createDocument(values);
          enqueueSnackbar('El documento se ha creado correctamente', {
            variant: 'success',
          });
        } catch (e) {
          enqueueSnackbar(formatErrors('document', e.message, 'crear'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      } else if (!isNew && document?.id) {
        try {
          await updateDocument({ id: document.id, data: values });
          enqueueSnackbar('El documento se ha editado correctamente', {
            variant: 'success',
          });
        } catch (e) {
          enqueueSnackbar(formatErrors('document', e.message, 'actualizar'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      }
      navigate('/organization/documents');
    },
    [
      isNew,
      document,
      navigate,
      createDocument,
      enqueueSnackbar,
      updateDocument,
    ],
  );

  const handleSaveText = useCallback(
    async ({
      text,
      formikHelpers,
    }: {
      text: string;
      formikHelpers: FormikHelpers<DocumentFormFields>;
    }) => {
      formikHelpers.setFieldValue('text', text);
      await formikHelpers.submitForm();
    },
    [],
  );

  const handleConfirmRemoveDocument = useCallback(async () => {
    try {
      if (document?.id) {
        await removeDocument(document.id);
        closeModal();
        navigate('/organization/documents');
        enqueueSnackbar('El documento se ha eliminado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('document', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [closeModal, document, enqueueSnackbar, removeDocument, navigate]);

  const handleRemoveDocument = useCallback(() => {
    if (document?.id) {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar este documento y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: handleConfirmRemoveDocument,
        title: 'Eliminar documento',
        variant: 'danger',
      });
    }
  }, [document, openDialog, handleConfirmRemoveDocument]);

  return {
    employeePermissions,
    handleOnSubmit,
    isNew,
    initialValues,
    handleSaveText,
    handleRemoveDocument,
    isLoading: getDocumentLoading || documentActionsLoading,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
