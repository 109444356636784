import { FC, memo } from 'react';
import { Container, LinkTitle, Icon } from './styles';
import type { Props } from './types';

const SectionLink: FC<Props> = ({
  className,
  toLink,
  title,
  border = 'top',
}) => (
  <Container className={className} to={toLink} $border={border}>
    <LinkTitle>{title}</LinkTitle>
    <Icon name="arrowRight" size={8} />
  </Container>
);

export default memo(SectionLink);
