import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { FormikConfig } from 'formik';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import usePartnerNoteActions from 'apollo/hooks/partner/usePartnerNoteActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';

import type { PartnerNoteFormFields } from 'model/Partner';

const useConnect = () => {
  const { close, type, partnerNotePayload } = useModal();
  const {
    createPartnerNote,
    updatePartnerNote,
    removePartnerNote,
    loading: noteActionLoading,
  } = usePartnerNoteActions();
  const { enqueueSnackbar } = useSnackbar();

  const { note, partnerId } = partnerNotePayload || {};

  const initialValues = useMemo<PartnerNoteFormFields>(
    () => ({
      id: note?.id || '',
      description: note?.description || '',
      showNotification: note?.showNotification || false,
    }),
    [note],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleCreateNote = useCallback<
    FormikConfig<PartnerNoteFormFields>['onSubmit']
  >(
    async (data) => {
      try {
        if (partnerId) {
          const createdNote = await createPartnerNote(partnerId, data);

          if (createdNote) {
            enqueueSnackbar(`La nota ha sido creada correctamente`, {
              variant: 'success',
            });

            // TODO improve flow
            window.location.reload();
            handleClose();
          }
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('note', e.message, 'crear'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [createPartnerNote, partnerId, enqueueSnackbar, handleClose],
  );

  const handleUpdateNote = useCallback<
    FormikConfig<PartnerNoteFormFields>['onSubmit']
  >(
    async (data) => {
      try {
        if (note?.id) {
          await updatePartnerNote({ noteId: note.id, values: data });

          enqueueSnackbar(`La nota ha sido editada correctamente`, {
            variant: 'success',
          });
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('note', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [note, updatePartnerNote, enqueueSnackbar, handleClose],
  );

  const handleRemoveNote = useCallback(async () => {
    try {
      if (note?.id) {
        await removePartnerNote({ noteId: note.id });

        enqueueSnackbar(`La nota ha sido eliminada correctamente`, {
          variant: 'success',
        });
        // TODO improve flow
        window.location.reload();
        handleClose();
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('note', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [note, removePartnerNote, enqueueSnackbar, handleClose]);

  return {
    handleClose,
    handleSubmit: note?.id ? handleUpdateNote : handleCreateNote,
    handleRemoveNote,
    initialValues,
    isOpen: type === ModalType.PARTNER_NOTE,
    isLoading: noteActionLoading,
  };
};

export default useConnect;
