import type { PurchaseDataFragment } from 'apollo/graphql.types';

export const normalizePurchase = (input: PurchaseDataFragment) => {
  const normalizeNumber = (num?: number | null): number | null =>
    (num && Number(num.toFixed(2))) || null;

  return {
    // ID
    id: input.id || '',

    // FIELDS
    canceled: input.canceled || false,
    canceledAt: input.canceledAt ? new Date(input.canceledAt).getTime() : null,
    createdAt: input.createdAt ? new Date(input.createdAt).getTime() : null,
    total: normalizeNumber(input.total) || 0.0,
    totalBenefit: normalizeNumber(input.totalBenefit) || 0.0,
    totalWithoutTaxes: normalizeNumber(input.totalWithoutTaxes) || 0.0,
    totalWithoutDiscounts: normalizeNumber(input.totalWithoutDiscounts) || 0.0,
    mainDiscount: input.mainDiscount?.name || '',
  };
};

export type Purchase = ReturnType<typeof normalizePurchase>;
