import styled, { css } from 'styled-components';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 1rem auto 0 auto;
`;

export const DateTimeFilterContainer = styled.div``;

export const Content = styled.div`
  padding-top: 1rem;
`;

export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;
`;

export const Filter = styled.div<{ $isSelected?: boolean }>`
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  padding: 0.625rem 1.8rem;
  transition: all 200ms ease-out;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 14px;
  text-transform: capitalize;

  ${({ theme, $isSelected }) =>
    $isSelected
      ? css`
          border-color: ${theme.colors.accent};
          color: ${theme.colors.accent};
        `
      : css`
          &:hover {
            border-color: ${({ theme }) => theme.colors.grey900};
            color: ${({ theme }) => theme.colors.primary};
          }
        `}
`;
