import { FC, memo } from 'react';

import type { Props } from './types';

const ArrowLeft: FC<Props> = ({ size = 7, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...rest}
  >
    <g xmlns="http://www.w3.org/2000/svg" data-name="Layer 2">
      <path
        d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"
        data-name="arrow-ios-back"
      />
    </g>
  </svg>
);

export default memo(ArrowLeft);
