import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { normalizeEmployee } from 'model/Employee';
import {
  GetEmployeeQuery,
  GetEmployeeQueryVariables,
} from 'apollo/graphql.types';
import { GET_EMPLOYEE } from 'apollo/queries/employees';

const useEmployee = ({
  id,
  fetchPolicy = 'cache-first',
}: {
  id?: string;
  fetchPolicy?: QueryHookOptions<GetEmployeeQueryVariables>['fetchPolicy'];
}) => {
  const { data, loading } = useQuery<
    GetEmployeeQuery,
    GetEmployeeQueryVariables
  >(GET_EMPLOYEE, {
    fetchPolicy,
    skip: !id,
    variables: { id: id! },
  });

  const employee = useMemo(
    () => (data?.employee ? normalizeEmployee(data.employee) : undefined),
    [data],
  );

  return {
    loading,
    employee,
  };
};

export default useEmployee;
