import styled from 'styled-components';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import { from } from 'styles/responsive';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  margin-bottom: 5rem;
`;

export const Header = styled(DefaultLink)`
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 2rem;
  justify-content: space-between;
  padding-right: 1.0625rem;
  width: 100%;

  ${from.tabletLandscape`
    padding-right: 0;
  `};
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const List = styled.div``;

export const ChevronIcon = styled(DefaultArrowRightIcon)`
  width: 1rem;

  ${from.tabletLandscape`
    opacity: 0.7; 
    width: 0.5rem;
  `};
`;
