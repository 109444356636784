import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useQuotas from 'apollo/hooks/quota/useQuotas';
import useModal from 'apollo/hooks/useModal';
import { useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive/permissionsState';
import useQuotaActions from 'apollo/hooks/quota/useQuotaActions';
import { formatErrors } from 'utils/errors/formatErrors';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import type { Quota } from 'model/Quota';

const useConnect = () => {
  const {
    quotas,
    loading: quotasLoading,
    refetch: refetchQuotas,
  } = useQuotas();
  const { deleteQuota, loading: quotaActionsLoading } = useQuotaActions();
  const { openDialog, openQuota, openPartnersQuota, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const handleRemoveQuota = useCallback(
    async (quota: Quota) => {
      try {
        await deleteQuota(quota.id);
        await refetchQuotas();
        close();
        enqueueSnackbar('La cuota se ha eliminado correctamente.', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('quota', e.message, 'eliminar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [close, deleteQuota, enqueueSnackbar, refetchQuotas],
  );

  const handleOpenRemoveQuotaDialog = useCallback(
    async (quota: Quota) => {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar una cuota y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: () => handleRemoveQuota(quota),
        title: 'Eliminar cuota',
        variant: 'danger',
      });
    },
    [handleRemoveQuota, openDialog],
  );

  return {
    employeePermissions,
    handleOpenPartnerQuotaModal: openPartnersQuota,
    handleOpenQuotaModal: openQuota,
    handleOpenRemoveQuotaDialog,
    isLoading: quotasLoading || quotaActionsLoading,
    quotas,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
