import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';

export const Header = styled.div<{
  $backgroundColor?: string;
  $useLogoBackground: boolean;
}>`
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor || theme.colors.menu.background};
  position: sticky;
  top: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  ${({ $useLogoBackground, $backgroundColor, theme }) =>
    $useLogoBackground &&
    css`
      background-color: ${$backgroundColor || theme.colors.menu.background}F0;
    `}

  ${from.tabletLandscape`
    flex-direction: row;
    justify-content: space-between;
  `};
`;

export const Categories = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  gap: 1rem;
  margin-top: 1.5rem;

  ${from.tabletLandscape`
    flex: 2;
    margin-top: 0;
  `};
`;

export const Category = styled.span<{ $color?: string; $selected?: boolean }>`
  color: ${({ $color, theme }) => $color || theme.colors.menu.categoryName};
  border: 1px solid
    ${({ $color, theme }) => $color || theme.colors.menu.categoryName};
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0.5rem;
  transition: all 200ms ease-in-out;

  ${({ $selected, $color, theme }) =>
    $selected &&
    css`
      color: white;
      background-color: ${$color || theme.colors.menu.categoryName};
    `}
`;

export const Products = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 4rem;
  margin: 5rem auto;
  width: 100%;
  padding: 0 0.8rem;
`;

export const Product = styled.div`
  display: flex;
  width: 100%;
  max-width: 25rem;

  ${from.tabletPortrait`
    max-width: 35rem;
  `};
`;

export const ProductImage = styled.div<{ $color?: string }>`
  border: 4px solid
    ${({ $color, theme }) => $color || theme.colors.menu.imageBorder};
  border-radius: 0.5rem;
  flex: 0 0 35%;
  width: 10rem;
  height: 8rem;

  ${from.tabletPortrait`
    width: 15rem;
    height: 12rem;
  `};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const ProductData = styled.div<{
  $backgroundColor?: string;
  $useLogoBackground: boolean;
}>`
  padding-left: 1rem;
  flex: 0 0 65%;
  max-width: 16rem;

  ${({ $useLogoBackground, $backgroundColor, theme }) =>
    $useLogoBackground &&
    css`
      background-color: ${$backgroundColor || theme.colors.menu.background}E0;
      border-radius: 0.25rem;
      margin-left: 0.5rem;
      padding: 0.5rem 0.8rem;
    `}

  ${from.tabletPortrait`
    max-width: unset;
  `};
`;

export const ProductTitleContent = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const ProductTitle = styled.div<{ $color?: string }>`
  display: flex;
  flex-flow: column;
  color: ${({ $color, theme }) => $color || theme.colors.menu.productTitle};
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.4rem;
`;

export const ProductReference = styled.span`
  font-size: 0.8rem;
  margin-top: 0.3rem;
`;

export const ProductPrice = styled.div<{ $color?: string }>`
  color: ${({ $color, theme }) => $color || theme.colors.menu.productPrice};
  font-weight: 600;
`;

export const ProductPriceContent = styled.div`
  display: flex;
`;

export const ProductDescription = styled.div<{ $color?: string }>`
  color: ${({ $color, theme }) =>
    $color || theme.colors.menu.productDescription};
  margin-top: 1rem;
`;

export const OriginalPrice = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: 600;
  margin-right: 0.5rem;
  position: relative;

  &:before {
    border-color: inherit;
    border-top: 1px solid;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 45%;
    transform: skewY(-10deg);
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
`;

export const OrganizationLogo = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
`;

export const Logo = styled.img`
  max-width: 9rem;
  max-height: 4rem;
`;
