import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';
import { SectionContainer } from 'components/Layout';
import {
  HEADER_CHILDREN_MARGIN,
  HEADER_HEIGHT,
} from 'containers/Common/Header';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  padding-top: calc(${HEADER_HEIGHT} + ${HEADER_CHILDREN_MARGIN});
  padding-bottom: 5rem;
  position: relative;
  max-width: 27rem;

  ${from.tabletPortrait`
    max-width: unset;
  `};

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const Sections = styled.div`
  padding: 0 1.25rem;
`;

export const Section = styled.div`
  margin-top: 3rem;
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const ContentSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  margin-top: 3rem;
  gap: 0.5rem;
`;

export const InfoContent = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  border: solid 1px ${({ theme }) => theme.colors.grey200};
  border-radius: 0.25rem;
  padding: 0.7rem 1rem;
  width: 7rem;
  min-height: 6rem;

  ${from.tabletPortrait`
    min-height: unset;
    width: 12rem;
  `};
`;

export const InfoContentTitle = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  ${from.tabletPortrait`
    font-size: 16px;
  `};
`;

export const InfoContentValue = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  margin-top: 1rem;

  ${from.tabletPortrait`
    font-size: 18px;
  `};
`;

export const CashierActionStatusTitle = styled.div<{ $isOpen?: boolean }>`
  color: ${({ $isOpen, theme }) =>
    $isOpen ? theme.colors.accent : theme.colors.primary};
  margin-bottom: 1.5rem;
  padding: 0 1.25rem;
  line-height: 1.2;
  font-weight: 500;
  white-space: pre-wrap;
`;

export const DateTimeFilterContainer = styled.div`
  padding: 0 1.25rem;
`;
