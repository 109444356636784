import { FC, memo } from 'react';
import CreditConcept from '../../../Modals/CreditConcept';
import {
  BackLink,
  Header,
  SectionTitle,
  HeaderContent,
  CreateButton,
} from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const OrganizationCreditConcepts: FC = () => {
  const {
    employeePermissions,
    conceptCredits,
    handleOpenCreditConceptModal,
    handleOpenRemoveCreditConceptDialog,
  } = useConnect();
  const hasConcepts = conceptCredits.length > 0;

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <BackLink to="/organization" />
            <SectionTitle>Conceptos de donación</SectionTitle>
          </HeaderContent>
          {employeePermissions.allowCreateCreditsConcept && (
            <CreateButton onClick={() => handleOpenCreditConceptModal()}>
              Crear
            </CreateButton>
          )}
        </Header>
        <Main>
          {!hasConcepts && employeePermissions.allowCreateCreditsConcept && (
            <EmptyState
              title="No hay conceptos"
              iconName="discount"
              buttonLabel="Crear Concepto"
              onClickButton={handleOpenCreditConceptModal}
            >
              Crea tu primer concepto
            </EmptyState>
          )}
          {hasConcepts && (
            <>
              <TableHead />
              {conceptCredits.map((concept) => (
                <Row
                  key={concept}
                  employeePermissions={employeePermissions}
                  name={concept}
                  onEdit={() => handleOpenCreditConceptModal({ concept })}
                  onRemove={() => handleOpenRemoveCreditConceptDialog(concept)}
                />
              ))}
            </>
          )}
        </Main>
      </Container>
      <CreditConcept />
    </>
  );
};

export default memo(OrganizationCreditConcepts);
