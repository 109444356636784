import { makeVar } from '@apollo/client';
import { ProductsListType } from '../generated/globalTypes';

export type OrganizationConfigVarData = {
  organizationName: string;
  productsListType: ProductsListType;
  activateRfidReader: boolean;
  activateProductReference: boolean;
  activeStrictCashier: boolean;
  allowExtraQuantity: boolean;
  maxExtraQuantity: number;
  creditConcepts: string[];
  hasDriveIntegration: boolean;
};

export const emptyOrganizationConfig: OrganizationConfigVarData = {
  organizationName: '',
  productsListType: ProductsListType.LANDSCAPE,
  activateRfidReader: false,
  activateProductReference: false,
  activeStrictCashier: false,
  allowExtraQuantity: false,
  maxExtraQuantity: '' as unknown as number,
  creditConcepts: [],
  hasDriveIntegration: false,
};

export const organizationConfigVar = makeVar<OrganizationConfigVarData>(
  emptyOrganizationConfig,
);

export const resetOrganizationConfigVar = () => {
  organizationConfigVar(emptyOrganizationConfig);
};

export const setOrganizationConfigVar = (
  data: Partial<OrganizationConfigVarData> | null = {},
) => {
  organizationConfigVar({ ...emptyOrganizationConfig, ...data });
};

export const updateOrganizationConfigVar = (
  data: Partial<OrganizationConfigVarData>,
) => {
  organizationConfigVar({ ...organizationConfigVar(), ...data });
};
