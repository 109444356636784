import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Note = styled.div`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.note};
`;

export const PartnerNote = styled.div`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.partnerNote};
`;

export const NoteData = styled.p`
  color: ${({ theme }) => theme.colors.grey500};
  display: flex;
  flex-flow: column;
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  white-space: initial;

  ${from.tabletLandscape`
   font-size: 1rem;
`}
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin-left: auto;
  flex: 0 0 ${TABLE_METRICS.date};
  ${TextEllipsis};
  white-space: pre-wrap;

  &:first-letter {
    text-transform: uppercase;
  }

  ${from.tabletLandscape`
   font-size: 1rem;
`}
`;

export const Credits = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.credits};
  ${TextEllipsis};

  ${from.tabletLandscape`
   font-size: 1rem;
`}
`;

export const Employee = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  margin-bottom: 0.4rem;
  ${TextEllipsis};

  &:hover {
    text-decoration: underline;
  }
`;
