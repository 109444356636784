import { apolloClient } from 'services/api';
import { VALIDATE_REMOVED_PARTNER } from 'apollo/queries/partners';
import type { Partner } from 'model/Partner';
import type {
  ValidateRemovedPartner,
  ValidateRemovedPartnerVariables,
} from 'apollo/generated/ValidateRemovedPartner';

const validateRemovedPartner = async (document: Partner['document']) => {
  const { data } = await apolloClient.query<
    ValidateRemovedPartner,
    ValidateRemovedPartnerVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    query: VALIDATE_REMOVED_PARTNER,
    variables: {
      document,
    },
  });

  if (!data) {
    return false;
  }

  return data.validateRemovedPartner;
};

export default validateRemovedPartner;
