import { FC, memo } from 'react';

import type { Props } from './types';

const Chat: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.993 2.75a9.4 9.4 0 015.876 2.06 9.038 9.038 0 013.184 5.139 9.086 9.086 0 01-2.433 8.283c-1.71 1.732-4.008 2.68-6.468 2.68-.867 0-1.977-.222-2.511-.373-.612-.171-1.195-.391-1.334-.443h0l-3.98 1.06c-.262.018-.472-.007-.615-.1h0l.83-3.635c-1.213-1.984-1.792-3.782-1.792-5.63-.007-2.372.922-4.612 2.614-6.31 1.758-1.762 4.11-2.731 6.629-2.731z"
      stroke="#0A2737"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(Chat);
