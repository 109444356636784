import { FC, memo } from 'react';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { FILTER_DATA_OPTIONS } from './constants';
import { SectionTitle, Header, BackLink, HeaderContent } from '../styles';
import {
  Container,
  EmptyState,
  Main,
  DateTimeFilterContainer,
  Content,
  Filter,
  Filters,
} from './styles';

const TransactionsHistory: FC = () => {
  const {
    organizationTransactions,
    isLoading,
    handleRevertTransaction,
    filterDates,
    handleOnSearch,
    dataTypeFilters,
    handleToggleDataType,
    handleOpenPurchaseDetailsModal,
  } = useConnect();

  const hasTransactions = organizationTransactions.length > 0;

  return (
    <Container>
      <Header>
        <HeaderContent>
          <BackLink to="/organization" />
          <SectionTitle>Histórico de transacciones</SectionTitle>
        </HeaderContent>
      </Header>
      <Main>
        <DateTimeFilterContainer>
          <DateTimeFilter
            fromDate={filterDates.from}
            toDate={filterDates.to}
            onSearch={handleOnSearch}
          />
        </DateTimeFilterContainer>
        <Filters>
          {FILTER_DATA_OPTIONS.map((option) => (
            <Filter
              key={option.value}
              $isSelected={dataTypeFilters.includes(option.value)}
              onClick={() => handleToggleDataType(option.value)}
            >
              {option.label}
            </Filter>
          ))}
        </Filters>
        {isLoading && <Loader />}
        {!isLoading && (
          <Content>
            {!hasTransactions && (
              <EmptyState title="No hay transacciones" iconName="organization">
                Debes tener al menos una transacción
              </EmptyState>
            )}

            {hasTransactions && (
              <>
                <TableHead />
                {organizationTransactions.map((transaction) => (
                  <Row
                    key={transaction.id}
                    transaction={transaction}
                    onClickItems={handleOpenPurchaseDetailsModal}
                    onRevert={() =>
                      handleRevertTransaction({
                        id: transaction.id,
                        type: transaction.type,
                      })
                    }
                  />
                ))}
              </>
            )}
          </Content>
        )}
      </Main>
    </Container>
  );
};

export default memo(TransactionsHistory);
