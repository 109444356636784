import { object as yupObject, string as yupString, ref as yupRef } from 'yup';

export const initialValues = {
  currentPin: '' as string,
  newPin: '' as string,
  newPin2: '' as string,
};

export const validationSchema = yupObject({
  currentPin: yupString()
    .matches(/^[0-9]+$/, 'Debe ser un número')
    .max(4, 'Debe tener un máximo de 4 dígitos')
    .min(4, 'Debe tener un mínimo de 4 dígitos')
    .required('Este campo es requerido'),
  newPin: yupString()
    .matches(/^[0-9]+$/, 'Debe ser un número')
    .max(4, 'Debe tener un máximo de 4 dígitos')
    .min(4, 'Debe tener un mínimo de 4 dígitos')
    .required('Este campo es requerido'),
  newPin2: yupString()
    .matches(/^[0-9]+$/, 'Debe ser un número')
    .max(4, 'Debe tener un máximo de 4 dígitos')
    .min(4, 'Debe tener un mínimo de 4 dígitos')
    .oneOf([yupRef('newPin'), null], 'No es el mismo pin')
    .required('Este campo es requerido'),
});
