import styled from 'styled-components';

export const Container = styled.div<{
  $backgroundColor?: string;
  $useLogoBackground: boolean;
}>`
  background-color: ${({ $useLogoBackground, $backgroundColor, theme }) =>
    !$useLogoBackground && ($backgroundColor || theme.colors.menu.background)};
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

export const BackgroundContainer = styled.div<{ $logoUrl: string }>`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -2;

  &::before {
    content: '';
    background-image: ${({ $logoUrl }) =>
      `url(${$logoUrl})`}; // URL de la imagen de fondo
    background-repeat: repeat;
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    transform: rotate(-45deg); // Rotación para crear el patrón diagonal
  }
`;
