import { apolloClient } from 'services/api';
import { GET_PARTNERS_TO_LIST } from 'apollo/queries/partners';
import { normalizePartner, Partner } from 'model/Partner';
import type {
  GetPartners,
  GetPartnersVariables,
} from 'apollo/generated/GetPartners';

const getPartner = async ({
  partnerNum,
  id,
  rfidCode,
}: {
  partnerNum?: Partner['memberNum'];
  id?: Partner['id'];
  rfidCode?: Partner['rfidCode'];
}) => {
  const { data } = await apolloClient.query<GetPartners, GetPartnersVariables>({
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    query: GET_PARTNERS_TO_LIST,
  });

  const partners = data?.partners;
  if (!partners) {
    return undefined;
  }

  const selectedPartner = partners.find(
    (partner) =>
      partner.memberNum === partnerNum ||
      partner.id === id ||
      // @ts-ignore
      partner.rfidCode === rfidCode,
  );

  if (!selectedPartner) {
    return undefined;
  }

  return normalizePartner(selectedPartner);
};

export default getPartner;
