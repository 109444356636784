import { QuotaType } from 'apollo/generated/globalTypes';

export const translateQuotaType = (
  type?: QuotaType,
  interaction?: number,
): string => {
  if (!type || !interaction) {
    return '';
  }

  let translatedType = '';

  switch (type) {
    case QuotaType.ANNUAL:
      translatedType = 'año';
      break;
    case QuotaType.MONTHLY:
      translatedType = 'mes';
      break;
    case QuotaType.WEEKLY:
      translatedType = 'semana';
      break;
    default:
      break;
  }

  const message = `Cada ${interaction} ${translatedType}`;
  const pluralType = type === QuotaType.MONTHLY ? 'es' : 's';

  return interaction > 1 ? `${message}${pluralType}` : message;
};
