import styled from 'styled-components';
import DefaultButton from 'components/Button';

export const Container = styled(DefaultButton)`
  &&& {
    background: transparent;
    border-radius: 50%;
    line-height: 1;
    min-height: auto;
    min-width: auto;
    padding: 0;
    height: 2rem;
    width: 2rem;
    transition: background-color 300ms ease;

    .MuiButton-label {
      line-height: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey200};
    }
  }
`;
