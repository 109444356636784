import { useField } from 'formik';
import { useCallback } from 'react';
import type { ChangeEvent as ReactChangeEvent } from 'react';

import type { LogicProps } from './types';

const useLogic = ({ name }: LogicProps) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);

  const handleChange = useCallback(
    (_: ReactChangeEvent<HTMLInputElement>, checked: boolean) => {
      setValue(checked);
    },
    [setValue],
  );

  return {
    handleChange,
    value,
    touched,
    error,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
