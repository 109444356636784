import { Formik } from 'formik';
import type { FC } from 'react';
import useConnect from './connect';
import { initialValues, validationSchema } from './constants';
import {
  Container,
  ErrorText,
  Form,
  Submit,
  TextField,
  Title,
  Logo,
} from './styles';

const Login: FC = () => {
  const { handleSignIn, loading } = useConnect();

  return (
    <Container>
      <Title>
        <Logo abilityNavigation />
      </Title>
      <Formik
        onSubmit={handleSignIn}
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, errors }) => (
          <Form onSubmit={handleSubmit}>
            <TextField
              key="email"
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              key="password"
              id="password"
              label="Password"
              name="password"
              type="password"
              autoComplete="current-password"
            />
            <Submit isLoading={loading} type="submit">
              Iniciar sesión
            </Submit>
            {errors.submitError && <ErrorText>{errors.submitError}</ErrorText>}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Login;
