import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import DefaultLogo from 'components/Logo';
import { from } from 'styles/responsive';
import { ZIndex } from 'styles/zIndex';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const SIDEBAR_WIDTH = '13rem';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  display: none;
  height: 100vh;
  padding: 1.5rem;
  position: fixed;
  width: ${SIDEBAR_WIDTH};
  z-index: ${ZIndex.SIDEBAR};

  ${from.tabletLandscape`
    display: initial;
  `};
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled(DefaultLogo)`
  width: 5.875rem;
`;

export const Navigation = styled.nav`
  margin-top: 6.5625rem;
`;

export const Label = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}0a;
  flex: 1 1 auto;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  padding-bottom: 1.1875rem;
  padding-top: 1rem;
  text-transform: capitalize;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 24 }))`
  flex: 0 0 auto;
  margin-right: 1.25rem;
  margin-top: -0.2188rem;
`;

export const Link = styled(NavLink)<NavLinkProps>`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.colors.accent};
    ${Label} {
      border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
    }
  }

  &:hover {
    opacity: 0.7;
    &.active {
      opacity: 1;
    }
  }
`;
