import { gql } from '@apollo/client';

export const EXPENSE_FRAGMENT = gql`
  fragment ExpenseData on Expense {
    id
    addedBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    categories {
      ... on Category {
        id
        name
      }
    }
    canceled
    canceledAt
    canceledBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    createdAt
    deletedAt
    description
    name
    price
    quantity
    taxes
    updatedAt
    provider {
      ... on Provider {
        id
        name
      }
    }
  }
`;
