import { FC, memo } from 'react';
import BaseConfig from './BaseConfig';
import PinConfig from './PinConfig';
import IntegrationsConfig from './IntegrationsConfig';
import { BackLink, Header, SectionTitle, HeaderContent } from '../styles';
import {
  Container,
  DesactivateButton,
  FormContent,
  FormContentTitle,
  Icon,
  Main,
} from './styles';
import useConnect from './connect';

const OrganizationConfig: FC = () => {
  const {
    handleConnectIntegration,
    handleDesactivateOrganization,
    handleRemoveIntegration,
    handleSubmit,
    handleSubmitPin,
    initialValues,
    isLoading,
    organization,
  } = useConnect();

  return (
    <Container>
      <Header>
        <HeaderContent>
          <BackLink to="/organization" />
          <SectionTitle>Configuración</SectionTitle>
        </HeaderContent>
      </Header>
      <Main>
        <FormContentTitle>
          <Icon name="organization" /> Configuración Base
        </FormContentTitle>
        <FormContent>
          <BaseConfig initialValues={initialValues} onSubmit={handleSubmit} />
        </FormContent>
        <FormContentTitle>
          <Icon name="organization" /> Pin De Bloqueo
        </FormContentTitle>
        <FormContent>
          <PinConfig onSubmit={handleSubmitPin} isLoading={isLoading} />
        </FormContent>
        <FormContentTitle>
          <Icon name="organization" /> Integraciones
        </FormContentTitle>
        <FormContent>
          <IntegrationsConfig
            onConnect={handleConnectIntegration}
            onRemoveIntegration={handleRemoveIntegration}
            actionLoading={isLoading}
            hasIntegration={organization?.config?.hasDriveIntegration}
          />
        </FormContent>
        <DesactivateButton
          disabled={isLoading}
          onClick={handleDesactivateOrganization}
        >
          Desactivar
        </DesactivateButton>
      </Main>
    </Container>
  );
};

export default memo(OrganizationConfig);
