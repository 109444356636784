import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TABLE_METRICS } from '../constants';

export const Container = styled(Link)`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 0 ${TABLE_METRICS.name};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const CreationDate = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 1 ${TABLE_METRICS.creationDate};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LastUpdateDate = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 1 ${TABLE_METRICS.lastUpdateDate};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
