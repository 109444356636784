import { FC } from 'react';
import { InputLabel, MenuItem } from '@mui/material';
import useLogic from './logic';
import { Container, InputContainer, MaterialSelect } from './styles';
import { ErrorMessage } from '../styles';
import type { Props } from './types';

const Select: FC<Props> = ({
  className,
  disabled,
  id,
  label,
  name,
  options,
  multiple,
  readonly,
}) => {
  const { value, handleChange, error } = useLogic({ name });

  return (
    <Container className={className}>
      <InputContainer fullWidth $isDisabled={disabled} $hasError={!!error}>
        <InputLabel id={`${id}_${label}`}>{label}</InputLabel>
        <MaterialSelect
          labelId={`${id}_${label}`}
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          label={label}
          multiple={multiple}
          readOnly={readonly}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MaterialSelect>
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default Select;
