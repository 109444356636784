import { memo } from 'react';
import type { FC } from 'react';
import Price from 'components/Price';
import {
  Billing,
  Chevron,
  Container,
  Date,
  DownIcon,
  UpIcon,
  Movement,
  Name,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  billing,
  date,
  name,
  movement,
  state,
  style,
}) => {
  return (
    <Container className={className} style={style}>
      <Name>{name}</Name>
      <Billing>
        <Price>{billing}</Price>
      </Billing>
      <Movement>{movement}</Movement>
      <Date>{date}</Date>
      <Chevron>{state ? <UpIcon /> : <DownIcon />}</Chevron>
    </Container>
  );
};

export default memo(Row);
