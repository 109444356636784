import { FC, memo } from 'react';
import Loader from 'components/Loader';
import ProfileForm from './ProfileForm';
import { useConnect } from './connect';
import { Container, Content, Section } from './styles';
import CategoryHeader from './Header';
import CategoryNavigationSidebar from './NavigationSidebar';
import ProductsForm from './ProductsForm';
import { CategorySections } from './constants';

const CategoryDetail: FC = () => {
  const {
    products,
    handleOnSubmit,
    handleOpenRemoveDialog,
    handleSelectedSection,
    handleToggleProduct,
    initialProductsValues,
    initialFormValues,
    isLoading,
    selectedSection,
    categoryId,
    handleOnSubmitProducts,
  } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <CategoryHeader
        name={initialFormValues.name}
        onDelete={handleOpenRemoveDialog}
      />
      <Content>
        <CategoryNavigationSidebar
          categoryId={categoryId}
          onSelectedSection={handleSelectedSection}
        />
        <Section>
          {selectedSection === CategorySections.PROFILE && (
            <ProfileForm
              initialValues={initialFormValues}
              onSubmit={handleOnSubmit}
            />
          )}
          {selectedSection === CategorySections.PRODUCTS && (
            <ProductsForm
              products={products}
              initialValues={initialProductsValues}
              onSubmit={handleOnSubmitProducts}
              onToggleProduct={handleToggleProduct}
            />
          )}
        </Section>
      </Content>
    </Container>
  );
};

export default memo(CategoryDetail);
