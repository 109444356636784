import styled from 'styled-components';
import { SectionContainer } from 'components/Layout';
import DefaultEmptyState from 'components/EmptyState';
import { List as DefaultList } from 'react-virtualized';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  position: relative;
  padding: 0 0 3.6rem 0;
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const ListContent = styled.div`
  margin-top: 1rem;
`;

export const List = styled(DefaultList)`
  outline: none;
`;
