import styled, { css, keyframes } from 'styled-components';

export const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

export const OTPContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const StyledInput = styled.input<{
  $hasError?: boolean;
  $isComplete?: boolean;
}>`
  animation: ${({ $isComplete, $hasError }) =>
    $isComplete && $hasError
      ? css`
          ${shakeAnimation} 0.5s ease-in-out
        `
      : 'none'};
  border: solid 1px ${({ theme }) => theme.colors.primary};
  border-radius: 0.25rem;
  font-size: 1em;
  height: 4em;
  margin: 0 0.2em;
  text-align: center;
  width: 4em;

  ${({ $hasError, $isComplete, theme }) =>
    $isComplete &&
    css`
      border-color: ${$hasError ? theme.colors.danger : theme.colors.green};
    `}
`;
