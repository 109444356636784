import { memo } from 'react';
import type { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import Dropbox from './Dropbox';
import Progress from './Progress';
import {
  Buttons,
  CameraIcon,
  Container,
  Content,
  Dimensions,
  Header,
  IconButton,
  Img,
  Label,
  Text,
  TrashIcon,
} from './styles';
import useLogic from './logic';
import { Props } from './types';

const ImageUploader: FC<Props> = ({
  acceptedDropzoneFiles = {
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'image/png': ['.png'],
  },
  acceptedInputFiles = 'image/jpg,image/jpeg,image/png',
  category,
  extraCategory,
  className,
  fileNameRegExp,
  headerPosition = 'top',
  id,
  imageIdFieldName,
  imageSize,
  label,
  maxSize = 10 * 1024 * 1024,
  name,
  ratio = 1,
  uploadingFlagName,
  uploadValidator,
}) => {
  const {
    contentHeight,
    contentRef,
    errorMessage,
    handleAccept,
    handleReject,
    handleReset,
    imageInfo,
    imgUrl,
    progress,
  } = useLogic({
    acceptedInputFiles,
    category,
    extraCategory,
    fileNameRegExp,
    imageIdFieldName: imageIdFieldName || `${id}ImageId`,
    imageSize,
    maxSize,
    name,
    ratio,
    uploadingFlagName,
    uploadValidator,
  });

  const { getInputProps, getRootProps, isDragActive, isDragReject } =
    useDropzone({
      accept: acceptedDropzoneFiles,
      maxSize,
      onDropAccepted: handleAccept,
      onDropRejected: handleReject,
    });

  const isInProgress = typeof progress === 'number';
  const showDropbox = !isInProgress && !imgUrl;

  return (
    <Container className={className} $headerPosition={headerPosition}>
      <Header $headerPosition={headerPosition}>
        <Buttons $hasImageLoaded={!!imgUrl}>
          <IconButton onClick={handleReset}>
            <TrashIcon />
          </IconButton>
          <IconButton onClick={getRootProps().onClick}>
            <CameraIcon />
          </IconButton>
        </Buttons>
        {(label || imageInfo) && (
          <Text>
            {label && <Label htmlFor={id || name}>{label}</Label>}
            {imageInfo && <Dimensions>{imageInfo}</Dimensions>}
          </Text>
        )}
      </Header>
      <Content $height={contentHeight} ref={contentRef}>
        {isInProgress ? (
          <Progress progress={progress} />
        ) : (
          <>
            <input
              {...getInputProps()}
              accept={acceptedInputFiles}
              id={id || name}
            />
            {!!imgUrl && <Img src={imgUrl} />}
            {showDropbox && (
              <Dropbox
                errorMessage={errorMessage}
                isDragActive={isDragActive}
                isDragReject={isDragReject}
                getRootProps={getRootProps}
              />
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default memo(ImageUploader);
