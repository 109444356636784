import styled, { css } from 'styled-components';
import DefaultLink from 'components/Link';
import DefaultIcon from 'components/Icon';

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.grey500};
  opacity: 0.7;
`;

export const LinkTitle = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
  text-transform: capitalize;
`;

export const Container = styled(DefaultLink)<{ $border?: 'top' | 'bottom' }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ $border, theme }) =>
    $border === 'top'
      ? css`
          border-top: 1px solid ${theme.colors.black}0a;
          padding-top: 1rem;
        `
      : css`
          border-bottom: 1px solid ${theme.colors.black}0a;
          padding-bottom: 1rem;
        `}

  &:hover {
    ${Icon} {
      color: ${({ theme }) => theme.colors.primary};
      opacity: 1;
      transition: color 0.3s ease;
    }
    ${LinkTitle} {
      font-weight: ${({ theme }) => theme.weights.semiBold};
      transition: font-weight 0.2s ease;
    }
  }
`;
