import { parseISO } from 'date-fns';
import type {
  BookingData,
  BookingData_orders,
} from 'apollo/generated/BookingData';
import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import { normalizeOrder, Order } from './Order';

export const getFilteredOrdersAndTotal = (input: Array<BookingData_orders>) => {
  // @ts-ignore
  const orders = removeOptionalsFromArray(input ?? []).map(normalizeOrder);

  const filteredOrders: Order[] = [];
  orders.forEach((order) => {
    const existedIndex = filteredOrders.findIndex(
      (o) => o.productName === order.productName,
    );
    if (filteredOrders?.[existedIndex]?.productId) {
      filteredOrders[existedIndex].quantity += order.quantity;
    } else {
      filteredOrders.push(order);
    }
  });

  const total = filteredOrders.reduce(
    (count, acc) => count + acc.productPrice * acc.quantity,
    0,
  );

  return { orders: filteredOrders, total };
};
export const normalizeBooking = (input: BookingData) => {
  const { orders, total } = getFilteredOrdersAndTotal(input.orders);

  return {
    id: input.id,
    state: input.state,
    toDate: parseISO(input.toDate).getTime(),
    partnerNum: input.partner.memberNum,
    orders,
    total,
  };
};

export type Booking = ReturnType<typeof normalizeBooking>;
