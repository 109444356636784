import { useCallback } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive';

const useConnect = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const {
    close,
    openNewExpense,
    openNewOrder,
    openNewPartner,
    openNewProduct,
    type,
  } = useModal();

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleOpenNewOrder = useCallback(() => {
    openNewOrder();
  }, [openNewOrder]);

  const handleOpenNewPartner = useCallback(() => {
    openNewPartner();
  }, [openNewPartner]);

  const handleOpenNewProduct = useCallback(() => {
    openNewProduct();
  }, [openNewProduct]);

  const handleOpenNewExpense = useCallback(() => {
    openNewExpense();
  }, [openNewExpense]);

  return {
    employeePermissions,
    handleClose,
    handleOpenNewExpense,
    handleOpenNewOrder,
    handleOpenNewPartner,
    handleOpenNewProduct,
    isOpen: type === ModalType.CREATE_MENU,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
