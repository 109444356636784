import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  align-items: center;
  animation: ${blink} 1s linear infinite;
  background: #ffffff;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  height: 3rem;
`;

export const Line = styled.div<{
  $height?: string;
  $isTag?: boolean;
  $width?: string;
}>`
  background-color: #dddddd;
  border-radius: ${({ $isTag }) => ($isTag ? '4px' : '0')};
  height: ${({ $height }) => $height || '0.6rem'};
  overflow: hidden;
  width: ${({ $width }) => $width || '5rem'};

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }
`;
