import styled, { keyframes } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import DefaultIcon from 'components/Icon';

const scan = keyframes`
    0% {
        box-shadow: 0 0 5px 0 rgba(0, 116, 202, 0.4), 0 0 10px 0 rgba(0, 116, 202, 0.4);
    }
    50% {
        box-shadow: 0 0 20px 5px rgba(0, 116, 202, 0.8), 0 0 30px 10px rgba(0, 116, 202, 0.8);
    }
    100% {
        box-shadow: 0 0 5px 0 rgba(0, 116, 202, 0.4), 0 0 10px 0 rgba(0, 116, 202, 0.4);
    }
`;

export const Container = styled(DefaultModal)`
  ${Content} {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: fit-content;
  }
`;

export const Button = styled(DefaultButton)``;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 20,
}))``;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  width: 100%;

  ${from.tabletPortrait`
    flex-flow: row nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'light',
}))`
  &&& {
    width: 100%;
  }
`;

export const Reader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent}0A;
  border-radius: 10px;
  border: 3px solid ${({ theme }) => theme.colors.blue100};
  display: flex;
  height: 200px;
  justify-content: center;
  position: relative;
  width: 200px;
`;

export const Light = styled.div`
  align-items: center;
  animation: ${scan} 1.5s infinite;
  background-color: ${({ theme }) => theme.colors.accent}0A;
  border-radius: 50%;
  color: #0490ff;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
`;
