import styled from 'styled-components';
import { SEARCH_HEIGHT } from 'components/Search/styles';
import {
  HEADER_CHILDREN_MARGIN,
  HEADER_HEIGHT,
} from 'containers/Common/Header';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import { List as DefaultList } from 'react-virtualized';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  padding-top: calc(
    ${HEADER_HEIGHT} + ${HEADER_CHILDREN_MARGIN} + ${SEARCH_HEIGHT}
  );
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const List = styled(DefaultList)`
  outline: none;
`;
