import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useOrganizationDocuments from 'apollo/hooks/organization/useOrganizationDocuments';

const useConnect = () => {
  const { documents, getDocumentsLoading } = useOrganizationDocuments();
  const navigate = useNavigate();

  const handleNavigateToCreationView = useCallback(() => {
    navigate('/organization/documents/new');
  }, [navigate]);

  return {
    isLoading: getDocumentsLoading,
    documents,
    handleNavigateToCreationView,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
