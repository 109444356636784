import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  Container,
  Credits,
  Date,
  Employee,
  LastQuantity,
  MovementType,
  NewQuantity,
  Note,
} from './styles';
import type { Props } from './types';

const TableHead: FC<Props> = ({ className }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <Employee>Empleado</Employee>
      <Date>Fecha</Date>
      {fromTabletPortrait && (
        <>
          <LastQuantity>Última cantidad</LastQuantity>
          <NewQuantity>Nueva cantidad</NewQuantity>
          <MovementType>Tipo</MovementType>
          <Note>Nota</Note>
        </>
      )}
      <Credits>Ajuste</Credits>
    </Container>
  );
};

export default memo(TableHead);
