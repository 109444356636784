import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS, TABLE_MOBILE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${TextEllipsis};

  ${from.tabletPortrait`
  font-size: 1rem;
`}
`;

export const AddedBy = styled(ColumnTitle)`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_MOBILE_METRICS.addedBy};

  ${from.tabletPortrait`
  flex: 0 0 ${TABLE_METRICS.addedBy};
`}
`;

export const LastEditionBy = styled(ColumnTitle)`
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.lastEditionBy};
  ${TextEllipsis};
`;

export const Note = styled(ColumnTitle)`
  margin-right: 1rem;
  flex: 0 0 ${TABLE_MOBILE_METRICS.description};
  ${TextEllipsis};

  ${from.tabletPortrait`
  flex: 0 0 25%;
`}

  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.description};
`}
`;

export const Date = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_MOBILE_METRICS.createdAt};
  ${TextEllipsis};

  ${from.tabletPortrait`
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.createdAt};
`}
`;

export const Notification = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_MOBILE_METRICS.showNotification};
  text-transform: capitalize;
  ${TextEllipsis};

  ${from.tabletPortrait`
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.showNotification};
`}
`;
