import styled from 'styled-components';
import { SEARCH_HEIGHT } from 'components/Search/styles';
import {
  HEADER_CHILDREN_MARGIN,
  HEADER_HEIGHT,
} from 'containers/Common/Header';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';
import DefaultFilters from 'components/Filters';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  padding-top: calc(
    ${HEADER_HEIGHT} + ${HEADER_CHILDREN_MARGIN} + ${SEARCH_HEIGHT} + 2rem
  );
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 1rem;
  `};
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const Filters = styled(DefaultFilters)``;
