import { useCallback, useState } from 'react';

export const useSelectedProducts = () => {
  const [selectedProductIds, setSelectedProductIds] = useState<
    ReadonlySet<string>
  >(new Set());

  const clearSelectedProducts = useCallback(
    () => setSelectedProductIds(new Set()),
    [setSelectedProductIds],
  );

  const getSelectedProducts = useCallback(
    <T extends { id: string }>(products: readonly T[]): T[] =>
      products.filter((it) => selectedProductIds.has(it.id)),
    [selectedProductIds],
  );

  const isProductSelected = useCallback(
    (productId: string) => selectedProductIds.has(productId),
    [selectedProductIds],
  );

  const handleProductClick = useCallback(
    (productId: string) => {
      setSelectedProductIds((prevState) => {
        const nextState = new Set(prevState);

        if (prevState.has(productId)) {
          nextState.delete(productId);
        } else {
          nextState.add(productId);
        }

        return nextState;
      });
    },
    [setSelectedProductIds],
  );

  const selectedProductCount = selectedProductIds.size;

  return {
    clearSelectedProducts,
    getSelectedProducts,
    handleProductClick,
    isProductSelected,
    selectedProductCount,
  };
};
