import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { QuantityMovementType } from 'apollo/graphql.types';
import useProductQuantityHistory from 'apollo/hooks/product/useProductQuantityHistory';
import useModal from 'apollo/hooks/useModal';
import type { ProductQuantityHistory } from 'model/Product';

const dataTypeFilter = [
  QuantityMovementType.Store,
  QuantityMovementType.FromStore,
];

const useConnect = () => {
  const { productId } = useParams<{ productId: string }>();
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startOfDay(subDays(new Date(), 2)),
    to: endOfDay(new Date()),
  });
  const { productQuantityHistories, loading, refetch } =
    useProductQuantityHistory({
      id: productId,
      dataType: dataTypeFilter,
      ...filterDates,
    });
  const { openAdjustProductDetails } = useModal();

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      setFilterDate({ from, to });
      await refetch({
        id: productId,
        filters: {
          dates: {
            fromDate: from || null,
            toDate: to || null,
          },
          dataType: dataTypeFilter,
        },
      });
    },
    [refetch, productId],
  );

  const handleOpenDetailsModal = useCallback(
    async (productQuantityAdjust: ProductQuantityHistory) => {
      openAdjustProductDetails({ productQuantityAdjust });
    },
    [openAdjustProductDetails],
  );

  return {
    isLoading: loading,
    handleOpenDetailsModal,
    adjustHistories: productQuantityHistories,
    filterDates,
    handleOnSearch,
  };
};

export default useConnect;
