import { gql } from '@apollo/client';
import { PURCHASE_FRAGMENT } from 'apollo/fragments/Purchase';

export const GET_PURCHASES = gql`
  query GetPurchases {
    purchases {
      ...PurchaseData
    }
  }
  ${PURCHASE_FRAGMENT}
`;

export const GET_PURCHASE = gql`
  query GetPurchase($id: ID!) {
    purchase(id: $id) {
      ...PurchaseData
    }
  }
  ${PURCHASE_FRAGMENT}
`;
