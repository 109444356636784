import type { FC } from 'react';
import useConnect from './connect';
import {
  Container,
  Header,
  Icon,
  IconCircle,
  Title,
  OTPInput,
  Loader,
} from './styles';

const ValidatePinModal: FC = () => {
  const {
    handleClose,
    isOpen,
    handleOnComplete,
    hasError,
    isValid,
    isLoading,
  } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <Header>
        {isLoading && <Loader size={20} />}
        {!isLoading && (
          <IconCircle>
            <Icon
              $hasError={hasError}
              $isValid={isValid}
              name={isValid ? 'lockOpen' : 'lock'}
            />
          </IconCircle>
        )}

        <Title>Desbloquear vista</Title>
      </Header>
      <OTPInput onComplete={handleOnComplete} hasError={hasError} />
    </Container>
  );
};

export default ValidatePinModal;
