import { gql } from '@apollo/client';
import { EXPENSE_FRAGMENT } from 'apollo/fragments/Expense';

export const CREATE_EXPENSE = gql`
  mutation CreateExpense($data: CreateExpenseDto!) {
    createExpense(data: $data) {
      ...ExpenseData
    }
  }
  ${EXPENSE_FRAGMENT}
`;

export const UPDATE_EXPENSE = gql`
  mutation UpdateExpense($id: ID!, $data: UpdateExpenseDto!) {
    updateExpense(id: $id, data: $data) {
      ...ExpenseData
    }
  }
  ${EXPENSE_FRAGMENT}
`;

export const REMOVE_EXPENSE = gql`
  mutation RemoveExpense($id: ID!) {
    removeExpense(id: $id)
  }
`;

export const CANCEL_EXPENSE = gql`
  mutation CancelExpense($id: ID!) {
    cancelExpense(id: $id) {
      ...ExpenseData
    }
  }
  ${EXPENSE_FRAGMENT}
`;
