import { FC, memo } from 'react';
import {
  Container,
  Price,
  Name,
  Quantity,
  CreatedDate,
  Employee,
  IconsSpace,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Name>Nombre</Name>
      <Employee>Empleado</Employee>
      <Price>Precio</Price>
      <Quantity>Cantidad</Quantity>
      <CreatedDate>Fecha</CreatedDate>
      <IconsSpace />
    </Container>
  );
};

export default memo(Row);
