import { Form } from 'formik';
import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultBackLink from 'components/BackLink';
import DefaultButton from 'components/Button';
import { SectionHeader } from 'components/Layout';
import DefaultTextField from 'components/Inputs/TextField';

export const Container = styled(Form)``;

export const BackLink = styled(DefaultBackLink)``;

export const DeleteButton = styled(DefaultButton).attrs(() => ({
  variant: 'danger',
}))``;

export const Header = styled(SectionHeader)`
  ${from.tabletLandscape`
    margin-bottom: 5rem;
  `}
`;

export const HeaderButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-left: auto;

  > :not(:last-child) {
    margin-right: 1rem !important;
  }
  > * {
    flex: 0 0 8rem;

    ${from.desktop`
      flex: 0 1 12.5rem;
    `}
  }
`;

export const HeaderInfo = styled.div`
  flex: 1 0 auto;
  margin-left: 1rem;
`;

export const HeaderName = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MobileButtons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;

  > * {
    margin-bottom: 1rem !important;
  }
`;

export const Row = styled.div`
  display: block;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${from.tabletLandscape`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;

export const TextField = styled(DefaultTextField)`
  margin-bottom: 0.5rem;
  ${from.tabletLandscape`
    margin-bottom: 0;
  `}
`;

export const Categories = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 2rem;
  max-width: 40rem;

  ${from.tabletPortrait`
    max-width: unset;
  `}
`;

export const CategoriesTitle = styled.h3`
  font-size: 0.9rem;
`;

export const CategoriesNames = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-top: 0.8rem;
`;
