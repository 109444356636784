import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultDoneIcon from 'components/Icon/Icons/Done';
import DefaultPrice from 'components/Price';

export const Thumbnail = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  height: 3.5rem;
  justify-content: center;
  width: 3.5rem;
`;

export const Container = styled.a<{ $isSelected: boolean }>`
  align-items: center;
  border: 1px solid rgba(245, 245, 245, 1);
  cursor: pointer;
  display: flex;
  flex-flow: column;
  padding: 1.25rem;
  height: 9.5rem;
  width: 100%;

  ${from.tabletPortrait`
    width: 17.25rem;
  `};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey500}0a;
  }

  ${({ $isSelected, theme }) => css`
    ${theme.colors.products
      .map((color, index) => {
        return `
          :nth-child(${index + 1}n) {
            ${
              $isSelected
                ? `
                background-color: ${color}14;
                
                &:hover {
                  background-color: ${color}28;
                }
              `
                : ''
            }

            ${Thumbnail} {
              ${$isSelected ? '' : `background-color: ${color}14;`}
              color: ${color};
            }
          }
        `;
      })
      .join('\n')}
  `};
`;

export const InfoProduct = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  flex: 1;
  text-transform: capitalize;
`;

export const ReferenceCode = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: 0.25rem;
`;

export const Price = styled(DefaultPrice)`
  color: ${({ theme }) => theme.colors.grey900};
  margin-top: 0.25rem;
`;

export const DoneIcon = styled(DefaultDoneIcon)`
  width: 50%;
`;

export const ImageContainer = styled.div`
  width: 110px;
  height: 110px;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;
