import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { from } from 'styles/responsive';
import DefaultTrashIcon from 'components/Icon/Icons/Trash';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  cursor: pointer;
  display: flex;
  transition: background-color 200ms ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;

export const Content = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  height: 100%;
  width: 100%;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  flex: 0 1 ${TABLE_METRICS.name};
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const Price = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.price};
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const Employee = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.employee};
  ${TextEllipsis};

  &:hover {
    text-decoration: underline;
  }

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const Quantity = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.quantity};
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const CreatedDate = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.createdDate};
  ${TextEllipsis};
  white-space: pre-wrap;

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const Icons = styled.div`
  align-items: center;
  display: flex;
  text-align: right;
  padding-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.chevron};
`;

export const TrashIcon = styled(DefaultTrashIcon).attrs(() => ({
  size: 16,
}))`
  color: ${({ theme }) => theme.colors.danger};
  cursor: pointer;
`;
