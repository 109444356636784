import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { GET_ORGANIZATION_MENUS } from 'apollo/queries';
import { userRoleVar } from 'apollo/reactive/userRole';
import { UserRole } from 'apollo/generated/globalTypes';
import { normalizeOrganizationMenuConfig } from 'model/Organization';
import type { GetOrganizationMenusQuery } from 'apollo/graphql.types';

const useMenusConfig = () => {
  const userRole = useReactiveVar(userRoleVar);
  const { data, loading } = useQuery<GetOrganizationMenusQuery>(
    GET_ORGANIZATION_MENUS,
    {
      skip: userRole === UserRole.EMPLOYEE,
    },
  );

  const menus = useMemo(
    () =>
      data?.organizationMenus && data?.organizationMenus?.length > 0
        ? data.organizationMenus.map(normalizeOrganizationMenuConfig)
        : [],
    [data],
  );

  return {
    getMenusLoading: loading,
    menus,
  };
};

export default useMenusConfig;
