import { FC, memo, useCallback, useState, useRef } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import html2pdf from 'html2pdf.js';
import { ButtonsContainer, Button, Container } from './styles';
import type { Props } from './types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.css';

const TextEditor: FC<Props> = ({
  allowSave = false,
  onSave,
  defaultContent = '',
  disableButton,
  isLoadingButton,
}) => {
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState<EditorState>(() => {
    const contentState = stateFromHTML(defaultContent);
    return EditorState.createWithContent(contentState);
  });

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const handleOnSave = useCallback(() => {
    const currentContent = editorState.getCurrentContent();
    const contentAsText = stateToHTML(currentContent);
    onSave(contentAsText);
  }, [editorState, onSave]);

  const handleDownloadPDF = useCallback(() => {
    const currentContent = editorState.getCurrentContent();
    if (currentContent) {
      const contentAsText = stateToHTML(currentContent);
      html2pdf()
        .from(contentAsText)
        .set({
          margin: [15, 10],
        })
        .save('plantilla_documento.pdf');
    }
  }, [editorState]);

  return (
    <Container>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        ref={editorRef}
      />
      <ButtonsContainer>
        {allowSave && (
          <Button
            onClick={handleOnSave}
            disabled={disableButton}
            isLoading={isLoadingButton}
          >
            Guardar cambios
          </Button>
        )}

        <Button variant="light" onClick={handleDownloadPDF}>
          Generar PDF
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default memo(TextEditor);
