import { FC, memo } from 'react';
import { Container, ProgressBar, Percent } from './styles';
import { Props } from './types';

const Progress: FC<Props> = ({ progress }) => (
  <Container>
    <ProgressBar completedPercent={progress || 0} />
    <Percent>{progress ?? '--'} %</Percent>
  </Container>
);

export default memo(Progress);
