import { memo } from 'react';
import type { FC } from 'react';
import { Container, Name } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, document, onSelect }) => {
  return (
    <Container className={className} onClick={() => onSelect(document)}>
      <Name>{document.name}</Name>
    </Container>
  );
};

export default memo(Row);
