import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { FormikProps } from 'formik';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useCategoryActions from 'apollo/hooks/category/useCategoriesActions';
import {
  productStateVar,
  updateProductStateVar,
} from 'apollo/reactive/productState';
import {
  expenseStateVar,
  updateExpenseStateVar,
} from 'apollo/reactive/expenseState';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { Category, EditCategoriesFields } from 'model/Category';

const useConnect = () => {
  const { selectCategoriesPayload, type } = useModal();
  const { createCategory, bulkUpdateCategories, removeCategory, loading } =
    useCategoryActions();
  const {
    categories,
    onSelectCategories,
    onReturn,
    onRefetch,
    type: categoryType,
  } = selectCategoriesPayload || {};
  const { enqueueSnackbar } = useSnackbar();
  const { categoryIds: productCategoriesId } = useReactiveVar(productStateVar);
  const { categoryIds: expenseCategoriesId } = useReactiveVar(expenseStateVar);
  const [categoriesList, setCategoriesList] = useState<
    (Omit<Category, 'type' | 'description' | 'products'> & {
      isChecked: boolean;
    })[]
  >([]);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryToRemove, setCategoryToRemove] = useState<string>('');
  const editCategoriesFormikRef =
    useRef<FormikProps<EditCategoriesFields>>(null);

  const categoryIds = useMemo(
    () => productCategoriesId || expenseCategoriesId || [],
    [expenseCategoriesId, productCategoriesId],
  );

  useEffect(() => {
    if (categories) {
      setCategoriesList(categories);
    }
  }, [categories]);

  const initialEditableFormValues = useMemo<EditCategoriesFields>(() => {
    const initialValues: EditCategoriesFields = { categories: [] };
    categoriesList.map((c) =>
      initialValues.categories.push({ id: c.id, name: c.name }),
    );
    return initialValues;
  }, [categoriesList]);

  const getOnlyUpdatedCategories = useCallback(
    (
      values: EditCategoriesFields,
      currentCategoriesState: EditCategoriesFields['categories'],
    ) => {
      const categoriesToUpdate: EditCategoriesFields['categories'] = [];

      values?.categories.forEach((category) => {
        const c = currentCategoriesState.find(
          (c) => c.id === category.id && c.name.trim() !== category.name.trim(),
        );

        if (c) {
          categoriesToUpdate.push({
            id: category.id,
            name: category.name.trim(),
          });
        }
      });

      return categoriesToUpdate;
    },
    [],
  );

  const updateSelectedCategories = useCallback(
    (editedCategories: EditCategoriesFields['categories']) => {
      const updatedCategories = categoriesList.map((c) => {
        const currentCategory = editedCategories.find((ec) => ec.id === c.id);
        if (currentCategory) {
          return { ...currentCategory, isChecked: false };
        }
        return { ...c, isChecked: false };
      });

      setCategoriesList(updatedCategories);
    },
    [categoriesList],
  );

  const updateCategoriesList = useCallback(
    (category: Category) => {
      setCategoriesList((list) => [
        {
          id: category.id || '',
          name: category.name || '',
          isChecked: true,
        },
        ...list,
      ]);

      const data = { categoryIds: [...categoryIds, category.id] };
      updateProductStateVar(data);
      updateExpenseStateVar(data);
    },
    [categoryIds],
  );

  const handleCreateCategory = useCallback(
    async (values: { newCategory: string }) => {
      try {
        if (values.newCategory && categoryType) {
          const category = await createCategory({
            name: values.newCategory,
            type: categoryType,
          });
          if (category) {
            updateCategoriesList(category);
          }
          enqueueSnackbar(
            `La categoría ${values.newCategory} ha sido creado correctamente`,
            { variant: 'success' },
          );
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('category', e.message, 'crear'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [categoryType, createCategory, enqueueSnackbar, updateCategoriesList],
  );

  const handleEditCategories = useCallback(
    async (values: EditCategoriesFields) => {
      try {
        const categoriesToUpdate = getOnlyUpdatedCategories(
          values,
          initialEditableFormValues.categories,
        );
        if (categoriesToUpdate?.length > 0) {
          const updatedCategories = await bulkUpdateCategories(
            categoriesToUpdate,
          );
          if (updatedCategories) {
            updateSelectedCategories(updatedCategories);
            enqueueSnackbar(`Se han editado las categorías correctamente`, {
              variant: 'success',
            });
          }
          setCategoriesList((values) =>
            values.map((v) => {
              const updated = categoriesToUpdate.find((c) => v.id === c.id);
              if (updated) {
                return {
                  ...v,
                  name: updated.name,
                };
              }
              return v;
            }),
          );
          if (onRefetch) {
            onRefetch();
          }
        }
        setIsEdit(false);
      } catch (e) {
        enqueueSnackbar(formatErrors('category', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [
      bulkUpdateCategories,
      enqueueSnackbar,
      getOnlyUpdatedCategories,
      initialEditableFormValues,
      onRefetch,
      updateSelectedCategories,
    ],
  );

  const handleRemoveCategory = useCallback(async () => {
    try {
      if (categoryToRemove) {
        await removeCategory(categoryToRemove);
        setIsEdit(false);
        setCategoriesList((values) =>
          values.filter((v) => v.id !== categoryToRemove),
        );
        if (onRefetch) {
          onRefetch();
        }
        enqueueSnackbar(`Se ha eliminado la categoría correctamente`, {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('category', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [categoryToRemove, enqueueSnackbar, removeCategory, onRefetch]);

  const actions = {
    selectCategoryToRemove: useCallback((id: string) => {
      setCategoryToRemove(id);
    }, []),
    removeCategory: useCallback(async () => {
      await handleRemoveCategory();
    }, [handleRemoveCategory]),
    notRemoveCategory: useCallback(() => {
      setCategoryToRemove('');
    }, []),
    toggleEdit: useCallback(async () => {
      if (!isEdit) {
        if (categoriesList.length > 0) {
          setIsEdit(true);
        }
      } else if (editCategoriesFormikRef?.current?.values) {
        const { values } = editCategoriesFormikRef.current;
        await handleEditCategories(values);
      }
    }, [categoriesList, handleEditCategories, isEdit]),
    toggleSelect: useCallback(
      (id: string) => {
        if (categoryIds) {
          const selected = categoryIds.find((c) => c === id);
          if (selected) {
            const newList = categoryIds.filter((c) => c !== id) || [];
            const data = { categoryIds: newList };
            updateProductStateVar(data);
            updateExpenseStateVar(data);
          } else {
            const data = { categoryIds: [...categoryIds, id] };
            updateProductStateVar(data);
            updateExpenseStateVar(data);
          }
        }
      },
      [categoryIds],
    ),
    finish: useCallback(() => {
      if (onSelectCategories) {
        onSelectCategories();
      }
    }, [onSelectCategories]),
    close: useCallback(() => {
      if (onReturn) {
        onReturn();
      }
    }, [onReturn]),
  };

  return {
    actions,
    categories: categoriesList,
    categoryToRemove,
    editCategoriesFormikRef,
    handleCreateCategory,
    handleEditCategories,
    handleRemoveCategory,
    isOpen: type === ModalType.SELECT_CATEGORIES,
    isLoading: loading,
    isEdit,
    initialEditableFormValues,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
