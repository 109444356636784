import { gql } from '@apollo/client';
import { PROVIDER_FRAGMENT } from 'apollo/fragments/Provider';

export const CREATE_PROVIDER = gql`
  mutation CreateProvider($data: CreateProviderDto!) {
    createProvider(data: $data) {
      ...ProviderData
    }
  }
  ${PROVIDER_FRAGMENT}
`;

export const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($id: ID!, $data: UpdateProviderDto!) {
    updateProvider(id: $id, data: $data) {
      ...ProviderData
    }
  }
  ${PROVIDER_FRAGMENT}
`;

export const REMOVE_PROVIDER = gql`
  mutation RemoveProvider($id: ID!) {
    removeProvider(id: $id)
  }
`;
