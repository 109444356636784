import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 1rem 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;

  ${from.tabletLandscape`
  padding: 1.0625rem 0 1.1875rem 0;
`}
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey500};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${TextEllipsis};
`;

export const Employee = styled(ColumnTitle)`
  margin-right: 0.5rem;
  flex: 0 0 33%;

  ${from.tabletPortrait`
   flex: 0 0 ${TABLE_METRICS.employee};
 `}
`;

export const LastQuantity = styled(ColumnTitle)`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.lastQuantity};
`;

export const NewQuantity = styled(ColumnTitle)`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.newQuantity};
`;

export const MovementType = styled(ColumnTitle)`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.lastBaseWeight};
`;

export const Note = styled(ColumnTitle)`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.newBaseWeight};
`;

export const Credits = styled(ColumnTitle)`
  margin-left: auto;
  flex: 0 0 33%;
  ${TextEllipsis};

  ${from.tabletPortrait`
   margin-right: 1rem;
   flex: 0 0 ${TABLE_METRICS.credits};
 `}
`;

export const Date = styled(ColumnTitle)`
  text-align: left;
  flex: 0 0 33%;
  ${TextEllipsis};

  ${from.tabletPortrait`
   flex: 0 0 ${TABLE_METRICS.date};
 `}
`;
