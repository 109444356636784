import { FC, memo } from 'react';
import { Container, Name } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, name, id }) => {
  return (
    <Container to={`/organization/permissions/${id}`} className={className}>
      <Name>{name}</Name>
    </Container>
  );
};

export default memo(Row);
