import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey700}1A;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.medium};
  ${TextEllipsis};
`;

export const Name = styled(ColumnTitle)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.name};
`;

export const Products = styled(ColumnTitle)`
  flex: 0 0 22%;
  margin-right: 0.5rem;
  ${TextEllipsis};

  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.products};
`}
`;

export const AddedCredits = styled(ColumnTitle)`
  flex: 0 0 10%;
  ${TextEllipsis};
  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.addedCredits};
`}
`;

export const Download = styled.div`
  cursor: pointer;
  text-align: right;
  margin-left: auto;
  flex: 0 0 1rem;

  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.button};
`}
`;

export const DownloadButton = styled(DefaultButton)`
  &&& {
    padding: 0.375rem 0.813rem;
    font-size: 1rem;
    text-transform: capitalize;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
