import { FC, memo } from 'react';

import type { Props } from './types';

const MoreSelected: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fill="#0A2737" fillRule="evenodd" stroke="#0A2737">
      <circle cx="12" cy="12" r="1.5" />
      <circle cx="5" cy="12" r="1.5" />
      <circle cx="19" cy="12" r="1.5" />
    </g>
  </svg>
);

export default memo(MoreSelected);
