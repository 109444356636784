import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useEmployeeActions from 'apollo/hooks/employee/useEmployeeActions';
import useEmployees from 'apollo/hooks/employee/useEmployees';
import usePermissionsGroups from 'apollo/hooks/permissionsGroup/usePermissionsGroups';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { EmployeeFormFields } from 'model/Employee';

const useConnect = () => {
  const { close, type } = useModal();
  const { createEmployee, loading: employeeActionsLoading } =
    useEmployeeActions();
  const { refetch } = useEmployees();
  const { permissionsGroups, getPermissionsGroupsLoading } =
    usePermissionsGroups();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo<EmployeeFormFields>(
    () => ({
      address: '',
      permissionsGroupId: '',
      document: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      phoneNumber: '',
      isActive: true,
    }),
    [],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<EmployeeFormFields>['onSubmit']
  >(
    async (data: EmployeeFormFields, { setErrors }) => {
      try {
        await createEmployee(data);
        enqueueSnackbar(
          `El empleado ${data.firstName} ${data.lastName} ha sido creado correctamente`,
          { variant: 'success' },
        );
        refetch();
        close();
      } catch (e) {
        const formattedError = formatErrors('employee', e.message, 'crear');
        if (formattedError.includes('email')) {
          setErrors({ email: formattedError });
        }
        enqueueSnackbar(formattedError, {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [createEmployee, enqueueSnackbar, close, refetch],
  );

  const permissionsGroupsOptions = useMemo(() => {
    const permissionsOptions = permissionsGroups.map((permissionsGroup) => ({
      value: permissionsGroup.id,
      label: permissionsGroup.name,
    }));

    return [{ value: 'admin', label: 'Admin' }, ...permissionsOptions];
  }, [permissionsGroups]);

  return {
    handleSubmit,
    handleClose,
    initialValues,
    permissionsGroupsOptions,
    isLoading: employeeActionsLoading || getPermissionsGroupsLoading,
    isOpen: type === ModalType.NEW_EMPLOYEE,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
