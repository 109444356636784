import { memo, FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import {
  Benefit,
  Container,
  Costs,
  Discounts,
  Name,
  Purchases,
  Quantity,
  RemainingQuantity,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, item }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <Name>TOTAL</Name>
      {fromTabletPortrait && <Quantity />}
      <Discounts>
        <Price>{item?.totalDiscounts || 0.0}</Price>
      </Discounts>
      <Purchases>
        <Price>{item?.totalPurchases || 0.0}</Price>
      </Purchases>
      <Costs>
        <Price>{item?.totalCosts || 0.0}</Price>
      </Costs>
      <Benefit $isNegative={(item?.totalBenefit || 0) < 0}>
        <Price>{item?.totalBenefit || 0.0}</Price>
      </Benefit>
      {fromTabletPortrait && <RemainingQuantity />}
    </Container>
  );
};

export default memo(Row);
