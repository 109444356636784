import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultIcon from 'components/Icon';
import { TABLE_METRICS, TABLE_MOBILE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

export const Content = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  height: 100%;
  width: 100%;
`;

const TextEllipsis = css`
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;

  ${from.tabletPortrait`
  font-size: 1rem;
`}
`;

export const AddedBy = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_MOBILE_METRICS.addedBy};
  ${TextEllipsis};

  ${from.tabletPortrait`
  flex: 0 0 ${TABLE_METRICS.addedBy};
`}
`;

export const LastEditedName = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.lastEditionBy};
  ${TextEllipsis};
`;

export const Note = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 1rem;
  flex: 0 0 ${TABLE_MOBILE_METRICS.description};
  ${TextEllipsis};
  white-space: pre-wrap;

  ${from.tabletPortrait`
  flex: 0 0 25%;
`}

  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.description};
`}
`;

export const Identifier = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_MOBILE_METRICS.createdAt};
  ${TextEllipsis};
  white-space: pre-wrap;

  ${from.tabletPortrait`
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.createdAt};
`}
`;

export const Notification = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_MOBILE_METRICS.showNotification};
  ${TextEllipsis};

  ${from.tabletPortrait`
  flex: 0 0 ${TABLE_METRICS.showNotification};
`}
`;

export const ActiveIcon = styled(DefaultIcon).attrs(() => ({
  name: 'done',
  size: 24,
}))`
  color: ${({ theme }) => theme.colors.green};
`;

export const InactiveIcon = styled(DefaultIcon).attrs(() => ({
  name: 'close',
  size: 24,
}))`
  color: ${({ theme }) => theme.colors.danger};
`;
