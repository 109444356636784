import type { FC } from 'react';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  Container,
  Description,
  IconCircle,
  Icon,
  Title,
} from './styles';

const Dialog: FC = () => {
  const {
    acceptButtonText,
    cancelButtonText,
    description,
    handleAccept,
    handleClose,
    iconName,
    isAcceptButtonClick,
    isOpen,
    title,
    variant,
  } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon name={iconName} />
      </IconCircle>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Buttons>
        <CancelButton onClick={handleClose}>{cancelButtonText}</CancelButton>
        {acceptButtonText && (
          <AcceptButton
            $danger={variant === 'danger'}
            disabled={isAcceptButtonClick}
            isLoading={isAcceptButtonClick}
            onClick={handleAccept}
          >
            {acceptButtonText}
          </AcceptButton>
        )}
      </Buttons>
    </Container>
  );
};

export default Dialog;
