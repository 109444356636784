import { object as yupObject, string as yupString } from 'yup';

export const validationSchema = yupObject({
  email: yupString()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  firstName: yupString().required('This field is required.'),
  lastName: yupString().required('This field is required.'),
  document: yupString().required('This field is required.'),
  address: yupString(),
  phoneNumber: yupString(),
});
