import styled from 'styled-components';

export const Container = styled.button`
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  background-color: transparent;
  cursor: pointer;
  padding: 0.4rem;
  font-size: 0.8rem;
  text-align: unset;
  transition: background-color 300ms ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
