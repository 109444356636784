import { FC, memo } from 'react';

import type { Props } from './types';

const Quota: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="jam jam-ghost-org-circle"
    preserveAspectRatio="xMinYMin"
    viewBox="-2 -2 24 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={size}
    height={size}
    {...rest}
  >
    <path
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"
    />
    <path
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      d="M5.024 12.655h3.92v1.887h-3.92zm5.881 0h3.918v1.887h-3.918zM5.021 8.881h9.802v1.887H5.021zm.003-3.774h5.881v1.887H5.024zm7.841 0h1.96v1.887h-1.96z"
    />
  </svg>
);

export default memo(Quota);
