import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import useProductTransactions from 'apollo/hooks/product/useProductTransactions';
import useProductStatistics from 'apollo/hooks/product/useProductStatistics';

const useConnect = () => {
  const { productId } = useParams<{ productId: string }>();
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startOfDay(subDays(new Date(), 2)),
    to: endOfDay(new Date()),
  });
  const { productTransactions, loading, refetch } = useProductTransactions({
    id: productId,
    filters: filterDates,
  });
  const {
    productStatistics,
    loading: statisticsLoading,
    refetch: refetchStatistics,
  } = useProductStatistics({
    id: productId,
    filters: filterDates,
  });

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      setFilterDate({ from, to });
      await refetch({
        id: productId,
        filters: {
          fromDate: from || null,
          toDate: to || null,
        },
      });
      await refetchStatistics({
        id: productId,
        filters: {
          fromDate: from || null,
          toDate: to || null,
        },
      });
    },
    [refetch, refetchStatistics, productId],
  );

  return {
    isLoading: loading || statisticsLoading,
    transactions: productTransactions?.transactions || [],
    filterDates,
    productStatistics,
    handleOnSearch,
  };
};

export default useConnect;
