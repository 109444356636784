import { FC, memo } from 'react';
import type { Props } from './types';

const Substract: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    height={size}
    width={size}
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M1 24h48v2H1z" stroke="currentColor" />
  </svg>
);

export default memo(Substract);
