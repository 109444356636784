import { FC, memo } from 'react';
import { Button, Container, Icon, IconCircle, Text, Title } from './styles';
import type { Props } from './types';

const EmptyState: FC<Props> = ({
  title,
  children,
  onClickButton,
  className,
  buttonLabel,
  iconName,
}) => {
  return (
    <Container className={className}>
      {iconName && (
        <IconCircle>
          <Icon name={iconName} />
        </IconCircle>
      )}
      {title && <Title>{title}</Title>}
      <Text>{children}</Text>
      {onClickButton && buttonLabel && (
        <Button onClick={onClickButton}>{buttonLabel}</Button>
      )}
    </Container>
  );
};

export default memo(EmptyState);
