import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetPartnerVariables, GetPartner } from 'apollo/generated/GetPartner';
import { useMemo } from 'react';
import { normalizePartner } from 'model/Partner';
import { GET_PARTNER } from 'apollo/queries';

const usePartner = ({
  id,
  fetchPolicy = 'network-only',
}: {
  id?: string;
  fetchPolicy?: QueryHookOptions<GetPartnerVariables>['fetchPolicy'];
}) => {
  const { data, loading, refetch } = useQuery<GetPartner, GetPartnerVariables>(
    GET_PARTNER,
    {
      fetchPolicy,
      skip: !id,
      variables: { id: id! },
    },
  );

  const partner = useMemo(
    () => (data?.partner ? normalizePartner(data.partner) : undefined),
    [data],
  );

  return {
    loading,
    partner,
    refetchPartner: refetch,
  };
};

export default usePartner;
