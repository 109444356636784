import { useCallback } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, cashierPartnersPayload, type } = useModal();
  const { partners } = cashierPartnersPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  return {
    handleClose,
    isOpen: type === ModalType.CASHIER_PARTNERS,
    partners,
  };
};

export default useConnect;
