import styled from 'styled-components';
import FloatingAction from 'components/FloatingAction';
import DefaultPrice from 'components/Price';

export const Container = styled(FloatingAction)`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span``;

export const Price = styled(DefaultPrice)``;
