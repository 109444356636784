import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultIcon from 'components/Icon';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled(DefaultModal)`
  ${Content} {
    position: relative;

    ${from.tabletPortrait`
      max-width: 36rem;
      position: unset;
    `}
  }
`;

export const TextField = styled(DefaultTextField)`
  padding: 0 0.5rem;
`;

export const Button = styled(DefaultButton)``;

export const Form = styled.form``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const FormContent = styled.div``;

export const Inputs = styled.div``;

export const Icon = styled(DefaultIcon)`
  cursor: pointer;
`;

export const EditButton = styled(DefaultButton).attrs(() => ({
  variant: 'text',
}))`
  &&& {
    width: 1rem;
  }
`;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Buttons = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-flow: row;
  position: absolute;
  margin: 2.5rem 0 1rem;

  > * {
    flex: 1 0 auto;
  }

  ${from.tabletPortrait`
      margin: 2.5rem 0 0;
      position: unset;
  `}
`;

export const AcceptButton = styled(DefaultButton)`
  &&& {
    width: 100%;

    ${from.tabletPortrait`
      width: unset !important;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    margin-right: 0.8rem;
    width: 100%;

    ${from.tabletPortrait`
      width: unset !important;
    `}
  }
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const CategoriesContainer = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 2rem;
    background: linear-gradient(white, rgba(255, 255, 255, 0.001));
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  }
`;

export const CategoriesList = styled.div`
  max-height: 33rem;
  overflow-y: scroll;
  padding: 0 0.5rem;

  ${from.tabletPortrait`
    max-height: 20rem;
    min-height: 12rem;
  `}
`;

export const Space = styled.div`
  height: 1rem;
`;

export const RemoveConfirmation = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
`;

export const RemoveConfirmationText = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const RemoveConfirmationIcons = styled.div`
  align-items: center;
  display: flex;
`;

export const RemoveCancelIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const RemoveAcceptIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.green};
  margin-right: 1rem;
`;
