import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Header, Icon, IconCircle, Title } from './styles';

const NewEmployee: FC = () => {
  const {
    handleClose,
    isOpen,
    initialValues,
    isLoading,
    handleSubmit,
    permissionsGroupsOptions,
  } = useConnect();

  return (
    <Container
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={handleClose}
    >
      <Header>
        <IconCircle>
          <Icon name="employees" />
        </IconCircle>
        <Title>Empleado nuevo</Title>
      </Header>

      <Form
        onSubmit={handleSubmit}
        onCancel={handleClose}
        initialValues={initialValues}
        isLoading={isLoading}
        permissionsGroupsOptions={permissionsGroupsOptions}
      />
    </Container>
  );
};

export default NewEmployee;
