import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type BillingProducts = {
  id: string;
  name: string;
  billing: number;
  movement: string;
  date: string;
  state?: boolean;
};

const Data: BillingProducts[] = [
  {
    id: '1',
    name: 'Lemon Haze',
    billing: 345,
    movement: 'Venta',
    date: '4 noviembre',
    state: true,
  },
  {
    id: '2',
    name: 'Lemon Haze',
    billing: 345,
    movement: 'Limpieza',
    date: '4 noviembre',
  },
  {
    id: '3',
    name: 'Lemon Haze',
    billing: 345,
    movement: 'Venta',
    date: '4 noviembre',
    state: true,
  },
  {
    id: '4',
    name: 'Lemon Haze',
    billing: 345,
    movement: 'Cancelación',
    date: '4 noviembre',
    state: true,
  },
  {
    id: '5',
    name: 'Lemon Haze',
    billing: 345,
    movement: 'Venta',
    date: '4 noviembre',
  },
  {
    id: '6',
    name: 'Lemon Haze',
    billing: 345,
    movement: 'Venta',
    date: '4 noviembre',
  },
  {
    id: '7',
    name: 'Lemon Haze',
    billing: 345,
    movement: 'Venta',
    date: '4 noviembre',
    state: true,
  },
  {
    id: '8',
    name: 'Lemon Haze',
    billing: 345,
    movement: 'Venta',
    date: '4 noviembre',
  },
];

const useConnect = () => {
  const navigate = useNavigate();

  const handleReturn = useCallback(() => {
    navigate(`/metrics`, { replace: true });
  }, [navigate]);

  return { billingProducts: Data, handleReturn };
};

export default useConnect;
