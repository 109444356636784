import { useCallback } from 'react';

import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, adjustProductOptionsDialogPayload, type } = useModal();

  const { onAcceptRemove, onAcceptSet, onAcceptAdd } =
    adjustProductOptionsDialogPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleAcceptRemove = useCallback(() => {
    if (!onAcceptRemove) return;
    onAcceptRemove();
  }, [onAcceptRemove]);

  const handleAcceptSet = useCallback(() => {
    if (!onAcceptSet) return;
    onAcceptSet();
  }, [onAcceptSet]);

  const handleAcceptAdd = useCallback(() => {
    if (!onAcceptAdd) return;
    onAcceptAdd();
  }, [onAcceptAdd]);

  return {
    handleAcceptRemove,
    handleAcceptSet,
    handleAcceptAdd,
    handleClose,
    isOpen: type === ModalType.ADJUST_PRODUCT_OPTIONS_DIALOG,
  };
};

export default useConnect;
