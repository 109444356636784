import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, IconCircle, Icon, Header } from './styles';

const NewProduct: FC = () => {
  const {
    handleOpenCategoriesSelection,
    handleClose,
    handleSubmit,
    isOpen,
    initialValues,
    isLoading,
    formikRef,
    activateProductReference,
  } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="flower" size={24} />
        </IconCircle>
        <Title>Nuevo producto</Title>
      </Header>
      <Form
        activateProductReference={activateProductReference}
        onSelectCategories={handleOpenCategoriesSelection}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        initialValues={initialValues}
        isLoading={isLoading}
        formikRef={formikRef}
      />
    </Container>
  );
};

export default NewProduct;
