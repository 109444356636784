import { FC, memo } from 'react';
import { Container, Credits, Name, Image, Number } from './styles';
import type { Props } from './types';

const TableHead: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Image />
      <Number>Nº</Number>
      <Name>Nombre</Name>
      <Credits>Créditos</Credits>
    </Container>
  );
};

export default memo(TableHead);
