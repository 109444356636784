import { gql } from '@apollo/client';
import { PURCHASE_FRAGMENT } from 'apollo/fragments/Purchase';

export const CREATE_PURCHASE = gql`
  mutation CreatePurchase($data: CreatePurchaseDto!) {
    createPurchase(data: $data) {
      ...PurchaseData
    }
  }
  ${PURCHASE_FRAGMENT}
`;

export const CANCEL_PURCHASE = gql`
  mutation CancelPurchase($purchaseId: ID!) {
    cancelPurchase(purchaseId: $purchaseId) {
      ...PurchaseData
    }
  }
  ${PURCHASE_FRAGMENT}
`;
