import { FC, memo } from 'react';
import useLogic from './logic';
import { Avatar, ChangeAvatar, Container } from './styles';
import { Props } from './types';

const AvatarEditor: FC<Props> = ({
  acceptedDropzoneFiles = {
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'image/png': ['.png'],
  },
  acceptedInputFiles = 'image/jpg,image/jpeg,image/png',
  maxSize = 10 * 1024 * 1024,
  fullName,
  onSubmitImage,
  currentImage,
  ...rest
}) => {
  const { handleOpenUploadImageDialogModal, imgUrl, getInputProps } = useLogic({
    acceptedInputFiles,
    acceptedDropzoneFiles,
    maxSize,
    currentImage,
    onSubmitImage,
  });

  return (
    <Container {...rest}>
      <Avatar shadow src={imgUrl} fullName={fullName} />
      <ChangeAvatar onClick={handleOpenUploadImageDialogModal}>
        Cambiar Imagen
      </ChangeAvatar>
      <input {...getInputProps()} accept={acceptedInputFiles} />
    </Container>
  );
};

export default memo(AvatarEditor);
