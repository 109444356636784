import { useCallback, Dispatch, SetStateAction } from 'react';
import { BaseMutationOptions } from '@apollo/client';
import uploadFile from 'utils/uploadFile';
import getImageSignedUrlTemporary from 'apollo/requests/getImageSignedUrlTemporary';
import { normalizeFilename } from 'utils/files';
import type {
  ImageCategory,
  GetImageSignedUrlTemporaryPartnerMutation,
  GetImageSignedUrlTemporaryPartnerMutationVariables,
} from 'apollo/graphql.types';

type Options = {
  getImageSignedUrlOptions?: BaseMutationOptions<
    GetImageSignedUrlTemporaryPartnerMutation,
    GetImageSignedUrlTemporaryPartnerMutationVariables
  >;
};

const useImageSignedUrlTemporaryActions = ({
  getImageSignedUrlOptions,
}: Options = {}) => {
  const uploadImage = useCallback(
    async ({
      file,
      name,
      partnerId,
      code,
      category,
      setProgress,
    }: {
      file: File;
      code: string;
      partnerId: string;
      name: string;
      category: ImageCategory;
      setProgress:
        | Dispatch<SetStateAction<number | undefined>>
        | ((progress?: number) => void);
    }) => {
      setProgress(0);

      const renamedFile = new File([file], normalizeFilename(file.name), {
        type: file.type,
      });

      try {
        const { uploadUrl, contentType } = await getImageSignedUrlTemporary({
          variables: {
            partnerId,
            code,
            data: {
              file_name: name?.replace(/\s/g, '-') || renamedFile.name,
              content_type: renamedFile.type,
              file_size: renamedFile.size,
              category,
            },
            ...getImageSignedUrlOptions,
          },
        });

        try {
          await uploadFile({
            file: renamedFile,
            onProgress: setProgress,
            uploadUrl,
            contentType,
          });

          setProgress(undefined);
          return true;
        } catch (error) {
          setProgress(undefined);
          throw new Error(error?.message);
        }
      } catch (error) {
        setProgress(undefined);
        throw new Error(error?.message);
      }
    },
    [getImageSignedUrlOptions],
  );

  return {
    uploadImage,
  };
};

export default useImageSignedUrlTemporaryActions;
