import type { FC } from 'react';
import { FieldArray, Formik } from 'formik';
import Switch from 'components/Inputs/CategorySwitch';
import Placeholder from './Placeholder';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  CategoriesContainer,
  CategoriesList,
  Container,
  EmptyState,
  Title,
  TextField,
  Space,
  Header,
  Icon,
  EditButton,
  FormContent,
  RemoveConfirmation,
  RemoveConfirmationText,
  RemoveCancelIcon,
  RemoveAcceptIcon,
  RemoveConfirmationIcons,
  Form,
} from './styles';

const SelectCategories: FC = () => {
  const {
    actions,
    categories,
    categoryToRemove,
    handleCreateCategory,
    handleEditCategories,
    isOpen,
    isLoading,
    isEdit,
    initialEditableFormValues,
    editCategoriesFormikRef,
  } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={actions.close}>
      <Header>
        <Icon name="arrowLeft" onClick={actions.close} />
        <Title>Categorías</Title>
        <EditButton
          disabled={categories.length <= 0}
          onClick={actions.toggleEdit}
        >
          {isEdit ? 'Guardar' : 'Editar'}
        </EditButton>
      </Header>

      {isLoading ? (
        <Placeholder nRows={7} />
      ) : (
        <>
          <Formik
            onSubmit={handleCreateCategory}
            initialValues={{ newCategory: '' }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  name="newCategory"
                  placeholder="Nueva Categoria"
                  id="newCategory"
                  icon="add"
                  onIconClick={handleSubmit}
                  readOnly={isEdit}
                />
              </Form>
            )}
          </Formik>
          {categories?.length > 0 ? (
            <CategoriesContainer>
              <CategoriesList>
                <Space />
                {isEdit ? (
                  <Formik
                    onSubmit={handleEditCategories}
                    initialValues={initialEditableFormValues}
                    innerRef={editCategoriesFormikRef}
                  >
                    {({ values }) => (
                      <FieldArray
                        name="categories"
                        render={() =>
                          values?.categories?.length > 0 &&
                          values.categories.map((category, index) => (
                            <FormContent key={category.id}>
                              <TextField
                                name={`categories[${index}].name`}
                                id={`categories[${index}].name`}
                                icon="close"
                                iconSize={24}
                                iconColor="#E53935"
                                readOnly={!!categoryToRemove}
                                onIconClick={() =>
                                  actions.selectCategoryToRemove(category.id)
                                }
                              />
                              {categoryToRemove === category.id && (
                                <RemoveConfirmation>
                                  <RemoveConfirmationText>
                                    Es una acción que no se puede deshacer
                                  </RemoveConfirmationText>
                                  <RemoveConfirmationIcons>
                                    <RemoveAcceptIcon
                                      onClick={actions.removeCategory}
                                      name="done"
                                    />
                                    <RemoveCancelIcon
                                      onClick={actions.notRemoveCategory}
                                      name="close"
                                    />
                                  </RemoveConfirmationIcons>
                                </RemoveConfirmation>
                              )}
                              <TextField
                                name={`categories[${index}].id`}
                                id={`categories[${index}].id`}
                                type="hidden"
                              />
                            </FormContent>
                          ))
                        }
                      />
                    )}
                  </Formik>
                ) : (
                  categories &&
                  categories.map((category) => (
                    <Switch
                      key={category.id}
                      id={category.id}
                      label={category.name}
                      onSelect={actions.toggleSelect}
                      isChecked={category.isChecked}
                    />
                  ))
                )}
                <Space />
                <Space />
              </CategoriesList>
            </CategoriesContainer>
          ) : (
            <EmptyState title="No hay categorias" iconName="categories">
              Aún no has creado ninguna categoria, crea al menos una para
              mostrar la información
            </EmptyState>
          )}
        </>
      )}
      <Buttons>
        <CancelButton onClick={actions.close}>Volver</CancelButton>
        <AcceptButton disabled={isEdit} onClick={actions.finish}>
          Aceptar
        </AcceptButton>
      </Buttons>
    </Container>
  );
};

export default SelectCategories;
