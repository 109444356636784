import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useUserActions from 'apollo/hooks/user/useUserActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';

const useConnect = () => {
  const [showSendMsg, setShowSendMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { close: closeModal, type } = useModal();
  const { sendTicket, userActionsLoading } = useUserActions();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(
    async ({ description }: { description: string }) => {
      try {
        setIsLoading(true);
        await sendTicket(description);
        setShowSendMsg(true);
        setIsLoading(false);
      } catch (e) {
        enqueueSnackbar('No se ha podido realizar la solicitud', {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, sendTicket],
  );

  return {
    handleOnClose,
    handleSubmit,
    isOpen: type === ModalType.NEW_TICKET_REQUEST,
    isLoading,
    getMeLoading: userActionsLoading,
    showSendMsg,
  };
};

export default useConnect;
