import type { FC } from 'react';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import AdjustProductDetailsModal from '../../../Modals/AdjustProductDetails';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { SectionTitle, Header, BackLink, HeaderContent } from '../styles';
import {
  Container,
  Content,
  DateTimeFilterContainer,
  EmptyState,
} from './styles';

const StockHistory: FC = () => {
  const {
    filterDates,
    productsQuantityHistoryByDates,
    handleOnSearch,
    isLoading,
    handleOpenDetailsModal,
  } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  const hasResults = productsQuantityHistoryByDates.length > 0;

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <BackLink to="/organization" />
            <SectionTitle>Histórico de Stock</SectionTitle>
          </HeaderContent>
        </Header>
        <DateTimeFilterContainer>
          <DateTimeFilter
            fromDate={filterDates.from}
            toDate={filterDates.to}
            onSearch={handleOnSearch}
          />
        </DateTimeFilterContainer>
        <Content>
          {!hasResults && (
            <EmptyState title="No existen ajustes" iconName="products">
              Aún no has creado ajuste de stock de ningún producto en esas
              fechas
            </EmptyState>
          )}
          {hasResults && (
            <>
              <TableHead />
              {productsQuantityHistoryByDates.map((adjust) => (
                <Row
                  key={adjust.id}
                  adjustHistory={adjust}
                  onClick={handleOpenDetailsModal}
                />
              ))}
            </>
          )}
        </Content>
      </Container>
      <AdjustProductDetailsModal />
    </>
  );
};

export default StockHistory;
