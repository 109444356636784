import { BaseMutationOptions } from '@apollo/client';
import { apolloClient } from 'services/api';
import {
  GetFileSignedUrl,
  GetFileSignedUrlVariables,
} from 'apollo/generated/GetFileSignedUrl';
import { GET_FILE_SIGNED_URL } from 'apollo/mutations/files';

const getFileSignedUrl = async (
  options: BaseMutationOptions<GetFileSignedUrl, GetFileSignedUrlVariables>,
) => {
  const { data } = await apolloClient.mutate<
    GetFileSignedUrl,
    GetFileSignedUrlVariables
  >({
    mutation: GET_FILE_SIGNED_URL,
    ...options,
  });

  const uploadUrl = data?.getFileSignedUrl.uploadUrl;
  const { contentType, id, fileName, fileSize } =
    data?.getFileSignedUrl.file || {};

  if (!contentType || !fileName || !id || !fileSize || !uploadUrl) {
    throw new Error('There has been a problem getting the upload url');
  }

  return {
    uploadUrl,
    contentType,
    id,
    fileName,
    fileSize,
  };
};

export default getFileSignedUrl;
