import { FC } from 'react';
import { Container, Content, Title } from './styles';
import type { Props } from './types';

const Modal: FC<Props> = ({
  className,
  disableBackdropClick,
  disableEscapeKeyDown,
  disableRestoreFocus,
  onClose,
  open,
  children,
  disableFullScreen,
}) => {
  const handleClose = (event: any, reason: string) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Container
      open={open}
      onClose={handleClose}
      closeAfterTransition
      disableRestoreFocus={disableRestoreFocus}
      className={className}
      $disableFullScreen={!!disableFullScreen}
    >
      <Content>{children}</Content>
    </Container>
  );
};

export default Modal;

export { Content, Title };
