import { memo, useCallback } from 'react';
import type { FC } from 'react';
import Price from 'components/Price';
import { formatDateToTable } from 'utils/dates/formatDates';
import {
  Container,
  Credits,
  Date,
  Employee,
  Note,
  NoteData,
  PartnerNote,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  onClickEmployee,
  onClickPartner,
  transaction,
}) => {
  const { employee, partner, total, date } = transaction;

  const handleClickEmployee = useCallback(
    () => onClickEmployee(employee?.id),
    [employee, onClickEmployee],
  );

  const handleClickPartner = useCallback(
    () => onClickPartner(partner?.memberNum),
    [partner, onClickPartner],
  );

  return (
    <Container className={className}>
      <Note>
        <NoteData>
          <Employee onClick={handleClickEmployee}>{employee.fullName}</Employee>
        </NoteData>
      </Note>
      <PartnerNote>
        <NoteData>
          <Employee onClick={handleClickPartner}>{partner.fullName}</Employee>
        </NoteData>
      </PartnerNote>
      <Date>{formatDateToTable(date)}</Date>
      <Credits>
        <Price>{total}</Price>
      </Credits>
    </Container>
  );
};

export default memo(Row);
