import { gql } from '@apollo/client';
import {
  EMPLOYEE_FRAGMENT,
  EMPLOYEE_TRANSACTIONS_FRAGMENT,
} from 'apollo/fragments/Employee';

export const GET_EMPLOYEES = gql`
  query GetEmployees($filter: String) {
    employees(filter: $filter) {
      ...EmployeeData
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;

export const GET_EMPLOYEE = gql`
  query GetEmployee($id: ID!) {
    employee(id: $id) {
      ...EmployeeData
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;

export const GET_EMPLOYEE_TRANSACTIONS = gql`
  query GetEmployeeTransactions(
    $id: ID!
    $filters: EmployeeTransactionsFilters
  ) {
    employeeTransactions(id: $id, filters: $filters) {
      ...EmployeeTransactionsData
    }
  }
  ${EMPLOYEE_TRANSACTIONS_FRAGMENT}
`;
