import { useField } from 'formik';
import { useCallback } from 'react';
import type { MouseEvent as ReactMouseEvent } from 'react';
import type { LogicProps } from './types';

const useLogic = ({ name }: LogicProps) => {
  const [{ value }, { touched, error }, { setValue, setError, setTouched }] =
    useField(name);

  const handleChange = useCallback(
    (e: any) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const handleClear = useCallback(
    (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setValue('');
      setError(undefined);
      setTouched(false);
    },
    [setError, setValue, setTouched],
  );

  return {
    handleChange,
    handleClear,
    value,
    touched,
    error,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
