import type { FC } from 'react';
import { Avatar } from 'components/AvatarEditorLegacy/styles';
import { Formik } from 'formik';
import useConnect from './connect';
import {
  Container,
  Title,
  Header,
  IsDeletedFlag,
  HeaderContainer,
  FormContainer,
  ButtonContainer,
  TextField,
  CancelButton,
  Row,
} from './styles';

const PartnerDetails: FC = () => {
  const { handleClose, isOpen, initialValues, handleSubmit, isDeleted } =
    useConnect();
  const fullName = `${initialValues.firstName} ${initialValues.lastName}`;
  const formId = `member_details`;

  return (
    <Container open={isOpen} onClose={handleClose}>
      <HeaderContainer>
        <Header>
          <Avatar
            shadow
            src={initialValues.avatarImageUrl}
            fullName={fullName}
          />
          <Title>
            {fullName} #{initialValues.memberNum}
          </Title>
          {isDeleted && <IsDeletedFlag>(Eliminado)</IsDeletedFlag>}
        </Header>
      </HeaderContainer>

      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        <FormContainer>
          <Row>
            <TextField
              id={`${formId}_firstName`}
              label="Nombre"
              name="firstName"
              variant="standard"
              readOnly
            />
            <TextField
              id={`${formId}_lastName`}
              label="Apellidos"
              name="lastName"
              variant="standard"
              readOnly
            />
          </Row>
          <Row>
            <TextField
              id={`${formId}_endorserNumber`}
              label="Nº de socio avalador"
              name="hostMemberNum"
              type="number"
              variant="standard"
              readOnly
            />
            <TextField
              id={`${formId}_maxConsumeMonth`}
              label="Consumo máximo"
              name="maxConsumeMonth"
              type="number"
              variant="standard"
              readOnly
            />
          </Row>
          <Row>
            <TextField
              id={`${formId}_usage`}
              label="Tipo de consumo"
              name="usage"
              variant="standard"
              readOnly
            />
          </Row>
        </FormContainer>
      </Formik>
      <ButtonContainer>
        <CancelButton onClick={handleClose}>Cerrar</CancelButton>
      </ButtonContainer>
    </Container>
  );
};

export default PartnerDetails;
