import type { Discount } from 'model/Discount';

export const applyDiscount = ({
  discount,
  price,
}: {
  discount?: Partial<Discount> | null;
  price: number;
}): number => {
  if (!discount) {
    return price;
  }

  if (discount.percentage) {
    return Number((price * ((100 - discount.percentage) / 100)).toFixed(2));
  }

  return Number((price - (discount.price || 0)).toFixed(2));
};
