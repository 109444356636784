import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type {
  CreatePartnerNoteVariables,
  CreatePartnerNote,
} from 'apollo/generated/CreatePartnerNote';
import {
  CREATE_PARTNER_NOTE,
  REMOVE_PARTNER_NOTE,
  UPDATE_PARTNER_NOTE,
} from 'apollo/mutations/partners';
import {
  RemovePartnerNote,
  RemovePartnerNoteVariables,
} from 'apollo/generated/RemovePartnerNote';
import {
  UpdatePartnerNote,
  UpdatePartnerNoteVariables,
} from 'apollo/generated/UpdatePartnerNote';
import { normalizePartnerNote, PartnerNoteFormFields } from 'model/Partner';

const usePartnerNoteActions = () => {
  const [createPartnerNoteMutation, { loading: isLoadingCreatePartnerNote }] =
    useMutation<CreatePartnerNote, CreatePartnerNoteVariables>(
      CREATE_PARTNER_NOTE,
    );
  const [removePartnerNoteMutation, { loading: isLoadingRemovePartnerNote }] =
    useMutation<RemovePartnerNote, RemovePartnerNoteVariables>(
      REMOVE_PARTNER_NOTE,
    );
  const [updatePartnerNoteMutation, { loading: isLoadingUpdatePartnerNote }] =
    useMutation<UpdatePartnerNote, UpdatePartnerNoteVariables>(
      UPDATE_PARTNER_NOTE,
    );

  const normalizeFormValues = useCallback(
    (values: PartnerNoteFormFields) => ({
      description: values.description,
      showNotification: values.showNotification,
    }),
    [],
  );

  const createPartnerNote = useCallback(
    async (partnerId: string, values: PartnerNoteFormFields) => {
      const dto = normalizeFormValues(values);
      const { data } = await createPartnerNoteMutation({
        variables: {
          id: partnerId,
          data: dto,
        },
      });

      if (data?.createPartnerNote) {
        return normalizePartnerNote(data.createPartnerNote);
      }
    },
    [createPartnerNoteMutation, normalizeFormValues],
  );

  const updatePartnerNote = useCallback(
    async ({
      noteId,
      values,
    }: {
      noteId: string;
      values: PartnerNoteFormFields;
    }) => {
      const dto = normalizeFormValues(values);
      await updatePartnerNoteMutation({
        variables: {
          noteId,
          data: dto,
        },
      });
    },
    [updatePartnerNoteMutation, normalizeFormValues],
  );

  const removePartnerNote = useCallback(
    async ({ noteId }: { noteId: string }) => {
      await removePartnerNoteMutation({
        variables: {
          noteId,
        },
      });
    },
    [removePartnerNoteMutation],
  );

  return {
    createPartnerNote,
    updatePartnerNote,
    removePartnerNote,
    loading:
      isLoadingCreatePartnerNote ||
      isLoadingRemovePartnerNote ||
      isLoadingUpdatePartnerNote,
  };
};

export default usePartnerNoteActions;
