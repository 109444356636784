import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import { Container, Credits, Date, Items, Note, Revert, State } from './styles';
import type { Props } from './types';

const TableHead: FC<Props> = ({ className }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <State />
      {fromTabletPortrait && <Note>Acción</Note>}
      <Items>Productos</Items>
      <Date>Fecha</Date>
      <Credits>Total</Credits>
      <Revert>Revertir</Revert>
    </Container>
  );
};

export default memo(TableHead);
