import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($dto: AuthLoginDto!) {
    login(dto: $dto) {
      accessToken
      userId
      role
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;
