export const TABLE_METRICS = {
  productImage: '8%',
  productName: '12%',
  employee: '10%',
  date: '15%',
  lastQuantity: '11%',
  newQuantity: '12%',
  movementType: '8%',
  note: '12%',
  credits: '10%',
};
