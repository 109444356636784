import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
} from 'yup';

export const validationSchema = yupObject({
  name: yupString().required('This field is required.'),
  description: yupString(),
  price: yupNumber().required('This field is required.'),
  quantity: yupNumber().required('This field is required.'),
});
