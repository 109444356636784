import { object as yupObject, string as yupString } from 'yup';

import type { FormFields } from './types';

export const validationSchema = yupObject({
  email: yupString()
    .email('Introduce un email correcto')
    .required('El email es requerido'),
  password: yupString().required('Introduce una contraseña'),
});

export const initialValues: FormFields = {
  email: '',
  password: '',
  submitError: '',
};
