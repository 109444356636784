import { memo, FC } from 'react';
import Price from 'components/Price';
import { AddedCredits, Container, Name, Products } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, quota, onSelectPartners }) => {
  return (
    <Container className={className}>
      <Name>{quota.name}</Name>
      <Products
        $hasProducts={quota.partners.length > 0}
        onClick={() => onSelectPartners(quota.partners)}
      >
        {quota.partners.length || 0}
      </Products>
      <AddedCredits>
        <Price>{quota.credits}</Price>
      </AddedCredits>
    </Container>
  );
};

export default memo(Row);
