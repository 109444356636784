import { FC, memo } from 'react';
import { Container, EditIcon, Icons, Name, TrashIcon } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ employeePermissions, name, onEdit, onRemove }) => {
  return (
    <Container>
      <Name>{name}</Name>
      <Icons>
        {employeePermissions.allowEditCreditsConcept && (
          <EditIcon onClick={onEdit} />
        )}
        {employeePermissions.allowRemoveCreditsConcept && (
          <TrashIcon onClick={onRemove} />
        )}
      </Icons>
    </Container>
  );
};

export default memo(Row);
