import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import useModal from 'apollo/hooks/useModal';
import getEmployee from 'apollo/requests/getEmployee';
import useProductTransactions from 'apollo/hooks/product/useProductTransactions';
import getPartner from 'apollo/requests/getPartner';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import type { Employee } from 'model/Employee';
import type { Partner } from 'model/Partner';

const useConnect = () => {
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const { productId } = useParams<{ productId: string }>();
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startOfDay(subMonths(new Date(), 1)),
    to: endOfDay(new Date()),
  });
  const { productTransactions, loading, refetch } = useProductTransactions({
    id: productId,
    filters: filterDates,
  });
  const navigate = useNavigate();
  const { openEmployeeDetails, openPartnerDetails } = useModal();

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      setFilterDate({ from, to });
      await refetch({
        id: productId,
        filters: {
          fromDate: from || null,
          toDate: to || null,
        },
      });
    },
    [refetch, productId],
  );

  const handleReturn = useCallback(() => {
    navigate('/metrics/products', { replace: true });
  }, [navigate]);

  const handleOpenEmployeeDetailsModal = useCallback(
    async (employeeId?: Employee['id']) => {
      if (employeeId) {
        const employee = await getEmployee(employeeId);
        if (employee) {
          openEmployeeDetails({ employee });
        }
      }
    },
    [openEmployeeDetails],
  );

  const handleOpenPartnerDetailsModal = useCallback(
    async (memberNum?: Partner['memberNum']) => {
      if (memberNum) {
        const partner = await getPartner({ partnerNum: memberNum });
        if (partner) {
          openPartnerDetails({ partner });
        }
      }
    },
    [openPartnerDetails],
  );

  return {
    activateProductReference: organizationConfig.activateProductReference,
    isLoading: loading,
    product: productTransactions?.product,
    handleReturn,
    handleOpenEmployeeDetailsModal,
    handleOpenPartnerDetailsModal,
    transactions: productTransactions?.transactions || [],
    filterDates,
    handleOnSearch,
  };
};

export default useConnect;
