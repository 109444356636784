import styled from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey200};

  ${from.tabletLandscape`
  padding: 1.0625rem 0 1.1875rem 0;
`}
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  flex: 0 0 ${TABLE_METRICS.name};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Times = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  flex: 0 0 ${TABLE_METRICS.times};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconsSpace = styled.div`
  text-align: right;
  flex: 0 0 ${TABLE_METRICS.icons};
`;
