import { useEffect, useState } from 'react';
import type { MenuData } from 'model/Organization';

const useLogic = (menuData: MenuData) => {
  const [categories, setCategories] = useState<
    { catName: string; products: MenuData['products'] }[]
  >([]);

  useEffect(() => {
    if (menuData) {
      const cats: Record<
        string,
        { catName: string; products: MenuData['products'] }
      > = {};
      menuData.products.forEach((product) => {
        product.categories.forEach((catName) => {
          if (cats[catName]) {
            cats[catName].products.push(product);
          } else {
            cats[catName] = {
              catName,
              products: [product],
            };
          }
        });
      });

      setCategories(Object.values(cats));
    }
  }, [menuData]);

  return {
    categories,
  };
};

export default useLogic;
