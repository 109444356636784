import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useQuotaActions from 'apollo/hooks/quota/useQuotaActions';
import { QuotaType } from 'apollo/generated/globalTypes';
import useQuotas from 'apollo/hooks/quota/useQuotas';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { QuotaFormFields } from 'model/Quota';

const useConnect = () => {
  const { close, type, quotaPayload } = useModal();
  const {
    createQuota,
    updateQuota,
    loading: quotaActionsLoading,
  } = useQuotaActions();
  const { refetch } = useQuotas();
  const { enqueueSnackbar } = useSnackbar();

  const { quota, onReturn } = quotaPayload || {};

  const initialValues = useMemo<QuotaFormFields>(
    () => ({
      id: quota?.id || '',
      name: quota?.name || '',
      type: quota?.type || QuotaType.ANNUAL,
      price: quota?.price || ('' as unknown as number),
      value: quota?.value || ('' as unknown as number),
    }),
    [quota],
  );

  const handleClose = useCallback(() => {
    if (onReturn) {
      onReturn();
    } else {
      close();
    }
  }, [onReturn, close]);

  const handleSubmit = useCallback<FormikConfig<QuotaFormFields>['onSubmit']>(
    async (data: QuotaFormFields) => {
      const quotaId = quota?.id;
      try {
        if (quotaId) {
          await updateQuota(quotaId, data);
        } else {
          await createQuota(data);
        }
        enqueueSnackbar(
          `La quota ha sido ${
            quotaId ? 'actualizada' : 'creada'
          } correctamente`,
          {
            variant: 'success',
          },
        );
        await refetch();
        handleClose();
      } catch (e) {
        enqueueSnackbar(
          formatErrors(
            'quota',
            e.message,
            `${quotaId ? 'actualizar' : 'crear'}`,
          ),
          {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          },
        );
      }
    },
    [quota, enqueueSnackbar, refetch, handleClose, updateQuota, createQuota],
  );

  return {
    handleSubmit,
    handleClose,
    initialValues,
    isLoading: quotaActionsLoading,
    isOpen: type === ModalType.QUOTA,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
