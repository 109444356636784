import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useDiscountActions from 'apollo/hooks/discount/useDiscountActions';
import useDiscounts from 'apollo/hooks/discount/useDiscounts';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { DiscountType } from 'apollo/graphql.types';
import type { DiscountFormFields } from 'model/Discount';

const useConnect = () => {
  const { close, type, discountPayload } = useModal();
  const {
    createDiscount,
    updateDiscount,
    loading: discountActionsLoading,
  } = useDiscountActions();
  const { refetch } = useDiscounts();
  const { enqueueSnackbar } = useSnackbar();

  const { discount } = discountPayload || {};

  const initialValues = useMemo<DiscountFormFields>(
    () => ({
      id: discount?.id || '',
      name: discount?.name || '',
      type: discount?.type || DiscountType.Product,
      price: discount?.price || ('' as unknown as number),
      percentage: discount?.percentage || ('' as unknown as number),
    }),
    [discount],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<DiscountFormFields>['onSubmit']
  >(
    async (data: DiscountFormFields) => {
      const discountId = discount?.id;
      try {
        if (discountId) {
          await updateDiscount({ id: discountId, data });
        } else {
          await createDiscount(data);
        }
        enqueueSnackbar(
          `El descuento ha sido ${
            discountId ? 'editado' : 'creado'
          } correctamente`,
          {
            variant: 'success',
          },
        );
        await refetch();
        close();
      } catch (e) {
        enqueueSnackbar(
          formatErrors(
            'discount',
            e.message,
            discountId ? 'actualizar' : 'crear',
          ),
          {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          },
        );
      }
    },
    [discount, enqueueSnackbar, refetch, close, updateDiscount, createDiscount],
  );

  return {
    handleSubmit,
    handleClose,
    initialValues,
    isOpen: type === ModalType.DISCOUNT,
    isLoading: discountActionsLoading,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
