import styled, { css } from 'styled-components';
import DefaultLink from 'components/Link';
import { Flag as DefaultFlag } from 'components/Layout';
import DefaultDownIcon from 'components/Icon/Icons/Down';
import DefaultUpIcon from 'components/Icon/Icons/Up';
import DefaultEqualIcon from 'components/Icon/Icons/Equal';
import { TABLE_METRICS } from '../constants';

export const Container = styled(DefaultLink)`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const State = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.state};
  ${TextEllipsis};
`;

export const Position = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.number};
  ${TextEllipsis};
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 1 ${TABLE_METRICS.name};
  text-transform: capitalize;
  ${TextEllipsis};
`;

export const ReferenceCode = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 0.5rem;
`;

export const Billing = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.billing};
  ${TextEllipsis};
`;

export const Sales = styled.div`
  color: ${({ theme }) => theme.colors.grey300};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.sales};
  ${TextEllipsis};
`;

export const Chevron = styled.div`
  text-align: right;
  padding-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.chevron};
`;

export const Flag = styled(DefaultFlag)`
  margin-left: 1rem;
`;

export const DownIcon = styled(DefaultDownIcon).attrs(() => ({ size: 24 }))`
  color: ${({ theme }) => theme.colors.danger};
`;

export const UpIcon = styled(DefaultUpIcon).attrs(() => ({ size: 24 }))`
  color: ${({ theme }) => theme.colors.green};
`;

export const EqualIcon = styled(DefaultEqualIcon).attrs(() => ({ size: 24 }))`
  color: ${({ theme }) => theme.colors.accent};
`;
