import { FC, memo } from 'react';
import { FlourContainer } from '../styles';
import './styles.css';

const RedFlour: FC = () => (
  <FlourContainer>
    <div className="container">
      <div className="glass" />
      <div className="thorns">
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className="glow" />
      <div className="rose-leaves">
        <div />
        <div />
      </div>
      <div className="rose-petals">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className="sparkles">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </FlourContainer>
);

export default memo(RedFlour);
