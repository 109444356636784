import styled from 'styled-components';
import DefaultLogo from 'components/Logo';

export const Container = styled.div`
  align-items: center;
  background-color: #f2eee8;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const FaceContent = styled.div`
  padding-top: 100px;
`;

export const Face = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  border: 4px solid #383a41;
  height: 300px;
  margin: 0 auto;
  width: 300px;

  @media screen and (max-width: 400px) {
    margin-top: 40px;
    transform: scale(0.8);
  }
`;

export const Band = styled.div`
  width: 350px;
  height: 27px;
  border: 4px solid #383a41;
  border-radius: 5px;
  margin-left: -25px;
  margin-top: 50px;
`;

export const Red = styled.div`
  height: calc(100% / 3);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue300};
`;

export const White = styled.div`
  height: calc(100% / 3);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue300};
`;

export const Blue = styled.div`
  height: calc(100% / 3);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue300};
`;

export const Eyes = styled.div`
  width: 128px;
  margin: 40px auto 0 auto;

  &:before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 15px;
    border: 7px solid #383a41;
    margin-right: 20px;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom: 0;
    box-sizing: content-box;
    transform: translate3d(0, 0, 0);
  }

  &:after {
    content: '';
    display: inline-block;
    width: 30px;
    height: 15px;
    border: 7px solid #383a41;
    margin-left: 20px;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom: 0;
    box-sizing: content-box;
    transform: translate3d(0, 0, 0);
  }
`;

export const Dimples = styled.div`
  width: 180px;
  margin: 15px auto 0 auto;

  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 80px;
    border-radius: 50%;
    background-color: #eb6d6d;
    box-sizing: content-box;
    transform: translate3d(0, 0, 0);
  }

  &:after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 80px;
    border-radius: 50%;
    background-color: #eb6d6d;
    box-sizing: content-box;
    transform: translate3d(0, 0, 0);
  }
`;

export const Mouth = styled.div`
  width: 40px;
  height: 5px;
  border-radius: 5px;
  background-color: #383a41;
  margin: 25px auto 0 auto;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.5em;
  font-weight: 800;
  padding-top: 20px;
  text-align: center;

  @media screen and (max-width: 400px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 2em;
  }
`;

export const Logo = styled(DefaultLogo)`
  padding-top: 40px;
`;
