import { FC, memo } from 'react';
import { Container, Credits, Date, Note, PartnerNote } from './styles';
import type { Props } from './types';

const TableHead: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Note>Empleado</Note>
      <PartnerNote>Socio</PartnerNote>
      <Date>Fecha</Date>
      <Credits>Total</Credits>
    </Container>
  );
};

export default memo(TableHead);
