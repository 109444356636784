import { FC, memo } from 'react';
import type { Props } from './types';

const Close: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      stroke="currentColor"
      strokeWidth="1.5"
      fill="currentColor"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M8 17l8.884-9M16.884 17L8 8" />
    </g>
  </svg>
);

export default memo(Close);
