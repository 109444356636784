import { FC, memo, useCallback } from 'react';
import { ListRowProps, WindowScroller } from 'react-virtualized';
import type { Partner } from 'model/Partner';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main, List, ListContent } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const SupportedPartners: FC<Props> = ({ partners }) => {
  const { handleOpenPartnerDetailsModal } = useConnect();
  const hasResults = partners?.length > 0;
  const ROW_HEIGHT_DESKTOP_PX = 60;

  const rowRenderer: FC<ListRowProps> = useCallback(
    ({ index, style }) => {
      const partner = partners[index] as Partial<Partner> & {
        isDeleted: boolean;
      };

      return (
        <Row
          key={partner.id}
          openPartnerDetails={handleOpenPartnerDetailsModal}
          partner={partner}
          style={style}
        />
      );
    },
    [handleOpenPartnerDetailsModal, partners],
  );

  return (
    <Container>
      <Main>
        {!hasResults && (
          <EmptyState title="No hay socios" iconName="users">
            No se ha avalado a otro socio
          </EmptyState>
        )}

        {hasResults && (
          <ListContent>
            <TableHead />
            <WindowScroller>
              {({ onChildScroll, ...scrollInfo }) => (
                <List
                  autoHeight
                  autoWidth
                  onScroll={onChildScroll}
                  rowCount={partners.length}
                  rowHeight={ROW_HEIGHT_DESKTOP_PX}
                  rowRenderer={rowRenderer}
                  {...scrollInfo}
                />
              )}
            </WindowScroller>
          </ListContent>
        )}
      </Main>
    </Container>
  );
};

export default memo(SupportedPartners);
