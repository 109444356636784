import { useCallback, useState } from 'react';
import useOrganizationActions from 'apollo/hooks/organization/useOrganizationActions';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, type, validatePinPayload } = useModal();
  const [hasError, setHasError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { validatePin, loading } = useOrganizationActions();
  const { onCorrectValidation } = validatePinPayload || {};

  const handleClose = useCallback(() => {
    close();
    setHasError(false);
    setIsValid(false);
  }, [close]);

  const handleOnComplete = useCallback(
    async (code: string) => {
      const isValidCode = await validatePin(code);
      if (isValidCode) {
        setHasError(false);
        setIsValid(true);
        if (onCorrectValidation) {
          onCorrectValidation();
          setIsValid(false);
        }
      } else {
        setHasError(true);
        setIsValid(false);
      }
    },
    [onCorrectValidation, validatePin],
  );

  return {
    handleOnComplete,
    handleClose,
    hasError,
    isValid,
    isLoading: loading,
    isOpen: type === ModalType.VALIDATE_PIN,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
