import { useMemo } from 'react';
import { QueryHookOptions, useQuery, useReactiveVar } from '@apollo/client';
import { GET_CASHIERS } from 'apollo/queries/cashiers';
import { userRoleVar } from 'apollo/reactive';
import { UserRole } from 'apollo/generated/globalTypes';
import { normalizeCashier } from 'model/Cashier';
import type {
  CashiersQuery,
  CashiersQueryVariables,
} from 'apollo/graphql.types';

const useCashiers = ({
  fetchPolicy = 'cache-and-network',
}: {
  fetchPolicy?: QueryHookOptions<CashiersQuery>['fetchPolicy'];
} = {}) => {
  const userRole = useReactiveVar(userRoleVar);

  const { data, loading: getCashiersLoading } = useQuery<
    CashiersQuery,
    CashiersQueryVariables
  >(GET_CASHIERS, {
    skip: userRole !== UserRole.ADMIN,
    fetchPolicy,
  });

  const cashiers = useMemo(
    () => (data?.cashiers ? data.cashiers.map(normalizeCashier) : []),
    [data],
  );

  return {
    getCashiersLoading,
    cashiers,
  };
};

export default useCashiers;
