import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CREATE_DISCOUNT,
  REMOVE_DISCOUNT,
  UPDATE_DISCOUNT,
} from 'apollo/mutations/discounts';
import { DiscountFormFields, normalizeDiscount } from 'model/Discount';
import type {
  CreateDiscountMutation,
  CreateDiscountMutationVariables,
  RemoveDiscountMutation,
  RemoveDiscountMutationVariables,
  UpdateDiscountMutation,
  UpdateDiscountMutationVariables,
} from 'apollo/graphql.types';

const useDiscountActions = () => {
  const [createDiscountMutation, { loading: isLoadingCreateDiscount }] =
    useMutation<CreateDiscountMutation, CreateDiscountMutationVariables>(
      CREATE_DISCOUNT,
    );
  const [removeDiscountMutation, { loading: isLoadingRemoveDiscount }] =
    useMutation<RemoveDiscountMutation, RemoveDiscountMutationVariables>(
      REMOVE_DISCOUNT,
    );
  const [updateDiscountMutation, { loading: isLoadingUpdateDiscount }] =
    useMutation<UpdateDiscountMutation, UpdateDiscountMutationVariables>(
      UPDATE_DISCOUNT,
    );

  const normalizeFormValues = useCallback(
    async (values: DiscountFormFields) => ({
      type: values.type,
      name: values.name,
      percentage: values.percentage ? Number(values.percentage) : null,
      price: values.price ? Number(values.price) : null,
    }),
    [],
  );

  const createDiscount = useCallback(
    async (values: DiscountFormFields) => {
      const dto = await normalizeFormValues(values);
      const { data } = await createDiscountMutation({
        variables: {
          data: dto,
        },
      });

      if (data?.createDiscount) {
        return normalizeDiscount(data.createDiscount);
      }
    },
    [createDiscountMutation, normalizeFormValues],
  );

  const updateDiscount = useCallback(
    async ({ id, data }: { id: string; data: DiscountFormFields }) => {
      const dto = await normalizeFormValues(data);
      await updateDiscountMutation({
        variables: {
          id,
          data: dto,
        },
      });
    },
    [normalizeFormValues, updateDiscountMutation],
  );

  const deleteDiscount = useCallback(
    async (id: string) => {
      await removeDiscountMutation({
        variables: {
          id,
        },
      });
    },
    [removeDiscountMutation],
  );

  return {
    createDiscount,
    deleteDiscount,
    updateDiscount,
    loading:
      isLoadingCreateDiscount ||
      isLoadingUpdateDiscount ||
      isLoadingRemoveDiscount,
  };
};

export default useDiscountActions;
