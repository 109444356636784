import { BaseMutationOptions } from '@apollo/client';
import { apolloClient } from 'services/api';
import { GET_FILE_SIGNED_URL_TEMPORARY } from 'apollo/mutations/files';
import type {
  GetFileSignedUrlTemporaryPartnerMutation,
  GetFileSignedUrlTemporaryPartnerMutationVariables,
} from 'apollo/graphql.types';

const getFileSignedUrlTemporary = async (
  options: BaseMutationOptions<
    GetFileSignedUrlTemporaryPartnerMutation,
    GetFileSignedUrlTemporaryPartnerMutationVariables
  >,
) => {
  const { data } = await apolloClient.mutate<
    GetFileSignedUrlTemporaryPartnerMutation,
    GetFileSignedUrlTemporaryPartnerMutationVariables
  >({
    mutation: GET_FILE_SIGNED_URL_TEMPORARY,
    ...options,
  });

  const uploadUrl = data?.getFileSignedUrlTemporaryPartner.uploadUrl;
  const { contentType, id, fileName, fileSize } =
    data?.getFileSignedUrlTemporaryPartner.file || {};

  if (!contentType || !fileName || !id || !fileSize || !uploadUrl) {
    throw new Error('There has been a problem getting the upload url');
  }

  return {
    uploadUrl,
    contentType,
    id,
    fileName,
    fileSize,
  };
};

export default getFileSignedUrlTemporary;
