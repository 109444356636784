import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import { Form as FormikForm } from 'formik';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultTextField from 'components/Inputs/TextField';

export const Container = styled(DefaultModal)`
  ${Content} {
    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Form = styled(FormikForm)``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
`;

export const IconCircle = styled(DefaultIconCircle)`
  margin-right: 1rem;
`;

export const Title = styled(ModalTitle)`
  margin: 1rem auto 3.5625rem;
`;

export const Label = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.accent};
  width: 3rem;
`;

export const Row = styled.div`
  margin-bottom: 1rem;
  display: flex;
  > * {
    flex: 0 0 50%;
    width: 50%;

    &:not(:last-child) {
      padding-right: 0.5rem;
    }
    &:not(:first-child) {
      padding-left: 0.5rem;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2rem;

  ${from.tabletPortrait`
    flex-flow: row-reverse nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))``;

export const TextField = styled(DefaultTextField)`
  margin-top: 1rem;
  ${from.tabletPortrait`
    margin-top: 0;
  `}
`;
