import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PRODUCT_TRANSACTIONS } from 'apollo/queries/products';
import {
  GetProductTransactionsQuery,
  GetProductTransactionsQueryVariables,
} from 'apollo/graphql.types';
import { normalizeProductTransaction } from 'model/Product';

const useProductTransactions = ({
  id,
  filters,
}: {
  id?: string;
  filters?: { from?: Date; to?: Date };
}) => {
  const { loading, data, refetch } = useQuery<
    GetProductTransactionsQuery,
    GetProductTransactionsQueryVariables
  >(GET_PRODUCT_TRANSACTIONS, {
    skip: !id,
    variables: {
      id: id!,
      filters: filters
        ? {
            fromDate: filters.from || null,
            toDate: filters.to || null,
          }
        : null,
    },
    fetchPolicy: 'cache-and-network',
  });

  const productTransactions = useMemo(
    () =>
      data?.productTransactions
        ? // @ts-ignore
          normalizeProductTransaction(data.productTransactions)
        : undefined,
    [data],
  );

  return {
    productTransactions,
    loading,
    refetch,
  };
};

export default useProductTransactions;
