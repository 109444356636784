import { FC, memo } from 'react';
import type { Props } from './types';

const Coin: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    {...rest}
    fill="#000000"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 470 470"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          fill="currentColor"
          d="M252.5,223h-34.9c-14.5,0-26.3-11.8-26.3-26.3s11.8-26.3,26.3-26.3h61.6c6.6,0,12-5.4,12-12s-5.4-12-12-12h-32.1v-24.5
			c0-6.6-5.4-12-12-12s-12,5.4-12,12v24.5h-5.5c-27.7,0-50.3,22.6-50.3,50.3s22.6,50.3,50.3,50.3h34.9c14.5,0,26.3,11.8,26.3,26.3
			s-11.8,26.3-26.3,26.3h-62.7c-6.6,0-12,5.4-12,12s5.4,12,12,12H223v25c0,6.6,5.4,12,12,12s12-5.4,12-12v-25h5.8c0.1,0,0.2,0,0.3,0
			c27.4-0.4,49.6-22.8,49.6-50.3C302.7,245.5,280.2,223,252.5,223z"
        />
        <path
          fill="currentColor"
          d="M401.2,68.8C356.8,24.4,297.8,0,235,0S113.2,24.4,68.8,68.8S0,172.2,0,235s24.4,121.8,68.8,166.2S172.2,470,235,470
			s121.8-24.4,166.2-68.8S470,297.8,470,235S445.6,113.2,401.2,68.8z M235,446c-116.3,0-211-94.7-211-211S118.7,24,235,24
			s211,94.7,211,211S351.3,446,235,446z"
        />
      </g>
    </g>
  </svg>
);

export default memo(Coin);
