import { FC, memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import useConnect from './connect';
import { Container, DatePickerInput, Error, Label } from './styles';
import type { Props } from './types';

const DatePicker: FC<Props> = ({
  className,
  label,
  name,
  id,
  readOnly,
  ref,
  value,
  onClick,
  onLocalChange,
  titleLabel,
}) => {
  const { error, field, handleChange, inputRef, touched } = useConnect({
    name,
    ref,
    onLocalChange,
  });

  const selectedDate = new Date(value || field.value);

  return (
    <Container className={className} onClick={onClick}>
      {titleLabel && <Label>{titleLabel}</Label>}
      <DatePickerInput
        label={label}
        id={id}
        disabled={readOnly}
        inputRef={inputRef}
        {...field}
        value={selectedDate}
        // @ts-ignore
        onChange={handleChange}
      />
      <AnimatePresence initial={false}>
        {touched && error && <Error>{error}</Error>}
      </AnimatePresence>
    </Container>
  );
};

export default memo(DatePicker);
