import { FC, memo } from 'react';
import { MappedDayOfWeek } from 'model/Employee';
import type { DayOfWeek } from 'apollo/generated/globalTypes';
import { Container, Icons, Name, Times, TrashIcon } from './styles';
import type { Props } from './types';

const ScheduleRow: FC<Props> = ({ menuSchedule, onRemove }) => (
  <Container>
    <Name>
      {menuSchedule.daysOfWeek
        .map((day) =>
          MappedDayOfWeek[day as DayOfWeek].slice(0, 3).toUpperCase(),
        )
        .join(', ')}
    </Name>
    <Times>
      {menuSchedule.schedules
        .map(
          (schedule) =>
            `${schedule.startTime.slice(0, 5)}-${schedule.endTime.slice(0, 5)}`,
        )
        .join(', ')}
    </Times>
    <Icons>
      <TrashIcon onClick={onRemove} />
    </Icons>
  </Container>
);

export default memo(ScheduleRow);
