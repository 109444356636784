import type { FC } from 'react';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import { ListRowProps, WindowScroller } from 'react-virtualized';
import { useCallback } from 'react';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { Container, EmptyState, Main, List } from './styles';

const MetricProductsRanking: FC = () => {
  const { isLoading, handleReturn, productsRanking } = useConnect();

  const hasResults = productsRanking?.length > 0;
  const ROW_HEIGHT_DESKTOP_PX = 80;

  const rowRenderer: FC<ListRowProps> = useCallback(
    ({ index, style }) => {
      const item = productsRanking[index];

      return <Row key={item.product.id} style={style} item={item} />;
    },
    [productsRanking],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header title="Productos más consumidos" onReturn={handleReturn} />
      <Main>
        {!hasResults && (
          <EmptyState
            title="No se puede generar la información"
            iconName="products"
          >
            No hay datos suficientes para generar estadisticas de productos
          </EmptyState>
        )}

        {hasResults && (
          <>
            <TableHead />
            <WindowScroller>
              {({ onChildScroll, ...scrollInfo }) => (
                <List
                  autoHeight
                  autoWidth
                  onScroll={onChildScroll}
                  rowCount={productsRanking.length}
                  rowHeight={ROW_HEIGHT_DESKTOP_PX}
                  rowRenderer={rowRenderer}
                  {...scrollInfo}
                />
              )}
            </WindowScroller>
          </>
        )}
      </Main>
    </Container>
  );
};

export default MetricProductsRanking;
