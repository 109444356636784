import { useCallback } from 'react';

import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { cancelOrderPayload, close, type } = useModal();

  const { onSelectReason, bookingId } = cancelOrderPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handlesActions = {
    withoutProducts: useCallback(
      () =>
        onSelectReason &&
        bookingId &&
        onSelectReason('No queda producto', bookingId),
      [bookingId, onSelectReason],
    ),
    overLimit: useCallback(
      () =>
        onSelectReason &&
        bookingId &&
        onSelectReason('Ha superado el límite', bookingId),
      [bookingId, onSelectReason],
    ),
    other: useCallback(
      () =>
        onSelectReason &&
        bookingId &&
        onSelectReason('Ha superado el límite', bookingId),
      [bookingId, onSelectReason],
    ),
  };

  return {
    handlesActions,
    handleClose,
    isOpen: type === ModalType.CANCEL_ORDER,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
