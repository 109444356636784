import styled from 'styled-components';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultIcon from 'components/Icon';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  padding-top: 10rem;
  margin-bottom: 5rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const Navigation = styled.nav``;

export const Label = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}0a;
  flex: 1 1 auto;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  padding-bottom: 1.1875rem;
  padding-top: 1rem;
  text-transform: capitalize;
`;

export const Link = styled(NavLink)<NavLinkProps>`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.colors.accent};
    ${Label} {
      border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
    }
  }

  &:hover {
    opacity: 0.7;
    &.active {
      opacity: 1;
    }
  }
`;

export const LabelContent = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1.1875rem;
  padding-top: 1rem;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 24 }))`
  flex: 0 0 auto;
  margin-right: 1.25rem;
  margin-top: -0.2188rem;
`;

export const ArrowRightIcon = styled(DefaultArrowRightIcon).attrs(() => ({
  size: 6.4,
}))`
  color: ${({ theme }) => theme.colors.grey500};
  opacity: 0.7;
`;
