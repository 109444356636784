import { FC, memo } from 'react';
import TableHead from './TableHead';
import { Container } from './styles';
import type { Props } from './types';
import Row from './Row';
import useLogic from './logic';

const QuotasList: FC<Props> = ({ className, allowDownload, quotas, csv }) => {
  const { handleShowPartners } = useLogic();

  return (
    <Container className={className}>
      <TableHead allowDownload={allowDownload} csv={csv} />
      {quotas.map((quota) => (
        <Row
          key={quota.id}
          quota={quota}
          onSelectPartners={handleShowPartners}
        />
      ))}
    </Container>
  );
};

export default memo(QuotasList);
