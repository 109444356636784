import styled, { css } from 'styled-components';
import DefaultLink from 'components/Link';
import DefaultIcon from 'components/Icon';
import { TABLE_METRICS } from '../constants';

export const Container = styled(DefaultLink)`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const State = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.state};
  ${TextEllipsis};
`;

export const PartnerNum = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 1 ${TABLE_METRICS.partnerNum};
  ${TextEllipsis};
`;

export const Identifier = styled.div`
  color: ${({ theme }) => theme.colors.grey300};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.identifier};
  ${TextEllipsis};
`;

export const Balance = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: auto;
  margin-right: 1rem;
  text-align: right;
  flex: 0 0 ${TABLE_METRICS.balance};
  ${TextEllipsis};
`;

export const Icon = styled(DefaultIcon)``;

export const XIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const DoneIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.green};
`;
