import type { FC } from 'react';
import { BlockPicker } from 'react-color';
import { v4 as uuidv4 } from 'uuid';
import { Formik, FieldArray } from 'formik';
import { OrganizationMenuType } from 'apollo/graphql.types';
import Header from 'containers/Common/Header';
import AddMenuScheduleModal from 'containers/Modals/AddMenuSchedule';
import Loader from 'components/Loader';
import TextField from 'components/Inputs/TextField';
import Button from 'components/Button';
import ScheduleRow from './ScheduleRow';
import useConnect from './connect';
import {
  Container,
  Main,
  Row,
  Form,
  Switch,
  ColorField,
  ColorFieldTitle,
  ColorsInputs,
  ContentTitle,
  CategoryOption,
  CategorySwitch,
  CategoryOptions,
  Content,
  UrlGeneratorContent,
  UrlButton,
  UrlTextField,
  ButtonsContainer,
  ButtonTitle,
  ContentTitleLink,
  Select,
} from './styles';
import { MENU_TYPE_OPTIONS, validationSchema } from './constants';

const Menu: FC = () => {
  const {
    categories,
    formikRef,
    handleAddSchedule,
    handleCopyLink,
    handleOnSubmit,
    handleRemoveMenu,
    handleResetColors,
    handleToggleCategory,
    initialValues,
    isLoading,
    isNew,
    organizationConfig,
  } = useConnect();
  const formId = 'menu';
  const title = !isNew ? `Menú "${initialValues.name}"` : 'Menú';
  const formattedOrganizationName = organizationConfig.organizationName
    .trim()
    .toLowerCase()
    .replace(/ /g, '-');

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <Header
          title={title}
          buttonText={!isNew ? 'Eliminar' : ''}
          buttonVariant="danger"
          onClickButton={handleRemoveMenu}
          showGlobalCreateButton={false}
          showRfidButton={false}
        />
        <Main>
          <Formik
            innerRef={formikRef}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            initialValues={initialValues}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <UrlGeneratorContent>
                  <UrlTextField
                    readOnly
                    id={`${formId}_menuCode`}
                    label="Url"
                    name="menuCode"
                    icon="copyClip"
                    onIconClick={() => handleCopyLink(values.menuCode)}
                  />
                  <UrlButton
                    onClick={() =>
                      setFieldValue(
                        'menuCode',
                        `https://www.inditas.com/menu/${formattedOrganizationName}-${uuidv4()}`,
                      )
                    }
                  >
                    Generar
                  </UrlButton>
                </UrlGeneratorContent>
                <ContentTitle>Configuración base</ContentTitle>
                <Content>
                  <Row>
                    <TextField
                      id={`${formId}_name`}
                      label="Nombre *"
                      name="name"
                    />
                    <Select
                      name="type"
                      label="Tipo"
                      id={`${formId}_type`}
                      options={MENU_TYPE_OPTIONS}
                    />
                  </Row>
                  <Row>
                    <Switch
                      name="menuActive"
                      label="Carta activada"
                      id={`${formId}_menuActive`}
                    />
                    {!!values.type &&
                      values.type !== OrganizationMenuType.Static && (
                        <Switch
                          name="showProductDescription"
                          label="Mostrar descripción"
                          id={`${formId}_showProductDescription`}
                        />
                      )}
                  </Row>
                  <Row>
                    <Switch
                      name="showLogo"
                      label="Mostrar Logo"
                      id={`${formId}_showLogo`}
                    />
                    <Switch
                      name="useLogoToBackground"
                      label="Usar logo como fondo"
                      id={`${formId}_useLogoToBackground`}
                    />
                  </Row>
                  <Row>
                    <Switch
                      name="showProductPrice"
                      label="Mostrar precio"
                      id={`${formId}_showProductPrice`}
                    />
                    <Switch
                      name="showPriceSimbol"
                      label="Mostrar símbolo Cr"
                      id={`${formId}_showPriceSimbol`}
                    />
                  </Row>
                  <Row>
                    <Switch
                      name="showProductDiscount"
                      label="Mostrar descuento"
                      id={`${formId}_showProductDiscount`}
                    />
                  </Row>
                  {!!values.type &&
                    values.type !== OrganizationMenuType.Static && (
                      <Row>
                        <TextField
                          id={`${formId}_defaultImageUrl`}
                          label="Imagen base de los productos"
                          name="defaultImageUrl"
                        />
                      </Row>
                    )}
                </Content>

                <ContentTitle>
                  Configura los colores de la carta{' '}
                  <ContentTitleLink
                    href="https://www.html-color-codes.info/"
                    target="_blank"
                  >
                    Genera tu código de color aquí
                  </ContentTitleLink>
                </ContentTitle>
                <Content>
                  <ColorsInputs>
                    <ColorField>
                      <ColorFieldTitle>Fondo de la carta</ColorFieldTitle>
                      <BlockPicker
                        color={values.backgroundColor}
                        onChangeComplete={(color) =>
                          setFieldValue('backgroundColor', color.hex)
                        }
                      />
                    </ColorField>
                    <ColorField>
                      <ColorFieldTitle>
                        Borde de imágenes/bloques
                      </ColorFieldTitle>
                      <BlockPicker
                        color={values.imageBorderColor}
                        onChangeComplete={(color) =>
                          setFieldValue('imageBorderColor', color.hex)
                        }
                      />
                    </ColorField>
                    <ColorField>
                      <ColorFieldTitle>Título del producto</ColorFieldTitle>
                      <BlockPicker
                        color={values.productTitleColor}
                        onChangeComplete={(color) =>
                          setFieldValue('productTitleColor', color.hex)
                        }
                      />
                    </ColorField>
                    <ColorField>
                      <ColorFieldTitle>Texto del producto</ColorFieldTitle>
                      <BlockPicker
                        color={values.productDescriptionColor}
                        onChangeComplete={(color) =>
                          setFieldValue('productDescriptionColor', color.hex)
                        }
                      />
                    </ColorField>
                    <ColorField>
                      <ColorFieldTitle>Precio del producto</ColorFieldTitle>
                      <BlockPicker
                        color={values.productPriceColor}
                        onChangeComplete={(color) =>
                          setFieldValue('productPriceColor', color.hex)
                        }
                      />
                    </ColorField>
                    <ColorField>
                      <ColorFieldTitle>Menú de categorías</ColorFieldTitle>
                      <BlockPicker
                        color={values.categoriesColor}
                        onChangeComplete={(color) =>
                          setFieldValue('categoriesColor', color.hex)
                        }
                      />
                    </ColorField>
                  </ColorsInputs>
                </Content>

                <ContentTitle>
                  Selecciona las categorías de la carta
                </ContentTitle>
                <Content>
                  <CategoryOptions>
                    {categories.map((category) => (
                      <CategoryOption key={category.id}>
                        <CategorySwitch
                          id={category.id}
                          isChecked={initialValues?.categoriesNames?.includes(
                            category.name,
                          )}
                          label={category.name}
                          onSelect={handleToggleCategory}
                        />
                      </CategoryOption>
                    ))}
                  </CategoryOptions>
                </Content>

                <ContentTitle>
                  <span>Añade el horario</span>
                  <ButtonTitle onClick={handleAddSchedule}>Añadir</ButtonTitle>
                </ContentTitle>
                <Content>
                  <FieldArray
                    name="menuSchedules"
                    render={(arrayHelpers) =>
                      values?.menuSchedules?.length > 0 &&
                      values.menuSchedules.map((schedule, index) => (
                        <ScheduleRow
                          key={`${schedule.daysOfWeek.join(',')}`}
                          menuSchedule={schedule}
                          onRemove={() => arrayHelpers.remove(index)}
                        />
                      ))
                    }
                  />
                </Content>
                <ButtonsContainer>
                  <Button type="submit">Guardar</Button>
                  <Button
                    variant="light"
                    onClick={() => handleResetColors(setFieldValue)}
                  >
                    Resetear colores
                  </Button>
                </ButtonsContainer>
              </Form>
            )}
          </Formik>
        </Main>
      </Container>
      <AddMenuScheduleModal />
    </>
  );
};

export default Menu;
