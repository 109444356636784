import { useCallback, useEffect, useState } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useDiscounts from 'apollo/hooks/discount/useDiscounts';
import type { Discount } from 'model/Discount';

const useConnect = () => {
  const { type, close, assignDiscountsPayload } = useModal();
  const {
    checkedDiscountsIds,
    oneSelection,
    onReturn,
    onSubmit,
    partnerId,
    selectedDiscount,
    type: discountType,
  } = assignDiscountsPayload || {};

  const { discounts, loading: getDiscountsLoading } =
    useDiscounts(discountType);
  const [selectedDiscounts, setSelectedDiscounts] = useState<Array<Discount>>(
    [],
  );

  useEffect(() => {
    const localDiscounts: Array<Discount> = [];

    discounts.forEach((discount) => {
      if (
        (partnerId &&
          discount.partnersIds.length > 0 &&
          discount.partnersIds.includes(partnerId)) ||
        (checkedDiscountsIds && checkedDiscountsIds.includes(discount.id))
      ) {
        localDiscounts.push(discount);
      }

      if (selectedDiscount && selectedDiscount.id === discount.id) {
        localDiscounts.push(discount);
      }
    });

    setSelectedDiscounts(localDiscounts);
  }, [checkedDiscountsIds, discounts, partnerId, selectedDiscount]);

  const onClose = useCallback(() => {
    if (onReturn) {
      onReturn();
    } else {
      close();
    }
  }, [close, onReturn]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit(selectedDiscounts);
      onClose();
    }
  }, [onClose, onSubmit, selectedDiscounts]);

  const handleSelect = useCallback(
    (discount: Discount) => {
      if (oneSelection) {
        setSelectedDiscounts([discount]);
      }
      const selected = selectedDiscounts.find(({ id }) => id === discount.id);
      if (selected) {
        setSelectedDiscounts((list) => [
          ...list.filter(({ id }) => id !== discount.id),
        ]);
      } else {
        setSelectedDiscounts((list) => [...list, discount]);
      }
    },
    [oneSelection, selectedDiscounts],
  );

  const isSelected = useCallback(
    (discount: Discount) => {
      return !!selectedDiscounts.find(({ id }) => id === discount.id);
    },
    [selectedDiscounts],
  );

  return {
    onClose,
    handleSubmit,
    handleSelect,
    discounts,
    isOpen: type === ModalType.ASSIGN_ACTIVE_DISCOUNTS,
    isLoading: getDiscountsLoading,
    isSelected,
    oneSelection,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
