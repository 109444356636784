import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';

export const Container = styled(DefaultLink)`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};

  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.black};
  }
`;
