import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import Add from 'components/Icon/Icons/Add';
import Substract from 'components/Icon/Icons/Substract';
import DefaultButton from 'components/Button';
import DefaultEmptyState from 'components/EmptyState';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import DefaultOrdersIcon from 'components/Icon/Icons/OrdersBig';
import DefaultIcon from 'components/Icon';
import { prettyScrollbar } from 'styles/mixins';
import DefaultTextField from 'components/Inputs/TextField';
import Price from 'components/Price';

export const Container = styled(DefaultModal)`
  ${Content} {
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Button = styled(DefaultButton)``;

export const EmptyState = styled(DefaultEmptyState)`
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 6.9375rem;
`;

export const IconCircle = styled(DefaultIconCircle)``;

export const OrdersIcon = styled(DefaultOrdersIcon).attrs(() => ({
  size: 20,
}))``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
`;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
`;

export const PartnerRow = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem;
  padding-bottom: 1.1875rem;
`;

export const CreditsRow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem;
  padding-bottom: 1.1875rem;
`;

export const CreditsForm = styled.form`
  display: flex;
  margin-top: 0.8rem;
  width: 100%;
`;

export const CreditsInput = styled(DefaultTextField)`
  &&& {
    width: 100%;
    input[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;

export const CreditsButton = styled(DefaultButton)`
  &&& {
    margin-left: 0.8rem;
  }
`;

export const PartnerLabel = styled.p`
  font-weight: ${({ theme }) => theme.weights.medium};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
`;

export const RestCredits = styled.span<{ $isPositive: boolean }>`
  font-weight: ${({ theme }) => theme.weights.medium};
  color: ${({ $isPositive, theme }) =>
    $isPositive ? theme.colors.primary : theme.colors.danger};
  font-size: 0.8rem;
  margin-left: 0.8rem;
`;

export const PartnerSelector = styled.div<{ $isSelected: boolean }>`
  border: none;
  background: inherit;
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.accent : theme.colors.grey500};
  padding: 0;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  text-transform: capitalize;
`;

export const ArrowRightIcon = styled(DefaultArrowRightIcon).attrs(() => ({
  size: 6.4,
}))`
  margin-left: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;

  ${from.tabletPortrait`
    flex-flow: row nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const ChargeButton = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-left: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    ${from.tabletPortrait`
      margin-right: 0.5rem;
    `}
  }
`;

export const Products = styled.div`
  ${prettyScrollbar};
  max-height: 15.5rem;
  overflow-y: scroll;
`;

export const ProductsContainer = styled.div`
  margin-bottom: 6rem;
  max-height: 16.875rem;
`;

export const ProductsTitleRow = styled.div`
  align-items: center;
  display: flex;
  padding: 1rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
`;

export const ProductRow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0.7rem 1rem 0.5rem 1rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey200};
`;

export const ProductAction = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const AddMoreButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.accent};
  border-radius: 0.25rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.accent};
  font-size: 0.8rem;
  padding: 0.4rem;
  transition: background 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.accent};
  }
`;

export const TotalProducts = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin: 0 1rem 0 0;
`;

export const ProductPrice = styled.p`
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-bottom: 0;
  margin-left: auto;
  margin-top: 0;
`;

export const TotalPrice = styled(ProductPrice)`
  margin-right: 1rem;
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.55;
  margin: 0.5rem;
  text-align: center;
`;

export const ProductNameRow = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: 0.6rem;
`;

export const ProductName = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProductReference = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  margin-top: 0.2rem;
  white-space: nowrap;
`;

export const ProductQuantity = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  margin-left: 0.25rem;
  white-space: nowrap;
`;

export const ProductNamePrice = styled(Price)`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  margin-left: 0.25rem;
  white-space: nowrap;
`;

export const Quantity = styled.div`
  align-items: center;
  display: flex;
`;

export const Credits = styled.div`
  align-items: center;
  display: flex;
`;

export const ExtraQuantity = styled.div`
  align-items: center;
  display: flex;
  margin-left: 1rem;
`;

export const ExtraQuantityText = styled.span`
  color: ${({ theme }) => theme.colors.grey500};
`;

export const IconButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border: none;
  &:first-child {
    margin-right: 0.5rem;
  }
`;

export const AddIcon = styled(Add).attrs(() => ({ size: 16 }))`
  width: 0.8rem;

  ${from.tabletPortrait`
      width: unset;
  `}
`;

export const SubstractIcon = styled(Substract).attrs(() => ({ size: 16 }))`
  width: 0.8rem;

  ${from.tabletPortrait`
      width: unset;
  `}
`;

export const RemoveIcon = styled(DefaultIcon)<{ $disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.danger};
  cursor: pointer;
  margin-left: 1rem;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.danger}80;
      cursor: unset;
    `}
`;

export const TextFieldNumber = styled(DefaultTextField)`
  max-width: 3rem;
  &&& {
    * {
      border: none;
    }

    input[type='number'] {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
      padding: 0;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;

export const Cr = styled.span`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const AddDiscountButton = styled.button<{ $hasDiscount?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  border: 1px solid
    ${({ $hasDiscount, theme }) =>
      $hasDiscount ? theme.colors.green : theme.colors.grey900};
  color: ${({ $hasDiscount, theme }) =>
    $hasDiscount ? theme.colors.green : theme.colors.grey900};
  cursor: pointer;
  font-size: 0.6em;
  margin-left: 0.4rem;
  padding: 0.4rem;
  text-transform: uppercase;
  transition: background 0.3s ease;

  &:hover {
    background: ${({ $hasDiscount, theme }) =>
      $hasDiscount ? theme.colors.green : theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const OriginalPrice = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-right: 0.5rem;
  position: relative;

  &:before {
    border-color: inherit;
    border-top: 1px solid;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 45%;
    transform: skewY(-10deg);
  }
`;

export const OriginalProductPrice = styled(OriginalPrice)`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
`;
