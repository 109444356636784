import { gql } from '@apollo/client';

export const GET_IMAGE_SIGNED_URL = gql`
  mutation GetImageSignedUrl($data: GetImageSignedUrlDto!) {
    getImageSignedUrl(data: $data) {
      image {
        id
        file_name
        content_type
        category
        file_size
      }
      uploadUrl
    }
  }
`;

export const GET_IMAGE_SIGNED_URL_TEMPORARY = gql`
  mutation GetImageSignedUrlTemporaryPartner(
    $code: String!
    $partnerId: ID!
    $data: GetImageSignedUrlDto!
  ) {
    getImageSignedUrlTemporaryPartner(
      code: $code
      partnerId: $partnerId
      data: $data
    ) {
      image {
        id
        file_name
        content_type
        category
        file_size
      }
      uploadUrl
    }
  }
`;

export const CONFIRM_IMAGE_UPLOAD = gql`
  mutation ConfirmImageUpload($data: ConfirmImageUploadDto!) {
    confirmImageUpload(data: $data) {
      id
      url
    }
  }
`;
