import type { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Formik } from 'formik';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import Button from 'components/Button';
import GenerateQRModal from 'containers/Modals/GenerateQR';
import useConnect from './connect';
import {
  Container,
  Main,
  Row,
  Form,
  Switch,
  ContentTitle,
  Content,
  UrlGeneratorContent,
  UrlButton,
  UrlTextField,
  ButtonsContainer,
} from './styles';
import { validationSchema } from './constants';

const PartnerFormConfig: FC = () => {
  const {
    handleCopyLink,
    handleOnSubmit,
    initialValues,
    isLoading,
    organizationConfig,
    BASE_URL,
    handleOpenQRModal,
  } = useConnect();
  const formId = 'menu';
  const formattedOrganizationName = organizationConfig.organizationName
    .trim()
    .toLowerCase()
    .replace(/ /g, '-');

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <Header
          title="Configuración de alta de socio mediante link"
          onClickButton={handleOpenQRModal}
          buttonText="QR"
          buttonVariant="light"
          showGlobalCreateButton={false}
          showRfidButton={false}
        />
        <Main>
          <Formik
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            initialValues={initialValues}
            validateOnMount
          >
            {({ values, setFieldValue, isValid }) => (
              <Form>
                <UrlGeneratorContent>
                  <UrlTextField
                    readOnly
                    id={`${formId}_code`}
                    label="Url"
                    name="code"
                    icon="copyClip"
                    onIconClick={() => handleCopyLink(values.code)}
                  />
                  <UrlButton
                    onClick={() =>
                      setFieldValue(
                        'code',
                        `${BASE_URL}/${formattedOrganizationName}-${uuidv4()}`,
                      )
                    }
                  >
                    Generar
                  </UrlButton>
                </UrlGeneratorContent>
                <ContentTitle>Configuración base</ContentTitle>
                <Content>
                  <Row>
                    <Switch
                      name="isActive"
                      label="Registro activado"
                      id={`${formId}_isActive`}
                    />
                  </Row>
                </Content>
                <ButtonsContainer>
                  <Button disabled={!isValid} type="submit">
                    Guardar
                  </Button>
                </ButtonsContainer>
              </Form>
            )}
          </Formik>
        </Main>
      </Container>
      <GenerateQRModal />
    </>
  );
};

export default PartnerFormConfig;
