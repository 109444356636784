import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CANCEL_EXPENSE,
  CREATE_EXPENSE,
  REMOVE_EXPENSE,
  UPDATE_EXPENSE,
} from 'apollo/mutations/expenses';
import type {
  CreateExpense,
  CreateExpenseVariables,
} from 'apollo/generated/CreateExpense';
import type {
  UpdateExpense,
  UpdateExpenseVariables,
} from 'apollo/generated/UpdateExpense';
import type {
  RemoveExpense,
  RemoveExpenseVariables,
} from 'apollo/generated/RemoveExpense';
import type { ExpenseFormFields } from 'model/Expense';
import type {
  CancelExpense,
  CancelExpenseVariables,
} from 'apollo/generated/CancelExpense';
import { normalizeExpense } from 'model/Expense';

const useExpenseActions = () => {
  const [createExpenseMutation, { loading: isLoadingCreateExpense }] =
    useMutation<CreateExpense, CreateExpenseVariables>(CREATE_EXPENSE);
  const [updateExpenseMutation, { loading: isLoadingUpdateExpense }] =
    useMutation<UpdateExpense, UpdateExpenseVariables>(UPDATE_EXPENSE);
  const [removeExpenseMutation, { loading: isLoadingRemoveExpense }] =
    useMutation<RemoveExpense, RemoveExpenseVariables>(REMOVE_EXPENSE);
  const [cancelExpenseMutation, { loading: isLoadingCancelExpense }] =
    useMutation<CancelExpense, CancelExpenseVariables>(CANCEL_EXPENSE);

  const normalizeFormValuesToUpdate = useCallback(
    async (values: ExpenseFormFields) => ({
      categoryIds: values.categoryIds,
      description: values.description,
      name: values.name,
      price: Number(values.price),
      providerId: values.providerId,
      quantity: Number(values.quantity),
      taxes: Number(values.taxes),
    }),
    [],
  );

  const createExpense = useCallback(
    async (data: ExpenseFormFields) => {
      const dto = await normalizeFormValuesToUpdate(data);
      const res = await createExpenseMutation({
        variables: { data: dto },
      });

      if (res.data?.createExpense) {
        return normalizeExpense(res.data.createExpense);
      }
    },
    [createExpenseMutation, normalizeFormValuesToUpdate],
  );

  const updateExpense = useCallback(
    async (id: string, data: ExpenseFormFields) => {
      const dto = await normalizeFormValuesToUpdate(data);
      await updateExpenseMutation({
        variables: { id, data: dto },
      });
    },
    [updateExpenseMutation, normalizeFormValuesToUpdate],
  );

  const deleteExpense = useCallback(
    async (id: string) => {
      await removeExpenseMutation({
        variables: {
          id,
        },
      });
    },
    [removeExpenseMutation],
  );

  const cancelExpense = useCallback(
    async (id: string) => {
      await cancelExpenseMutation({
        variables: {
          id,
        },
      });
    },
    [cancelExpenseMutation],
  );

  return {
    cancelExpense,
    createExpense,
    updateExpense,
    deleteExpense,
    loading:
      isLoadingCreateExpense ||
      isLoadingUpdateExpense ||
      isLoadingRemoveExpense ||
      isLoadingCancelExpense,
  };
};

export default useExpenseActions;
export type useExpenseActionsType = ReturnType<typeof useExpenseActions>;
