import type { FC } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from 'containers/Support/PrivacyPolicy';
import LegalNotice from 'containers/Support/LegalNotice';
import Login from './Login';
import Flour from './Flour';
import Landing from './Landing';
import MenuCatalog from './MenuCatalog';
import NewPartnerForm from './NewPartnerForm';
import NotFound from './NotFound';

const Guest: FC = () => (
  <>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/menu/:menuCode" element={<MenuCatalog />} />
      <Route path="/new/partner/:formCode" element={<NewPartnerForm />} />
      <Route path="/mmkzrm" element={<Flour />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/legal-notice" element={<LegalNotice />} />
      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </>
);

export default Guest;
