import { memo } from 'react';
import type { FC } from 'react';
import defaultAvatar from 'assets/images/tables/default_avatar.png';
import {
  Container,
  Credits,
  Name,
  Number,
  ImageContainer,
  Image,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, partner, onSelect }) => {
  return (
    <Container className={className} onClick={onSelect}>
      <ImageContainer>
        <Image src={partner.avatarImageUrl || defaultAvatar} alt="imagen" />
      </ImageContainer>
      <Number>#{partner.memberNum}</Number>
      <Name>{partner.fullName}</Name>
      <Credits>{partner.credits} cr</Credits>
    </Container>
  );
};

export default memo(Row);
