import { FC, memo } from 'react';

import type { Props } from './types';

const Employees: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>employees icon</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(3.500000, 0.000000)">
        <path
          d="M8.50000929,11.7114688 C9.97211757,11.7114688 11.1150838,12.0450629 11.9337166,12.5659313 C12.5630695,12.9663672 12.9952481,13.4763644 13.2186924,14.0093282 L3.92218099,14.3640466 C3.92814603,13.5968912 4.3736741,13.013775 5.06103012,12.5723605 C5.87997089,12.0464434 7.02469869,11.7114688 8.50000929,11.7114688 Z M8.50007078,3.6147093 C9.28826949,3.6147093 9.95517509,3.90245941 10.4248727,4.39611081 C10.9159296,4.91221083 11.1875647,5.64273558 11.1875647,6.47061017 C11.1875647,7.40531318 10.897238,8.17573394 10.4164458,8.71847878 C9.92425892,9.27408666 9.23641994,9.58361105 8.50007078,9.58361105 C7.76365282,9.58361105 7.07577432,9.27409544 6.58356465,8.7184883 C6.1027504,8.17574433 5.81241973,7.40531937 5.81241973,6.47061017 C5.81241973,5.64274962 6.08408486,4.91223865 6.57516071,4.39614329 C7.04489777,3.90247391 7.71184982,3.6147093 8.50007078,3.6147093 Z"
          id="Shape"
          stroke="currentColor"
          strokeWidth="1.2294186"
        />
        <rect
          id="Rectangle"
          fill="currentColor"
          x="3"
          y="18"
          width="11"
          height="1.5"
          rx="0.705882353"
        />
        <rect
          id="Rectangle"
          stroke="currentColor"
          strokeWidth="1.5"
          x="0.75"
          y="0.75"
          width="15.5"
          height="22.5"
          rx="2.82352941"
        />
      </g>
    </g>
  </svg>
);

export default memo(Employees);
