import styled, { css } from 'styled-components';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

export const Content = styled.div`
  cursor: pointer;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  height: 100%;
  width: 100%;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Number = styled.div`
  ${TextEllipsis};
  color: ${({ theme }) => theme.colors.grey500};
  flex: 0 0 2rem;
  margin-right: 0.5rem;

  ${from.tabletLandscape`
    flex: 0 0 ${TABLE_METRICS.number};
  `}
`;

export const NumberCircle = styled.div`
  align-items: center;
  border-radius: 50%;
  border: solid 2px ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  height: 1.5rem;
  justify-content: center;
  transition: border-color 0.3s ease;
  width: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  overflow: hidden;

  ${from.tabletLandscape`
    flex: 0 1 ${TABLE_METRICS.name};
    margin-left: 1rem;
    margin-right: 1rem;
  `}
`;

export const Name = styled.div<{ $active?: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.5;
  margin-right: 0.6875rem;
  text-transform: capitalize;

  ${({ $active, theme }) =>
    !$active &&
    css`
      color: ${theme.colors.danger};
    `}

  ${TextEllipsis};
`;

export const Sales = styled.div`
  color: ${({ theme }) => theme.colors.grey300};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.sales};
  ${TextEllipsis};
`;

export const Billing = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: auto;
  margin-right: 1rem;
  text-align: right;
  flex: 0 0 ${TABLE_METRICS.billing};
  ${TextEllipsis};
`;

export const Chevron = styled.div`
  text-align: right;
  padding-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.chevron};
`;

export const ChevronRightIcon = styled(DefaultArrowRightIcon).attrs(() => ({
  size: 6.4,
}))`
  color: ${({ theme }) => theme.colors.grey500};
`;

export const InfoBlock = styled.div`
  flex: 1 1 auto;
`;

export const MonthlyInfo = styled.p`
  color: ${({ theme }) => theme.colors.grey300};
  font-size: 0.8125rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  font-family: 'Inter';
`;
