import axios, { AxiosProgressEvent, CancelToken } from 'axios';
import { clamp } from 'lodash';

const uploadFile = async ({
  file,
  onProgress,
  uploadUrl,
  contentType,
  cancelToken,
}: {
  file: File;
  onProgress: (progress: number) => void;
  uploadUrl: string;
  contentType: string;
  cancelToken?: CancelToken;
}) => {
  try {
    await axios.put(uploadUrl, file, {
      cancelToken,
      headers: {
        'Content-Type': contentType,
      },
      onUploadProgress: (e: AxiosProgressEvent) => {
        const progress = clamp(
          Math.floor((e.loaded / (e?.total || 0)) * 100),
          0,
          100,
        );
        onProgress(progress);
      },
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error('The upload has been cancelled');
    }
    throw error;
  }
};

export default uploadFile;
