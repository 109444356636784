import styled, { css } from 'styled-components';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 0.5rem 0 0.5rem 1.0625rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Position = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  flex: 0 0 2rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  ${TextEllipsis};
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.5;
  margin-right: 0.6875rem;

  ${TextEllipsis};
`;

export const Billing = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  text-align: right;
  flex: 1 0 ${TABLE_METRICS.billing};
  ${TextEllipsis};
`;

export const InfoBlock = styled.div`
  flex: 1 1 auto;
`;
