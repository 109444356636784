import { BaseMutationOptions } from '@apollo/client';
import { apolloClient } from 'services/api';
import { CONFIRM_FILE_UPLOAD } from 'apollo/mutations/files';
import type {
  ConfirmFileUpload,
  ConfirmFileUploadVariables,
} from 'apollo/generated/ConfirmFileUpload';

const confirmFileUpload = async (
  options: BaseMutationOptions<ConfirmFileUpload, ConfirmFileUploadVariables>,
) => {
  const { data } = await apolloClient.mutate<
    ConfirmFileUpload,
    ConfirmFileUploadVariables
  >({
    mutation: CONFIRM_FILE_UPLOAD,
    ...options,
  });

  const id = data?.confirmFileUpload.id;

  if (!id) return undefined;

  return {
    id,
    url: data?.confirmFileUpload.url || '',
  };
};

export default confirmFileUpload;
