import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, Icon, IconCircle, Header } from './styles';

const NewQuota: FC = () => {
  const { handleClose, isOpen, initialValues, isLoading, handleSubmit } =
    useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="quota" />
        </IconCircle>
        <Title>{initialValues.id ? 'Editar' : 'Nueva'} Cuota</Title>
      </Header>
      <Form
        onSubmit={handleSubmit}
        onCancel={handleClose}
        initialValues={initialValues}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default NewQuota;
