import styled from 'styled-components';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import { Form as FormikForm } from 'formik';
import DefaultButton from 'components/Button';
import DefaultIcon from 'components/Icon';

export const Container = styled.div`
  margin-bottom: 6.25rem;
`;

export const Main = styled(SectionContainer)`
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const Form = styled(FormikForm)``;

export const FormContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 2rem;
`;

export const FormContentTitle = styled.div`
  align-content: center;
  color: ${({ theme }) => theme.colors.blue900};
  display: flex;
  font-size: 14px;
  margin-bottom: 0.8rem;
`;

export const Row = styled.div`
  display: block;
  margin-bottom: 2rem;

  ${from.tabletLandscape`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;

export const SubmitButton = styled(DefaultButton)``;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 16,
}))`
  color: ${({ theme }) => theme.colors.blue700};
  margin-right: 0.5rem;
`;

export const DesactivateButton = styled(DefaultButton).attrs(() => ({
  variant: 'danger',
}))``;
