import { FC } from 'react';
import useConnect from './connect';
import {
  AddIcon,
  Button,
  ChevronRightIcon,
  Container,
  Header,
  IconCircle,
  Label,
  MenuButton,
  Icon,
  Title,
  DangerIcon,
  Menus,
  ButtonContent,
} from './styles';

const EmployeeAdvancedMenu: FC = () => {
  const {
    isOpen,
    handleClose,
    handleRemoveEmployee,
    handleOpenUpdatePassword,
    handleToggleEmployeeActivationStatus,
    employeeActive,
  } = useConnect();

  return (
    <Container open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <AddIcon />
        </IconCircle>
        <Title>Opciones Avanzadas</Title>
      </Header>
      <Menus>
        <MenuButton type="button" onClick={handleOpenUpdatePassword}>
          <IconCircle>
            <Icon name="password" />
          </IconCircle>
          <Label>Cambiar contraseña</Label>
          <ChevronRightIcon />
        </MenuButton>
        <MenuButton
          type="button"
          onClick={handleToggleEmployeeActivationStatus}
        >
          <IconCircle $isDanger={employeeActive}>
            {employeeActive ? (
              <DangerIcon name="employees" />
            ) : (
              <Icon name="employees" />
            )}
          </IconCircle>
          <Label>{employeeActive ? 'Bloquear' : 'Desbloquear'} Empleado</Label>
          <ChevronRightIcon />
        </MenuButton>
        <MenuButton type="button" onClick={handleRemoveEmployee}>
          <IconCircle $isDanger>
            <DangerIcon name="trash" />
          </IconCircle>
          <Label>Eliminar Empleado</Label>
          <ChevronRightIcon />
        </MenuButton>
      </Menus>
      <ButtonContent>
        <Button onClick={handleClose}>Cancelar</Button>
      </ButtonContent>
    </Container>
  );
};

export default EmployeeAdvancedMenu;
