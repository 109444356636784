import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PARTNER_FORM_DATA } from 'apollo/queries/organizations';
import { normalizeOrganizationPartnerFormConfig } from 'model/Organization';
import type {
  GetPartnerFormDataQuery,
  GetPartnerFormDataQueryVariables,
} from 'apollo/graphql.types';

const usePartnerFormData = (formCode?: string) => {
  const { data, loading } = useQuery<
    GetPartnerFormDataQuery,
    GetPartnerFormDataQueryVariables
  >(GET_PARTNER_FORM_DATA, {
    skip: !formCode,
    variables: {
      formCode: formCode!,
    },
  });

  const partnerFormData = useMemo(
    () =>
      data?.getPartnerFormData
        ? normalizeOrganizationPartnerFormConfig(data.getPartnerFormData)
        : undefined,
    [data],
  );

  return {
    getPartnerFormDataLoading: loading,
    partnerFormData,
  };
};

export default usePartnerFormData;
