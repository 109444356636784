import type { FC } from 'react';
import Row from './Row';
import { Container, ChevronIcon, Header, Title, List } from './styles';
import { Props } from './types';

const ProductsMobileMetricList: FC<Props> = ({ totalProducts, items }) => {
  return (
    <Container>
      <Header to="/metrics/products">
        <Title>{totalProducts} Productos</Title>
        <ChevronIcon />
      </Header>
      <List>
        {items.map((item) => (
          <Row
            key={item.product.id}
            position={item.position}
            name={item.product.name}
            billing={item.totalSales}
          />
        ))}
      </List>
    </Container>
  );
};

export default ProductsMobileMetricList;
