import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PRODUCT_QUANTITY_HISTORY } from 'apollo/queries/products';
import {
  GetProductHistoricAdjustsQuery,
  GetProductHistoricAdjustsQueryVariables,
  QuantityMovementType,
} from 'apollo/graphql.types';
import { normalizeProductQuantityHistory } from 'model/Product';

const useProductQuantityHistory = ({
  id,
  from,
  to,
  dataType = [],
}: {
  id?: string;
  from?: Date;
  to?: Date;
  dataType?: QuantityMovementType[];
}) => {
  const { loading, data, refetch } = useQuery<
    GetProductHistoricAdjustsQuery,
    GetProductHistoricAdjustsQueryVariables
  >(GET_PRODUCT_QUANTITY_HISTORY, {
    skip: !id,
    variables: {
      id: id!,
      filters: {
        dates: { fromDate: from || null, toDate: to || null },
        dataType,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const productQuantityHistories = useMemo(
    () =>
      data?.productHistoricAdjusts
        ? data.productHistoricAdjusts.map(normalizeProductQuantityHistory)
        : [],
    [data],
  );

  return {
    productQuantityHistories,
    loading,
    refetch,
  };
};

export default useProductQuantityHistory;
