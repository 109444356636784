import useAuthActions from 'apollo/hooks/useAuthActions';
import { useCallback, useMemo } from 'react';
import getMe from 'apollo/hooks/user/useMe';

const useConnect = () => {
  const { logout, loading: logoutLoading } = useAuthActions();
  const { data } = getMe({});

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  const partnerData = useMemo<{ name: string }>(
    () => ({
      name: data?.fullName || 'socio',
    }),
    [data],
  );

  return {
    handleLogout,
    isLoading: logoutLoading,
    partnerData,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
