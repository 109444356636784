import Switch from '@mui/material/Switch';
import styled, { css } from 'styled-components';
import { Label as DefaultLabel } from '../styles';

export const Container = styled.div``;

export const MaterialSwitch = styled(Switch)``;

export const Label = styled(DefaultLabel)<{ $checked: boolean }>`
  cursor: pointer;
  font-size: 0.9rem;
  ${({ $checked }) =>
    $checked &&
    css`
      color: ${({ theme }) => theme.colors.accent};
    `}
`;

export const TitleLabel = styled.label`
  font-size: 0.9rem;
  white-space: pre-wrap;
`;

export const InputContainer = styled.div<{
  $isDisabled?: boolean;
  $hasError?: boolean;
}>`
  border-bottom: solid 2px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  :focus-within {
    border-bottom: solid 2px ${({ theme }) => theme.colors.accent};
    ${Label} {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;
