import styled, { css } from 'styled-components';
import DefaultTrashIcon from 'components/Icon/Icons/Trash';
import DefaultUploadFile from 'components/Icon/Icons/UploadFile';
import DefaultDriveLogo from 'components/Icon/Icons/DriveLogo';
import DefaultDisabledDriveLogo from 'components/Icon/Icons/DisabledDriveLogo';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey200};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  flex: 0 0 ${TABLE_METRICS.name};
  margin-right: 0.5rem;
  text-transform: capitalize;
  ${TextEllipsis};
`;

export const Icons = styled.div`
  align-items: center;
  display: flex;
  text-align: right;
  padding-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.icons};
`;

export const TrashIcon = styled(DefaultTrashIcon).attrs(() => ({
  size: 16,
}))`
  color: ${({ theme }) => theme.colors.danger};
  cursor: pointer;
`;

export const OpenIcon = styled(DefaultUploadFile).attrs(() => ({
  size: 20,
}))`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  margin-right: 0.5rem;
`;

export const DisabledDriveLogoIcon = styled(DefaultDisabledDriveLogo).attrs(
  () => ({
    size: 16,
  }),
)`
  cursor: pointer;
  margin-right: 0.5rem;
`;

export const DriveLogoIcon = styled(DefaultDriveLogo).attrs(() => ({
  size: 16,
}))`
  margin-right: 0.5rem;
`;
