import styled from 'styled-components';
import { Form as DefaultForm } from 'formik';
import DefaultIcon from 'components/Icon';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultTextField from 'components/Inputs/TextField';

export const Container = styled(DefaultModal)`
  ${Content} {
    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
`;

export const IconCircle = styled(DefaultIconCircle)`
  margin-right: 1rem;
`;

export const Title = styled(ModalTitle)`
  margin: 1rem auto 3.5625rem;
`;

export const Label = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.accent};
  width: 3rem;
`;

export const Form = styled(DefaultForm)``;

export const Row = styled.div`
  display: block;
  margin-bottom: 1rem;

  ${from.tabletPortrait`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2rem;

  ${from.tabletPortrait`
    flex-flow: row-reverse nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const CreateButton = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-left: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))``;

export const TextField = styled(DefaultTextField)`
  margin-top: 1rem;
  ${from.tabletPortrait`
    margin-top: 0;
  `}
`;

export const SaveMessage = styled.div`
  margin: 1rem 0;
`;

export const SaveMessageText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  font-weight: 600;
`;

export const SaveMessageNumber = styled.span<{ $isNegative: boolean }>`
  color: ${({ theme, $isNegative }) =>
    $isNegative ? theme.colors.danger : theme.colors.green};
  font-size: 1.1rem;
`;
