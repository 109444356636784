import { makeVar } from '@apollo/client';
import type { ProductOrderItem } from 'model/Product';
import type { Partner } from 'model/Partner';
import type { Discount } from 'model/Discount';

export type OrderData = {
  products: ProductOrderItem[];
  partner: Partner | null;
  discount: Partial<Discount> | null;
};

export const orderVar = makeVar<OrderData>({
  products: [],
  partner: null,
  discount: null,
});
