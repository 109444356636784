import { FC, memo } from 'react';
import Loader from 'components/Loader';
import {
  SectionTitle,
  Header,
  BackLink,
  HeaderContent,
  CreateButton,
} from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const Menus: FC = () => {
  const { menus, handleNavigateToCreationView, isLoading } = useConnect();

  const hasMenus = menus.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header>
        <HeaderContent>
          <BackLink to="/organization" />
          <SectionTitle>Menus</SectionTitle>
        </HeaderContent>
        <CreateButton onClick={handleNavigateToCreationView}>
          Crear
        </CreateButton>
      </Header>
      <Main>
        {!hasMenus && (
          <EmptyState
            title="No hay menús"
            iconName="menu"
            buttonLabel="Crear menú"
            onClickButton={handleNavigateToCreationView}
          >
            Crea tu primer menú
          </EmptyState>
        )}

        {hasMenus && (
          <>
            <TableHead />
            {menus.map((menu) => (
              <Row key={menu.id} menu={menu} />
            ))}
          </>
        )}
      </Main>
    </Container>
  );
};

export default memo(Menus);
