import { FC, memo } from 'react';
import type { Props } from './types';

const Cr: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
  >
    <path
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      d="M11 16.755V19H9v-2.143c-1.712-.1-3.066-.589-4.241-1.797l1.718-1.74c.859.87 2.023 1.16 3.282 1.16 1.565 0 2.405-.599 2.405-1.702 0-.483-.133-.889-.42-1.16-.267-.251-.572-.387-1.202-.483L8.9 10.903c-1.164-.174-2.022-.541-2.634-1.141-.648-.657-.973-1.546-.973-2.707 0-2.155 1.382-3.743 3.707-4.1V1h2v1.932c1.382.145 2.465.62 3.415 1.551l-1.679 1.682c-.859-.832-1.889-.947-2.787-.947-1.412 0-2.099.792-2.099 1.74 0 .348.115.716.401.986.267.252.706.464 1.26.541l1.602.232c1.241.174 2.023.522 2.596 1.063.726.696 1.05 1.702 1.05 2.92 0 2.25-1.567 3.662-3.759 4.055z"
    />
  </svg>
);

export default memo(Cr);
