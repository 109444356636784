import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultIcon from 'components/Icon';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultPrice from 'components/Price';
import DefaultSelect from 'components/Inputs/Select';

export const Container = styled(DefaultModal)`
  ${Content} {
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TextField = styled(DefaultTextField)`
  &&& {
    input[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;

export const Button = styled(DefaultButton)``;

export const IconCircle = styled(DefaultIconCircle)`
  margin-left: auto;
  margin-right: auto;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 20,
}))``;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  margin-top: 2.5rem;

  ${from.tabletPortrait`
    flex-flow: row nowrap;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const AcceptButton = styled(DefaultButton)`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-right: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    ${from.tabletPortrait`
      margin-right: 0.5rem;
    `}
  }
`;

export const Price = styled(DefaultPrice)<{ $credits: number }>`
  color: ${({ $credits, theme }) =>
    $credits < 0 ? theme.colors.danger : theme.colors.green};
`;

export const Select = styled(DefaultSelect)`
  margin-top: 1rem;
`;

export const EmptyConcept = styled.div`
  text-align: left;
  margin-top: 1rem;
`;

export const EmptyConceptMsg = styled.p`
  font-size: 0.85rem;
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const LinkToOrganization = styled.span`
  border: none;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  transition: text-decoration 200ms ease-out;

  &:hover {
    text-decoration: underline;
  }
`;
