import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultOrdersIcon from 'components/Icon/Icons/ShoppingCart';
import { prettyScrollbar } from 'styles/mixins';

export const Container = styled(DefaultModal)`
  ${Content} {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Button = styled(DefaultButton)``;

export const IconCircle = styled(DefaultIconCircle)``;

export const OrdersIcon = styled(DefaultOrdersIcon).attrs(() => ({
  size: 20,
}))``;

export const HeaderContainer = styled.div`
  flex: 0 1 auto;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2.75rem;
`;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
`;

export const Buttons = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;
`;

export const CloseButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
  &&& {
    ${from.tabletPortrait`
      margin-right: 0.5rem;
    `}
  }
`;

export const Partners = styled.div`
  ${prettyScrollbar};
  max-height: 16.875rem;
  overflow-y: scroll;
`;

export const PartnersContainer = styled.div`
  flex: 1 1 auto;
  padding-bottom: 1rem;
`;

export const PartnersRow = styled.div`
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  padding: 1.0625rem 1rem 1.125rem;
  justify-content: space-between;
`;

export const TotalPartners = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin: 0 1rem 0 0;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PartnerName = styled.span`
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 0.5rem;
  text-transform: capitalize;
  ${TextEllipsis};
`;

export const PartnerNameContent = styled.div``;

export const PartnerNum = styled.span`
  ${TextEllipsis};
`;

export const PartnerDiscount = styled.span`
  font-size: 0.8rem;
`;

export const PartnerPrice = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: column;
`;
