import { memo } from 'react';
import type { FC } from 'react';
import {
  ChevronIcon,
  Container,
  InfoProduct,
  Label,
  Price,
  PriceContainer,
  Thumbnail,
  Image,
  ImageContainer,
} from './styles';
import type { Props } from './types';
import { ReferenceCode } from '../../../Main/Products/ListFormat/styles';

const Row: FC<Props> = ({
  activateProductReference,
  className,
  product,
  onSelect,
}) => (
  <Container className={className} onClick={onSelect}>
    {!product.mainImageUrl ? (
      <Thumbnail>{product.abbreviation}</Thumbnail>
    ) : (
      <ImageContainer>
        <Image src={product.mainImageUrl} />
      </ImageContainer>
    )}
    <InfoProduct>
      <Label>
        {product.name}
        {activateProductReference && product.referenceCode && (
          <ReferenceCode>#{product.referenceCode}</ReferenceCode>
        )}{' '}
        ({product.quantity.toFixed(2)})
      </Label>
      <PriceContainer>
        <Price>{product.price}</Price>
        <ChevronIcon />
      </PriceContainer>
    </InfoProduct>
  </Container>
);

export default memo(Row);
