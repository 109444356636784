import { useCallback, useEffect, useState } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useProviders from 'apollo/hooks/provider/useProviders';
import type { Provider } from 'model/Provider';

const useConnect = () => {
  const { type, close, selectProviderPayload } = useModal();
  const { providers, loading: getProvidersLoading } = useProviders();
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
    null,
  );

  const { checkedProviderId, onReturn, onSubmit } = selectProviderPayload || {};

  useEffect(() => {
    providers.forEach((provider) => {
      if (checkedProviderId && checkedProviderId === provider.id) {
        setSelectedProvider(provider);
      }
    });
  }, [checkedProviderId, providers]);

  const onClose = useCallback(() => {
    if (onReturn) {
      onReturn();
    } else {
      close();
    }
  }, [close, onReturn]);

  const handleSubmit = useCallback(() => {
    if (onSubmit && selectedProvider) {
      onSubmit(selectedProvider);
      onClose();
    }
  }, [onClose, onSubmit, selectedProvider]);

  const handleSelect = useCallback((provider: Provider) => {
    setSelectedProvider(provider);
  }, []);

  const isSelected = useCallback(
    (provider: Provider) => {
      return selectedProvider?.id === provider.id;
    },
    [selectedProvider],
  );

  return {
    handleSelect,
    handleSubmit,
    isLoading: getProvidersLoading,
    isOpen: type === ModalType.SELECT_PROVIDER,
    isSelected,
    onClose,
    providers,
    selectedProvider,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
