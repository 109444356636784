import { useCallback } from 'react';

import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, webcamPayload, type } = useModal();

  const { onAccept, onReturn } = webcamPayload || {};

  const handleClose = useCallback(() => {
    if (onReturn) {
      onReturn();
    } else {
      close();
    }
  }, [close, onReturn]);

  const handleAccept = useCallback(
    (imgUrl: string) => {
      if (!onAccept) return;
      onAccept(imgUrl);
    },
    [onAccept],
  );

  return {
    handleClose,
    handleAccept,
    isOpen: type === ModalType.WEBCAM,
  };
};

export default useConnect;
