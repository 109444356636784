import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { Label as DefaultLabel } from '../styles';

export const Container = styled.div``;

export const MaterialCheckbox = styled(Checkbox)``;

export const Label = styled(DefaultLabel)`
  font-size: 0.9rem;
`;

export const InputContainer = styled.div<{
  $isDisabled?: boolean;
  $hasError?: boolean;
}>`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
