import type { FC } from 'react';
import Sidebar from 'components/Sidebars/OrganizationSidebar';
import TabBar from 'components/TabBar';
import Loader from 'components/Loader';
import { Container, Content, SidebarSpacer } from './styles';
import useConnect from './connect';
import { Props } from './types';

const MainLayout: FC<Props> = ({ children }) => {
  const { handleLogout, isLoading, organizationData } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Sidebar onLogout={handleLogout} organization={organizationData} />
      <SidebarSpacer />
      <Content>{children}</Content>
      <TabBar />
    </Container>
  );
};

export default MainLayout;
