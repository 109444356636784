import styled from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 1rem 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;

  ${from.tabletLandscape`
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
`}
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_METRICS.name};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Adjust = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_METRICS.adjust};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TotalCredits = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_METRICS.totalCreditsAdded};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RealCredits = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_METRICS.realCreditsAdded};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CreationDate = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 1 ${TABLE_METRICS.creationDate};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LastUpdateDate = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 1 ${TABLE_METRICS.lastUpdateDate};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
