import { FC, memo } from 'react';

import type { Props } from './types';

const Menu: FC<Props> = ({ size = 12, ...rest }) => (
  <svg
    width={size}
    height={size}
    fill="currentColor"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...rest}
  >
    <g>
      <g>
        <path
          d="M256,0L31.394,32.593V512L256,479.407L480.606,512V32.593L256,0z M240.806,450.907L61.781,476.884V58.889l179.025-25.977
V450.907z M450.219,476.884l-179.025-25.977V32.911l179.025,25.977V476.884z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M305.37,220.596v204.482l110.672,16.059V236.655L305.37,220.596z M385.654,406.021l-49.897-7.239v-143.07l49.897,7.239
			V406.021z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M95.958,236.654v204.484l110.672-16.059V220.595L95.958,236.654z M176.243,398.783l-49.897,7.239c0,0,0-143.07,0-143.07
			l49.897-7.239V398.783z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect
          x="345.497"
          y="36.28"
          transform="matrix(0.1436 -0.9896 0.9896 0.1436 217.2465 436.2592)"
          width="30.387"
          height="112.653"
        />
      </g>
    </g>
    <g>
      <g>
        <rect
          x="345.497"
          y="86.347"
          transform="matrix(0.1436 -0.9896 0.9896 0.1436 167.6995 479.1369)"
          width="30.387"
          height="112.653"
        />
      </g>
    </g>
    <g>
      <g>
        <rect
          x="345.529"
          y="136.446"
          transform="matrix(0.1436 -0.9896 0.9896 0.1436 118.1468 522.0733)"
          width="30.387"
          height="112.653"
        />
      </g>
    </g>
    <g>
      <g>
        <rect
          x="94.972"
          y="77.414"
          transform="matrix(0.9896 -0.1436 0.1436 0.9896 -11.7307 22.687)"
          width="112.653"
          height="30.387"
        />
      </g>
    </g>
    <g>
      <g>
        <rect
          x="94.969"
          y="127.502"
          transform="matrix(0.9896 -0.1436 0.1436 0.9896 -18.9235 23.2059)"
          width="112.653"
          height="30.387"
        />
      </g>
    </g>
    <g>
      <g>
        <rect
          x="94.963"
          y="177.582"
          transform="matrix(0.9896 -0.1436 0.1436 0.9896 -26.1155 23.7242)"
          width="112.653"
          height="30.387"
        />
      </g>
    </g>
  </svg>
);

export default memo(Menu);
