import styled from 'styled-components';
import { SEARCH_HEIGHT } from 'components/Search/styles';
import DefaultHeader, {
  HEADER_CHILDREN_MARGIN,
  HEADER_HEIGHT,
} from 'containers/Common/Header';
import { from } from 'styles/responsive';
import { SectionContainer } from 'components/Layout';
import DefaultEmptyState from 'components/EmptyState';
import DefaultFilters from 'components/Filters';
import DefaultOrderCard from './BookingCard';

export const Container = styled.div`
  position: relative;
`;

export const Main = styled(SectionContainer)`
  padding-top: calc(
    ${HEADER_HEIGHT} + ${HEADER_CHILDREN_MARGIN} + ${SEARCH_HEIGHT}
  );

  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const Header = styled(DefaultHeader)`
  h1 {
    font-size: 1.5rem;
  }
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const OrdersContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  max-height: 28rem;
  padding-bottom: 7rem;
  padding-top: 1.5rem;

  ${from.tabletPortrait`
    margin-top: 1rem;
    max-height: unset;
    padding-top: unset;
  `};

  ${from.tabletLandscape`
    background-color: ${({ theme }) => theme.colors.white};
    display: grid;
    grid-template-columns: auto auto auto;
    padding-bottom: unset;
  `};
`;

export const OrderCard = styled(DefaultOrderCard)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}0a;
  margin-bottom: 1rem;

  ${from.tabletLandscape`
    border: 1px solid ${({ theme }) => theme.colors.black}0a;
    border-top: 0;
    margin: 0;
  `};
`;

export const Filters = styled(DefaultFilters)``;
