import { FC, memo } from 'react';
import { Container, Title, Description, Content } from './styles';
import { Props } from './types';

const Index: FC<Props> = ({ title, description, sectionName, onSelect }) => (
  <Container onClick={() => onSelect(sectionName)}>
    <Content>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Content>
  </Container>
);

export default memo(Index);
