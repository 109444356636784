import styled from 'styled-components';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 1rem auto 0 auto;
`;

export const DateTimeFilterContainer = styled.div`
  padding: 0 1.25rem 1.5rem;
`;

export const ProductGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
