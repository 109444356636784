import styled from 'styled-components';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultAddIcon from 'components/Icon/Icons/Add';
import DefaultIcon from 'components/Icon';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import { from } from 'styles/responsive';

export const Container = styled(DefaultModal)`
  ${Content} {
    display: flex;
    flex-direction: column;
    ${from.tabletPortrait`
      max-width: 21.5rem;
    `}
  }
`;

export const AddIcon = styled(DefaultAddIcon).attrs(() => ({ size: 20 }))``;

export const Header = styled.div`
  flex: 0 1 auto;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  padding: 0 0 2.5rem 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
`;

export const Label = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Menus = styled.div`
  flex: 1 1 auto;
`;

export const MenuButton = styled.button`
  cursor: pointer;
  display: flex;
  padding: 12px 0;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  justify-content: space-between;
  width: 100%;
  &:hover {
    ${Label} {
      color: ${({ theme }) => theme.colors.accent};
      transition: color 200ms ease;
    }
  }
`;

export const IconCircle = styled(DefaultIconCircle)<{ $isDanger?: boolean }>`
  background-color: ${({ $isDanger, theme }) =>
    $isDanger ? theme.colors.danger : theme.colors.blue300}0A;
  margin-right: 1rem;
  height: 2.5rem;
  width: 2.5rem;
`;

export const ChevronRightIcon = styled(DefaultArrowRightIcon).attrs(() => ({
  size: 6.4,
}))`
  color: ${({ theme }) => theme.colors.grey500};
  margin-left: auto;
`;

export const Icon = styled(DefaultIcon)``;

export const DangerIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const ButtonContent = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  margin-top: 1rem;
  width: 100%;
`;

export const Button = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
`;
