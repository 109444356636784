import { FC, memo } from 'react';
import { Container, Name, Number } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Name>Dato</Name>
      <Number>Valor</Number>
    </Container>
  );
};

export default memo(Row);
