import { FC, memo } from 'react';
import { Formik } from 'formik';
import useConnect from './connect';
import {
  Container,
  Title,
  IconCircle,
  Icon,
  Header,
  Form,
  Buttons,
  CancelButton,
  Row,
  TextField,
} from './styles';

const ExpenseDetailsModal: FC = () => {
  const { handleClose, handleSubmit, initialValues, isOpen } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="expenses" size={24} />
        </IconCircle>
        <Title>Detalles del gasto {initialValues.name}</Title>
      </Header>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        <Form>
          <Row>
            <TextField variant="standard" readOnly name="name" label="Nombre" />
            <TextField
              variant="standard"
              readOnly
              name="addedBy"
              label="Creado por"
            />
          </Row>
          <Row>
            <TextField
              variant="standard"
              readOnly
              name="price"
              label="Precio"
            />
            <TextField
              variant="standard"
              readOnly
              name="quantity"
              label="Unidades"
            />
          </Row>
          <Row>
            <TextField
              variant="standard"
              readOnly
              name="createdAt"
              label="Fecha"
            />
            <TextField
              variant="standard"
              readOnly
              name="provider"
              label="Proveedor"
            />
          </Row>
          <TextField
            variant="standard"
            readOnly
            name="categories"
            label="Categorías"
          />

          <br />

          <TextField
            disabled
            isMultiline
            rows={5}
            name="description"
            label="Descripción"
            id="description"
          />
          <Buttons>
            <CancelButton onClick={handleClose}>Cerrar</CancelButton>
          </Buttons>
        </Form>
      </Formik>
    </Container>
  );
};

export default memo(ExpenseDetailsModal);
