import { useState, useRef, useEffect, useCallback, ChangeEvent } from 'react';
import { LogicProps } from './types';

const useLogic = ({ debounce, onChange, value }: LogicProps) => {
  const [localValue, setLocalValue] = useState(value);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (debounce) {
      setLocalValue(value);
    }
  }, [debounce, value]);

  const localOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLocalValue(e.target.value);
      const event = { ...e };

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        onChange(event);
      }, debounce);
    },
    [debounce, onChange],
  );

  return { localOnChange, localValue };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
