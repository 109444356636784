import styled from 'styled-components';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  padding: 0 1rem 3.6rem 1rem;

  ${from.tabletLandscape`
    padding: 0 0 3.6rem 0;
  `};
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const DateTimeFilterContainer = styled.div`
  padding-top: 7rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;
