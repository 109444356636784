import { FC, memo } from 'react';
import { Container, IconsSpace, Name, Times } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Name>Dias de la semana</Name>
      <Times>Tramos</Times>
      <IconsSpace />
    </Container>
  );
};

export default memo(Row);
