import { FC, memo } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, IconCircle, Icon, Header } from './styles';

const AdjustProductQuantityModal: FC = () => {
  const {
    actionType,
    adjustState,
    handleClose,
    handleSubmit,
    initialValues,
    isFirstSave,
    isLoading,
    isOpen,
    handleChangeNewQuantity,
  } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="flower" size={24} />
        </IconCircle>
        {actionType === 'set' && (
          <Title>Ajuste del producto {initialValues.name}</Title>
        )}
        {actionType === 'add' && (
          <Title>Añadir stock al producto {initialValues.name}</Title>
        )}
        {actionType === 'remove' && (
          <Title>Retirar stock del producto {initialValues.name}</Title>
        )}
      </Header>
      <Form
        actionType={actionType}
        adjustState={adjustState}
        initialValues={initialValues}
        isFirstSave={isFirstSave}
        isLoading={isLoading}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        onChangeNewQuantity={handleChangeNewQuantity}
      />
    </Container>
  );
};

export default memo(AdjustProductQuantityModal);
