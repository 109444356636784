import type { FC } from 'react';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import {
  Container,
  Content,
  EmptyState,
  DateTimeFilterContainer,
  Filters,
  Filter,
} from './styles';

const PartnerTransactions: FC = () => {
  const {
    handleOpenPurchaseDetailsModal,
    handleRevertTransaction,
    isLoading,
    transactions,
    filterDates,
    handleOnSearch,
    handleToggleDataType,
    FILTER_DATA_OPTIONS,
    dataTypeFilters,
  } = useConnect();

  const hasResults = transactions?.length > 0;

  return (
    <Container>
      <DateTimeFilterContainer>
        <DateTimeFilter
          fromDate={filterDates.from}
          toDate={filterDates.to}
          onSearch={handleOnSearch}
        />
      </DateTimeFilterContainer>
      <Filters>
        {FILTER_DATA_OPTIONS.map((option) => (
          <Filter
            key={option.value}
            $isSelected={dataTypeFilters.includes(option.value)}
            onClick={() => handleToggleDataType(option.value)}
          >
            {option.label}
          </Filter>
        ))}
      </Filters>
      {isLoading && <Loader />}
      {!isLoading && (
        <Content>
          {!hasResults && (
            <EmptyState
              title="No se puede generar la información"
              iconName="char"
            >
              No hay datos suficientes para generar estadísticas de este socio
            </EmptyState>
          )}
          {hasResults && (
            <>
              <TableHead />
              {transactions.map((transaction) => (
                <Row
                  key={transaction.id}
                  transaction={transaction}
                  onClickItems={handleOpenPurchaseDetailsModal}
                  onRevert={() =>
                    handleRevertTransaction({
                      id: transaction.id,
                      type: transaction.type,
                    })
                  }
                />
              ))}
            </>
          )}
        </Content>
      )}
    </Container>
  );
};

export default PartnerTransactions;
