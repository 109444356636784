import { FC } from 'react';
// import formatNumber from 'utils/formatNumber';
import {
  Container,
  Content,
  ForceApplyButton,
  ListLoader,
  Pagination,
  // Results,
  Search,
} from './styles';
import { Props } from './types';

const Filters: FC<Props> = ({
  children,
  className,
  currentPage,
  debounce = 500,
  isLoading = false,
  mode = 'horizontal',
  nResults,
  onPageChange,
  onSearchChange,
  pageSize,
  filter,
  onForceSearch,
}) => (
  <Container className={className}>
    <Content>
      <Search
        $mode={mode}
        debounce={debounce}
        value={filter}
        onChange={onSearchChange}
      />
      {onForceSearch && (
        <ForceApplyButton onClick={onForceSearch}>Aplicar</ForceApplyButton>
      )}
      {/* {nResults > 0 && <Results>{formatNumber(nResults)} resultados</Results>} */}
      {currentPage && pageSize && onPageChange && (
        <Pagination
          onPageChange={onPageChange}
          page={currentPage}
          pageSize={pageSize}
          total={nResults}
        />
      )}
    </Content>
    {children}
    {isLoading && <ListLoader />}
  </Container>
);

export default Filters;
