import { useCallback } from 'react';
import { CashierProduct } from 'model/Cashier';
import useModal from 'apollo/hooks/useModal';

const useLogic = () => {
  const { openCashierProducts } = useModal();

  const handleShowProducts = useCallback(
    (products: CashierProduct[], totalDiscounts: number) => {
      openCashierProducts({ products, totalDiscounts });
    },
    [openCashierProducts],
  );

  return {
    handleShowProducts,
  };
};

export default useLogic;
