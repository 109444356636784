import {
  OrganizationState,
  ProductsListType,
} from 'apollo/generated/globalTypes';
import { normalizeBooking } from 'model/Booking';
import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import type {
  OrganizationData,
  OrganizationData_subscription,
} from 'apollo/generated/OrganizationData';
import type { OrganizationSubscriptionData } from 'apollo/generated/OrganizationSubscriptionData';
import {
  DocumentCustomDataInputType,
  OrganizationDocument as OrganizationDocumentData,
  OrganizationMenuConfigDataFragment,
  OrganizationMenuDataDataFragment,
  OrganizationMenuType,
  OrganizationPartnerFormConfigDataFragment,
  TemporaryPartnerDataFragment,
  UserGender,
} from 'apollo/graphql.types';

export type FormFields = {
  id: string;
  name: string;
  isActive: boolean;
  document: string;
  state: OrganizationState;
};

export type DocumentFormFields = {
  text: string;
  name: string;
  hasSignature: boolean;
  customData: Array<{
    key: string;
    label: string;
    inputType: DocumentCustomDataInputType;
  }>;
};

export type MenuConfigFormFields = {
  name: string;
  type: OrganizationMenuType;
  showLogo: boolean;
  useLogoToBackground: boolean;
  showProductPrice: boolean;
  showProductDescription: boolean;
  showProductDiscount: boolean;
  showPriceSimbol: boolean;
  defaultImageUrl?: string;
  backgroundColor: string;
  imageBorderColor: string;
  productTitleColor: string;
  productDescriptionColor: string;
  productPriceColor: string;
  categoriesColor: string;
  categoriesNames: string[];
  menuCode: string;
  menuActive: boolean;
  menuSchedules: Array<{
    daysOfWeek: string[];
    schedules: Array<{ startTime: string; endTime: string }>;
  }>;
};

export type ConfigFormFields = {
  allowExtraQuantity?: boolean;
  allowNegativeCredits?: boolean;
  activateRfidReader?: boolean;
  activateProductReference?: boolean;
  forceOpenCashier?: boolean;
  activeStrictCashier?: boolean;
  deleteExpiredPartner?: boolean;
  daysToRemoveExpiredPartner?: number;
  reasignReleasedPartnerNumber?: boolean;
  productsListType?: ProductsListType;
  blockContentPin?: {
    currentPin: string;
    newPin: string;
  };
  maxExtraQuantity?: number;
};

export type CreditConceptFormFields = {
  creditConcept: string;
  creditConceptToRemove?: string;
};

export const normalizeOrganizationMenuConfig = (
  input: OrganizationMenuConfigDataFragment,
) => ({
  id: input?.id,
  name: input?.name || '',
  type: input?.type || '',
  showLogo: input?.showLogo,
  useLogoToBackground: input?.useLogoToBackground,
  showProductPrice: input?.showProductPrice,
  showProductDescription: input?.showProductDescription,
  showProductDiscount: input?.showProductDiscount,
  showPriceSimbol: input?.showPriceSimbol,
  defaultImageUrl: input?.defaultImageUrl || '',
  backgroundColor: input?.backgroundColor || '',
  imageBorderColor: input?.imageBorderColor || '',
  productTitleColor: input?.productTitleColor || '',
  productDescriptionColor: input?.productDescriptionColor || '',
  productPriceColor: input?.productPriceColor || '',
  categoriesColor: input?.categoriesColor || '',
  categoriesNames: input?.categoriesNames || [],
  menuCode: input?.menuCode || '',
  menuActive: input?.menuActive,
  menuSchedules:
    input?.menuSchedules?.map((workSchedule) => ({
      daysOfWeek: workSchedule.dayOfWeek || [],
      schedules: workSchedule.schedules.map((schedule) => ({
        startTime: schedule.startTime || '',
        endTime: schedule.endTime || '',
      })),
    })) || [],
  createdAt: (input.createdAt && new Date(input.createdAt)) || new Date(),
  updatedAt: (input.updatedAt && new Date(input.updatedAt)) || new Date(),
});

export type OrganizationMenu = ReturnType<
  typeof normalizeOrganizationMenuConfig
>;

export const normalizeOrganizationMenuData = (
  input: OrganizationMenuDataDataFragment | null,
) => ({
  type: input?.type || OrganizationMenuType.Selectable,
  showLogo: input?.showLogo,
  useLogoToBackground: input?.useLogoToBackground,
  showProductPrice: input?.showProductPrice,
  showPriceSimbol: input?.showPriceSimbol,
  showProductDescription: input?.showProductDescription,
  showProductDiscount: input?.showProductDiscount,
  defaultImageUrl: input?.defaultImageUrl || '',
  backgroundColor: input?.backgroundColor || '',
  imageBorderColor: input?.imageBorderColor || '',
  productTitleColor: input?.productTitleColor || '',
  productDescriptionColor: input?.productDescriptionColor || '',
  productPriceColor: input?.productPriceColor || '',
  categoriesColor: input?.categoriesColor || '',
  categoriesNames: input?.categoriesNames || [],
  menuCode: input?.menuCode || '',
  menuActive: input?.menuActive,
  organizationLogo: input?.organizationLogo,
  products:
    input?.products.map((product) => ({
      categories: product.categories || [],
      price: product.price || 0,
      priceWithDiscount: product.priceWithDiscount || 0,
      hasDiscount: product.hasDiscount || false,
      description: product.description || '',
      title: product.title || '',
      referenceCode: product.referenceCode || '',
      imageUrl: product.imageUrl || '',
    })) || [],
});

export type MenuData = ReturnType<typeof normalizeOrganizationMenuData>;

export const normalizeOrganizationSubscription = (
  input: OrganizationSubscriptionData | OrganizationData_subscription | null,
) => ({
  id: input?.id || '',
  allowedCashier: input?.allowedCashier || false,
  allowedDiscounts: input?.allowedDiscounts || false,
  allowedEmployees: input?.allowedEmployees || false,
  allowedExpenses: input?.allowedExpenses || false,
  allowedGenerateFiles: input?.allowedGenerateFiles || false,
  allowedMetrics: input?.allowedMetrics || false,
  allowedOrders: input?.allowedOrders || false,
  allowedPartnerTransactions: input?.allowedPartnerTransactions || false,
  allowedProductTransactions: input?.allowedProductTransactions || false,
  allowedReports: input?.allowedReports || false,
  description: input?.description || '',
  maxEmployees: input?.maxEmployees || 0,
  maxPartners: input?.maxPartners || 0,
  maxProducts: input?.maxProducts || 0,
  maxUserFiles: input?.maxUserFiles || 0,
  name: input?.name || '',
  price: input?.price || 0,
});

export type OrganizationSubscription = ReturnType<
  typeof normalizeOrganizationSubscription
>;

export const normalizeOrganization = (input: OrganizationData) => ({
  id: input.id || '',
  bookings: removeOptionalsFromArray(input?.bookings ?? []).map((occurrence) =>
    normalizeBooking(occurrence),
  ),
  config: input.config
    ? {
        allowNegativeCredits: input.config.allowNegativeCredits,
        // @ts-ignore
        activateRfidReader: input.config.activateRfidReader,
        // @ts-ignore
        activateProductReference: input.config.activateProductReference,
        // @ts-ignore
        forceOpenCashier: input.config.forceOpenCashier,
        // @ts-ignore
        activeStrictCashier: input.config.activeStrictCashier,
        creditConcepts: input.config.creditConcepts || [],
        deleteExpiredPartner: input.config.deleteExpiredPartner,
        daysToRemoveExpiredPartner: input.config.daysToRemoveExpiredPartner,
        reasignReleasedPartnerNumber: input.config.reasignReleasedPartnerNumber,
        productsListType: input.config.productsListType,
      }
    : null,
  document: input.document || '',
  isActive: input.isActive || false,
  name: input.name || '',
  state: input.state || OrganizationState.DEMO,
  logoImageId: input.logo?.id || '',
  logoImageSize: input.logo?.file_size || 0,
  logoImageUrl: input.logo?.url || '',
  address: input?.address || '',
  city: input?.city || '',
  locality: input?.locality || '',
  zipCode: input?.zipCode || '',
  email: input?.email || '',
  subscription: normalizeOrganizationSubscription(input.subscription),
  // @ts-ignore
  hasDriveIntegration: input?.hasDriveIntegration || false,
});

export type Organization = ReturnType<typeof normalizeOrganization>;

export const normalizeOrganizationDocument = (
  input: OrganizationDocumentData,
) => ({
  id: input.id || '',
  name: input.name || '',
  text: input.text || '',
  hasSignature: input.hasSignature || false,
  customData:
    (input?.customData &&
      input.customData.map((item) => ({
        key: (item.key && item.key.replace('{', '').replace('}', '')) || '',
        label: item.label || '',
        inputType: item.inputType || '',
      }))) ||
    [],
  hasCustomData: input?.customData && input.customData.length > 0,
  createdAt: (input.createdAt && new Date(input.createdAt)) || new Date(),
  updatedAt: (input.updatedAt && new Date(input.updatedAt)) || new Date(),
});

export type OrganizationDocument = ReturnType<
  typeof normalizeOrganizationDocument
>;

export const normalizeOrganizationPartnerFormConfig = (
  input: OrganizationPartnerFormConfigDataFragment,
) => ({
  id: input.id || '',
  code: input.code || '',
  isActive: input.isActive || false,
  createdAt: (input.createdAt && new Date(input.createdAt)) || new Date(),
  updatedAt: (input.updatedAt && new Date(input.updatedAt)) || new Date(),
});

export const normalizeOrganizationPartnerRequest = (
  input: TemporaryPartnerDataFragment,
) => ({
  id: input.id || '',
  address: input?.user?.address || '',
  document: input?.user?.document || '',
  email: input?.user?.email || '',
  firstName: input?.user?.firstName || '',
  gender: input?.user?.gender || UserGender.Other,
  lastName: input?.user?.lastName || '',
  phoneNumber: input?.user?.phoneNumber || '',
  usage: input.usage || '',
  birthDate: input?.user?.birthDate || null,
  createdAt: (input.createdAt && new Date(input.createdAt)) || new Date(),
  updatedAt: (input.updatedAt && new Date(input.updatedAt)) || new Date(),
  avatarUrl: input?.user?.avatar?.url || '',
});

export type OrganizationPartnerRequest = ReturnType<
  typeof normalizeOrganizationPartnerRequest
>;
