import styled from 'styled-components';
import { from } from 'styles/responsive';

export const SectionContainer = styled.main`
  padding: 1.125rem 1rem;
  width: 100%;
  ${from.tabletPortrait`
    padding: 1.125rem;
    max-width: 80rem;
	  margin: 0 auto;
  `}
`;

export const SectionHeader = styled.header`
  align-items: baseline;
  display: flex;
  padding: 0 1.125rem;
`;

export const Flag = styled.div`
  background: ${({ theme }) => theme.colors.greenPale};
  border-radius: 0.125rem;
  border: solid 1px ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.green};
  display: inline-block;
  font-size: 0.6875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  padding: 0.0625rem 0.6875rem;
`;
