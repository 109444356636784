import { FC, memo } from 'react';
import { Formik } from 'formik';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  TextField,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const Form: FC<Props> = ({
  className,
  creationLoading,
  id = 'new',
  initialValues,
  onCancel,
  onSubmit,
}) => {
  const formId = `provider_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isValid: formIsValid }) => (
        <Container className={className} onSubmit={handleSubmit}>
          <TextField
            placeholder="Mercadona"
            name="name"
            label="Nombre"
            id={`${formId}_name`}
          />
          <TextField
            placeholder="Compra mensual"
            name="description"
            label="Descripción"
            id={`${formId}_description`}
          />
          <Buttons>
            <CreateButton
              type="submit"
              disabled={!formIsValid}
              isLoading={creationLoading}
            >
              {initialValues.id ? 'Editar' : 'Crear'}
            </CreateButton>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(Form);
