import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import type {
  GetOrganizationMenuConfigQuery,
  GetOrganizationMenuConfigQueryVariables,
} from 'apollo/graphql.types';
import { GET_ORGANIZATION_MENU_CONFIG } from 'apollo/queries';
import { normalizeOrganizationMenuConfig } from 'model/Organization';

const useMenuConfig = (id?: string) => {
  const { data, loading, refetch } = useQuery<
    GetOrganizationMenuConfigQuery,
    GetOrganizationMenuConfigQueryVariables
  >(GET_ORGANIZATION_MENU_CONFIG, {
    skip: !id,
    variables: { id: id! },
  });

  const menuConfig = useMemo(
    () =>
      data?.getMenuConfiguration
        ? normalizeOrganizationMenuConfig(data.getMenuConfiguration)
        : undefined,
    [data],
  );

  return {
    loading,
    menuConfig,
    refetch,
  };
};

export default useMenuConfig;
