import { useMemo } from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_EVENTS } from 'apollo/queries/events';
import { normalizeEvent } from 'model/OrganizationEvent';
import type { GetEvents } from 'apollo/generated/GetEvents';

const useEvents = ({
  fetchPolicy = 'cache-and-network',
}: {
  fetchPolicy?: QueryHookOptions<GetEvents>['fetchPolicy'];
} = {}) => {
  const { data, loading } = useQuery<GetEvents>(GET_EVENTS, {
    fetchPolicy,
  });

  const events = useMemo(
    () =>
      data?.organizationEvents
        ? data.organizationEvents.map(normalizeEvent)
        : [],
    [data],
  );

  return {
    eventsLoading: loading,
    events,
  };
};

export default useEvents;
