import { FC, memo } from 'react';
import { Formik } from 'formik';
import { Button, Buttons, Container, Switch, TextField } from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const Form: FC<Props> = ({
  className,
  id = 'new',
  initialValues,
  isLoading,
  onCancel,
  onRemove,
  onSubmit,
}) => {
  const formId = `note_${id}`;
  const isEdition = !!initialValues?.id;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isValid: formIsValid }) => (
        <Container className={className} onSubmit={handleSubmit}>
          <TextField
            placeholder="Nota..."
            name="description"
            label="Nota *"
            isMultiline
            rows={5}
            id={`${formId}_description`}
          />
          <Switch
            id={`${formId}_showNotification`}
            label="Activar notificación"
            name="showNotification"
          />
          <Buttons>
            <Button
              type="submit"
              variant="primary"
              disabled={!formIsValid}
              isLoading={isLoading}
            >
              {isEdition ? 'Actualizar' : 'Crear'}
            </Button>
            <Button variant="secondary" onClick={onCancel}>
              Cerrar
            </Button>
            {isEdition && (
              <Button
                type="button"
                variant="danger"
                onClick={onRemove}
                isLoading={isLoading}
              >
                Eliminar
              </Button>
            )}
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(Form);
