import type { FC } from 'react';
import Price from 'components/Price';
import useConnect from './connect';
import {
  Buttons,
  Container,
  Header,
  IconCircle,
  OrdersIcon,
  ProductName,
  ProductRow,
  Products,
  ProductsContainer,
  Title,
  TotalPrice,
  TotalProducts,
  ProductTotalPrice,
  CloseButton,
  HeaderContainer,
  ProductPrice,
  OriginalPrice,
  TotalProductsContent,
  ProductsDiscount,
  ProductReference,
  ProductNameContainer,
  ProductDiscount,
} from './styles';

const CashierProducts: FC = () => {
  const {
    activateProductReference,
    isOpen,
    handleClose,
    products,
    totalPurchase,
    totalDiscounts,
  } = useConnect();
  const hasDiscounts = !!totalDiscounts;
  const originalPrice = Number(totalPurchase);
  const finalPrice = hasDiscounts
    ? originalPrice - totalDiscounts
    : originalPrice;

  return (
    <Container open={isOpen} onClose={handleClose}>
      <HeaderContainer>
        <Header>
          <IconCircle>
            <OrdersIcon />
          </IconCircle>
          <Title>Artículos</Title>
        </Header>
      </HeaderContainer>

      <ProductsContainer>
        <ProductRow>
          <TotalProductsContent>
            <TotalProducts>{products?.length} Productos</TotalProducts>
            {hasDiscounts && (
              <ProductsDiscount>
                -<Price>{totalDiscounts}</Price> en descuentos
              </ProductsDiscount>
            )}
          </TotalProductsContent>
          <ProductPrice>
            {hasDiscounts && (
              <OriginalPrice>
                <Price>{originalPrice}</Price>
              </OriginalPrice>
            )}
            <TotalPrice>
              <Price>{finalPrice}</Price>
            </TotalPrice>
          </ProductPrice>
        </ProductRow>
        <Products>
          {products?.map((product) => {
            const hasDiscount = product.totalDiscounts > 0;
            return (
              <ProductRow key={product.name}>
                <ProductNameContainer>
                  <ProductName>
                    {product.name}
                    {activateProductReference && product.referenceCode && (
                      <ProductReference>
                        #{product.referenceCode}
                      </ProductReference>
                    )}
                    ({product.consumedQuantity})
                  </ProductName>{' '}
                  {hasDiscount && (
                    <ProductDiscount>
                      -<Price>{product.totalDiscounts}</Price> en descuentos
                    </ProductDiscount>
                  )}
                </ProductNameContainer>

                <ProductTotalPrice>
                  {hasDiscount && (
                    <OriginalPrice>
                      <Price>
                        {product.totalDiscounts + product.totalPurchases}
                      </Price>
                    </OriginalPrice>
                  )}
                  <Price>{product.totalPurchases}</Price>
                </ProductTotalPrice>
              </ProductRow>
            );
          })}
        </Products>
      </ProductsContainer>
      <Buttons>
        <CloseButton onClick={handleClose}>Cerrar</CloseButton>
      </Buttons>
    </Container>
  );
};

export default CashierProducts;
