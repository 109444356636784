import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PRODUCT } from 'apollo/queries/products';
import { GetProduct, GetProductVariables } from 'apollo/generated/GetProduct';
import { normalizeProduct } from 'model/Product';

const useProduct = ({
  id,
  fetchPolicy = 'network-only',
}: {
  id?: string;
  fetchPolicy?: QueryHookOptions<GetProductVariables>['fetchPolicy'];
}) => {
  const { data, loading, refetch } = useQuery<GetProduct, GetProductVariables>(
    GET_PRODUCT,
    {
      fetchPolicy,
      skip: !id,
      variables: { id: id! },
    },
  );

  const product = useMemo(
    () => (data?.product ? normalizeProduct(data.product) : undefined),
    [data],
  );

  return {
    loading,
    product,
    refetch,
  };
};

export default useProduct;
