import { FC, memo } from 'react';
import { getDiscountTypeName } from 'model/Discount';
import {
  Container,
  Name,
  Price,
  Type,
  EditIcon,
  Icons,
  TrashIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  employeePermissions,
  name,
  onEdit,
  onRemove,
  percentage,
  price,
  type,
}) => {
  return (
    <Container className={className}>
      <Name>{name}</Name>
      <Type>{getDiscountTypeName(type)}</Type>
      <Price>{price ? `${price} cr` : `${percentage} %`}</Price>
      <Icons>
        {employeePermissions.allowEditDiscounts && (
          <EditIcon onClick={onEdit} />
        )}
        {employeePermissions.allowRemoveDiscounts && (
          <TrashIcon onClick={onRemove} />
        )}
      </Icons>
    </Container>
  );
};

export default memo(Row);
