import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 1.125rem 1.5rem 1rem 1rem;
`;

export const LabelContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 2;
`;

export const LabelTitle = styled(Label)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 1rem;
  width: 100%;
`;

export const ApproveButton = styled(Button)`
  &&& {
    flex: 1 1 auto;
  }
`;

export const RejectButton = styled(Button).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    flex: 0 0 auto;
    margin-right: 1rem;
  }
`;
