import { gql } from '@apollo/client';
import { FILE_FRAGMENT } from '../fragments/File';

export const GET_FILE_SIGNED_URL = gql`
  mutation GetFileSignedUrl($data: GetFileSignedUrlDto!) {
    getFileSignedUrl(data: $data) {
      file {
        ...FileData
      }
      uploadUrl
    }
  }
  ${FILE_FRAGMENT}
`;

export const GET_FILE_SIGNED_URL_TEMPORARY = gql`
  mutation GetFileSignedUrlTemporaryPartner(
    $code: String!
    $data: GetFileSignedUrlDto!
  ) {
    getFileSignedUrlTemporaryPartner(code: $code, data: $data) {
      file {
        ...FileData
      }
      uploadUrl
    }
  }
  ${FILE_FRAGMENT}
`;

export const CONFIRM_FILE_UPLOAD = gql`
  mutation ConfirmFileUpload($data: ConfirmFileUploadDto!) {
    confirmFileUpload(data: $data) {
      id
      url
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($data: UploadFileDto!) {
    uploadFile(data: $data) {
      ...FileData
    }
  }
  ${FILE_FRAGMENT}
`;

export const UPLOAD_FILE_FROM_S3_TO_DRIVE = gql`
  mutation UploadFileFromS3ToDrive($fileId: String!) {
    uploadFileFromS3ToDrive(fileId: $fileId) {
      ...FileData
    }
  }
  ${FILE_FRAGMENT}
`;

export const REMOVE_FILE = gql`
  mutation RemoveFile($fileId: String!) {
    removeFile(fileId: $fileId)
  }
`;
