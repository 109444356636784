import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import useProviders from 'apollo/hooks/provider/useProviders';
import useProviderActions from 'apollo/hooks/provider/useProviderActions';
import { permissionsStateVar } from 'apollo/reactive/permissionsState';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { Provider } from 'model/Provider';

const useConnect = () => {
  const {
    providers,
    loading: providersLoading,
    refetch: refetchProviders,
  } = useProviders();
  const { deleteProvider, loading: providerActionsLoading } =
    useProviderActions();
  const { openDialog, openNewProvider, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const handleRemoveProvider = useCallback(
    async (provider: Provider) => {
      try {
        await deleteProvider(provider.id);
        await refetchProviders();
        close();
        enqueueSnackbar('El proveedor se ha eliminado correctamente.', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('provider', e.message, 'eliminar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [close, deleteProvider, enqueueSnackbar, refetchProviders],
  );

  const handleEditProvider = useCallback(
    async (provider: Provider) => {
      openNewProvider({ provider });
    },
    [openNewProvider],
  );

  const handleOpenRemoveProviderDialog = useCallback(
    async (provider: Provider) => {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar un proveedor y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: () => handleRemoveProvider(provider),
        title: 'Eliminar descuento',
        variant: 'danger',
      });
    },
    [handleRemoveProvider, openDialog],
  );

  const handleOpenProviderExpenses = useCallback(
    async (expenses: Provider['expenses']) => {
      console.log(expenses);
    },
    [],
  );

  return {
    employeePermissions,
    handleEditProvider,
    handleOpenNewProviderModal: openNewProvider,
    handleOpenRemoveProviderDialog,
    handleOpenProviderExpenses,
    isLoading: providersLoading || providerActionsLoading,
    providers,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
