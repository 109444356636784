import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import usePartnerFormData from 'apollo/hooks/organization/usePartnerFormData';
import usePartnerFormActions from 'apollo/hooks/organization/usePartnerFormActions';
import useFileSignedUrlTemporaryActions from 'apollo/hooks/upload/useFileSignedUrlTemporaryActions';
import useImageSignedUrlTemporaryActions from 'apollo/hooks/upload/useImageSignedUrlTemporaryActions';
import { ImageCategory, PartnerUsage, UserGender } from 'apollo/graphql.types';
import { formatErrors } from 'utils/errors/formatErrors';
import type { PartnerFormFieldsFromQR } from 'model/Partner';

const useConnect = () => {
  const navigate = useNavigate();
  const { formCode } = useParams<{ formCode: string }>();
  const { partnerFormData, getPartnerFormDataLoading } =
    usePartnerFormData(formCode);
  const { createPartnerRequest, partnerFormConfigLoading } =
    usePartnerFormActions();
  const { uploadFileAction } = useFileSignedUrlTemporaryActions();
  const { uploadImage } = useImageSignedUrlTemporaryActions();
  const { enqueueSnackbar } = useSnackbar();
  const [stepNum, setStepNum] = useState(1);
  const [selfieFile, setSelfieFile] = useState<File | null>(null);
  const [document1File, setDocument1File] = useState<File | null>(null);
  const [document2File, setDocument2File] = useState<File | null>(null);
  const [finishStatus, setFinishStatus] = useState<{
    statusOk: boolean;
  } | null>(null);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      (!getPartnerFormDataLoading && !partnerFormData) ||
      (partnerFormData && !partnerFormData?.isActive)
    ) {
      navigate('/not-found');
    }
  }, [navigate, partnerFormData, getPartnerFormDataLoading]);

  const initialValues = useMemo(
    () => ({
      address: '',
      document: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      usage: PartnerUsage.Playful,
      birthDate: undefined,
      gender: UserGender.Other,
    }),
    [],
  );

  const handleChangeStep = useCallback(
    (type: 'next' | 'prev') => {
      if (type === 'next' && stepNum < 2) {
        setStepNum(stepNum + 1);
      } else if (type === 'prev' && stepNum > 1) {
        setStepNum(stepNum - 1);
      }
    },
    [stepNum],
  );

  const handleFinish = useCallback(
    async (data: PartnerFormFieldsFromQR) => {
      try {
        setIsLoading(true);
        if (formCode) {
          const partnerId = await createPartnerRequest(formCode, data);
          if (partnerId) {
            if (document1File) {
              await uploadFileAction({
                file: document1File,
                name: 'dniDelantero',
                code: formCode,
                setProgress,
                partnerId,
              });
            }
            if (document2File) {
              await uploadFileAction({
                file: document2File,
                name: 'dniTrasero',
                code: formCode,
                setProgress,
                partnerId,
              });
            }
            if (selfieFile) {
              await uploadImage({
                file: selfieFile,
                name: 'avatar',
                category: ImageCategory.Avatar,
                code: formCode,
                setProgress,
                partnerId,
              });
            }
          }
          setFinishStatus({ statusOk: true });
        } else {
          throw new Error('Form code not found');
        }
      } catch (e) {
        setFinishStatus({ statusOk: false });
        enqueueSnackbar(formatErrors('temporaryPartner', e.message, 'crear'), {
          variant: 'error',
        });
      }
      setIsLoading(false);
    },
    [
      selfieFile,
      document1File,
      document2File,
      formCode,
      createPartnerRequest,
      uploadFileAction,
      uploadImage,
      enqueueSnackbar,
    ],
  );

  const handleSetDocuments = useCallback(
    (file: File | null, type: 'selfie' | 'document1' | 'document2') => {
      if (type === 'selfie') {
        setSelfieFile(file);
      } else if (type === 'document1') {
        setDocument1File(file);
      } else if (type === 'document2') {
        setDocument2File(file);
      }
    },
    [],
  );

  return {
    formCode,
    stepNum,
    handleChangeStep,
    handleFinish,
    initialValues,
    handleSetDocuments,
    selfieFile,
    document1File,
    document2File,
    finishStatus,
    progress,
    isLoading:
      getPartnerFormDataLoading || partnerFormConfigLoading || isLoading,
  };
};

export default useConnect;
