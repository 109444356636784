import { gql } from '@apollo/client';

export const SEND_TICKET = gql`
  mutation SendRequestTicket($msg: String!) {
    sendRequestTicket(msg: $msg)
  }
`;

export const SEND_NEW_ACCOUNT_REQUEST = gql`
  mutation NewAccountRequest(
    $organizationName: String!
    $description: String!
    $email: String!
    $name: String!
    $phone: Int!
  ) {
    newAccountRequest(
      organizationName: $organizationName
      description: $description
      email: $email
      name: $name
      phone: $phone
    )
  }
`;
