import { gql } from '@apollo/client';
import { USER_FRAGMENT } from 'apollo/fragments/User';

export const GET_ME = gql`
  query Me {
    me {
      ...UserData
    }
  }

  ${USER_FRAGMENT}
`;
