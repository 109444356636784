import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PRODUCT_STATISTICS } from 'apollo/queries/products';
import {
  GetProductsStatisticsQuery,
  GetProductsStatisticsQueryVariables,
} from 'apollo/graphql.types';
import { normalizeProductStatistics } from 'model/Product';

const useProductStatistics = ({
  id,
  filters,
}: {
  id?: string;
  filters?: { from?: Date; to?: Date };
}) => {
  const { loading, data, refetch } = useQuery<
    GetProductsStatisticsQuery,
    GetProductsStatisticsQueryVariables
  >(GET_PRODUCT_STATISTICS, {
    skip: !id,
    variables: {
      id: id!,
      filters: filters
        ? {
            fromDate: filters.from || null,
            toDate: filters.to || null,
          }
        : null,
    },
    fetchPolicy: 'cache-and-network',
  });

  const productStatistics = useMemo(
    () => normalizeProductStatistics(data?.productsStatistics),
    [data],
  );

  return {
    productStatistics,
    loading,
    refetch,
  };
};

export default useProductStatistics;
