import { FC, memo } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './constants';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  Row,
  SaveMessage,
  SaveMessageNumber,
  SaveMessageText,
  TextField,
} from './styles';
import type { Props } from './types';

const Form: FC<Props> = ({
  actionType,
  adjustState,
  className,
  id = 'new',
  initialValues,
  isFirstSave,
  isLoading,
  onCancel,
  onSubmit,
  onChangeNewQuantity,
}) => {
  const formId = `adjustProduct_${id}`;
  const isSetStock = actionType === 'set';

  let quantityLabel = 'Nuevo Stock';
  if (actionType === 'add') {
    quantityLabel = 'Añadir stock';
  } else if (actionType === 'remove') {
    quantityLabel = 'Retirar stock';
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid, values }) => (
        <Container className={className}>
          <Row>
            <TextField
              readOnly
              type="number"
              name="total"
              label="Stock Actual"
              value={Number(
                (
                  Number(values?.baseWeight || 0) +
                  Number(values?.quantity || 0)
                ).toFixed(2),
              )}
            />
            <TextField
              type="number"
              name="newQuantity"
              label={quantityLabel}
              onLocalChange={onChangeNewQuantity}
              id={`${formId}_newQuantity`}
            />
          </Row>
          {isSetStock && initialValues?.baseWeight ? (
            <>
              <Row>
                <TextField
                  readOnly
                  type="number"
                  name="quantity"
                  label="Cantidad actual"
                  id={`${formId}_quantity`}
                />
              </Row>

              <Row>
                <TextField
                  readOnly
                  type="number"
                  name="baseWeight"
                  label="Cantidad base"
                  id={`${formId}_baseWeight`}
                />
              </Row>
            </>
          ) : null}

          <TextField
            type="text"
            name="note"
            label="Nota"
            id={`${formId}_note`}
          />
          {adjustState?.show && (
            <SaveMessage>
              <SaveMessageText>
                La diferencia del ajuste es{' '}
                {adjustState.isNegative ? 'negativo' : 'positivo'}.{' '}
                <SaveMessageNumber $isNegative={adjustState.isNegative}>
                  {adjustState.remainingQuantity}
                </SaveMessageNumber>
              </SaveMessageText>
            </SaveMessage>
          )}
          <Buttons>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || !isFormValid || values?.newQuantity === 0}
            >
              {isFirstSave && actionType !== 'add' ? 'Validar' : 'Guardar'}
            </CreateButton>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(Form);
