import type { FC } from 'react';
import {
  ActionButton,
  Buttons,
  CancelButton,
  Container,
  IconCircle,
  Icon,
  Title,
} from './styles';
import useConnect from './connect';

const AdjustProductOptionsDialogModal: FC = () => {
  const {
    handleAcceptSet,
    handleAcceptRemove,
    handleAcceptAdd,
    isOpen,
    handleClose,
  } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>Tipo de ajuste</Title>
      <Buttons>
        <ActionButton variant="light" onClick={handleAcceptRemove}>
          Retirar
        </ActionButton>
        <ActionButton variant="light" onClick={handleAcceptAdd}>
          Añadir
        </ActionButton>
        <ActionButton variant="light" onClick={handleAcceptSet}>
          Definir
        </ActionButton>
      </Buttons>
      <CancelButton onClick={handleClose}>Cancelar</CancelButton>
    </Container>
  );
};

export default AdjustProductOptionsDialogModal;
