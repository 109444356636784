import { gql } from '@apollo/client';
import { CASHIER_FRAGMENT } from 'apollo/fragments/Cashier';

export const OPEN_CASHIER = gql`
  mutation OpenCashier {
    openCashier {
      ...CashierData
    }
  }
  ${CASHIER_FRAGMENT}
`;

export const CLOSE_CASHIER = gql`
  mutation CloseCashier($id: ID!, $data: CloseCashierInput!) {
    closeCashier(id: $id, data: $data) {
      ...CashierData
    }
  }
  ${CASHIER_FRAGMENT}
`;
