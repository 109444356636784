import { FC, memo } from 'react';

import type { Props } from './types';

const ChartSelected: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      stroke="#0A2737"
      strokeWidth="3"
      d="M5 16l6-6 3 3 5-5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default memo(ChartSelected);
