import type { FC } from 'react';
import Placeholder from './Placeholder';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  Container,
  EmptyState,
  Title,
  Space,
  Header,
  ProvidersContainer,
  ProviderName,
  ProvidersList,
  Provider,
} from './styles';

const SelectProvider: FC = () => {
  const {
    handleSelect,
    handleSubmit,
    isLoading,
    isOpen,
    isSelected,
    onClose,
    providers,
    selectedProvider,
  } = useConnect();

  const hasResults = !isLoading && providers?.length > 0;

  return (
    <Container disableBackdropClick open={isOpen} onClose={onClose}>
      <Header>
        <Title>Seleccionar Proveedor</Title>
      </Header>

      {isLoading && <Placeholder nRows={7} />}
      {hasResults ? (
        <ProvidersContainer>
          <ProvidersList>
            <Space />
            <Space />
            {providers &&
              providers.map((provider) => (
                <Provider
                  $isSelected={isSelected(provider)}
                  key={provider.id}
                  onClick={() => handleSelect(provider)}
                >
                  <ProviderName> {provider.name}</ProviderName>
                </Provider>
              ))}
            <Space />
            <Space />
          </ProvidersList>
        </ProvidersContainer>
      ) : (
        <EmptyState title="No hay proveedores" iconName="cart">
          Aún no has creado ningùn proveedor, crea al menos una desde la
          configuración de la organización para mostrar la información
        </EmptyState>
      )}
      <Buttons>
        <CancelButton onClick={onClose}>Cerrar</CancelButton>
        <AcceptButton onClick={handleSubmit} disabled={!selectedProvider}>
          Seleccionar
        </AcceptButton>
      </Buttons>
    </Container>
  );
};

export default SelectProvider;
