import { FC, memo } from 'react';
import {
  Adjust,
  Container,
  CreationDate,
  LastUpdateDate,
  Name,
  RealCredits,
  TotalCredits,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Name>Abierta Por</Name>
      <CreationDate>Fecha inicio</CreationDate>
      <Name>Cerrada Por</Name>
      <LastUpdateDate>Fecha fin</LastUpdateDate>
      <TotalCredits>Ingresado</TotalCredits>
      <RealCredits>Disponible</RealCredits>
      <Adjust>Ajuste</Adjust>
    </Container>
  );
};

export default memo(Row);
