import { useCallback, useMemo } from 'react';
import format from 'date-fns/format';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { DocumentCustomDataInputType } from 'apollo/graphql.types';

const useConnect = () => {
  const { close, setDocumentDataPayload, type } = useModal();
  const { customData, onSubmit } = setDocumentDataPayload || {};

  const initialValues = useMemo(() => {
    const cleanedCustomData = customData || [];

    const initialData: Record<string, unknown> = {};
    cleanedCustomData.forEach((item) => {
      const key = item.key.toLowerCase().trim();
      let value: null | Date | boolean = null;
      if (item.inputType === DocumentCustomDataInputType.Date) {
        value = new Date();
      }

      if (item.inputType === DocumentCustomDataInputType.Boolean) {
        value = false;
      }

      initialData[key] = value;
    });

    return initialData;
  }, [customData]);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback(
    async (values: Record<string, unknown>) => {
      if (onSubmit) {
        const mappedValues: Record<string, unknown> = {};
        Object.keys(values).forEach((key) => {
          const mappedKey = `{${key.toLowerCase().trim().replace(/ /g, '_')}}`;
          let value = values[key];
          if (
            Object.prototype.toString.call(value) === '[object Date]' &&
            !isNaN((value as Date).getTime())
          ) {
            value = format(new Date(value as string), 'dd/MM/yyyy');
          }

          if (typeof value === 'boolean') {
            value = (value && 'Si') || 'No';
          }
          mappedValues[mappedKey] = value || '';
        });
        onSubmit(mappedValues as Record<string, string | number>);
      }
    },
    [onSubmit],
  );

  return {
    customData,
    handleClose,
    handleSubmit,
    initialValues,
    isOpen: type === ModalType.SET_DOCUMENT_CUSTOM_DATA,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
