import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PRODUCTS } from 'apollo/queries';
import { normalizeProduct } from 'model/Product';
import notEmpty from 'utils/notEmpty';
import type {
  GetProducts,
  GetProductsVariables,
} from 'apollo/generated/GetProducts';

const useProducts = ({
  filters,
  fetchPolicy = 'cache-and-network',
}: {
  filters?: GetProductsVariables['filters'];
  fetchPolicy?: QueryHookOptions<GetProductsVariables>['fetchPolicy'];
} = {}) => {
  const { loading, data, refetch } = useQuery<
    GetProducts,
    GetProductsVariables
  >(GET_PRODUCTS, {
    fetchPolicy,
    variables: { filters },
  });

  const { products } = useMemo(
    () => ({
      products: data?.products
        ? data.products.filter(notEmpty).map(normalizeProduct)
        : [],
    }),
    [data],
  );

  return {
    loading,
    products,
    refetch,
  };
};

export default useProducts;
