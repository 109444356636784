import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { SEND_NEW_ACCOUNT_REQUEST, SEND_TICKET } from 'apollo/mutations/user';
import type {
  SendRequestTicketMutation,
  SendRequestTicketMutationVariables,
  NewAccountRequestMutation,
  NewAccountRequestMutationVariables,
} from 'apollo/graphql.types';

const useUserActions = () => {
  const [sendTicketMutation, { loading: sendTicketIsLoading }] = useMutation<
    SendRequestTicketMutation,
    SendRequestTicketMutationVariables
  >(SEND_TICKET);
  const [
    sendNewAccountRequestMutation,
    { loading: newAccountRequestIsLoading },
  ] = useMutation<
    NewAccountRequestMutation,
    NewAccountRequestMutationVariables
  >(SEND_NEW_ACCOUNT_REQUEST);

  const sendTicket = useCallback(
    async (msg: string) => {
      await sendTicketMutation({
        variables: {
          msg,
        },
      });
    },
    [sendTicketMutation],
  );

  const sendNewAccountRequest = useCallback(
    async (data: {
      description: string;
      email: string;
      name: string;
      phone: number;
    }) => {
      await sendNewAccountRequestMutation({
        variables: {
          ...data,
          organizationName: 'web',
        },
      });
    },
    [sendNewAccountRequestMutation],
  );

  return {
    sendTicket,
    sendNewAccountRequest,
    userActionsLoading: sendTicketIsLoading || newAccountRequestIsLoading,
  };
};

export default useUserActions;
