import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CREATE_ORGANIZATION_MENU,
  REMOVE_ORGANIZATION_MENU,
  UPDATE_ORGANIZATION_MENU,
} from 'apollo/mutations/organizations';
import type { MenuConfigFormFields } from 'model/Organization';
import {
  CreateOrganizationMenuMutation,
  CreateOrganizationMenuMutationVariables,
  UpdateOrganizationMenuMutation,
  UpdateOrganizationMenuMutationVariables,
  RemoveOrganizationMenuMutation,
  RemoveOrganizationMenuMutationVariables,
  MenuScheduleDto,
} from 'apollo/graphql.types';

const useMenuConfigActions = () => {
  const [createMenuConfigMutation, { loading: isLoadingCreateMenuConfig }] =
    useMutation<
      CreateOrganizationMenuMutation,
      CreateOrganizationMenuMutationVariables
    >(CREATE_ORGANIZATION_MENU);

  const [updateMenuConfigMutation, { loading: isLoadingUpdateMenuConfig }] =
    useMutation<
      UpdateOrganizationMenuMutation,
      UpdateOrganizationMenuMutationVariables
    >(UPDATE_ORGANIZATION_MENU);

  const [removeMenuConfigMutation, { loading: isLoadingRemoveMenuConfig }] =
    useMutation<
      RemoveOrganizationMenuMutation,
      RemoveOrganizationMenuMutationVariables
    >(REMOVE_ORGANIZATION_MENU);

  const normalizeValues = (data: MenuConfigFormFields) => ({
    name: data?.name,
    type: data?.type,
    showLogo: data?.showLogo,
    useLogoToBackground: data?.useLogoToBackground,
    showProductPrice: data?.showProductPrice,
    showProductDescription: data?.showProductDescription,
    showProductDiscount: data?.showProductDiscount,
    showPriceSimbol: data?.showPriceSimbol,
    defaultImageUrl: data?.defaultImageUrl,
    backgroundColor: data?.backgroundColor,
    imageBorderColor: data?.imageBorderColor,
    productTitleColor: data?.productTitleColor,
    productDescriptionColor: data?.productDescriptionColor,
    productPriceColor: data?.productPriceColor,
    categoriesColor: data?.categoriesColor,
    menuSchedules: (data?.menuSchedules as unknown as MenuScheduleDto[]) || [],
    // @ts-ignore
    categoriesNames: [...new Set(data?.categoriesNames || [])],
    menuCode: data.menuCode.split('/menu/')[1],
    menuActive: data?.menuActive,
  });

  const createMenuConfig = useCallback(
    async (data: MenuConfigFormFields) => {
      const dto = normalizeValues(data);
      await createMenuConfigMutation({
        variables: {
          data: dto,
        },
      });
    },
    [createMenuConfigMutation],
  );

  const updateMenuConfig = useCallback(
    async (id: string, data: MenuConfigFormFields) => {
      const dto = normalizeValues(data);
      await updateMenuConfigMutation({
        variables: {
          id,
          data: dto,
        },
      });
    },
    [updateMenuConfigMutation],
  );

  const removeMenuConfig = useCallback(
    async (id: string) => {
      await removeMenuConfigMutation({
        variables: {
          id,
        },
      });
    },
    [removeMenuConfigMutation],
  );

  return {
    updateMenuConfig,
    createMenuConfig,
    removeMenuConfig,
    menuActionsLoading:
      isLoadingCreateMenuConfig ||
      isLoadingUpdateMenuConfig ||
      isLoadingRemoveMenuConfig,
  };
};

export default useMenuConfigActions;
