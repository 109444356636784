import { FC, memo } from 'react';
import useConnect from './connect';
import { Avatar, Container, Name, IconButton, LogoutIcon } from './styles';
import type { Props } from './types';

const UserLogout: FC<Props> = ({ className, onLogout }) => {
  const { avatarUrl, email, fullName } = useConnect();

  return (
    <Container className={className}>
      <Avatar email={email} fullName={fullName} src={avatarUrl} />
      <Name>{fullName}</Name>
      <IconButton onClick={onLogout}>
        <LogoutIcon />
      </IconButton>
    </Container>
  );
};

export default memo(UserLogout);
