import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_EMPLOYEE_TRANSACTIONS } from 'apollo/queries/employees';
import type {
  GetEmployeeTransactionsQuery,
  GetEmployeeTransactionsQueryVariables,
} from 'apollo/graphql.types';
import { normalizeEmployee } from 'model/Employee';
import { normalizeEmployeeTransaction } from 'model/Transaction';

const useEmployeeTransactions = ({
  id,
  filters,
}: {
  id?: string;
  filters?: { from?: Date; to?: Date };
}) => {
  const { loading, data, refetch } = useQuery<
    GetEmployeeTransactionsQuery,
    GetEmployeeTransactionsQueryVariables
  >(GET_EMPLOYEE_TRANSACTIONS, {
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id!,
      filters: filters
        ? {
            fromDate: filters.from || null,
            toDate: filters.to || null,
          }
        : null,
    },
  });

  const { employee, transactions } = useMemo(
    () => ({
      employee: data?.employeeTransactions.employee
        ? normalizeEmployee(data.employeeTransactions.employee)
        : null,
      transactions: data?.employeeTransactions?.transactions
        ? // @ts-ignore TODO pending type migrations
          normalizeEmployeeTransaction(data.employeeTransactions.transactions)
        : [],
    }),
    [data],
  );

  return {
    loading,
    employee,
    refetch,
    transactions,
  };
};

export default useEmployeeTransactions;
