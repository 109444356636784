import { memo, FC } from 'react';
import Row from './Row';
import TableHead from './TableHead';
import {
  Container,
  EmptyState,
  Main,
  ListContent,
  Content,
  Button,
} from './styles';
import type { Props } from './types';
import useConnect from './connect';

const PartnerNotes: FC<Props> = ({ notes, partnerId }) => {
  const { handleOpenNoteDetailsModal, handleOpenNewNoteModal } = useConnect({
    partnerId,
  });
  const hasResults = notes?.length > 0;

  return (
    <Container>
      <Main>
        {!hasResults && (
          <EmptyState
            title="No hay notas"
            iconName="note"
            buttonLabel="Crear nota"
            onClickButton={handleOpenNewNoteModal}
          >
            No se ha creado ninguna nota
          </EmptyState>
        )}

        {hasResults && (
          <Content>
            <Button variant="light" onClick={handleOpenNewNoteModal}>
              Crear Nota
            </Button>
            <ListContent>
              <TableHead />
              {notes.map((note) => (
                <Row
                  key={note.id}
                  openNoteDetails={handleOpenNoteDetailsModal}
                  note={note}
                />
              ))}
            </ListContent>
          </Content>
        )}
      </Main>
    </Container>
  );
};

export default memo(PartnerNotes);
