import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { Props } from './types';
import { EmployeeSections } from '../constants';

type Section = {
  key: string;
  value: string;
};

const useLogic = ({ onSelectedSection, employeeId }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);

  const EmployeeSectionsMap = useMemo(
    () => [
      {
        key: EmployeeSections.PROFILE,
        value: 'Perfil',
        isAllow: true,
      },
      {
        key: EmployeeSections.TRANSACTIONS,
        value: 'Transacciones',
        isAllow: true,
      },
      {
        key: EmployeeSections.SCHEDULES,
        value: 'Horario de trabajo',
        isAllow: true,
      },
      {
        key: EmployeeSections.LOGINS,
        value: 'Registros',
        isAllow: true,
      },
    ],
    [],
  );

  useEffect(() => {
    const queryStringSection = searchParams.get('section');
    const sectionData = EmployeeSectionsMap.find(
      (section) => section.key === queryStringSection,
    );
    if (sectionData) {
      setSelectedSection(sectionData);
    }
  }, [EmployeeSectionsMap, onSelectedSection, searchParams]);

  useEffect(() => {
    if (selectedSection) {
      onSelectedSection(selectedSection.key);
    }
  }, [onSelectedSection, selectedSection]);

  const handleToggleSection = useCallback(
    (section: Section) => {
      setSelectedSection(section);
      if (employeeId) {
        navigate(`/employees/${employeeId}?section=${section.key}`);
      }
    },
    [employeeId, navigate],
  );

  return {
    handleToggleSection,
    selectedSection,
    sections: EmployeeSectionsMap,
  };
};

export default useLogic;
