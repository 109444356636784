import { gql } from '@apollo/client';
import { ORDER_FRAGMENT } from './Order';

export const PURCHASE_FRAGMENT = gql`
  fragment PurchaseData on Purchase {
    id
    canceled
    canceledAt
    createdAt
    deletedAt
    total
    totalBenefit
    totalWithoutDiscounts
    totalWithoutTaxes
    updatedAt
    partner {
      ... on Partner {
        id
        memberNum
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    employee {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    canceledBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    orders {
      ...OrderData
    }
    mainDiscount {
      ... on Discount {
        name
        percentage
        price
      }
    }
  }
  ${ORDER_FRAGMENT}
`;
