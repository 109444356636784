import styled, { css } from 'styled-components';

import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

export const Content = styled.div`
  cursor: pointer;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  height: 100%;
  width: 100%;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;
`;

export const Number = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.number};
  ${TextEllipsis};
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 1 ${TABLE_METRICS.name};
  ${TextEllipsis};
`;

export const IsDeletedFlag = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.medium};
`;
