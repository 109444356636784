import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultLogo from 'components/Logo';
import { SectionHeader } from 'components/Layout';

export const Container = styled.div`
  padding: 1rem 2rem;

  ${from.tabletPortrait} {
    padding: 1rem 2rem;
  }
`;

export const Header = styled(SectionHeader)`
  align-items: center;
  justify-content: space-between;
  ${from.tabletLandscape`
    margin-bottom: 5rem;
  `}
`;

export const Logo = styled(DefaultLogo)`
  width: 10rem;
`;

export const Content = styled.div`
  margin: 3rem 0;
  padding: 0 9rem;
`;

export const Title = styled.h1`
  text-align: center;
`;

export const Subtitle = styled.h6`
  text-align: center;
  font-weight: normal;
  margin-bottom: 4rem;
`;

export const Text = styled.p`
  margin-top: 1rem;
  text-align: justify;
  line-height: 1.7;
  font-weight: 500;
`;

export const Options = styled.ul`
  margin-top: 1rem;
  text-align: justify;
  line-height: 1.7;
  font-weight: 500;
  padding-left: 1.5rem;
`;
export const Option = styled.li``;

export const TextTitle = styled.h2`
  margin-top: 1.7rem;
  font-weight: bold;
`;

export const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: bold;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue300} !important;
  text-decoration: none;
`;

export const PendingInfo = styled.mark``;
