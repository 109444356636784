import { FC, memo } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, IconCircle, Icon, Header } from './styles';

const PartnerNote: FC = () => {
  const {
    handleClose,
    handleRemoveNote,
    handleSubmit,
    initialValues,
    isLoading,
    isOpen,
  } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="note" size={24} />
        </IconCircle>
        <Title>{!initialValues?.id ? 'Nueva' : 'Editar'} Nota</Title>
      </Header>
      <Form
        initialValues={initialValues}
        isLoading={isLoading}
        onCancel={handleClose}
        onRemove={handleRemoveNote}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default memo(PartnerNote);
