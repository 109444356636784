import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import notEmpty from 'utils/notEmpty';
import { permissionsStateVar, subscriptionStateVar } from 'apollo/reactive';
import { GET_EXPENSES_TAGS } from 'apollo/queries/expenses';
import type { GetExpensesTagsQuery } from 'apollo/graphql.types';

const useExpensesTags = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const subscription = useReactiveVar(subscriptionStateVar);

  const { loading: getExpensesTagsLoading, data } =
    useQuery<GetExpensesTagsQuery>(GET_EXPENSES_TAGS, {
      skip:
        !subscription.allowedExpenses || !employeePermissions.allowShowExpenses,
    });

  const expensesTags = useMemo(
    () => (data?.expensesTags ? data.expensesTags.filter(notEmpty) : []),
    [data],
  );

  return {
    getExpensesTagsLoading,
    expensesTags,
  };
};

export default useExpensesTags;
