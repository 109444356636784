import styled from 'styled-components';
import DefaultModal, {
  Content as ModalContent,
  Title as ModalTitle,
} from 'components/Modal';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultEmptyState from 'components/EmptyState';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultUploadFileIcon from 'components/Icon/Icons/UploadFile';

export const Container = styled(DefaultModal)`
  ${ModalContent} {
    display: flex;
    flex-direction: column;
    ${from.tabletPortrait`
      min-height: 30rem;
      max-width: 55rem;
    `}
  }
`;

export const UploadFileIcon = styled(DefaultUploadFileIcon).attrs(() => ({
  size: 20,
}))``;

export const Header = styled.div`
  flex: 0 1 auto;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  padding: 0 0 2.5rem 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
`;

export const IconCircle = styled(DefaultIconCircle)`
  background-color: ${({ theme }) => theme.colors.blue300}0A;
  margin-right: 1rem;
  height: 2.5rem;
  width: 2.5rem;
`;

export const Content = styled.div`
  flex: 1 1 auto;
`;

export const DocumentsList = styled.div`
  max-height: 20rem;
  margin: 0.8rem 0;
  overflow-y: scroll;
`;

export const ButtonContent = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  margin-top: 1rem;
  width: 100%;
`;

export const Button = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;
