import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CreateProvider,
  CreateProviderVariables,
} from 'apollo/generated/CreateProvider';
import {
  UpdateProvider,
  UpdateProviderVariables,
} from 'apollo/generated/UpdateProvider';
import {
  RemoveProvider,
  RemoveProviderVariables,
} from 'apollo/generated/RemoveProvider';
import { ProviderFormFields } from 'model/Provider';
import {
  CREATE_PROVIDER,
  REMOVE_PROVIDER,
  UPDATE_PROVIDER,
} from 'apollo/mutations/providers';

const useProviderActions = () => {
  const [createProviderMutation, { loading: isLoadingCreateProvider }] =
    useMutation<CreateProvider, CreateProviderVariables>(CREATE_PROVIDER);
  const [updateProviderMutation, { loading: isLoadingUpdateProvider }] =
    useMutation<UpdateProvider, UpdateProviderVariables>(UPDATE_PROVIDER);
  const [removeProviderMutation, { loading: isLoadingRemoveProvider }] =
    useMutation<RemoveProvider, RemoveProviderVariables>(REMOVE_PROVIDER);

  const normalizeFormValues = useCallback(
    async (values: ProviderFormFields) => ({
      description: values.description,
      name: values.name,
    }),
    [],
  );

  const createProvider = useCallback(
    async (data: ProviderFormFields) => {
      const dto = await normalizeFormValues(data);
      await createProviderMutation({
        variables: { data: dto },
      });
    },
    [createProviderMutation, normalizeFormValues],
  );

  const updateProvider = useCallback(
    async (id: string, data: ProviderFormFields) => {
      const dto = await normalizeFormValues(data);
      await updateProviderMutation({
        variables: { id, data: dto },
      });
    },
    [updateProviderMutation, normalizeFormValues],
  );

  const deleteProvider = useCallback(
    async (id: string) => {
      await removeProviderMutation({
        variables: {
          id,
        },
      });
    },
    [removeProviderMutation],
  );

  return {
    createProvider,
    updateProvider,
    deleteProvider,
    loading:
      isLoadingCreateProvider ||
      isLoadingUpdateProvider ||
      isLoadingRemoveProvider,
  };
};

export default useProviderActions;
