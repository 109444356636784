import { FC, memo, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import { ProductsOrderByDirection, ProductsOrderType } from 'model/Product';
import {
  Container,
  Name,
  Icon,
  Image,
  CurrentQuantity,
  BaseWeight,
  AddStock,
  RetireStock,
  SetStock,
  StoreMovement,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, currentOrder, onChangeOrder }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  const handleChangeOrder = useCallback(
    (type: ProductsOrderType) => {
      let direction = ProductsOrderByDirection.ASC;
      if (
        currentOrder?.type === type &&
        currentOrder?.direction === ProductsOrderByDirection.ASC
      ) {
        direction = ProductsOrderByDirection.DESC;
      }

      onChangeOrder({ direction, type });
    },
    [currentOrder, onChangeOrder],
  );

  const handleGetIconName = useCallback(
    (type: ProductsOrderType) => {
      if (!currentOrder || currentOrder.type !== type) {
        return 'up';
      }

      return currentOrder.direction === ProductsOrderByDirection.ASC
        ? 'down'
        : 'up';
    },
    [currentOrder],
  );

  return (
    <Container className={className}>
      <Image>Imagen</Image>
      <Name onClick={() => handleChangeOrder(ProductsOrderType.NAME)}>
        <Icon
          size={fromTabletPortrait ? 18 : 12}
          name={handleGetIconName(ProductsOrderType.NAME)}
        />
        Nombre
      </Name>
      <CurrentQuantity
        onClick={() => handleChangeOrder(ProductsOrderType.QUANTITY)}
      >
        <Icon
          size={fromTabletPortrait ? 18 : 12}
          name={handleGetIconName(ProductsOrderType.QUANTITY)}
        />
        Cantidad {fromTabletPortrait && 'actual'}
      </CurrentQuantity>
      {fromTabletPortrait && <BaseWeight>Cantidad base</BaseWeight>}
      <AddStock>Añadir</AddStock>
      <RetireStock>Retirar</RetireStock>
      <SetStock>Definir</SetStock>
      <StoreMovement>Almacén</StoreMovement>
    </Container>
  );
};

export default memo(Row);
