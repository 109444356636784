import styled from 'styled-components';
import DefaultButton from '../Button';

export const Container = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row;
  margin-top: 1rem;
`;

export const Button = styled(DefaultButton)`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;
