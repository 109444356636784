import { FC, memo } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { SearchIcon, TextField } from './styles';
import { Props } from './types';
import useLogic from './logic';

const Search: FC<Props> = ({ className, debounce, onChange, value }) => {
  const { localOnChange, localValue } = useLogic({
    debounce,
    onChange,
    value,
  });

  const startAdornment = (
    <InputAdornment position="start">
      <SearchIcon />
    </InputAdornment>
  );

  return (
    <TextField
      className={className}
      hiddenLabel
      InputProps={{ startAdornment }}
      onChange={debounce ? localOnChange : onChange}
      placeholder="Buscar..."
      size="small"
      type="search"
      value={debounce ? localValue : value}
      variant="filled"
    />
  );
};

export default memo(Search);
