import { makeVar } from '@apollo/client';

export type ProductStateVarData = {
  baseWeight?: number;
  categoryIds?: string[];
  countToMaxConsume?: boolean;
  description?: string;
  extraQuantitiesIds?: string[];
  isActive?: boolean;
  showInMenu?: boolean;
  name?: string;
  referenceCode?: string;
  costs?: number;
  price?: number;
  quantity?: number;
  storeQuantity?: number;
  taxes?: number;
};

export const emptyProductState: ProductStateVarData = {
  baseWeight: 0,
  categoryIds: [],
  countToMaxConsume: false,
  description: '',
  referenceCode: '',
  extraQuantitiesIds: [],
  isActive: false,
  showInMenu: false,
  name: '',
  costs: 0,
  price: 0,
  quantity: 0,
  storeQuantity: 0,
  taxes: 0,
};

export const productStateVar = makeVar<ProductStateVarData>(emptyProductState);

export const resetProductStateVar = () => {
  productStateVar(emptyProductState);
};

export const setProductStateVar = (data: Partial<ProductStateVarData>) => {
  productStateVar({ ...emptyProductState, ...data });
};

export const updateProductStateVar = (data: Partial<ProductStateVarData>) => {
  productStateVar({ ...productStateVar(), ...data });
};
