import { FC, memo } from 'react';
import { Container, Date } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Date>Fecha</Date>
    </Container>
  );
};

export default memo(Row);
