import { FC, memo } from 'react';
import {
  ActiveText,
  CameraIcon,
  Container,
  ErrorText,
  IconBox,
  InvalidIcon,
  Text,
} from './styles';
import { Props } from './types';

const Dropbox: FC<Props> = ({
  className,
  isDragActive,
  isDragReject,
  errorMessage,
  getRootProps,
}) => (
  <Container
    className={className}
    isDragActive={isDragActive}
    isDragReject={isDragReject}
    {...getRootProps()}
  >
    <IconBox isDragReject={isDragReject}>
      {isDragReject ? <InvalidIcon /> : <CameraIcon />}
    </IconBox>
    {!isDragActive ? (
      <>
        <Text>Browse or drag an image</Text>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </>
    ) : (
      <ActiveText>
        {isDragReject ? 'Invalid file' : 'Drag files here...'}
      </ActiveText>
    )}
  </Container>
);

export default memo(Dropbox);
