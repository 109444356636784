import type { FC } from 'react';
import {
  Logo,
  Band,
  Blue,
  Container,
  Dimples,
  Eyes,
  Face,
  FaceContent,
  Mouth,
  Red,
  Title,
  White,
} from './styles';

const NotFound: FC = () => {
  return (
    <Container>
      <Logo />
      <FaceContent>
        <Face>
          <Band>
            <Red />
            <White />
            <Blue />
          </Band>
          <Eyes />
          <Dimples />
          <Mouth />
        </Face>
      </FaceContent>
      <Title>¡Oh! ¡Algo salió mal!</Title>
    </Container>
  );
};

export default NotFound;
