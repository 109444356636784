import { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import Price from 'components/Price';
import { ProductRankingState } from 'model/Metric';
import {
  Billing,
  Chevron,
  Container,
  DownIcon,
  EqualIcon,
  Flag,
  Name,
  Position,
  ReferenceCode,
  Sales,
  State,
  UpIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, item }) => {
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const { product, position, totalPurchases, totalSales, state, isNew } = item;

  return (
    <Container
      className={className}
      to={`/products/${product.id}/transactions`}
    >
      <State>
        {state === ProductRankingState.UP && <UpIcon />}
        {state === ProductRankingState.DOWN && <DownIcon />}
        {state === ProductRankingState.EQUAL && <EqualIcon />}
      </State>
      <Position>{position}</Position>
      <Name>
        {product.name}{' '}
        {product.referenceCode &&
          organizationConfig.activateProductReference && (
            <ReferenceCode>#{product.referenceCode}</ReferenceCode>
          )}{' '}
        {isNew && <Flag>Nuevo</Flag>}
      </Name>
      <Billing>
        <Price>{totalSales}</Price>
      </Billing>
      <Sales>{totalPurchases} pedidos</Sales>
      <Chevron />
    </Container>
  );
};

export default memo(Row);
