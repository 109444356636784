import { dark } from './dark';
import { light } from './light';
import 'styled-components';

export const themes = {
  dark,
  light,
};

export type Theme = typeof light;

declare module 'styled-components' {
  // We need this to be an interface - a type will error.
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}
