import { FC, memo, useCallback, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RedFlour from './redFlour';
import WhiteFlour from './whiteFlour';
import { DateContainer } from './styles';

const firstEvent = 1699052400000;
const secondEvent = 1713477600000;

const Flour: FC = () => {
  const [showRedFlour, setShowRedFlour] = useState(false);
  const [showWhiteFlour, setShowWhiteFlour] = useState(false);

  const onSelectedDate = useCallback((date: any) => {
    if (date.getTime() === firstEvent) {
      setShowRedFlour(true);
    } else if (date.getTime() === secondEvent) {
      setShowWhiteFlour(true);
    }
  }, []);

  return (
    <>
      {!showRedFlour && !showWhiteFlour ? (
        <DateContainer>
          <DatePicker label="Our day" onChange={onSelectedDate} />
        </DateContainer>
      ) : null}

      {showRedFlour && !showWhiteFlour ? <RedFlour /> : null}
      {showWhiteFlour && !showRedFlour ? <WhiteFlour /> : null}
    </>
  );
};

export default memo(Flour);
