export const ANIMATION_ROUTE_BLACKLIST = [/.*\/(employee|product)\/+/];

export const getKey = (pathname: string) => {
  if (pathname.includes('product')) {
    return 'product';
  }
  if (pathname.includes('employee')) {
    return 'employee';
  }
  return pathname;
};
