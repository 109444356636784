import { ReactNode } from 'react';
import { makeVar } from '@apollo/client';
import type { Partner, PartnerFormFields, PartnerNote } from 'model/Partner';
import type { Product, ProductQuantityHistory } from 'model/Product';
import type { Category } from 'model/Category';
import type { Employee } from 'model/Employee';
import type { Order } from 'model/Order';
import type { Discount } from 'model/Discount';
import type { Quota } from 'model/Quota';
import type { Provider } from 'model/Provider';
import type { OrganizationEvent } from 'model/OrganizationEvent';
import type { Expense } from 'model/Expense';
import type { DiscountType, TypeOfCash } from 'apollo/graphql.types';
import type {
  CategoryType,
  ProductsFilter,
} from 'apollo/generated/globalTypes';
import type { Cashier, CashierProduct, CashierQuota } from 'model/Cashier';
import type { ICONS } from 'components/Icon/constants';
import type { OrganizationDocument } from 'model/Organization';

export enum ModalType {
  MANAGE_CREDITS,
  ADD_MENU_SCHEDULE,
  ADD_WORK_SCHEDULE,
  ADJUST_PRODUCT_OPTIONS_DIALOG,
  ADJUST_PRODUCT_DETAILS,
  ADJUST_PRODUCT_QUANTITY,
  ADJUST_PRODUCT_STORE,
  ASSIGN_ACTIVE_DISCOUNTS,
  ASSIGN_QUOTA,
  CANCEL_ORDER,
  CASHIER_PRODUCTS,
  CASHIER_PARTNERS,
  CASHIER_SUMMARY,
  CATEGORY,
  CASHIER_DETAILS,
  CLOSE_CASHIER_DIALOG,
  CREATE_MENU,
  CREDIT_CONCEPT,
  DIALOG,
  DISCOUNT,
  DOCUMENTS,
  EMPLOYEE_ADVANCED_MENU,
  EMPLOYEE_DETAILS,
  EVENT,
  EXPIRED_QUOTA_DIALOG,
  EXPENSE_DETAILS,
  GENERATE_FILE_DIALOG,
  LOAD_PARTNER_CREDITS,
  MOVE_STORE_TO_STOCK,
  NEW_ACCOUNT_REQUEST,
  NEW_EMPLOYEE,
  NEW_EXPENSE,
  NEW_ORDER,
  NEW_PARTNER,
  NEW_PRODUCT,
  NEW_PROVIDER,
  NEW_REFERENCED_PRODUCT,
  NEW_TICKET_REQUEST,
  PARTNER_ADVANCED_MENU,
  PARTNER_DETAILS,
  PARTNER_MENU,
  PARTNER_NOTE,
  PARTNERS,
  PARTNERS_QUOTA,
  PRODUCT_ADVANCED_MENU,
  PRODUCTS,
  PURCHASE_DETAILS,
  QUOTA,
  QUOTA_DETAILS,
  REACTIVATE_PARTNER,
  RFID_DIALOG,
  SELECT_CATEGORIES,
  SELECT_PROVIDER,
  SELECT_TYPE_OF_CASH,
  SET_DOCUMENT_CUSTOM_DATA,
  SIGN_DOCUMENT,
  STORE_PRODUCT_OPTIONS_DIALOG,
  SHOW_QR,
  UPDATE_PASSWORD,
  UPLOAD_FILE,
  UPLOAD_IMAGE_DIALOG,
  VALIDATE_PIN,
  WEBCAM,
}

export type ModalDialogPayload = {
  acceptButtonText?: string;
  cancelButtonText?: string;
  description?: ReactNode;
  onAccept?: () => void;
  onCloseAction?: () => void;
  title: ReactNode;
  variant?: 'default' | 'danger';
  iconName?: keyof typeof ICONS;
};

export type ModalGenerateFileDialogPayload = {
  description?: ReactNode;
  title: ReactNode;
  iconName?: keyof typeof ICONS;
  onDownload: () => void;
  onUpload: () => void;
};

export type ModalSignDocumentPayload = {
  onClick: (signData: string, signHostData: string) => void;
  hasHostSign?: boolean;
};

export type ModalAdjustProductDetailsPayload = {
  productQuantityAdjust: ProductQuantityHistory;
};

export type ModalExpenseDetailsDetailsPayload = {
  expense: Expense;
};

export type ModalAdjustProductQuantityPayload = {
  product: Product;
  reloadPage?: boolean;
  actionType?: 'add' | 'remove' | 'set';
};

export type ModalAdjustProductStorePayload = {
  product: Product;
  reloadPage?: boolean;
  actionType?: 'add' | 'remove' | 'movement';
};

export type ModalMoveStoreToStockPayload = {
  product: Product;
  reloadPage?: boolean;
};

export type ModalWebcamPayload = {
  onAccept?: (imgUrl: string) => void;
  onReturn?: () => void;
};

export type ModalUploadImageDialogPayload = {
  onAcceptCamera?: () => void;
  onAcceptComputer?: () => void;
};

export type ModalAdjustProductOptionsDialogPayload = {
  onAcceptAdd?: () => void;
  onAcceptRemove?: () => void;
  onAcceptSet?: () => void;
};

export type ModalGenerateQRPayload = {
  value: string;
};

export type ModalStoreProductOptionsDialogPayload = {
  onAcceptAdd?: () => void;
  onAcceptRemove?: () => void;
  onAcceptMovement?: () => void;
};

export type ModalDiscountPayload = {
  discount?: Discount;
};

export type ModalNewProviderPayload = {
  provider?: Provider;
};

export type ModalManageCreditsPayload = {
  partner: Partner;
  onReturn?: () => void;
  allowCloseModal?: boolean;
  action?: 'added' | 'retire';
};

export type ModalEventPayload = {
  event?: OrganizationEvent;
};

export type ModalAddMenuSchedulePayload = {
  onSave: (menuSchedule: {
    daysOfWeek: string[];
    schedules: Array<{ startTime: string; endTime: string }>;
  }) => void;
};

export type ModalAddWorkSchedulePayload = {
  employeeId: string;
};

export type ModalSetDocumentDataPayload = {
  customData: OrganizationDocument['customData'];
  onSubmit: (values: Record<string, string | number>) => void;
};

export type ModalCreditConceptPayload = {
  concept?: string;
};

export type ModalCancelOrderPayload = {
  onSelectReason?: (reason: string, bookingId: string) => void;
  bookingId: string;
};

export type ModalCloseCashierPayload = {
  cashier: Cashier;
  selectedTypesOfCash?: TypeOfCash[];
};

export type ModalNewReferencedProductPayload = {
  product: Product;
};

export type ModalSelectTypeOfCashPayload = {
  typesOfCash: TypeOfCash[];
  onSelect: (typesOfCash: TypeOfCash[]) => void;
  onReturn: () => void;
};

export type ModalEmployeeAdvancedMenuPayload = {
  onReturn?: () => void;
  updatePassword: () => void;
  removeEmployee: () => void;
  toggleEmployeeActivationStatus: () => void;
  employeeActive?: boolean;
};

export type ModalUpdatePasswordPayload = {
  employeeId?: string;
};

export type ModalPartnerAdvancedMenuPayload = {
  onReturn?: () => void;
  loadCredits: (action: 'added' | 'retire') => void;
  convertToPartnerToEmployee: () => void;
  removePartner: () => void;
  assignDiscounts: () => void;
  generateContractFile: () => void;
  openOrder: () => void;
  isEmployee?: boolean;
};

export type ModalProductAdvancedMenuPayload = {
  onRemove: () => void;
  onAdjust: () => void;
  onStoreMovement: () => void;
  onCreateReference: () => void;
  assignDiscount: () => void;
};

export type ModalPartnerDetailsPayload = {
  partner?: Partner;
};

export type ModalEmployeeDetailsPayload = {
  employee?: Employee;
};

export type ModalPurchaseDetailsPayload = {
  orders: Order[];
  discount?: Partial<Discount>;
};

export type ModalCashierProductsPayload = {
  products: CashierProduct[];
  totalDiscounts: number;
};

export type ModalCashierPartnersPayload = {
  partners: CashierQuota['partners'];
};

export type ModalCategoryPayload = {
  category: Category;
};

export type ModalPartnerMenuPayload = {
  onReturn?: () => void;
  newOrder: () => void;
  loadCredits: () => void;
};

export type ModalLoadPartnerCreditsPayload = {
  onReturn?: () => void;
  partner: Partner;
};

export type ModalPartnersPayload = {
  onSelect?: (partner: Partner) => void;
  onReturn?: () => void;
};

export type ModalDocumentsPayload = {
  onSelect: (document: OrganizationDocument) => void;
};

export type ModalPartnersQuotaPayload = {
  partnersIds: string[];
};

export type ModalNewPartnerPayload = {
  onReturn?: (partner?: Partner) => void;
  initData?: PartnerFormFields;
};

export type ModalNewOrderPayload = {
  onSubmitPurchase?: () => void;
};

export type ModalQuotaPayload = {
  quota?: Quota;
  onReturn?: () => void;
};

export type ModalProductsPayload = {
  onSelect?: (product: Product) => void;
  onSubmitPurchase?: () => void;
  filters?: Partial<ProductsFilter>;
};

export type ModalSelectCategoriesPayload = {
  categories: (Category & { isChecked: boolean })[];
  onSelectCategories?: () => void;
  onReturn: () => void;
  onRefetch?: () => void;
  type: CategoryType;
};

export type ModalAssignDiscountsPayload = {
  partnerId?: Partner['id'];
  onSubmit: (discountsIds: Array<Discount>) => void;
  onReturn?: () => void;
  checkedDiscountsIds?: Array<Discount['id']>;
  selectedDiscount?: Partial<Discount>;
  oneSelection?: boolean;
  type?: DiscountType;
};

export type ModalAssignedQuotaPayload = {
  partner: Partner;
  selectedDiscount?: Partial<Discount>;
  onRefetchPartner?: () => void;
};

export type ModalQuotaDetailsPayload = {
  quota?: Partner['quota'];
};

export type ModalSelectProviderPayload = {
  onSubmit: (provider: Provider) => void;
  onReturn?: () => void;
  checkedProviderId?: Provider['id'];
};

export type ModalPartnerNotePayload = {
  note?: PartnerNote;
  partnerId: Partner['id'];
};

export type ModalUploadFilePayload = {
  partnerId: Partner['id'];
};

export type ModalReactivatePartnerPayload = {
  data: PartnerFormFields;
};

export type ModalValidatePinPayload = {
  onCorrectValidation: () => void;
};

export type Modal = {
  type: ModalType | null;

  addMenuSchedulePayload: ModalAddMenuSchedulePayload | null;
  addWorkSchedulePayload: ModalAddWorkSchedulePayload | null;
  adjustProductDetailsPayload: ModalAdjustProductDetailsPayload | null;
  adjustProductOptionsDialogPayload: ModalAdjustProductOptionsDialogPayload | null;
  adjustProductQuantityPayload: ModalAdjustProductQuantityPayload | null;
  adjustProductStorePayload: ModalAdjustProductStorePayload | null;
  assignDiscountsPayload: ModalAssignDiscountsPayload | null;
  assignQuotaPayload: ModalAssignedQuotaPayload | null;
  cancelOrderPayload: ModalCancelOrderPayload | null;
  cashierProductsPayload: ModalCashierProductsPayload | null;
  cashierPartnersPayload: ModalCashierPartnersPayload | null;
  categoryPayload: ModalCategoryPayload | null;
  closeCashierPayload: ModalCloseCashierPayload | null;
  creditConceptPayload: ModalCreditConceptPayload | null;
  dialogPayload: ModalDialogPayload | null;
  discountPayload: ModalDiscountPayload | null;
  documentsPayload: ModalDocumentsPayload | null;
  employeeAdvancedMenuPayload: ModalEmployeeAdvancedMenuPayload | null;
  employeeDetailsPayload: ModalEmployeeDetailsPayload | null;
  eventPayload: ModalEventPayload | null;
  expenseDetailsPayload: ModalExpenseDetailsDetailsPayload | null;
  generateFileDialogPayload: ModalGenerateFileDialogPayload | null;
  generateQRPayload: ModalGenerateQRPayload | null;
  loadPartnerCreditsPayload: ModalLoadPartnerCreditsPayload | null;
  manageCreditsPayload: ModalManageCreditsPayload | null;
  moveStoreToStockPayload: ModalMoveStoreToStockPayload | null;
  newOrderPayload: ModalNewOrderPayload | null;
  newPartnerPayload: ModalNewPartnerPayload | null;
  newProviderPayload: ModalNewProviderPayload | null;
  newReferencedProductPayload: ModalNewReferencedProductPayload | null;
  partnerAdvancedMenuPayload: ModalPartnerAdvancedMenuPayload | null;
  partnerDetailsPayload: ModalPartnerDetailsPayload | null;
  partnerMenuPayload: ModalPartnerMenuPayload | null;
  partnerNotePayload: ModalPartnerNotePayload | null;
  partnersPayload: ModalPartnersPayload | null;
  partnersQuotaPayload: ModalPartnersQuotaPayload | null;
  productAdvancedMenuPayload: ModalProductAdvancedMenuPayload | null;
  productPayload: ModalProductsPayload | null;
  purchaseDetailsPayload: ModalPurchaseDetailsPayload | null;
  quotaDetailsPayload: ModalQuotaDetailsPayload | null;
  quotaPayload: ModalQuotaPayload | null;
  reactivatePartnerPayload: ModalReactivatePartnerPayload | null;
  selectCategoriesPayload: ModalSelectCategoriesPayload | null;
  selectProviderPayload: ModalSelectProviderPayload | null;
  selectTypeOfCashPayload: ModalSelectTypeOfCashPayload | null;
  setDocumentDataPayload: ModalSetDocumentDataPayload | null;
  signDocumentPayload: ModalSignDocumentPayload | null;
  storeProductOptionsDialogPayload: ModalStoreProductOptionsDialogPayload | null;
  updatePasswordPayload: ModalUpdatePasswordPayload | null;
  uploadFilePayload: ModalUploadFilePayload | null;
  uploadImageDialogPayload: ModalUploadImageDialogPayload | null;
  validatePinPayload: ModalValidatePinPayload | null;
  webcamPayload: ModalWebcamPayload | null;
};

const emptyModal: Modal = {
  type: null,

  addMenuSchedulePayload: null,
  addWorkSchedulePayload: null,
  adjustProductOptionsDialogPayload: null,
  adjustProductDetailsPayload: null,
  adjustProductQuantityPayload: null,
  adjustProductStorePayload: null,
  assignDiscountsPayload: null,
  assignQuotaPayload: null,
  cancelOrderPayload: null,
  closeCashierPayload: null,
  creditConceptPayload: null,
  dialogPayload: null,
  discountPayload: null,
  documentsPayload: null,
  employeeDetailsPayload: null,
  generateFileDialogPayload: null,
  generateQRPayload: null,
  loadPartnerCreditsPayload: null,
  manageCreditsPayload: null,
  moveStoreToStockPayload: null,
  newPartnerPayload: null,
  newOrderPayload: null,
  newProviderPayload: null,
  newReferencedProductPayload: null,
  employeeAdvancedMenuPayload: null,
  eventPayload: null,
  expenseDetailsPayload: null,
  updatePasswordPayload: null,
  partnerAdvancedMenuPayload: null,
  partnerDetailsPayload: null,
  partnerMenuPayload: null,
  partnersPayload: null,
  partnersQuotaPayload: null,
  productPayload: null,
  productAdvancedMenuPayload: null,
  purchaseDetailsPayload: null,
  cashierProductsPayload: null,
  cashierPartnersPayload: null,
  categoryPayload: null,
  quotaDetailsPayload: null,
  quotaPayload: null,
  reactivatePartnerPayload: null,
  selectCategoriesPayload: null,
  selectProviderPayload: null,
  selectTypeOfCashPayload: null,
  setDocumentDataPayload: null,
  signDocumentPayload: null,
  storeProductOptionsDialogPayload: null,
  partnerNotePayload: null,
  uploadFilePayload: null,
  uploadImageDialogPayload: null,
  validatePinPayload: null,
  webcamPayload: null,
};

export const modalVar = makeVar<Modal>(emptyModal);

export const resetModalVar = () => {
  modalVar(emptyModal);
};

export const setModalVar = (data: Partial<Modal>) => {
  modalVar({ ...emptyModal, ...data });
};

export const updateModalVar = (data: Partial<Modal>) => {
  modalVar({ ...modalVar(), ...data });
};
