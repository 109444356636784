import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
} from 'yup';

const requiredMsg = 'Este campo es requerido.';

export const validationSchema = yupObject({
  name: yupString().required(requiredMsg),
  quantity: yupNumber().required(requiredMsg),
  price: yupNumber().required(requiredMsg),
});
