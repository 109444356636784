import { FC, memo } from 'react';
import type { Props } from './types';

const Trash: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    height={(size * 20) / 18}
    viewBox="0 0 18 20"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.25 0a2.5 2.5 0 012.5 2.5V3H17a1 1 0 01.117 1.993L17 5h-1v12.5a2.5 2.5 0 01-2.336 2.495L13.5 20h-9A2.5 2.5 0 012 17.5V5H1a1 1 0 01-.117-1.993L1 3h3.25v-.5A2.5 2.5 0 016.586.005L6.75 0zM14 5H4v12.5a.5.5 0 00.41.492L4.5 18h9a.5.5 0 00.5-.5V5zM7 8a1 1 0 01.993.883L8 9v6a1 1 0 01-1.993.117L6 15V9a1 1 0 011-1zm4 0a1 1 0 01.993.883L12 9v6a1 1 0 01-1.993.117L10 15V9a1 1 0 011-1zm.25-6h-4.5a.5.5 0 00-.5.5V3h5.5v-.5a.5.5 0 00-.41-.492L11.25 2z"
      fill="currentColor"
    />
  </svg>
);

export default memo(Trash);
