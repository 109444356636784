import { gql } from '@apollo/client';
import { BOOKING_FRAGMENT } from 'apollo/fragments/Booking';

export const GET_BOOKINGS = gql`
  query GetBookings($filter: String) {
    bookings(filter: $filter) {
      ...BookingData
    }
  }
  ${BOOKING_FRAGMENT}
`;

export const GET_PREVIOUS_BOOKINGS = gql`
  query GetPreviousBookings($filter: String) {
    previousBookings(filter: $filter) {
      ...BookingData
    }
  }
  ${BOOKING_FRAGMENT}
`;
