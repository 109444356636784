import { TransactionDataType } from 'apollo/graphql.types';

export const TABLE_METRICS = {
  state: '7%',
  createdBy: '18%',
  appliedTo: '18%',
  items: '13%',
  date: '18%',
  credits: '11%',
  revert: '11%',
};

export const MOBILE_TABLE_METRICS = {
  state: '5%',
  createdBy: '14%',
  appliedTo: '13%',
  items: '12%',
  date: '15%',
  credits: '14%',
  revert: '15%',
};

export const FILTER_DATA_OPTIONS = [
  {
    value: TransactionDataType.Purchases,
    label: 'Compras',
  },
  {
    value: TransactionDataType.Credits,
    label: 'Créditos',
  },
  {
    value: TransactionDataType.Quotas,
    label: 'Cuotas',
  },
  {
    value: TransactionDataType.Reverted,
    label: 'Cancelados',
  },
];
