import { FC, memo } from 'react';
import type { Props } from './types';

const Expenses: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>expenses icon</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Group"
        stroke="currentColor"
        transform="translate(5.000000, 5.000000)"
      >
        <rect id="Rectangle" x="0.5" y="0.5" width="13" height="13" rx="1" />
        <rect
          id="Rectangle-Copy-4"
          x="0.5"
          y="0.5"
          width="4"
          height="13"
          rx="1"
        />
      </g>
    </g>
  </svg>
);

export default memo(Expenses);
