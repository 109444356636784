import type { GetEvents_organizationEvents } from 'apollo/generated/GetEvents';

export type EventFormFields = {
  allDay?: boolean;
  endDate: Date;
  startDate: Date;
  title: string;
  notify?: boolean;
  emails?: string;
};

export const normalizeEvent = (input: GetEvents_organizationEvents) => ({
  id: input.id || '',
  title: input.title || '',
  start: (input.start && new Date(input.start)) || new Date(),
  end: (input.end && new Date(input.end)) || new Date(),
  allDay: input.allDay || false,
  notify: input.notify || false,
  emails:
    input.emails && input.emails?.length > 0 ? input.emails?.join(',') : '',
});

export type OrganizationEvent = ReturnType<typeof normalizeEvent>;
