import styled from 'styled-components';
import { SectionHeader } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultBackLink from 'components/BackLink';
import DefaultButton from 'components/Button';

export const Header = styled(SectionHeader)`
  margin-bottom: 2rem;
  ${from.tabletLandscape`
    margin-bottom: 3rem;
  `}
`;

export const BackLink = styled(DefaultBackLink)``;

export const HeaderInfo = styled.div`
  flex: 1 0 auto;
  margin-left: 1rem;
`;

export const HeaderName = styled.p`
  align-items: flex-end;
  display: flex;
  flex: 1 0 auto;
  font-size: 2rem;
  text-transform: capitalize;
`;

export const Button = styled(DefaultButton)``;
