import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { MOBILE_TABLE_METRICS, TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 1rem 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;

  ${from.tabletLandscape`
  padding: 1.0625rem 0 1.1875rem 0;
`}
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.grey500};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${TextEllipsis};

  ${from.tabletLandscape`
  font-size: 1rem;
`}
`;

export const CreatedBy = styled(ColumnTitle)`
  flex: 0 0 ${MOBILE_TABLE_METRICS.createdBy};

  ${from.tabletLandscape`
   margin-right: 0.5rem;
   flex: 0 0 ${TABLE_METRICS.createdBy};
 `}
`;

export const AppliedTo = styled(ColumnTitle)`
  flex: 0 0 ${MOBILE_TABLE_METRICS.appliedTo};
  margin: 0 0.5rem;

  ${from.tabletLandscape`
   flex: 0 0 ${TABLE_METRICS.appliedTo};
 `}
`;

export const Items = styled(ColumnTitle)`
  flex: 0 0 ${MOBILE_TABLE_METRICS.items};
  text-align: center;
  ${TextEllipsis};

  ${from.tabletLandscape`
   margin:0 1rem;
   flex: 0 0 ${TABLE_METRICS.items};
 `}
`;

export const State = styled(ColumnTitle)`
  flex: 0 0 ${MOBILE_TABLE_METRICS.state};

  ${from.tabletLandscape`
   flex: 0 0 ${TABLE_METRICS.state};
 `}
`;

export const Credits = styled(ColumnTitle)`
  flex: 0 0 ${MOBILE_TABLE_METRICS.credits};
  text-align: center;
  ${TextEllipsis};

  ${from.tabletLandscape`
   margin-right: 1rem;
   flex: 0 0 ${TABLE_METRICS.credits};
 `}
`;

export const Revert = styled(ColumnTitle)`
  flex: 0 0 ${MOBILE_TABLE_METRICS.revert};
  text-align: center;
  ${TextEllipsis};

  ${from.tabletLandscape`
   margin-right: 1rem;
   flex: 0 0 ${TABLE_METRICS.revert};
 `}
`;

export const Date = styled(ColumnTitle)`
  flex: 0 0 ${MOBILE_TABLE_METRICS.date};
  text-align: center;
  ${TextEllipsis};

  ${from.tabletLandscape`
   flex: 0 0 ${TABLE_METRICS.date};
 `}
`;
