import { FC, memo, useCallback } from 'react';
import useCashierActions from 'apollo/hooks/cashier/useCashierActions';
import { useSnackbar } from 'notistack';
import { Container } from './styles';

const OpenCashierSnackbarButton: FC = () => {
  const { openCashier, cashierActionsLoading } = useCashierActions();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOpenCashier = useCallback(async () => {
    await openCashier();

    closeSnackbar();
    enqueueSnackbar('Se ha abierto la caja', {
      variant: 'info',
    });
  }, [openCashier, closeSnackbar, enqueueSnackbar]);

  return (
    <Container onClick={handleOpenCashier} disabled={cashierActionsLoading}>
      Abrir
    </Container>
  );
};

export default memo(OpenCashierSnackbarButton);
