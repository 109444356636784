import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useUserActions from 'apollo/hooks/user/useUserActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';

const useConnect = () => {
  const [showSendMsg, setShowSendMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { close: closeModal, type } = useModal();
  const { sendNewAccountRequest, userActionsLoading } = useUserActions();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(
    async ({
      email,
      name,
      description,
      phone,
    }: {
      email: string;
      name: string;
      description: string;
      phone: number;
    }) => {
      try {
        setIsLoading(true);
        await sendNewAccountRequest({
          description,
          phone: Number(phone),
          name,
          email,
        });
        setShowSendMsg(true);
        setIsLoading(false);
      } catch (e) {
        enqueueSnackbar('No se ha podido realizar la solicitud', {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, sendNewAccountRequest],
  );

  return {
    handleOnClose,
    handleSubmit,
    isOpen: type === ModalType.NEW_ACCOUNT_REQUEST,
    isLoading: isLoading || userActionsLoading,
    showSendMsg,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
