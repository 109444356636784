import { FC, memo } from 'react';
import { Chevron, Container } from './styles';
import type { Props } from './types';

// TODO Implement steps util for auto detecting destination
const BackLink: FC<Props> = (props) => (
  <Container {...props}>
    <Chevron />
  </Container>
);

export default memo(BackLink);
