import type { FC } from 'react';
import { OrganizationMenuType } from 'apollo/graphql.types';
import Loader from 'components/Loader';
import SelectableView from './SelectableView';
import StaticView from './StaticView';
import useConnect from './connect';
import * as S from './styles';
import { BackgroundContainer } from './styles';

const MenuCatalog: FC = () => {
  const { menuData, loading } = useConnect();

  const isDynamic = menuData?.type === OrganizationMenuType.Selectable;
  const useLogoBackground = Boolean(
    menuData?.useLogoToBackground && menuData?.organizationLogo,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <S.Container
        $backgroundColor={menuData?.backgroundColor}
        $useLogoBackground={useLogoBackground}
      >
        {useLogoBackground && (
          <BackgroundContainer $logoUrl={menuData?.organizationLogo || ''} />
        )}
        {isDynamic && menuData && (
          <SelectableView
            useLogoBackground={useLogoBackground}
            menuData={menuData}
          />
        )}
        {!isDynamic && menuData && (
          <StaticView
            useLogoBackground={useLogoBackground}
            menuData={menuData}
          />
        )}
      </S.Container>
    </>
  );
};

export default MenuCatalog;
