import { gql } from '@apollo/client';
import { DISCOUNT_FRAGMENT } from 'apollo/fragments/Discount';

export const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($data: CreateDiscountDto!) {
    createDiscount(data: $data) {
      ...DiscountData
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount($id: ID!, $data: UpdateDiscountDto!) {
    updateDiscount(id: $id, data: $data) {
      ...DiscountData
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const REMOVE_DISCOUNT = gql`
  mutation RemoveDiscount($id: ID!) {
    removeDiscount(id: $id)
  }
`;
