import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  ADJUST_PRODUCT_QUANTITY,
  CREATE_PRODUCT,
  REMOVE_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_NEW_PRODUCT_REFERENCE,
  ASSIGN_DISCOUNT_TO_PRODUCT,
  UNASSIGN_PRODUCT_DISCOUNT,
} from 'apollo/mutations';
import { GET_PRODUCTS } from 'apollo/queries';
import {
  normalizeProduct,
  ProductAdjustQuantityFormFields,
  ProductFormFields,
  ProductReferenceFormFields,
} from 'model/Product';
import type {
  CreateProduct,
  CreateProductVariables,
} from 'apollo/generated/CreateProduct';
import type {
  UpdateProduct,
  UpdateProductVariables,
} from 'apollo/generated/UpdateProduct';
import type {
  RemoveProduct,
  RemoveProductVariables,
} from 'apollo/generated/RemoveProduct';
import type {
  AdjustProductQuantity,
  AdjustProductQuantityVariables,
} from 'apollo/generated/AdjustProductQuantity';
import type {
  CreateNewProductReferenceMutation,
  CreateNewProductReferenceMutationVariables,
  AssignDiscountToProductMutation,
  AssignDiscountToProductMutationVariables,
  UnassignProductDiscountMutation,
  UnassignProductDiscountMutationVariables,
} from 'apollo/graphql.types';

const useProductActions = () => {
  const [createProductMutation, { loading: isLoadingCreateProduct }] =
    useMutation<CreateProduct, CreateProductVariables>(CREATE_PRODUCT, {
      refetchQueries: [{ query: GET_PRODUCTS }],
    });

  const [updateProductMutation, { loading: isLoadingUpdateProduct }] =
    useMutation<UpdateProduct, UpdateProductVariables>(UPDATE_PRODUCT);

  const [removeProductMutation, { loading: isLoadingRemoveProduct }] =
    useMutation<RemoveProduct, RemoveProductVariables>(REMOVE_PRODUCT);

  const [adjustProductQuantityMutation, { loading: isLoadingProductQuantity }] =
    useMutation<AdjustProductQuantity, AdjustProductQuantityVariables>(
      ADJUST_PRODUCT_QUANTITY,
    );

  const [
    assignDiscountToProductMutation,
    { loading: isLoadingAssignDiscountToProduct },
  ] = useMutation<
    AssignDiscountToProductMutation,
    AssignDiscountToProductMutationVariables
  >(ASSIGN_DISCOUNT_TO_PRODUCT);

  const [
    unassignDiscountToProductMutation,
    { loading: isLoadingUnassignProductDiscount },
  ] = useMutation<
    UnassignProductDiscountMutation,
    UnassignProductDiscountMutationVariables
  >(UNASSIGN_PRODUCT_DISCOUNT);

  const [
    createNewProductReferenceMutation,
    { loading: isLoadingCreateNewProductReference },
  ] = useMutation<
    CreateNewProductReferenceMutation,
    CreateNewProductReferenceMutationVariables
  >(CREATE_NEW_PRODUCT_REFERENCE);

  const normalizeFormValuesToUpdate = useCallback(
    async (values: ProductFormFields) => ({
      baseWeight: Number(values.baseWeight),
      countToMaxConsume: values.countToMaxConsume,
      description: values.description,
      referenceCode: values.referenceCode,
      isActive: values.isActive,
      showInMenu: values.showInMenu,
      mainImageId: values.mainImageId,
      name: values.name,
      costs: Number(values.costs),
      price: Number(values.price),
      taxes: Number(values.taxes),
    }),
    [],
  );

  const normalizeFormValuesToCreateReference = useCallback(
    async (values: ProductReferenceFormFields) => ({
      baseWeight: Number(values.baseWeight),
      costs: Number(values.costs),
      price: Number(values.price),
      quantity: Number(values.quantity),
      referenceCode: values.referenceCode || '',
    }),
    [],
  );

  const normalizeFormValuesToCreate = useCallback(
    async (values: ProductFormFields) => ({
      baseWeight: Number(values.baseWeight),
      countToMaxConsume: values.countToMaxConsume,
      description: values.description,
      isActive: values.isActive,
      showInMenu: values.showInMenu,
      mainImageId: values.mainImageId,
      name: values.name,
      costs: Number(values.costs),
      price: Number(values.price),
      quantity: Number(values.quantity),
      storeQuantity: Number(values.storeQuantity),
      taxes: Number(values.taxes),
      categoryIds: values.categoryIds || [],
      referenceCode: values.referenceCode,
    }),
    [],
  );

  const normalizeFormValuesToAdjust = useCallback(
    async (values: ProductAdjustQuantityFormFields) => ({
      baseWeight: values.baseWeight ? Number(values.baseWeight) : 0,
      quantity: Number(values.newQuantity),
      note: values.note,
      movementType: values.movementType,
    }),
    [],
  );

  const normalizeImagesValues = useCallback(
    async (values: Partial<ProductFormFields>) => ({
      mainImageId: values.mainImageId,
    }),
    [],
  );

  const normalizeCategoriesValues = useCallback(
    async (values: Partial<ProductFormFields>) => ({
      categoryIds: values.categoryIds,
    }),
    [],
  );

  const createProduct = useCallback(
    async (data: ProductFormFields) => {
      const dto = await normalizeFormValuesToCreate(data);
      const res = await createProductMutation({
        variables: { data: dto },
      });

      if (res?.data?.createProduct) {
        return normalizeProduct(res.data.createProduct);
      }

      return null;
    },
    [createProductMutation, normalizeFormValuesToCreate],
  );

  const updateProduct = useCallback(
    async (id: string, data: ProductFormFields) => {
      const dto = await normalizeFormValuesToUpdate(data);
      await updateProductMutation({
        variables: { id, data: dto },
      });
    },
    [updateProductMutation, normalizeFormValuesToUpdate],
  );

  const createNewProductReference = useCallback(
    async (productId: string, data: ProductReferenceFormFields) => {
      const dto = await normalizeFormValuesToCreateReference(data);
      const res = await createNewProductReferenceMutation({
        variables: { productId, data: dto },
      });

      if (res?.data?.createNewProductReference) {
        return normalizeProduct(res.data.createNewProductReference);
      }

      return null;
    },
    [createNewProductReferenceMutation, normalizeFormValuesToCreateReference],
  );

  const deleteProduct = useCallback(
    async (id: string) => {
      await removeProductMutation({
        variables: {
          id,
        },
      });
    },
    [removeProductMutation],
  );

  const updateProductImages = useCallback(
    async (id: string, data: Partial<ProductFormFields>) => {
      const dto = await normalizeImagesValues(data);
      await updateProductMutation({
        variables: { id, data: dto },
      });
    },
    [normalizeImagesValues, updateProductMutation],
  );

  const adjustProductQuantity = useCallback(
    async (id: string, data: ProductAdjustQuantityFormFields) => {
      const dto = await normalizeFormValuesToAdjust(data);
      await adjustProductQuantityMutation({
        variables: { id, data: dto },
      });
    },
    [adjustProductQuantityMutation, normalizeFormValuesToAdjust],
  );

  const updateProductCategories = useCallback(
    async (id: string, data: Partial<ProductFormFields>) => {
      const dto = await normalizeCategoriesValues(data);
      await updateProductMutation({
        variables: { id, data: dto },
      });
    },
    [normalizeCategoriesValues, updateProductMutation],
  );

  const assignDiscountToProduct = useCallback(
    async (id: string, discountId: string) => {
      await assignDiscountToProductMutation({
        variables: { id, discountId },
      });
    },
    [assignDiscountToProductMutation],
  );

  const unassignDiscountToProduct = useCallback(
    async (id: string) => {
      await unassignDiscountToProductMutation({
        variables: { id },
      });
    },
    [unassignDiscountToProductMutation],
  );

  return {
    unassignDiscountToProduct,
    assignDiscountToProduct,
    adjustProductQuantity,
    createProduct,
    updateProduct,
    deleteProduct,
    updateProductCategories,
    updateProductImages,
    createNewProductReference,
    loading:
      isLoadingCreateProduct ||
      isLoadingUpdateProduct ||
      isLoadingRemoveProduct ||
      isLoadingProductQuantity ||
      isLoadingCreateNewProductReference ||
      isLoadingAssignDiscountToProduct ||
      isLoadingUnassignProductDiscount,
  };
};

export default useProductActions;
