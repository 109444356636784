import { FC, memo } from 'react';
import QuotaModal from '../../../Modals/Quota';
import PartnersQuotaModal from '../../../Modals/PartnersQuota';
import {
  SectionTitle,
  Header,
  BackLink,
  HeaderContent,
  CreateButton,
} from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const OrganizationQuotas: FC = () => {
  const {
    employeePermissions,
    quotas,
    handleOpenQuotaModal,
    handleOpenPartnerQuotaModal,
    handleOpenRemoveQuotaDialog,
  } = useConnect();
  const hasQuotas = quotas.length > 0;

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <BackLink to="/organization" />
            <SectionTitle>Cuotas</SectionTitle>
          </HeaderContent>
          {employeePermissions.allowCreateQuotas && (
            <CreateButton onClick={() => handleOpenQuotaModal()}>
              Crear
            </CreateButton>
          )}
        </Header>
        <Main>
          {!hasQuotas && employeePermissions.allowCreateQuotas && (
            <EmptyState
              title="No hay cuotas"
              iconName="quota"
              buttonLabel="Crear Cuota"
              onClickButton={handleOpenQuotaModal}
            >
              Crea tu primera Cuota
            </EmptyState>
          )}
          {hasQuotas && (
            <>
              <TableHead />
              {quotas.map((quota) => (
                <Row
                  key={quota.id}
                  employeePermissions={employeePermissions}
                  totalPartners={quota.partnersIds.length}
                  name={quota.name}
                  type={quota.type}
                  price={quota.price}
                  value={quota.value}
                  onOpenPartnersModal={() =>
                    handleOpenPartnerQuotaModal({
                      partnersIds: quota.partnersIds,
                    })
                  }
                  onEdit={() => handleOpenQuotaModal({ quota })}
                  onRemove={() => handleOpenRemoveQuotaDialog(quota)}
                />
              ))}
            </>
          )}
        </Main>
      </Container>
      <QuotaModal />
      <PartnersQuotaModal />
    </>
  );
};

export default memo(OrganizationQuotas);
