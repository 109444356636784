import { FC, memo } from 'react';
import { CategoryType } from 'apollo/generated/globalTypes';
import {
  Container,
  Content,
  Name,
  Type,
  EditIcon,
  Icons,
  TrashIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  employeePermissions,
  name,
  onEdit,
  onRemove,
  type,
  onClickRow,
}) => {
  const isProductCat = type === CategoryType.PRODUCT;

  return (
    <Container className={className}>
      <Content $isProductCat={isProductCat} onClick={onClickRow}>
        <Name>{name}</Name>
        <Type>{isProductCat ? 'producto' : 'gasto'}</Type>
      </Content>
      <Icons>
        {employeePermissions.allowEditCategories &&
          type !== CategoryType.PRODUCT && <EditIcon onClick={onEdit} />}
        {employeePermissions.allowRemoveCategories && (
          <TrashIcon onClick={onRemove} />
        )}
      </Icons>
    </Container>
  );
};

export default memo(Row);
