import { useState, useRef, useLayoutEffect } from 'react';

const useDimensions = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{
    height: number;
    width: number;
  }>();

  useLayoutEffect(() => {
    const handleResize = () => {
      if (ref?.current) {
        setDimensions(ref.current.getBoundingClientRect().toJSON());
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { dimensions, ref };
};

export default useDimensions;
