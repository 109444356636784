import { useCallback, useState } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import useProductsGroupedByName from 'apollo/hooks/product/useProductsGroupedByName';

const useConnect = () => {
  const { groupedProducts, loading, refetch } = useProductsGroupedByName();
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  });

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      setFilterDate({ from, to });
      await refetch({
        filters: {
          fromDate: from || null,
          toDate: to || null,
        },
      });
    },
    [refetch],
  );

  return {
    isLoading: loading,
    groupedProducts,
    filterDates,
    handleOnSearch,
  };
};

export default useConnect;
