import { FC, memo } from 'react';
import { formatDate } from 'utils/dates/formatDates';
import { Container, Document, Email, Phone, Name, RequestDate } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, partnerRequest }) => {
  return (
    <Container
      className={className}
      to={`/organization/partners-requests/${partnerRequest.id}`}
    >
      <Name>{`${partnerRequest.firstName} ${partnerRequest.lastName}`}</Name>
      <Document>{partnerRequest.document}</Document>
      <Email>{partnerRequest.email}</Email>
      <Phone>{partnerRequest.phoneNumber}</Phone>
      <RequestDate>{formatDate(partnerRequest.createdAt)}</RequestDate>
    </Container>
  );
};

export default memo(Row);
