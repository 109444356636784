import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import DefaultMoreIcon from 'components/Icon/Icons/More';
import DefaultUsersIcon from 'components/Icon/Icons/Users';
import DefaultProductsIcon from 'components/Icon/Icons/Products';
import { NavLink } from 'react-router-dom';
import { from } from 'styles/responsive';

export const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  position: fixed;
  width: 100%;
  z-index: 1000;

  &.active {
    color: ${({ theme }) => theme.colors.accent};
  }

  ${from.tabletLandscape`
    display: none;
  `};
`;

export const Link = styled(NavLink)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 3.5rem;
  justify-content: space-evenly;
  width: 4rem;

  &.active {
    background-color: ${({ theme }) => theme.colors.grey300};
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.625rem;
`;

export const Icon = styled(DefaultIcon)``;

export const DashboardIcon = styled(DefaultProductsIcon).attrs(() => ({
  size: 24,
}))``;

export const MoreIcon = styled(DefaultMoreIcon).attrs(() => ({ size: 24 }))``;

export const UsersIcon = styled(DefaultUsersIcon).attrs(() => ({
  size: 24,
}))``;
