import type { FC } from 'react';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { Container, EmptyState, Main, DateTimeFilterContainer } from './styles';

const ProductTransactions: FC = () => {
  const {
    activateProductReference,
    product,
    transactions,
    isLoading,
    handleReturn,
    handleOpenEmployeeDetailsModal,
    handleOpenPartnerDetailsModal,
    filterDates,
    handleOnSearch,
  } = useConnect();

  const hasResults = transactions?.length > 0;
  const referenceCode =
    product?.referenceCode && activateProductReference
      ? `#${product.referenceCode}`
      : '';

  return (
    <Container>
      <Header
        title={`Transacciones producto ${product?.name} ${referenceCode}`}
        onReturn={handleReturn}
      />
      <DateTimeFilterContainer>
        <DateTimeFilter
          fromDate={filterDates.from}
          toDate={filterDates.to}
          onSearch={handleOnSearch}
        />
      </DateTimeFilterContainer>

      {isLoading && <Loader />}
      {!isLoading && (
        <Main>
          {!hasResults && (
            <EmptyState
              title="No se puede generar la información"
              iconName="products"
            >
              No hay existen transacciones de este producto en estas fechas
            </EmptyState>
          )}
          {hasResults && (
            <>
              <TableHead />
              {transactions.map((transaction) => (
                <Row
                  key={transaction.id}
                  transaction={transaction}
                  onClickEmployee={handleOpenEmployeeDetailsModal}
                  onClickPartner={handleOpenPartnerDetailsModal}
                />
              ))}
            </>
          )}
        </Main>
      )}
    </Container>
  );
};

export default ProductTransactions;
