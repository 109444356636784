import { QueryHookOptions, useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import notEmpty from 'utils/notEmpty';
import { permissionsStateVar, subscriptionStateVar } from 'apollo/reactive';
import { GET_PROVIDERS } from 'apollo/queries/providers';
import type {
  GetProviders,
  GetProvidersVariables,
} from 'apollo/generated/GetProviders';
import { normalizeProvider } from 'model/Provider';

const useProviders = ({
  filter,
  fetchPolicy = 'cache-first',
}: {
  filter?: string;
  fetchPolicy?: QueryHookOptions<GetProvidersVariables>['fetchPolicy'];
} = {}) => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const subscription = useReactiveVar(subscriptionStateVar);

  const { loading, data, refetch } = useQuery<
    GetProviders,
    GetProvidersVariables
  >(GET_PROVIDERS, {
    fetchPolicy,
    skip:
      !subscription.allowedExpenses || !employeePermissions.allowShowProviders,
    variables: { filter },
  });

  const { providers } = useMemo(
    () => ({
      providers: data?.providers
        ? data.providers.filter(notEmpty).map(normalizeProvider)
        : [],
    }),
    [data],
  );

  return {
    loading,
    providers,
    refetch,
  };
};

export default useProviders;
