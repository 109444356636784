import type { FC } from 'react';
import {
  ApproveButton,
  ButtonsContainer,
  Container,
  Label,
  LabelContainer,
  LabelTitle,
  ProductsContainer,
  RejectButton,
} from './styles';
import type { Props } from './types';

const formatter = Intl.NumberFormat('es-ES', {
  minimumIntegerDigits: 2,
  minimumFractionDigits: 2,
});

const BookingCard: FC<Props> = ({
  className,
  partnerNum,
  items,
  total,
  approveOrder,
  completeOrder,
  rejectOrder,
  isReviewed,
}) => (
  <Container className={className}>
    <LabelContainer>
      <LabelTitle>Socio #{partnerNum}</LabelTitle>
      <LabelTitle>{formatter.format(total)} cr</LabelTitle>
    </LabelContainer>
    <ProductsContainer>
      {items &&
        items.map((item) => (
          <LabelContainer key={item.productId}>
            <Label>{item.productName}</Label>
            <Label>
              {formatter.format(item.productPrice * item.quantity)} cr
            </Label>
          </LabelContainer>
        ))}
    </ProductsContainer>
    <ButtonsContainer>
      <RejectButton onClick={rejectOrder}>Cancelar</RejectButton>
      <ApproveButton
        variant={isReviewed ? 'success' : 'primary'}
        onClick={isReviewed ? completeOrder : approveOrder}
      >
        {isReviewed ? 'Listo' : 'Aceptar'}
      </ApproveButton>
    </ButtonsContainer>
  </Container>
);

export default BookingCard;
