import styled from 'styled-components';
import DefaultButton from 'components/Button';
import { IconCircle as DefaultIconCircle } from '../Icon/Icons';
import DefaultIcon from '../Icon';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  max-width: 16.5rem;
`;

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const Text = styled.p`
  font-weight: ${({ theme }) => theme.weights.regular};
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.grey900};
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const Button = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))``;

export const IconCircle = styled(DefaultIconCircle)`
  background-color: ${({ theme }) => theme.colors.danger}0A;
  margin-right: 1rem;
`;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.danger};
`;
