import { useCallback, useEffect, useRef, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import useImageSignedUrlActions from 'apollo/hooks/upload/useImageSignedUrlActions';
import { ImageCategory } from 'apollo/generated/globalTypes';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { urlToFile } from 'utils/images';
import type { LogicProps } from './types';

const useLogic = ({
  acceptedInputFiles,
  acceptedDropzoneFiles,
  maxSize,
  currentImage,
  onSubmitImage,
}: LogicProps) => {
  const { uploadImage } = useImageSignedUrlActions();
  const { enqueueSnackbar } = useSnackbar();
  const { openUploadImageDialog, openWebcam, close: closeModal } = useModal();

  const [imgUrl, setImgUrl] = useState<string | undefined>(currentImage);
  const [progress, setProgress] = useState<number | undefined>(undefined);

  const cancelSourceRef = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => {
      cancelSourceRef.current?.cancel();
    };
  }, []);

  const handleAccept = useCallback(
    async (files: File[]) => {
      const imageFile = files[0];
      try {
        cancelSourceRef.current = axios.CancelToken.source();
        const uploadData = await uploadImage({
          file: imageFile,
          setProgress,
          category: ImageCategory.AVATAR,
          cancelToken: cancelSourceRef.current?.token,
        });

        if (!uploadData?.url) {
          throw new Error();
        }

        setImgUrl(uploadData.url);
        onSubmitImage(uploadData.id);
      } catch (error) {
        enqueueSnackbar(`Ha ocurrido un error al subir la imagen.`, {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={error} />,
        });
      }
    },
    [onSubmitImage, uploadImage, enqueueSnackbar],
  );

  const handleReject = useCallback(
    (fileRejections: FileRejection[]) => {
      const rejectedFile = fileRejections[0].file;
      if (!acceptedInputFiles.includes(rejectedFile?.type)) {
        enqueueSnackbar(`Tipo de archivo no valido`, {
          variant: 'error',
        });
        return;
      }

      if (rejectedFile.size > maxSize) {
        enqueueSnackbar(
          maxSize < 1000000
            ? `El archivo no puede exceder ${maxSize / 1000} KB.`
            : `El archivo no puede exceder ${maxSize / 1000000} MB.`,
          {
            variant: 'error',
          },
        );
      }
    },
    [acceptedInputFiles, enqueueSnackbar, maxSize],
  );

  const { getInputProps, open: openComputerFiles } = useDropzone({
    accept: acceptedDropzoneFiles,
    maxSize,
    onDropAccepted: handleAccept,
    onDropRejected: handleReject,
  });

  const handleOpenWebcamModal = useCallback(() => {
    openWebcam({
      onAccept: async (imgFile) => {
        await handleAccept([urlToFile(imgFile, 'avatar')]);
        closeModal();
      },
    });
  }, [closeModal, handleAccept, openWebcam]);

  const handleOpenUploadImageDialogModal = useCallback(() => {
    openUploadImageDialog({
      onAcceptComputer: openComputerFiles,
      onAcceptCamera: handleOpenWebcamModal,
    });
  }, [handleOpenWebcamModal, openComputerFiles, openUploadImageDialog]);

  return {
    handleAccept,
    handleOpenUploadImageDialogModal,
    handleReject,
    imgUrl,
    progress,
    getInputProps,
  };
};

export default useLogic;
