import type { FC } from 'react';
import useConnect from './connect';
import {
  ActionButton,
  CancelButton,
  Container,
  IconCircle,
  Icon,
  Title,
  Buttons,
} from './styles';

const ReactivatePartnerModal: FC = () => {
  const { data, handleClose, handleActivate, isLoading, isOpen } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>
        ¿Quieres restaurar a "{data?.firstName} {data?.lastName}" como socio?
      </Title>
      <Buttons>
        <CancelButton onClick={handleClose}>Cancelar</CancelButton>
        <ActionButton onClick={handleActivate} isLoading={isLoading}>
          Restaurar
        </ActionButton>
      </Buttons>
    </Container>
  );
};

export default ReactivatePartnerModal;
