import { FC, memo } from 'react';
import { Formik } from 'formik';
import { initialValues, validationSchema } from './constants';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  Row,
  TextField,
} from './styles';
import type { Props } from './types';

const PasswordModalForm: FC<Props> = ({
  className,
  onSubmit,
  onCancel,
  isLoading,
  id = 'new',
}) => {
  const formId = `updatePassword_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <TextField
            type="password"
            name="oldPassword"
            label="Contraseña actual *"
            id={`${formId}_oldPassword`}
          />
          <Row>
            <TextField
              type="password"
              name="newPassword"
              label="Nueva Contraseña *"
              id={`${formId}_newPassword`}
            />
            <TextField
              type="password"
              name="newPassword2"
              label="Repite Contraseña *"
              id={`${formId}_newPassword2`}
            />
          </Row>
          <Buttons>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={!isFormValid}
            >
              Actualizar
            </CreateButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(PasswordModalForm);
