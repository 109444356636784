import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  REMOVE_FILE,
  UPLOAD_FILE,
  UPLOAD_FILE_FROM_S3_TO_DRIVE,
} from 'apollo/mutations/files';
import { GET_PARTNER } from 'apollo/queries/partners';
import { normalizeFilename, toBase64 } from 'utils/files';
import type {
  UploadFileMutation,
  UploadFileMutationVariables,
  UploadFileFromS3ToDriveMutation,
  UploadFileFromS3ToDriveMutationVariables,
  RemoveFileMutation,
  RemoveFileMutationVariables,
} from 'apollo/graphql.types';

const useFileActions = (partnerId: string) => {
  const [removeFileMutation, { loading: isLoadingRemoveFile }] = useMutation<
    RemoveFileMutation,
    RemoveFileMutationVariables
  >(REMOVE_FILE, {
    refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
  });
  const [
    uploadFileFromS3ToDriveMutation,
    { loading: isLoadingUploadFileFromS3ToDrive },
  ] = useMutation<
    UploadFileFromS3ToDriveMutation,
    UploadFileFromS3ToDriveMutationVariables
  >(UPLOAD_FILE_FROM_S3_TO_DRIVE, {
    refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
  });
  const [uploadFileMutation, { loading: uploadFileIsLoading }] = useMutation<
    UploadFileMutation,
    UploadFileMutationVariables
  >(UPLOAD_FILE, {
    refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
  });

  const removeFile = useCallback(
    async (fileId: string) => {
      await removeFileMutation({
        variables: {
          fileId,
        },
      });
    },
    [removeFileMutation],
  );

  const uploadFileFromS3ToDrive = useCallback(
    async (fileId: string) => {
      await uploadFileFromS3ToDriveMutation({
        variables: {
          fileId,
        },
      });
    },
    [uploadFileFromS3ToDriveMutation],
  );

  const uploadFile = useCallback(
    async ({
      file,
      fileName,
      partnerId,
    }: {
      file: File;
      fileName: string;
      partnerId: string;
    }) => {
      let base64 = await toBase64(file);
      base64 = base64
        .replace(/^data:application\/\w+;base64,/, '')
        .replace(/^data:image\/\w+;base64,/, '');

      if (!base64) {
        throw new Error('Bad file');
      }

      const renamedFile = new File([file], normalizeFilename(fileName), {
        type: file.type,
      });

      await uploadFileMutation({
        variables: {
          data: {
            file: base64,
            fileName: fileName?.replace(/\s/g, '-') || renamedFile.name,
            contentType: renamedFile.type,
            fileSize: renamedFile.size,
            partnerId,
          },
        },
      });
    },
    [uploadFileMutation],
  );

  return {
    removeFile,
    uploadFile,
    uploadFileFromS3ToDrive,
    fileActionsLoading:
      isLoadingRemoveFile ||
      uploadFileIsLoading ||
      isLoadingUploadFileFromS3ToDrive,
  };
};

export default useFileActions;
