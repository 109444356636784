import { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider as MaterialUIProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components';
import { apolloClient } from 'services/api';
import GlobalStyle from 'styles/global';
import { materialUITheme, notistackConfig } from 'styles/material';
import { themes } from 'styles/themes';
import Routes from 'containers/Routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const App: FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <MaterialUIProvider theme={materialUITheme}>
          <ThemeProvider theme={themes.light}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider {...notistackConfig}>
                <Routes />
                <GlobalStyle />
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </MaterialUIProvider>
      </Router>
    </ApolloProvider>
  );
};

export default App;
