import { FC, memo } from 'react';
import { Formik } from 'formik';
import useConnect from './connect';
import {
  Container,
  Title,
  IconCircle,
  Icon,
  Header,
  Buttons,
  CancelButton,
  CreateButton,
  Row,
  SaveMessage,
  SaveMessageNumber,
  SaveMessageText,
  TextField,
  Form,
} from './styles';
import { validationSchema } from './constants';

const AdjustProductStoreModal: FC = () => {
  const {
    actionType,
    adjustState,
    handleClose,
    handleSubmit,
    initialValues,
    isFirstSave,
    isLoading,
    isOpen,
    handleChangeNewQuantity,
  } = useConnect();

  const formId = `storeProduct_${initialValues.name}`;
  const isAddAction = actionType === 'add';

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="store" size={24} />
        </IconCircle>
        {isAddAction && <Title>Añadir al almacén {initialValues.name}</Title>}
        {!isAddAction && (
          <Title>Retirada del almacén {initialValues.name}</Title>
        )}
      </Header>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isValid: isFormValid, values }) => (
          <Form>
            <Row>
              <TextField
                readOnly
                type="number"
                name="quantity"
                label="Almacén Actual"
              />
              <TextField
                type="number"
                name="newQuantity"
                label={isAddAction ? 'Añadir' : 'Retirar'}
                onLocalChange={handleChangeNewQuantity}
                id={`${formId}_newQuantity`}
              />
            </Row>
            <TextField
              type="text"
              name="note"
              label="Nota"
              id={`${formId}_note`}
            />
            {adjustState?.show && (
              <SaveMessage>
                <SaveMessageText>
                  La diferencia es{' '}
                  {adjustState.isNegative ? 'negativa' : 'positiva'}.{' '}
                  <SaveMessageNumber $isNegative={adjustState.isNegative}>
                    {adjustState.remainingQuantity}
                  </SaveMessageNumber>
                </SaveMessageText>
              </SaveMessage>
            )}
            <Buttons>
              <CreateButton
                type="submit"
                isLoading={isLoading}
                disabled={
                  isLoading || !isFormValid || values?.newQuantity === 0
                }
              >
                {isFirstSave ? 'Validar' : 'Guardar'}
              </CreateButton>
              <CancelButton onClick={handleClose}>Cancelar</CancelButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default memo(AdjustProductStoreModal);
