import { FC, memo } from 'react';

import type { Props } from './types';

const ShoppingCart: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={size}
    height={size}
    {...rest}
  >
    <circle
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      cx="16.5"
      cy="18.5"
      r="1.5"
    />
    <circle
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      cx="9.5"
      cy="18.5"
      r="1.5"
    />
    <path
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      d="M18 16H8a1 1 0 0 1-.958-.713L4.256 6H3a1 1 0 0 1 0-2h2a1 1 0 0 1 .958.713L6.344 6H21a1 1 0 0 1 .937 1.352l-3 8A1 1 0 0 1 18 16zm-9.256-2h8.563l2.25-6H6.944z"
    />
  </svg>
);

export default memo(ShoppingCart);
