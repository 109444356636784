import { gql } from '@apollo/client';
import { PROVIDER_FRAGMENT } from 'apollo/fragments/Provider';

export const GET_PROVIDERS = gql`
  query GetProviders($filter: String) {
    providers(filter: $filter) {
      ...ProviderData
    }
  }
  ${PROVIDER_FRAGMENT}
`;

export const GET_PROVIDER = gql`
  query GetProvider($id: ID!) {
    provider(id: $id) {
      ...ProviderData
    }
  }
  ${PROVIDER_FRAGMENT}
`;
