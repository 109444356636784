import Add from './Icons/Add';
import ArrowLeft from './Icons/ArrowLeft';
import ArrowRight from './Icons/ArrowRight';
import Calendar from './Icons/Calendar';
import CaretDownLight from './Icons/CaretDownLight';
import Cart from './Icons/Cart';
import Cashier from './Icons/Cashier';
import Categories from './Icons/Categories';
import Coin from './Icons/Coin';
import CopyClip from './Icons/CopyClip';
import Chart from './Icons/Chart';
import ChartSelected from './Icons/ChartSelected';
import Chat from './Icons/Chat';
import Check from './Icons/Check';
import Clock from './Icons/Clock';
import Close from './Icons/Close';
import Cr from './Icons/Cr';
import DisabledDriveLogo from './Icons/DisabledDriveLogo';
import Discount from './Icons/Discount';
import Done from './Icons/Done';
import Down from './Icons/Down';
import DriveLogo from './Icons/DriveLogo';
import Edit from './Icons/Edit';
import Employees from './Icons/Employees';
import Equal from './Icons/Equal';
import Event from './Icons/Event';
import Expenses from './Icons/Expenses';
import Flower from './Icons/Flower';
import Help from './Icons/Help';
import Lock from './Icons/Lock';
import LockOpen from './Icons/LockOpen';
import LogOut from './Icons/LogOut';
import Menu from './Icons/Menu';
import Minus from './Icons/Minus';
import More from './Icons/More';
import MoreSelected from './Icons/MoreSelected';
import Note from './Icons/Note';
import Orders from './Icons/Orders';
import OrdersBig from './Icons/OrdersBig';
import OrdersSelected from './Icons/OrdersSelected';
import Organization from './Icons/Organization';
import Password from './Icons/Password';
import Products from './Icons/Products';
import ProductsSelected from './Icons/ProductsSelected';
import Quota from './Icons/Quota';
import Reports from './Icons/Reports';
import Revert from './Icons/Revert';
import Rfid from './Icons/Rfid';
import Search from '../Search';
import Settings from './Icons/Settings';
import ShoppingCart from './Icons/ShoppingCart';
import Store from './Icons/Store';
import Substract from './Icons/Substract';
import Timer from './Icons/Timer';
import Trash from './Icons/Trash';
import Up from './Icons/Up';
import UploadFile from './Icons/UploadFile';
import Users from './Icons/Users';

export const ICONS = {
  add: Add,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  calendar: Calendar,
  caretDownLight: CaretDownLight,
  cart: Cart,
  cashier: Cashier,
  categories: Categories,
  coin: Coin,
  copyClip: CopyClip,
  char: Chart,
  chartSelected: ChartSelected,
  chat: Chat,
  check: Check,
  clock: Clock,
  close: Close,
  cr: Cr,
  disabledDriveLogo: DisabledDriveLogo,
  discount: Discount,
  done: Done,
  down: Down,
  driveLogo: DriveLogo,
  edit: Edit,
  employees: Employees,
  equal: Equal,
  event: Event,
  expenses: Expenses,
  flower: Flower,
  help: Help,
  lock: Lock,
  lockOpen: LockOpen,
  logout: LogOut,
  menu: Menu,
  minus: Minus,
  more: More,
  moreSelected: MoreSelected,
  note: Note,
  orders: Orders,
  ordersBig: OrdersBig,
  ordersSelected: OrdersSelected,
  organization: Organization,
  password: Password,
  products: Products,
  productsSelected: ProductsSelected,
  quota: Quota,
  reports: Reports,
  revert: Revert,
  rfid: Rfid,
  search: Search,
  settings: Settings,
  shoppingCart: ShoppingCart,
  store: Store,
  substract: Substract,
  timer: Timer,
  trash: Trash,
  up: Up,
  uploadFile: UploadFile,
  users: Users,
};
