import { useQuery } from '@apollo/client';
import type { GetMostUsedCategories } from 'apollo/generated/GetMostUsedCategories';
import { GET_MOST_USED_CATEGORIES } from 'apollo/queries/categories';
import { useMemo } from 'react';
import notEmpty from 'utils/notEmpty';
import { normalizeCategory } from 'model/Category';

const useMostUsedCategories = () => {
  const { loading, data, refetch } = useQuery<GetMostUsedCategories>(
    GET_MOST_USED_CATEGORIES,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const { mostUsedCategories } = useMemo(
    () => ({
      mostUsedCategories: data?.mostUsedCategories
        ? data.mostUsedCategories.filter(notEmpty).map(normalizeCategory)
        : [],
    }),
    [data],
  );

  return {
    loading,
    mostUsedCategories,
    refetch,
  };
};

export default useMostUsedCategories;
