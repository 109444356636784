import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { OPEN_CASHIER, CLOSE_CASHIER } from 'apollo/mutations/cashier';
import type {
  OpenCashierMutation,
  OpenCashierMutationVariables,
  CloseCashierMutation,
  CloseCashierMutationVariables,
  CloseCashierInput,
} from 'apollo/graphql.types';
import { normalizeCashier } from 'model/Cashier';

const useCashierActions = () => {
  const [openCashierMutation, { loading: openCashierIsLoading }] = useMutation<
    OpenCashierMutation,
    OpenCashierMutationVariables
  >(OPEN_CASHIER);
  const [closeCashierMutation, { loading: closeCashierIsLoading }] =
    useMutation<CloseCashierMutation, CloseCashierMutationVariables>(
      CLOSE_CASHIER,
    );

  const openCashier = useCallback(async () => {
    const { data } = await openCashierMutation();

    if (data?.openCashier) {
      return normalizeCashier(data.openCashier);
    }
  }, [openCashierMutation]);

  const closeCashier = useCallback(
    async ({ id, dto }: { id: string; dto: CloseCashierInput }) => {
      const { data } = await closeCashierMutation({
        variables: {
          id,
          data: {
            note: dto.note || '',
            typeOfCash:
              dto.typeOfCash?.map((item) => ({
                key: Number(item.key),
                value: Number(item.value),
              })) || [],
            realCreditsAdded: dto.realCreditsAdded
              ? Number(dto.realCreditsAdded)
              : 0.0,
            restRealCredits: dto.restRealCredits
              ? Number(dto.restRealCredits)
              : 0.0,
          },
        },
      });

      if (data?.closeCashier) {
        return normalizeCashier(data.closeCashier);
      }
    },
    [closeCashierMutation],
  );

  return {
    closeCashier,
    openCashier,
    cashierActionsLoading: openCashierIsLoading || closeCashierIsLoading,
  };
};

export default useCashierActions;
