import { useCallback, useState } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useUploadFile from 'hooks/useUploadFile';

const useConnect = () => {
  const [file, setFile] = useState<File | null>(null);
  const { close, type, uploadFilePayload } = useModal();
  const { partnerId } = uploadFilePayload || {};

  const { uploadFile, isUploadFileLoading } = useUploadFile(partnerId || '');

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleOnGetFile = useCallback((file: File | null) => {
    setFile(file);
  }, []);

  const handleOnsubmit = useCallback(
    async ({ fileName }: { fileName: string }) => {
      if (file && partnerId) {
        await uploadFile({
          fileName,
          file,
        });
      }
      handleClose();
    },
    [file, partnerId, uploadFile, handleClose],
  );

  return {
    file,
    handleClose,
    handleOnGetFile,
    handleOnsubmit,
    isLoading: isUploadFileLoading,
    isOpen: type === ModalType.UPLOAD_FILE,
  };
};

export default useConnect;
