import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { GET_EMPLOYEES } from 'apollo/queries/employees';
import { userRoleVar } from 'apollo/reactive';
import { UserRole } from 'apollo/generated/globalTypes';
import notEmpty from 'utils/notEmpty';
import { normalizeEmployee } from 'model/Employee';
import type { GetEmployeesQuery } from 'apollo/graphql.types';

const useEmployees = () => {
  const userRole = useReactiveVar(userRoleVar);

  const { loading, data, refetch } = useQuery<GetEmployeesQuery>(
    GET_EMPLOYEES,
    {
      skip: userRole === UserRole.EMPLOYEE,
    },
  );

  const { employees } = useMemo(
    () => ({
      employees: data?.employees
        ? data.employees.filter(notEmpty).map(normalizeEmployee)
        : [],
    }),
    [data],
  );

  return {
    employees,
    loading,
    refetch,
  };
};

export default useEmployees;
