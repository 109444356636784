import type { PermissionsGroupDataFragment } from 'apollo/graphql.types';
import type { PermissionsStateVarData } from 'apollo/reactive/permissionsState';

export type PermissionsFormFields = PermissionsStateVarData & {
  name: string;
};

export const normalizePermissions = (
  input: PermissionsGroupDataFragment | null,
) => ({
  id: input?.id || '',
  name: input?.name || '',

  // Permissions
  // Products
  allowCreateProducts: input?.allowCreateProducts || false,
  allowEditProducts: input?.allowEditProducts || false,
  allowAdjustProducts: input?.allowAdjustProducts || false,
  allowShowAdjustProducts: input?.allowShowAdjustProducts || false,
  allowRemoveProducts: input?.allowRemoveProducts || false,

  // Partners
  allowCreatePartners: input?.allowCreatePartners || false,
  allowEditPartners: input?.allowEditPartners || false,
  allowRemovePartners: input?.allowRemovePartners || false,

  // Expenses
  allowShowExpenses: input?.allowShowExpenses || false,
  allowCreateExpenses: input?.allowCreateExpenses || false,
  allowEditExpenses: input?.allowEditExpenses || false,
  allowRemoveExpenses: input?.allowRemoveExpenses || false,

  // Reports
  allowShowReports: input?.allowShowReports || false,

  // Metrics
  allowShowMetrics: input?.allowShowMetrics || false,

  // Cashier
  allowShowCashier: input?.allowShowCashier || false,
  allowShowCashierOneDay: input?.allowShowCashierOneDay || false,
  allowDownloadCashierData: input?.allowDownloadCashierData || false,
  allowShowProductsCashier: input?.allowShowProductsCashier || false,
  allowShowPartnersCashier: input?.allowShowPartnersCashier || false,

  // Calendar
  allowShowCalendars: input?.allowShowCalendars || false,
  allowCreateCalendars: input?.allowCreateCalendars || false,
  allowEditCalendars: input?.allowEditCalendars || false,
  allowRemoveCalendars: input?.allowRemoveCalendars || false,

  // Files
  allowShowFiles: input?.allowShowFiles || false,
  allowCreateFiles: input?.allowCreateFiles || false,
  allowRemoveFiles: input?.allowRemoveFiles || false,

  // Providers
  allowShowProviders: input?.allowShowProviders || false,
  allowCreateProviders: input?.allowCreateProviders || false,
  allowEditProviders: input?.allowEditProviders || false,
  allowRemoveProviders: input?.allowRemoveProviders || false,

  // Discounts
  allowShowDiscounts: input?.allowShowDiscounts || false,
  allowCreateDiscounts: input?.allowCreateDiscounts || false,
  allowAssignDiscounts: input?.allowAssignDiscounts || false,
  allowEditDiscounts: input?.allowEditDiscounts || false,
  allowRemoveDiscounts: input?.allowRemoveDiscounts || false,

  // Quotas
  allowShowQuotas: input?.allowShowQuotas || false,
  allowCreateQuotas: input?.allowCreateQuotas || false,
  allowAssignQuotas: input?.allowAssignQuotas || false,
  allowEditQuotas: input?.allowEditQuotas || false,
  allowRemoveQuotas: input?.allowRemoveQuotas || false,

  // Credits concepts
  allowShowCreditsConcept: input?.allowShowCreditsConcept || false,
  allowCreateCreditsConcept: input?.allowCreateCreditsConcept || false,
  allowEditCreditsConcept: input?.allowEditCreditsConcept || false,
  allowRemoveCreditsConcept: input?.allowRemoveCreditsConcept || false,

  // Categories
  allowCreateCategories: input?.allowCreateCategories || false,
  allowEditCategories: input?.allowEditCategories || false,
  allowRemoveCategories: input?.allowRemoveCategories || false,

  // Contract
  allowShowContract: input?.allowShowContract || false,
  allowEditContract: input?.allowEditContract || false,
  allowAssignContract: input?.allowAssignContract || false,

  // Organization
  allowShowTransactionsHistory: input?.allowShowTransactionsHistory || false,
});
