import type { FC } from 'react';
import {
  LineChart as LineChartComp,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import { Container, ResponsiveContainer, Header, Title } from './styles';
import type { Props } from './types';

const LineChart: FC<Props> = ({ data, title, labelProfits, labelCosts }) => (
  <Container>
    <Header>
      <Title>{title}</Title>
    </Header>
    <ResponsiveContainer width="100%" height="100%">
      <LineChartComp
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={labelProfits}
          stroke="#0074CA"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey={labelCosts} stroke="#E5AA22" />
      </LineChartComp>
    </ResponsiveContainer>
  </Container>
);

export default LineChart;
