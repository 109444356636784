import { FC, memo } from 'react';
import { Container, Track } from './styles';
import { Props } from './types';

const ProgressBar: FC<Props> = ({ className, completedPercent }) => (
  <Container className={className}>
    <Track $percent={completedPercent} />
  </Container>
);

export default memo(ProgressBar);
