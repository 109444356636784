import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  Benefit,
  Container,
  Costs,
  Discounts,
  Name,
  Purchases,
  Quantity,
  RemainingQuantity,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <Name>Productos</Name>
      {fromTabletPortrait && <Quantity>Cantidad</Quantity>}
      <Discounts>Descuento</Discounts>
      <Purchases>Ventas</Purchases>
      <Costs>Gastos</Costs>
      <Benefit>Ganancias</Benefit>
      {fromTabletPortrait && (
        <RemainingQuantity>Cantidad restante</RemainingQuantity>
      )}
    </Container>
  );
};

export default memo(Row);
