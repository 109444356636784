import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetPartners } from 'apollo/generated/GetPartners';
import { GET_PARTNERS_TO_LIST } from 'apollo/queries/partners';
import { useMemo } from 'react';
import notEmpty from 'utils/notEmpty';
import { normalizePartnerToList } from 'model/Partner';

const usePartners = ({
  fetchPolicy = 'cache-and-network',
}: {
  fetchPolicy?: QueryHookOptions<GetPartners>['fetchPolicy'];
} = {}) => {
  const { loading, data, refetch } = useQuery<GetPartners>(
    GET_PARTNERS_TO_LIST,
    {
      fetchPolicy,
    },
  );

  const { partners } = useMemo(
    () => ({
      partners: data?.partners
        ? data.partners.filter(notEmpty).map(normalizePartnerToList)
        : [],
    }),
    [data],
  );

  return {
    partners,
    loading,
    refetch,
  };
};

export default usePartners;

export type UserPartner = ReturnType<typeof usePartners>;
