import { FC, memo } from 'react';
import Price from 'components/Price';
import { Billing, Container, Name, Position } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, name, position, billing }) => {
  return (
    <Container className={className}>
      <Position>{position}</Position>
      <Name>{name}</Name>
      <Billing>
        <Price>{billing}</Price>
      </Billing>
    </Container>
  );
};

export default memo(Row);
