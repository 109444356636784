import { useCallback } from 'react';
import useModal from 'apollo/hooks/useModal';
import type { PartnerNote } from 'model/Partner';
import type { Props } from './types';

const useConnect = ({ partnerId }: { partnerId: Props['partnerId'] }) => {
  const { openPartnerNote } = useModal();

  const handleOpenNoteDetailsModal = useCallback(
    async (note: PartnerNote) => {
      if (partnerId) {
        openPartnerNote({
          note,
          partnerId,
        });
      }
    },
    [openPartnerNote, partnerId],
  );

  const handleOpenNewNoteModal = useCallback(async () => {
    if (partnerId) {
      openPartnerNote({
        partnerId,
      });
    }
  }, [openPartnerNote, partnerId]);

  return {
    handleOpenNoteDetailsModal,
    handleOpenNewNoteModal,
  };
};

export default useConnect;
