import { makeVar } from '@apollo/client';

export type SubscriptionStateVarData = {
  id: string;
  allowedCashier: boolean;
  allowedDiscounts: boolean;
  allowedEmployees: boolean;
  allowedExpenses: boolean;
  allowedGenerateFiles: boolean;
  allowedMetrics: boolean;
  allowedOrders: boolean;
  allowedPartnerTransactions: boolean;
  allowedProductTransactions: boolean;
  allowedReports: boolean;
  description: string;
  maxEmployees: number;
  maxPartners: number;
  maxProducts: number;
  maxUserFiles: number;
  name: string;
  price: number;
};

export const emptySubscriptionState: SubscriptionStateVarData = {
  id: '',
  allowedCashier: false,
  allowedDiscounts: false,
  allowedEmployees: false,
  allowedExpenses: false,
  allowedGenerateFiles: false,
  allowedMetrics: false,
  allowedOrders: false,
  allowedPartnerTransactions: false,
  allowedProductTransactions: false,
  allowedReports: false,
  description: '',
  maxEmployees: 0,
  maxPartners: 0,
  maxProducts: 0,
  maxUserFiles: 0,
  name: '',
  price: 0,
};

export const subscriptionStateVar = makeVar<SubscriptionStateVarData>(
  emptySubscriptionState,
);

export const resetSubscriptionStateVar = () => {
  subscriptionStateVar(emptySubscriptionState);
};

export const setSubscriptionStateVar = (
  data: Partial<SubscriptionStateVarData>,
) => {
  subscriptionStateVar({ ...emptySubscriptionState, ...data });
};
