import { memo, FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import {
  Benefit,
  Container,
  Costs,
  Discounts,
  Name,
  Purchases,
  Quantity,
  RemainingQuantity,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, item }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const { totalPurchases, totalCosts, totalBenefit, totalDiscounts } = item;

  return (
    <Container className={className}>
      <Name>TOTAL</Name>
      {fromTabletPortrait && <Quantity />}
      <Purchases>
        <Price>{totalPurchases}</Price>
      </Purchases>
      <Costs>
        {totalCosts > 0 && '-'}
        <Price>{totalCosts}</Price>
      </Costs>
      {fromTabletPortrait && (
        <Discounts>
          {totalDiscounts > 0 && '-'}
          <Price>{totalDiscounts}</Price>
        </Discounts>
      )}
      <Benefit $isNegative={totalBenefit < 0}>
        <Price>{totalBenefit}</Price>
      </Benefit>
      <RemainingQuantity />
    </Container>
  );
};

export default memo(Row);
