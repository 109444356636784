import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useMenuData from 'apollo/hooks/organization/useMenuData';

const useConnect = () => {
  const navigate = useNavigate();
  const { menuCode } = useParams<{ menuCode: string }>();
  const { menuData, loading } = useMenuData(menuCode);

  useEffect(() => {
    if ((!loading && !menuData) || (menuData && !menuData?.menuActive)) {
      navigate('/not-found');
    }
  }, [navigate, menuData, loading]);

  return {
    menuData,
    loading,
  };
};

export default useConnect;
