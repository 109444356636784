import useMe from 'apollo/hooks/user/useMe';

const useConnect = () => {
  const { data } = useMe();

  return {
    avatarUrl: data?.avatarImageUrl,
    fullName: data?.fullName,
    email: data?.email,
  };
};

export default useConnect;
