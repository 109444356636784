import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import useExpense from 'apollo/hooks/expense/useExpense';
import useExpenseActions from 'apollo/hooks/expense/useExpenseActions';
import useCategories from 'apollo/hooks/category/useCategories';
import { CategoryType } from 'apollo/generated/globalTypes';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import { formatErrors } from 'utils/errors/formatErrors';
import type { ExpenseFormFields } from 'model/Expense';

export const useConnect = () => {
  const { expenseId } = useParams<{ expenseId: string }>();
  const { expense, loading: getExpenseLoading } = useExpense({ id: expenseId });
  const { categories } = useCategories({
    filter: { type: CategoryType.EXPENSES },
  });
  const { deleteExpense, loading: expenseActionsLoading } = useExpenseActions();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const initialValues = useMemo<ExpenseFormFields>(() => {
    const mappedCategories = removeOptionalsFromArray(
      expense?.categories ?? [],
    ).map((c) => c.id);

    return {
      categoryIds: mappedCategories,
      description: expense?.description || '',
      id: expense?.id || '',
      name: expense?.name || '',
      price: expense?.originalPrice || 0,
      providerId: expense?.provider?.id || '',
      quantity: expense?.quantity || 0,
      taxes: expense?.taxes || 0,
    };
  }, [expense]);

  const handleOnSubmit = useCallback(async (values: ExpenseFormFields) => {
    console.log(values);
  }, []);

  const handleOnDelete = useCallback(async () => {
    try {
      if (expenseId) {
        await deleteExpense(expenseId);
        navigate('/expenses');
        enqueueSnackbar('El gasto se ha eliminado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('expense', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [deleteExpense, expenseId, navigate, enqueueSnackbar]);

  const getSelectedCategories = useCallback(() => {
    const selectedCategories = categories.filter((category) =>
      initialValues?.categoryIds.includes(category.id),
    );

    return selectedCategories.map((cat) => cat.name).join(', ') || '';
  }, [categories, initialValues?.categoryIds]);

  return {
    getSelectedCategories,
    initialValues,
    isLoading: getExpenseLoading || expenseActionsLoading,
    handleOnSubmit,
    handleOnDelete,
  };
};
