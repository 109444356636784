import styled from 'styled-components';
import DefaultButton from 'components/Button';

export const Container = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  border-radius: 0.25rem;
  display: flex;
  flex-flow: column nowrap;
  max-width: 10rem;
  padding: 1rem;
`;

export const Logo = styled.img`
  max-width: 5rem;
  max-height: 4rem;
  margin-bottom: 1rem;
`;

export const Button = styled(DefaultButton)``;
