import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import { Container, Name, Number } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  const isTabletLandscape = !useMediaQuery(from.tabletLandscape.query);

  return (
    <Container className={className}>
      <Number>Nº{!isTabletLandscape ? ' de socio' : ''}</Number>
      <Name>Nombre</Name>
    </Container>
  );
};

export default memo(Row);
