import styled from 'styled-components';
import { SEARCH_HEIGHT } from 'components/Search/styles';
import {
  HEADER_CHILDREN_MARGIN,
  HEADER_HEIGHT,
} from 'containers/Common/Header';
import { from } from 'styles/responsive';
import { SectionContainer } from 'components/Layout';
import DefaultChart from './Chart';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  padding-top: calc(
    ${HEADER_HEIGHT} + ${HEADER_CHILDREN_MARGIN} + ${SEARCH_HEIGHT}
  );
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 4rem;
  `};
`;
export const Chart = styled(DefaultChart)``;

export const Charts = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey100};
  border-top: solid 1px ${({ theme }) => theme.colors.grey100};
  display: flex;
  flex-flow: column;
  padding: 2rem 0;

  > *:first-child {
    margin-right: 1rem;
  }

  ${from.tabletPortrait`
    align-items: center;
    flex-flow: row;
    justify-content: space-between;
  `};
`;

export const LineCharts = styled.div`
  display: flex;
  flex-flow: column;
  padding-bottom: 2rem;
`;
