import styled from 'styled-components';
import { ResponsiveContainer as DefaultResponsiveContainer } from 'recharts';

export const Container = styled.div`
  cursor: pointer;
  width: 100%;
  height: 15rem;
  padding-bottom: 2rem;
`;

export const ResponsiveContainer = styled(DefaultResponsiveContainer)`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey100};
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: space-between;
  transition: color 0.3s ease;
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.weights.light};
`;
