import { gql } from '@apollo/client';
import { BOOKING_FRAGMENT } from 'apollo/fragments/Booking';

export const CREATE_BOOKING = gql`
  mutation CreateBooking($data: CreateBookingDto!) {
    createBooking(data: $data) {
      ...BookingData
    }
  }
  ${BOOKING_FRAGMENT}
`;

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking($id: ID!, $data: UpdateBookingDto!) {
    updateBooking(id: $id, data: $data) {
      ...BookingData
    }
  }
  ${BOOKING_FRAGMENT}
`;

export const REMOVE_BOOKING = gql`
  mutation RemoveBooking($id: ID!) {
    removeBooking(id: $id)
  }
`;
