import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useSnackbar } from 'notistack';
import { FileRejection } from 'react-dropzone';
import { useField } from 'formik';
import useImageSignedUrlActions from 'apollo/hooks/upload/useImageSignedUrlActions';
import useDimensions from 'utils/useDimensions';
import { Dimensions, LogicProps } from './types';

const useLogic = ({
  acceptedInputFiles,
  category,
  extraCategory,
  fileNameRegExp,
  imageIdFieldName,
  imageSize,
  maxSize,
  name,
  ratio,
  uploadingFlagName,
  uploadValidator,
}: LogicProps) => {
  const [
    { value: imgUrl },
    { error: imgUrlFieldError, touched: touchedUrl },
    { setValue: setUrlValue },
  ] = useField<string>(name);
  const [
    ,
    { error: imageIdFieldError, touched: touchedImageId },
    { setValue: setIdValue },
  ] = useField<string | null>(imageIdFieldName);
  const [, , { setValue: setIsUploading }] =
    useField<boolean>(uploadingFlagName);
  const { dimensions: contentDimensions, ref: contentRef } = useDimensions();
  const { uploadImage } = useImageSignedUrlActions();
  const { enqueueSnackbar } = useSnackbar();

  const [dimensions, setDimensions] = useState<Dimensions | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [progress, setProgress] = useState<number | undefined>(undefined);

  const cancelSourceRef = useRef<CancelTokenSource | null>(null);

  const contentWidth = contentDimensions?.width;

  const contentHeight = useMemo(() => {
    if (!contentWidth) return;
    return contentWidth / ratio;
  }, [contentWidth, ratio]);

  const imageInfo = useMemo(() => {
    let value = ``;
    if (dimensions) {
      value = `${dimensions.width}x${dimensions.height} px`;
    }

    if (dimensions && imageSize) {
      value = `${value} · `;
    }

    if (imageSize) {
      value = `${value}${(imageSize / 1000).toFixed(0)} kB`;
    }

    return value;
  }, [dimensions, imageSize]);

  const getAndSetDimensions = useCallback((imageSrc: string) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const imageDimensions = {
        height: image.naturalHeight,
        width: image.naturalWidth,
      };
      setDimensions(imageDimensions);
    };
  }, []);

  useEffect(() => {
    if (imgUrl) {
      getAndSetDimensions(imgUrl);
    }
  }, [imgUrl, getAndSetDimensions]);

  useEffect(() => {
    return () => {
      cancelSourceRef.current?.cancel();
    };
  }, []);

  const handleAccept = useCallback(
    async (files: File[]) => {
      setIsUploading(true);
      const imageFile = files[0];
      try {
        const fileName = imageFile.name.replace(/\.(jpg|jpeg|png)$/, '');

        if (fileNameRegExp && !fileNameRegExp.test(fileName)) {
          throw new Error('Invalid file name');
        }

        setUrlValue('');
        setIdValue('');

        let uploadData;
        try {
          cancelSourceRef.current = axios.CancelToken.source();
          uploadData = await uploadImage({
            file: imageFile,
            setProgress,
            category,
            extraCategory,
            cancelToken: cancelSourceRef.current?.token,
          });
          if (uploadValidator) uploadValidator(fileName);
          enqueueSnackbar(
            'La imagen se ha subido: Pulsa guardar para aplicar los cambios.',
            { variant: 'info' },
          );
        } catch (error) {
          const isCancel = error?.message === 'The upload has been cancelled';
          enqueueSnackbar(
            error?.message || `There has been an error uploading the image`,
            { variant: isCancel ? 'info' : 'error' },
          );
        }

        if (!uploadData?.url) {
          throw new Error();
        }

        setUrlValue(uploadData.url);
        setIdValue(uploadData.id);
        setIsUploading(false);
        setErrorMessage(undefined);
      } catch (error) {
        setIsUploading(false);
        setErrorMessage(
          error?.message || 'An error has occurred, please try again',
        );
      }
    },
    [
      setIsUploading,
      fileNameRegExp,
      setUrlValue,
      setIdValue,
      uploadImage,
      category,
      extraCategory,
      uploadValidator,
      enqueueSnackbar,
    ],
  );

  const handleReject = useCallback(
    (fileRejections: FileRejection[]) => {
      const rejectedFile = fileRejections[0].file;
      if (!acceptedInputFiles.includes(rejectedFile?.type)) {
        setErrorMessage('File type is invalid.');
        return;
      }
      if (rejectedFile.size > maxSize) {
        setErrorMessage(
          maxSize < 1000000
            ? `File size cannot exceed ${maxSize / 1000} KB.`
            : `File size cannot exceed ${maxSize / 1000000} MB.`,
        );
      }
    },
    [acceptedInputFiles, maxSize],
  );

  const handleReset = useCallback(() => {
    setDimensions(undefined);
    setUrlValue('');
    setIdValue(null);
    setErrorMessage(undefined);
  }, [setUrlValue, setIdValue]);

  return {
    contentHeight,
    contentRef,
    errorMessage:
      (touchedUrl && imgUrlFieldError) ||
      (touchedImageId && imageIdFieldError) ||
      errorMessage,
    handleAccept,
    handleReject,
    handleReset,
    imageInfo,
    imgUrl,
    progress,
  };
};

export default useLogic;
