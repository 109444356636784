import styled from 'styled-components';

export const Content = styled.div`
  padding-bottom: 8rem;
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
`;

export const Document = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

export const DocumentTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
`;
