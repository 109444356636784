import { FC, memo } from 'react';

import type { Props } from './types';

const OrdersSelected: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      transform="translate(5 3)"
      stroke="#0A2737"
      fill="none"
      fillRule="evenodd"
    >
      <rect fill="#0A2737" x=".5" y="2.5" width="13" height="13" rx="1.5" />
      <path d="M9.5.5a.997.997 0 011 1h0v1h-7v-1a.997.997 0 011-1h0z" />
    </g>
  </svg>
);

export default memo(OrdersSelected);
