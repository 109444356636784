import { apolloClient } from 'services/api';
import { PARTNER_FRAGMENT } from 'apollo/fragments/Partner';
import { PartnerData } from 'apollo/generated/PartnerData';

function cacheUpdatePartnerFragment(
  id: PartnerData['id'],
  data: Partial<PartnerData>,
) {
  const key = `Partner:${id}`;

  const cacheData = apolloClient.readFragment<PartnerData>({
    fragment: PARTNER_FRAGMENT,
    fragmentName: 'PartnerData',
    id: key,
  });

  if (cacheData) {
    apolloClient.writeFragment<PartnerData>({
      fragment: PARTNER_FRAGMENT,
      fragmentName: 'PartnerData',
      id: key,
      data: {
        ...cacheData,
        ...data,
      },
    });
  }
}

export default cacheUpdatePartnerFragment;
