import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled.div``;

export const Section = styled.section`
  margin-bottom: 1.5rem;
`;

export const SectionTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  ${from.tabletPortrait`
    margin-top: 0;
  `};
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 1rem;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 1rem;
`;

export const Button = styled(DefaultButton)`
  &&& {
    padding: 0.3rem 0.6rem;
  }
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 1rem auto 0 auto;
`;
