import { FC, memo } from 'react';

import type { Props } from './types';

const Event: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={size}
    height={(size * 10) / 7}
    {...rest}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m16.17 25.86-5.36-5.36a1 1 0 0 1 1.41-1.41L16.17 23l8.64-8.64a1 1 0 0 1 1.41 1.41z"
      className="clr-i-outline clr-i-outline-path-1"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M32.25 6H29v2h3v22H4V8h3V6H3.75A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6z"
      className="clr-i-outline clr-i-outline-path-2"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1z"
      className="clr-i-outline clr-i-outline-path-3"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1z"
      className="clr-i-outline clr-i-outline-path-4"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M13 6h10v2H13z"
      className="clr-i-outline clr-i-outline-path-5"
    />
    <path xmlns="http://www.w3.org/2000/svg" fill="none" d="M0 0h36v36H0z" />
  </svg>
);

export default memo(Event);
