import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Header, Icon, IconCircle, Title } from './styles';

const UpdatePasswordForm: FC = () => {
  const { handleClose, isOpen, isLoading, handleSubmit } = useConnect();

  return (
    <Container
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={handleClose}
    >
      <Header>
        <IconCircle>
          <Icon name="password" />
        </IconCircle>
        <Title>Actualizar contraseña</Title>
      </Header>

      <Form
        onSubmit={handleSubmit}
        onCancel={handleClose}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default UpdatePasswordForm;
