import DefaultAvatar from 'components/Avatar';
import styled from 'styled-components';
import { linkStyle } from 'components/Links/styles';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const Avatar = styled(DefaultAvatar)`
  height: 6rem;
  width: 6rem;
  font-size: 2.5rem;
`;

export const ChangeAvatar = styled.span`
  ${linkStyle};
  color: ${({ theme }) => theme.colors.accent};
  font-size: 0.65rem;
  margin-top: 0.4rem;
`;
