import { gql } from '@apollo/client';
import { QUOTA_FRAGMENT } from 'apollo/fragments/Quota';

export const GET_QUOTAS = gql`
  query GetQuotas {
    quotas {
      ...QuotaData
    }
  }
  ${QUOTA_FRAGMENT}
`;

export const GET_QUOTA = gql`
  query GetQuota($id: ID!) {
    quota(id: $id) {
      ...QuotaData
    }
  }
  ${QUOTA_FRAGMENT}
`;
