import { QueryHookOptions, useQuery } from '@apollo/client';
import type {
  GetCategories,
  GetCategoriesVariables,
} from 'apollo/generated/GetCategories';
import { GET_CATEGORIES } from 'apollo/queries/categories';
import { useMemo } from 'react';
import notEmpty from 'utils/notEmpty';
import { normalizeCategory } from 'model/Category';
import { CategoryFilterDto } from 'apollo/generated/globalTypes';

const useCategories = ({
  filter,
  fetchPolicy = 'cache-first',
}: {
  filter?: CategoryFilterDto;
  fetchPolicy?: QueryHookOptions<GetCategoriesVariables>['fetchPolicy'];
} = {}) => {
  const { loading, data, refetch } = useQuery<
    GetCategories,
    GetCategoriesVariables
  >(GET_CATEGORIES, { fetchPolicy, variables: { filter } });

  const { categories } = useMemo(
    () => ({
      categories: data?.categories
        ? data.categories.filter(notEmpty).map(normalizeCategory)
        : [],
    }),
    [data],
  );

  return {
    loading,
    categories,
    refetch,
  };
};

export default useCategories;
