import styled from 'styled-components';
import { Form } from 'formik';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultTextField from '../../../components/Inputs/TextField';

export const Container = styled(DefaultModal)`
  ${Content} {
    display: flex;
    flex-direction: column;

    ${from.tabletPortrait`
      max-width: 55rem;
    `}
  }
`;

export const HeaderContainer = styled.div`
  flex: 0 1 auto;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
`;

export const Title = styled(ModalTitle)`
  text-transform: capitalize;
  margin: 1rem auto 0.5rem;
`;

export const Label = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.primary};
`;

export const IsDeletedFlag = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const FormContainer = styled(Form)`
  flex: 1 1 auto;
  padding: 1.5rem 0;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
`;

export const TextField = styled(DefaultTextField)`
  margin-top: 1rem;

  .MuiFormLabel-root {
    color: ${({ theme }) => theme.colors.accent} !important;
  }

  ${from.tabletPortrait`
    margin-top: 0;
  `}
`;

export const Row = styled.div`
  display: block;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${from.tabletLandscape`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;
