import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultIcon from 'components/Icon';

export const Container = styled(Form)``;

export const Row = styled.div`
  margin-bottom: 1rem;

  display: flex;
  > * {
    flex: 0 0 50%;
    width: 50%;

    &:not(:last-child) {
      padding-right: 0.5rem;
    }
    &:not(:first-child) {
      padding-left: 0.5rem;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2rem;

  ${from.tabletPortrait`
    flex-flow: row-reverse nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const CreateButton = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-left: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))``;

export const TextField = styled(DefaultTextField)`
  margin-top: 1rem;
  ${from.tabletPortrait`
    margin-top: 0;
  `}
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 0.9rem;
  font-weight: 700;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const SaveMessage = styled.div`
  margin: 1rem 0;
`;

export const SaveMessageText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  font-weight: 600;
`;

export const SaveMessageNumber = styled.span<{ $isNegative: boolean }>`
  color: ${({ theme, $isNegative }) =>
    $isNegative ? theme.colors.danger : theme.colors.green};
  font-size: 1.1rem;
`;

export const EmptyDiv = styled.div`
  width: 100%;
`;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.grey500};
  opacity: 0.7;
  width: 0.5rem;
`;

const activeStatus = css`
  border-bottom-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.accent};
  ${Icon} {
    color: ${({ theme }) => theme.colors.accent};
    opacity: 1;
  }
`;

export const SelectTypeOfCash = styled.div<{ $hasTypeOfCash: boolean }>`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}0a;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem 0;
  transition: all 300ms ease;

  &:hover {
    ${activeStatus}
  }

  ${({ $hasTypeOfCash }) =>
    $hasTypeOfCash &&
    css`
      ${activeStatus}
    `}
`;

export const SelectedCash = styled.h3`
  font-size: 1rem;
  line-height: 1.4;
`;
