import { FC, memo } from 'react';
import Price from 'components/Price';
import useLogic from './logic';
import * as S from './styles';
import type { Props } from './types';

const SelectableView: FC<Props> = ({ menuData, useLogoBackground }) => {
  const { selectedCategory, handleOnChangeCategory, products, defaultImage } =
    useLogic(menuData);
  const hasLogo =
    !useLogoBackground && menuData?.showLogo && menuData.organizationLogo;

  return (
    <>
      <S.Header
        $useLogoBackground={useLogoBackground}
        $backgroundColor={menuData?.backgroundColor}
      >
        {hasLogo && (
          <S.OrganizationLogo>
            <S.Logo src={menuData.organizationLogo || ''} />
          </S.OrganizationLogo>
        )}
        <S.Categories>
          <>
            <S.Category
              $selected={selectedCategory === 'all'}
              $color={menuData?.categoriesColor}
              onClick={() => handleOnChangeCategory('all')}
            >
              Todo
            </S.Category>
            {menuData?.categoriesNames.map((catName) => (
              <S.Category
                $selected={selectedCategory === catName}
                $color={menuData?.categoriesColor}
                key={catName}
                onClick={() => handleOnChangeCategory(catName)}
              >
                {catName}
              </S.Category>
            ))}
          </>
        </S.Categories>
      </S.Header>
      <S.Content>
        <S.Products>
          {products.map((product) => (
            <S.Product>
              <S.ProductImage $color={menuData?.imageBorderColor}>
                <S.Image src={product.imageUrl || defaultImage} />
              </S.ProductImage>
              <S.ProductData
                $useLogoBackground={useLogoBackground}
                $backgroundColor={menuData?.backgroundColor}
              >
                <S.ProductTitleContent>
                  <S.ProductTitle $color={menuData?.productTitleColor}>
                    {product.title}
                    {product.referenceCode && (
                      <S.ProductReference>
                        #{product.referenceCode}
                      </S.ProductReference>
                    )}
                  </S.ProductTitle>
                  {menuData?.showProductPrice && (
                    <>
                      {menuData?.showProductDiscount && product.hasDiscount ? (
                        <S.ProductPriceContent>
                          <S.OriginalPrice>
                            <Price showIcon={menuData?.showPriceSimbol}>
                              {product.price}
                            </Price>
                          </S.OriginalPrice>
                          <S.ProductPrice $color={menuData?.productPriceColor}>
                            <Price showIcon={menuData?.showPriceSimbol}>
                              {product.priceWithDiscount}
                            </Price>
                          </S.ProductPrice>
                        </S.ProductPriceContent>
                      ) : (
                        <S.ProductPrice $color={menuData?.productPriceColor}>
                          <Price showIcon={menuData?.showPriceSimbol}>
                            {product.price}
                          </Price>
                        </S.ProductPrice>
                      )}
                    </>
                  )}
                </S.ProductTitleContent>
                {menuData?.showProductDescription && (
                  <S.ProductDescription
                    $color={menuData?.productDescriptionColor}
                  >
                    {product.description}
                  </S.ProductDescription>
                )}
              </S.ProductData>
            </S.Product>
          ))}
        </S.Products>
      </S.Content>
    </>
  );
};

export default memo(SelectableView);
