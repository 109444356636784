import styled from 'styled-components';
import { Form } from 'formik';
import DefaultButton from 'components/Button';
import { from } from 'styles/responsive';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultSelect from 'components/Inputs/Select';

export const Container = styled(Form)`
  margin-bottom: 2rem;

  ${from.tabletLandscape`
    margin-bottom: 0;
  `};
`;

export const TextField = styled(DefaultTextField)`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${from.tabletPortrait`
    margin-bottom: 0;
  `}
`;

export const Row = styled.div`
  display: block;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${from.tabletLandscape`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;

export const SubmitButton = styled(DefaultButton)`
  width: 100%;

  ${from.tabletLandscape`
    float: right;
    width: unset;
  `}
`;

export const Select = styled(DefaultSelect)`
  margin-bottom: 1rem;
`;
