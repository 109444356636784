import { FC, memo } from 'react';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { Container, EmptyState, Main, DateTimeFilterContainer } from './styles';

const AdjustsHistory: FC = () => {
  const {
    adjustHistories,
    isLoading,
    handleOnSearch,
    handleOpenDetailsModal,
    filterDates,
  } = useConnect();

  const hasResults = adjustHistories?.length > 0;

  return (
    <Container>
      <DateTimeFilterContainer>
        <DateTimeFilter
          fromDate={filterDates.from}
          toDate={filterDates.to}
          onSearch={handleOnSearch}
        />
      </DateTimeFilterContainer>
      {isLoading && <Loader />}
      {!isLoading && (
        <Main>
          {!hasResults && (
            <EmptyState
              title="No se han realizado ajustes en este producto"
              iconName="products"
            >
              No hay datos de ajustes en estas fechas
            </EmptyState>
          )}
          {hasResults && (
            <>
              <TableHead />
              {adjustHistories.map((adjustHistory) => (
                <Row
                  key={adjustHistory.id}
                  adjustHistory={adjustHistory}
                  onClick={handleOpenDetailsModal}
                />
              ))}
            </>
          )}
        </Main>
      )}
    </Container>
  );
};

export default memo(AdjustsHistory);
