import type { FC } from 'react';
import { memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import useConnect from './connect';
import {
  Container,
  ClearButton,
  Error,
  Icon,
  InputContainer,
  MuiTextField,
  IconContainer,
  Label,
} from './styles';
import type { Props } from './types';

const TextField: FC<Props> = ({
  autoComplete,
  autoFocus,
  className,
  disabled,
  icon,
  iconSize = 16,
  iconColor,
  id,
  isClearable = false,
  label,
  maxLength,
  name,
  normalize,
  placeholder,
  readOnly,
  ref,
  type,
  value,
  onIconClick,
  onClick,
  onLocalChange,
  min,
  max,
  isMultiline,
  titleLabel,
  rows,
  variant = 'outlined',
}) => {
  const { error, field, handleChange, handleClear, inputRef, touched } =
    useConnect({
      name,
      normalize,
      ref,
      onLocalChange,
    });

  const showIcon = icon && (!isClearable || (isClearable && !field.value));

  return (
    <Container className={className} onClick={onClick}>
      {titleLabel && <Label>{titleLabel}</Label>}
      <InputContainer>
        <MuiTextField
          $hasIcon={isClearable || !!showIcon}
          $isHidden={type === 'hidden'}
          $readOnly={readOnly}
          $isClickable={!!onClick}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          error={touched && !!error}
          fullWidth
          helperText={touched && error}
          hiddenLabel={!label}
          id={id}
          InputProps={{
            readOnly,
            inputProps: { maxLength, min, max },
          }}
          inputRef={inputRef}
          placeholder={placeholder}
          label={label}
          type={type}
          variant={variant}
          {...field}
          onChange={handleChange}
          value={value || field.value}
          multiline={isMultiline}
          rows={rows}
        />
        {isClearable && !!field.value && (
          <ClearButton type="button" onClick={handleClear}>
            <Icon name="close" size={iconSize} />
          </ClearButton>
        )}
        {showIcon && (
          <IconContainer
            $disabled={!!disabled}
            $hasValue={!!field.value && !!label}
            $isClickable={!!onIconClick && !disabled}
            onClick={onIconClick}
          >
            <Icon
              $color={iconColor}
              $disabled={!!disabled}
              name={icon}
              size={iconSize}
            />
          </IconContainer>
        )}
      </InputContainer>
      <AnimatePresence initial={false}>
        {touched && error && <Error>{error}</Error>}
      </AnimatePresence>
    </Container>
  );
};

export default memo(TextField);
