import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ApiConfig } from 'config';
import useModal from 'apollo/hooks/useModal';
import useMe from 'apollo/hooks/user/useMe';
import useOrganizationActions from 'apollo/hooks/organization/useOrganizationActions';
import { formatErrors } from 'utils/errors/formatErrors';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { ProductsListType } from 'apollo/generated/globalTypes';
import type { FormFields, ConfigFormFields } from 'model/Organization';
import { updateOrganizationConfigVar } from '../../../../apollo/reactive';

const useConnect = () => {
  const {
    data: me,
    loading: getMeLoading,
    refetch,
  } = useMe({
    fetchPolicy: 'cache-first',
  });
  const { openDialog } = useModal();
  const [searchParams] = useSearchParams();
  const {
    setOrganizationConfig,
    removeDriveIntegration,
    desactivateOrganization,
    loading: setOrganizationConfigLoading,
  } = useOrganizationActions();
  const { enqueueSnackbar } = useSnackbar();

  const { organization } = me || {};

  const initialValues = useMemo<ConfigFormFields>(
    () => ({
      allowNegativeCredits: organization?.config?.allowNegativeCredits || false,
      allowExtraQuantity: organization?.config?.allowExtraQuantity || false,
      activateRfidReader: organization?.config?.activateRfidReader || false,
      activateProductReference:
        organization?.config?.activateProductReference || false,
      forceOpenCashier: organization?.config?.forceOpenCashier || false,
      activeStrictCashier: organization?.config?.activeStrictCashier || false,
      deleteExpiredPartner: organization?.config?.deleteExpiredPartner || false,
      maxExtraQuantity: organization?.config?.maxExtraQuantity || '',
      daysToRemoveExpiredPartner:
        organization?.config?.daysToRemoveExpiredPartner || 0,
      reasignReleasedPartnerNumber:
        organization?.config?.reasignReleasedPartnerNumber || false,
      productsListType:
        organization?.config?.productsListType || ProductsListType.LANDSCAPE,
    }),
    [organization],
  );

  useEffect(() => {
    const socialLogin = searchParams.get('socialLogin');
    const hasError = searchParams.get('hasError');
    if (socialLogin && !hasError) {
      enqueueSnackbar('Se ha conectado correctamente', {
        variant: 'success',
      });
    } else if (hasError) {
      enqueueSnackbar('Ha ocurrido un error en la integración', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, searchParams]);

  const handleSubmit = useCallback(
    async (values: FormFields | ConfigFormFields) => {
      const configValues = values as ConfigFormFields;
      if (configValues?.daysToRemoveExpiredPartner !== undefined) {
        try {
          await setOrganizationConfig(configValues);
          updateOrganizationConfigVar(configValues);
          enqueueSnackbar('La configuración se ha actualizado correctamente', {
            variant: 'success',
          });
        } catch (e) {
          enqueueSnackbar(
            formatErrors('organization', e.message, 'actualizar'),
            {
              variant: 'error',
              action: () => <NotifySnackbarErrorButton error={e} />,
            },
          );
        }
      }
    },
    [enqueueSnackbar, setOrganizationConfig],
  );

  const handleSubmitPin = useCallback(
    async (values: { currentPin: string; newPin: string; newPin2: string }) => {
      try {
        await setOrganizationConfig({
          ...initialValues,
          blockContentPin: {
            newPin: values.newPin,
            currentPin: values.currentPin,
          },
        });
        enqueueSnackbar('El pin se ha actualizado correctamente', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('organization', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, initialValues, setOrganizationConfig],
  );

  const handleDesactivateOrganization = useCallback(() => {
    openDialog({
      acceptButtonText: 'Desactivar',
      cancelButtonText: 'Cancelar',
      description:
        'Vas a DESACTIVAR la Organización y es una acción que no se puede deshacer, ¿estás seguro?',
      onAccept: async () => {
        await desactivateOrganization();
      },
      title: '¡ATENCIÓN!',
      variant: 'danger',
    });
  }, [desactivateOrganization, openDialog]);

  const handleRemoveIntegration = useCallback(async () => {
    try {
      await removeDriveIntegration();
      await refetch();
      updateOrganizationConfigVar({ hasDriveIntegration: false });
      enqueueSnackbar('Se ha desconectado correctamente', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(formatErrors('organization', e.message, 'actualizar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [enqueueSnackbar, removeDriveIntegration, refetch]);

  const handleConnectIntegration = useCallback(async () => {
    if (me && me.organization) {
      window.open(
        `${ApiConfig.API}/auth/google/login?userId=${me.id}&organizationId=${me.organization.id}`,
        '_self',
      );
    }
  }, [me]);

  return {
    handleConnectIntegration,
    handleDesactivateOrganization,
    handleRemoveIntegration,
    handleSubmit,
    handleSubmitPin,
    initialValues,
    isLoading: getMeLoading || setOrganizationConfigLoading,
    organization: me?.organization,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
