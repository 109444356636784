import styled from 'styled-components';
import { SectionContainer, SectionHeader } from 'components/Layout';
import DefaultSectionLink from 'components/SectionLink';
import DefaultBackLink from 'components/BackLink';
import DefaultButton from 'components/Button';
import { from } from 'styles/responsive';

export const Container = styled(SectionContainer)``;

export const Content = styled.div`
  margin-top: 1rem;
  margin-bottom: 5rem;

  ${from.tabletLandscape`
    margin-bottom: 0;
  `}
`;

export const Header = styled(SectionHeader)`
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  ${from.tabletLandscape`
    margin-bottom: 5rem;
  `}
`;

export const HeaderContent = styled.div`
  align-items: center;
  display: flex;
`;

export const SectionLink = styled(DefaultSectionLink)`
  margin-bottom: 1rem;
`;

export const SectionTitleContainer = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  display: flex;
  padding-bottom: 1rem;
`;

export const SectionTitle = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const BackLink = styled(DefaultBackLink)``;

export const CreateButton = styled(DefaultButton).attrs(() => ({
  variant: 'light',
}))``;

export const LinkContentTitle = styled.div`
  color: ${({ theme }) => theme.colors.blue900};
  font-size: 1.2rem;
  margin: 1.5rem 0;
  font-weight: bold;
`;
