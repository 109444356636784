import { useCallback, Dispatch, SetStateAction } from 'react';
import { BaseMutationOptions } from '@apollo/client';
import uploadFile from 'utils/uploadFile';
import getFileSignedUrlTemporary from 'apollo/requests/getFileSignedUrlTemporary';
import { normalizeFilename } from 'utils/files';
import type {
  GetFileSignedUrlTemporaryPartnerMutation,
  GetFileSignedUrlTemporaryPartnerMutationVariables,
} from 'apollo/graphql.types';

type Options = {
  getFileSignedUrlOptions?: BaseMutationOptions<
    GetFileSignedUrlTemporaryPartnerMutation,
    GetFileSignedUrlTemporaryPartnerMutationVariables
  >;
};

const useFileSignedUrlTemporaryActions = ({
  getFileSignedUrlOptions,
}: Options = {}) => {
  const uploadFileAction = useCallback(
    async ({
      file,
      name,
      partnerId,
      setProgress,
      code,
    }: {
      file: File;
      name: string;
      partnerId: string;
      code: string;
      setProgress:
        | Dispatch<SetStateAction<number | undefined>>
        | ((progress?: number) => void);
    }) => {
      setProgress(0);

      const renamedFile = new File([file], normalizeFilename(name), {
        type: file.type,
      });

      try {
        const { uploadUrl, contentType } = await getFileSignedUrlTemporary({
          variables: {
            code,
            data: {
              fileName: name?.replace(/\s/g, '-') || renamedFile.name,
              contentType: renamedFile.type,
              fileSize: renamedFile.size,
              partnerId,
            },
            ...getFileSignedUrlOptions,
          },
        });

        try {
          await uploadFile({
            file: renamedFile,
            onProgress: setProgress,
            uploadUrl,
            contentType,
          });

          setProgress(undefined);
          return true;
        } catch (error) {
          setProgress(undefined);
          throw new Error(error?.message);
        }
      } catch (error) {
        setProgress(undefined);
        throw new Error(error?.message);
      }
    },
    [getFileSignedUrlOptions],
  );

  return {
    uploadFileAction,
  };
};

export default useFileSignedUrlTemporaryActions;
