import { useCallback } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { assignQuotaPayload, close, openAssignQuota, type } = useModal();

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleAccept = useCallback(() => {
    const { partner, onRefetchPartner } = assignQuotaPayload || {};
    if (partner) {
      openAssignQuota({ partner, onRefetchPartner });
    }
  }, [assignQuotaPayload, openAssignQuota]);

  return {
    handleClose,
    handleAccept,
    isOpen: type === ModalType.EXPIRED_QUOTA_DIALOG,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
