import { BaseMutationOptions } from '@apollo/client';
import { apolloClient } from 'services/api';
import {
  ConfirmImageUpload,
  ConfirmImageUploadVariables,
} from 'apollo/generated/ConfirmImageUpload';
import { CONFIRM_IMAGE_UPLOAD } from 'apollo/mutations/images';

const confirmImageUpload = async (
  options: BaseMutationOptions<ConfirmImageUpload, ConfirmImageUploadVariables>,
) => {
  const { data } = await apolloClient.mutate<
    ConfirmImageUpload,
    ConfirmImageUploadVariables
  >({
    mutation: CONFIRM_IMAGE_UPLOAD,
    ...options,
  });

  const id = data?.confirmImageUpload.id;

  if (!id) return undefined;

  return {
    id,
    url: data?.confirmImageUpload.url || '',
  };
};

export default confirmImageUpload;
