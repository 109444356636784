import { FC, memo } from 'react';
import { Container, Line } from './styles';
import { Props } from './types';

const OrderPlaceholder: FC<Props> = ({ className, nRows = 1 }) => (
  <>
    {Array.from(Array(nRows).keys()).map((i) => (
      <Container key={i} className={className}>
        <Line $height="0.7rem" $width="100%" />
      </Container>
    ))}
  </>
);

export default memo(OrderPlaceholder);
