import styled from 'styled-components';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled.div``;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const Content = styled.div`
  margin-top: 3rem;
  padding: 0 1.125rem;
`;

export const DateTimeFilterContainer = styled.div`
  padding: 0 1.25rem;
`;
