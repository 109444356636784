import { object as yupObject, number as yupNumber } from 'yup';

const requiredMsg = 'Este campo es requerido.';

export const validationSchema = yupObject({
  newQuantity: yupNumber()
    .required(requiredMsg)
    .test(
      'validValue',
      'No es posible transferir una cantidad mayor a la disponible en el almacén.',
      function (value) {
        const { quantity } = this.parent;

        if (!value || !quantity) {
          return true;
        }

        return Number(value) <= Number(quantity);
      },
    ),
});
