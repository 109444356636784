import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled(DefaultModal)`
  ${Content} {
    position: relative;

    ${from.tabletPortrait`
      max-width: 36rem;
      position: unset;
    `}
  }
`;

export const Button = styled(DefaultButton)``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Inputs = styled.div``;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Buttons = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-flow: row;
  position: absolute;
  margin: 2.5rem 0 1rem;

  > * {
    flex: 1 0 auto;
  }

  ${from.tabletPortrait`
      margin: 2.5rem 0 0;
      position: unset;
  `}
`;

export const Provider = styled.div<{ $isSelected?: boolean }>`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}0A;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 1rem 0.3rem;
  width: 100%;
  transition: background-color 200ms ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      border-color: ${theme.colors.accent};
      color: ${theme.colors.accent};
    `}
`;

export const ProviderName = styled.div`
  font-size: 0.8rem;
`;

export const AcceptButton = styled(DefaultButton)`
  &&& {
    width: 100%;

    ${from.tabletPortrait`
      width: unset !important;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    margin-right: 0.8rem;
    width: 100%;

    ${from.tabletPortrait`
      width: unset !important;
    `}
  }
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const ProvidersContainer = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(white, rgba(255, 255, 255, 0.001));
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4rem;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  }
`;

export const ProvidersList = styled.div`
  max-height: 33rem;
  overflow-y: scroll;
  padding: 0 0.5rem;

  ${from.tabletPortrait`
    max-height: 20rem;
    min-height: 12rem;
  `}
`;

export const Space = styled.div`
  height: 1rem;
`;
