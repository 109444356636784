export const light = {
  colors: {
    accent: '#0074CA',
    danger: '#E53935',
    dangerPale: '#ffecec',
    orange: '#FB762E',
    orangePale: '#fff4ec',
    green: '#2CB532',
    greenPale: '#edf9ee',
    black: '#000000',
    blue100: '#83b0e7',
    blue300: '#5393de',
    blue700: '#2F73D8',
    blue900: '#2C3E50',
    grey100: '#FAFAFA',
    grey200: '#E1E1E5',
    grey300: '#BCC6CC',
    grey400: '#A3A9B5',
    grey500: '#819098',
    grey700: '#9E9E9E',
    grey900: '#6E7A76',
    primary: '#0A2737',
    products: [
      '#E53935',
      '#FB762E',
      '#E5AA22',
      '#2CB532',
      '#2E9FFB',
      '#3E2EFB',
      '#FB2EE1',
    ],
    menu: {
      background: '#efefef',
      categoryName: '#812b05',
      productTitle: '#0A2737',
      productPrice: '#812b05',
      productDescription: '#0A2737',
      imageBorder: '#812b05',
    },
    charts: {
      strike: '#0074CA',
      background: '#96bfde',
    },
    text: '#263238',
    white: '#FFFFFF',
  },
  weights: {
    extraThin: 100,
    thin: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};
