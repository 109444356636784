import { gql } from '@apollo/client';
import { CATEGORY_FRAGMENT } from 'apollo/fragments/Category';

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($data: CreateCategoryDto!) {
    createCategory(data: $data) {
      ...CategoryData
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $data: UpdateCategoryDto!) {
    updateCategory(id: $id, data: $data) {
      ...CategoryData
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const BULK_UPDATE_CATEGORIES = gql`
  mutation BulkUpdateCategories($data: [BulkUpdateCategoriesDto!]!) {
    bulkUpdateCategories(data: $data) {
      ...CategoryData
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const REMOVE_CATEGORY = gql`
  mutation RemoveCategory($id: ID!) {
    removeCategory(id: $id)
  }
`;

export const TOGGLE_CATEGORY_PRODUCTS = gql`
  mutation ToggleCategoryProducts($categoryId: ID!, $productsIds: [ID!]!) {
    toggleCategoryProducts(categoryId: $categoryId, productsIds: $productsIds) {
      id
      name
      description
      type
      updatedAt
      createdAt
      deletedAt
      products {
        ... on Product {
          id
          name
        }
      }
    }
  }
`;
