import styled from 'styled-components';

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-family: 'Inter';
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  padding-left: 1rem;
  margin-top: 0.5rem;
`;

export const Label = styled.label`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.black};
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  padding-right: 1rem;
`;
