import { ChangeEvent, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import usePartners from 'apollo/hooks/partner/usePartners';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import type { Partner, PartnerToList } from 'model/Partner';

const useConnect = () => {
  const { close, type, partnersPayload, openNewPartner } = useModal();
  const { partners, loading: getPartnersLoading, refetch } = usePartners();
  const { enqueueSnackbar } = useSnackbar();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');

  const { onSelect, onReturn } = partnersPayload || {};

  const handleSearch = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value;

      setIsRefetching(true);
      try {
        await refetch({ filter: newSearch });
        setFilter(newSearch);
        setIsRefetching(false);
      } catch (e) {
        setIsRefetching(false);
        enqueueSnackbar('No se ha podido realizar la búsqueda', {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, refetch],
  );

  const handleForceSearch = useCallback(async () => {
    setIsRefetching(true);
    try {
      await refetch({ filter });
      setIsRefetching(false);
    } catch (e) {
      setIsRefetching(false);
      enqueueSnackbar('No se ha podido realizar la búsqueda', {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [enqueueSnackbar, filter, refetch]);

  const handleClose = useCallback(async () => {
    setIsRefetching(false);
    setFilter('');
    await refetch();

    if (onReturn) {
      onReturn();
    } else {
      close();
    }
  }, [close, onReturn, refetch]);

  const handleSelect = useCallback(
    (partner: PartnerToList) => {
      if (onSelect) {
        onSelect(partner as Partner);
      }

      if (onReturn) {
        onReturn();
      } else {
        close();
      }
    },
    [close, onReturn, onSelect],
  );

  const handleReturnFromNewPartnerModal = useCallback(
    async (partner?: Partner) => {
      if (partner) {
        handleSelect(partner);
      } else {
        await handleClose();
      }
    },
    [handleClose, handleSelect],
  );

  const handleCreatePartner = useCallback(async () => {
    openNewPartner({ onReturn: handleReturnFromNewPartnerModal });
  }, [openNewPartner, handleReturnFromNewPartnerModal]);

  return {
    handleSelect,
    handleClose,
    handleSearch,
    handleCreatePartner,
    partners,
    isOpen: type === ModalType.PARTNERS,
    isLoading: getPartnersLoading,
    isRefetching,
    filter,
    handleForceSearch,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
