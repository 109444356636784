import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultIcon from 'components/Icon';

export const Container = styled(DefaultModal)`
  ${Content} {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 70rem;
      width: 100%;
    `}
  }
`;

export const Button = styled(DefaultButton)``;

export const ResetButton = styled(Button)<{ $hasHostSign: boolean }>`
  ${({ $hasHostSign }) =>
    !$hasHostSign
      ? css`
          &&& {
            margin-top: 0.5rem;
            ${from.tabletPortrait`
              margin-top: 0;
              margin-right: 0.5rem;
            `}
          }
        `
      : css`
          &&& {
            font-size: 0.8rem;
            margin-top: 0.5rem;
            padding: 0.6rem;
          }
        `}
`;

export const IconCircle = styled(DefaultIconCircle)`
  flex: 0 1 auto;
  margin-left: auto;
  margin-right: auto;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 20,
}))``;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Buttons = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  display: flex;
  flex-flow: column-reverse nowrap;
  margin-top: 1.5rem;
  width: 100%;

  ${from.tabletPortrait`
    flex-flow: row nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    margin-top: 0.5rem;
    ${from.tabletPortrait`
      margin-right: 0.5rem;
      margin-top: 0;
    `}
  }
`;

export const Signs = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

export const SignContent = styled.div`
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const SignatureContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.accent};
  border-radius: 0.25rem;
`;
