import { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { subscriptionStateVar, permissionsStateVar } from 'apollo/reactive';
import {
  Icon,
  Container,
  DashboardIcon,
  Label,
  Link,
  MoreIcon,
  UsersIcon,
} from './styles';

const TabBar: FC = () => {
  const subscription = useReactiveVar(subscriptionStateVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const allowCashier =
    subscription.allowedCashier && employeePermissions.allowShowCashier;

  return (
    <Container>
      <Link to="/">
        <DashboardIcon />
        <Label>Productos</Label>
      </Link>
      <Link to="/partners">
        <UsersIcon />
        <Label>Socios</Label>
      </Link>
      <Link to={allowCashier ? '/cashier' : '/support'}>
        <Icon
          size={allowCashier ? 20 : 24}
          name={allowCashier ? 'cashier' : 'help'}
        />
        <Label>{allowCashier ? 'Caja' : 'Soporte'}</Label>
      </Link>
      <Link to="/more">
        <MoreIcon />
        <Label>Más</Label>
      </Link>
    </Container>
  );
};

export default memo(TabBar);
