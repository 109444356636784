import {
  object as yupObject,
  string as yupString,
  array as yupArray,
} from 'yup';
import { DayOfWeek } from 'apollo/generated/globalTypes';

export const validationSchema = yupObject({
  daysOfWeek: yupArray()
    .of(yupString())
    .min(1, 'Debes seleccionar un día')
    .required('Este campo es requerido.'),
  schedules: yupArray()
    .of(
      yupObject().shape({
        startTime: yupString(),
        endTime: yupString(),
      }),
    )
    .test(
      'startTimeBeforeEndTime',
      'La hora de inicio debe ser anterior a la hora de fin',
      (schedules) => {
        return !!schedules?.every(({ startTime, endTime }) => {
          if (startTime && endTime) {
            return startTime < endTime;
          }

          return true;
        });
      },
    )
    .required('Este campo es requerido.'),
});

export const initialValues = {
  daysOfWeek: [],
  schedules: [
    {
      startTime: '',
      endTime: '',
    },
  ],
};

export const DaysOptions = [
  {
    label: 'Lunes',
    value: DayOfWeek.MONDAY,
  },
  {
    label: 'Martes',
    value: DayOfWeek.TUESDAY,
  },
  {
    label: 'Miércoles',
    value: DayOfWeek.WEDNESDAY,
  },
  {
    label: 'Jueves',
    value: DayOfWeek.THURSDAY,
  },
  {
    label: 'Viernes',
    value: DayOfWeek.FRIDAY,
  },
  {
    label: 'Sábado',
    value: DayOfWeek.SATURDAY,
  },
  {
    label: 'Domingo',
    value: DayOfWeek.SUNDAY,
  },
];
