import styled from 'styled-components';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

export const Container = styled(Box)``;

export const MaterialSelect = styled(Select)`
  flex: 1 0 auto;
  border-bottom: none;
  &&& {
    .MuiNativeSelect-root {
      font-size: 0.9rem;
    }
    background: ${({ theme }) => theme.colors.white};
    &.Mui-focused > select {
      background: none;
    }
    ::before,
    ::after {
      display: none;
    }
  }
`;

export const InputContainer = styled(FormControl)<{
  $isDisabled?: boolean;
  $hasError?: boolean;
}>`
  width: 100%;
`;
