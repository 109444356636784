import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { ProductReferenceFormFields } from 'model/Product';
import useProductActions from 'apollo/hooks/product/useProductActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';

const useConnect = () => {
  const { close, type, newReferencedProductPayload } = useModal();
  const { createNewProductReference, loading: productActionsLoading } =
    useProductActions();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { product } = newReferencedProductPayload || {};

  const initialValues = useMemo(
    () => ({
      baseWeight: product?.baseWeight || ('' as unknown as number),
      referenceCode: '',
      description: product?.description || '',
      costs: product?.costs || ('' as unknown as number),
      price: product?.price || ('' as unknown as number),
      quantity: product?.quantity || ('' as unknown as number),
    }),
    [product],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<ProductReferenceFormFields>['onSubmit']
  >(
    async (data) => {
      try {
        if (product) {
          const referencedProduct = await createNewProductReference(
            product.id,
            data,
          );
          if (referencedProduct) {
            enqueueSnackbar(
              `El producto ${referencedProduct.name} con referencia ${referencedProduct.referenceCode} ha sido creado correctamente`,
              { variant: 'success' },
            );
            navigate(`/products/${referencedProduct.id}`);
          }
          close();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('product', e.message, 'crear'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [close, createNewProductReference, enqueueSnackbar, navigate, product],
  );

  return {
    handleClose,
    handleSubmit,
    isOpen: type === ModalType.NEW_REFERENCED_PRODUCT,
    initialValues,
    productName: product?.name || '',
    isLoading: productActionsLoading,
  };
};

export default useConnect;
