import type { FC } from 'react';
import { Formik } from 'formik';
import { DocumentCustomDataInputType } from 'apollo/graphql.types';
import DatePicker from 'components/Inputs/DatePicker';
import TextField from 'components/Inputs/TextField';
import Switch from 'components/Inputs/Switch';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  Container,
  Form,
  Icon,
  IconCircle,
  Title,
} from './styles';

const SetDocumentCustomDataModal: FC = () => {
  const { customData, isOpen, handleClose, handleSubmit, initialValues } =
    useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>Rellena los campos</Title>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {customData?.map((item, index) => {
              const label = `${index + 1}. ${
                (item.label && item.label) || item.key
              }`;
              switch (item.inputType) {
                case DocumentCustomDataInputType.Date:
                  return (
                    <>
                      <DatePicker
                        titleLabel={label}
                        name={item.key}
                        id={item.key}
                      />
                      <br />
                    </>
                  );
                case DocumentCustomDataInputType.Number:
                  return (
                    <>
                      <TextField
                        type="number"
                        name={item.key}
                        titleLabel={label}
                        id={item.key}
                      />
                      <br />
                    </>
                  );
                case DocumentCustomDataInputType.Boolean:
                  return (
                    <>
                      <Switch
                        id={item.key}
                        titleLabel={label}
                        name={item.key}
                      />
                      <br />
                    </>
                  );
                default:
                  return (
                    <>
                      <TextField
                        type="text"
                        name={item.key}
                        titleLabel={label}
                        id={item.key}
                      />
                      <br />
                    </>
                  );
              }
            })}
            <Buttons>
              <CancelButton onClick={handleClose}>Cerrar</CancelButton>
              <AcceptButton type="submit">Guardar</AcceptButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default SetDocumentCustomDataModal;
