import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { orderVar, subscriptionStateVar } from 'apollo/reactive';
import getPartner from 'apollo/requests/getPartner';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import { DiscountType } from 'apollo/graphql.types';

const useConnect = () => {
  const { close, type, openNewOrder } = useModal();
  const subscription = useReactiveVar(subscriptionStateVar);
  const { registerPartnerPresence } = usePartnerActions();

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleCodeReceived = useCallback(
    async (key: string) => {
      const partner = await getPartner({ rfidCode: key });
      if (partner) {
        const productDiscounts = partner.discounts.filter(
          (discount) => discount.type === DiscountType.Product,
        );
        orderVar({
          partner,
          products: [],
          // @ts-ignore
          discount:
            (subscription.allowedDiscounts && productDiscounts?.[0]) || null,
        });
        openNewOrder();
        await registerPartnerPresence(partner.id);
      }
    },
    [openNewOrder, registerPartnerPresence, subscription.allowedDiscounts],
  );

  return {
    handleClose,
    handleCodeReceived,
    isOpen: type === ModalType.RFID_DIALOG,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
