import { useCallback } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, employeeAdvancedMenuPayload, type } = useModal();

  const {
    removeEmployee,
    updatePassword,
    toggleEmployeeActivationStatus,
    employeeActive,
  } = employeeAdvancedMenuPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleRemoveEmployee = useCallback(() => {
    if (removeEmployee) {
      removeEmployee();
    }
  }, [removeEmployee]);

  const handleOpenUpdatePassword = useCallback(() => {
    if (updatePassword) {
      updatePassword();
    }
  }, [updatePassword]);

  const handleToggleEmployeeActivationStatus = useCallback(() => {
    if (toggleEmployeeActivationStatus) {
      toggleEmployeeActivationStatus();
    }
  }, [toggleEmployeeActivationStatus]);

  return {
    handleClose,
    handleOpenUpdatePassword,
    handleRemoveEmployee,
    handleToggleEmployeeActivationStatus,
    employeeActive,
    isOpen: type === ModalType.EMPLOYEE_ADVANCED_MENU,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
