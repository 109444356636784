import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import { formatDate, formatDateToTable } from 'utils/dates/formatDates';
import { TransactionType } from 'model/Transaction';
import type { Discount } from 'model/Discount';
import {
  Container,
  Credits,
  Date,
  DateItem,
  Icon,
  IconBorder,
  Items,
  CreatedBy,
  Partner,
  Revert,
  State,
  AppliedTo,
  CreditValue,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, onClickItems, onRevert, transaction }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const {
    canceled,
    canceledAt,
    canceledBy,
    concept,
    credits,
    date,
    partner,
    icon,
    items,
    sumCredits,
    type,
    discount,
    employee,
  } = transaction;

  const hasSumCredits = sumCredits || type === TransactionType.PURCHASE;

  const products =
    items.length === 1
      ? `${items[0].productName}(${items[0].quantity})`
      : items.length;

  const handleClickItems = useCallback(
    () => onClickItems(items, discount as Partial<Discount>),
    [items, onClickItems, discount],
  );

  return (
    <Container className={className}>
      <State>
        {canceled ? (
          <Icon
            $color="#E53935"
            name="revert"
            size={fromTabletPortrait ? 22 : 15}
          />
        ) : (
          <Icon
            $color={icon.color}
            name={icon.name}
            size={fromTabletPortrait ? 22 : 15}
          />
        )}
      </State>
      <CreatedBy>
        {employee && (
          <Partner to={`/employees/${employee.id}`}>
            {employee.fullName}
          </Partner>
        )}
        {concept}
      </CreatedBy>
      <AppliedTo>
        {partner && (
          <Partner to={`/partners/${partner.id}`}>{partner.fullName}</Partner>
        )}
      </AppliedTo>
      <Items $hasItems={items.length > 0} onClick={handleClickItems}>
        {products}
      </Items>
      <Date>
        {canceledAt ? (
          <>
            <DateItem $type="canceled">
              {fromTabletPortrait
                ? formatDateToTable(canceledAt)
                : formatDate(canceledAt)}
            </DateItem>
            <DateItem $type="second">
              {fromTabletPortrait ? formatDateToTable(date) : formatDate(date)}
            </DateItem>
          </>
        ) : (
          <DateItem>
            {fromTabletPortrait ? formatDateToTable(date) : formatDate(date)}{' '}
          </DateItem>
        )}
      </Date>
      <Credits $creditsAdded={hasSumCredits}>
        <CreditValue $isReverted={canceled}>
          {hasSumCredits
            ? '+'
            : (type !== TransactionType.CREDITS && '-') || ''}
          <Price>{credits}</Price>
        </CreditValue>
      </Credits>
      {!canceled && (
        <Revert onClick={onRevert}>
          <IconBorder>
            <Icon
              $color="#E53935"
              name="revert"
              size={fromTabletPortrait ? 22 : 15}
            />
          </IconBorder>
        </Revert>
      )}
      {canceledBy && (
        <Revert>
          Cancelado por{' '}
          <Partner to={`/employees/${canceledBy.id}`}>
            {canceledBy.fullName}
          </Partner>
        </Revert>
      )}
    </Container>
  );
};

export default memo(Row);
