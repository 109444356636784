import styled from 'styled-components';

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.charts.background};
  justify-content: center;
  height: 100vh;
  margin: 0;
`;

export const FlourContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue900};
  justify-content: center;
  height: 100vh;
  margin: 0;
`;

export const Text = styled.p`
  color: #defcf9;
  font-weight: lighter;
  font-size: 0.9rem;
  margin-top: 20rem;
  max-width: 30rem;
`;
