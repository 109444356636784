import type { FC } from 'react';
import { Avatar } from 'components/AvatarEditorLegacy/styles';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, Header } from './styles';

const EmployeeDetails: FC = () => {
  const { handleClose, isOpen, initialValues, handleSubmit } = useConnect();
  const fullName = `${initialValues.firstName} ${initialValues.lastName}`;
  return (
    <Container open={isOpen} onClose={handleClose}>
      <Header>
        <Avatar shadow src={initialValues.avatarImageUrl} fullName={fullName} />
        <Title>{fullName}</Title>
      </Header>
      <Form
        onSubmit={handleSubmit}
        onClose={handleClose}
        initialValues={initialValues}
      />
    </Container>
  );
};

export default EmployeeDetails;
