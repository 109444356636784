import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_ME } from 'apollo/queries/users';
import { normalizeUser } from 'model/User';
import { withoutNulls } from 'utils/withoutNulls';
import { Me_me } from 'apollo/generated/Me';
import { userRoleVar } from '../../reactive';

const useMe = ({
  fetchPolicy = 'cache-and-network',
}: {
  fetchPolicy?: QueryHookOptions<Me_me>['fetchPolicy'];
} = {}) => {
  const {
    data: raw,
    loading,
    refetch,
  } = useQuery(GET_ME, {
    fetchPolicy,
  });

  const normalized = useMemo(() => {
    const data = raw?.me && withoutNulls(raw.me);
    const user = normalizeUser(data);
    if (user.role) {
      userRoleVar(user.role);
    }
    return user;
  }, [raw]);

  return { data: normalized, loading, refetch };
};

export default useMe;
