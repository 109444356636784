import type { FC, ChangeEvent } from 'react';
import { FieldArray, Formik } from 'formik';
import { applyDiscount } from 'utils/discounts';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import Loader from 'components/Loader';
import OrderPlaceholder from './Placeholder';
import useConnect from './connect';
import {
  AddIcon,
  AddMoreButton,
  ArrowRightIcon,
  Buttons,
  CancelButton,
  Cr,
  ErrorText,
  ChargeButton,
  Container,
  EmptyState,
  Header,
  IconButton,
  IconCircle,
  OrdersIcon,
  PartnerLabel,
  PartnerRow,
  PartnerSelector,
  ProductName,
  ProductRow,
  Products,
  ProductsContainer,
  RemoveIcon,
  SubstractIcon,
  Title,
  TitleContainer,
  TotalPrice,
  TotalProducts,
  TextFieldNumber,
  AddDiscountButton,
  OriginalPrice,
  CreditsRow,
  CreditsButton,
  CreditsForm,
  CreditsInput,
  ProductAction,
  ProductsTitleRow,
  Quantity,
  Credits,
  ExtraQuantity,
  ExtraQuantityText,
  RestCredits,
  ProductNameRow,
  ProductReference,
  ProductNamePrice,
  ProductQuantity,
  OriginalProductPrice,
} from './styles';

const NewOrder: FC = () => {
  const {
    actions,
    discount,
    formikRef,
    handleAddDiscount,
    handleChangeAmount,
    handleChangeProductInput,
    handleClose,
    handleOnSubmit,
    handlePurchase,
    handleSelectPartner,
    initialFormValues,
    isLoading,
    isOpen,
    isProductEdited,
    onDisableSumButton,
    partner,
    products,
    showPartnerError,
    totalPrice,
    subscription,
    addCreditsLoading,
    handleAddCreditsSubmit,
    partnerCredits,
    organizationConfig,
    handleChangeProductExtraAmount,
  } = useConnect();
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const hasDiscount = !!discount;
  const originalPrice = Number(totalPrice);
  const finalPrice = !hasDiscount
    ? originalPrice
    : applyDiscount({
        discount,
        price: Number(totalPrice),
      });

  return (
    <Container
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={handleClose}
    >
      <Header>
        <IconCircle>
          <OrdersIcon />
        </IconCircle>
        <Title>Nuevo pedido</Title>
      </Header>
      {products.length === 0 ? (
        <EmptyState
          buttonLabel="Añadir producto"
          onClickButton={actions.selectProducts}
        >
          Aun no has seleccionado ningún producto, selecciona al menos uno para
          realizar un pedido
        </EmptyState>
      ) : (
        <ProductsContainer>
          <ProductsTitleRow>
            <TitleContainer>
              {fromTabletPortrait && (
                <TotalProducts>{products.length} productos</TotalProducts>
              )}

              <AddMoreButton onClick={actions.selectProducts}>
                Añadir
              </AddMoreButton>
              {subscription.allowedDiscounts && (
                <AddDiscountButton
                  $hasDiscount={hasDiscount}
                  onClick={handleAddDiscount}
                >
                  {hasDiscount ? `Descuento '${discount.name}'` : 'Descuento'}
                </AddDiscountButton>
              )}
            </TitleContainer>
            <TotalPrice>
              {discount && (
                <OriginalPrice>
                  <Price>{originalPrice}</Price>
                </OriginalPrice>
              )}{' '}
              <Price>{finalPrice}</Price>
            </TotalPrice>
          </ProductsTitleRow>
          <Products>
            {isProductEdited ? (
              <OrderPlaceholder nRows={4} />
            ) : (
              <Formik
                onSubmit={handlePurchase}
                initialValues={initialFormValues}
                innerRef={formikRef}
                enableReinitialize
              >
                {({ values, setFieldValue }) => (
                  <FieldArray
                    name="products"
                    render={() =>
                      values?.products?.length > 0 &&
                      values.products.map((product, index) => {
                        const finalProductCost = product.discount
                          ? applyDiscount({
                              discount: product.discount,
                              price: product.costs,
                            })
                          : product.costs;

                        const handleChangePrice = (
                          e: ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleChangeProductInput({
                            e,
                            productIndex: index,
                            actionType: 'PRICE',
                          });
                        };

                        const handleChangeInputAmount = (
                          e: ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleChangeProductInput({
                            e,
                            productIndex: index,
                            actionType: 'AMOUNT',
                          });
                        };

                        return (
                          <ProductRow key={product.id}>
                            <ProductNameRow>
                              <ProductName>
                                {product.name}{' '}
                                {organizationConfig?.activateProductReference &&
                                  product.referenceCode && (
                                    <ProductReference>
                                      #{product.referenceCode}
                                    </ProductReference>
                                  )}
                              </ProductName>
                              <ProductQuantity>
                                ({product.quantity})
                              </ProductQuantity>
                              {product.discount && (
                                <OriginalProductPrice>
                                  <ProductNamePrice>
                                    {product.costs}
                                  </ProductNamePrice>
                                </OriginalProductPrice>
                              )}{' '}
                              <ProductNamePrice>
                                {finalProductCost}
                              </ProductNamePrice>
                            </ProductNameRow>
                            <ProductAction>
                              <Quantity>
                                <IconButton
                                  onClick={() =>
                                    handleChangeAmount(index, 'substract')
                                  }
                                  type="button"
                                >
                                  <SubstractIcon />
                                </IconButton>
                                <TextFieldNumber
                                  name={`products[${index}].amount`}
                                  id={`products[${index}].amount`}
                                  type="number"
                                  onLocalChange={handleChangeInputAmount}
                                />
                                <IconButton
                                  onClick={() =>
                                    handleChangeAmount(index, 'sum')
                                  }
                                  disabled={onDisableSumButton(index)}
                                  type="button"
                                >
                                  <AddIcon />
                                </IconButton>
                                {organizationConfig.allowExtraQuantity &&
                                  product.countToMaxConsume && (
                                    <ExtraQuantity>
                                      <ExtraQuantityText>
                                        Real:
                                      </ExtraQuantityText>
                                      <TextFieldNumber
                                        name={`products[${index}].extraAmount`}
                                        id={`products[${index}].extraAmount`}
                                        type="number"
                                        min={0}
                                        onLocalChange={(e) =>
                                          handleChangeProductExtraAmount({
                                            e,
                                            setFieldValue,
                                            productIndex: index,
                                          })
                                        }
                                      />
                                    </ExtraQuantity>
                                  )}
                              </Quantity>

                              <Credits>
                                <TextFieldNumber
                                  name={`products[${index}].price`}
                                  id={`products[${index}].price`}
                                  type="number"
                                  onLocalChange={handleChangePrice}
                                />
                                <Cr>cr</Cr>
                                <RemoveIcon
                                  onClick={() =>
                                    actions.removeProduct(product.id)
                                  }
                                  name="close"
                                />
                              </Credits>
                            </ProductAction>
                          </ProductRow>
                        );
                      })
                    }
                  />
                )}
              </Formik>
            )}
          </Products>
        </ProductsContainer>
      )}
      <PartnerRow onClick={handleSelectPartner}>
        <PartnerLabel>Socio</PartnerLabel>
        <PartnerSelector $isSelected={!!partner?.id}>
          {partner?.id
            ? `${partner.fullName}  #${partner.memberNum}`
            : 'Selecciona un socio'}
          <ArrowRightIcon />
        </PartnerSelector>
      </PartnerRow>
      {partner?.id && (
        <CreditsRow>
          {addCreditsLoading ? (
            <Loader />
          ) : (
            <>
              <PartnerLabel>
                Créditos <Price>{partnerCredits}</Price>
                {originalPrice && finalPrice ? (
                  <RestCredits $isPositive={partnerCredits - finalPrice > 0}>
                    Restante: <Price>{partnerCredits - finalPrice}</Price>
                  </RestCredits>
                ) : null}
              </PartnerLabel>
              <Formik
                onSubmit={handleAddCreditsSubmit}
                initialValues={{ credits: '' as unknown as number }}
                validateOnBlur={false}
              >
                {({ handleSubmit, values }) => (
                  <CreditsForm onSubmit={handleSubmit}>
                    <CreditsInput
                      key="credits"
                      id="credits"
                      name="credits"
                      type="number"
                    />
                    <CreditsButton
                      type="submit"
                      disabled={values.credits <= 0 || values.credits > 1000}
                      isLoading={isLoading}
                    >
                      Añadir
                    </CreditsButton>
                  </CreditsForm>
                )}
              </Formik>
            </>
          )}
        </CreditsRow>
      )}

      {showPartnerError && <ErrorText>Debes seleccionar un socio</ErrorText>}
      <Buttons>
        <CancelButton onClick={handleClose}>Cancelar</CancelButton>
        <ChargeButton
          disabled={products?.length === 0 || !partner}
          isLoading={isLoading}
          onClick={handleOnSubmit}
        >
          Cobrar
        </ChargeButton>
      </Buttons>
    </Container>
  );
};

export default NewOrder;
