import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultOTPInput from 'components/Inputs/OTPInput';
import DefaultIcon from 'components/Icon';
import DefaultLoader from 'components/Loader';

export const Container = styled(DefaultModal)`
  ${Content} {
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
`;

export const IconCircle = styled(DefaultIconCircle)`
  margin-right: 1rem;
`;

export const Icon = styled(DefaultIcon)<{
  $hasError?: boolean;
  $isValid?: boolean;
}>`
  color: ${({ $hasError, $isValid, theme }) =>
    // eslint-disable-next-line no-nested-ternary
    $hasError
      ? theme.colors.danger
      : $isValid
      ? theme.colors.green
      : theme.colors.accent};
`;

export const Title = styled(ModalTitle)`
  margin: 1rem auto 3rem;
`;

export const OTPInput = styled(DefaultOTPInput)`
  margin-bottom: 1rem;
`;

export const Loader = styled(DefaultLoader)`
  min-height: unset;
  margin-bottom: 0.8rem;
`;
