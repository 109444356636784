import styled, { css } from 'styled-components';
import { TABLE_METRICS } from './constants';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.primary}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  flex: 0 0 ${TABLE_METRICS.name};
  margin-right: 0.5rem;
  text-transform: capitalize;
  ${TextEllipsis};
`;

export const Type = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.type};
  ${TextEllipsis};
`;

export const Price = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: auto;
  margin-right: 1rem;
  text-align: right;
  flex: 0 0 ${TABLE_METRICS.price};
  ${TextEllipsis};
`;

export const OriginalPrice = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-right: 0.5rem;
  position: relative;

  &:before {
    border-color: inherit;
    border-top: 1px solid;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 45%;
    transform: skewY(-10deg);
  }
`;
