import { useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import {
  ModalAddMenuSchedulePayload,
  ModalAddWorkSchedulePayload,
  ModalAdjustProductOptionsDialogPayload,
  ModalAdjustProductDetailsPayload,
  ModalAdjustProductQuantityPayload,
  ModalAdjustProductStorePayload,
  ModalAssignDiscountsPayload,
  ModalAssignedQuotaPayload,
  ModalCancelOrderPayload,
  ModalCloseCashierPayload,
  ModalCreditConceptPayload,
  ModalDialogPayload,
  ModalDiscountPayload,
  ModalDocumentsPayload,
  ModalEmployeeDetailsPayload,
  ModalLoadPartnerCreditsPayload,
  ModalManageCreditsPayload,
  ModalMoveStoreToStockPayload,
  ModalNewPartnerPayload,
  ModalNewOrderPayload,
  ModalNewProviderPayload,
  ModalNewReferencedProductPayload,
  ModalEmployeeAdvancedMenuPayload,
  ModalPartnerAdvancedMenuPayload,
  ModalPartnerDetailsPayload,
  ModalPartnerMenuPayload,
  ModalPartnersPayload,
  ModalPartnersQuotaPayload,
  ModalProductsPayload,
  ModalProductAdvancedMenuPayload,
  ModalPurchaseDetailsPayload,
  ModalCashierProductsPayload,
  ModalCashierPartnersPayload,
  ModalQuotaDetailsPayload,
  ModalQuotaPayload,
  ModalReactivatePartnerPayload,
  ModalSelectCategoriesPayload,
  ModalSelectProviderPayload,
  ModalSelectTypeOfCashPayload,
  ModalSetDocumentDataPayload,
  ModalSignDocumentPayload,
  ModalStoreProductOptionsDialogPayload,
  ModalType,
  ModalWebcamPayload,
  ModalUploadFilePayload,
  ModalUploadImageDialogPayload,
  modalVar,
  resetModalVar,
  setModalVar,
  updateModalVar,
  ModalPartnerNotePayload,
  ModalGenerateFileDialogPayload,
  ModalGenerateQRPayload,
  ModalUpdatePasswordPayload,
  ModalCategoryPayload,
  ModalValidatePinPayload,
  ModalEventPayload,
  ModalExpenseDetailsDetailsPayload,
} from 'apollo/reactive/modal';

const useModal = () => {
  const {
    type,

    addMenuSchedulePayload,
    addWorkSchedulePayload,
    adjustProductOptionsDialogPayload,
    adjustProductDetailsPayload,
    adjustProductQuantityPayload,
    adjustProductStorePayload,
    assignDiscountsPayload,
    assignQuotaPayload,
    cancelOrderPayload,
    closeCashierPayload,
    cashierProductsPayload,
    cashierPartnersPayload,
    categoryPayload,
    creditConceptPayload,
    dialogPayload,
    discountPayload,
    documentsPayload,
    employeeDetailsPayload,
    eventPayload,
    expenseDetailsPayload,
    generateFileDialogPayload,
    generateQRPayload,
    loadPartnerCreditsPayload,
    manageCreditsPayload,
    moveStoreToStockPayload,
    newPartnerPayload,
    newOrderPayload,
    newProviderPayload,
    newReferencedProductPayload,
    employeeAdvancedMenuPayload,
    partnerAdvancedMenuPayload,
    partnerDetailsPayload,
    partnerMenuPayload,
    partnerNotePayload,
    partnersPayload,
    partnersQuotaPayload,
    productPayload,
    productAdvancedMenuPayload,
    purchaseDetailsPayload,
    quotaDetailsPayload,
    quotaPayload,
    reactivatePartnerPayload,
    selectCategoriesPayload,
    selectProviderPayload,
    selectTypeOfCashPayload,
    setDocumentDataPayload,
    signDocumentPayload,
    storeProductOptionsDialogPayload,
    uploadFilePayload,
    uploadImageDialogPayload,
    updatePasswordPayload,
    validatePinPayload,
    webcamPayload,
  } = useReactiveVar(modalVar);

  const close = useCallback((dataClearDelay?: number) => {
    updateModalVar({ type: null });
    if (dataClearDelay) {
      setTimeout(() => {
        resetModalVar();
      }, dataClearDelay);
    } else {
      resetModalVar();
    }
  }, []);

  const openCancelOrder = useCallback((data: ModalCancelOrderPayload) => {
    setModalVar({ type: ModalType.CANCEL_ORDER, cancelOrderPayload: data });
  }, []);

  const openCloseCashierDialog = useCallback(
    (data: ModalCloseCashierPayload) => {
      setModalVar({
        type: ModalType.CLOSE_CASHIER_DIALOG,
        closeCashierPayload: data,
      });
    },
    [],
  );

  const openCashierDetails = useCallback((data: ModalCloseCashierPayload) => {
    setModalVar({
      type: ModalType.CASHIER_DETAILS,
      closeCashierPayload: data,
    });
  }, []);

  const openCreateMenu = useCallback(() => {
    setModalVar({ type: ModalType.CREATE_MENU });
  }, []);

  const openEmployeeAdvancedMenu = useCallback(
    (data: ModalEmployeeAdvancedMenuPayload) => {
      setModalVar({
        type: ModalType.EMPLOYEE_ADVANCED_MENU,
        employeeAdvancedMenuPayload: data,
      });
    },
    [],
  );

  const openUpdatePasswordForm = useCallback(
    (data: ModalUpdatePasswordPayload) => {
      setModalVar({
        type: ModalType.UPDATE_PASSWORD,
        updatePasswordPayload: data,
      });
    },
    [],
  );

  const openPartnerAdvancedMenu = useCallback(
    (data: ModalPartnerAdvancedMenuPayload) => {
      setModalVar({
        type: ModalType.PARTNER_ADVANCED_MENU,
        partnerAdvancedMenuPayload: data,
      });
    },
    [],
  );

  const openPartnerMenu = useCallback((data: ModalPartnerMenuPayload) => {
    setModalVar({ type: ModalType.PARTNER_MENU, partnerMenuPayload: data });
  }, []);

  const openCreditConcept = useCallback((data?: ModalCreditConceptPayload) => {
    setModalVar({
      type: ModalType.CREDIT_CONCEPT,
      creditConceptPayload: data,
    });
  }, []);

  const openLoadPartnerCredits = useCallback(
    (data: ModalLoadPartnerCreditsPayload) => {
      setModalVar({
        type: ModalType.LOAD_PARTNER_CREDITS,
        loadPartnerCreditsPayload: data,
      });
    },
    [],
  );

  const openDialog = useCallback((data: ModalDialogPayload) => {
    setModalVar({ type: ModalType.DIALOG, dialogPayload: data });
  }, []);

  const openUploadFileDialog = useCallback((data: ModalUploadFilePayload) => {
    setModalVar({
      type: ModalType.UPLOAD_FILE,
      uploadFilePayload: data,
    });
  }, []);

  const openUploadImageDialog = useCallback(
    (data: ModalUploadImageDialogPayload) => {
      setModalVar({
        type: ModalType.UPLOAD_IMAGE_DIALOG,
        uploadImageDialogPayload: data,
      });
    },
    [],
  );

  const openWebcam = useCallback((data: ModalWebcamPayload) => {
    setModalVar({
      type: ModalType.WEBCAM,
      webcamPayload: data,
    });
  }, []);

  const openExpiredQuotaDialog = useCallback(
    (data: ModalAssignedQuotaPayload) => {
      setModalVar({
        type: ModalType.EXPIRED_QUOTA_DIALOG,
        assignQuotaPayload: data,
      });
    },
    [],
  );

  const openAssignDiscounts = useCallback(
    (data: ModalAssignDiscountsPayload) => {
      setModalVar({
        type: ModalType.ASSIGN_ACTIVE_DISCOUNTS,
        assignDiscountsPayload: data,
      });
    },
    [],
  );

  const openManageCredits = useCallback((data: ModalManageCreditsPayload) => {
    setModalVar({ type: ModalType.MANAGE_CREDITS, manageCreditsPayload: data });
  }, []);

  const openMoveStoreToStock = useCallback(
    (data: ModalMoveStoreToStockPayload) => {
      setModalVar({
        type: ModalType.MOVE_STORE_TO_STOCK,
        moveStoreToStockPayload: data,
      });
    },
    [],
  );

  const openAddMenuSchedule = useCallback(
    (data: ModalAddMenuSchedulePayload) => {
      setModalVar({
        type: ModalType.ADD_MENU_SCHEDULE,
        addMenuSchedulePayload: data,
      });
    },
    [],
  );

  const openAddWorkSchedule = useCallback(
    (data: ModalAddWorkSchedulePayload) => {
      setModalVar({
        type: ModalType.ADD_WORK_SCHEDULE,
        addWorkSchedulePayload: data,
      });
    },
    [],
  );

  const openAdjustProductOptionsDialog = useCallback(
    (data: ModalAdjustProductOptionsDialogPayload) => {
      setModalVar({
        type: ModalType.ADJUST_PRODUCT_OPTIONS_DIALOG,
        adjustProductOptionsDialogPayload: data,
      });
    },
    [],
  );

  const openAdjustProductDetails = useCallback(
    (data: ModalAdjustProductDetailsPayload) => {
      setModalVar({
        type: ModalType.ADJUST_PRODUCT_DETAILS,
        adjustProductDetailsPayload: data,
      });
    },
    [],
  );

  const openAdjustProductQuantity = useCallback(
    (data: ModalAdjustProductQuantityPayload) => {
      setModalVar({
        type: ModalType.ADJUST_PRODUCT_QUANTITY,
        adjustProductQuantityPayload: data,
      });
    },
    [],
  );

  const openAdjustProductStore = useCallback(
    (data: ModalAdjustProductStorePayload) => {
      setModalVar({
        type: ModalType.ADJUST_PRODUCT_STORE,
        adjustProductStorePayload: data,
      });
    },
    [],
  );

  const openSelectCategories = useCallback(
    (data: ModalSelectCategoriesPayload) => {
      setModalVar({
        type: ModalType.SELECT_CATEGORIES,
        selectCategoriesPayload: data,
      });
    },
    [],
  );

  const openSelectProvider = useCallback((data: ModalSelectProviderPayload) => {
    setModalVar({
      type: ModalType.SELECT_PROVIDER,
      selectProviderPayload: data,
    });
  }, []);

  const openSelectTypeOfCash = useCallback(
    (data: ModalSelectTypeOfCashPayload) => {
      setModalVar({
        type: ModalType.SELECT_TYPE_OF_CASH,
        selectTypeOfCashPayload: data,
      });
    },
    [],
  );

  const openSetDocumentData = useCallback(
    (data: ModalSetDocumentDataPayload) => {
      setModalVar({
        type: ModalType.SET_DOCUMENT_CUSTOM_DATA,
        setDocumentDataPayload: data,
      });
    },
    [],
  );

  const openSignDocument = useCallback((data: ModalSignDocumentPayload) => {
    setModalVar({
      type: ModalType.SIGN_DOCUMENT,
      signDocumentPayload: data,
    });
  }, []);

  const openStoreProductOptionsDialog = useCallback(
    (data: ModalStoreProductOptionsDialogPayload) => {
      setModalVar({
        type: ModalType.STORE_PRODUCT_OPTIONS_DIALOG,
        storeProductOptionsDialogPayload: data,
      });
    },
    [],
  );

  const openPartnerNote = useCallback((data?: ModalPartnerNotePayload) => {
    setModalVar({
      type: ModalType.PARTNER_NOTE,
      partnerNotePayload: data,
    });
  }, []);

  const openNewAccountRequest = useCallback(() => {
    setModalVar({ type: ModalType.NEW_ACCOUNT_REQUEST });
  }, []);

  const openNewTicketRequest = useCallback(() => {
    setModalVar({ type: ModalType.NEW_TICKET_REQUEST });
  }, []);

  const openNewOrder = useCallback((data?: ModalNewOrderPayload) => {
    setModalVar({ type: ModalType.NEW_ORDER, newOrderPayload: data });
  }, []);

  const openNewProduct = useCallback(() => {
    setModalVar({ type: ModalType.NEW_PRODUCT });
  }, []);

  const openNewPartner = useCallback((data?: ModalNewPartnerPayload) => {
    setModalVar({ type: ModalType.NEW_PARTNER, newPartnerPayload: data });
  }, []);

  const openNewEmployee = useCallback(() => {
    setModalVar({ type: ModalType.NEW_EMPLOYEE });
  }, []);

  const openNewExpense = useCallback(() => {
    setModalVar({ type: ModalType.NEW_EXPENSE });
  }, []);

  const openDiscount = useCallback((data?: ModalDiscountPayload) => {
    setModalVar({ type: ModalType.DISCOUNT, discountPayload: data });
  }, []);

  const openDocuments = useCallback((data?: ModalDocumentsPayload) => {
    setModalVar({ type: ModalType.DOCUMENTS, documentsPayload: data });
  }, []);

  const openNewProvider = useCallback((data?: ModalNewProviderPayload) => {
    setModalVar({ type: ModalType.NEW_PROVIDER, newProviderPayload: data });
  }, []);

  const openNewReferencedProduct = useCallback(
    (data: ModalNewReferencedProductPayload) => {
      setModalVar({
        type: ModalType.NEW_REFERENCED_PRODUCT,
        newReferencedProductPayload: data,
      });
    },
    [],
  );

  const openPartners = useCallback((data: ModalPartnersPayload) => {
    setModalVar({ type: ModalType.PARTNERS, partnersPayload: data });
  }, []);

  const openPartnersQuota = useCallback((data: ModalPartnersQuotaPayload) => {
    setModalVar({ type: ModalType.PARTNERS_QUOTA, partnersQuotaPayload: data });
  }, []);

  const openPartnerDetails = useCallback((data: ModalPartnerDetailsPayload) => {
    setModalVar({
      type: ModalType.PARTNER_DETAILS,
      partnerDetailsPayload: data,
    });
  }, []);

  const openEmployeeDetails = useCallback(
    (data: ModalEmployeeDetailsPayload) => {
      setModalVar({
        type: ModalType.EMPLOYEE_DETAILS,
        employeeDetailsPayload: data,
      });
    },
    [],
  );

  const openEvent = useCallback((data?: ModalEventPayload) => {
    setModalVar({
      type: ModalType.EVENT,
      eventPayload: data,
    });
  }, []);

  const openExpenseDetails = useCallback(
    (data?: ModalExpenseDetailsDetailsPayload) => {
      setModalVar({
        type: ModalType.EXPENSE_DETAILS,
        expenseDetailsPayload: data,
      });
    },
    [],
  );

  const openGenerateFileDialog = useCallback(
    (data: ModalGenerateFileDialogPayload) => {
      setModalVar({
        type: ModalType.GENERATE_FILE_DIALOG,
        generateFileDialogPayload: data,
      });
    },
    [],
  );

  const openGenerateQRDialog = useCallback((data: ModalGenerateQRPayload) => {
    setModalVar({
      type: ModalType.SHOW_QR,
      generateQRPayload: data,
    });
  }, []);

  const openPurchaseDetails = useCallback(
    (data: ModalPurchaseDetailsPayload) => {
      setModalVar({
        type: ModalType.PURCHASE_DETAILS,
        purchaseDetailsPayload: data,
      });
    },
    [],
  );

  const openCashierProducts = useCallback(
    (data: ModalCashierProductsPayload) => {
      setModalVar({
        type: ModalType.CASHIER_PRODUCTS,
        cashierProductsPayload: data,
      });
    },
    [],
  );

  const openCashierPartners = useCallback(
    (data: ModalCashierPartnersPayload) => {
      setModalVar({
        type: ModalType.CASHIER_PARTNERS,
        cashierPartnersPayload: data,
      });
    },
    [],
  );

  const openCategory = useCallback((data?: ModalCategoryPayload) => {
    setModalVar({
      type: ModalType.CATEGORY,
      categoryPayload: data,
    });
  }, []);

  const openProducts = useCallback((data: ModalProductsPayload) => {
    setModalVar({ type: ModalType.PRODUCTS, productPayload: data });
  }, []);

  const openProductsAdvancedMenu = useCallback(
    (data: ModalProductAdvancedMenuPayload) => {
      setModalVar({
        type: ModalType.PRODUCT_ADVANCED_MENU,
        productAdvancedMenuPayload: data,
      });
    },
    [],
  );

  const openAssignQuota = useCallback((data: ModalAssignedQuotaPayload) => {
    setModalVar({ type: ModalType.ASSIGN_QUOTA, assignQuotaPayload: data });
  }, []);

  const openQuotaDetails = useCallback((data: ModalQuotaDetailsPayload) => {
    setModalVar({ type: ModalType.QUOTA_DETAILS, quotaDetailsPayload: data });
  }, []);

  const openQuota = useCallback((data?: ModalQuotaPayload) => {
    setModalVar({ type: ModalType.QUOTA, quotaPayload: data });
  }, []);

  const openReactivatePartner = useCallback(
    (data?: ModalReactivatePartnerPayload) => {
      setModalVar({
        type: ModalType.REACTIVATE_PARTNER,
        reactivatePartnerPayload: data,
      });
    },
    [],
  );

  const openValidatePin = useCallback((data: ModalValidatePinPayload) => {
    setModalVar({ type: ModalType.VALIDATE_PIN, validatePinPayload: data });
  }, []);

  const openRfidDialog = useCallback(() => {
    setModalVar({ type: ModalType.RFID_DIALOG });
  }, []);

  return {
    type,

    addMenuSchedulePayload,
    addWorkSchedulePayload,
    adjustProductDetailsPayload,
    adjustProductOptionsDialogPayload,
    adjustProductQuantityPayload,
    adjustProductStorePayload,
    assignDiscountsPayload,
    assignQuotaPayload,
    cancelOrderPayload,
    closeCashierPayload,
    cashierProductsPayload,
    cashierPartnersPayload,
    categoryPayload,
    close,
    creditConceptPayload,
    dialogPayload,
    discountPayload,
    employeeDetailsPayload,
    eventPayload,
    expenseDetailsPayload,
    generateFileDialogPayload,
    generateQRPayload,
    loadPartnerCreditsPayload,
    manageCreditsPayload,
    moveStoreToStockPayload,
    newPartnerPayload,
    newOrderPayload,
    newProviderPayload,
    newReferencedProductPayload,
    openManageCredits,
    openMoveStoreToStock,
    openAddMenuSchedule,
    openAddWorkSchedule,
    openAdjustProductOptionsDialog,
    openAdjustProductDetails,
    openAdjustProductQuantity,
    openAdjustProductStore,
    openAssignDiscounts,
    openAssignQuota,
    openCancelOrder,
    openCashierProducts,
    openCashierPartners,
    openCategory,
    openCashierDetails,
    openCloseCashierDialog,
    openCreateMenu,
    openCreditConcept,
    openDialog,
    openDiscount,
    openDocuments,
    openEmployeeDetails,
    openEvent,
    openExpenseDetails,
    openGenerateFileDialog,
    openGenerateQRDialog,
    openExpiredQuotaDialog,
    openLoadPartnerCredits,
    openNewAccountRequest,
    openNewEmployee,
    openNewExpense,
    openNewOrder,
    openNewPartner,
    openNewProduct,
    openNewProvider,
    openNewReferencedProduct,
    openNewTicketRequest,
    openEmployeeAdvancedMenu,
    openPartnerAdvancedMenu,
    openPartnerDetails,
    openPartnerMenu,
    openPartnerNote,
    openPartners,
    openPartnersQuota,
    openQuota,
    openReactivatePartner,
    openRfidDialog,
    openProducts,
    openProductsAdvancedMenu,
    openPurchaseDetails,
    openQuotaDetails,
    openSelectCategories,
    openSelectProvider,
    openSelectTypeOfCash,
    openSetDocumentData,
    openSignDocument,
    openStoreProductOptionsDialog,
    openUploadFileDialog,
    openUploadImageDialog,
    openUpdatePasswordForm,
    openValidatePin,
    openWebcam,
    employeeAdvancedMenuPayload,
    partnerAdvancedMenuPayload,
    partnerDetailsPayload,
    documentsPayload,
    partnerMenuPayload,
    partnerNotePayload,
    partnersPayload,
    partnersQuotaPayload,
    productPayload,
    productAdvancedMenuPayload,
    purchaseDetailsPayload,
    quotaDetailsPayload,
    quotaPayload,
    reactivatePartnerPayload,
    selectCategoriesPayload,
    selectProviderPayload,
    selectTypeOfCashPayload,
    setDocumentDataPayload,
    signDocumentPayload,
    storeProductOptionsDialogPayload,
    uploadFilePayload,
    uploadImageDialogPayload,
    updatePasswordPayload,
    validatePinPayload,
    webcamPayload,
  };
};

export default useModal;

export type UseModal = ReturnType<typeof useModal>;
