import { useCallback, Dispatch, SetStateAction } from 'react';
import { BaseMutationOptions } from '@apollo/client';
import uploadFile from 'utils/uploadFile';
import { confirmFileUpload, getFileSignedUrl } from 'apollo/requests';
import { normalizeFilename } from 'utils/files';
import { GET_PARTNER } from 'apollo/queries/partners';
import type {
  GetFileSignedUrl,
  GetFileSignedUrlVariables,
} from 'apollo/generated/GetFileSignedUrl';
import type {
  ConfirmFileUpload,
  ConfirmFileUploadVariables,
} from 'apollo/generated/ConfirmFileUpload';

type Options = {
  getFileSignedUrlOptions?: BaseMutationOptions<
    GetFileSignedUrl,
    GetFileSignedUrlVariables
  >;
  confirmFileUploadOptions?: BaseMutationOptions<
    ConfirmFileUpload,
    ConfirmFileUploadVariables
  >;
};

const useFileSignedUrlActions = ({
  getFileSignedUrlOptions,
  confirmFileUploadOptions,
}: Options = {}) => {
  const uploadFileAction = useCallback(
    async ({
      file,
      name,
      partnerId,
      setProgress,
    }: {
      file: File;
      name: string;
      partnerId: string;
      setProgress:
        | Dispatch<SetStateAction<number | undefined>>
        | ((progress?: number) => void);
    }) => {
      setProgress(0);

      const renamedFile = new File([file], normalizeFilename(name), {
        type: file.type,
      });

      try {
        const { uploadUrl, contentType, id, fileName, fileSize } =
          await getFileSignedUrl({
            variables: {
              data: {
                fileName: name?.replace(/\s/g, '-') || renamedFile.name,
                contentType: renamedFile.type,
                fileSize: renamedFile.size,
                partnerId,
              },
              ...getFileSignedUrlOptions,
            },
          });

        try {
          await uploadFile({
            file: renamedFile,
            onProgress: setProgress,
            uploadUrl,
            contentType,
          });

          const confirmImageUploadData = await confirmFileUpload({
            variables: {
              data: {
                fileId: id,
                fileName,
                contentType,
                fileSize,
                partnerId,
              },
            },
            refetchQueries: [
              { query: GET_PARTNER, variables: { id: partnerId } },
            ],
            ...confirmFileUploadOptions,
          });

          setProgress(undefined);
          return confirmImageUploadData;
        } catch (error) {
          setProgress(undefined);
          throw new Error(error?.message);
        }
      } catch (error) {
        setProgress(undefined);
        throw new Error(error?.message);
      }
    },
    [confirmFileUploadOptions, getFileSignedUrlOptions],
  );

  return {
    uploadFileAction,
  };
};

export default useFileSignedUrlActions;
