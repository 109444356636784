import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
  date as yupDate,
} from 'yup';

const requiredMsg = 'Este campo es requerido.';

export const validationSchema = yupObject({
  email: yupString()
    .email('Introduce un email valido')
    .required('El email es requerido'),
  firstName: yupString().required(requiredMsg),
  lastName: yupString().required(requiredMsg),
  document: yupString().required(requiredMsg),
  address: yupString().required(requiredMsg),
  phoneNumber: yupString().required(requiredMsg),
  maxConsumeMonth: yupNumber().required(requiredMsg),
  birthDate: yupDate().required(requiredMsg),
});
