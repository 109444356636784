import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const Point = styled.div`
  background: #bbbbbb;
  border-radius: 50%;
`;

export const Container = styled.div<{
  $pointSeparation: string;
  $pointSize: string;
}>`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;

  ${Point} {
    height: ${({ $pointSize }) => $pointSize};
    width: ${({ $pointSize }) => $pointSize};
    &:nth-child(1) {
      animation: ${blink} 0.8s linear infinite;
      margin-right: ${({ $pointSeparation }) => $pointSeparation};
    }
    &:nth-child(2) {
      animation: ${blink} 0.8s linear 0.2s infinite;
      margin-right: ${({ $pointSeparation }) => $pointSeparation};
    }
    &:nth-child(3) {
      animation: ${blink} 0.8s linear 0.4s infinite;
    }
  }
`;
