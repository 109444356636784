import type { FC } from 'react';
import WebcamScreenshot from 'components/WebcamScreenshot';
import useConnect from './connect';
import { Container } from './styles';

const WebcamModal: FC = () => {
  const { isOpen, handleClose, handleAccept } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <WebcamScreenshot onGetImage={handleAccept} />
    </Container>
  );
};

export default WebcamModal;
