import { FC, memo } from 'react';
import { Container, Name, Number } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, data, value }) => {
  return (
    <Container className={className}>
      <Name>{data}</Name>
      <Number>{value}</Number>
    </Container>
  );
};

export default memo(Row);
