import type { FC } from 'react';
import Search from 'components/Search';
import Header from 'containers/Common/Header';
import { useCallback } from 'react';
import { ListRowProps, WindowScroller } from 'react-virtualized';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { Container, Main, List } from './styles';

const MetricBillings: FC = () => {
  const { billingProducts, handleReturn } = useConnect();

  const hasResults = billingProducts?.length > 0;
  const ROW_HEIGHT_DESKTOP_PX = 60;

  const rowRenderer: FC<ListRowProps> = useCallback(
    ({ index, style }) => {
      const product = billingProducts[index];

      return (
        <Row
          key={product.id}
          billing={product.billing}
          name={product.name}
          movement={product.movement}
          date={product.date}
          state={product.state}
          style={style}
        />
      );
    },
    [billingProducts],
  );

  return (
    <Container>
      <Header title="Facturación" onReturn={handleReturn}>
        <Search />
      </Header>
      <Main>
        {hasResults && (
          <>
            <TableHead />
            <WindowScroller>
              {({ onChildScroll, ...scrollInfo }) => (
                <List
                  autoHeight
                  autoWidth
                  onScroll={onChildScroll}
                  rowCount={billingProducts.length}
                  rowHeight={ROW_HEIGHT_DESKTOP_PX}
                  rowRenderer={rowRenderer}
                  {...scrollInfo}
                />
              )}
            </WindowScroller>
          </>
        )}
      </Main>
    </Container>
  );
};

export default MetricBillings;
