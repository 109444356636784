import { useCallback, useState } from 'react';
import { Props } from './types';

const useLogic = ({ fromDate, toDate, onSearch }: Props) => {
  const [savedFromDate, setSavedFromDate] = useState(fromDate);
  const [savedToDate, setSavedToDate] = useState(toDate);

  const handleChangeFromDate = useCallback((e: any) => {
    const date = e?.target?.value ? new Date(e.target.value) : new Date();
    setSavedFromDate(date);
  }, []);

  const handleChangeToDate = useCallback((e: any) => {
    const date = e?.target?.value ? new Date(e.target.value) : new Date();
    setSavedToDate(date);
  }, []);

  const handleSubmit = useCallback(() => {
    onSearch({
      from: savedFromDate,
      to: savedToDate,
    });
  }, [onSearch, savedFromDate, savedToDate]);

  return {
    handleChangeFromDate,
    handleChangeToDate,
    handleSubmit,
    savedFromDate,
    savedToDate,
  };
};

export default useLogic;
