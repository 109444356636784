import { format } from 'date-fns';
import { PartnerUsage } from 'apollo/generated/globalTypes';
import type { Partner } from 'model/Partner';
import type { User } from 'model/User';

export enum DATA_PDFS_KEYS {
  PARTNER_FIRST_NAME = '{nombre_socio}',
  PARTNER_LAST_NAME = '{apellidos_socio}',
  PARTNER_ADDRESS = '{domicilio_socio}',
  PARTNER_BIRTHDATE = '{nacimiento_socio}',
  PARTNER_NUM = '{numero_socio}',
  PARTNER_EMAIL = '{email_socio}',
  PARTNER_CP = '{cp_socio}',
  PARTNER_CITY = '{ciudad_socio}',
  PARTNER_USAGE = '{tipo_socio}',
  PARTNER_DOCUMENT = '{dni_socio}',
  PARTNER_PHONE = '{telefono_socio}',
  PARTNER_MAX_CONSUME = '{consumo_socio}',
  PARTNER_CREATED = '{inscripcion_socio}',
  PARTNER_SIGNATURE = '{firma_socio}',
  HOST_PARTNER_FIRST_NAME = '{nombre_avalador}',
  HOST_PARTNER_LAST_NAME = '{apellidos_avalador}',
  HOST_PARTNER_ADDRESS = '{domicilio_avalador}',
  HOST_PARTNER_BIRTHDATE = '{nacimiento_avalador}',
  HOST_PARTNER_NUM = '{numero_avalador}',
  HOST_PARTNER_EMAIL = '{email_avalador}',
  HOST_PARTNER_CP = '{cp_avalador}',
  HOST_PARTNER_CITY = '{ciudad_avalador}',
  HOST_PARTNER_DOCUMENT = '{dni_avalador}',
  HOST_PARTNER_PHONE = '{telefono_avalador}',
  HOST_PARTNER_MAX_CONSUME = '{consumo_avalador}',
  HOST_PARTNER_CREATED = '{inscripcion_avalador}',
  HOST_PARTNER_SIGNATURE = '{firma_avalador}',
  ORGANIZATION_NAME = '{nombre_organizacion}',
  ORGANIZATION_ADDRESS = '{domicilio_organizacion}',
  ORGANIZATION_EMAIL = '{email_organizacion}',
  ORGANIZATION_CP = '{cp_organizacion}',
  ORGANIZATION_CITY = '{ciudad_organizacion}',
  ORGANIZATION_DOCUMENT = '{cif_organizacion}',
  ORGANIZATION_PHONE = '{telefono_organizacion}',
}

export const DefaultContractText = `<p><strong>Solicitud de Admisión como Miembro Libre Consumidor</strong></p>
<p><br></p>
<p><strong>Información Personal:</strong></p>
<p><br></p>
<p>- <strong>Identificación:</strong> {dni_socio}</p>
<p>- <strong>Apellidos:</strong> {apellidos_socio}</p>
<p>- <strong>Nombre:</strong> {nombre_socio}</p>
<p>- <strong>Dirección:</strong> {domicilio_socio} , {ciudad_socio}</p>
<p>- <strong>Teléfono:</strong> {telefono_socio}</p>
<p>- <strong>Correo Electrónico:</strong> {email_socio}</p>
<p>- <strong>Fecha de Nacimiento:</strong> {nacimiento_socio}</p>
<p>- <strong>Número de Socio:</strong> {numero_socio}</p>
<p><br></p>
<p><strong>Declaración y Compromisos:</strong></p>
<p><br></p>
<p>Mediante este documento, manifiesto, de forma veraz y comprometida, mi condición de consumidor habitual de Cannabis sativa L. y otras plantas. Consciente de los objetivos, estatutos y normativas de la Asociación {nombre_organizacion}, me comprometo a su cumplimiento, así como a acatar la legislación española.&nbsp;</p>
<p>Considerando especialmente:</p>
<p>- El respeto al artículo 36.16 de la Ley Orgánica 4/2015 de protección ciudadana, que sanciona el consumo y posesión ilícitos de sustancias prohibidas.</p>
<p>- La observancia del artículo 368 del Código Penal Español, Ley Orgánica 10/1995, que castiga el cultivo, tráfico y consumo ilegal de drogas.</p>
<p>- Reconociendo y aceptando las inspecciones y registros en espacios públicos conforme al artículo 18 de la Constitución Española.</p>
<p><br></p>
<p><strong>Compromisos Específicos:</strong></p>
<p><br></p>
<p>1. Me comprometo a utilizar exclusivamente el cannabis obtenido de la Asociación {nombre_organizacion} para mi consumo personal en entornos privados, asumiendo toda responsabilidad legal por acciones contrarias a la ley, exonerando a la asociación y sus miembros de cualquier implicación.</p>
<p>2. Confirmo un consumo mensual de {consumo_socio} gramos de cannabis, revisable trimestralmente. La asociación entregará cantidades menores para fomentar un consumo responsable.</p>
<p>3. Autorizo a los miembros activos y fundadores de la Asociación a adquirir, cultivar y distribuir colectivamente el cannabis para autoconsumo en la sede.</p>
<p>4. Me comprometo a informar inmediatamente mi decisión de abandonar la Asociación y ratificar anualmente mi condición de miembro.</p>
<p>5. Me comprometo a notificar cualquier sanción según el artículo 25.1 de la Ley Orgánica 1/1992 o la imputación del delito especificado en el artículo 368 del Código Penal, lo que podría resultar en la expulsión de la asociación.</p>
<p>6. No transferiré mi carnet de socio, siendo su uso intransferible. La presentación del documento de identidad y el carnet será obligatoria para retirar material.</p>
<p>7. Cualquier incumplimiento de estos compromisos conllevará un proceso de expulsión según lo estipulado en los estatutos.</p>
<p><br></p>
<p><strong>Obligaciones y Derechos de los Asociados:</strong></p>
<p><br></p>
<p>Las obligaciones y derechos de los miembros se detallan en los estatutos, incluyendo el consumo privado, la responsabilidad hacia terceros, el cumplimiento de normativas internas y la colaboración con la asociación en sus programas y actividades.</p>
<p><br></p>
<p><strong>Protección de Datos:</strong></p>
<p><br></p>
<p>En cumplimiento con el Reglamento (UE) 2016/679, los datos personales se manejarán con confidencialidad y seguridad. Una vez finalizada la relación con la Asociación, los datos se archivarán o destruirán tras un mínimo de 6 meses.</p>
<p><br></p>
<p><strong>Aval del Solicitante y Conformidad de la Junta Directiva:</strong></p>
<p><br></p>
<p>SOCIO LÚDICO: {tipo_socio}&nbsp;</p>
<p>CONSUMO MAXIMO POR MES: {consumo_socio}</p>
<p><br></p>
<p>- Nombre: {nombre_avalador}</p>
<p>- Apellidos: {apellidos_avalador}</p>
<p>- DNI: {dni_avalador}</p>
<p>- Numero: {numero_avalador}</p>
<p><br></p>
<p><strong>Firmas:</strong></p>
<p><br></p>
<p>Firma del Solicitante:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Firma del Avalador:</p>
<p><br><br></p>
<p><br><br></p>
<p><br><br></p>
<p>Fecha de Ingreso: {inscripcion_socio}</p>`;

export const DefaultContractTextV2 = `<p><strong>Documento de prueba</strong></p>
<p><br></p>
<p><strong>Información Personal:</strong></p>
<p><br></p>
<p>- <strong>Identificación:</strong> {dni_socio}</p>
<p>- <strong>Apellidos:</strong> {apellidos_socio}</p>
<p>- <strong>Nombre:</strong> {nombre_socio}</p>
<p>- <strong>Dirección:</strong> {domicilio_socio} , {ciudad_socio}</p>
<p>- <strong>Teléfono:</strong> {telefono_socio}</p>
<p>- <strong>Correo Electrónico:</strong> {email_socio}</p>
<p>- <strong>Fecha de Nacimiento:</strong> {nacimiento_socio}</p>
<p>- <strong>Número de Socio:</strong> {numero_socio}</p>
<p><br></p>
<p><strong>Protección de Datos:</strong></p>
<p><br></p>
<p>En cumplimiento con el Reglamento (UE) 2016/679, los datos personales se manejarán con confidencialidad y seguridad. Una vez finalizada la relación con la Asociación, los datos se archivarán o destruirán tras un mínimo de 6 meses.</p>
<p><br></p>
<p>- Nombre: {nombre_avalador}</p>
<p>- Apellidos: {apellidos_avalador}</p>
<p>- DNI: {dni_avalador}</p>
<p>- Numero: {numero_avalador}</p>
<p><br></p>
<p><strong>Firmas:</strong></p>
<p><br></p>
<p>Firma del Solicitante</p> 
<p>{firma_socio}</p> 
<p><br><br></p>
<p><br><br></p>
<p>Firma del Avalador</p>
<p>{firma_avalador}</p>
<p><br><br></p>
<p><br><br></p>
<p><br><br></p>
<p>Fecha de Ingreso: {inscripcion_socio}</p>`;

export const replaceDocumentData = ({
  customData,
  hostSignatureData,
  organization,
  partner,
  signatureData,
  text,
}: {
  customData?: Record<string, string | number>;
  hostSignatureData?: string;
  organization: User['organization'];
  partner: Partner;
  signatureData?: string;
  text: string;
}) => {
  const condition = new RegExp(Object.values(DATA_PDFS_KEYS).join('|'), 'g');
  let fixedReplaces = text.replace(condition, (match) => {
    switch (match) {
      case DATA_PDFS_KEYS.PARTNER_FIRST_NAME:
        return partner.firstName;
      case DATA_PDFS_KEYS.PARTNER_LAST_NAME:
        return partner.lastName;
      case DATA_PDFS_KEYS.PARTNER_ADDRESS:
        return partner.address;
      case DATA_PDFS_KEYS.PARTNER_BIRTHDATE:
        return format(partner.birthDate || new Date(), 'dd/MM/yyyy');
      case DATA_PDFS_KEYS.PARTNER_NUM:
        return partner.memberNum;
      case DATA_PDFS_KEYS.PARTNER_EMAIL:
        return partner.email;
      case DATA_PDFS_KEYS.PARTNER_USAGE:
        return partner.usage === PartnerUsage.PLAYFUL
          ? 'Lúdico'
          : 'Terapéutico';
      case DATA_PDFS_KEYS.PARTNER_CP:
        return partner.zipCode;
      case DATA_PDFS_KEYS.PARTNER_CITY:
        return partner.city;
      case DATA_PDFS_KEYS.PARTNER_DOCUMENT:
        return partner.document;
      case DATA_PDFS_KEYS.PARTNER_PHONE:
        return partner.phoneNumber;
      case DATA_PDFS_KEYS.PARTNER_MAX_CONSUME:
        return String(partner.maxConsumeMonth);
      case DATA_PDFS_KEYS.PARTNER_SIGNATURE:
        return signatureData
          ? `<img src="${signatureData}" style="width: 250px; height: 150px;" />`
          : '';
      case DATA_PDFS_KEYS.PARTNER_CREATED:
        return format(partner.createdAt || new Date(), 'dd/MM/yyyy');
      case DATA_PDFS_KEYS.HOST_PARTNER_FIRST_NAME:
        return partner.hostMember.firstName;
      case DATA_PDFS_KEYS.HOST_PARTNER_LAST_NAME:
        return partner.hostMember.lastName;
      case DATA_PDFS_KEYS.HOST_PARTNER_ADDRESS:
        return partner.hostMember.address;
      case DATA_PDFS_KEYS.HOST_PARTNER_BIRTHDATE:
        return format(partner.hostMember.birthDate || new Date(), 'dd/MM/yyyy');
      case DATA_PDFS_KEYS.HOST_PARTNER_NUM:
        return partner.hostMember.memberNum;
      case DATA_PDFS_KEYS.HOST_PARTNER_EMAIL:
        return partner.hostMember.email;
      case DATA_PDFS_KEYS.HOST_PARTNER_CP:
        return partner.hostMember.zipCode;
      case DATA_PDFS_KEYS.HOST_PARTNER_CITY:
        return partner.hostMember.city;
      case DATA_PDFS_KEYS.HOST_PARTNER_DOCUMENT:
        return partner.hostMember.document;
      case DATA_PDFS_KEYS.HOST_PARTNER_PHONE:
        return partner.hostMember.phoneNumber;
      case DATA_PDFS_KEYS.HOST_PARTNER_MAX_CONSUME:
        return String(partner.maxConsumeMonth);
      case DATA_PDFS_KEYS.HOST_PARTNER_SIGNATURE:
        return hostSignatureData
          ? `<img src="${hostSignatureData}" style="width: 250px; height: 150px;" />`
          : '';
      case DATA_PDFS_KEYS.HOST_PARTNER_CREATED:
        return format(partner.hostMember.createdAt || new Date(), 'dd/MM/yyyy');
      case DATA_PDFS_KEYS.ORGANIZATION_NAME:
        return organization.name;
      case DATA_PDFS_KEYS.ORGANIZATION_ADDRESS:
        return organization.address;
      case DATA_PDFS_KEYS.ORGANIZATION_EMAIL:
        return organization.email;
      case DATA_PDFS_KEYS.ORGANIZATION_CP:
        return organization.zipCode;
      case DATA_PDFS_KEYS.ORGANIZATION_CITY:
        return organization.city;
      case DATA_PDFS_KEYS.ORGANIZATION_DOCUMENT:
        return organization.document;
      default:
        return '';
    }
  });

  if (customData && Object.values(customData).length > 0) {
    Object.keys(customData).forEach((key) => {
      const regex = new RegExp(`${key}`, 'g');
      fixedReplaces = fixedReplaces.replace(regex, String(customData[key]));
    });
  }

  return fixedReplaces;
};
