import { FC, memo, useEffect } from 'react';
import { Formik } from 'formik';
import Price from 'components/Price';
import { validationSchema } from '../constants';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  EmptyDiv,
  InputContainer,
  Label,
  Row,
  SaveMessage,
  SaveMessageNumber,
  SaveMessageText,
  TextField,
  SelectTypeOfCash,
  Icon,
  SelectedCash,
} from './styles';
import type { Props } from './types';

const Form: FC<Props> = ({
  adjustState,
  className,
  id = 'new',
  initialValues,
  isFirstSave,
  isLoading,
  onCancel,
  onSubmit,
  onChangeRealCreditsAdded,
  strictMode,
  onOpenTypesOfCash,
  typesOfCash,
  formikRef,
}) => {
  const formId = `closeCashier_${id}`;
  const showExpenses =
    initialValues?.totalExpenses && initialValues.totalExpenses !== 0;
  const showLastRestCredits =
    initialValues?.lastRestRealCredits &&
    initialValues.lastRestRealCredits !== 0;
  const showTotalCreditAdded = showExpenses || showLastRestCredits;
  const settedTypeOfCash = typesOfCash.filter(
    (typesOfCash) => Number(typesOfCash.value) > 0,
  );
  const hasTypesOfCash = settedTypeOfCash.length > 0;

  useEffect(() => {
    if (formikRef.current && hasTypesOfCash) {
      const total = typesOfCash.reduce(
        (acc, current) => acc + current.key * current.value,
        0,
      );
      formikRef.current.setFieldValue(
        'realCreditsAdded',
        Number(total.toFixed(2)),
      );
      formikRef.current.setFieldValue('typeOfCash', typesOfCash);
    }
  }, [hasTypesOfCash, formikRef, typesOfCash]);

  return (
    <Formik
      innerRef={formikRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid, values }) => (
        <Container className={className}>
          <Row>
            <TextField
              readOnly
              type="number"
              name="totalCashier"
              label="Total ingresado"
              id={`${formId}_totalCashier`}
            />
            <TextField
              type="number"
              name="realCreditsAdded"
              label="Total real en caja *"
              id={`${formId}_realCreditsAdded`}
              readOnly={strictMode}
              onLocalChange={onChangeRealCreditsAdded}
            />
          </Row>

          <Row>
            {showTotalCreditAdded ? (
              <TextField
                readOnly
                type="number"
                name="totalCreditsAdded"
                label="Créditos ingresados"
                id={`${formId}_totalCreditsAdded`}
              />
            ) : (
              <EmptyDiv />
            )}

            <TextField
              type="number"
              name="restRealCredits"
              label="Resto para siguiente caja"
              id={`${formId}_restRealCredits`}
            />
          </Row>

          {showExpenses || values.restRealCredits ? (
            <Row>
              {showExpenses ? (
                <TextField
                  readOnly
                  type="number"
                  name="totalExpenses"
                  label="Gastos"
                  id={`${formId}_totalExpenses`}
                />
              ) : (
                <EmptyDiv />
              )}
              {values.restRealCredits ? (
                <InputContainer>
                  <Label>
                    Caja real sin resto:{' '}
                    <Price>
                      {values.realCreditsAdded - values.restRealCredits}
                    </Price>
                  </Label>
                </InputContainer>
              ) : null}
            </Row>
          ) : null}

          {showLastRestCredits ? (
            <Row>
              <TextField
                readOnly
                type="number"
                name="lastRestRealCredits"
                label="Resto de la caja anterior"
                id={`${formId}_lastRestRealCredits`}
              />
            </Row>
          ) : null}

          <TextField
            type="text"
            name="note"
            label="Nota"
            id={`${formId}_note`}
            rows={2}
            isMultiline
          />

          {strictMode && (
            <SelectTypeOfCash
              $hasTypeOfCash={hasTypesOfCash}
              onClick={onOpenTypesOfCash}
            >
              {!hasTypesOfCash ? (
                <SelectedCash>Seleccionar tipos de moneda</SelectedCash>
              ) : (
                <SelectedCash>
                  Monedas:{' '}
                  {settedTypeOfCash
                    .map((item) => `(${item.key}: ${item.value})`)
                    .join(', ')}
                </SelectedCash>
              )}
              <Icon name="arrowRight" />
            </SelectTypeOfCash>
          )}

          {adjustState?.show && (
            <SaveMessage>
              <SaveMessageText>
                La diferencia del ajuste es{' '}
                {adjustState.isNegative ? 'negativo' : 'positivo'}.{' '}
                <SaveMessageNumber $isNegative={adjustState.isNegative}>
                  {adjustState.remainingQuantity}
                </SaveMessageNumber>
              </SaveMessageText>
            </SaveMessage>
          )}
          <Buttons>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || !isFormValid}
            >
              {isFirstSave ? 'Validar' : 'Guardar'}
            </CreateButton>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(Form);
