import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  BULK_UPDATE_CATEGORIES,
  CREATE_CATEGORY,
  REMOVE_CATEGORY,
  TOGGLE_CATEGORY_PRODUCTS,
  UPDATE_CATEGORY,
} from 'apollo/mutations/categories';
import {
  GET_CATEGORIES,
  GET_MOST_USED_CATEGORIES,
} from 'apollo/queries/categories';
import type {
  CreateCategory,
  CreateCategoryVariables,
} from 'apollo/generated/CreateCategory';
import {
  CategoryFormFields,
  EditCategoriesFields,
  normalizeCategory,
} from 'model/Category';
import type {
  BulkUpdateCategories,
  BulkUpdateCategoriesVariables,
} from 'apollo/generated/BulkUpdateCategories';
import type {
  RemoveCategory,
  RemoveCategoryVariables,
} from 'apollo/generated/RemoveCategory';
import type {
  UpdateCategory,
  UpdateCategoryVariables,
} from 'apollo/generated/UpdateCategory';
import type {
  ToggleCategoryProductsMutation,
  ToggleCategoryProductsMutationVariables,
} from 'apollo/graphql.types';
import type { CategoryType } from 'apollo/generated/globalTypes';

const useCategoryActions = () => {
  const [createCategoryMutation, { loading: isLoadingCreateCategory }] =
    useMutation<CreateCategory, CreateCategoryVariables>(CREATE_CATEGORY, {
      refetchQueries: [{ query: GET_MOST_USED_CATEGORIES }],
    });
  const [
    bulkUpdateCategoriesMutation,
    { loading: isLoadingBulkUpdateCategories },
  ] = useMutation<BulkUpdateCategories, BulkUpdateCategoriesVariables>(
    BULK_UPDATE_CATEGORIES,
    { refetchQueries: [{ query: GET_MOST_USED_CATEGORIES }] },
  );
  const [removeCategoryMutation, { loading: isLoadingRemoveCategory }] =
    useMutation<RemoveCategory, RemoveCategoryVariables>(REMOVE_CATEGORY, {
      refetchQueries: [
        { query: GET_MOST_USED_CATEGORIES },
        { query: GET_CATEGORIES },
      ],
    });
  const [updateCategoryMutation, { loading: isLoadingUpdateCategory }] =
    useMutation<UpdateCategory, UpdateCategoryVariables>(UPDATE_CATEGORY, {
      refetchQueries: [{ query: GET_MOST_USED_CATEGORIES }],
    });
  const [
    toggleCategoryProductsMutation,
    { loading: isLoadingToggleCategoryProducts },
  ] = useMutation<
    ToggleCategoryProductsMutation,
    ToggleCategoryProductsMutationVariables
  >(TOGGLE_CATEGORY_PRODUCTS);

  const createCategory = useCallback(
    async ({ name, type }: { name: string; type: CategoryType }) => {
      const { data } = await createCategoryMutation({
        variables: {
          data: {
            name,
            description: '',
            type,
          },
        },
      });
      if (data?.createCategory) {
        return normalizeCategory(data?.createCategory);
      }

      return null;
    },
    [createCategoryMutation],
  );

  const bulkUpdateCategories = useCallback(
    async (categoriesToUpdate: EditCategoriesFields['categories']) => {
      if (categoriesToUpdate?.length > 0) {
        const { data } = await bulkUpdateCategoriesMutation({
          variables: {
            data: categoriesToUpdate,
          },
        });

        if (data?.bulkUpdateCategories) {
          return data.bulkUpdateCategories;
        }

        return null;
      }
    },
    [bulkUpdateCategoriesMutation],
  );

  const removeCategory = useCallback(
    async (id: string) => {
      await removeCategoryMutation({
        variables: {
          id,
        },
      });
    },
    [removeCategoryMutation],
  );

  const updateCategory = useCallback(
    async ({ id, data }: { id: string; data: CategoryFormFields }) => {
      await updateCategoryMutation({
        variables: {
          id,
          data,
        },
      });
    },
    [updateCategoryMutation],
  );

  const toggleCategoryProducts = useCallback(
    async ({ id, productsIds }: { id: string; productsIds: string[] }) => {
      await toggleCategoryProductsMutation({
        variables: {
          categoryId: id,
          productsIds,
        },
      });
    },
    [toggleCategoryProductsMutation],
  );

  return {
    bulkUpdateCategories,
    createCategory,
    removeCategory,
    updateCategory,
    toggleCategoryProducts,
    loading:
      isLoadingCreateCategory ||
      isLoadingBulkUpdateCategories ||
      isLoadingRemoveCategory ||
      isLoadingUpdateCategory ||
      isLoadingToggleCategoryProducts,
  };
};

export default useCategoryActions;
