import { FC, memo } from 'react';
import AvatarEditor from 'components/AvatarEditor';
import {
  AvatarContainer,
  BackLink,
  Header,
  HeaderButtons,
  HeaderInfo,
  HeaderName,
  HeaderId,
  SecondaryButton,
} from './styles';
import { Props } from './types';

const EmployeeHeader: FC<Props> = ({
  currentAvatar,
  fromTabletPortrait,
  fullName,
  onSubmitAvatar,
  partnerNum,
  openPartnerDetails,
  onClickAdvancedActions,
  isActive,
}) => {
  return (
    <>
      <Header>
        <BackLink to="/employees" />
        <AvatarContainer>
          <AvatarEditor
            fullName={fullName}
            currentImage={currentAvatar}
            onSubmitImage={onSubmitAvatar}
          />
        </AvatarContainer>
        {fromTabletPortrait && (
          <HeaderInfo>
            <HeaderName $isActive={isActive}>
              {fullName} {!isActive && '(inactivo)'}
            </HeaderName>
            {partnerNum && (
              <HeaderId onClick={openPartnerDetails}>#{partnerNum}</HeaderId>
            )}
          </HeaderInfo>
        )}
        <HeaderButtons>
          <SecondaryButton onClick={onClickAdvancedActions}>
            ···
          </SecondaryButton>
        </HeaderButtons>
      </Header>
    </>
  );
};

export default memo(EmployeeHeader);
