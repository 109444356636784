import styled from 'styled-components';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import { from } from 'styles/responsive';
import DefaultIcon from 'components/Icon';

export const Container = styled(DefaultModal)`
  ${Content} {
    min-width: 25rem;
    width: 100%;

    ${from.tabletPortrait`
      max-width: 55rem;
    `}
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
`;

export const IconCircle = styled(DefaultIconCircle)`
  margin-right: 1rem;
`;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Title = styled(ModalTitle)`
  margin: 1rem auto 3.5625rem;
`;

export const Label = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.primary};
`;
