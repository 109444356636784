import { FC, memo } from 'react';
import type { Props } from './types';

const LogOut: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke="currentColor"
      strokeWidth="1.5"
    >
      <path d="M20.082 12.034H9M15.966 7.034l5 5-5 5M13 2H5v20h8" />
    </g>
  </svg>
);

export default memo(LogOut);
