import { useCallback, useRef } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const signatureRef = useRef<any>(null);
  const hostSignatureRef = useRef<any>(null);
  const { close, signDocumentPayload, type } = useModal();
  const { hasHostSign, onClick } = signDocumentPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      const signatureData = signatureRef?.current?.toDataURL();
      const hostSignatureData = hostSignatureRef?.current?.toDataURL();
      onClick(signatureData, hostSignatureData);
    }
  }, [onClick]);

  const handleResetSignature = useCallback(() => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  }, [signatureRef]);

  const handleResetHostSignature = useCallback(() => {
    if (hostSignatureRef.current) {
      hostSignatureRef.current.clear();
    }
  }, [hostSignatureRef]);

  return {
    handleClose,
    handleOnClick,
    signatureRef,
    hostSignatureRef,
    handleResetSignature,
    handleResetHostSignature,
    hasHostSign,
    isOpen: type === ModalType.SIGN_DOCUMENT,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
