import { FC, memo } from 'react';
import {
  Container,
  OpenIcon,
  Icons,
  Name,
  TrashIcon,
  DriveLogoIcon,
  DisabledDriveLogoIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  employeePermissions,
  fileDriveId,
  name,
  onOpen,
  onRemove,
  onUploadToDrive,
}) => (
  <Container>
    <Name>{name}</Name>
    <Icons>
      {fileDriveId ? (
        <DriveLogoIcon />
      ) : (
        <DisabledDriveLogoIcon onClick={onUploadToDrive} />
      )}
      <OpenIcon onClick={onOpen} />
      {employeePermissions.allowRemoveFiles && <TrashIcon onClick={onRemove} />}
    </Icons>
  </Container>
);

export default memo(Row);
