import { FC, memo, useCallback } from 'react';
import useUserActions from 'apollo/hooks/user/useUserActions';
import { useSnackbar } from 'notistack';
import type { Props } from './types';
import { Container } from './styles';

const NotifySnackbarErrorButton: FC<Props> = ({ error }) => {
  const { sendTicket, userActionsLoading } = useUserActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSendRequest = useCallback(async () => {
    await sendTicket(JSON.stringify({ error }));

    closeSnackbar();
    enqueueSnackbar('Se ha reportado el error. Gracias', {
      variant: 'info',
    });
  }, [sendTicket, error, closeSnackbar, enqueueSnackbar]);

  return (
    <Container onClick={handleSendRequest} disabled={userActionsLoading}>
      Reportar
    </Container>
  );
};

export default memo(NotifySnackbarErrorButton);
