import { object as yupObject, string as yupString } from 'yup';

const requiredMsg = 'Este campo es requerido';
export const validationSchema = yupObject({
  oldPassword: yupString().required(requiredMsg),
  newPassword: yupString().required(requiredMsg),
  newPassword2: yupString().required(requiredMsg),
});

export const initialValues = {
  oldPassword: '',
  newPassword: '',
  newPassword2: '',
};
