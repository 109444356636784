import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormikConfig, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useCashierActions from 'apollo/hooks/cashier/useCashierActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { Cashier, CloseCashierFormFields } from 'model/Cashier';
import { defaultTypesOfCash } from './constants';

const useConnect = () => {
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const [isFirstSave, setIsFirstSave] = useState(true);
  const [adjustState, setAdjustState] = useState<{
    isNegative: boolean;
    remainingQuantity: number;
    show: boolean;
  } | null>({
    isNegative: false,
    remainingQuantity: 0.0,
    show: false,
  });
  const { closeCashier, cashierActionsLoading } = useCashierActions();
  const {
    close,
    type,
    closeCashierPayload,
    openSelectTypeOfCash,
    openCloseCashierDialog,
  } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { cashier, selectedTypesOfCash } = closeCashierPayload || {};
  const [typesOfCash, setTypesOfCash] = useState(defaultTypesOfCash);
  const formikRef = useRef<FormikProps<CloseCashierFormFields>>(null);

  useEffect(() => {
    if (selectedTypesOfCash) {
      setTypesOfCash(selectedTypesOfCash);
    }
  }, [formikRef, selectedTypesOfCash]);

  const initialValues = useMemo<CloseCashierFormFields>(() => {
    const totalCreditsAdded = Number(
      (cashier?.totalCreditsAdded || 0).toFixed(2),
    );
    const totalExpenses = Number((cashier?.totalExpenses || 0).toFixed(2));
    const lastRestRealCredits = Number(
      (cashier?.lastRestRealCredits || 0).toFixed(2),
    );

    return {
      note: '',
      realCreditsAdded: 0,
      restRealCredits: cashier?.restRealCredits || 0,
      totalCashier: Number(
        (totalCreditsAdded + lastRestRealCredits - totalExpenses).toFixed(2),
      ),
      typeOfCash: [],
      totalExpenses,
      lastRestRealCredits,
      totalCreditsAdded,
    };
  }, [cashier]);

  const handleClose = useCallback(() => {
    setAdjustState(null);
    setIsFirstSave(true);
    close();
  }, [close]);

  const handleOpenTypesOfCash = useCallback(() => {
    openSelectTypeOfCash({
      typesOfCash,
      onSelect: (selectedTypesOfCash) => {
        setIsFirstSave(true);
        setAdjustState(null);
        openCloseCashierDialog({
          cashier: cashier as Cashier,
          selectedTypesOfCash,
        });
      },
      onReturn: () => {
        openCloseCashierDialog({
          cashier: cashier as Cashier,
          selectedTypesOfCash: typesOfCash,
        });
      },
    });
  }, [cashier, openCloseCashierDialog, openSelectTypeOfCash, typesOfCash]);

  const handleSubmit = useCallback<
    FormikConfig<CloseCashierFormFields>['onSubmit']
  >(
    async (data) => {
      try {
        if (isFirstSave) {
          const currentRealCredits = Number(data?.realCreditsAdded || 0);
          const currentTotalCredits = Number(data?.totalCashier || 0);
          // We must add the values when the value of added credits is negative.
          const currentTotalCreditsIsNegative = currentTotalCredits < 0;
          const remainingQuantity = currentTotalCreditsIsNegative
            ? currentRealCredits + currentTotalCredits
            : currentRealCredits - currentTotalCredits;

          setAdjustState({
            show: true,
            isNegative:
              remainingQuantity < 0
                ? true
                : currentRealCredits < currentTotalCredits,
            remainingQuantity: Number(remainingQuantity.toFixed(2)),
          });
          setIsFirstSave(false);
        } else if (cashier?.id) {
          await closeCashier({
            id: cashier.id,
            dto: data,
          });
          enqueueSnackbar(`La caja se ha cerrado correctamente`, {
            variant: 'success',
          });
          setTypesOfCash(defaultTypesOfCash);
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('cashier', e.message, 'ajustar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [isFirstSave, cashier, closeCashier, enqueueSnackbar, handleClose],
  );

  const handleChangeRealCreditsAdded = useCallback(async () => {
    setIsFirstSave(true);
  }, []);

  return {
    adjustState,
    handleClose,
    handleSubmit,
    initialValues,
    isFirstSave,
    handleOpenTypesOfCash,
    handleChangeRealCreditsAdded,
    typesOfCash,
    formikRef,
    strictMode: organizationConfig?.activeStrictCashier,
    isLoading: cashierActionsLoading,
    isOpen: type === ModalType.CLOSE_CASHIER_DIALOG,
  };
};

export default useConnect;
