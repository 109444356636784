import { FC, memo } from 'react';
import { Image, Initials } from './styles';
import type { Props } from './types';
import useLogic from './logic';

const Avatar: FC<Props> = ({ className, email, fullName, src }) => {
  const { initials } = useLogic({ email, fullName });
  const avatarMissing = !src || src[0] === '/';

  return !avatarMissing ? (
    <Image alt="avatar" className={className} src={src} />
  ) : (
    <Initials className={className}>{initials}</Initials>
  );
};

export default memo(Avatar);
