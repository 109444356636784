import { useMemo, useCallback } from 'react';
import { LogicProps } from './types';

const useLogic = ({ onPageChange, page, pageSize, total }: LogicProps) => {
  const nPages = useMemo(() => Math.ceil(total / pageSize), [pageSize, total]);

  const { pageStart, pageEnd } = useMemo(() => {
    const start = (page - 1) * pageSize + 1;
    const end = start + pageSize - 1;
    return { pageStart: start, pageEnd: end > total ? total : end };
  }, [page, pageSize, total]);

  const handleFirstPage = useCallback(() => {
    onPageChange(1);
  }, [onPageChange]);

  const handlePreviousPage = useCallback(() => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  }, [onPageChange, page]);

  const handleNextPage = useCallback(() => {
    if (page < nPages) {
      onPageChange(page + 1);
    }
  }, [nPages, onPageChange, page]);

  const handleLastPage = useCallback(() => {
    onPageChange(nPages);
  }, [nPages, onPageChange]);

  return {
    handleFirstPage,
    handlePreviousPage,
    handleNextPage,
    handleLastPage,
    nPages,
    pageStart,
    pageEnd,
  };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
