import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultCalendarIcon from 'components/Icon/Icons/Calendar';
import DefaultTextField from 'components/Inputs/TextField';

export const Container = styled(DefaultModal)`
  ${Content} {
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TextField = styled(DefaultTextField)`
  &:not(:first-child) {
    margin-top: 1rem;
  }

  ${from.tabletLandscape`
    margin-top: 0 !important;
  `}
`;

export const Row = styled.div`
  display: block;
  margin-top: 1rem;

  ${from.tabletLandscape`
    display: flex;
    > * {
      flex: 0 0 50%;
      width: 50%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  `}
`;

export const Space = styled.div`
  display: block;
  margin-top: 1rem;
`;

export const IconCircle = styled(DefaultIconCircle)`
  margin-left: auto;
  margin-right: auto;
`;

export const Icon = styled(DefaultCalendarIcon).attrs(() => ({
  size: 20,
}))``;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  margin-top: 2.5rem;

  ${from.tabletPortrait`
    flex-flow: row nowrap;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const Button = styled(DefaultButton)`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-right: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    ${from.tabletPortrait`
      margin-right: 0.5rem;
    `}
  }
`;
