import { css } from 'styled-components';

export const prettyScrollbar = ({
  margin,
  vertical = true,
  size = '0.4rem',
}: {
  margin?: string;
  vertical?: boolean;
  size?: string;
}) => css`
  scrollbar-width: ${size};
  scrollbar-color: #bcc6cc;
  &::-webkit-scrollbar {
    ${vertical
      ? css`
          width: ${size};
        `
      : css`
          height: ${size};
        `};
  }
  &::-webkit-scrollbar-track {
    background-color: #e1e1e5;
    ${margin &&
    css`
      margin: ${margin};
    `};
  }
  &::-webkit-scrollbar-thumb {
    background: #bcc6cc;
    border-radius: 4px;
  }
`;
