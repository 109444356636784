import { FC, memo } from 'react';

import type { Props } from './types';

const Help: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fill="none" fillRule="evenodd">
      <rect
        stroke="#0A2737"
        strokeWidth="1.5"
        x=".75"
        y=".75"
        width="22.5"
        height="22.5"
        rx="11.25"
      />
      <text
        fontFamily="Inter-Medium, Inter"
        fontSize="14"
        fontWeight="400"
        fill="#0A2737"
      >
        <tspan x="8.321" y="17">
          ?
        </tspan>
      </text>
    </g>
  </svg>
);

export default memo(Help);
