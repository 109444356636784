import { FC, memo } from 'react';
import { Container } from './styles';
import { Props } from './types';

const IconButton: FC<Props> = ({
  className,
  children,
  isLoading,
  to,
  ...rest
}) => (
  <Container className={className} to={to} isLoading={isLoading} {...rest}>
    {children}
  </Container>
);

export default memo(IconButton);
