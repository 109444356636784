import { DiscountDataFragment, DiscountType } from 'apollo/graphql.types';

export type DiscountFormFields = Readonly<{
  id?: string;
  type: DiscountType;
  name: string;
  percentage: number;
  price: number;
}>;

export const discountTypeOptions = [
  { label: 'cuota', value: DiscountType.Quota },
  { label: 'pedido', value: DiscountType.Product },
  { label: 'producto', value: DiscountType.UnitProduct },
];

export const getDiscountTypeName = (discountType: DiscountType) => {
  switch (discountType) {
    case DiscountType.Product:
      return 'pedido';
    case DiscountType.Quota:
      return 'cuota';
    case DiscountType.UnitProduct:
      return 'producto';
    default:
      return 'producto';
  }
};

export const normalizeDiscount = (input: DiscountDataFragment) => ({
  id: input?.id || '',
  type: input?.type || DiscountType.Product,
  name: input?.name || '',
  percentage: Number(input?.percentage) || 0,
  price: Number(input?.price) || 0,
  partnersIds: input?.partners?.map((partner) => partner.id) || [],
});

export type Discount = ReturnType<typeof normalizeDiscount>;
