import { FC, memo } from 'react';
import { Formik } from 'formik';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  TextField,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const CreditConceptForm: FC<Props> = ({
  className,
  initialValues,
  isLoading,
  onCancel,
  onSubmit,
}) => {
  const formId = `creditConcept_${initialValues.creditConcept}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Container className={className}>
        <TextField
          name="creditConcept"
          label="Nombre del concepto"
          id={`${formId}_creditConcept`}
          autoFocus
        />
        <Buttons>
          <CancelButton onClick={onCancel}>Cancelar</CancelButton>
          <CreateButton type="submit" isLoading={isLoading}>
            {initialValues.creditConcept ? 'Editar' : 'Crear'}
          </CreateButton>
        </Buttons>
      </Container>
    </Formik>
  );
};

export default memo(CreditConceptForm);
