import { FC, memo } from 'react';
import Row from './Row';
import TableHead from './TableHead';
import {
  Container,
  ChevronIcon,
  ExtraTitle,
  Subtitle,
  Title,
  TitleContainer,
  Table,
  Header,
} from './styles';
import { Props } from './types';

const ProductsMetricList: FC<Props> = ({ totalOfLastCreations, items }) => {
  const hasResults = items?.length > 0;
  const itemsCount = items?.length;
  const takenItems = items.slice(0, 10);

  return (
    <Container>
      <Header to="/metrics/products">
        <TitleContainer>
          <Title>
            {itemsCount} <ExtraTitle>Productos</ExtraTitle>
          </Title>
          <Subtitle>{totalOfLastCreations} nuevos este mes</Subtitle>
        </TitleContainer>
        <ChevronIcon />
      </Header>
      <Table>
        {hasResults && (
          <>
            <TableHead />
            {takenItems.map((item) => (
              <Row key={item.product.id} item={item} />
            ))}
          </>
        )}
      </Table>
    </Container>
  );
};

export default memo(ProductsMetricList);
