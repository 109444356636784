import { FC, memo } from 'react';

import type { Props } from './types';

const Users: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>partners icon</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="element/icon/24/list-copy-17"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <path
          d="M17.1456063,15.8664455 L17.4099209,15.8709016 C19.1448949,15.8713807 20.4801072,16.2678456 21.4471939,16.8910179 C22.2011667,17.3768637 22.7247067,17.9987909 23.0097074,18.6528836 L14.951381,19.2172129 C14.4588777,18.2418811 14.1711028,17.5904437 13.8352194,17.0448965 L13.744,16.902 L13.7592307,16.8924233 C14.8692909,16.1613582 16.001188,15.8691161 17.1456063,15.8664455 Z M6.56336288,15.8713795 C8.27155607,15.8713795 9.6067758,16.2678412 10.5738672,16.8910102 C11.3217346,17.3729169 11.8428854,17.9887084 12.1294196,18.6369749 L1.11662645,19.2140426 C1.11993606,18.2434984 1.64935152,17.5014139 2.48170699,16.9415501 C3.45369148,16.2877683 4.81265554,15.8713795 6.56336288,15.8713795 Z M17.4368032,5.75132229 C18.3684737,5.75132229 19.1554265,6.11038003 19.7102155,6.72104908 C20.3019638,7.37240008 20.6277059,8.29453387 20.6277059,9.33913572 C20.6276598,10.5178941 20.2791862,11.4894739 19.7002637,12.1739244 C19.1189286,12.8612273 18.3077881,13.2484295 17.4368032,13.2484295 C16.5657585,13.2484295 15.7545917,12.8612409 15.1732585,12.1739457 C14.5943409,11.4895064 14.2459004,10.5179397 14.2459004,9.33920257 C14.2459004,8.29455456 14.5716415,7.37241144 15.1633896,6.72105497 C15.7181784,6.11038087 16.5051318,5.75132229 17.4368032,5.75132229 Z M6.56343625,5.75132229 C7.49507016,5.75132229 8.28194613,6.11035576 8.83666922,6.72099825 C9.42838525,7.37236278 9.75407653,8.29452957 9.75407653,9.33920257 C9.75407653,10.5179288 9.40564247,11.489488 8.82676209,12.1739289 C8.24546703,12.8612247 7.43436698,13.2484295 6.56343625,13.2484295 C5.69242414,13.2484295 4.88127644,12.8612363 4.29995412,12.1739409 C3.72104711,11.4895012 3.37260849,10.5179366 3.37260849,9.33920257 C3.37260849,8.29454742 3.69833534,7.37239753 4.29007429,6.72103876 C4.84484429,6.1103737 5.63177559,5.75132229 6.56343625,5.75132229 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default memo(Users);
