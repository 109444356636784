import CircularProgress from '@mui/material/CircularProgress';
import DefaultLogo from 'components/Logo';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Logo = styled(DefaultLogo)`
  display: block;
  margin-bottom: 2rem;
  width: 230px;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  color: 'inherit',
  size: 45,
}))`
  color: #fc3e29;
`;
