import {
  object as yupObject,
  string as yupString,
  boolean as yupBoolean,
  array as yupArray,
} from 'yup';
import { DATA_PDFS_KEYS } from 'utils/texts/document';

export const TABLE_METRICS = {
  name: '20%',
  number: '40%',
};

export const partnerData = [
  {
    key: 'Nombre',
    value: DATA_PDFS_KEYS.PARTNER_FIRST_NAME,
  },
  {
    key: 'Apellidos',
    value: DATA_PDFS_KEYS.PARTNER_LAST_NAME,
  },
  {
    key: 'Domicilio',
    value: DATA_PDFS_KEYS.PARTNER_ADDRESS,
  },
  {
    key: 'Fecha de Nacimiento',
    value: DATA_PDFS_KEYS.PARTNER_BIRTHDATE,
  },
  {
    key: 'Nº de socio',
    value: DATA_PDFS_KEYS.PARTNER_NUM,
  },
  {
    key: 'Email',
    value: DATA_PDFS_KEYS.PARTNER_EMAIL,
  },
  {
    key: 'CP',
    value: DATA_PDFS_KEYS.PARTNER_CP,
  },
  {
    key: 'Ciudad',
    value: DATA_PDFS_KEYS.PARTNER_CITY,
  },
  {
    key: 'DNI',
    value: DATA_PDFS_KEYS.PARTNER_DOCUMENT,
  },
  {
    key: 'Teléfono',
    value: DATA_PDFS_KEYS.PARTNER_PHONE,
  },
  {
    key: 'Consumo máximo',
    value: DATA_PDFS_KEYS.PARTNER_MAX_CONSUME,
  },
  {
    key: 'Fecha de inscripción',
    value: DATA_PDFS_KEYS.PARTNER_CREATED,
  },
  {
    key: 'Firma',
    value: DATA_PDFS_KEYS.PARTNER_SIGNATURE,
  },
];

export const hostPartnerData = [
  {
    key: 'Nombre',
    value: DATA_PDFS_KEYS.HOST_PARTNER_FIRST_NAME,
  },
  {
    key: 'Apellidos',
    value: DATA_PDFS_KEYS.HOST_PARTNER_LAST_NAME,
  },
  {
    key: 'Domicilio',
    value: DATA_PDFS_KEYS.HOST_PARTNER_ADDRESS,
  },
  {
    key: 'Fecha de Nacimiento',
    value: DATA_PDFS_KEYS.HOST_PARTNER_BIRTHDATE,
  },
  {
    key: 'Nº de socio',
    value: DATA_PDFS_KEYS.HOST_PARTNER_NUM,
  },
  {
    key: 'Email',
    value: DATA_PDFS_KEYS.HOST_PARTNER_EMAIL,
  },
  {
    key: 'CP',
    value: DATA_PDFS_KEYS.HOST_PARTNER_CP,
  },
  {
    key: 'Ciudad',
    value: DATA_PDFS_KEYS.HOST_PARTNER_CITY,
  },
  {
    key: 'DNI',
    value: DATA_PDFS_KEYS.HOST_PARTNER_DOCUMENT,
  },
  {
    key: 'Teléfono',
    value: DATA_PDFS_KEYS.HOST_PARTNER_PHONE,
  },
  {
    key: 'Consumo máximo',
    value: DATA_PDFS_KEYS.HOST_PARTNER_MAX_CONSUME,
  },
  {
    key: 'Fecha de inscripción',
    value: DATA_PDFS_KEYS.HOST_PARTNER_CREATED,
  },
  {
    key: 'Firma',
    value: DATA_PDFS_KEYS.HOST_PARTNER_SIGNATURE,
  },
];

export const organizationData = [
  {
    key: 'Nombre',
    value: DATA_PDFS_KEYS.ORGANIZATION_NAME,
  },
  {
    key: 'Domicilio',
    value: DATA_PDFS_KEYS.ORGANIZATION_ADDRESS,
  },
  {
    key: 'Email',
    value: DATA_PDFS_KEYS.ORGANIZATION_EMAIL,
  },
  {
    key: 'CP',
    value: DATA_PDFS_KEYS.ORGANIZATION_CP,
  },
  {
    key: 'Ciudad',
    value: DATA_PDFS_KEYS.ORGANIZATION_CITY,
  },
  {
    key: 'CIF',
    value: DATA_PDFS_KEYS.ORGANIZATION_DOCUMENT,
  },
];

export const validationSchema = yupObject({
  name: yupString().required('Este campo es requerido.'),
  hasSignature: yupBoolean(),
  customData: yupArray().of(
    yupObject().shape({
      key: yupString(),
      inputType: yupString(),
    }),
  ),
});
