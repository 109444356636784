import { FC, memo } from 'react';

import type { Props } from './types';

const ProductsSelected: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...rest}
  >
    <g
      transform="translate(5 5)"
      fill="#0A2737"
      stroke="#0A2737"
      fillRule="evenodd"
    >
      <rect x=".5" y=".5" width="5" height="5" rx="1" />
      <rect x="8.5" y=".5" width="5" height="5" rx="1" />
      <rect x=".5" y="8.5" width="5" height="5" rx="1" />
      <rect x="8.5" y="8.5" width="5" height="5" rx="1" />
    </g>
  </svg>
);

export default memo(ProductsSelected);
