import styled, { css } from 'styled-components';
import DefaultButton from '@mui/material/Button';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import type { ContainerProps } from './types';

export const Container = styled(DefaultButton)<ContainerProps>`
  &&& {
    padding: 0.875rem;
    border-radius: 0.25rem;
    border: solid 1px
      ${({ theme, $variant }) => {
        if ($variant === 'secondary') return theme.colors.grey300;
        if ($variant === 'danger') return `${theme.colors.danger}`;
        if ($variant === 'success') return `${theme.colors.green}`;
        if ($variant === 'light') return `${theme.colors.blue100}`;
        if ($variant === 'text') return 'transparent';
        return theme.colors.accent;
      }};
    background-color: ${({ theme, $variant }) => {
      if ($variant === 'secondary') return theme.colors.grey100;
      if ($variant === 'danger') return `${theme.colors.dangerPale}`;
      if ($variant === 'success') return `${theme.colors.green}`;
      if ($variant === 'light') return `${theme.colors.white}`;
      if ($variant === 'text') return 'transparent';
      return theme.colors.accent;
    }};
    color: ${({ theme, $variant }) => {
      if ($variant === 'secondary') return theme.colors.grey500;
      if ($variant === 'danger') return theme.colors.danger;
      if ($variant === 'text') return theme.colors.grey900;
      if ($variant === 'light') return theme.colors.accent;

      return theme.colors.white;
    }};
    font-weight: ${({ theme, $variant }) =>
      $variant === 'text' ? theme.weights.regular : theme.weights.semiBold};
    font-size: 1rem;

    &:hover,
    &:focus {
      ${({ theme, $variant }) =>
        $variant === 'text' &&
        css`
          color: ${theme.colors.accent};
        `}
    }

    &:disabled,
    .Mui-disabled {
      opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0.6')};
      cursor: default;
      pointer-events: none;
    }
  }
`;

export const Label = styled.div<{ $isLoading: boolean }>`
  ${({ $isLoading }) =>
    !!$isLoading &&
    css`
      opacity: 0;
    `}
`;

export const Loader = styled(CircularProgress).attrs<CircularProgressProps>(
  () => ({ size: 20, color: 'inherit' }),
)`
  color: inherit;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
`;
