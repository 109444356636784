import { FC, memo } from 'react';
import {
  Container,
  InfoProduct,
  Label,
  Price,
  Thumbnail,
  Image,
  ImageContainer,
  ReferenceCode,
} from './styles';
import type { Props } from './types';

const LandscapeFormat: FC<Props> = ({
  activateProductReference,
  onSelect,
  product,
}) => {
  const { abbreviation, name, price } = product;

  return (
    <Container onClick={onSelect}>
      {!product.mainImageUrl ? (
        <Thumbnail>{abbreviation}</Thumbnail>
      ) : (
        <ImageContainer>
          <Image src={product.mainImageUrl} />
        </ImageContainer>
      )}

      <InfoProduct>
        <Label>
          {name} ({product.quantity.toFixed(2)})
        </Label>
        {activateProductReference && product.referenceCode && (
          <ReferenceCode>#{product.referenceCode}</ReferenceCode>
        )}
        <Price>{price}</Price>
      </InfoProduct>
    </Container>
  );
};

export default memo(LandscapeFormat);
