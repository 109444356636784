import type { FC } from 'react';
import { UserRole } from 'apollo/generated/globalTypes';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import useConnect from './connect';
import {
  AddIcon,
  Button,
  ChevronRightIcon,
  Container,
  Header,
  IconCircle,
  Label,
  MenuButton,
  Icon,
  Title,
  DangerIcon,
  Menus,
  ButtonContent,
} from './styles';

const PartnerAdvancedMenu: FC = () => {
  const {
    handleOpenOrder,
    isOpen,
    handleClose,
    handleRemovePartner,
    handleOpenLoadCredits,
    handleOpenGenerateFiles,
    handleOpenConvertToEmployee,
    handleOpenAssignDiscounts,
    subscription,
    userRole,
    isEmployee,
    employeePermissions,
  } = useConnect();

  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <AddIcon />
        </IconCircle>
        <Title>Opciones Avanzadas</Title>
      </Header>

      <Menus>
        {!fromTabletPortrait && (
          <MenuButton type="button" onClick={handleOpenOrder}>
            <IconCircle>
              <Icon name="orders" size={16} />
            </IconCircle>
            <Label>Pedido</Label>
            <ChevronRightIcon />
          </MenuButton>
        )}

        <MenuButton
          type="button"
          onClick={() => handleOpenLoadCredits('added')}
        >
          <IconCircle>
            <Icon name="cr" size={16} />
          </IconCircle>
          <Label>Añadir Créditos</Label>
          <ChevronRightIcon />
        </MenuButton>

        {subscription.allowedGenerateFiles &&
          employeePermissions.allowAssignContract && (
            <MenuButton type="button" onClick={handleOpenGenerateFiles}>
              <IconCircle>
                <Icon name="uploadFile" size={16} />
              </IconCircle>
              <Label>Generar Documento</Label>
              <ChevronRightIcon />
            </MenuButton>
          )}

        {subscription.allowedDiscounts &&
          employeePermissions.allowAssignDiscounts && (
            <MenuButton type="button" onClick={handleOpenAssignDiscounts}>
              <IconCircle>
                <Icon name="discount" size={16} />
              </IconCircle>
              <Label>Asignar descuentos</Label>
              <ChevronRightIcon />
            </MenuButton>
          )}

        {subscription.allowedEmployees &&
          userRole === UserRole.ADMIN &&
          !isEmployee && (
            <MenuButton type="button" onClick={handleOpenConvertToEmployee}>
              <IconCircle>
                <Icon name="employees" size={16} />
              </IconCircle>
              <Label>Convertir a empleado</Label>
              <ChevronRightIcon />
            </MenuButton>
          )}

        <MenuButton
          type="button"
          onClick={() => handleOpenLoadCredits('retire')}
        >
          <IconCircle>
            <Icon name="coin" size={20} />
          </IconCircle>
          <Label>Retirar Créditos</Label>
          <ChevronRightIcon />
        </MenuButton>

        {employeePermissions.allowRemovePartners && (
          <MenuButton type="button" onClick={handleRemovePartner}>
            <IconCircle $isDanger>
              <DangerIcon name="trash" size={16} />
            </IconCircle>
            <Label>Eliminar Socio</Label>
            <ChevronRightIcon />
          </MenuButton>
        )}
      </Menus>

      <ButtonContent>
        <Button onClick={handleClose}>Cancelar</Button>
      </ButtonContent>
    </Container>
  );
};

export default PartnerAdvancedMenu;
