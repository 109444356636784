import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  Container,
  Benefit,
  Quantity,
  Name,
  Costs,
  RealQuantity,
  Orders,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, productName }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <Name>{productName}</Name>
      <Benefit>Ganancias</Benefit>
      <Costs>Costes</Costs>
      <Quantity>Unidades</Quantity>
      <RealQuantity>Unidades Reales</RealQuantity>
      {fromTabletPortrait && <Orders>N° Ventas</Orders>}
    </Container>
  );
};

export default memo(Row);
