import { FC, memo } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, IconCircle, Icon, Header } from './styles';

const CloseCashierModal: FC = () => {
  const {
    adjustState,
    handleClose,
    handleSubmit,
    initialValues,
    isFirstSave,
    isLoading,
    isOpen,
    handleChangeRealCreditsAdded,
    strictMode,
    handleOpenTypesOfCash,
    typesOfCash,
    formikRef,
  } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="cashier" size={24} />
        </IconCircle>
        <Title>Cierre de caja</Title>
      </Header>
      <Form
        adjustState={adjustState}
        initialValues={initialValues}
        isFirstSave={isFirstSave}
        isLoading={isLoading}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        onChangeRealCreditsAdded={handleChangeRealCreditsAdded}
        strictMode={strictMode}
        onOpenTypesOfCash={handleOpenTypesOfCash}
        typesOfCash={typesOfCash}
        formikRef={formikRef}
      />
    </Container>
  );
};

export default memo(CloseCashierModal);
