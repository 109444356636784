import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import { from } from 'styles/responsive';
import {
  AddStock,
  BaseWeight,
  Container,
  CurrentQuantity,
  DownIcon,
  IconContainer,
  Image,
  ImageContainer,
  Name,
  ReferencedCode,
  RetireStock,
  SetIcon,
  SetStock,
  StoreIcon,
  StoreMovement,
  UpIcon,
} from './styles';
import type { Props } from './types';

const DEFAULT_IMAGE =
  'https://d3e9urqlsf1dy8.cloudfront.net/images/defaults/default-product-image.png';

const Row: FC<Props> = ({ className, product, onClick }) => {
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <ImageContainer>
        <Image src={product.mainImageUrl || DEFAULT_IMAGE} />
      </ImageContainer>
      <Name>
        {product.name}
        {organizationConfig?.activateProductReference &&
          product.referenceCode && (
            <ReferencedCode>#{product.referenceCode}</ReferencedCode>
          )}
      </Name>
      <CurrentQuantity>{product.quantity}</CurrentQuantity>
      {fromTabletPortrait && <BaseWeight>{product.baseWeight}</BaseWeight>}
      <AddStock>
        <IconContainer onClick={() => onClick(product, 'add')}>
          <UpIcon />
        </IconContainer>
      </AddStock>
      <RetireStock>
        <IconContainer onClick={() => onClick(product, 'remove')}>
          <DownIcon />
        </IconContainer>
      </RetireStock>
      <SetStock>
        <IconContainer onClick={() => onClick(product, 'set')}>
          <SetIcon />
        </IconContainer>
      </SetStock>
      <StoreMovement>
        <IconContainer onClick={() => onClick(product, 'store')}>
          <StoreIcon />
        </IconContainer>
      </StoreMovement>
    </Container>
  );
};

export default memo(Row);
