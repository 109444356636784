import type { FC } from 'react';
import {
  Container,
  Heading,
  Icon,
  Label,
  Link,
  Logo,
  Navigation,
} from './styles';
import { Props } from './types';

const PartnerSidebar: FC<Props> = ({ onLogout, partnerName }) => (
  <Container>
    <Heading>
      <Logo />
    </Heading>
    <Navigation>
      <Link to="/">
        <Icon name="more" />
        <Label>{partnerName}</Label>
      </Link>
      <Link to="/orders">
        <Icon name="orders" />
        <Label>Pedidos</Label>
      </Link>
      <Link to="/logout" onClick={onLogout}>
        <Icon name="logout" />
        <Label>Cerrar sesión</Label>
      </Link>
    </Navigation>
  </Container>
);

export default PartnerSidebar;
