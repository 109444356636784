import { FC, memo } from 'react';
import {
  Container,
  Credits,
  Date,
  Employee,
  ExtraQuantity,
  Partner,
  Quantity,
} from './styles';
import type { Props } from './types';

const TableHead: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Employee>Empleado</Employee>
      <Partner>Socio</Partner>
      <Date>Fecha</Date>
      <Quantity>Cantidad</Quantity>
      <ExtraQuantity>Cantidad Real</ExtraQuantity>
      <Credits>Total</Credits>
    </Container>
  );
};

export default memo(TableHead);
