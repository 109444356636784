import styled from 'styled-components';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { from } from 'styles/responsive';

export const Container = styled(DefaultModal)`
  ${Content} {
    width: 100%;

    ${from.tabletPortrait`
      max-width: 55rem;
    `}
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
`;

export const Title = styled(ModalTitle)`
  text-transform: capitalize;
  margin: 1rem auto 3.5625rem;
`;

export const Label = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.primary};
`;
