import styled, { css } from 'styled-components';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.primary}0A;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.name};
  ${TextEllipsis};
`;
