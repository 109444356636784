import styled, { css } from 'styled-components';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';
import DefaultFilters from 'components/Filters';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 1rem auto 0 auto;
`;

export const FiltersContainer = styled.div`
  padding: 0 1.4rem;
  margin-top: 2rem;

  ${from.tabletLandscape`
    margin-top: 0;
  `};
`;

export const Filters = styled(DefaultFilters)`
  padding: 0;
  width: 100%;
`;

export const CategoriesFilter = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const CategoryName = styled.span`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 0.8rem;
  text-transform: capitalize;
  transition: color 200ms ease;

  ${from.tabletPortrait`
    font-size: 0.9rem;
  `};
`;

export const CategoryFilter = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  padding: 0.5rem 0.8rem;
  transition: border-color 200ms ease;

  ${from.tabletPortrait`
    padding: 0.625rem 1.8rem;
  `};

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey900};

    ${CategoryName} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ theme, $selected }) =>
    $selected &&
    css`
      border-color: ${theme.colors.accent};

      ${CategoryName} {
        color: ${theme.colors.accent};
      }
    `}
`;
