import type { QueryOptions } from '@apollo/client';
import type { Me } from 'apollo/generated/Me';
import { GET_ME } from 'apollo/queries/users';
import { normalizeUser } from 'model/User';
import { apolloClient } from 'services/api';
import { userRoleVar } from 'apollo/reactive';

const getMe = async (options: Omit<QueryOptions<Me>, 'query'> = {}) => {
  const { data } = await apolloClient.query<Me>({
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    query: GET_ME,
    ...options,
  });

  if (!data?.me) {
    return { me: undefined };
  }

  const user = normalizeUser(data.me);

  if (user.role) {
    userRoleVar(user.role);
  }

  return {
    me: user,
  };
};

export default getMe;
