import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useEmployeeActions from 'apollo/hooks/employee/useEmployeeActions';
import useModal from 'apollo/hooks/useModal';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { Props } from './types';

const useLogic = (employeeId: Props['employeeId']) => {
  const { removeSchedule } = useEmployeeActions();
  const { openDialog, close, openAddWorkSchedule } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmRemoveSchedule = useCallback(
    async (scheduleId: string) => {
      try {
        await removeSchedule(scheduleId);
        close();
        enqueueSnackbar('El tramo horario se ha eliminado correctamente', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('employee', e.message, 'eliminar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [removeSchedule, close, enqueueSnackbar],
  );

  const handleRemoveSchedule = useCallback(
    (scheduleId: string) => {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar este tramo horario y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: () => handleConfirmRemoveSchedule(scheduleId),
        title: 'Eliminar tramo',
        variant: 'danger',
      });
    },
    [openDialog, handleConfirmRemoveSchedule],
  );

  const handleOpenAddWorkScheduleModal = useCallback(() => {
    openAddWorkSchedule({ employeeId });
  }, [employeeId, openAddWorkSchedule]);

  return {
    handleRemoveSchedule,
    handleOpenAddWorkScheduleModal,
  };
};

export default useLogic;
