import { FC, memo } from 'react';
import type { Props } from './types';

const Equal: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={size}
    height={size}
    {...rest}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      id="icon-bound"
      fill="none"
      d="M0 0h16v16H0z"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      d="M1 11h14V9H1v2zm0-6v2h14V5H1z"
    />
  </svg>
);

export default memo(Equal);
