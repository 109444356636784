import { useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikConfig, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useCategories from 'apollo/hooks/category/useCategories';
import { ProductFormFields } from 'model/Product';
import useProductActions from 'apollo/hooks/product/useProductActions';
import {
  cleanStore,
  organizationConfigVar,
  productStateVar,
  resetProductStateVar,
  updateProductStateVar,
} from 'apollo/reactive';
import { CategoryType } from 'apollo/generated/globalTypes';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';

const useConnect = () => {
  const { close, type, openSelectCategories, openNewProduct } = useModal();
  const { categories, refetch: refetchCategories } = useCategories({
    filter: { type: CategoryType.PRODUCT },
    fetchPolicy: 'network-only',
  });
  const { createProduct, loading: productActionsLoading } = useProductActions();
  const { ...fields } = useReactiveVar(productStateVar);
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const formikRef = useRef<FormikProps<ProductFormFields>>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const initialValues = useMemo<ProductFormFields>(
    () => ({
      baseWeight: fields.baseWeight || ('' as unknown as number),
      categoryIds: fields.categoryIds || [],
      extraQuantitiesIds: fields.extraQuantitiesIds || [],
      description: fields.description || '',
      name: fields.name || '',
      referenceCode: fields.referenceCode || '',
      costs: fields.costs || ('' as unknown as number),
      price: fields.price || ('' as unknown as number),
      taxes: fields.taxes || ('' as unknown as number),
      quantity: fields.quantity || ('' as unknown as number),
      storeQuantity: fields.storeQuantity || ('' as unknown as number),
      isActive: fields.isActive || true,
      showInMenu: fields.showInMenu || true,
      countToMaxConsume: fields.countToMaxConsume || false,
    }),
    [fields],
  );

  const handleClose = useCallback(() => {
    cleanStore();
    close();
  }, [close]);

  const handleOnSelectOptions = useCallback(async () => {
    await refetchCategories();
    openNewProduct();
  }, [openNewProduct, refetchCategories]);

  const handleOpenCategoriesSelection = useCallback(async () => {
    if (formikRef?.current?.values) {
      const { values } = formikRef.current;
      updateProductStateVar({ ...values });
    }

    const modalCategories = categories.map((c) => ({
      ...c,
      isChecked: !!fields?.categoryIds?.includes(c.id),
    }));

    openSelectCategories({
      categories: modalCategories,
      onSelectCategories: handleOnSelectOptions,
      onReturn: handleOnSelectOptions,
      type: CategoryType.PRODUCT,
    });
  }, [categories, fields, handleOnSelectOptions, openSelectCategories]);

  const handleSubmit = useCallback<FormikConfig<ProductFormFields>['onSubmit']>(
    async (data) => {
      try {
        const product = await createProduct({ ...data, description: 'test' });
        resetProductStateVar();
        enqueueSnackbar(
          `El producto ${data.name} ha sido creado correctamente`,
          { variant: 'success' },
        );
        if (product) {
          navigate(`/products/${product.id}`);
        }
        close();
      } catch (e) {
        enqueueSnackbar(formatErrors('product', e.message, 'crear'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [close, createProduct, enqueueSnackbar, navigate],
  );

  return {
    handleOpenCategoriesSelection,
    handleClose,
    handleSubmit,
    isOpen: type === ModalType.NEW_PRODUCT,
    formikRef,
    initialValues,
    activateProductReference: organizationConfig.activateProductReference,
    isLoading: productActionsLoading,
  };
};

export default useConnect;
