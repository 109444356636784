import { DayOfWeek } from 'apollo/generated/globalTypes';
import type { EmployeeDataFragment } from 'apollo/graphql.types';
import { normalizeBaseUser } from './User';
import { normalizePermissions } from './PermissionsGroup';

export type EmployeeFormFields = {
  id?: string;
  document: string;
  permissionsGroupId?: string;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  phoneNumber: string;

  // Images
  avatarImageId?: string;
  avatarImageIsUploading?: boolean;
  avatarImageUrl?: string;
  avatarImageSize?: number;
};

export type PasswordFormFields = {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
};

export type WorkScheduleFormFields = {
  daysOfWeek: DayOfWeek[];
  schedules: Array<{ startTime: string; endTime: string }>;
};

export const MappedDayOfWeek = {
  [DayOfWeek.MONDAY]: 'lunes',
  [DayOfWeek.TUESDAY]: 'martes',
  [DayOfWeek.WEDNESDAY]: 'miercoles',
  [DayOfWeek.THURSDAY]: 'jueves',
  [DayOfWeek.FRIDAY]: 'viernes',
  [DayOfWeek.SATURDAY]: 'sabado',
  [DayOfWeek.SUNDAY]: 'domingo',
};

export const normalizeEmployee = (input: EmployeeDataFragment) => ({
  // Primary Key
  id: input.id || '',

  // Employee Fields
  monthlyBilling: input.monthlyBilling || 0.0,
  monthlySales: input.monthlySales || 0,
  partnerNum: input.partnerNum || '0',
  // @ts-ignore TODO pending type migrations
  permissions: normalizePermissions(input.permissions),
  logins:
    input?.logins?.map((login) => ({
      id: login.id || '',
      date: login.date ? new Date(login.date) : new Date(),
      endSession: login.endSession ? new Date(login.endSession) : null,
    })) || [],
  workSchedules:
    input.workSchedules && input.workSchedules?.length > 0
      ? input.workSchedules?.map((workSchedule) => ({
          id: workSchedule.id || '',
          daysOfWeek: workSchedule.dayOfWeek || [],
          schedules: workSchedule.schedules.map((schedule) => ({
            startTime: schedule.startTime || '',
            endTime: schedule.endTime || '',
          })),
        }))
      : [],

  // User Fields
  // @ts-ignore TODO pending type migrations
  ...normalizeBaseUser(input.user),
});

export type Employee = ReturnType<typeof normalizeEmployee>;
