import { useCallback, useMemo, useState } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useProductActions from 'apollo/hooks/product/useProductActions';
import { QuantityMovementType } from 'apollo/graphql.types';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { ProductAdjustQuantityFormFields } from 'model/Product';

const useConnect = () => {
  const [isFirstSave, setIsFirstSave] = useState(true);
  const [showAdjust, setShowAdjust] = useState(false);
  const { adjustProductQuantity, loading } = useProductActions();
  const { close, type, moveStoreToStockPayload } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { product, reloadPage } = moveStoreToStockPayload || {};

  const initialValues = useMemo(
    () => ({
      name: product?.name || '',
      quantity: product?.storeQuantity
        ? Number(product.storeQuantity.toFixed(2))
        : 0.0,
      newQuantity: '' as unknown as number,
      note: '',
      movementType: QuantityMovementType.FromStore,
      currentProductStock: product?.quantity
        ? Number(product.quantity.toFixed(2))
        : 0,
    }),
    [product],
  );

  const handleClose = useCallback(() => {
    setShowAdjust(false);
    setIsFirstSave(true);
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<ProductAdjustQuantityFormFields>['onSubmit']
  >(
    async (data) => {
      const newQuantity = Number(data?.newQuantity || 0);

      try {
        if (isFirstSave) {
          setShowAdjust(true);
          setIsFirstSave(false);
        } else if (product?.id) {
          await adjustProductQuantity(product.id, {
            ...data,
            newQuantity,
          });
          enqueueSnackbar(
            `Se ha transferido del almacén al stock correctamente`,
            {
              variant: 'success',
            },
          );
          if (reloadPage) {
            window.location.reload();
          }
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('product', e.message, 'ajustar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [
      isFirstSave,
      product,
      adjustProductQuantity,
      enqueueSnackbar,
      reloadPage,
      handleClose,
    ],
  );

  const handleChangeNewQuantity = useCallback(async () => {
    setIsFirstSave(true);
  }, []);

  return {
    showAdjust,
    handleClose,
    handleSubmit,
    initialValues,
    isFirstSave,
    handleChangeNewQuantity,
    isLoading: loading,
    isOpen: type === ModalType.MOVE_STORE_TO_STOCK,
  };
};

export default useConnect;
