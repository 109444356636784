import styled from 'styled-components';
import { SIDEBAR_WIDTH } from 'components/Sidebars/OrganizationSidebar/styles';
import { from } from 'styles/responsive';

export const Container = styled.button`
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 0.25rem;
  border: none;
  bottom: 4.2rem;
  color: white;
  cursor: pointer;
  left: 1rem;
  padding: 0.875rem;
  position: fixed;
  text-align: unset;
  width: calc(100vw - 2rem);

  ${from.tabletLandscape`
    bottom: 16px;
    left: calc(50% + ${SIDEBAR_WIDTH} / 2);
    transform: translateX(-50%);
    width: 20rem;
  `};
`;
