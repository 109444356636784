import { FC } from 'react';
import { Container, Label, Price } from './styles';
import type { Props } from './types';

const OrderFloatingAction: FC<Props> = ({ count, total }) => (
  <Container>
    <Label>({count}) Pedido en curso</Label>
    <Price>{total}</Price>
  </Container>
);

export default OrderFloatingAction;
