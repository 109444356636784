import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import type {
  GetMenuDataQuery,
  GetMenuDataQueryVariables,
} from 'apollo/graphql.types';
import { GET_ORGANIZATION_MENU_DATA } from 'apollo/queries';
import { normalizeOrganizationMenuData } from 'model/Organization';

const useMenuData = (menuCode?: string) => {
  const { data, loading, refetch } = useQuery<
    GetMenuDataQuery,
    GetMenuDataQueryVariables
  >(GET_ORGANIZATION_MENU_DATA, {
    skip: !menuCode,
    variables: {
      menuCode: menuCode || '',
    },
  });

  const menuData = useMemo(
    () =>
      data?.getMenuData
        ? normalizeOrganizationMenuData(data.getMenuData)
        : undefined,
    [data],
  );

  return {
    loading,
    menuData,
    refetch,
  };
};

export default useMenuData;
