import { gql } from '@apollo/client';
import {
  ORGANIZATION_DOCUMENT_FRAGMENT,
  ORGANIZATION_FRAGMENT,
  ORGANIZATION_MENU_CONFIG_FRAGMENT,
  ORGANIZATION_MENU_DATA_FRAGMENT,
  ORGANIZATION_PARTNER_FORM_FRAGMENT,
  ORGANIZATION_TRANSACTIONS_FRAGMENT,
  TEMPORARY_PARTNER,
} from 'apollo/fragments/Organization';

export const GET_ORGANIZATION = gql`
  query GetOrganization($id: ID!) {
    organization(id: $id) {
      ...OrganizationData
    }
  }

  ${ORGANIZATION_FRAGMENT}
`;

export const GET_ORGANIZATION_MENU_DATA = gql`
  query GetMenuData($menuCode: String!) {
    getMenuData(menuCode: $menuCode) {
      ...OrganizationMenuDataData
    }
  }
  ${ORGANIZATION_MENU_DATA_FRAGMENT}
`;

export const GET_ORGANIZATION_MENU_CONFIG = gql`
  query GetOrganizationMenuConfig($id: ID!) {
    getMenuConfiguration(id: $id) {
      ...OrganizationMenuConfigData
    }
  }
  ${ORGANIZATION_MENU_CONFIG_FRAGMENT}
`;

export const GET_ORGANIZATION_MENUS = gql`
  query GetOrganizationMenus {
    organizationMenus {
      ...OrganizationMenuConfigData
    }
  }
  ${ORGANIZATION_MENU_CONFIG_FRAGMENT}
`;

export const GET_ORGANIZATION_DOCUMENT = gql`
  query GetDocument($documentId: ID!) {
    getDocument(id: $documentId) {
      ...OrganizationDocumentData
    }
  }
  ${ORGANIZATION_DOCUMENT_FRAGMENT}
`;

export const GET_ORGANIZATION_DOCUMENTS = gql`
  query GetDocuments {
    getDocuments {
      ...OrganizationDocumentData
    }
  }
  ${ORGANIZATION_DOCUMENT_FRAGMENT}
`;

export const GET_ORGANIZATION_TRANSACTIONS = gql`
  query GetOrganizationTransactions($filters: OrganizationTransactionsFilters) {
    organizationTransactions(filters: $filters) {
      ...OrganizationTransactionsData
    }
  }
  ${ORGANIZATION_TRANSACTIONS_FRAGMENT}
`;

export const GET_PARTNER_FORM_CONFIG = gql`
  query GetPartnerFormConfig {
    getPartnerFormConfig {
      ...OrganizationPartnerFormConfigData
    }
  }
  ${ORGANIZATION_PARTNER_FORM_FRAGMENT}
`;

export const GET_TEMPORARY_PARTNERS = gql`
  query GetTemporaryPartners {
    getTemporaryPartners {
      ...TemporaryPartnerData
    }
  }
  ${TEMPORARY_PARTNER}
`;

export const GET_TEMPORARY_PARTNER = gql`
  query GetTemporaryPartner($id: ID!) {
    getTemporaryPartner(id: $id) {
      ...TemporaryPartnerData
    }
  }
  ${TEMPORARY_PARTNER}
`;

export const GET_PARTNER_FORM_DATA = gql`
  query GetPartnerFormData($formCode: String!) {
    getPartnerFormData(formCode: $formCode) {
      ...OrganizationPartnerFormConfigData
    }
  }
  ${ORGANIZATION_PARTNER_FORM_FRAGMENT}
`;
