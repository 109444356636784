import { useCallback, useMemo } from 'react';
import useModal from 'apollo/hooks/useModal';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, purchaseDetailsPayload, type } = useModal();
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const { orders, discount } = purchaseDetailsPayload || {};

  const totalPrice = useMemo(
    () =>
      orders?.reduce((a, b) => a + b.productPrice * b.quantity, 0).toFixed(2),
    [orders],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  return {
    activateProductReference: organizationConfig.activateProductReference,
    handleClose,
    isOpen: type === ModalType.PURCHASE_DETAILS,
    isProductEdited: false,
    orders,
    totalPrice,
    discount,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
