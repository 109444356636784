import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  Balance,
  ChevronSpace,
  Container,
  Identifier,
  Image,
  Name,
  Number,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <Image />
      <Number>Nº{fromTabletPortrait ? ' de socio' : ''}</Number>
      <Name>Nombre</Name>
      {fromTabletPortrait && <Identifier>Nº padrino</Identifier>}
      <Balance>Saldo</Balance>
      {fromTabletPortrait && <ChevronSpace />}
    </Container>
  );
};

export default memo(Row);
