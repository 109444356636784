import { FC, memo, useState } from 'react';
import { S3Url } from 'config';
import {
  Container,
  SidebarOption,
  Sidebar,
  SidebarOptionContent,
  Content,
  ContentTitle,
  ContentSteps,
  ContentStep,
  ContentStepDesc,
  ContentStepImg,
} from './styles';
import { Props } from './types';

const SectionContent: FC<Props> = ({ section }) => {
  const [selectedSection, setSelectedSection] = useState(section.sections[0]);

  const hasSections = section.sections.length > 0;

  return (
    <Container>
      {!hasSections && 'Sección en construcción, disculpa las molestias'}
      {hasSections && (
        <>
          <Sidebar>
            {section.sections.map((section) => (
              <SidebarOptionContent
                $selected={selectedSection.link === section.link}
              >
                <SidebarOption onClick={() => setSelectedSection(section)}>
                  {section.link}
                </SidebarOption>
              </SidebarOptionContent>
            ))}
          </Sidebar>
          <Content>
            <ContentTitle>{selectedSection.title}</ContentTitle>
            <ContentSteps>
              {selectedSection.steps.map((step) => (
                <ContentStep>
                  <ContentStepDesc>- {step.text}</ContentStepDesc>
                  {step.imageUrl && (
                    <ContentStepImg
                      src={`${S3Url}/${step.imageUrl}`}
                      alt={step.imageAlt}
                    />
                  )}
                </ContentStep>
              ))}
            </ContentSteps>
          </Content>
        </>
      )}
    </Container>
  );
};

export default memo(SectionContent);
