import type { Employee } from 'model/Employee';
import { formatDateToTableExtended2 } from '../dates/formatDates';

export const employeeRegistersHeaders = [
  { label: 'Fecha inicio', key: 'startDate' },
  { label: 'Fecha fin', key: 'endDate' },
];

export const getEmployeesRegistersData = (logins?: Employee['logins']) => {
  const data: Array<Record<string, unknown>> = [];

  logins?.forEach((login) => {
    data.push({
      startDate: formatDateToTableExtended2(login.date),
      endDate: login.endSession
        ? formatDateToTableExtended2(login.endSession)
        : 'EN CURSO',
    });
  });

  return data;
};
