import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
  boolean as yupBoolean,
} from 'yup';

const requiredMsg = 'Este campo es requerido';

export const validationSchema = yupObject({
  email: yupString().email('Introduce un email valido').required(requiredMsg),
  name: yupString().required(requiredMsg),
  description: yupString(),
  phone: yupNumber().required(requiredMsg),
  privacyPolicy: yupBoolean().oneOf([true], ''),
  legalNotice: yupBoolean().oneOf([true], ''),
  cookiesPolicy: yupBoolean().oneOf([true], ''),
});

export const initialValues = {
  email: '',
  name: '',
  description: '',
  privacyPolicy: false,
  legalNotice: false,
  cookiesPolicy: false,
  phone: '' as unknown as number,
};
