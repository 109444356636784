export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const normalizeFilename = (filename: string) =>
  filename.replace(/\s/g, '-').replace(/([^\w-.]|[.](?=.*[.]))/g, '');
