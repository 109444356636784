import type { FC } from 'react';
import ExpenseDetailsModal from 'containers/Modals/ExpenseDetails';
import Header from 'containers/Common/Header';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import {
  Container,
  EmptyState,
  Filters,
  Main,
  ListContent,
  FiltersContainer,
  TagsFilter,
  TagFilter,
  TagName,
} from './styles';

const Expenses: FC = () => {
  const {
    expenses,
    filter,
    handleOpenNewExpense,
    handleRemoveExpenseDialog,
    handleSearch,
    isRefetching,
    employeePermissions,
    expensesTags,
    isSelectedTag,
    handleToggleTag,
    handleOpenExpenseDetails,
  } = useConnect();

  const hasResults = expenses?.length > 0;
  const hasExpensesTags = expensesTags.length > 0;

  return (
    <>
      <Container>
        <Header title="Gastos">
          <FiltersContainer>
            <Filters
              isLoading={isRefetching}
              onSearchChange={handleSearch}
              filter={filter}
              nResults={expenses?.length || 0}
            />
            {hasExpensesTags && (
              <TagsFilter>
                {expensesTags.map((expensesTag) => (
                  <TagFilter
                    key={expensesTag}
                    $selected={isSelectedTag(expensesTag)}
                    onClick={() => handleToggleTag(expensesTag)}
                  >
                    <TagName>{expensesTag}</TagName>
                  </TagFilter>
                ))}
              </TagsFilter>
            )}
          </FiltersContainer>
        </Header>
        <Main>
          {!hasResults && employeePermissions.allowCreateExpenses && (
            <EmptyState
              title="No hay gastos"
              iconName="expenses"
              buttonLabel="Crear gasto"
              onClickButton={handleOpenNewExpense}
            >
              Aún no has creado ningún gasto, crea al menos uno para mostrar la
              información
            </EmptyState>
          )}

          {hasResults && (
            <ListContent>
              <TableHead />
              {expenses.map((expense) => (
                <Row
                  key={expense.id}
                  expense={expense}
                  employeePermissions={employeePermissions}
                  onRemove={handleRemoveExpenseDialog}
                  onClick={() => handleOpenExpenseDetails(expense)}
                />
              ))}
            </ListContent>
          )}
        </Main>
      </Container>
      <ExpenseDetailsModal />
    </>
  );
};

export default Expenses;
