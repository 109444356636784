import { FC } from 'react';

import {
  BorderContainer,
  Button,
  Container,
  Input,
  SearchIcon,
} from './styles';
import type { Props } from './types';

const Search: FC<Props> = ({ buttonIsAccent, buttonLabel, buttonOnClick }) => {
  const showButton = !!buttonLabel;
  return (
    <Container>
      <SearchIcon />
      <BorderContainer>
        <Input placeholder="Buscar..." />
        {showButton && (
          <Button $isAccent={!!buttonIsAccent} onClick={buttonOnClick}>
            {buttonLabel}
          </Button>
        )}
      </BorderContainer>
    </Container>
  );
};

export default Search;
