import { FC, memo } from 'react';
import { Container, CreationDate, LastUpdateDate, Name } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Name>Nombre</Name>
      <CreationDate>Fecha de creación</CreationDate>
      <LastUpdateDate>Última actualización</LastUpdateDate>
    </Container>
  );
};

export default memo(Row);
