import { FC } from 'react';
import useConnect from './connect';
import {
  AddIcon,
  Button,
  ChevronRightIcon,
  Container,
  CreditsIcon,
  Header,
  IconCircle,
  Label,
  MenuButton,
  OrdersIcon,
  // PasswordIcon,
  Title,
} from './styles';

const PartnerMenu: FC = () => {
  const { isOpen, handleClose, handleOpenNewOrder, handleOpenLoadCredits } =
    useConnect();

  return (
    <Container open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <AddIcon />
        </IconCircle>
        <Title>¿Qué quieres realizar?</Title>
      </Header>
      <MenuButton type="button" onClick={handleOpenNewOrder}>
        <IconCircle>
          <OrdersIcon />
        </IconCircle>
        <Label>Nuevo pedido</Label>
        <ChevronRightIcon />
      </MenuButton>
      <MenuButton type="button" onClick={handleOpenLoadCredits}>
        <IconCircle>
          <CreditsIcon />
        </IconCircle>
        <Label>Añadir Créditos</Label>
        <ChevronRightIcon />
      </MenuButton>
      <Button onClick={handleClose}>Cancelar</Button>
    </Container>
  );
};

export default PartnerMenu;
