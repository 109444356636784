import { FC, memo } from 'react';

import type { Props } from './types';

const Minus: FC<Props> = ({ size = 12, ...rest }) => (
  <svg
    width={size}
    height={(size * 2) / 12}
    viewBox="0 0 12 2"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect
      transform="rotate(90 10.5 3.5)"
      x="7.25"
      y="2"
      width="1.5"
      height="12"
      rx=".75"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(Minus);
