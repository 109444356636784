import MaterialInput from '@mui/material/Input';
import styled, { css } from 'styled-components';
import DefaultSearchIcon from 'components/Icon/Icons/Search';
import { from } from 'styles/responsive';

export const SEARCH_HEIGHT = '2.5rem';

export const BorderContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}0A;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin-left: 2rem;
  margin-right: 1.125rem;

  min-height: ${SEARCH_HEIGHT};
`;

export const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  display: flex;
  padding: 0 0 0 1rem;

  :focus-within {
    ${BorderContainer} {
      border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
    }
  }
`;

export const SearchIcon = styled(DefaultSearchIcon).attrs(() => ({
  size: 24,
}))``;

export const Input = styled(MaterialInput).attrs({
  disableUnderline: true,
})``;

export const Button = styled.button<{ $isAccent: boolean }>`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.grey900};
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: auto;
  margin-right: 1rem;
  padding: 0;

  ${from.tabletLandscape`
    margin-right: 0;
  `}

  ${({ $isAccent }) =>
    $isAccent &&
    css`
      color: ${({ theme }) => theme.colors.accent};
    `}
`;
