import { FC, memo, useCallback, useRef } from 'react';
import Webcam from 'react-webcam';
import { Container, WebcamContainer } from './styles';
import type { Props } from './types';
import Button from '../Button';

const WebcamScreenshot: FC<Props> = ({ className, height, onGetImage }) => {
  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    if (webcamRef?.current) {
      // @ts-ignore
      const imageSrc = webcamRef.current.getScreenshot() as string;
      onGetImage(imageSrc);
    }
  }, [onGetImage]);

  const videoConstraints = {
    facingMode: 'user',
  };

  return (
    <Container className={className}>
      <WebcamContainer>
        <Webcam
          audio={false}
          height={height || 500}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="100%"
          videoConstraints={videoConstraints}
        />
      </WebcamContainer>
      <Button onClick={capture}>Capturar</Button>
    </Container>
  );
};

export default memo(WebcamScreenshot);
