import { apolloClient } from 'services/api';
import { GET_PARTNERS } from 'apollo/queries/partners';
import {
  GetPartners,
  GetPartnersVariables,
} from 'apollo/generated/GetPartners';
import { normalizePartnerToList } from 'model/Partner';

const getPartners = async (ids: string[]) => {
  const { data } = await apolloClient.query<GetPartners, GetPartnersVariables>({
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    query: GET_PARTNERS,
  });

  const partners = data?.partners;

  if (!partners) {
    return undefined;
  }

  const selectedPartners = partners.filter((partner) =>
    ids.includes(partner.id),
  );

  if (!selectedPartners) {
    return undefined;
  }

  return selectedPartners.map(normalizePartnerToList);
};

export default getPartners;
