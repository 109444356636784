import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  margin-top: 2rem;
`;

export const Content = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.grey300};
  display: flex;
  flex-flow: column;
  padding-left: 0.5rem;
  flex: 0 0 80%;
  max-width: 17rem;

  ${from.tabletPortrait`
    max-width: unset;
    padding-left: 2rem;
`}
`;

export const ContentTitle = styled.h3``;

export const ContentSteps = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
  margin-top: 2rem;
`;

export const ContentStep = styled.div`
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const ContentStepDesc = styled.p`
  line-height: 1.4;
`;

export const ContentStepImg = styled.img`
  max-width: 70%;
  margin-top: 1rem;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-flow: column;
  padding-right: 0.5rem;
  flex: 0 0 10%;
  position: sticky;
  top: 6rem;
  height: 100%;

  ${from.tabletPortrait`
    padding-right: 1.8rem;
`}

  ${from.tabletLandscape`
  top: 1rem;
`}
`;

export const SidebarOption = styled.div`
  color: ${({ theme }) => theme.colors.grey300};
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }

  ${from.tabletPortrait`
    font-size: 1rem;
    padding: 0.5rem 1rem;
`}
`;

export const SidebarOptionContent = styled.div<{ $selected: boolean }>`
  border-left: 1px solid ${({ theme }) => theme.colors.grey300};

  ${({ $selected }) =>
    $selected &&
    css`
      border-left: 2px solid ${({ theme }) => theme.colors.primary};
      ${SidebarOption} {
        color: ${({ theme }) => theme.colors.primary};
      }
    `}
`;
