import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import useModal from 'apollo/hooks/useModal';
import useEvents from 'apollo/hooks/event/useEvents';
import { permissionsStateVar } from 'apollo/reactive';
import type { OrganizationEvent } from 'model/OrganizationEvent';

const useConnect = () => {
  const { events, eventsLoading } = useEvents();
  const { openEvent } = useModal();
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const handleOnSelectEvent = useCallback(
    (event: OrganizationEvent) => {
      openEvent({ event });
    },
    [openEvent],
  );

  return {
    employeePermissions,
    events,
    handleOnSelectEvent,
    handleOpenNewEventModal: openEvent,
    isLoading: eventsLoading,
  };
};

export default useConnect;
