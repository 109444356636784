import { useCallback, useRef, useState } from 'react';
import type { Props } from './types';

const MAX_SIZE = 10 * 1024 * 1024;

const useLogic = (
  onGetFile: Props['onGetFile'],
  selectedFileName: Props['selectedFileName'],
) => {
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState(selectedFileName || '');
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleRemoveFile = () => {
    setError('');
    setFileName('');
    onGetFile(null);
  };

  const handleFileChange = useCallback(
    (event: any) => {
      try {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          if (selectedFile?.size > MAX_SIZE) {
            const msg =
              MAX_SIZE < 1000000
                ? `El archivo no puede exceder ${MAX_SIZE / 1000} KB.`
                : `El archivo no puede exceder ${MAX_SIZE / 1000000} MB.`;
            throw new Error(msg);
          }

          setFileName(selectedFile.name);
          onGetFile(selectedFile);
        }
      } catch (err) {
        setError(err.message);
      }
    },
    [onGetFile],
  );

  const handleButtonClick = useCallback(() => {
    if (inputFileRef?.current) {
      inputFileRef.current.click();
    }
  }, []);

  return {
    error,
    fileName,
    handleButtonClick,
    handleFileChange,
    inputFileRef,
    handleRemoveFile,
  };
};

export default useLogic;
