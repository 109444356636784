/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BookingState {
  CANCEL = "CANCEL",
  CLOSED = "CLOSED",
  REQUESTED = "REQUESTED",
  REVIEWED = "REVIEWED",
}

export enum CategoryType {
  EXPENSES = "EXPENSES",
  PRODUCT = "PRODUCT",
}

export enum DayOfWeek {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

export enum DiscountType {
  PRODUCT = "PRODUCT",
  QUOTA = "QUOTA",
}

export enum ImageCategory {
  AVATAR = "AVATAR",
  ORGANIZATION = "ORGANIZATION",
  PARTNER = "PARTNER",
  PRODUCT = "PRODUCT",
}

export enum ImageExtraCategory {
  DEFAULT = "DEFAULT",
  PARTNER_BACK_DOCUMENT = "PARTNER_BACK_DOCUMENT",
  PARTNER_FRONT_DOCUMENT = "PARTNER_FRONT_DOCUMENT",
}

export enum ImageUploadStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum OrganizationState {
  DEMO = "DEMO",
  FREE_LICENSE = "FREE_LICENSE",
  INIT = "INIT",
  PENDING_PAY = "PENDING_PAY",
  REQUESTED = "REQUESTED",
}

export enum PartnerUsage {
  PLAYFUL = "PLAYFUL",
  THERAPEUTIC = "THERAPEUTIC",
}

export enum ProductOrderDirection {
  asc = "asc",
  desc = "desc",
}

export enum ProductOrderType {
  createdAt = "createdAt",
  name = "name",
  quantity = "quantity",
}

export enum ProductsListType {
  LANDSCAPE = "LANDSCAPE",
  LIST = "LIST",
  SQUARE = "SQUARE",
}

export enum QuotaType {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum UserGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum UserRole {
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
  INDITAS = "INDITAS",
  PARTNER = "PARTNER",
}

export interface AdjustProductDto {
  baseWeight: number;
  quantity: number;
  note?: string | null;
}

export interface AssignCreditsDto {
  concept?: string | null;
  value: number;
}

export interface AssignQuotaDto {
  discountId?: string | null;
  quotaId: string;
}

export interface AuthLoginDto {
  email: string;
  password: string;
}

export interface BulkUpdateCategoriesDto {
  name: string;
  id: string;
}

export interface CashierFilters {
  fromDate?: any | null;
  toDate?: any | null;
}

export interface CategoryFilterDto {
  keyword?: string | null;
  type?: CategoryType | null;
}

export interface ConfirmFileUploadDto {
  fileId: string;
  partnerId: string;
  fileName: string;
  contentType: string;
  fileSize: number;
}

export interface ConfirmImageUploadDto {
  imageId: string;
  file_name: string;
  content_type: string;
  category: ImageCategory;
  extraCategory?: ImageExtraCategory | null;
  file_size: number;
}

export interface CreateBookingDto {
  partnerId: string;
  orders: OrderInput[];
}

export interface CreateCategoryDto {
  name: string;
  description: string;
  type: CategoryType;
}

export interface CreateDiscountDto {
  type: DiscountType;
  name: string;
  percentage?: number | null;
  price?: number | null;
}

export interface CreateEmployeeDto {
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  email: string;
  document: string;
  city?: string | null;
  country?: string | null;
  address?: string | null;
  zipCode?: string | null;
  password: string;
  permissionsGroupId: string;
}

export interface CreateExpenseDto {
  categoryIds?: string[] | null;
  description?: string | null;
  name: string;
  price: number;
  quantity?: number | null;
  taxes?: number | null;
  providerId: string;
}

export interface CreatePartnerDto {
  address?: string | null;
  avatarId?: string | null;
  birthDate?: any | null;
  city?: string | null;
  country?: string | null;
  document: string;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  hostMemberNum: string;
  lastName: string;
  maxConsumeMonth: number;
  memberNum?: string | null;
  phoneNumber: string;
  usage: PartnerUsage;
  zipCode?: string | null;
}

export interface CreatePartnerNoteDto {
  description: string;
  showNotification?: boolean | null;
}

export interface CreateProductDto {
  baseWeight?: number | null;
  categoryIds?: string[] | null;
  countToMaxConsume?: boolean | null;
  description: string;
  isActive?: boolean | null;
  mainImageId?: string | null;
  name: string;
  price: number;
  costs?: number | null;
  quantity?: number | null;
  taxes?: number | null;
}

export interface CreateProviderDto {
  description?: string | null;
  name: string;
}

export interface CreatePurchaseDto {
  partnerId: string;
  orders: OrderInput[];
  discountsIds?: string[] | null;
}

export interface CreateQuotaDto {
  type: QuotaType;
  name: string;
  value: number;
  price: number;
}

export interface CreditConceptDto {
  creditConcept: string;
  creditConceptToRemove?: string | null;
}

export interface GetFileSignedUrlDto {
  partnerId: string;
  fileName: string;
  contentType: string;
  fileSize: number;
}

export interface GetImageSignedUrlDto {
  imageId?: string | null;
  file_name: string;
  content_type: string;
  category: ImageCategory;
  extraCategory?: ImageExtraCategory | null;
  file_size: number;
}

export interface NewBlockContentPinDto {
  currentPin: number;
  newPin: number;
}

export interface OrderInput {
  productId: string;
  quantity: number;
}

export interface OrganizationConfigDto {
  allowNegativeCredits?: boolean | null;
  daysToRemoveExpiredPartner?: number | null;
  deleteExpiredPartner?: boolean | null;
  reasignReleasedPartnerNumber?: boolean | null;
  productsListType?: ProductsListType | null;
  blockContentPin?: NewBlockContentPinDto | null;
}

export interface OrganizationDocumentDto {
  name: string;
  text: string;
}

export interface OrganizationEventDto {
  allDay?: boolean | null;
  endDate: any;
  startDate: any;
  title: string;
  notify?: boolean | null;
  emails?: string[] | null;
}

export interface PermissionsGroupDto {
  name: string;
  allowCreateProducts: boolean;
  allowEditProducts: boolean;
  allowRemoveProducts: boolean;
  allowCreatePartners: boolean;
  allowEditPartners: boolean;
  allowRemovePartners: boolean;
  allowShowExpenses: boolean;
  allowCreateExpenses: boolean;
  allowEditExpenses: boolean;
  allowRemoveExpenses: boolean;
  allowShowReports: boolean;
  allowShowMetrics: boolean;
  allowShowCashier: boolean;
  allowShowCashierOneDay: boolean;
  allowShowCalendars: boolean;
  allowCreateCalendars: boolean;
  allowEditCalendars: boolean;
  allowRemoveCalendars: boolean;
  allowShowFiles: boolean;
  allowCreateFiles: boolean;
  allowRemoveFiles: boolean;
  allowShowProviders: boolean;
  allowCreateProviders: boolean;
  allowEditProviders: boolean;
  allowRemoveProviders: boolean;
  allowShowDiscounts: boolean;
  allowCreateDiscounts: boolean;
  allowAssignDiscounts: boolean;
  allowEditDiscounts: boolean;
  allowRemoveDiscounts: boolean;
  allowShowQuotas: boolean;
  allowCreateQuotas: boolean;
  allowAssignQuotas: boolean;
  allowEditQuotas: boolean;
  allowRemoveQuotas: boolean;
  allowShowCreditsConcept: boolean;
  allowCreateCreditsConcept: boolean;
  allowEditCreditsConcept: boolean;
  allowRemoveCreditsConcept: boolean;
  allowCreateCategories: boolean;
  allowEditCategories: boolean;
  allowRemoveCategories: boolean;
  allowShowContract: boolean;
  allowEditContract: boolean;
  allowAssignContract: boolean;
}

export interface ProductOrderBy {
  type?: ProductOrderType | null;
  direction?: ProductOrderDirection | null;
}

export interface ProductsFilter {
  keyword?: string | null;
  isActive?: boolean | null;
  positiveQuantity?: boolean | null;
  deletedProducts?: boolean | null;
  categories?: string[] | null;
  orderBy?: ProductOrderBy | null;
}

export interface ScheduleDto {
  startTime: string;
  endTime: string;
}

export interface UpdateBookingDto {
  orders?: OrderInput[] | null;
  cancelReason?: string | null;
  state?: BookingState | null;
}

export interface UpdateCategoryDto {
  name?: string | null;
  description?: string | null;
  type?: CategoryType | null;
}

export interface UpdateDiscountDto {
  type?: DiscountType | null;
  name?: string | null;
  percentage?: number | null;
  price?: number | null;
}

export interface UpdateEmployeeDto {
  avatarId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  document?: string | null;
  city?: string | null;
  country?: string | null;
  address?: string | null;
  zipCode?: string | null;
  permissionsGroupId?: string | null;
}

export interface UpdateExpenseDto {
  categoryIds?: string[] | null;
  description?: string | null;
  name?: string | null;
  price?: number | null;
  quantity?: number | null;
  taxes?: number | null;
  providerId?: string | null;
}

export interface UpdatePartnerDto {
  address?: string | null;
  avatarId?: string | null;
  birthDate?: any | null;
  city?: string | null;
  country?: string | null;
  document?: string | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  lastName?: string | null;
  maxConsumeMonth?: number | null;
  phoneNumber?: string | null;
  usage?: PartnerUsage | null;
  zipCode?: string | null;
}

export interface UpdatePartnerNoteDto {
  description?: string | null;
  showNotification?: boolean | null;
}

export interface UpdatePasswordDto {
  oldPassword: string;
  newPassword: string;
}

export interface UpdateProductDto {
  baseWeight?: number | null;
  categoryIds?: string[] | null;
  countToMaxConsume?: boolean | null;
  description?: string | null;
  isActive?: boolean | null;
  mainImageId?: string | null;
  name?: string | null;
  price?: number | null;
  costs?: number | null;
  quantity?: number | null;
  taxes?: number | null;
}

export interface UpdateProviderDto {
  description?: string | null;
  name?: string | null;
}

export interface UpdateQuotaDto {
  type?: QuotaType | null;
  name?: string | null;
  value?: number | null;
  price?: number | null;
}

export interface WorkScheduleDto {
  daysOfWeek: DayOfWeek[];
  schedules: ScheduleDto[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
