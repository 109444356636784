import { useCallback, useEffect, useState } from 'react';
import type { MenuData } from 'model/Organization';

const useLogic = (menuData: MenuData) => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [products, setProducts] = useState(menuData?.products || []);
  const [defaultImage, setDefaultImage] = useState(
    'https://sesamobakery.com/wp-content/uploads/2020/12/product-default-list-350.jpg',
  );

  useEffect(() => {
    if (menuData) {
      setProducts(menuData.products);
      if (menuData.defaultImageUrl) {
        setDefaultImage(menuData.defaultImageUrl);
      }
    }
  }, [menuData]);

  const handleOnChangeCategory = useCallback(
    (catName: string) => {
      setSelectedCategory(catName);
      if (catName === 'all') {
        setProducts(menuData?.products || []);
      } else {
        setProducts(
          (menuData?.products || []).filter((product) =>
            product.categories.includes(catName),
          ),
        );
      }
    },
    [menuData],
  );

  return {
    selectedCategory,
    handleOnChangeCategory,
    products,
    defaultImage,
  };
};

export default useLogic;
