import styled from 'styled-components';
import DefaultIconButton from '@mui/material/IconButton';
import DefaultFirstPage from '@mui/icons-material/FirstPage';
import DefaultNavigateBefore from '@mui/icons-material/NavigateBefore';
import DefaultNavigateNext from '@mui/icons-material/NavigateNext';
import DefaultLastPage from '@mui/icons-material/LastPage';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

export const IconButton = styled(DefaultIconButton)`
  &.MuiIconButton-root {
    margin: 0;
    padding: 0;
  }
`;

export const FirstPage = styled(DefaultFirstPage)``;

export const NavigateBefore = styled(DefaultNavigateBefore)``;

export const NavigateNext = styled(DefaultNavigateNext)``;

export const LastPage = styled(DefaultLastPage)``;

export const Info = styled.div`
  margin: 0 0.6rem;
  white-space: nowrap;
`;
