import { FC, memo } from 'react';
import {
  AppliedTo,
  Container,
  CreatedBy,
  Credits,
  Date,
  Items,
  Revert,
  State,
} from './styles';
import type { Props } from './types';

const TableHead: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <State />
      <CreatedBy>Empleado</CreatedBy>
      <AppliedTo>Socio</AppliedTo>
      <Items>Productos</Items>
      <Date>Fecha</Date>
      <Credits>Total</Credits>
      <Revert>Revertir</Revert>
    </Container>
  );
};

export default memo(TableHead);
