import { CategoryType } from 'apollo/generated/globalTypes';
import type { CategoryData } from 'apollo/generated/CategoryData';
import type { ProductData_categories } from 'apollo/generated/ProductData';
import type { GetCategoryQuery } from 'apollo/graphql.types';

export type EditCategoriesFields = Readonly<{
  categories: {
    name: string;
    id: string;
  }[];
}>;

export type CategoryFormFields = {
  name: string;
  description: string;
  type: CategoryType;
};

export const categoryTypeOptions = [
  { label: 'gastos', value: CategoryType.EXPENSES },
  { label: 'producto', value: CategoryType.PRODUCT },
];

export const normalizeCategory = (
  input: CategoryData | ProductData_categories | GetCategoryQuery['category'],
) => ({
  id: input.id || '',
  name: input.name || '',
  type: (input.type || '') as CategoryType,
  description: input.description || '',
  products:
    'products' in input
      ? input.products?.map((product) => ({
          id: product.id || '',
          name: product.name || '',
        }))
      : [],
});

export type Category = ReturnType<typeof normalizeCategory>;
