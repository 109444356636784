import { FC, memo, useCallback } from 'react';
import { formatDateToTable } from 'utils/dates/formatDates';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  Container,
  Identifier,
  Content,
  AddedBy,
  ActiveIcon,
  InactiveIcon,
  Note,
  Notification,
  LastEditedName,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, note, openNoteDetails }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const handleOpenPartnerNoteDetails = useCallback(() => {
    openNoteDetails(note);
  }, [openNoteDetails, note]);

  return (
    <Container className={className}>
      <Content onClick={handleOpenPartnerNoteDetails}>
        <AddedBy>{note.addedByName}</AddedBy>
        {fromTabletPortrait && (
          <LastEditedName>{note.lastEditionByName}</LastEditedName>
        )}
        <Note>{note.description}</Note>
        <Identifier>{formatDateToTable(note.createdAt)}</Identifier>
        <Notification>
          {note.showNotification ? <ActiveIcon /> : <InactiveIcon />}
        </Notification>
      </Content>
    </Container>
  );
};

export default memo(Row);
