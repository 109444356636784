import { useMemo } from 'react';
import { QueryHookOptions, useQuery, useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive';
import { GET_PRODUCTS_QUANTITY_HISTORY_BY_DATES } from 'apollo/queries/products';
import { normalizeProductQuantityHistory } from 'model/Product';
import {
  GetProductsHistoricAdjustsByDatesQuery,
  GetProductsHistoricAdjustsByDatesQueryVariables,
  QuantityMovementType,
} from 'apollo/graphql.types';

const useProductsQuantityHistoryByDates = ({
  start,
  end,
  dataType = [],
  fetchPolicy = 'network-only',
}: {
  start?: Date;
  end?: Date;
  dataType?: QuantityMovementType[];
  fetchPolicy?: QueryHookOptions<GetProductsHistoricAdjustsByDatesQuery>['fetchPolicy'];
} = {}) => {
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const { loading, data, refetch } = useQuery<
    GetProductsHistoricAdjustsByDatesQuery,
    GetProductsHistoricAdjustsByDatesQueryVariables
  >(GET_PRODUCTS_QUANTITY_HISTORY_BY_DATES, {
    fetchPolicy,
    skip:
      !employeePermissions.allowAdjustProducts &&
      !employeePermissions.allowShowAdjustProducts,
    variables: {
      filters: {
        dates: { fromDate: start || null, toDate: end || null },
        dataType,
      },
    },
  });

  const productsQuantityHistoryByDates = useMemo(
    () =>
      data?.productsHistoricAdjustsByDates?.length
        ? data.productsHistoricAdjustsByDates.map(
            normalizeProductQuantityHistory,
          )
        : [],
    [data],
  );

  return {
    productsQuantityHistoryByDates,
    refetch,
    getProductsQuantityHistoryByDatesLoading: loading,
  };
};

export default useProductsQuantityHistoryByDates;
