import styled from 'styled-components';
import DefaultDatePickerTime from 'components/Inputs/DatePickerTime';
import DefaultButton from 'components/Button';
import { from } from 'styles/responsive';

export const Container = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.black}0a;
  border-radius: 0.25rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 1rem;

  ${from.tabletPortrait`
    align-items: flex-start;
    flex-flow: row;
  `};
`;

export const Inputs = styled.div`
  display: flex;
  flex-flow: column;

  ${from.tabletPortrait`
    flex-flow: row;
    margin-right: 4rem;
  `};
`;

export const DatePickerTime = styled(DefaultDatePickerTime)`
  margin-bottom: 1rem;
  margin-right: 0;

  ${from.tabletPortrait`
   margin-bottom: 0;
   &:not(:last-child) {
    margin-right: 2rem;
   }
  `};
`;

export const Button = styled(DefaultButton)`
  margin-left: auto;
  &&& {
    padding: 0.375rem 0.813rem;
    font-size: 1rem;
    text-transform: capitalize;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
