import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CREATE_BOOKING, UPDATE_BOOKING } from 'apollo/mutations/bookings';
import {
  CreateBooking,
  CreateBookingVariables,
} from 'apollo/generated/CreateBooking';
import {
  UpdateBooking,
  UpdateBookingVariables,
} from 'apollo/generated/UpdateBooking';
import {
  CreateBookingDto,
  UpdateBookingDto,
} from 'apollo/generated/globalTypes';

const useBookingActions = () => {
  const [createBookingMutation, { loading: isLoadingCreateBooking }] =
    useMutation<CreateBooking, CreateBookingVariables>(CREATE_BOOKING);
  const [updateBookingMutation, { loading: isLoadingUpdateBooking }] =
    useMutation<UpdateBooking, UpdateBookingVariables>(UPDATE_BOOKING);

  const createBooking = useCallback(
    async ({ partnerId, orders }: CreateBookingDto) => {
      await createBookingMutation({
        variables: {
          data: {
            partnerId,
            orders,
          },
        },
      });
    },
    [createBookingMutation],
  );

  const updateBooking = useCallback(
    async ({ id, data }: { id: string; data: UpdateBookingDto }) => {
      await updateBookingMutation({
        variables: {
          id,
          data,
        },
      });
    },
    [updateBookingMutation],
  );

  return {
    createBooking,
    updateBooking,
    loading: isLoadingCreateBooking || isLoadingUpdateBooking,
  };
};

export default useBookingActions;
