import { gql } from '@apollo/client';
import {
  PARTNER_FRAGMENT,
  PARTNER_NOTE_FRAGMENT,
} from 'apollo/fragments/Partner';

export const CREATE_PARTNER = gql`
  mutation CreatePartner($data: CreatePartnerDto!) {
    createPartner(data: $data) {
      ...PartnerData
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const UPDATE_PARTNER = gql`
  mutation UpdatePartner($id: ID!, $data: UpdatePartnerDto!) {
    updatePartner(id: $id, data: $data) {
      ...PartnerData
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const ACTIVATE_REMOVED_PARTNER = gql`
  mutation ActivateRemovedPartner($document: String!) {
    activateRemovedPartner(document: $document) {
      ...PartnerData
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const REMOVE_PARTNER = gql`
  mutation RemovePartner($id: ID!) {
    removePartner(id: $id)
  }
`;

export const ADD_CREDITS_TO_PARTNER = gql`
  mutation AddCreditsToPartner($id: ID!, $data: AssignCreditsDto!) {
    addCreditsToPartner(id: $id, data: $data)
  }
`;

export const RETIRE_CREDITS_FROM_PARTNER = gql`
  mutation RetireCreditsFromPartner($id: ID!, $data: AssignCreditsDto!) {
    retireCreditsFromPartner(id: $id, data: $data)
  }
`;

export const ASSIGN_DISCOUNTS_TO_PARTNER = gql`
  mutation AssignDiscountsToPartner($id: ID!, $discountsIds: [ID!]!) {
    assignDiscountsToPartner(id: $id, discountsIds: $discountsIds) {
      partner {
        ...PartnerData
      }
      discountsAssigned
      discountsUnassigned
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const ASSIGN_QUOTA_TO_PARTNER = gql`
  mutation AssignQuotaToPartner($id: ID!, $data: AssignQuotaDto!) {
    assignQuotaToPartner(id: $id, data: $data)
  }
`;

export const REGISTER_PARTNER_PRESENCE = gql`
  mutation RegisterPartnerPresence($id: ID!) {
    registerPartnerPresence(id: $id) {
      ...PartnerData
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const CANCEL_CREDITS_TRANSACTION = gql`
  mutation CancelCreditsTransaction($creditTransactionId: ID!) {
    cancelCreditsTransaction(creditTransactionId: $creditTransactionId) {
      ...PartnerData
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const CANCEL_QUOTA_TRANSACTION = gql`
  mutation CancelQuotaTransaction($quotaTransactionId: ID!) {
    cancelQuotaTransaction(quotaTransactionId: $quotaTransactionId) {
      ...PartnerData
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const CREATE_PARTNER_NOTE = gql`
  mutation CreatePartnerNote($id: ID!, $data: CreatePartnerNoteDto!) {
    createPartnerNote(id: $id, data: $data) {
      ...PartnerNoteData
    }
  }
  ${PARTNER_NOTE_FRAGMENT}
`;

export const UPDATE_PARTNER_NOTE = gql`
  mutation UpdatePartnerNote($noteId: ID!, $data: UpdatePartnerNoteDto!) {
    updatePartnerNote(noteId: $noteId, data: $data) {
      ...PartnerNoteData
    }
  }
  ${PARTNER_NOTE_FRAGMENT}
`;

export const REMOVE_PARTNER_NOTE = gql`
  mutation RemovePartnerNote($noteId: ID!) {
    removePartnerNote(noteId: $noteId)
  }
`;
