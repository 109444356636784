import { gql } from '@apollo/client';

export const QUOTA_FRAGMENT = gql`
  fragment QuotaData on Quota {
    id
    type
    value
    price
    name
    partners {
      ... on Partner {
        id
      }
    }
  }
`;
