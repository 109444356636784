import { FC, useMemo } from 'react';
import Price from 'components/Price';
import useConnect from './connect';
import {
  Buttons,
  Container,
  Header,
  IconCircle,
  OrdersIcon,
  Title,
  CloseButton,
  HeaderContainer,
  PartnerNum,
  PartnerName,
  Partners,
  PartnersContainer,
  TotalPartners,
  PartnersRow,
  PartnerPrice,
  PartnerDiscount,
  PartnerNameContent,
} from './styles';

const CashierPartnersModal: FC = () => {
  const { isOpen, handleClose, partners } = useConnect();
  const totalPrice = useMemo(
    () => (partners || []).reduce((a, b) => a + b.credits, 0).toFixed(2),
    [partners],
  );

  return (
    <Container open={isOpen} onClose={handleClose}>
      <HeaderContainer>
        <Header>
          <IconCircle>
            <OrdersIcon />
          </IconCircle>
          <Title>Socios</Title>
        </Header>
      </HeaderContainer>

      <PartnersContainer>
        <PartnersRow>
          <TotalPartners>{partners?.length} socios</TotalPartners>
          <Price>{Number(totalPrice)}</Price>
        </PartnersRow>
        <Partners>
          {partners?.map((partner) => (
            <PartnersRow key={partner.id}>
              <PartnerNameContent>
                <PartnerNum>#{partner.memberNum}</PartnerNum>
                <PartnerName>{partner.fullName}</PartnerName>
              </PartnerNameContent>

              <PartnerPrice>
                <Price>{partner.credits}</Price>
                {partner.discountName && (
                  <PartnerDiscount>
                    Descuento: '{partner.discountName}'
                  </PartnerDiscount>
                )}
              </PartnerPrice>
            </PartnersRow>
          ))}
        </Partners>
      </PartnersContainer>
      <Buttons>
        <CloseButton onClick={handleClose}>Cerrar</CloseButton>
      </Buttons>
    </Container>
  );
};

export default CashierPartnersModal;
