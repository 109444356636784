import { memo } from 'react';
import type { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import { QuantityMovementType } from 'apollo/graphql.types';
import { formatDateToTable, formatDate } from 'utils/dates/formatDates';
import {
  Container,
  Credits,
  Date,
  Employee,
  ProductName,
  LastQuantity,
  ProductImage,
  Image,
  NewQuantity,
  NoteData,
  NoteEmployee,
  MovementType,
  Note,
} from './styles';
import type { Props } from './types';

const DEFAULT_IMAGE =
  'https://d3e9urqlsf1dy8.cloudfront.net/images/defaults/default-product-image.png';

const Row: FC<Props> = ({ adjustHistory, className, onClick }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const employeeFullName = `${adjustHistory.editedBy.firstName} ${adjustHistory.editedBy.lastName}`;
  const isStockMovement =
    adjustHistory.movementType === QuantityMovementType.Stock;

  const lastQuantity = isStockMovement
    ? (adjustHistory.lastQuantity || 0).toFixed(2)
    : (adjustHistory.lastStock || 0).toFixed(2);

  const currentQuantity = isStockMovement
    ? (adjustHistory.currentQuantity || 0).toFixed(2)
    : (adjustHistory.currentStock || 0).toFixed(2);

  const adjustDifference = isStockMovement
    ? adjustHistory.adjustDifference
    : adjustHistory.adjustDifference * -1;

  const movementText = isStockMovement ? 'Stock Directo' : 'Almacén';

  return (
    <Container className={className} onClick={() => onClick(adjustHistory)}>
      {fromTabletPortrait && (
        <ProductImage>
          <Image src={adjustHistory.product.imageUrl || DEFAULT_IMAGE} />
        </ProductImage>
      )}

      <ProductName>{adjustHistory.product.name}</ProductName>
      <NoteEmployee>
        <NoteData>
          <Employee>{employeeFullName}</Employee>
        </NoteData>
      </NoteEmployee>
      <Date>
        {fromTabletPortrait
          ? formatDateToTable(adjustHistory.createdAt)
          : formatDate(adjustHistory.createdAt)}
      </Date>
      {fromTabletPortrait && <LastQuantity>{lastQuantity}</LastQuantity>}
      <NewQuantity>{currentQuantity}</NewQuantity>
      {fromTabletPortrait && (
        <>
          <MovementType>{movementText}</MovementType>
          <Note>{adjustHistory.note}</Note>
        </>
      )}
      <Credits $isNegative={adjustDifference < 0}>
        <Price showIcon={false}>{adjustDifference}</Price>
      </Credits>
    </Container>
  );
};

export default memo(Row);
