import { FC, memo } from 'react';
import { Formik } from 'formik';
import { QuantityMovementType } from 'apollo/graphql.types';
import useConnect from './connect';
import {
  Container,
  Title,
  IconCircle,
  Icon,
  Header,
  Form,
  Buttons,
  CancelButton,
  Row,
  TextField,
} from './styles';

const AdjustProductDetailsModal: FC = () => {
  const { handleClose, handleSubmit, initialValues, isOpen } = useConnect();

  const isStoreMovement =
    initialValues.movementType === QuantityMovementType.Store;
  const isToSockMovement =
    initialValues.movementType === QuantityMovementType.FromStore;

  let titleText = 'Ajuste del Stock';

  if (isStoreMovement) {
    titleText = 'Movimiento del almacén';
  }

  if (isToSockMovement) {
    titleText = 'Abastecimiento del stock';
  }

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="flower" size={24} />
        </IconCircle>
        <Title>
          {titleText} - {initialValues.name}
        </Title>
      </Header>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        <Form>
          <Row>
            <TextField
              variant="standard"
              readOnly
              name="employee"
              label="Empleado"
            />
            <TextField variant="standard" readOnly name="date" label="Fecha" />
          </Row>
          <Row>
            <TextField
              variant="standard"
              readOnly
              name="lastQuantity"
              label="Última cantidad"
            />
            <TextField
              variant="standard"
              readOnly
              name="currentQuantity"
              label="Nueva cantidad"
            />
          </Row>
          {/* <Row>
            <TextField
              variant="standard"
              readOnly
              name="lastBaseWeight"
              label="Último peso base"
            />
            <TextField
              variant="standard"
              readOnly
              name="currentBaseWeight"
              label="Nuevo peso base"
            />
          </Row> */}
          <Row>
            <TextField
              variant="standard"
              readOnly
              name="adjustDifference"
              label="Ajuste"
            />
          </Row>

          <TextField readOnly name="note" label="Nota" id="note" />
          <Buttons>
            <CancelButton onClick={handleClose}>Cerrar</CancelButton>
          </Buttons>
        </Form>
      </Formik>
    </Container>
  );
};

export default memo(AdjustProductDetailsModal);
