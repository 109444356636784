import type { FC } from 'react';
import {
  Button,
  Container,
  DisableButtonContainer,
  Icon,
  PreviousOrdersContainer,
  PreviousOrdersTitle,
} from './styles';
import type { Props } from './types';

const BookingButtons: FC<Props> = ({ disableOrders }) => (
  <Container>
    <PreviousOrdersContainer to="/orders/previous">
      <PreviousOrdersTitle>Pedidos anteriores</PreviousOrdersTitle>
      <Icon name="arrowRight" size={8} />
    </PreviousOrdersContainer>
    <DisableButtonContainer>
      <Button onClick={disableOrders}>Desactivar pedidos</Button>
    </DisableButtonContainer>
  </Container>
);

export default BookingButtons;
