import { useCallback, useMemo } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { FormikConfig } from 'formik';
import type { PartnerQuotaFormFields } from 'model/Partner';
import { translateQuotaType } from 'utils/quotas';

const useConnect = () => {
  const { close, type, quotaDetailsPayload } = useModal();
  const { quota } = quotaDetailsPayload || {};

  const initialValues = useMemo<PartnerQuotaFormFields>(
    () => ({
      name: quota?.name || '',
      price: quota?.price || 0,
      value: translateQuotaType(quota?.type, quota?.value),
      nextPayment: quota?.nextPayment || '',
      assignedDate: quota?.assignedDate || '',
    }),
    [quota],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  // TODO Method to remove
  const handleSubmit = useCallback<
    FormikConfig<PartnerQuotaFormFields>['onSubmit']
  >(async () => {
    console.log('');
  }, []);

  return {
    handleSubmit,
    handleClose,
    initialValues,
    isOpen: type === ModalType.QUOTA_DETAILS,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
