import type { FC } from 'react';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import {
  Container,
  DocumentsList,
  EmptyState,
  ButtonContent,
  Button,
  Header,
  Title,
  IconCircle,
  UploadFileIcon,
  Content,
} from './styles';

const DocumentsModal: FC = () => {
  const { handleClose, handleSelect, isOpen, documents } = useConnect();

  const hasResults = documents?.length > 0;

  return (
    <Container open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <UploadFileIcon />
        </IconCircle>
        <Title>Selecciona el documento</Title>
      </Header>

      <Content>
        {!hasResults && (
          <EmptyState title="No hay documentos" iconName="uploadFile">
            Aún no has creado ningún document, crea uno desde la configuración
            de la organización
          </EmptyState>
        )}

        {hasResults && (
          <DocumentsList>
            <TableHead />
            {documents.map((document) => (
              <Row
                key={document.id}
                document={document}
                onSelect={handleSelect}
              />
            ))}
          </DocumentsList>
        )}
      </Content>

      <ButtonContent>
        <Button onClick={handleClose}>Cerrar</Button>
      </ButtonContent>
    </Container>
  );
};

export default DocumentsModal;
