import { FC, memo } from 'react';
import { MappedDayOfWeek } from 'model/Employee';
import { Container, Icons, Name, Times, TrashIcon } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ workSchedule, onRemove }) => (
  <Container>
    <Name>
      {workSchedule.daysOfWeek
        .map((day) => MappedDayOfWeek[day].slice(0, 3).toUpperCase())
        .join(', ')}
    </Name>
    <Times>
      {workSchedule.schedules
        .map(
          (schedule) =>
            `${schedule.startTime.slice(0, 5)}-${schedule.endTime.slice(0, 5)}`,
        )
        .join(', ')}
    </Times>
    <Icons>
      <TrashIcon onClick={onRemove} />
    </Icons>
  </Container>
);

export default memo(Row);
