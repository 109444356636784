import { FC, memo } from 'react';
import {
  Billing,
  ChevronSpace,
  Container,
  Sales,
  Name,
  Number,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Number>Nº de socio</Number>
      <Name>Nombre</Name>
      <Sales>Ventas</Sales>
      <Billing>Facturación (mensual)</Billing>
      <ChevronSpace />
    </Container>
  );
};

export default memo(Row);
