import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_ORGANIZATION_DOCUMENT } from 'apollo/queries';
import { normalizeOrganizationDocument } from 'model/Organization';
import type {
  GetDocumentQuery,
  GetDocumentQueryVariables,
} from 'apollo/graphql.types';

const useOrganizationDocument = (id?: string) => {
  const { data, loading } = useQuery<
    GetDocumentQuery,
    GetDocumentQueryVariables
  >(GET_ORGANIZATION_DOCUMENT, {
    skip: !id,
    variables: { documentId: id as string },
  });

  const document = useMemo(
    () =>
      data?.getDocument
        ? normalizeOrganizationDocument(data.getDocument)
        : undefined,
    [data],
  );

  return {
    getDocumentLoading: loading,
    document,
  };
};

export default useOrganizationDocument;
