import { FC, memo } from 'react';
import TableHead from './TableHead';
import { Container } from './styles';
import type { Props } from './types';
import Row from './Row';
import useLogic from './logic';

const PartnersList: FC<Props> = ({
  className,
  allowDownload,
  partners,
  csv,
}) => {
  const { handleShowProducts } = useLogic();

  return (
    <Container className={className}>
      <TableHead allowDownload={allowDownload} csv={csv} />
      {partners.map((partner) => (
        <Row
          key={partner.memberNum}
          partner={partner}
          onSelectProducts={handleShowProducts}
        />
      ))}
    </Container>
  );
};

export default memo(PartnersList);
