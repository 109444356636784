import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import usePartnerStatistics from 'apollo/hooks/partner/usePartnerStatistics';
import useModal from 'apollo/hooks/useModal';
import usePurchaseActions from 'apollo/hooks/usePurchaseActions';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import { TransactionDataType } from 'apollo/graphql.types';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import OpenCashierSnackbarButton from 'components/OpenCashierSnackbarButton';
import { formatErrors, isClosedCashierError } from 'utils/errors/formatErrors';
import { TransactionType } from 'model/Transaction';
import type { Order } from 'model/Order';
import type { Discount } from 'model/Discount';

const FILTER_DATA_OPTIONS = [
  {
    value: TransactionDataType.Purchases,
    label: 'Compras',
  },
  {
    value: TransactionDataType.Credits,
    label: 'Créditos',
  },
  {
    value: TransactionDataType.Quotas,
    label: 'Cuotas',
  },
  {
    value: TransactionDataType.Reverted,
    label: 'Cancelados',
  },
];

const useConnect = () => {
  const { partnerId } = useParams<{ partnerId: string }>();
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startOfDay(subDays(new Date(), 2)),
    to: endOfDay(new Date()),
  });
  const {
    transactions,
    refetch,
    loading: getTransactionLoading,
  } = usePartnerStatistics({ id: partnerId, filters: filterDates });
  const { cancelPurchase, loading: purchaseActionsLoading } =
    usePurchaseActions();
  const {
    cancelCreditsTransaction,
    cancelQuotaTransaction,
    loading: partnerActionsLoading,
  } = usePartnerActions();
  const { enqueueSnackbar } = useSnackbar();
  const { openPurchaseDetails, openDialog, close: closeModal } = useModal();
  const [dataTypeFilters, setDataTypeFilters] = useState<TransactionDataType[]>(
    [],
  );

  useEffect(() => {
    refetch({
      id: partnerId,
      filters: {
        dates: {
          fromDate: filterDates.from || null,
          toDate: filterDates.to || null,
        },
        dataType: dataTypeFilters,
      },
    });
  }, [dataTypeFilters, filterDates, partnerId, refetch]);

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      setFilterDate({ from, to });
    },
    [],
  );

  const handleToggleDataType = useCallback(
    (dataType: TransactionDataType) => {
      const exists = !!dataTypeFilters.find(
        (dataTypeFilter) => dataTypeFilter === dataType,
      );
      if (exists) {
        setDataTypeFilters((prev) => prev.filter((item) => item !== dataType));
      } else {
        setDataTypeFilters([...dataTypeFilters, dataType]);
      }
    },
    [dataTypeFilters],
  );

  const handleOpenPurchaseDetailsModal = useCallback(
    async (orders: Order[], discount?: Partial<Discount>) => {
      if (orders.length > 0) {
        openPurchaseDetails({ orders, discount });
      }
    },
    [openPurchaseDetails],
  );

  const handleCancelTransaction = useCallback(
    async ({ id, type }: { id: string; type: TransactionType }) => {
      try {
        if (id && type) {
          if (type === TransactionType.PURCHASE) {
            await cancelPurchase(id);
          } else if (type === TransactionType.CREDITS) {
            await cancelCreditsTransaction(id);
          } else if (type === TransactionType.QUOTA) {
            await cancelQuotaTransaction(id);
          }

          closeModal();
          await refetch();
          enqueueSnackbar('La transacción se ha cancelado correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        if (isClosedCashierError(e.message)) {
          enqueueSnackbar('Debes abrir la caja', {
            variant: 'error',
            action: () => <OpenCashierSnackbarButton />,
          });
        } else {
          enqueueSnackbar(formatErrors('transaction', e.message, 'cancelar'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      }
    },
    [
      cancelCreditsTransaction,
      cancelPurchase,
      cancelQuotaTransaction,
      closeModal,
      enqueueSnackbar,
      refetch,
    ],
  );

  const handleRevertTransaction = useCallback(
    async ({ id, type }: { id: string; type: TransactionType }) => {
      openDialog({
        acceptButtonText: 'Revertir',
        cancelButtonText: 'Cerrar',
        description:
          'Vas a cancelar la transacción, es una acción que no se puede deshacer, ¿quieres cancelar?',
        onAccept: () => handleCancelTransaction({ id, type }),
        title: 'Revertir transacción',
        variant: 'danger',
      });
    },
    [openDialog, handleCancelTransaction],
  );

  return {
    handleOpenPurchaseDetailsModal,
    handleRevertTransaction,
    isLoading:
      getTransactionLoading || purchaseActionsLoading || partnerActionsLoading,
    transactions,
    filterDates,
    handleOnSearch,
    handleToggleDataType,
    FILTER_DATA_OPTIONS,
    dataTypeFilters,
  };
};

export default useConnect;
