import { gql } from '@apollo/client';

export const DISCOUNT_FRAGMENT = gql`
  fragment DiscountData on Discount {
    id
    type
    name
    percentage
    price
    partners {
      ... on Partner {
        id
      }
    }
  }
`;
