import { useCallback } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useOrganizationDocuments from 'apollo/hooks/organization/useOrganizationDocuments';
import type { OrganizationDocument } from 'model/Organization';

const useConnect = () => {
  const { close, type, documentsPayload } = useModal();
  const { documents, getDocumentsLoading } = useOrganizationDocuments();
  const { onSelect } = documentsPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSelect = useCallback(
    (document: OrganizationDocument) => {
      if (onSelect) {
        onSelect(document);
      }
    },
    [onSelect],
  );

  return {
    handleSelect,
    handleClose,
    documents,
    isOpen: type === ModalType.DOCUMENTS,
    isLoading: getDocumentsLoading,
  };
};

export default useConnect;
