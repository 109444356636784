import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT } from 'apollo/fragments/Product';

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($data: CreateProductDto!) {
    createProduct(data: $data) {
      ...ProductData
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $data: UpdateProductDto!) {
    updateProduct(id: $id, data: $data) {
      ...ProductData
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const CREATE_NEW_PRODUCT_REFERENCE = gql`
  mutation CreateNewProductReference(
    $productId: ID!
    $data: CreateReferenceProductDto!
  ) {
    createNewProductReference(productId: $productId, data: $data) {
      ...ProductData
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const REMOVE_PRODUCT = gql`
  mutation RemoveProduct($id: ID!) {
    removeProduct(id: $id)
  }
`;

export const ADJUST_PRODUCT_QUANTITY = gql`
  mutation AdjustProductQuantity($id: ID!, $data: AdjustProductDto!) {
    adjustProductQuantity(id: $id, data: $data) {
      ...ProductData
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const ASSIGN_DISCOUNT_TO_PRODUCT = gql`
  mutation AssignDiscountToProduct($id: ID!, $discountId: ID!) {
    assignDiscountToProduct(id: $id, discountId: $discountId) {
      ...ProductData
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const UNASSIGN_PRODUCT_DISCOUNT = gql`
  mutation UnassignProductDiscount($id: ID!) {
    unassignProductDiscount(id: $id) {
      ...ProductData
    }
  }
  ${PRODUCT_FRAGMENT}
`;
