import type { FC } from 'react';
import { Area, AreaChart } from 'recharts';
import {
  ChevronIcon,
  Container,
  ExtraTitle,
  Header,
  Subtitle,
  Title,
  TitleContent,
  ResponsiveContainer,
  ExtraSubtitle,
} from './styles';
import { Props } from './types';

const Chart: FC<Props> = ({
  data,
  extraTitle,
  extraSubtitle,
  title,
  subtitle,
  linkTo = '/metrics',
}) => (
  <Container>
    <Header to={linkTo}>
      <TitleContent>
        <Title>
          {title} <ExtraTitle>{extraTitle}</ExtraTitle>
        </Title>
        <Subtitle>
          {subtitle} <ExtraSubtitle>{extraSubtitle}</ExtraSubtitle>
        </Subtitle>
      </TitleContent>
      <ChevronIcon />
    </Header>
    <ResponsiveContainer width="100%" height="30%">
      <AreaChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#96bfde" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#96bfde" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke="#0074CA"
          fill="url(#colorValue)"
        />
      </AreaChart>
    </ResponsiveContainer>
  </Container>
);

export default Chart;
