import { gql } from '@apollo/client';
import { ORDER_FRAGMENT } from './Order';

export const BOOKING_FRAGMENT = gql`
  fragment BookingData on Booking {
    id
    state
    toDate
    updatedAt
    createdAt
    deletedAt
    partner {
      ... on Partner {
        id
        memberNum
      }
    }
    orders {
      ...OrderData
    }
  }
  ${ORDER_FRAGMENT}
`;
