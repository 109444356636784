import { FC } from 'react';
import useConnect from './connect';
import {
  AddIcon,
  Button,
  Buttons,
  ChevronRightIcon,
  Container,
  ExpensesIcon,
  Header,
  HeaderContainer,
  IconCircle,
  Label,
  MenuButton,
  Menus,
  OrdersIcon,
  PartnersIcon,
  ProductsIcon,
  Title,
} from './styles';

const CreateMenu: FC = () => {
  const {
    employeePermissions,
    isOpen,
    handleClose,
    handleOpenNewExpense,
    handleOpenNewProduct,
    handleOpenNewOrder,
    handleOpenNewPartner,
  } = useConnect();

  return (
    <Container open={isOpen} onClose={handleClose}>
      <HeaderContainer>
        <Header>
          <IconCircle>
            <AddIcon />
          </IconCircle>
          <Title>¿Qué quieres crear?</Title>
        </Header>
      </HeaderContainer>

      <Menus>
        <MenuButton type="button" onClick={handleOpenNewOrder}>
          <IconCircle>
            <OrdersIcon />
          </IconCircle>
          <Label>Nuevo pedido</Label>
          <ChevronRightIcon />
        </MenuButton>

        {employeePermissions.allowCreateProducts && (
          <MenuButton type="button" onClick={handleOpenNewProduct}>
            <IconCircle>
              <ProductsIcon />
            </IconCircle>
            <Label>Nuevo producto</Label>
            <ChevronRightIcon />
          </MenuButton>
        )}

        {employeePermissions.allowCreatePartners && (
          <MenuButton type="button" onClick={handleOpenNewPartner}>
            <IconCircle>
              <PartnersIcon />
            </IconCircle>
            <Label>Nuevo socio</Label>
            <ChevronRightIcon />
          </MenuButton>
        )}

        {employeePermissions.allowCreateExpenses && (
          <MenuButton type="button" onClick={handleOpenNewExpense}>
            <IconCircle>
              <ExpensesIcon />
            </IconCircle>
            <Label>Nuevo gasto</Label>
            <ChevronRightIcon />
          </MenuButton>
        )}
      </Menus>

      <Buttons>
        <Button onClick={handleClose}>Cancelar</Button>
      </Buttons>
    </Container>
  );
};

export default CreateMenu;
