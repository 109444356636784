import { gql } from '@apollo/client';
import {
  ORGANIZATION_DOCUMENT_FRAGMENT,
  ORGANIZATION_FRAGMENT,
  ORGANIZATION_PARTNER_FORM_FRAGMENT,
  TEMPORARY_PARTNER,
} from 'apollo/fragments/Organization';

export const TOGGLE_ORGANIZATION_ORDERS = gql`
  mutation ActiveOrganizationOrders($id: ID, $value: Boolean!) {
    activeOrganizationOrders(id: $id, value: $value)
  }
`;

export const SET_ORGANIZATION_CONFIG = gql`
  mutation SetOrganizationConfig($data: OrganizationConfigDto!) {
    setOrganizationConfig(data: $data) {
      ...OrganizationData
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const CREATE_ORGANIZATION_MENU = gql`
  mutation CreateOrganizationMenu($data: CreateOrganizationMenuDto!) {
    createOrganizationMenu(data: $data) {
      id
    }
  }
`;

export const UPDATE_ORGANIZATION_MENU = gql`
  mutation UpdateOrganizationMenu($id: ID!, $data: CreateOrganizationMenuDto!) {
    updateOrganizationMenu(id: $id, data: $data) {
      id
    }
  }
`;

export const REMOVE_ORGANIZATION_MENU = gql`
  mutation RemoveOrganizationMenu($id: ID!) {
    removeOrganizationMenu(id: $id)
  }
`;

export const SET_ORGANIZATION_CREDIT_CONCEPTS = gql`
  mutation SetCreditConcept($data: CreditConceptDto!) {
    setCreditConcept(data: $data) {
      ...OrganizationData
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const UPDATE_ORGANIZATION_LOGO = gql`
  mutation UpdateOrganizationLogo($id: ID!, $logoId: ID!) {
    updateOrganizationLogo(id: $id, logoId: $logoId) {
      ...OrganizationData
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const SET_ORGANIZATION_CONTRACT_TEXT = gql`
  mutation SetOrganizationContractText($text: String!) {
    setOrganizationContractText(text: $text) {
      ...OrganizationData
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const VALIDATE_ORGANIZATION_PIN = gql`
  mutation ValidateOrganizationPin($code: String!) {
    validateOrganizationPin(code: $code)
  }
`;

export const CREATE_ORGANIZATION_DOCUMENT = gql`
  mutation CreateDocument($data: OrganizationDocumentDto!) {
    createDocument(data: $data) {
      ...OrganizationDocumentData
    }
  }
  ${ORGANIZATION_DOCUMENT_FRAGMENT}
`;

export const UPDATE_ORGANIZATION_DOCUMENT = gql`
  mutation EditDocument($id: ID!, $data: OrganizationDocumentDto!) {
    editDocument(id: $id, data: $data) {
      ...OrganizationDocumentData
    }
  }
  ${ORGANIZATION_DOCUMENT_FRAGMENT}
`;

export const REMOVE_ORGANIZATION_DOCUMENT = gql`
  mutation RemoveDocument($id: ID!) {
    removeDocument(id: $id)
  }
`;

export const DESACTIVATE_ORGANIZATION = gql`
  mutation DesactivateOrganization {
    desactivateOrganization
  }
`;

export const REMOVE_DRIVE_INTEGRATION = gql`
  mutation RemoveDriveIntegration {
    removeDriveIntegration
  }
`;

export const SET_ORGANIZATION_PARTNER_FORM_CONFIG = gql`
  mutation SetOrganizationPartnerFormConfig(
    $data: OrganizationPartnerFormDto!
  ) {
    setOrganizationPartnerFormConfig(data: $data) {
      ...OrganizationPartnerFormConfigData
    }
  }
  ${ORGANIZATION_PARTNER_FORM_FRAGMENT}
`;

export const CREATE_TEMPORARY_PARTNER = gql`
  mutation CreateTemporaryPartner($data: CreateTemporaryPartnerDto!) {
    createTemporaryPartner(data: $data)
  }
`;

export const ACTIVATE_TEMPORARY_PARTNER = gql`
  mutation ActivateTemporaryPartner(
    $id: ID!
    $data: ActivateTemporaryPartnerDto!
  ) {
    activateTemporaryPartner(id: $id, data: $data) {
      ...TemporaryPartnerData
    }
  }
  ${TEMPORARY_PARTNER}
`;

export const REMOVE_TEMPORARY_PARTNER = gql`
  mutation RemoveTemporaryPartner($id: ID!) {
    removeTemporaryPartner(id: $id)
  }
`;
