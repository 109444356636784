import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useModal from 'apollo/hooks/useModal';
import metricsView from 'assets/images/landing/metrics-view.png';
import NewAccountRequest from '../../Modals/NewAccountRequest';
import * as S from './styles';

const Landing: FC = () => {
  const { openNewAccountRequest } = useModal();
  const navigate = useNavigate();

  return (
    <>
      <S.Container>
        <S.Header>
          <S.Logo />
          <S.Buttons>
            <S.Button variant="light" onClick={openNewAccountRequest}>
              Solicitar demo
            </S.Button>
            <S.Button onClick={() => navigate('/login')}>
              Iniciar sesión
            </S.Button>
          </S.Buttons>
        </S.Header>
        <S.Content>
          <S.Dashboard>
            <S.DashboardText>
              <S.DashboardTextTitle>
                La solución integral para la gestión de tus{' '}
                <S.Highlighted>activos</S.Highlighted>
              </S.DashboardTextTitle>
              <S.DashboardTextSubtitle>
                Centraliza, analiza y maximiza el rendimiento de tus activos
              </S.DashboardTextSubtitle>
            </S.DashboardText>
            <S.DashboardImgContainer>
              <S.DashboardImg
                src={metricsView}
                alt="imagén sobre la sección de métricas"
              />
            </S.DashboardImgContainer>
          </S.Dashboard>
        </S.Content>
      </S.Container>
      <S.Footer>
        <S.FooterText>
          © inditasApp. 2024. Todos los derechos reservados{' '}
        </S.FooterText>
        <S.FooterContact>
          <S.Link target="_blank" to="/privacy-policy">
            Política de Privacidad
          </S.Link>
          <S.Link target="_blank" to="/legal-notice">
            Aviso Legal
          </S.Link>
          <S.Link target="_blank" to="/legal-notice">
            Política de Cookies
          </S.Link>
        </S.FooterContact>
      </S.Footer>
      <NewAccountRequest />
    </>
  );
};

export default Landing;
