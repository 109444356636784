import { useCallback, useEffect, useState } from 'react';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { deleteToken, getToken } from 'services/storage';
import getMe from 'apollo/requests/getMe';
import {
  initVar,
  userRoleVar,
  setSubscriptionStateVar,
  organizationActiveVar,
  setPermissionsStateVar,
  setOrganizationConfigVar,
} from 'apollo/reactive';
import useAuthActions from 'apollo/hooks/useAuthActions';
import { useIdleTimer } from 'react-idle-timer';
import { isDevelopment } from 'config';

const useConnect = () => {
  const apolloClient = useApolloClient();
  const init = useReactiveVar(initVar);
  const userRole = useReactiveVar(userRoleVar);
  const organizationActive = useReactiveVar(organizationActiveVar);
  const { isAuthenticated, logout } = useAuthActions();
  const [isLocked, setLock] = useState(false);

  useEffect(() => {
    const handleAuth = async () => {
      try {
        initVar(false);

        const token = getToken();

        if (!token) {
          throw new Error();
        }

        const { me } = await getMe();

        if (!me) {
          throw new Error();
        }

        if (me.role) {
          userRoleVar(me.role);
        }

        setPermissionsStateVar(me.employee?.permissions || {}, me.role);
        setSubscriptionStateVar(me.organization.subscription);
        setOrganizationConfigVar({
          ...me.organization.config,
          organizationName: me.organization.name,
        });
        organizationActiveVar(me.organization.isActive);
      } catch (e) {
        deleteToken();
      } finally {
        initVar(true);
      }
    };

    handleAuth();
  }, [apolloClient]);

  const handleOnIdle = useCallback(() => {
    if (!isDevelopment) {
      setLock(true);
    }
  }, []);

  const handleOnActive = useCallback(() => {
    setLock(false);
  }, []);

  useIdleTimer({
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    timeout: 1000 * 60, // 1 min
    name: 'idle-timer-locked',
  });

  useIdleTimer({
    onIdle: logout,
    timeout: 1000 * 60 * 60, // 1h
    name: 'idle-timer-logout',
  });

  return {
    init,
    isAuthenticated,
    userRole,
    handleOnIdle,
    isLocked,
    organizationActive,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
