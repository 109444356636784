import { Form } from 'formik';
import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultCategorySwitch from 'components/Inputs/CategorySwitch';

export const Container = styled(Form)``;

export const SubmitButton = styled(DefaultButton)`
  &&& {
    margin: 1rem 0 3rem 0;
    width: 100%;

    ${from.tabletPortrait`
      float: right;
      width: unset;
  `}
  }
`;

export const Categories = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 21rem;

  ${from.tabletPortrait`
    max-width: unset;
  `}
`;

export const CategoryOptions = styled.ul`
  column-count: 2;

  ${from.tabletPortrait`
    column-count: 3;
  `}
`;

export const CategoryOption = styled.li`
  list-style-type: none;
`;

export const CategorySwitch = styled(DefaultCategorySwitch)``;
