import Dialog from '@mui/material/Dialog';
import styled, { css } from 'styled-components';
import { prettyScrollbar } from 'styles/mixins';
import { from } from 'styles/responsive';

export const Content = styled.div`
  ${prettyScrollbar({ vertical: true })};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  outline: none;
  overflow-y: auto;
  padding: 1.2375rem 1rem 1rem;
  position: relative;
  width: 100%;

  p {
    white-space: pre-wrap;
  }

  height: 100vh;

  ${from.tabletPortrait`
    height:unset;
    max-height: 95vh;
    width: 35rem;
`}
`;

export const Title = styled.h1`
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Container = styled(Dialog)<{ $disableFullScreen: boolean }>`
  position: relative;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.white};
    color: inherit;
    margin: 0;
    max-height: none;
    max-width: none;
    box-shadow: 0 0.375rem 1.1875rem 0.4375rem rgba(0, 0, 0, 0.21);
    width: 100vh;
    border-radius: 0;

    ${({ $disableFullScreen }) =>
      $disableFullScreen &&
      css`
        width: calc(100vw - 1rem);
        border-radius: 3.5px;
        ${Content} {
          height: unset;
        }
      `}

    ${from.tabletPortrait`
      border-radius: 3.5px;
      width: unset;
    `}
  }
`;
