import { FC, memo } from 'react';
import QRCode from 'react-qr-code';
import {
  PrintButton,
  Buttons,
  CancelButton,
  Container,
  QRContent,
} from './styles';
import useConnect from './connect';

const GenerateQRModal: FC = () => {
  const { handleOnPrint, isOpen, handleClose, value, QRRef } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <QRContent ref={QRRef}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={value}
          viewBox="0 0 256 256"
        />
      </QRContent>
      <Buttons>
        <CancelButton onClick={handleClose}>Cerrar</CancelButton>
        <PrintButton variant="light" onClick={handleOnPrint}>
          Imprimir
        </PrintButton>
      </Buttons>
    </Container>
  );
};

export default memo(GenerateQRModal);
