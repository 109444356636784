import { useCallback, useEffect, useState } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, generateFileDialogPayload, type } = useModal();
  const [disableButton, setDisableButton] = useState(false);

  const {
    title,
    description,
    iconName = 'organization',
    onDownload,
    onUpload,
  } = generateFileDialogPayload || {};

  useEffect(() => {
    if (type === ModalType.GENERATE_FILE_DIALOG) {
      setDisableButton(false);
    }
  }, [type]);

  const handleClose = useCallback(() => {
    setDisableButton(false);
    close();
  }, [close]);

  const handleOnDownload = useCallback(() => {
    if (onDownload) {
      onDownload();
      setDisableButton(true);
    }
  }, [onDownload]);

  const handleUpload = useCallback(() => {
    if (onUpload) {
      onUpload();
      setDisableButton(true);
    }
  }, [onUpload]);

  return {
    title,
    description,
    handleClose,
    iconName,
    handleOnDownload,
    handleUpload,
    disableButton,
    isOpen: type === ModalType.GENERATE_FILE_DIALOG,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
