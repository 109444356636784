import { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import FullscreenLoader from 'components/FullscreenLoader';
import FullscreenStandby from 'components/FullscreenStandby';
import Guest from 'containers/Guest';
import Main from 'containers/Main';
import { UserRole } from 'apollo/generated/globalTypes';
import PartnerViews from 'containers/PartnerViews';
import useConnect from './connect';

const Routes: FC = () => {
  const { init, isAuthenticated, userRole, isLocked } = useConnect();

  const isLoading = !init;
  const isAuthenticatedUser = init && isAuthenticated;
  const isGuestUser = init && !isAuthenticated;
  const isPartner = userRole === UserRole.PARTNER && isAuthenticatedUser;
  const isOrganization = userRole !== UserRole.PARTNER && isAuthenticatedUser;

  // TODO review
  /* if (!isGuestUser && !isLoading && !organizationActive) {
    return <FullscreenInactive />;
  } */

  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={init ? 'loaded' : 'loading'}
        initial={false}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {isLocked && !isLoading && !isGuestUser && <FullscreenStandby />}
        {isLoading && <FullscreenLoader />}
        {isOrganization && <Main />}
        {isPartner && <PartnerViews />}
        {isGuestUser && <Guest />}
      </motion.div>
    </AnimatePresence>
  );
};

export default Routes;
