import { object as yupObject, string as yupString } from 'yup';

const requiredMsg = 'Este campo es requerido';
export const validationSchema = yupObject({
  email: yupString().email('Introduce un email valido').required(requiredMsg),
  firstName: yupString().required(requiredMsg),
  lastName: yupString().required(requiredMsg),
  document: yupString().required(requiredMsg),
  address: yupString(),
  phoneNumber: yupString(),
  password: yupString().required(requiredMsg),
  permissionsGroupId: yupString().required(requiredMsg),
});
