import { FC, memo } from 'react';
import { FieldArray, Formik } from 'formik';
import useConnect from './connect';
import { DaysOptions, initialValues, validationSchema } from './constants';
import {
  AcceptButton,
  AddIcon,
  Buttons,
  CancelButton,
  Container,
  Form,
  Icon,
  IconCircle,
  Icons,
  Inputs,
  RemoveIcon,
  ScheduleRow,
  SchedulesContent,
  SectionError,
  SectionLabel,
  Select,
  TimePicker,
  Title,
} from './styles';

const AddMenuScheduleModal: FC = () => {
  const { isOpen, handleClose, handleSubmit } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>Añadir tramo horario</Title>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, isValid, values, errors }) => (
          <Form onSubmit={handleSubmit}>
            <SectionLabel>Días de la semana</SectionLabel>
            <Select
              name="daysOfWeek"
              id="daysOfWeek"
              options={DaysOptions}
              multiple
            />

            <SchedulesContent>
              <SectionLabel>Tramos horarios</SectionLabel>
              {errors && errors.schedules && (
                <SectionError>{errors.schedules as string}</SectionError>
              )}
              <FieldArray
                name="schedules"
                render={(arrayHelpers) =>
                  values?.schedules?.length > 0 &&
                  values.schedules.map((schedule, index) => {
                    return (
                      <ScheduleRow>
                        <Inputs>
                          <TimePicker
                            label="Inicio"
                            name={`schedules[${index}].startTime`}
                            id={`products[${index}].amount`}
                          />
                          <TimePicker
                            label="Fin"
                            name={`schedules[${index}].endTime`}
                            id={`products[${index}].price`}
                          />
                        </Inputs>
                        <Icons>
                          {(index !== 0 || values.schedules.length > 1) && (
                            <RemoveIcon
                              onClick={() => arrayHelpers.remove(index)}
                              name="trash"
                              size={16}
                            />
                          )}

                          {((index === 0 && values.schedules.length === 1) ||
                            index === values.schedules.length - 1) && (
                            <AddIcon
                              onClick={() =>
                                arrayHelpers.push({
                                  startTime: '',
                                  endTime: '',
                                })
                              }
                              name="add"
                            />
                          )}
                        </Icons>
                      </ScheduleRow>
                    );
                  })
                }
              />
            </SchedulesContent>
            <Buttons>
              <CancelButton onClick={handleClose}>Cerrar</CancelButton>
              <AcceptButton
                type="submit"
                disabled={
                  !isValid ||
                  !values.daysOfWeek?.length ||
                  !values.schedules?.length ||
                  !values.schedules[0]?.startTime ||
                  !values.schedules[0]?.endTime
                }
              >
                Crear
              </AcceptButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default memo(AddMenuScheduleModal);
