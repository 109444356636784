import styled from 'styled-components';
import DefaultLink from 'components/Link';

export const Container = styled.div``;

export const SeeMoreButton = styled(DefaultLink)`
  cursor: pointer;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  transition: color 200ms ease-out;
  width: 100%;
`;

export const SeeMore = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.black}0A;
  text-align: center;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey400}0A;
    ${SeeMoreButton} {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
  transition: background-color 200ms ease-out;
`;
