import { FC, memo } from 'react';

import type { Props } from './types';

const Password: FC<Props> = ({ size = 24, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke="currentColor"
        strokeWidth="1.5"
        x=".75"
        y=".75"
        width="22.5"
        height="22.5"
        rx="11.25"
      />
      <path
        d="M14.733 17c.385 0 .703-.139.954-.416a1.4 1.4 0 00.376-.97c0-.554-.386-1.08-1.157-1.579-1.137-.739-1.918-1.348-2.342-1.828a6.718 6.718 0 011.88-.278l1.416.056c1.427 0 2.14-.471 2.14-1.413 0-.407-.135-.73-.405-.97s-.597-.36-.983-.36c-.463 0-1.108.323-1.937.97-.906.72-1.677 1.163-2.314 1.33 0-.795.174-1.635.52-2.522.348-.886.521-1.431.521-1.635 0-.369-.135-.692-.404-.97A1.321 1.321 0 0012.014 6c-.404 0-.737.143-.997.43-.26.286-.39.641-.39 1.066 0 .203.24.924.722 2.161.193.5.29.942.29 1.33v.555c-.386-.13-1.34-.712-2.863-1.746-.52-.351-.993-.526-1.417-.526-.405 0-.732.11-.983.332-.25.222-.376.526-.376.914 0 .98.723 1.469 2.169 1.469l1.503-.056c.25 0 .52.019.81.056.29.037.578.11.867.222-.404.461-1.021.97-1.85 1.523-1.08.74-1.62 1.386-1.62 1.94 0 .388.13.697.39.928.261.231.603.347 1.027.347.771 0 1.302-.48 1.59-1.441.444-1.478.82-2.41 1.128-2.799.27.351.646 1.284 1.128 2.799C13.47 16.5 14 17 14.732 17z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Password);
