import { FC, memo } from 'react';
import { CSVLink } from 'react-csv';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Loader from 'components/Loader';
import { formatDateToTable } from 'utils/dates/formatDates';
import useConnect from './connect';
import {
  Container,
  Header,
  BackLink,
  HeaderInfo,
  HeaderName,
  DownloadButton,
  Main,
  ListContainer,
  FileIcon,
} from './styles';
import TableHead from './TableHead';
import Row from './Row';
import TotalRow from './TotalRow';

const CashierProducts: FC = () => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const { isLoading, products, csvInfo, employeePermissions, start, end } =
    useConnect();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header>
        <BackLink to="/cashier" />
        <HeaderInfo>
          <HeaderName>
            Caja{' '}
            {formatDateToTable(start ? new Date(Number(start)) : new Date())} -{' '}
            {formatDateToTable(end ? new Date(Number(end)) : new Date())}
          </HeaderName>
        </HeaderInfo>
        {employeePermissions.allowDownloadCashierData && (
          <>
            {fromTabletPortrait ? (
              <DownloadButton>
                <CSVLink
                  data={csvInfo.data}
                  headers={csvInfo.headers}
                  filename={csvInfo.filename}
                  target="_blank"
                >
                  Descargar
                </CSVLink>
              </DownloadButton>
            ) : (
              <CSVLink
                data={csvInfo.data}
                headers={csvInfo.headers}
                filename={csvInfo.filename}
                target="_blank"
              >
                <FileIcon />
              </CSVLink>
            )}
          </>
        )}
      </Header>
      <Main>
        <ListContainer>
          <TableHead />
          <TotalRow item={products?.stats} />
          {products?.items.map((product) => (
            <Row key={product.name} product={product} />
          ))}
        </ListContainer>
      </Main>
    </Container>
  );
};

export default memo(CashierProducts);
