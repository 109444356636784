import { FC } from 'react';
import useConnect from './connect';
import {
  Button,
  Container,
  OrdersIcon,
  Header,
  IconCircle,
  Label,
  MenuButton,
  Title,
} from './styles';

const CancelOrder: FC = () => {
  const { isOpen, handlesActions, handleClose } = useConnect();

  return (
    <Container open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <OrdersIcon />
        </IconCircle>
        <Title>¿Por qué lo cancelas?</Title>
      </Header>
      <MenuButton type="button" onClick={handlesActions.withoutProducts}>
        <Label>No queda producto</Label>
      </MenuButton>
      <MenuButton type="button" onClick={handlesActions.overLimit}>
        <Label>Ha superado el límite</Label>
      </MenuButton>
      <MenuButton type="button" onClick={handlesActions.other}>
        <Label>Otros motivos</Label>
      </MenuButton>
      <Button onClick={handleClose}>Volver</Button>
    </Container>
  );
};

export default CancelOrder;
