import { FC, memo } from 'react';
import { translateQuotaType } from 'utils/quotas';
import {
  Container,
  Name,
  Number,
  Price,
  Type,
  EditIcon,
  Icons,
  TrashIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  employeePermissions,
  name,
  onEdit,
  onOpenPartnersModal,
  onRemove,
  price,
  totalPartners,
  type,
  value,
}) => {
  return (
    <Container className={className}>
      <Name>{name}</Name>
      <Number onClick={onOpenPartnersModal} $hasPartners={totalPartners > 0}>
        {totalPartners}
      </Number>
      <Type>{translateQuotaType(type, value)}</Type>
      <Price>{price} cr</Price>
      <Icons>
        {employeePermissions.allowEditQuotas && <EditIcon onClick={onEdit} />}
        {employeePermissions.allowRemoveQuotas && (
          <TrashIcon onClick={onRemove} />
        )}
      </Icons>
    </Container>
  );
};

export default memo(Row);
