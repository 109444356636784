import styled from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 1rem 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;

  ${from.tabletLandscape`
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
`}
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_METRICS.name};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Document = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 1 ${TABLE_METRICS.document};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Email = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_METRICS.email};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Phone = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 1 ${TABLE_METRICS.phone};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RequestDate = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_METRICS.requestDate};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
