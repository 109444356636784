import styled from 'styled-components';
import DefaultButton from 'components/Button';
import DefaultIcon from 'components/Icon';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultLogo from 'components/Logo';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2rem 1.8rem 0 1.8rem;
`;

export const SectionContainer = styled.div``;

export const SectionTitle = styled.h3`
  margin-bottom: 1.5rem;
`;

export const Footer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};
  bottom: 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  left: 0;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

export const PreviousButton = styled(DefaultButton).attrs(() => ({
  variant: 'light',
}))`
  &&& {
    max-height: 2.5rem;
  }
`;

export const NextButton = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  &&& {
    max-height: 2.5rem;
  }
`;

export const FinishButton = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  &&& {
    max-height: 2.5rem;
  }
`;

export const ResultContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  min-height: 90vh;
  justify-content: space-around;
`;

export const Logo = styled(DefaultLogo)`
  width: 15rem;
`;

export const MessageContainer = styled.div<{ $isOk?: boolean }>`
  border: 1px solid
    ${({ $isOk, theme }) => ($isOk ? theme.colors.green : theme.colors.danger)};
  border-radius: 0.5rem;
  display: flex;
  flex-flow: column;
  padding: 1rem;
  text-align: center;
  width: 100%;
  max-width: 23rem;
`;

export const IconCircle = styled(DefaultIconCircle)`
  margin-left: auto;
  margin-right: auto;
`;

export const GreenIconCircle = styled(IconCircle)`
  background-color: ${({ theme }) => theme.colors.green}0A;
  color: ${({ theme }) => theme.colors.green};
`;

export const DangerIconCircle = styled(IconCircle)`
  background-color: ${({ theme }) => theme.colors.danger}0A;
  color: ${({ theme }) => theme.colors.danger};
`;

export const RequestMsg = styled.h3`
  margin: 1rem 0 2rem 0;
  font-weight: 500;
  line-height: 1.2;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 24,
}))``;
