import { useCallback } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useEmployeeActions from 'apollo/hooks/employee/useEmployeeActions';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { PasswordFormFields } from 'model/Employee';

const useConnect = () => {
  const { close, type, updatePasswordPayload } = useModal();
  const { updatePassword, loading: employeeActionsLoading } =
    useEmployeeActions();
  const { enqueueSnackbar } = useSnackbar();
  const { employeeId } = updatePasswordPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<PasswordFormFields>['onSubmit']
  >(
    async (data: PasswordFormFields, { setErrors }) => {
      try {
        if (employeeId) {
          if (data.newPassword !== data.newPassword2) {
            setErrors({ newPassword2: 'Las contraseñas no coinciden' });
            return;
          }
          await updatePassword({
            id: employeeId,
            data: {
              newPassword: data.newPassword,
              oldPassword: data.oldPassword,
            },
          });
          enqueueSnackbar(`Se ha actualizado la contraseña`, {
            variant: 'success',
          });
          close();
        }
      } catch (e) {
        if (e.message === 'Incorrect credentials') {
          setErrors({ oldPassword: 'Contraseña incorrecta' });
          return;
        }
        enqueueSnackbar(formatErrors('employee', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [employeeId, updatePassword, enqueueSnackbar, close],
  );

  return {
    handleSubmit,
    handleClose,
    isLoading: employeeActionsLoading,
    isOpen: type === ModalType.UPDATE_PASSWORD,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
