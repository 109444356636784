import type { FC } from 'react';
import { Formik, Form } from 'formik';
import TextField from 'components/Inputs/TextField';
import FileInput from 'components/Inputs/FileInput';
import useConnect from './connect';
import { validationSchema } from './constants';
import {
  ActionButton,
  CancelButton,
  Container,
  IconCircle,
  Icon,
  Title,
  Buttons,
} from './styles';

const UploadFileDialogModal: FC = () => {
  const {
    file,
    handleClose,
    handleOnGetFile,
    handleOnsubmit,
    isLoading,
    isOpen,
  } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>Carga un archivo</Title>
      <Formik
        onSubmit={handleOnsubmit}
        validationSchema={validationSchema}
        initialValues={{ fileName: '' }}
      >
        {({ isValid: isFormValid }) => (
          <Form>
            <TextField
              name="fileName"
              label="Nombre del archivo"
              id="fileName"
            />
            <FileInput onGetFile={handleOnGetFile} />
            <Buttons>
              <CancelButton onClick={handleClose}>Cancelar</CancelButton>
              <ActionButton
                type="submit"
                disabled={!file || !isFormValid || isLoading}
                isLoading={isLoading}
              >
                Subir
              </ActionButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default UploadFileDialogModal;
