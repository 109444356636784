import { FC, memo, useCallback } from 'react';
import Price from 'components/Price';
import { formatDateToTableExtended2 } from 'utils/dates/formatDates';
import {
  Adjust,
  Container,
  CreationDate,
  LastUpdateDate,
  Name,
  RealCredits,
  TotalCredits,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, cashier, onOpenCashier }) => {
  const totalCashier =
    cashier.totalCreditsAdded +
    cashier.lastRestRealCredits -
    cashier.totalExpenses;

  // We must add the values when the value of added credits is negative.
  const sumValues = totalCashier < 0;
  let adjust = sumValues
    ? cashier.realCreditsAdded + totalCashier
    : cashier.realCreditsAdded - totalCashier;
  adjust = Number(adjust.toFixed(2));

  const totalAdded = cashier.totalCreditsAdded + cashier.lastRestRealCredits;

  const handleOpenDetails = useCallback(() => {
    if (cashier.end) {
      onOpenCashier(cashier);
    }
  }, [onOpenCashier, cashier]);

  return (
    <Container className={className} onClick={handleOpenDetails}>
      <Name>{cashier.openedBy?.fullName || 'No especificado'}</Name>
      <CreationDate>{formatDateToTableExtended2(cashier.start)}</CreationDate>
      <Name>{cashier.closedBy?.fullName || 'Sigue abierta'}</Name>
      <LastUpdateDate>
        {cashier.end
          ? formatDateToTableExtended2(cashier.end)
          : 'Sigue abierta'}
      </LastUpdateDate>
      <TotalCredits>
        <Price>{totalAdded}</Price>
      </TotalCredits>
      <RealCredits>
        <Price>{cashier.realCreditsAdded}</Price>
      </RealCredits>
      <Adjust $value={adjust}>{cashier.end && <Price>{adjust}</Price>}</Adjust>
    </Container>
  );
};

export default memo(Row);
