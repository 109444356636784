import { FC, memo } from 'react';
import { Container, Section, SectionName } from './styles';
import { Props } from './types';
import useLogic from './logic';

const CategoryNavigationSidebar: FC<Props> = ({
  categoryId,
  onSelectedSection,
}) => {
  const { handleToggleSection, sections, selectedSection } = useLogic({
    onSelectedSection,
    categoryId,
  });

  return (
    <Container>
      {sections
        .filter((section) => section.isAllow)
        .map((section) => (
          <Section
            onClick={() => handleToggleSection(section)}
            key={section.key}
            $isSelected={
              !!(selectedSection && selectedSection.key === section.key)
            }
          >
            <SectionName>{section.value}</SectionName>
          </Section>
        ))}
    </Container>
  );
};

export default memo(CategoryNavigationSidebar);
