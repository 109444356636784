import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  REMOVE_DRIVE_INTEGRATION,
  DESACTIVATE_ORGANIZATION,
  SET_ORGANIZATION_CONFIG,
  SET_ORGANIZATION_CONTRACT_TEXT,
  SET_ORGANIZATION_CREDIT_CONCEPTS,
  TOGGLE_ORGANIZATION_ORDERS,
  UPDATE_ORGANIZATION_LOGO,
  VALIDATE_ORGANIZATION_PIN,
} from 'apollo/mutations/organizations';
import type {
  ActiveOrganizationOrders,
  ActiveOrganizationOrdersVariables,
} from 'apollo/generated/ActiveOrganizationOrders';
import type {
  SetCreditConcept,
  SetCreditConceptVariables,
} from 'apollo/generated/SetCreditConcept';
import type {
  ConfigFormFields,
  CreditConceptFormFields,
} from 'model/Organization';
import type {
  UpdateOrganizationLogo,
  UpdateOrganizationLogoVariables,
} from 'apollo/generated/UpdateOrganizationLogo';
import type {
  SetOrganizationContractText,
  SetOrganizationContractTextVariables,
} from 'apollo/generated/SetOrganizationContractText';
import type {
  ValidateOrganizationPinMutation,
  ValidateOrganizationPinMutationVariables,
  SetOrganizationConfigMutation,
  SetOrganizationConfigMutationVariables,
  RemoveDriveIntegrationMutation,
  RemoveDriveIntegrationMutationVariables,
  DesactivateOrganizationMutation,
} from 'apollo/graphql.types';

const useOrganizationActions = () => {
  const [
    toggleOrganizationMutation,
    { loading: isLoadingToggleOrganizationOrders },
  ] = useMutation<ActiveOrganizationOrders, ActiveOrganizationOrdersVariables>(
    TOGGLE_ORGANIZATION_ORDERS,
  );
  const [
    setOrganizationConfigMutation,
    { loading: isLoadingSetOrganizationConfig },
  ] = useMutation<
    SetOrganizationConfigMutation,
    SetOrganizationConfigMutationVariables
  >(SET_ORGANIZATION_CONFIG);
  const [
    setOrganizationCreditConceptsMutation,
    { loading: isLoadingSetOrganizationCreditConcepts },
  ] = useMutation<SetCreditConcept, SetCreditConceptVariables>(
    SET_ORGANIZATION_CREDIT_CONCEPTS,
  );
  const [
    setOrganizationContractTextMutation,
    { loading: isLoadingSetOrganizationContractTextMutation },
  ] = useMutation<
    SetOrganizationContractText,
    SetOrganizationContractTextVariables
  >(SET_ORGANIZATION_CONTRACT_TEXT);
  const [
    updateOrganizationLogoMutation,
    { loading: isLoadingUpdateOrganizationLogo },
  ] = useMutation<UpdateOrganizationLogo, UpdateOrganizationLogoVariables>(
    UPDATE_ORGANIZATION_LOGO,
  );
  const [validatePinMutation, { loading: isLoadingValidatePin }] = useMutation<
    ValidateOrganizationPinMutation,
    ValidateOrganizationPinMutationVariables
  >(VALIDATE_ORGANIZATION_PIN);
  const [
    desactivateOrganizationMutation,
    { loading: isLoadingDesactivateOrganization },
  ] = useMutation<DesactivateOrganizationMutation>(DESACTIVATE_ORGANIZATION);
  const [
    removeDriveIntegrationMutation,
    { loading: removeDriveIntegrationIsLoading },
  ] = useMutation<
    RemoveDriveIntegrationMutation,
    RemoveDriveIntegrationMutationVariables
  >(REMOVE_DRIVE_INTEGRATION);

  const toggleOrganizationOrder = useCallback(
    async ({ id, value }: { id?: string; value: boolean }) => {
      await toggleOrganizationMutation({
        variables: {
          id,
          value,
        },
      });
    },
    [toggleOrganizationMutation],
  );

  const normalizeFormValuesSetConfig = useCallback(
    (values: ConfigFormFields) => ({
      allowExtraQuantity: values.allowExtraQuantity,
      allowNegativeCredits: values.allowNegativeCredits,
      activateRfidReader: values.activateRfidReader,
      activateProductReference: values.activateProductReference,
      forceOpenCashier: values.forceOpenCashier,
      activeStrictCashier: values.activeStrictCashier,
      deleteExpiredPartner: values.deleteExpiredPartner,
      daysToRemoveExpiredPartner: Number(values.daysToRemoveExpiredPartner),
      reasignReleasedPartnerNumber: values.reasignReleasedPartnerNumber,
      productsListType: values.productsListType,
      maxExtraQuantity: values.maxExtraQuantity
        ? Number(values.maxExtraQuantity)
        : undefined,
      blockContentPin: values.blockContentPin?.newPin
        ? {
            currentPin: values.blockContentPin.currentPin || '',
            newPin: values.blockContentPin.newPin || '',
          }
        : null,
    }),
    [],
  );

  const setOrganizationConfig = useCallback(
    async (data: ConfigFormFields) => {
      const normalizedData = normalizeFormValuesSetConfig(data);
      await setOrganizationConfigMutation({
        variables: {
          // @ts-ignore
          data: normalizedData,
        },
      });
    },
    [normalizeFormValuesSetConfig, setOrganizationConfigMutation],
  );

  const setOrganizationContractText = useCallback(
    async (text: string) => {
      await setOrganizationContractTextMutation({
        variables: {
          text,
        },
      });
    },
    [setOrganizationContractTextMutation],
  );

  const setOrganizationCreditConcepts = useCallback(
    async (data: CreditConceptFormFields) => {
      await setOrganizationCreditConceptsMutation({
        variables: {
          data,
        },
      });
    },
    [setOrganizationCreditConceptsMutation],
  );

  const updateOrganizationLogo = useCallback(
    async ({ id, logoId }: { id: string; logoId: string }) => {
      await updateOrganizationLogoMutation({
        variables: {
          id,
          logoId,
        },
      });
    },
    [updateOrganizationLogoMutation],
  );

  const validatePin = useCallback(
    async (code: string) => {
      const response = await validatePinMutation({
        variables: {
          code,
        },
      });

      return !!response?.data?.validateOrganizationPin;
    },
    [validatePinMutation],
  );

  const desactivateOrganization = useCallback(async () => {
    const { data } = await desactivateOrganizationMutation();

    if (data?.desactivateOrganization) {
      window.location.reload();
    }
  }, [desactivateOrganizationMutation]);

  const removeDriveIntegration = useCallback(async () => {
    await removeDriveIntegrationMutation();
  }, [removeDriveIntegrationMutation]);

  return {
    setOrganizationConfig,
    setOrganizationCreditConcepts,
    toggleOrganizationOrder,
    updateOrganizationLogo,
    setOrganizationContractText,
    validatePin,
    desactivateOrganization,
    removeDriveIntegration,
    loading:
      isLoadingToggleOrganizationOrders ||
      isLoadingSetOrganizationConfig ||
      isLoadingSetOrganizationCreditConcepts ||
      isLoadingUpdateOrganizationLogo ||
      isLoadingSetOrganizationContractTextMutation ||
      isLoadingValidatePin ||
      isLoadingDesactivateOrganization ||
      removeDriveIntegrationIsLoading,
  };
};

export default useOrganizationActions;
