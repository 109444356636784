import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import type { ExpenseData } from 'apollo/generated/ExpenseData';
import parseISO from 'date-fns/parseISO';
import type { CreateExpense_createExpense } from 'apollo/generated/CreateExpense';
import { normalizeCategory } from './Category';
import { Provider } from './Provider';

const getPriceWithTaxes = (price: number, taxes: number): number => {
  return Number((price + price * (taxes / 100)).toFixed(2));
};

export type ExpenseFormFields = Readonly<{
  id?: string;
  categoryIds: string[];
  description: string;
  name: string;
  price: number;
  providerId: Provider['id'];
  providerName?: Provider['id'];
  taxes: number;
  quantity?: number;
}>;

export const normalizeExpense = (
  input: ExpenseData | CreateExpense_createExpense,
) => {
  const price = input?.price ? Number(input.price) : 0;
  const taxes = input?.taxes ? Number(input.taxes) : 0;

  return {
    // ID
    id: input.id || '',

    // FIELDS
    addedBy: {
      id: input.addedBy.id,
      fullName: `${input.addedBy.user.firstName} ${input.addedBy.user.lastName}`,
    },
    canceled: input.canceled || false,
    canceledAt: input.canceledAt ? parseISO(input.canceledAt).getTime() : null,
    createdAt: parseISO(input.createdAt).getTime(),
    categories: removeOptionalsFromArray(input?.categories ?? []).map(
      (occurrence) => normalizeCategory(occurrence as any),
    ),
    description: input.description || '',
    name: input.name || '',
    originalPrice: price,
    price: getPriceWithTaxes(price, taxes),
    provider: {
      id: input.provider.id || '',
      name: input.provider.name || '',
    },
    quantity: input?.quantity ? Number(input.quantity) : 0,
    taxes,
  };
};

export type Expense = ReturnType<typeof normalizeExpense>;
