import type { FC } from 'react';
import Header from 'containers/Common/Header';
import NewRequestTicket from 'containers/Modals/NewRequestTicket';
import SectionOption from './SectionOption';
import SectionContent from './SectionContent';
import {
  Container,
  Disclaimer,
  Label,
  Main,
  ReadonlyField,
  Row,
  Value,
  Filters,
  Sections,
  Footer,
  SelectedSection,
  SectionNav,
  Link,
  CurrentSectionLink,
} from './styles';
import useLogic from './logic';

const SupportView: FC = () => {
  const {
    filter,
    handleChange,
    handleSearch,
    isRefetching,
    openNewTicketRequestModal,
    sections,
    selectedSection,
  } = useLogic();

  return (
    <>
      <Container>
        <Header
          title="Soporte"
          buttonText="Crear ticket"
          buttonVariant="light"
          onClickButton={openNewTicketRequestModal}
          showGlobalCreateButton={false}
          showRfidButton={false}
        />
        <Main>
          {selectedSection && (
            <SelectedSection>
              <SectionNav>
                <Link to="/support">Inicio</Link>/
                <CurrentSectionLink>{selectedSection.title}</CurrentSectionLink>
              </SectionNav>
              <SectionContent section={selectedSection} />
            </SelectedSection>
          )}

          {!selectedSection && (
            <>
              <Filters
                isLoading={isRefetching}
                onSearchChange={handleSearch}
                filter={filter}
                nResults={sections.length || 0}
              />
              <Sections>
                {sections.map((section) => (
                  <SectionOption
                    key={section.sectionName}
                    sectionName={section.sectionName}
                    title={section.title}
                    description={section.description}
                    onSelect={handleChange}
                  />
                ))}
              </Sections>
            </>
          )}

          <Footer>
            <Row>
              <ReadonlyField>
                <Label>Email de contacto</Label>
                <Value>contacto@inditas.com</Value>
              </ReadonlyField>
            </Row>
            <Row>
              <Disclaimer>
                Si tienes algún problema, consulta o gestión y quieres contactar
                con soporte, escribe a esta dirección de correo electrónico
                explicando lo sucedido, te atenderemos en un plazo de 2-3 días.
              </Disclaimer>
            </Row>
          </Footer>
        </Main>
      </Container>
      <NewRequestTicket />
    </>
  );
};

export default SupportView;
