import { QueryHookOptions, useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import notEmpty from 'utils/notEmpty';
import { userRoleVar } from 'apollo/reactive';
import { GET_PERMISSIONS_GROUPS } from 'apollo/queries/permissionsGroup';
import { UserRole } from 'apollo/generated/globalTypes';
import { normalizePermissions } from 'model/PermissionsGroup';
import type { GetPermissionsGroupsQuery } from 'apollo/graphql.types';

const usePermissionsGroups = ({
  fetchPolicy = 'cache-and-network',
}: {
  fetchPolicy?: QueryHookOptions<GetPermissionsGroupsQuery>['fetchPolicy'];
} = {}) => {
  const userRole = useReactiveVar(userRoleVar);

  const { loading, data, refetch } = useQuery<GetPermissionsGroupsQuery>(
    GET_PERMISSIONS_GROUPS,
    {
      fetchPolicy,
      skip: ![UserRole.ADMIN, UserRole.INDITAS].includes(userRole),
    },
  );

  const permissionsGroups = useMemo(
    () =>
      data?.getPermissionsGroups
        ? data.getPermissionsGroups.filter(notEmpty).map(normalizePermissions)
        : [],
    [data],
  );

  return {
    getPermissionsGroupsLoading: loading,
    permissionsGroups,
    refetch,
  };
};

export default usePermissionsGroups;
