import { memo, FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import {
  Benefit,
  Container,
  Costs,
  Discounts,
  Name,
  Purchases,
  Quantity,
  RemainingQuantity,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, product }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const { name, quantity, consumedQuantity, totalCosts, totalPurchases } =
    product;

  return (
    <Container className={className}>
      <Name>{name}</Name>
      {fromTabletPortrait && <Quantity>{consumedQuantity}</Quantity>}
      <Discounts />
      <Purchases>
        <Price>{totalPurchases}</Price>
      </Purchases>
      <Costs>
        <Price>{totalCosts}</Price>
      </Costs>
      <Benefit />
      {fromTabletPortrait && <RemainingQuantity>{quantity}</RemainingQuantity>}
    </Container>
  );
};

export default memo(Row);
