import styled from 'styled-components';
import DefaultModal, { Content } from 'components/Modal';
import { from } from 'styles/responsive';
import { prettyScrollbar } from 'styles/mixins';
import DefaultButton from 'components/Button';

export const Container = styled(DefaultModal)`
  ${Content} {
    display: flex;
    flex-direction: column;

    ${from.tabletPortrait`
      max-width: 55rem;
    `}
  }
`;

export const FiltersContainer = styled.div`
  flex: 0 1 auto;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;
`;

export const Partners = styled.div`
  flex: 1 1 auto;
  padding: 1rem 0;
  min-height: 20rem;
  ${prettyScrollbar};
  max-height: 20rem;
  overflow-y: scroll;
`;

export const ReturnButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
`;
