import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS, TABLE_MOBILE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 1rem 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;

  ${from.tabletLandscape`
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
`}
`;

const TextEllipsis = css`
  font-size: 0.8rem;
  overflow: hidden;
  white-space: pre-wrap;
  text-transform: capitalize;
  text-overflow: ellipsis;

  ${from.tabletPortrait`
  font-size: 1rem;
`}
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 0 ${TABLE_MOBILE_METRICS.name};
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-right: 1rem;

  ${TextEllipsis};

  ${from.tabletPortrait`
  flex: 0 0 ${TABLE_METRICS.name};
`}
`;

export const Benefit = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_MOBILE_METRICS.benefit};
  font-weight: ${({ theme }) => theme.weights.regular};

  ${TextEllipsis};

  ${from.tabletPortrait`
  flex: 0 0 ${TABLE_METRICS.benefit};
  margin-right: 1rem;
`}
`;

export const Costs = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_MOBILE_METRICS.costs};
  font-weight: ${({ theme }) => theme.weights.regular};

  ${TextEllipsis};

  ${from.tabletPortrait`
  flex: 0 0 ${TABLE_METRICS.costs};
  margin-right: 1rem;
`}
`;

export const Quantity = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_MOBILE_METRICS.quantity};
  font-weight: ${({ theme }) => theme.weights.regular};

  ${TextEllipsis};

  ${from.tabletPortrait`
  flex: 0 0 ${TABLE_METRICS.quantity};
  margin-right: 1rem;
`}
`;

export const RealQuantity = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_MOBILE_METRICS.realQuantity};
  font-weight: ${({ theme }) => theme.weights.regular};

  ${TextEllipsis};

  ${from.tabletPortrait`
  flex: 0 0 ${TABLE_METRICS.realQuantity};
`}
`;

export const Orders = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  flex: 0 0 ${TABLE_METRICS.orders};
  font-weight: ${({ theme }) => theme.weights.regular};

  ${TextEllipsis};
`;
