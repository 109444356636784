import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PREVIOUS_BOOKINGS } from 'apollo/queries';
import notEmpty from 'utils/notEmpty';
import { normalizeBooking } from 'model/Booking';

const usePreviousBookings = () => {
  const { loading, data, refetch } = useQuery(GET_PREVIOUS_BOOKINGS);

  const { previousBookings } = useMemo(
    () => ({
      previousBookings: data?.previousBookings
        ? data.previousBookings.filter(notEmpty).map(normalizeBooking)
        : [],
    }),
    [data],
  );

  return {
    loading,
    previousBookings,
    refetch,
  };
};

export default usePreviousBookings;
