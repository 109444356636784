import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import type { TypeOfCash } from 'apollo/graphql.types';

const useConnect = () => {
  const { close, type, selectTypeOfCashPayload } = useModal();
  const { typesOfCash, onSelect, onReturn } = selectTypeOfCashPayload || {};

  const initialValues = useMemo<{ typesOfCash: TypeOfCash[] }>(() => {
    return {
      typesOfCash: (typesOfCash || []).map((typeOfCash) => ({
        key: Number(typeOfCash.key),
        value: Number(typeOfCash.value),
      })),
    };
  }, [typesOfCash]);

  const handleClose = useCallback(() => {
    if (onReturn) {
      onReturn();
    } else {
      close();
    }
  }, [close, onReturn]);

  const handleSave = useCallback<
    FormikConfig<{ typesOfCash: TypeOfCash[] }>['onSubmit']
  >(
    async ({ typesOfCash }) => {
      if (onSelect) {
        onSelect(typesOfCash);
      }
    },
    [onSelect],
  );

  return {
    handleClose,
    handleSave,
    initialValues,
    isOpen: type === ModalType.SELECT_TYPE_OF_CASH,
  };
};

export default useConnect;
