import { FC, memo } from 'react';
import { Formik } from 'formik';
import {
  Container,
  SubmitButton,
  Categories,
  CategorySwitch,
  CategoryOptions,
  CategoryOption,
} from './styles';
import type { Props } from './types';

const ProductsForm: FC<Props> = ({
  products,
  className,
  onSubmit,
  initialValues,
  onToggleProduct,
}) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      <Container className={className}>
        {products?.length > 0 && (
          <Categories role="group">
            <CategoryOptions>
              {products.map((product) => (
                <CategoryOption key={product.id}>
                  <CategorySwitch
                    id={product.id}
                    isChecked={initialValues?.includes(product.id)}
                    label={product.name}
                    onSelect={onToggleProduct}
                  />
                </CategoryOption>
              ))}
            </CategoryOptions>
          </Categories>
        )}
        <SubmitButton type="submit">Guardar</SubmitButton>
      </Container>
    </Formik>
  );
};

export default memo(ProductsForm);
