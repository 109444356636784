import type { FC } from 'react';
import Placeholder from 'components/Placeholder';
import useConnect from './connect';
import {
  Buttons,
  CancelButton,
  Container,
  EmptyState,
  IconCircle,
  Icon,
  Title,
  Quotas,
  Price,
  AddDiscountButton,
} from './styles';
import Row from './Row';

const AssignQuota: FC = () => {
  const {
    discount,
    handleAddDiscount,
    handleAssignQuota,
    handleClose,
    handleOpenCreditsModal,
    handleOpenQuotaModalModal,
    isLoading,
    isOpen,
    partnerCredits,
    quotas,
    subscription,
  } = useConnect();

  const hasDiscount = !!discount;

  return (
    <Container
      disableBackdropClick
      disableEscapeKeyDown
      disableFullScreen
      open={isOpen}
      onClose={handleClose}
    >
      <IconCircle>
        <Icon />
      </IconCircle>
      <Title>
        Selecciona una cuota (
        <Price onClick={handleOpenCreditsModal}>{partnerCredits}</Price>)
        {subscription.allowedDiscounts && (
          <AddDiscountButton
            $hasDiscount={hasDiscount}
            onClick={handleAddDiscount}
          >
            {hasDiscount
              ? `Descuento aplicado '${discount.name}'`
              : 'Añadir descuento'}
          </AddDiscountButton>
        )}
      </Title>
      {isLoading ? (
        <Placeholder nRows={2} />
      ) : (
        <Quotas>
          {quotas.length > 0 ? (
            quotas.map((quota) => (
              <Row
                discount={discount}
                key={quota.id}
                name={quota.name}
                onSelectQuota={() => handleAssignQuota(quota.id)}
                price={quota.price}
                type={quota.type}
                value={quota.value}
              />
            ))
          ) : (
            <EmptyState
              title="No hay cuotas"
              iconName="quota"
              buttonLabel="Crear Cuota"
              onClickButton={handleOpenQuotaModalModal}
            >
              Crea tu primera Cuota
            </EmptyState>
          )}
        </Quotas>
      )}

      <Buttons>
        <CancelButton onClick={handleClose}>Cerrar</CancelButton>
      </Buttons>
    </Container>
  );
};

export default AssignQuota;
