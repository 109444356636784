import type { FC } from 'react';
import { UserRole } from 'apollo/generated/globalTypes';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import Price from 'components/Price';
import { formatDateToTableExtended } from 'utils/dates/formatDates';
import CashierPartnersModal from '../../Modals/CashierPartners';
import SelectTypeOfCashModal from '../../Modals/SelectTypeOfCash';
import CashierProductsModal from '../../Modals/CashierProducts';
import CloseCashierModal from '../../Modals/CloseCashier';
import Products from './Products';
import Partners from './Partners';
import Quotas from './Quotas';
import useConnect from './connect';
import {
  CashierActionStatusTitle,
  Container,
  ContentSection,
  DateTimeFilterContainer,
  EmptyState,
  InfoContent,
  InfoContentTitle,
  InfoContentValue,
  Main,
  Section,
  Sections,
} from './styles';

const Cashier: FC = () => {
  const {
    cashier,
    cashierLoading,
    csvPartnersInfo,
    csvProductsInfo,
    csvQuotasInfo,
    filterDates,
    handleCloseCashier,
    handleOnSearch,
    handleOpenCashier,
    isLoading,
    lastCashier,
    employeePermissions,
    userRole,
  } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  const hasProducts = cashier?.products && cashier.products.items.length > 0;
  const hasPartners = cashier?.partners && cashier.partners.length > 0;
  const hasQuotas = cashier?.quotas && cashier.quotas.length > 0;

  const cashierIsOpen = lastCashier && !lastCashier.end;
  const allowShowDatePicker =
    userRole === UserRole.ADMIN || !employeePermissions.allowShowCashierOneDay;
  const allowShowProducts =
    userRole === UserRole.ADMIN || employeePermissions.allowShowProductsCashier;
  const allowShowPartners =
    userRole === UserRole.ADMIN || employeePermissions.allowShowPartnersCashier;
  const allowDownload =
    userRole === UserRole.ADMIN || employeePermissions.allowDownloadCashierData;

  return (
    <>
      <Container>
        <Header
          title="Caja"
          onClickButton={cashierIsOpen ? handleCloseCashier : handleOpenCashier}
          buttonVariant={cashierIsOpen ? 'light' : 'primary'}
          buttonText={cashierIsOpen ? 'Cerrar caja' : 'Abrir caja'}
          buttonSize="small"
          showGlobalCreateButton={false}
          showRfidButton={false}
        />
        <Main>
          {cashierLoading ? (
            <Loader />
          ) : (
            <CashierActionStatusTitle $isOpen={!lastCashier?.end}>
              {lastCashier &&
                !lastCashier.end &&
                `Caja abierta el ${formatDateToTableExtended(
                  lastCashier.start,
                )} por ${lastCashier.openedBy?.fullName}`}
              {lastCashier &&
                lastCashier.end &&
                `Última caja cerrada el ${formatDateToTableExtended(
                  lastCashier.end,
                )} por ${lastCashier.closedBy?.fullName}`}
            </CashierActionStatusTitle>
          )}

          {allowShowDatePicker && (
            <DateTimeFilterContainer>
              <DateTimeFilter
                fromDate={filterDates.from}
                toDate={filterDates.to}
                onSearch={handleOnSearch}
              />
            </DateTimeFilterContainer>
          )}

          <Sections>
            {hasProducts || hasPartners || cashier?.totalExpenses ? (
              <ContentSection>
                <InfoContent>
                  <InfoContentTitle>Créditos Ingresados</InfoContentTitle>{' '}
                  <InfoContentValue>
                    <Price>{cashier?.depositedCredits || 0.0}</Price>
                  </InfoContentValue>
                </InfoContent>
                <InfoContent>
                  <InfoContentTitle>Gastos</InfoContentTitle>{' '}
                  <InfoContentValue>
                    <Price>{cashier?.totalExpenses || 0.0}</Price>
                  </InfoContentValue>
                </InfoContent>
                <InfoContent>
                  <InfoContentTitle>Créditos en caja</InfoContentTitle>{' '}
                  <InfoContentValue>
                    <Price>{cashier?.totalRealCredits || 0.0}</Price>
                  </InfoContentValue>
                </InfoContent>
                <InfoContent>
                  <InfoContentTitle>Nuevos Socios</InfoContentTitle>{' '}
                  <InfoContentValue>
                    {cashier?.newPartners || 0}
                  </InfoContentValue>
                </InfoContent>
                <InfoContent>
                  <InfoContentTitle>Nuevos Productos</InfoContentTitle>{' '}
                  <InfoContentValue>
                    {cashier?.products?.stats?.newProducts || 0}
                  </InfoContentValue>
                </InfoContent>
              </ContentSection>
            ) : null}
            {!hasProducts && !hasPartners && (
              <EmptyState title="No existen transacciones" iconName="cashier">
                No disponemos de suficiente información para mostrar los datos
              </EmptyState>
            )}
            {hasProducts && allowShowProducts && (
              <Section>
                <Products
                  allowDownload={allowDownload}
                  csv={csvProductsInfo}
                  products={cashier.products}
                  filterDates={filterDates}
                />
              </Section>
            )}
            {hasPartners && allowShowPartners && (
              <Section>
                <Partners
                  allowDownload={allowDownload}
                  csv={csvPartnersInfo}
                  partners={cashier.partners}
                />
              </Section>
            )}

            {hasQuotas && allowShowPartners && (
              <Section>
                <Quotas
                  allowDownload={allowDownload}
                  csv={csvQuotasInfo}
                  quotas={cashier?.quotas}
                />
              </Section>
            )}
          </Sections>
        </Main>
      </Container>
      <CashierPartnersModal />
      <CloseCashierModal />
      <SelectTypeOfCashModal />
      <CashierProductsModal />
    </>
  );
};

export default Cashier;
