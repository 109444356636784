import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import useDiscounts from 'apollo/hooks/discount/useDiscounts';
import useDiscountActions from 'apollo/hooks/discount/useDiscountActions';
import { permissionsStateVar } from 'apollo/reactive/permissionsState';
import { formatErrors } from 'utils/errors/formatErrors';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import type { Discount } from 'model/Discount';

const useConnect = () => {
  const {
    discounts,
    loading: discountsLoading,
    refetch: refetchDiscounts,
  } = useDiscounts();
  const { deleteDiscount, loading: discountActionsLoading } =
    useDiscountActions();
  const { openDialog, openDiscount, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const handleRemoveDiscount = useCallback(
    async (discount: Discount) => {
      try {
        await deleteDiscount(discount.id);
        await refetchDiscounts();
        close();
        enqueueSnackbar('El descuento se ha eliminado correctamente.', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('discount', e.message, 'eliminar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [close, deleteDiscount, enqueueSnackbar, refetchDiscounts],
  );

  const handleOpenRemoveDiscountDialog = useCallback(
    async (discount: Discount) => {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar un descuento y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: () => handleRemoveDiscount(discount),
        title: 'Eliminar descuento',
        variant: 'danger',
      });
    },
    [handleRemoveDiscount, openDialog],
  );

  return {
    discounts,
    employeePermissions,
    handleOpenDiscountModal: openDiscount,
    handleOpenRemoveDiscountDialog,
    isLoading: discountsLoading || discountActionsLoading,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
