import { FC, memo } from 'react';
import {
  SectionTitle,
  Header,
  BackLink,
  HeaderContent,
  CreateButton,
} from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const PermissionsGroups: FC = () => {
  const { permissionsGroups, handleNavigateToCreationView } = useConnect();

  const hasPermissions = permissionsGroups.length > 0;

  return (
    <Container>
      <Header>
        <HeaderContent>
          <BackLink to="/organization" />
          <SectionTitle>Grupos de permisos</SectionTitle>
        </HeaderContent>
        <CreateButton onClick={handleNavigateToCreationView}>
          Crear
        </CreateButton>
      </Header>
      <Main>
        {!hasPermissions && (
          <EmptyState
            title="No hay grupos de permisos"
            iconName="organization"
            buttonLabel="Crear grupo"
            onClickButton={handleNavigateToCreationView}
          >
            Crea tu grupo de permisos
          </EmptyState>
        )}

        {hasPermissions && (
          <>
            <TableHead />{' '}
            {permissionsGroups.map((group) => (
              <Row key={group.id} id={group.id} name={group.name} />
            ))}
          </>
        )}
      </Main>
    </Container>
  );
};

export default memo(PermissionsGroups);
