import styled, { css } from 'styled-components';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import DefaultDoneIcon from 'components/Icon/Icons/Done';
import DefaultPrice from 'components/Price';

export const Thumbnail = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  justify-content: center;
`;

export const Container = styled.a<{ $isSelected: boolean }>`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 3.4625rem auto;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey500}0a;
  }

  ${({ $isSelected, theme }) => css`
    ${theme.colors.products
      .map((color, index) => {
        return `
          :nth-child(${index + 1}n) {
            ${
              $isSelected
                ? `
                background-color: ${color}14;
                
                &:hover {
                  background-color: ${color}28;
                }
              `
                : ''
            }

            ${Thumbnail} {
              ${$isSelected ? '' : `background-color: ${color}14;`}
              color: ${color};
            }
          }
        `;
      })
      .join('\n')}
  `};
`;

export const InfoProduct = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}0a;
  justify-content: space-between;
  width: 100%;
  padding: 1.125rem 0;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  flex: 1;
  text-transform: capitalize;
`;

export const ReferenceCode = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: 600;
  font-size: 0.8rem;
  margin-left: 0.25rem;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OriginalPrice = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-right: 0.5rem;
  position: relative;

  &:before {
    border-color: inherit;
    border-top: 1px solid;
    content: '';
    left: 0;
    position: absolute;
    right: 15px;
    top: 45%;
    transform: skewY(-10deg);
  }
`;

export const Price = styled(DefaultPrice)`
  color: ${({ theme }) => theme.colors.grey900};
  margin-right: 1rem;
`;

export const ChevronIcon = styled(DefaultArrowRightIcon)`
  color: ${({ theme }) => theme.colors.grey500};
  margin-right: 1rem;
  width: 0.4rem;
  opacity: 0.7;
  z-index: -10;
`;

export const DoneIcon = styled(DefaultDoneIcon)`
  width: 50%;
`;

export const ImageContainer = styled.div`
  align-content: center;
  display: flex;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
