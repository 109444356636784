import styled, { css } from 'styled-components';
import DefaultAddIcon from 'components/Icon/Icons/Add';
import DefaultRfidIcon from 'components/Icon/Icons/Rfid';
import DefaultLogo from 'components/Logo';
import DefaultIcon from 'components/Icon';
import { from } from 'styles/responsive';
import { ZIndex } from 'styles/zIndex';
import DefaultButton from 'components/Button';
import type { Props } from './types';

export const HEADER_HEIGHT = '6rem';
export const HEADER_CHILDREN_MARGIN = '2rem';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: ${ZIndex.HEADER};

  > *:nth-child(2) {
    margin-top: ${HEADER_CHILDREN_MARGIN};
  }

  ${from.tabletLandscape`
    position: initial;
  `};
`;

export const Title = styled.h1`
  display: none;

  ${from.tabletLandscape`
    display: initial;
  `};
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Icon = styled(DefaultIcon)`
  cursor: pointer;
  margin-right: 1rem;
`;

export const HeaderBar = styled.header`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-height: ${HEADER_HEIGHT};
  padding: 0 2rem;

  ${from.tabletLandscape`
    padding: 0 1.25rem;
  `};
`;

export const Logo = styled(DefaultLogo)`
  ${from.tabletLandscape`
    display: none;
  `};
`;

export const AddIcon = styled(DefaultAddIcon)`
  height: 1.6rem;
  width: 1.6rem;
  transition: color 200ms ease-out;
`;

export const RfidIcon = styled(DefaultRfidIcon)`
  height: 1.6rem;
  width: 1.6rem;
  transition: color 200ms ease-out;
`;

export const CreateButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 0.25rem;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  padding: 0.6rem 0.8rem;

  &:not(:last-child) {
    margin-right: 0.8rem;
  }

  &:hover {
    ${AddIcon} {
      color: ${({ theme }) => theme.colors.accent};
    }

    ${RfidIcon} {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

export const Button = styled(DefaultButton)<{
  $buttonSize?: Props['buttonSize'];
}>`
  &&& {
    margin-right: 1rem;
    ${({ $buttonSize }) =>
      $buttonSize === 'small' &&
      css`
        font-size: 0.8rem;
        padding: 0.7rem;
      `}
  }
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
`;
