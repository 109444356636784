import { FC, memo } from 'react';
import Loader from 'components/Loader';
import TableHead from './TableHead';
import Row from './Row';
import useLogic from './logic';
import {
  Button,
  Container,
  EmptyState,
  Section,
  SectionTitle,
  SectionContent,
  Title,
} from './styles';
import type { Props } from './types';

const PartnerArchivesForm: FC<Props> = ({
  className,
  partnerId,
  files,
  isLoading,
}) => {
  const {
    employeePermissions,
    handleOpenFile,
    handleRemoveFile,
    handleUploadFileToDrive,
    handleOpenUploadFileDialog,
  } = useLogic(partnerId, files);
  const hasFiles = files.length > 0;

  return (
    <Container className={className}>
      <Section>
        <SectionTitle>
          <Title>Archivos del socio</Title>
          {employeePermissions.allowCreateFiles && (
            <Button variant="light" onClick={handleOpenUploadFileDialog}>
              Subir archivo
            </Button>
          )}
        </SectionTitle>
        <SectionContent>
          {isLoading && <Loader />}
          {!isLoading && !hasFiles && employeePermissions.allowCreateFiles && (
            <EmptyState
              title="No hay archivos"
              iconName="uploadFile"
              buttonLabel="Añadir archivo"
              onClickButton={handleOpenUploadFileDialog}
            >
              Carga tu primer archivo
            </EmptyState>
          )}
          {!isLoading && hasFiles && (
            <>
              <TableHead />
              {files.map((file) => (
                <Row
                  key={file.id}
                  name={file.fileName}
                  fileDriveId={file.fileDriveId}
                  employeePermissions={employeePermissions}
                  onOpen={() => handleOpenFile(file.url)}
                  onRemove={() => handleRemoveFile(file.id)}
                  onUploadToDrive={() => handleUploadFileToDrive(file.id)}
                />
              ))}
            </>
          )}
        </SectionContent>
      </Section>
    </Container>
  );
};

export default memo(PartnerArchivesForm);
