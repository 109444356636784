import { FC, memo } from 'react';
import format from 'date-fns/format';
import { Container, TextField } from './styles';
import { Props } from './types';

const DatePickerTime: FC<Props> = ({
  className,
  label,
  onChange,
  value = new Date(),
}) => {
  const formattedValue = `${format(value, 'yyyy-MM-dd')}T${format(
    value,
    'HH:mm',
  )}`;

  return (
    <Container className={className}>
      <TextField
        id="datetime-local"
        type="datetime-local"
        label={label}
        value={formattedValue}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange}
      />
    </Container>
  );
};

export default memo(DatePickerTime);
