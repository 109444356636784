import styled from 'styled-components';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled.div``;

export const Main = styled.div`
  margin-top: 1rem;
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const DateTimeFilterContainer = styled.div``;
