import { FC, memo } from 'react';
import { CSVLink } from 'react-csv';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Icon from 'components/Icon';
import {
  AddedCredits,
  Container,
  Download,
  DownloadButton,
  Name,
  Products,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, allowDownload, csv }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const fromTabletLandscape = useMediaQuery(from.tabletLandscape.query);

  return (
    <Container className={className}>
      <Name>Cuotas</Name>
      <Products>Socios</Products>
      <AddedCredits>{fromTabletPortrait ? 'Créditos' : 'Cr'}</AddedCredits>
      {allowDownload && (
        <Download>
          {fromTabletLandscape ? (
            <DownloadButton variant="secondary">
              <CSVLink
                data={csv.data}
                headers={csv.headers}
                filename={csv.filename}
                target="_blank"
              >
                Descargar
              </CSVLink>
            </DownloadButton>
          ) : (
            <CSVLink
              data={csv.data}
              headers={csv.headers}
              filename={csv.filename}
              target="_blank"
            >
              <Icon size={16} name="uploadFile" />
            </CSVLink>
          )}
        </Download>
      )}
    </Container>
  );
};

export default memo(Row);
