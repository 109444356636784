import { gql } from '@apollo/client';

export const CREATE_PERMISSIONS_GROUP = gql`
  mutation CreatePermissionsGroup($data: PermissionsGroupDto!) {
    createPermissionsGroup(data: $data) {
      id
    }
  }
`;

export const EDIT_PERMISSIONS_GROUP = gql`
  mutation EditPermissionsGroup($id: ID!, $data: PermissionsGroupDto!) {
    editPermissionsGroup(id: $id, data: $data) {
      id
    }
  }
`;

export const REMOVE_PERMISSIONS_GROUP = gql`
  mutation RemovePermissionsGroup($id: ID!) {
    removePermissionsGroup(id: $id)
  }
`;
