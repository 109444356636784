import { FC, memo } from 'react';
import { Container, Progress } from './styles';
import { Props } from './types';

// ToDO: enhance this component -> e.g add "description", "size", etc props
const Loader: FC<Props> = ({ className, size = 50 }) => (
  <Container className={className}>
    <Progress size={size} />
  </Container>
);

export default memo(Loader);
