import { object as yupObject, string as yupString } from 'yup';
import { OrganizationMenuType } from 'apollo/graphql.types';

export const DEFAULT_COLORS = {
  background: '#efefef',
  categoryName: '#812b05',
  productTitle: '#0A2737',
  productPrice: '#812b05',
  productDescription: '#0A2737',
  imageBorder: '#812b05',
};

export const MENU_TYPE_OPTIONS = [
  {
    value: OrganizationMenuType.Static,
    label: 'Estático',
  },
  {
    value: OrganizationMenuType.Selectable,
    label: 'Dinámico',
  },
];

export const validationSchema = yupObject({
  name: yupString().required('El nombre es requerido.'),
  menuCode: yupString().required('El código es requerido.'),
});
