import styled from 'styled-components';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';
import DefaultButton from 'components/Button';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Main = styled(SectionContainer)`
  padding: 1rem 0 3.6rem 0;
  position: relative;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const ListContent = styled.div`
  margin-top: 3rem;

  ${from.tabletPortrait`
    margin-top: 1rem;
  `};
`;

export const Button = styled(DefaultButton)`
  align-self: flex-end;
  margin-bottom: 1rem;

  &&& {
    padding: 0.3rem 1rem;
  }
`;
