import styled from 'styled-components';
import DefaultModal, { Content as ModalContent } from 'components/Modal';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultFilters from 'components/Filters';
import DefaultEmptyState from 'components/EmptyState';

export const Container = styled(DefaultModal)`
  ${ModalContent} {
    display: flex;
    flex-direction: column;
    max-width: 27rem;
    ${from.tabletLandscape`
      min-height: 40rem;
      max-width: 55rem;
    `}
  }
`;

export const Filters = styled(DefaultFilters)`
  flex: 1 1 auto;
`;

export const PartnersList = styled.div`
  flex: 1 1 auto;
  margin: 0.8rem 0;
  overflow-y: scroll;

  ${from.tabletPortrait`
    max-height: 30rem;
  `}
`;

export const ButtonContent = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;
`;

export const ReturnButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;
