import { FC, memo } from 'react';

import type { Props } from './types';

const Up: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      fill="currentColor"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke="currentColor"
      strokeWidth="1.5"
    >
      <path d="M12 18.116V7.034M7 11l5-5 5 5" />
    </g>
  </svg>
);

export default memo(Up);
