import styled from 'styled-components';
import DefaultButton from 'components/Button';
import { from } from 'styles/responsive';
import DefaultIcon from 'components/Icon';
import { Link as DefaultLink } from 'react-router-dom';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 0;
  display: flex;
  flex-flow: column;
  position: absolute;
  padding-right: 2rem;
  width: 100%;
`;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.grey500};
  opacity: 0.7;
`;

export const PreviousOrdersTitle = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const PreviousOrdersContainer = styled(DefaultLink)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.black}0a;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  &:hover {
    ${Icon} {
      color: ${({ theme }) => theme.colors.primary};
      opacity: 1;
      transition: color 0.3s ease;
    }
    ${PreviousOrdersTitle} {
      font-weight: ${({ theme }) => theme.weights.semiBold};
      transition: font-weight 0.2s ease;
    }
  }
`;

export const DisableButtonContainer = styled.div`
  margin-top: 1.5rem;

  ${from.tabletLandscape`
    display: none;
  `};
`;

export const Button = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
`;
