import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CREATE_EVENT,
  REMOVE_EVENT,
  UPDATE_EVENT,
} from 'apollo/mutations/events';
import { GET_EVENTS } from 'apollo/queries/events';
import type { EventFormFields } from 'model/OrganizationEvent';
import type {
  RemoveEvent,
  RemoveEventVariables,
} from 'apollo/generated/RemoveEvent';
import type {
  UpdateEvent,
  UpdateEventVariables,
} from 'apollo/generated/UpdateEvent';
import type {
  CreateEvent,
  CreateEventVariables,
} from 'apollo/generated/CreateEvent';

const useEventActions = () => {
  const [createEventMutation, { loading: isLoadingCreateEvent }] = useMutation<
    CreateEvent,
    CreateEventVariables
  >(CREATE_EVENT, { refetchQueries: [GET_EVENTS] });
  const [removeEventMutation, { loading: isLoadingRemoveEvent }] = useMutation<
    RemoveEvent,
    RemoveEventVariables
  >(REMOVE_EVENT, { refetchQueries: [GET_EVENTS] });
  const [updateEventMutation, { loading: isLoadingUpdateEvent }] = useMutation<
    UpdateEvent,
    UpdateEventVariables
  >(UPDATE_EVENT, { refetchQueries: [GET_EVENTS] });

  const normalizeFormValues = useCallback(
    (values: EventFormFields) => ({
      title: values.title,
      startDate: values.startDate,
      endDate: values.endDate,
      allDay: values.allDay,
      notify: values.notify,
      emails: values.emails?.split(',') || [],
    }),
    [],
  );

  const createEvent = useCallback(
    async (data: EventFormFields) => {
      const normalizedValues = normalizeFormValues(data);
      await createEventMutation({
        variables: {
          data: normalizedValues,
        },
      });
    },
    [createEventMutation, normalizeFormValues],
  );

  const updateEvent = useCallback(
    async ({ id, data }: { id: string; data: EventFormFields }) => {
      const dto = normalizeFormValues(data);
      await updateEventMutation({
        variables: {
          organizationEventId: id,
          data: dto,
        },
      });
    },
    [normalizeFormValues, updateEventMutation],
  );

  const deleteEvent = useCallback(
    async (id: string) => {
      await removeEventMutation({
        variables: {
          organizationEventId: id,
        },
      });
    },
    [removeEventMutation],
  );

  return {
    createEvent,
    deleteEvent,
    updateEvent,
    loading:
      isLoadingCreateEvent || isLoadingRemoveEvent || isLoadingUpdateEvent,
  };
};

export default useEventActions;
