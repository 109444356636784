import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_LAST_CASHIER } from 'apollo/queries/cashiers';
import { normalizeCashier } from 'model/Cashier';
import type {
  GetLastCashierQuery,
  GetLastCashierQueryVariables,
} from 'apollo/graphql.types';

const useLastCashier = ({
  fetchPolicy = 'cache-and-network',
}: {
  fetchPolicy?: QueryHookOptions<GetLastCashierQuery>['fetchPolicy'];
} = {}) => {
  const {
    data,
    loading: getLastCashierLoading,
    refetch: lastCashierRefetch,
  } = useQuery<GetLastCashierQuery, GetLastCashierQueryVariables>(
    GET_LAST_CASHIER,
    {
      fetchPolicy,
    },
  );

  const lastCashier = useMemo(
    () => (data?.getLastCashier ? normalizeCashier(data.getLastCashier) : null),
    [data],
  );

  return {
    getLastCashierLoading,
    lastCashier,
    lastCashierRefetch,
  };
};

export default useLastCashier;
