import styled, { css } from 'styled-components';
import DefaultTrashIcon from 'components/Icon/Icons/Trash';
import DefaultEditIcon from 'components/Icon/Icons/Edit';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Number = styled.div<{ $hasExpenses: boolean }>`
  color: ${({ theme, $hasExpenses }) =>
    $hasExpenses ? theme.colors.accent : theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 1 ${TABLE_METRICS.number};
  margin-left: 1rem;
  margin-right: 1rem;
  ${TextEllipsis};

  ${({ $hasExpenses }) =>
    $hasExpenses &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  flex: 0 0 ${TABLE_METRICS.name};
  margin-right: 0.5rem;
  text-transform: capitalize;
  ${TextEllipsis};
`;

export const Type = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.type};
  ${TextEllipsis};
`;

export const Icons = styled.div`
  align-items: center;
  display: flex;
  text-align: right;
  padding-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.icons};
`;

export const TrashIcon = styled(DefaultTrashIcon).attrs(() => ({
  size: 16,
}))`
  color: ${({ theme }) => theme.colors.danger};
  cursor: pointer;
`;

export const EditIcon = styled(DefaultEditIcon).attrs(() => ({
  size: 16,
}))`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  margin-right: 0.5rem;
`;
