import parseISO from 'date-fns/parseISO';
import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import { ICONS } from 'components/Icon/constants';
import type {
  GetPartnerTransactions_partnerTransactions_transactions,
  GetPartnerTransactions_partnerTransactions_transactions_credits,
  GetPartnerTransactions_partnerTransactions_transactions_purchases,
  GetPartnerTransactions_partnerTransactions_transactions_quotas,
} from 'apollo/generated/GetPartnerTransactions';
import type {
  GetEmployeeTransactions_employeeTransactions_transactions,
  GetEmployeeTransactions_employeeTransactions_transactions_credits,
  GetEmployeeTransactions_employeeTransactions_transactions_expenses,
} from 'apollo/generated/GetEmployeeTransactions';
import { normalizeOrder } from './Order';
import { OrganizationTransactionsDataFragment } from '../apollo/graphql.types';

export enum TransactionType {
  PURCHASE = 'purchase',
  CREDITS = 'credits',
  QUOTA = 'quota',
  EXPENSE = 'expense',
}

const normalizePurchaseTransactions = (
  input: GetPartnerTransactions_partnerTransactions_transactions_purchases,
) => {
  const orders = removeOptionalsFromArray(input.orders ?? []);
  return {
    id: input.id || '',

    canceled: input.canceled || false,
    canceledAt: input.canceledAt
      ? parseISO(input.canceledAt).getTime()
      : undefined,
    canceledBy: input.canceledBy
      ? {
          id: input.canceledBy.id || '',
          fullName: `${input.canceledBy.user.firstName} ${input.canceledBy.user.lastName}`,
        }
      : undefined,
    concept: 'Ejecutó una compra',
    credits: input.total || 0,
    date: parseISO(input.createdAt).getTime(),
    discount: input.discount,
    employee: {
      id: input.employee.id || '',
      fullName: `${input.employee.user.firstName} ${input.employee.user.lastName}`,
    },
    partner: {
      id: input.partner.id || '',
      // @ts-ignore
      fullName: `${input.partner?.user?.firstName} ${input.partner?.user?.lastName}`,
    },
    icon: {
      name: 'shoppingCart' as keyof typeof ICONS,
      color: '#0074CA',
    },
    // @ts-ignore
    items: orders.map(normalizeOrder),
    state: undefined,
    sumCredits: false,
    toDate: undefined,
    type: TransactionType.PURCHASE,
    // @ts-ignore
    discount: !input?.mainDiscount
      ? undefined
      : {
          // @ts-ignore
          name: input.mainDiscount?.name || '',
          // @ts-ignore
          price: input.mainDiscount?.price
            ? // @ts-ignore
              Number(input.mainDiscount.price)
            : 0,
          // @ts-ignore
          percentage: input.mainDiscount?.percentage
            ? // @ts-ignore
              Number(input.mainDiscount.percentage)
            : 0,
        },
  };
};

export const normalizeCreditTransactions = (
  input:
    | GetPartnerTransactions_partnerTransactions_transactions_credits
    | GetEmployeeTransactions_employeeTransactions_transactions_credits,
) => {
  // @ts-ignore TODO until fix types
  const isAddedCreditsAction = input.action === 'ADDED';

  return {
    id: input.id || '',
    canceled: input.canceled || false,
    canceledAt: input.canceledAt
      ? parseISO(input.canceledAt).getTime()
      : undefined,
    canceledBy: input.canceledBy
      ? {
          id: input.canceledBy.id || '',
          fullName: `${input.canceledBy.user.firstName} ${input.canceledBy.user.lastName}`,
        }
      : undefined,
    concept: `A ${isAddedCreditsAction ? 'añadido' : 'retirado'} créditos`,
    // @ts-ignore TODO until fix types
    credits: input.credits || 0,
    date: parseISO(input.date).getTime(),
    employee:
      'addedBy' in input
        ? {
            id: input.addedBy.id || '',
            fullName: `${input.addedBy.user.firstName} ${input.addedBy.user.lastName}`,
          }
        : undefined,
    icon: {
      name: (isAddedCreditsAction ? 'cr' : 'coin') as keyof typeof ICONS,
      color: isAddedCreditsAction ? '#2CB532' : '#E53935',
    },
    items: [],
    partner:
      'partner' in input
        ? {
            id: input.partner?.id || '',
            fullName: `${input.partner?.user?.firstName} ${input.partner?.user?.lastName}`,
          }
        : undefined,
    state: undefined,
    sumCredits: isAddedCreditsAction,
    toDate: undefined,
    type: TransactionType.CREDITS,
    discount: undefined,
  };
};

const normalizeQuotaTransactions = (
  input: GetPartnerTransactions_partnerTransactions_transactions_quotas,
) => ({
  id: input.id || '',
  canceled: input.canceled || false,
  canceledAt: input.canceledAt
    ? parseISO(input.canceledAt).getTime()
    : undefined,
  canceledBy: input.canceledBy
    ? {
        id: input.canceledBy.id || '',
        fullName: `${input.canceledBy.user.firstName} ${input.canceledBy.user.lastName}`,
      }
    : undefined,
  concept: `A asigando la cuota '${input.quota.name}'`,
  credits: input.quota.price || 0,
  date: parseISO(input.createdAt).getTime(),
  employee: {
    id: input.addedBy.id || '',
    fullName: `${input.addedBy.user.firstName} ${input.addedBy.user.lastName}`,
  },
  icon: {
    name: 'quota' as keyof typeof ICONS,
    color: '#2C3E50',
  },
  partner: {
    // @ts-ignore
    id: input?.partner?.id || '',
    // @ts-ignore
    fullName: `${input.partner?.user?.firstName} ${input.partner?.user?.lastName}`,
  },
  items: [],
  state: undefined,
  sumCredits: false,
  toDate: undefined,
  type: TransactionType.QUOTA,
  discount: undefined,
});

const normalizeExpenseTransactions = (
  input: GetEmployeeTransactions_employeeTransactions_transactions_expenses,
) => ({
  id: input.id || '',
  canceled: input.canceled || false,
  canceledAt: input.canceledAt
    ? parseISO(input.canceledAt).getTime()
    : undefined,
  canceledBy: input.canceledBy
    ? {
        id: input.canceledBy.id || '',
        fullName: `${input.canceledBy.user.firstName} ${input.canceledBy.user.lastName}`,
      }
    : undefined,
  concept: `A creado un gasto '${input.name}'`,
  credits: input.price || 0,
  date: parseISO(input.createdAt).getTime(),
  employee: input.addedBy
    ? {
        id: input.addedBy.id || '',
        fullName: `${input.addedBy.user.firstName} ${input.addedBy.user.lastName}`,
      }
    : undefined,
  icon: {
    name: 'expenses' as keyof typeof ICONS,
    color: '#2C3E50',
  },
  items: [],
  state: undefined,
  sumCredits: false,
  toDate: undefined,
  type: TransactionType.EXPENSE,
});

export const normalizePartnerTransaction = (
  input: GetPartnerTransactions_partnerTransactions_transactions,
) => {
  const credits = removeOptionalsFromArray(input.credits ?? []).map(
    normalizeCreditTransactions,
  );
  const purchase = removeOptionalsFromArray(input.purchases ?? []).map(
    normalizePurchaseTransactions,
  );

  const quotas = removeOptionalsFromArray(input.quotas ?? []).map(
    normalizeQuotaTransactions,
  );

  return [...credits, ...purchase, ...quotas].sort((a, b) => b.date - a.date);
};

export const normalizeEmployeeTransaction = (
  input: GetEmployeeTransactions_employeeTransactions_transactions,
) => {
  const credits = removeOptionalsFromArray(input.credits ?? []).map(
    normalizeCreditTransactions,
  );
  const expenses = removeOptionalsFromArray(input.expenses ?? []).map(
    normalizeExpenseTransactions,
  );
  const purchase = removeOptionalsFromArray(input.purchases ?? []).map(
    normalizePurchaseTransactions,
  );

  const quotas = removeOptionalsFromArray(input.quotas ?? []).map(
    normalizeQuotaTransactions,
  );

  return [...credits, ...expenses, ...purchase, ...quotas].sort(
    (a, b) => b.date - a.date,
  );
};

export const normalizeOrganizationTransactions = (
  input: OrganizationTransactionsDataFragment,
) => {
  const credits = removeOptionalsFromArray(input.credits ?? []).map(
    // @ts-ignore
    normalizeCreditTransactions,
  );
  const purchase = removeOptionalsFromArray(input.purchases ?? []).map(
    // @ts-ignore
    normalizePurchaseTransactions,
  );
  const quotas = removeOptionalsFromArray(input.quotas ?? []).map(
    // @ts-ignore
    normalizeQuotaTransactions,
  );

  return [...credits, ...purchase, ...quotas].sort((a, b) => b.date - a.date);
};
