import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.blue900}33;
  border-radius: 0.3125rem;
  height: 0.5rem;
  width: 100%;
`;

export const Track = styled.div<{ $percent: number }>`
  background-color: ${({ theme }) => theme.colors.blue700};
  height: 100%;
  border-radius: inherit;
  width: ${({ $percent }) => $percent}%;
`;
