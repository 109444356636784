import styled from 'styled-components';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';

export const Container = styled(SectionContainer)`
  ${from.tabletLandscape`
    padding-left: 0;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 1.5rem;

  ${from.tabletLandscape`
    margin-top: 0;
  `};
`;

export const Section = styled.div`
  flex-grow: 1;
  margin-top: 2.5rem;
`;
