import styled from 'styled-components';
import { SectionContainer } from 'components/Layout';
import DefaultEmptyState from 'components/EmptyState';
import { from } from '../../../../styles/responsive';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  position: relative;
  padding: 0 0 3.6rem 0;
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const DateTimeFilterContainer = styled.div``;

export const Squares = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  margin-top: 3rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
`;

export const Square = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  border: solid 1px ${({ theme }) => theme.colors.grey200};
  border-radius: 0.25rem;
  padding: 0.7rem 1rem;
  width: 7rem;
  min-height: 6rem;

  ${from.tabletPortrait`
    min-height: unset;
    width: 12rem;
  `};
`;

export const SquareTitle = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  ${from.tabletPortrait`
    font-size: 16px;
  `};
`;

export const SquareValue = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  margin-top: 1rem;

  ${from.tabletPortrait`
    font-size: 18px;
  `};
`;
