import {
  object as yupObject,
  number as yupNumber,
  array as yupArray,
} from 'yup';

export const validationSchema = yupObject({
  typesOfCash: yupArray().of(
    yupObject().shape({
      key: yupNumber(),
      value: yupNumber(),
    }),
  ),
});
