import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive';
import useExpenses from 'apollo/hooks/expense/useExpenses';
import useModal from 'apollo/hooks/useModal';
import useExpenseActions from 'apollo/hooks/expense/useExpenseActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import useExpensesTags from 'apollo/hooks/expense/useExpensesTags';
import type { Expense } from 'model/Expense';

const useConnect = () => {
  const { expenses, loading, refetch } = useExpenses();
  const { expensesTags, getExpensesTagsLoading } = useExpensesTags();
  const { deleteExpense } = useExpenseActions();
  const { enqueueSnackbar } = useSnackbar();
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const {
    openNewExpense,
    openDialog,
    openExpenseDetails,
    close: closeModal,
  } = useModal();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    setIsRefetching(true);
    try {
      refetch({
        filters: { keyword: filter, tags: selectedTags },
      });
      setIsRefetching(false);
    } catch (error) {
      setIsRefetching(false);
      enqueueSnackbar('No se puede realizar la búsqueda', { variant: 'error' });
    }
  }, [enqueueSnackbar, filter, refetch, selectedTags]);

  const handleSearch = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;
    setFilter(newSearch);
  }, []);

  const handleOpenNewExpense = useCallback(() => {
    openNewExpense();
  }, [openNewExpense]);

  const handleRemoveExpense = useCallback(
    async (expenseId: string) => {
      try {
        await deleteExpense(expenseId);
        await refetch();
        closeModal();
        enqueueSnackbar('El gasto se ha eliminado correctamente.', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('expense', e.message, 'eliminar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [closeModal, deleteExpense, enqueueSnackbar, refetch],
  );

  const handleRemoveExpenseDialog = useCallback(
    (expenseId: string) => {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar un gasto y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: () => handleRemoveExpense(expenseId),
        title: 'Eliminar gasto',
        variant: 'danger',
      });
    },
    [openDialog, handleRemoveExpense],
  );

  const isSelectedTag = useCallback(
    (name: string) => !!selectedTags.find((tag) => tag === name),
    [selectedTags],
  );

  const handleToggleTag = useCallback(
    async (name: string) => {
      const exists = isSelectedTag(name);
      if (exists) {
        setSelectedTags((prev) => prev.filter((tag) => tag !== name));
      } else {
        setSelectedTags((prev) => [...prev, name]);
      }
    },
    [isSelectedTag],
  );

  const handleOpenExpenseDetails = useCallback(
    async (expense: Expense) => {
      openExpenseDetails({ expense });
    },
    [openExpenseDetails],
  );

  return {
    handleOpenExpenseDetails,
    employeePermissions,
    expenses,
    filter,
    handleOpenNewExpense,
    handleRemoveExpenseDialog,
    handleSearch,
    isLoading: loading || getExpensesTagsLoading,
    isRefetching,
    expensesTags,
    isSelectedTag,
    handleToggleTag,
  };
};

export default useConnect;
