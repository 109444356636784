import type { BaseThemedCssFunction, DefaultTheme } from 'styled-components';
import { css } from 'styled-components';

import { keysOf } from 'utils/keysOf';

const size = {
  tabletPortrait: '600px',
  tabletLandscape: '900px',
  laptop: '1200px',
  desktop: '1800px',
} as const;

const minWidthQuery = (width: string) => `@media (min-width: ${width})`;

const minWidth =
  (width: string): BaseThemedCssFunction<DefaultTheme> =>
  (first: any, ...args: any) =>
    css`
      ${minWidthQuery(width)} {
        ${css(first, ...args)};
      }
    `;

interface Breakpoint extends ReturnType<typeof minWidth> {
  query: string;
}

// eslint-disable-next-line import/prefer-default-export
export const from = keysOf(size).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (() => {
      const mixin = minWidth(size[key]) as Breakpoint;
      mixin.query = minWidthQuery(size[key]);
      return mixin;
    })(),
  }),
  {} as { [key in keyof typeof size]: Breakpoint },
);
