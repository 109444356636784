import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { formatDate } from 'utils/dates/formatDates';

const useConnect = () => {
  const { close, type, expenseDetailsPayload } = useModal();
  const { expense } = expenseDetailsPayload || {};

  const initialValues = useMemo(
    () => ({
      addedBy: expense?.addedBy?.fullName || '',
      description: expense?.description || '',
      name: expense?.name || '',
      provider: expense?.provider?.name || '',
      price: expense?.originalPrice || 0.0,
      quantity: expense?.quantity || 0,
      createdAt: (expense?.createdAt && formatDate(expense.createdAt)) || '',
      categories: expense?.categories.map((cat) => cat.name).join(', ') || '',
    }),
    [expense],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<FormikConfig<any>['onSubmit']>(
    async (data) => {
      console.log(data);
    },
    [],
  );

  return {
    handleClose,
    handleSubmit,
    initialValues,
    isOpen: type === ModalType.EXPENSE_DETAILS,
  };
};

export default useConnect;
