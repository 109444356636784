import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.primary}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NoteEmployee = styled.div`
  margin-right: 0.5rem;
  flex: 0 0 33%;

  ${from.tabletPortrait`
   flex: 0 0 ${TABLE_METRICS.employee};
 `}
`;

export const NoteData = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-flow: column;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  white-space: initial;
`;

export const LastQuantity = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.lastQuantity};
  ${TextEllipsis};
`;

export const NewQuantity = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.newQuantity};
  ${TextEllipsis};
`;

export const MovementType = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.lastBaseWeight};
  ${TextEllipsis};
  white-space: pre-wrap;

  ${from.tabletLandscape`
   font-size: 1rem;
 `}
`;

export const Note = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.newBaseWeight};
  ${TextEllipsis};
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  flex: 0 0 33%;
  ${TextEllipsis};

  &:first-letter {
    text-transform: uppercase;
  }

  ${from.tabletPortrait`
   flex: 0 0 ${TABLE_METRICS.date};
 `}
`;

export const Credits = styled.div<{ $isNegative: boolean }>`
  color: ${({ theme, $isNegative }) =>
    $isNegative ? theme.colors.danger : theme.colors.green};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  flex: 0 0 33%;
  ${TextEllipsis};

  ${from.tabletPortrait`
   margin-right: 1rem;
   flex: 0 0 ${TABLE_METRICS.credits};
 `}
`;

export const Employee = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 0.4rem;
  text-transform: capitalize;
  ${TextEllipsis};
`;
