import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { organizationConfigVar } from 'apollo/reactive';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import RevertSnackbarButton from 'components/RevertSnackbarButton';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import OpenCashierSnackbarButton from 'components/OpenCashierSnackbarButton';
import { formatErrors, isClosedCashierError } from 'utils/errors/formatErrors';

const useConnect = () => {
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const { close, manageCreditsPayload, type } = useModal();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    updatePartnerCredits,
    cancelCreditsTransaction,
    loading: partnerActionLoading,
  } = usePartnerActions();
  const navigate = useNavigate();
  const {
    partner,
    onReturn,
    action = 'added',
    allowCloseModal = true,
  } = manageCreditsPayload || {};

  const creditConcepts = useMemo(
    () =>
      (organizationConfig.creditConcepts || []).map((concept) => ({
        value: concept,
        label: concept,
      })),
    [organizationConfig],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleGoToOrganization = useCallback(() => {
    navigate('/organization/credits-concepts');
    close();
  }, [close, navigate]);

  const handleRevertTransaction = useCallback(
    async (transactionId: string) => {
      try {
        await cancelCreditsTransaction(transactionId);

        closeSnackbar();
        enqueueSnackbar('Se ha cancelado la transacción', {
          variant: 'info',
        });

        if (onReturn) {
          onReturn();
        }
      } catch (error) {
        enqueueSnackbar(
          formatErrors('transaction', error.message, 'cancelar'),
          {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={error} />,
          },
        );
      }
    },
    [onReturn, cancelCreditsTransaction, closeSnackbar, enqueueSnackbar],
  );

  const handleSubmit = useCallback(
    async ({ credits, concept }: { credits: number; concept?: string }) => {
      try {
        if (partner) {
          const id = await updatePartnerCredits({
            id: partner.id,
            credits,
            concept,
            action,
          });

          if (!id) {
            throw new Error();
          }

          const isAddedAction = action === 'added';

          enqueueSnackbar(
            `Los créditos se han ${
              isAddedAction ? 'añadido' : 'retirado'
            } correctamente al socio ${partner.memberNum}`,
            {
              variant: 'success',
              action: () => (
                <RevertSnackbarButton
                  onClickBar={() => handleRevertTransaction(id)}
                />
              ),
            },
          );

          if (onReturn) {
            onReturn();
          }

          if (allowCloseModal) {
            handleClose();
          }
        }
      } catch (e) {
        if (isClosedCashierError(e.message)) {
          enqueueSnackbar('Debes abrir la caja', {
            variant: 'error',
            action: () => <OpenCashierSnackbarButton />,
          });
        } else {
          enqueueSnackbar(formatErrors('partner', e.message, 'actualizar'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      }
    },
    [
      allowCloseModal,
      onReturn,
      action,
      enqueueSnackbar,
      handleClose,
      handleRevertTransaction,
      partner,
      updatePartnerCredits,
    ],
  );

  return {
    action,
    concepts: creditConcepts,
    currentCredits: partner?.credits || 0,
    handleClose,
    handleSubmit,
    handleGoToOrganization,
    isOpen: type === ModalType.MANAGE_CREDITS,
    isLoading: partnerActionLoading,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
