import { useCallback } from 'react';
import format from 'date-fns/format';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import type { MenuScheduleDto } from 'apollo/graphql.types';

const useConnect = () => {
  const { close, addMenuSchedulePayload, type } = useModal();
  const { onSave } = addMenuSchedulePayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback(
    async (value: MenuScheduleDto) => {
      if (onSave) {
        onSave({
          daysOfWeek: value.daysOfWeek,
          schedules: value.schedules
            .filter((schedule) => schedule.startTime && schedule.endTime)
            .map((schedule) => ({
              startTime: format(new Date(schedule.startTime), 'HH:mm:ss'),
              endTime: format(new Date(schedule.endTime), 'HH:mm:ss'),
            })),
        });
        handleClose();
      }
    },
    [onSave, handleClose],
  );

  return {
    handleClose,
    handleSubmit,
    isOpen: type === ModalType.ADD_MENU_SCHEDULE,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
