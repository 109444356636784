import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultModal, { Content } from 'components/Modal';

export const Container = styled(DefaultModal)`
  ${Content} {
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;
