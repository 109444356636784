import type { FC } from 'react';
import { ListRowProps, WindowScroller } from 'react-virtualized';
import { memo, useCallback } from 'react';
import Row from './Row';
import TableHead from './TableHead';
import {
  Container,
  EmptyState,
  Main,
  List,
  ListContent,
  Content,
} from './styles';
import type { Props } from './types';

const PartnerRegisters: FC<Props> = ({ registers }) => {
  const hasResults = registers?.length > 0;
  const ROW_HEIGHT_DESKTOP_PX = 60;

  const rowRenderer: FC<ListRowProps> = useCallback(
    ({ index, style }) => {
      const register = registers[index];

      return <Row key={register.id} date={register.date} style={style} />;
    },
    [registers],
  );

  return (
    <Container>
      <Main>
        {!hasResults && (
          <EmptyState title="No hay registros" iconName="timer">
            No se ha creado ningún registro
          </EmptyState>
        )}

        {hasResults && (
          <Content>
            <ListContent>
              <TableHead />
              <WindowScroller>
                {({ onChildScroll, ...scrollInfo }) => (
                  <List
                    autoHeight
                    autoWidth
                    onScroll={onChildScroll}
                    rowCount={registers.length}
                    rowHeight={ROW_HEIGHT_DESKTOP_PX}
                    rowRenderer={rowRenderer}
                    {...scrollInfo}
                  />
                )}
              </WindowScroller>
            </ListContent>
          </Content>
        )}
      </Main>
    </Container>
  );
};

export default memo(PartnerRegisters);
