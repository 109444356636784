import {
  object as yupObject,
  string as yupString,
  boolean as yupBoolean,
} from 'yup';

export const validationSchema = yupObject({
  title: yupString().required('Este campo es requerido.'),
  startDate: yupString().required('Este campo es requerido.'),
  notify: yupBoolean(),
  emails: yupString().when('notify', {
    is: true,
    then: yupString().required('Para notificar debes añadir los emails'),
    otherwise: yupString(),
  }),
});
