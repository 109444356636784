import { memo, FC } from 'react';
import { Container, Content, Logo, Progress } from './styles';

const FullscreenLoader: FC = () => (
  <Container>
    <Content>
      <Logo />
      <Progress />
    </Content>
  </Container>
);

export default memo(FullscreenLoader);
