import type { FC } from 'react';
import { applyDiscount } from 'utils/discounts';
import Price from 'components/Price';
import useConnect from './connect';
import {
  Container,
  Header,
  IconCircle,
  OrdersIcon,
  ProductName,
  ProductRow,
  Products,
  ProductsContainer,
  Title,
  TotalPrice,
  TotalProducts,
  ProductQuantity,
  ProductTotalPrice,
  CloseButton,
  HeaderContainer,
  ButtonContainer,
  OriginalPrice,
  ProductTitle,
  ProductDiscount,
  ProductNameContent,
  ProductDiscountName,
  ProductReferenceCode,
  OriginalProductPrice,
} from './styles';

const PurchaseDetails: FC = () => {
  const {
    activateProductReference,
    isOpen,
    handleClose,
    orders,
    totalPrice,
    discount,
  } = useConnect();
  const originalPrice = Number(totalPrice);
  const finalPrice = !discount
    ? originalPrice
    : applyDiscount({
        discount,
        price: Number(totalPrice),
      });

  return (
    <Container open={isOpen} onClose={handleClose}>
      <HeaderContainer>
        <Header>
          <IconCircle>
            <OrdersIcon />
          </IconCircle>
          <Title>Artículos del Pedido</Title>
        </Header>
      </HeaderContainer>

      <ProductsContainer>
        <ProductRow>
          <ProductTitle>
            <TotalProducts>{orders?.length} productos</TotalProducts>
            {discount?.name && (
              <ProductDiscount>
                Descuento aplicado: '{discount.name}'
              </ProductDiscount>
            )}
          </ProductTitle>
          <TotalPrice>
            {discount && (
              <OriginalPrice>
                <Price>{originalPrice}</Price>
              </OriginalPrice>
            )}{' '}
            <Price>{finalPrice}</Price>
          </TotalPrice>
        </ProductRow>
        <Products>
          {orders?.map((order) => {
            const hasDiscount = !!order.productDiscountName;
            const productPrice = Number(
              (
                order.quantity * (order.productWithoutDiscountPrice || 0)
              ).toFixed(2),
            );
            const finalProductPrice = !hasDiscount
              ? productPrice
              : Number((order.quantity * (order.productPrice || 0)).toFixed(2));

            return (
              <ProductRow key={order.id}>
                <ProductQuantity>{order.totalQuantity}</ProductQuantity>
                <ProductNameContent>
                  <ProductName>
                    {order.productName}
                    {activateProductReference && order.productReferenceCode && (
                      <ProductReferenceCode>
                        #{order.productReferenceCode}
                      </ProductReferenceCode>
                    )}
                  </ProductName>
                  {order.productDiscountName && (
                    <ProductDiscountName>
                      Descuento aplicado: '{order.productDiscountName}'
                    </ProductDiscountName>
                  )}
                </ProductNameContent>

                <ProductTotalPrice>
                  {hasDiscount && (
                    <OriginalProductPrice>
                      <Price>{productPrice}</Price>
                    </OriginalProductPrice>
                  )}{' '}
                  <Price>{finalProductPrice}</Price>
                </ProductTotalPrice>
              </ProductRow>
            );
          })}
        </Products>
      </ProductsContainer>
      <ButtonContainer>
        <CloseButton onClick={handleClose}>Cerrar</CloseButton>
      </ButtonContainer>
    </Container>
  );
};

export default PurchaseDetails;
