import styled from 'styled-components';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import { ResponsiveContainer as DefaultResponsiveContainer } from 'recharts';
import { from } from 'styles/responsive';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  cursor: pointer;
  max-width: 24.5rem;
  width: 100%;
  height: 15rem;

  ${from.tabletLandscape`
     max-width: 30rem;
  `};
`;

export const ResponsiveContainer = styled(DefaultResponsiveContainer)`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey100};
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.6rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin-top: 0.3rem;
`;

export const ChevronIcon = styled(DefaultArrowRightIcon)`
  width: 1rem;

  ${from.tabletLandscape`
    opacity: 0.7; 
    width: 0.5rem;
  `};
`;

export const Header = styled(DefaultLink)`
  display: flex;
  width: 100%;
  margin-bottom: 3.5rem;
  justify-content: space-between;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
    ${Subtitle} {
      color: ${({ theme }) => theme.colors.accent};
    }
    ${ChevronIcon} {
      opacity: 1;
    }
  }
`;

export const TitleContent = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.light};
`;

export const ExtraTitle = styled.span`
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const ExtraSubtitle = styled(Subtitle)``;
