import { FC, memo } from 'react';
import { Formik } from 'formik';
import { discountTypeOptions } from 'model/Discount';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  Row,
  TextField,
  Select,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const DiscountForm: FC<Props> = ({
  className,
  onSubmit,
  onCancel,
  initialValues,
  isLoading,
  id = 'new',
}) => {
  const formId = `discount_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <TextField name="name" label="Nombre" id={`${formId}_name`} />
          <Select
            readonly={!!initialValues.id}
            name="type"
            label="Tipo de descuento"
            id={`${formId}_type`}
            options={discountTypeOptions}
          />
          <Row>
            <TextField
              name="percentage"
              label="Porcentaje"
              id={`${formId}_percentage`}
              type="number"
            />
            <TextField name="price" label="Precio" id={`${formId}_price`} />
          </Row>
          <Buttons>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={!isFormValid}
            >
              {initialValues.id ? 'Editar' : 'Crear'}
            </CreateButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(DiscountForm);
