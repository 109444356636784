import { useRef, useCallback } from 'react';
import { useField } from 'formik';
import { LogicProps } from './types';

const useConnect = ({ name, ref, onLocalChange }: LogicProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  const localRef = useRef<HTMLInputElement>(null);
  const inputRef = ref || localRef;

  const handleChange = useCallback(
    (value: Date) => {
      setValue(value);

      if (onLocalChange) {
        onLocalChange(value);
      }
    },
    [setValue, onLocalChange],
  );

  return {
    error,
    field,
    handleChange,
    inputRef,
    touched,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
