import { FC, memo } from 'react';
import Loader from 'components/Loader';
import {
  SectionTitle,
  Header,
  BackLink,
  HeaderContent,
  CreateButton,
} from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const Documents: FC = () => {
  const { documents, handleNavigateToCreationView, isLoading } = useConnect();

  const hasDocuments = documents.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header>
        <HeaderContent>
          <BackLink to="/organization" />
          <SectionTitle>Documentos</SectionTitle>
        </HeaderContent>
        <CreateButton onClick={handleNavigateToCreationView}>
          Crear
        </CreateButton>
      </Header>
      <Main>
        {!hasDocuments && (
          <EmptyState
            title="No hay documentos"
            iconName="organization"
            buttonLabel="Crear documento"
            onClickButton={handleNavigateToCreationView}
          >
            Crea tu primer documento
          </EmptyState>
        )}

        {hasDocuments && (
          <>
            <TableHead />
            {documents.map((document) => (
              <Row key={document.id} document={document} />
            ))}
          </>
        )}
      </Main>
    </Container>
  );
};

export default memo(Documents);
