import { useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import notEmpty from 'utils/notEmpty';
import { permissionsStateVar, subscriptionStateVar } from 'apollo/reactive';
import { GET_DISCOUNTS } from 'apollo/queries/discounts';
import type {
  GetDiscountsQuery,
  GetDiscountsQueryVariables,
} from 'apollo/graphql.types';
import { normalizeDiscount } from 'model/Discount';

const useDiscounts = (filter?: string) => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const subscription = useReactiveVar(subscriptionStateVar);

  const { loading, data, refetch } = useQuery<
    GetDiscountsQuery,
    GetDiscountsQueryVariables
  >(GET_DISCOUNTS, {
    skip:
      !subscription.allowedDiscounts || !employeePermissions.allowShowDiscounts,
    variables: { filter },
  });

  const discounts = useMemo(
    () =>
      data?.discounts
        ? data.discounts.filter(notEmpty).map(normalizeDiscount)
        : [],
    [data],
  );

  return {
    discounts,
    loading,
    refetch,
  };
};

export default useDiscounts;
