import styled, { css } from 'styled-components';

export const Container = styled.img<{ $abilityNavigation?: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  width: 151px;

  ${({ $abilityNavigation }) =>
    $abilityNavigation &&
    css`
      cursor: pointer;
    `}
`;
