import styled, { css } from 'styled-components';
import { SectionContainer } from 'components/Layout';
import DefaultSwitch from 'components/Inputs/Switch';
import { from } from 'styles/responsive';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultSelect from 'components/Inputs/Select';
import DefaultIcon from 'components/Icon';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  position: relative;
  padding-bottom: 3.6rem;
  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const InfoContainer = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.grey300};
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  padding: 1rem;
`;

export const InfoContainerDescription = styled.p`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.4;
`;

export const InfoTables = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const InfoContent = styled.div`
  width: 100%;
`;

export const Form = styled.div`
  margin-bottom: 3rem;
  ${from.tabletLandscape`
    margin-bottom: 0;
  `}
`;

export const TextField = styled(DefaultTextField)<{ $long?: boolean }>`
  ${({ $long }) =>
    $long &&
    css`
      width: 70%;
    `}
`;

export const Switch = styled(DefaultSwitch)`
  margin-top: 1rem;
  margin-bottom: 2rem;
  max-width: 20%;
`;

export const InfoContentTitle = styled.h5``;

export const Select = styled(DefaultSelect)`
  &&& {
    width: 20%;
  }
`;

export const RemoveIcon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.danger};
  cursor: pointer;
  margin-left: 1rem;
`;

export const AddIcon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.accent};
  border: 1px solid ${({ theme }) => theme.colors.grey400};
  border-radius: 0.25rem;
  cursor: pointer;
  margin-left: 0.3rem;
  padding: 0.3rem;
`;

export const Inputs = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const CustomDataContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey400};
  border-radius: 0.25rem;
  margin: 1rem 0;
  padding: 1rem;
  max-height: 25rem;
  overflow: scroll;
  position: relative;
`;

export const CustomDataTitle = styled.h5`
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 1rem;
`;

export const CustomDataRow = styled.div`
  align-items: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
`;
