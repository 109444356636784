import { FC, memo } from 'react';
import DriveLogo from 'assets/images/drive-logo.svg';
import { Button, Container, Logo } from './styles';
import type { Props } from './types';

const IntegrationsConfig: FC<Props> = ({
  hasIntegration,
  onRemoveIntegration,
  onConnect,
  actionLoading,
}) => {
  return (
    <Container>
      <Logo alt="googlge drive logo" src={DriveLogo} />
      <Button
        variant={hasIntegration ? 'primary' : 'light'}
        onClick={hasIntegration ? onRemoveIntegration : onConnect}
        isLoading={actionLoading}
      >
        {hasIntegration ? 'Desconectar' : 'Conectar'}
      </Button>
    </Container>
  );
};

export default memo(IntegrationsConfig);
