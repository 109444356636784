import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormikConfig, FormikProps } from 'formik';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import { PartnerUsage, UserGender } from 'apollo/generated/globalTypes';
import validateRemovedPartner from 'apollo/requests/validateRemovedPartner';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { Partner, PartnerFormFields } from 'model/Partner';

const useConnect = () => {
  const {
    close,
    type,
    newPartnerPayload,
    openReactivatePartner,
    openPartners,
    openNewPartner,
  } = useModal();
  const { createPartner, loading: creationPartnerLoading } =
    usePartnerActions();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { onReturn, initData } = newPartnerPayload || {};
  const formikRef = useRef<FormikProps<PartnerFormFields>>(null);
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);

  const initialValues = useMemo<PartnerFormFields>(
    () => ({
      address: initData?.address || '',
      document: initData?.document || '',
      email: initData?.email || '',
      firstName: initData?.firstName || '',
      lastName: initData?.lastName || '',
      phoneNumber: initData?.phoneNumber || '',
      usage: PartnerUsage.PLAYFUL,
      maxConsumeMonth: (initData?.maxConsumeMonth || '') as unknown as number,
      memberNum: initData?.memberNum || '',
      birthDate: initData?.birthDate || undefined,
      gender: initData?.gender || UserGender.OTHER,
      credits: 0,
      rfidCode: initData?.rfidCode || '',
    }),
    [initData],
  );

  const handleClose = useCallback(() => {
    setSelectedPartner(null);
    if (onReturn) {
      onReturn();
    } else {
      close();
    }
  }, [close, onReturn]);

  const handleSubmit = useCallback<FormikConfig<PartnerFormFields>['onSubmit']>(
    async (data: PartnerFormFields) => {
      try {
        const existsRemovedPartner = await validateRemovedPartner(
          data.document,
        );
        if (existsRemovedPartner) {
          openReactivatePartner({ data });
          return;
        }
        if (selectedPartner) {
          const partner = await createPartner({
            ...data,
            hostMemberNum: selectedPartner.memberNum,
          });
          enqueueSnackbar(
            `El socio ${data.firstName} ${data.lastName} ha sido creado correctamente`,
            { variant: 'success' },
          );

          setSelectedPartner(null);
          if (onReturn) {
            onReturn(partner);
          } else {
            if (partner) {
              navigate(`partners/${partner.id}`, { replace: true });
            }
            close();
          }
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('partner', e.message, 'crear'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [
      selectedPartner,
      openReactivatePartner,
      createPartner,
      enqueueSnackbar,
      onReturn,
      close,
      navigate,
    ],
  );

  const onSelectPartner = useCallback((hostPartner: Partner) => {
    if (hostPartner?.id) {
      setSelectedPartner(hostPartner);
    }
  }, []);

  const handleSelectHostPartner = useCallback(() => {
    const lastValues = formikRef.current?.values;
    openPartners({
      onSelect: onSelectPartner,
      onReturn: () => openNewPartner({ initData: lastValues }),
    });
  }, [formikRef, openPartners, onSelectPartner, openNewPartner]);

  return {
    creationPartnerLoading,
    handleClose,
    handleSubmit,
    handleSelectHostPartner,
    initialValues,
    isOpen: type === ModalType.NEW_PARTNER,
    formikRef,
    selectedPartner,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
