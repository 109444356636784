import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';

const useConnect = () => {
  const { activateRemovedPartner, loading } = usePartnerActions();
  const { close, type, reactivatePartnerPayload } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { data } = reactivatePartnerPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleActivate = useCallback(async () => {
    if (data?.document) {
      try {
        const partner = await activateRemovedPartner(data.document);
        if (!partner?.id) {
          throw new Error();
        }
        handleClose();
        navigate(`/partners/${partner.id}`);
        enqueueSnackbar(`El socio se ha reactivado correctamente.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(formatErrors('partner', error.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={error} />,
        });
      }
    }
  }, [data, activateRemovedPartner, navigate, enqueueSnackbar, handleClose]);

  return {
    data,
    handleClose,
    handleActivate,
    isLoading: loading,
    isOpen: type === ModalType.REACTIVATE_PARTNER,
  };
};

export default useConnect;
