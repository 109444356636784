import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultIcon from 'components/Icon';
import { MOBILE_TABLE_METRICS, TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1rem 0 1.1rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  border-top: solid 1px ${({ theme }) => theme.colors.grey200};
  margin-top: 1.5rem;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.grey700};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${TextEllipsis};

  ${from.tabletPortrait`
     font-size: 1rem;
  `};
`;

export const Image = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_METRICS.image};
  margin-left: 0.5rem;

  ${from.tabletPortrait`
     flex: 0 0 ${TABLE_METRICS.image};
  `};
`;

export const Name = styled(ColumnTitle)`
  cursor: pointer;
  flex: 0 0 ${MOBILE_TABLE_METRICS.name};
  margin-left: 0.5rem;
  ${TextEllipsis};

  ${from.tabletPortrait`
     flex: 0 0 ${TABLE_METRICS.name};
  `};
`;

export const CurrentQuantity = styled(ColumnTitle)`
  cursor: pointer;
  flex: 0 0 ${MOBILE_TABLE_METRICS.currentQuantity};
  white-space: pre-wrap;
  margin-left: 0.2rem;

  ${from.tabletPortrait`
     flex: 0 0 ${TABLE_METRICS.currentQuantity};
  `};
`;

export const BaseWeight = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_METRICS.baseWeight};
  margin-left: 0.5rem;
  white-space: pre-wrap;
`;

export const AddStock = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_METRICS.addStock};
  margin-left: 0.2rem;
`;

export const RetireStock = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_METRICS.retireStock};
  margin-left: 0.2rem;
`;

export const SetStock = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_METRICS.setStock};
  margin-left: 0.2rem;
`;

export const StoreMovement = styled(ColumnTitle)`
  flex: 0 0 ${TABLE_METRICS.storeMovement};
  margin-left: 0.2rem;
`;

export const Icon = styled(DefaultIcon)``;
