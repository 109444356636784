import { useCallback } from 'react';

import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, uploadImageDialogPayload, type } = useModal();

  const { onAcceptCamera, onAcceptComputer } = uploadImageDialogPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleAcceptCamera = useCallback(() => {
    if (!onAcceptCamera) return;
    onAcceptCamera();
  }, [onAcceptCamera]);

  const handleAcceptComputer = useCallback(() => {
    if (!onAcceptComputer) return;
    onAcceptComputer();
    handleClose();
  }, [handleClose, onAcceptComputer]);

  return {
    handleAcceptCamera,
    handleAcceptComputer,
    handleClose,
    isOpen: type === ModalType.UPLOAD_IMAGE_DIALOG,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
