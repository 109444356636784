import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultIcon from 'components/Icon';
import { MOBILE_TABLE_METRICS, TABLE_METRICS } from '../constants';

export const Container = styled.div`
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}1D;
  display: flex;
  padding: 1rem 0 1.1rem 0;
  transition: background-color 200ms ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;

const TextEllipsis = css`
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${from.tabletPortrait`
     font-size: 1rem;
  `};
`;

export const ImageContainer = styled.div`
  flex: 0 0 ${TABLE_METRICS.image};
  width: 2rem;
  height: 2rem;

  ${from.tabletPortrait`
     flex: 0 0 ${TABLE_METRICS.image};
     width: 3.3rem;
     height: 3.3rem;
  `};
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.medium};
  flex: 0 0 ${MOBILE_TABLE_METRICS.name};
  margin-left: 0.2rem;
  text-transform: capitalize;
  ${TextEllipsis};
  white-space: pre-wrap;

  ${from.tabletPortrait`
     flex: 0 0 ${TABLE_METRICS.name};
  `};
`;

export const CurrentQuantity = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${MOBILE_TABLE_METRICS.currentQuantity};
  margin-left: 0.5rem;
  ${TextEllipsis};

  ${from.tabletPortrait`
     flex: 0 0 ${TABLE_METRICS.currentQuantity};
  `};
`;

export const BaseWeight = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_METRICS.baseWeight};
  margin-left: 0.5rem;
  ${TextEllipsis};
`;

export const AddStock = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 ${TABLE_METRICS.addStock};
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: 0.5rem;
`;

export const RetireStock = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  display: flex;
  flex-flow: row nowrap;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_METRICS.retireStock};
`;

export const SetStock = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  display: flex;
  flex-flow: row nowrap;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_METRICS.setStock};
`;

export const StoreMovement = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  display: flex;
  flex-flow: row nowrap;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_METRICS.storeMovement};
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const IconContainer = styled.div``;

export const UpIcon = styled(DefaultIcon).attrs(() => ({
  size: 24,
  name: 'up',
}))`
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.green};
  cursor: pointer;
  margin-right: 0.5rem;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const DownIcon = styled(DefaultIcon).attrs(() => ({
  size: 24,
  name: 'down',
}))`
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.danger};
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const SetIcon = styled(DefaultIcon).attrs(() => ({
  size: 24,
  name: 'revert',
}))`
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StoreIcon = styled(DefaultIcon).attrs(() => ({
  size: 24,
  name: 'store',
}))`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue100};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ReferencedCode = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-left: 0.3rem;
`;
