import { FC, memo } from 'react';
import { Formik } from 'formik';
import { Container, Row, TextField, SubmitButton, Select } from './styles';
import { validationSchema } from './constants';
import type { Props } from './types';

const ProfileForm: FC<Props> = ({
  className,
  onSubmit,
  initialValues,
  permissionsGroupsOptions,
  isAdmin,
  id = 'new',
}) => {
  const formId = `employee_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      <Container className={className}>
        <Row>
          <TextField
            readOnly={isAdmin}
            name="firstName"
            label="Nombre *"
            id={`${formId}_firstName`}
          />
          <TextField
            readOnly={isAdmin}
            name="lastName"
            label="Apellidos *"
            id={`${formId}_lastName`}
          />
        </Row>
        <Row>
          <TextField
            readOnly={isAdmin}
            id={`${formId}_email`}
            label="Email *"
            name="email"
            type="email"
          />
          <TextField
            readOnly={isAdmin}
            name="phoneNumber"
            label="Nº de móvil"
            id={`${formId}_phoneNumber`}
          />
        </Row>
        {!isAdmin && (
          <Select
            name="permissionsGroupId"
            label="Permisos"
            id={`${formId}_permissionsGroupId`}
            options={permissionsGroupsOptions}
          />
        )}

        <SubmitButton disabled={isAdmin} type="submit">
          Guardar
        </SubmitButton>
      </Container>
    </Formik>
  );
};

export default memo(ProfileForm);
