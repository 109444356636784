import {
  object as yupObject,
  number as yupNumber,
  string as yupString,
} from 'yup';
import type { TypeOfCash } from 'apollo/graphql.types';

const requiredMsg = 'Este campo es requerido.';

export const validationSchema = yupObject({
  realCreditsAdded: yupNumber().required(requiredMsg),
  restRealCredits: yupNumber(),
  note: yupString(),
});

export const defaultTypesOfCash: TypeOfCash[] = [
  {
    key: 0.01,
    value: 0,
  },
  {
    key: 0.02,
    value: 0,
  },
  {
    key: 0.05,
    value: 0,
  },
  {
    key: 0.1,
    value: 0,
  },
  {
    key: 0.2,
    value: 0,
  },
  {
    key: 0.5,
    value: 0,
  },
  {
    key: 1,
    value: 0,
  },
  {
    key: 2,
    value: 0,
  },
  {
    key: 5,
    value: 0,
  },
  {
    key: 10,
    value: 0,
  },
  {
    key: 20,
    value: 0,
  },
  {
    key: 50,
    value: 0,
  },
  {
    key: 100,
    value: 0,
  },
];
