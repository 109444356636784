import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import {
  subscriptionStateVar,
  permissionsStateVar,
  userRoleVar,
  organizationConfigVar,
} from 'apollo/reactive';
import useOrganizationActions from 'apollo/hooks/organization/useOrganizationActions';
import useMe from 'apollo/hooks/user/useMe';
import useModal from 'apollo/hooks/useModal';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';

const useConnect = () => {
  const { data, loading: getUserLoading } = useMe();
  const { updateOrganizationLogo, loading: updateOrganizationLoading } =
    useOrganizationActions();
  const { openCreateMenu } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const subscription = useReactiveVar(subscriptionStateVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const userRole = useReactiveVar(userRoleVar);
  const organizationConfig = useReactiveVar(organizationConfigVar);

  const initialValues = useMemo(
    () => ({
      name: data.organization?.name || '',
      logoImageUrl: data.organization?.logoImageUrl || '',
    }),
    [data],
  );

  const handleUpdateAvatar = useCallback(
    async (imageId: string) => {
      try {
        if (data.organization.id) {
          await updateOrganizationLogo({
            id: data.organization.id,
            logoId: imageId,
          });
          enqueueSnackbar('El logo se ha actualizado correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('organization', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [data, enqueueSnackbar, updateOrganizationLogo],
  );

  return {
    employeePermissions,
    handleUpdateAvatar,
    initialValues,
    isLoading: getUserLoading || updateOrganizationLoading,
    openCreateMenu,
    subscription,
    userRole,
    organizationConfig,
  };
};

export default useConnect;
