import { FC, memo } from 'react';
import { Formik } from 'formik';
import { Buttons, CancelButton, Container, Row, TextField } from './styles';
import type { Props } from './types';

const EmployeeDetailInfo: FC<Props> = ({
  className,
  onSubmit,
  onClose,
  initialValues,
  id = 'new',
}) => {
  const formId = `employee_${id}`;

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      <Container className={className}>
        <Row>
          <TextField
            name="firstName"
            label="Nombre"
            id={`${formId}_firstName`}
            readOnly
          />
          <TextField
            name="lastName"
            label="Apellidos"
            id={`${formId}_lastName`}
            readOnly
          />
        </Row>
        <Row>
          <TextField
            name="document"
            label="DNI"
            id={`${formId}_document`}
            readOnly
          />
          <TextField
            name="address"
            label="Domicilio"
            id={`${formId}_address`}
            readOnly
          />
        </Row>
        <Row>
          <TextField
            id={`${formId}_email`}
            label="email"
            name="email"
            type="email"
            readOnly
          />
          <TextField
            name="phoneNumber"
            label="Nº de móvil"
            id={`${formId}_phoneNumber`}
            readOnly
          />
        </Row>
        <Buttons>
          <CancelButton onClick={onClose}>Cerrar</CancelButton>
        </Buttons>
      </Container>
    </Formik>
  );
};

export default memo(EmployeeDetailInfo);
