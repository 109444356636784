import { FC, memo } from 'react';
import { Formik } from 'formik';
import { ImageCategory } from 'apollo/generated/globalTypes';
import {
  Container,
  SubmitButton,
  Section,
  SectionContent,
  ImageUploader,
} from './styles';
import type { Props } from './types';

const ProductImagesForm: FC<Props> = ({
  className,
  initialValues,
  onSubmit,
}) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      <Container className={className}>
        <Section>
          <SectionContent>
            <ImageUploader
              category={ImageCategory.PRODUCT}
              id="mainImageUrl"
              imageIdFieldName="mainImageId"
              imageSize={initialValues.mainImageSize}
              label="Principal"
              name="mainImageUrl"
              uploadingFlagName="mainImageIsUploading"
            />
          </SectionContent>
        </Section>
        <SubmitButton type="submit">Guardar</SubmitButton>
      </Container>
    </Formik>
  );
};

export default memo(ProductImagesForm);
