import { FC } from 'react';
import useLogic from './logic';
import {
  Container,
  InputContainer,
  MaterialSwitch,
  Label,
  TitleLabel,
} from './styles';
import { ErrorMessage } from '../styles';
import type { Props } from './types';

const Switch: FC<Props> = ({
  className,
  disabled,
  titleLabel,
  id,
  label,
  name,
}) => {
  const { value, handleChange, error } = useLogic({ name });

  return (
    <Container className={className}>
      {titleLabel && <TitleLabel>{titleLabel}</TitleLabel>}
      <InputContainer
        $isDisabled={disabled}
        $hasError={!!error}
        onClick={() => handleChange('' as any, !value)}
      >
        <Label $checked={value}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!titleLabel ? label : value ? 'SI' : 'NO'}
        </Label>
        <MaterialSwitch
          id={id}
          name={name}
          checked={value}
          onChange={handleChange}
          disabled={disabled}
        />
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default Switch;
