import { useCallback } from 'react';

import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, partnerMenuPayload, type } = useModal();

  const { newOrder, loadCredits } = partnerMenuPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleOpenNewOrder = useCallback(() => {
    if (newOrder) {
      newOrder();
    }
  }, [newOrder]);

  const handleOpenLoadCredits = useCallback(() => {
    if (loadCredits) {
      loadCredits();
    }
  }, [loadCredits]);

  return {
    handleOpenLoadCredits,
    handleOpenNewOrder,
    handleClose,
    isOpen: type === ModalType.PARTNER_MENU,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
