import { FC, memo, useCallback } from 'react';
import { formatDateToTable } from 'utils/dates/formatDates';
import {
  Container,
  Price,
  Content,
  Name,
  Quantity,
  CreatedDate,
  Employee,
  Icons,
  TrashIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  expense,
  onRemove,
  employeePermissions,
  onClick,
}) => {
  const { id, name, price, quantity, createdAt, addedBy } = expense;
  const handleOnRemove = useCallback(() => onRemove(id), [id, onRemove]);

  return (
    <Container className={className}>
      <Content onClick={onClick}>
        <Name>{name}</Name>
        <Employee to={`/employees/${addedBy.id}`}>{addedBy.fullName}</Employee>
        <Price>{price}</Price>
        <Quantity>{quantity}</Quantity>
        <CreatedDate>{formatDateToTable(createdAt)}</CreatedDate>
      </Content>
      <Icons>
        {employeePermissions.allowRemoveExpenses && (
          <TrashIcon onClick={handleOnRemove} />
        )}
      </Icons>
    </Container>
  );
};

export default memo(Row);
