import { memo } from 'react';
import type { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  Balance,
  Container,
  DoneIcon,
  Identifier,
  PartnerNum,
  State,
  XIcon,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ booking, className, style }) => {
  const isTabletPortrait = !useMediaQuery(from.tabletPortrait.query);
  const { partnerNum, state, orders, total } = booking;
  const productQuantities = orders?.length || 0;

  return (
    <Container className={className} to="/orders" style={style}>
      <State>
        {state === 'CANCEL' ? (
          <XIcon name="close" />
        ) : (
          <DoneIcon size={20} name="done" />
        )}
      </State>
      <PartnerNum>#{partnerNum}</PartnerNum>
      {!isTabletPortrait && (
        <Identifier>{productQuantities} productos</Identifier>
      )}
      <Balance>{total} cr</Balance>
    </Container>
  );
};

export default memo(Row);
