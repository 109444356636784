import usePartnersRequests from 'apollo/hooks/organization/usePartnersRequests';

const useConnect = () => {
  const { partnersRequests, gePartnersRequestsLoading } = usePartnersRequests();

  return {
    isLoading: gePartnersRequestsLoading,
    partnersRequests,
  };
};

export default useConnect;
