import { useMemo } from 'react';

const useLogic = ({
  email,
  fullName,
}: {
  email?: string;
  fullName?: string;
}) => {
  const initials = useMemo(() => {
    if (!fullName && !email) return '?';

    // eslint-disable-next-line no-nested-ternary
    const letters = fullName
      ? fullName
          .split(' ')
          .slice(0, 2)
          .map((v) => v[0])
          .join('')
      : email
      ? email[0]
      : '?';

    return letters.toUpperCase();
  }, [email, fullName]);

  return { initials };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
