import { useCallback, useEffect, useState } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, dialogPayload, type } = useModal();
  const [isAcceptButtonClick, setIsAcceptButtonClick] = useState(false);

  const {
    onAccept,
    onCloseAction,
    acceptButtonText,
    cancelButtonText,
    title,
    description,
    variant,
    iconName = 'organization',
  } = dialogPayload || {};

  useEffect(() => {
    if (type === ModalType.DIALOG) {
      setIsAcceptButtonClick(false);
    }
  }, [type]);

  const handleClose = useCallback(() => {
    if (onCloseAction) {
      onCloseAction();
    } else {
      close();
    }
  }, [close, onCloseAction]);

  const handleAccept = useCallback(() => {
    if (!onAccept) {
      return;
    }

    setIsAcceptButtonClick(true);
    onAccept();
  }, [onAccept]);

  return {
    acceptButtonText,
    cancelButtonText,
    title,
    description,
    handleClose,
    handleAccept,
    variant,
    iconName,
    isAcceptButtonClick,
    isOpen: type === ModalType.DIALOG,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
