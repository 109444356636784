import { useApolloClient, useMutation } from '@apollo/client';
import { cacheResetMe } from 'apollo/cache';
import { LOGIN, LOGOUT } from 'apollo/mutations/auth';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { deleteToken, getToken, setToken } from 'services/storage';
import type { Login, LoginVariables } from 'apollo/generated/Login';
import type { Logout } from 'apollo/generated/Logout';
import { initVar, userRoleVar } from 'apollo/reactive';

const useAuthActions = () => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [loginMutation, { loading: isLoadingLogin }] = useMutation<
    Login,
    LoginVariables
  >(LOGIN);
  const [logoutMutation, { loading: isLoadingLogout }] =
    useMutation<Logout>(LOGOUT);

  const isAuthenticated = !!getToken();

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      initVar(false);
      const { data } = await loginMutation({
        variables: { dto: { email, password } },
      });

      if (data?.login?.accessToken) {
        setToken(data.login.accessToken);
        userRoleVar(data.login.role);
      }
      initVar(true);
      window.location.reload();
    },
    [loginMutation],
  );

  const logout = useCallback(async () => {
    const storedToken = getToken();
    if (storedToken) {
      try {
        initVar(false);
        await logoutMutation();
        await apolloClient.cache.reset();
        deleteToken();
        cacheResetMe();
        initVar(true);
      } catch (error) {
        initVar(true);
      }
    }
    navigate('/login', { replace: true });
  }, [apolloClient, logoutMutation, navigate]);

  return {
    login,
    logout,
    loading: isLoadingLogin || isLoadingLogout,
    isAuthenticated,
  };
};

export default useAuthActions;
export type UseAuthActions = ReturnType<typeof useAuthActions>;
