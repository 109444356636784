import { gql } from '@apollo/client';

export const FILE_FRAGMENT = gql`
  fragment FileData on File {
    id
    url
    fileName
    contentType
    uploadStatus
    fileSize
    fileDriveId
  }
`;
