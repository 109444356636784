import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_EXPENSE } from 'apollo/queries/expenses';
import type {
  GetExpense,
  GetExpenseVariables,
} from 'apollo/generated/GetExpense';
import { normalizeExpense } from 'model/Expense';

const useExpense = ({
  id,
  fetchPolicy = 'network-only',
}: {
  id?: string;
  fetchPolicy?: QueryHookOptions<GetExpenseVariables>['fetchPolicy'];
}) => {
  const { data, loading, refetch } = useQuery<GetExpense, GetExpenseVariables>(
    GET_EXPENSE,
    {
      fetchPolicy,
      skip: !id,
      variables: { id: id! },
    },
  );

  const expense = useMemo(
    () => (data?.expense ? normalizeExpense(data.expense) : undefined),
    [data],
  );

  return {
    loading,
    expense,
    refetch,
  };
};

export default useExpense;
