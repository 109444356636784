import { FC, memo } from 'react';
import { Formik } from 'formik';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  Row,
  TextField,
  Select,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const EmployeeModalForm: FC<Props> = ({
  className,
  onSubmit,
  onCancel,
  initialValues,
  isLoading,
  permissionsGroupsOptions,
  id = 'new',
}) => {
  const formId = `employee_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <Row>
            <TextField
              name="firstName"
              label="Nombre *"
              id={`${formId}_firstName`}
            />
            <TextField
              name="lastName"
              label="Apellidos *"
              id={`${formId}_lastName`}
            />
          </Row>
          <Row>
            <TextField
              name="document"
              label="DNI *"
              id={`${formId}_document`}
            />
            <TextField
              name="address"
              label="Domicilio"
              id={`${formId}_address`}
            />
          </Row>
          <Row>
            <TextField
              id={`${formId}_email`}
              label="email *"
              name="email"
              type="email"
            />
            <TextField
              name="phoneNumber"
              label="Nº de móvil"
              id={`${formId}_phoneNumber`}
            />
          </Row>
          <Select
            name="permissionsGroupId"
            label="Permisos"
            id={`${formId}_permissionsGroupId`}
            options={permissionsGroupsOptions}
          />
          <TextField
            type="password"
            name="password"
            label="Contraseña de acceso al sistema *"
            id={`${formId}_password`}
          />
          <Buttons>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={!isFormValid}
            >
              Crear
            </CreateButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(EmployeeModalForm);
