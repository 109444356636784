import { gql } from '@apollo/client';

export const EVENT_FRAGMENT = gql`
  fragment OrganizationEventData on OrganizationEvent {
    allDay
    end
    id
    start
    title
    notify
    emails
  }
`;
