import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useMetrics from 'apollo/hooks/useMetrics';

const useConnect = () => {
  const { productsRanking, loading } = useMetrics();
  const navigate = useNavigate();

  const handleReturn = useCallback(() => {
    navigate(`/metrics`, { replace: true });
  }, [navigate]);

  return {
    isLoading: loading,
    productsRanking,
    handleReturn,
  };
};

export default useConnect;
