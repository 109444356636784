import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultQuotaIcon from 'components/Icon/Icons/Quota';
import DefaultEmptyState from 'components/EmptyState';
import DefaultPrice from 'components/Price';

export const Container = styled(DefaultModal)`
  ${Content} {
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Button = styled(DefaultButton)``;

export const IconCircle = styled(DefaultIconCircle)`
  margin-left: auto;
  margin-right: auto;
`;

export const Icon = styled(DefaultQuotaIcon).attrs(() => ({
  size: 20,
}))``;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Quotas = styled.div`
  margin: 1rem 0;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;

  ${from.tabletPortrait`
    flex-flow: row nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    ${from.tabletPortrait`
      margin-right: 0.5rem;
    `}
  }
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 1rem auto 0 auto;
`;

export const Price = styled(DefaultPrice)`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const AddDiscountButton = styled.button<{ $hasDiscount?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  border: 1px solid
    ${({ $hasDiscount, theme }) =>
      $hasDiscount ? theme.colors.green : theme.colors.accent};
  color: ${({ $hasDiscount, theme }) =>
    $hasDiscount ? theme.colors.green : theme.colors.accent};
  cursor: pointer;
  font-size: 0.6em;
  margin-left: 0.4rem;
  padding: 0.4rem;
  text-transform: uppercase;
  transition: background 0.3s ease;

  &:hover {
    background: ${({ $hasDiscount, theme }) =>
      $hasDiscount ? theme.colors.green : theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }
`;
