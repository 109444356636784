import styled from 'styled-components';
import { SectionHeader } from 'components/Layout';
import { from } from 'styles/responsive';

export const Header = styled(SectionHeader)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0;

  ${from.tabletLandscape`
    margin-bottom: 5rem;
  `}
`;

export const AvatarContainer = styled.div`
  margin: 0 1rem;
`;

export const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
`;

export const HeaderName = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: 0;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
