import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar, permissionsStateVar } from 'apollo/reactive';
import useCashierByPeriod from 'apollo/hooks/cashier/useCashierByPeriod';
import {
  cashierProductHeaders,
  getCashierProductData,
  getCashierProductFilename,
} from 'utils/csv/cashier';

const useConnect = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { activateProductReference } = useReactiveVar(organizationConfigVar);
  const [searchParams] = useSearchParams();
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const getCashierFilters = useCallback(() => {
    const filters: { start?: Date; end?: Date } = {};
    if (start) {
      filters.start = new Date(Number(start));
    }

    if (end) {
      filters.end = new Date(Number(end));
    }
    return filters;
  }, [end, start]);

  const { cashier, loading } = useCashierByPeriod({ ...getCashierFilters() });

  const csvInfo = useMemo(() => {
    const data = getCashierProductData({
      showProductReference: activateProductReference,
      products: cashier?.products,
    });
    const filename = getCashierProductFilename({
      from: start ? new Date(Number(start)) : new Date(),
      to: end ? new Date(Number(end)) : new Date(),
    });

    return { headers: cashierProductHeaders, data, filename };
  }, [activateProductReference, cashier, start, end]);

  return {
    products: cashier?.products,
    csvInfo,
    isLoading: loading,
    start,
    end,
    employeePermissions,
  };
};

export default useConnect;
