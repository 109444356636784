import { FC, memo } from 'react';
import AvatarEditor from 'components/AvatarEditor';
import { formatDateToTableExtended } from 'utils/dates/formatDates';
import {
  AvatarContainer,
  BackLink,
  Header,
  HeaderButtons,
  HeaderInfo,
  HeaderName,
  TimerContainer,
  TimerIcon,
  PrintButton,
  SecondaryButton,
  LastRegister,
} from './styles';
import { Props } from './types';

const PartnerHeader: FC<Props> = ({
  currentAvatar,
  fromTabletPortrait,
  fullName,
  lastRegister,
  onClickAdvancedActions,
  onClickOrder,
  onClickPartnerPresence,
  onSubmitAvatar,
  partnerPresenceRegistered,
}) => {
  return (
    <>
      <Header>
        <BackLink to="/partners" />
        <AvatarContainer>
          <AvatarEditor
            fullName={fullName}
            currentImage={currentAvatar}
            onSubmitImage={onSubmitAvatar}
          />
        </AvatarContainer>
        {fromTabletPortrait && (
          <>
            <HeaderInfo>
              <HeaderName>{fullName}</HeaderName>
              <TimerContainer>
                <TimerIcon
                  $isJoined={partnerPresenceRegistered}
                  name="timer"
                  onClick={onClickPartnerPresence}
                />
                {partnerPresenceRegistered && lastRegister && (
                  <LastRegister>
                    Último registro: {formatDateToTableExtended(lastRegister)}
                  </LastRegister>
                )}
              </TimerContainer>
            </HeaderInfo>
          </>
        )}
        <HeaderButtons>
          {fromTabletPortrait && (
            <PrintButton onClick={onClickOrder}>Pedido</PrintButton>
          )}
          <SecondaryButton onClick={onClickAdvancedActions}>
            ···
          </SecondaryButton>
        </HeaderButtons>
      </Header>
    </>
  );
};

export default memo(PartnerHeader);
