import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultLogo from 'components/Logo';
import { SectionHeader } from 'components/Layout';
import DefaultButton from 'components/Button';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  padding: 1rem 1.5rem;
  margin: 0 auto;
  max-width: 1236px;
  width: 100%;

  ${from.tabletPortrait`
    padding: 1rem 2rem;
  `}
`;

export const Header = styled(SectionHeader)`
  padding: 0;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Logo = styled(DefaultLogo)`
  width: 7rem !important;

  ${from.tabletPortrait`
    width: 10rem !important;
  `}
`;

export const Content = styled.div`
  margin: 3rem 0;
`;

export const Dashboard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
`;

export const DashboardText = styled.div`
  padding-top: 2rem;
  margin-right: 1.5rem;

  ${from.tabletPortrait`
    margin-right: 0;
    padding-top: 6rem;
  `}
`;

export const DashboardTextTitle = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 2rem 0;

  ${from.tabletPortrait`
    font-size: 3rem;
    margin: 0 0 0.8rem 0;
  `}
`;

export const DashboardTextSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.grey900};
  display: none;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  ${from.tabletPortrait`
    display: inherit;
    font-size: 1.25rem;
  `}
`;

export const DashboardImgContainer = styled.div`
  padding: 1rem;
`;

export const DashboardImg = styled.img`
  -webkit-box-shadow: 10px 10px 0 1px rgba(140, 152, 164, 0.13);
  -moz-box-shadow: 10px 10px 0 1px rgba(140, 152, 164, 0.13);
  box-shadow: 10px 10px 0 1px rgba(140, 152, 164, 0.13);
  border-radius: 0.5rem;
  max-width: 25rem;
  max-height: 25rem;

  ${from.tabletPortrait`
    max-width: 43rem;
    max-height: 43rem;
  `}
`;

export const Highlighted = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  background: linear-gradient(transparent 82%, rgba(249, 185, 52, 0.3) 0%);
`;

export const Buttons = styled.div`
  display: flex;
`;

export const Button = styled(DefaultButton)`
  &&& {
    padding: 0.5rem 0.8rem;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    ${from.tabletPortrait`
     display: inherit;
     font-size: 1.25rem;
  `}
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  margin-bottom: 1rem;
  width: 100%;
`;

export const FooterText = styled.h6`
  color: ${({ theme }) => theme.colors.grey900};
  text-align: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
`;

export const FooterContact = styled.div`
  text-align: center;
`;

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
  text-decoration: underline;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;
