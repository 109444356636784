import { FC, useCallback } from 'react';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import { ListRowProps, WindowScroller } from 'react-virtualized';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { Container, EmptyState, Main, Filters, List } from './styles';

const PreviousOrders: FC = () => {
  const {
    previousBookings,
    isLoading,
    isRefetching,
    handleReturn,
    handleSearch,
    filter,
  } = useConnect();

  const hasResults = previousBookings?.length > 0;
  const ROW_HEIGHT_DESKTOP_PX = 65;

  const rowRenderer: FC<ListRowProps> = useCallback(
    ({ index, style }) => {
      const booking = previousBookings[index];

      return <Row booking={booking} key={booking.id} style={style} />;
    },
    [previousBookings],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header title="Pedidos anteriores" onReturn={handleReturn}>
        <Filters
          isLoading={isRefetching}
          onSearchChange={handleSearch}
          filter={filter}
          nResults={previousBookings?.length || 0}
        />
      </Header>
      <Main>
        {!hasResults && (
          <EmptyState title="No hay pedidos anteriores" iconName="orders">
            No se puede generar la información
          </EmptyState>
        )}

        {hasResults && (
          <>
            <TableHead />
            <WindowScroller>
              {({ onChildScroll, ...scrollInfo }) => (
                <List
                  autoHeight
                  autoWidth
                  onScroll={onChildScroll}
                  rowCount={previousBookings.length}
                  rowHeight={ROW_HEIGHT_DESKTOP_PX}
                  rowRenderer={rowRenderer}
                  {...scrollInfo}
                />
              )}
            </WindowScroller>
          </>
        )}
      </Main>
    </Container>
  );
};

export default PreviousOrders;
