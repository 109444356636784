import styled from 'styled-components';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 0 ${TABLE_METRICS.name};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const Adjust = styled.div<{ $value: number }>`
  color: ${({ theme, $value }) =>
    // eslint-disable-next-line no-nested-ternary
    $value === 0
      ? theme.colors.primary
      : $value >= 0
      ? theme.colors.green
      : theme.colors.danger};
  flex: 0 0 ${TABLE_METRICS.adjust};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const TotalCredits = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 0 ${TABLE_METRICS.totalCreditsAdded};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const RealCredits = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 0 ${TABLE_METRICS.realCreditsAdded};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const CreationDate = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 1 ${TABLE_METRICS.creationDate};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LastUpdateDate = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  flex: 0 1 ${TABLE_METRICS.lastUpdateDate};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
