import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  Container,
  Note,
  Notification,
  AddedBy,
  Date,
  LastEditionBy,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <AddedBy>Creada por</AddedBy>
      {fromTabletPortrait && <LastEditionBy>Ultima edición por</LastEditionBy>}
      <Note>Nota</Note>
      <Date>Fecha</Date>
      <Notification>Mostrar</Notification>
    </Container>
  );
};

export default memo(Row);
