import type { FC } from 'react';
import useGlobalKeyPress from 'hooks/useGlobalKeyPress';
import useConnect from './connect';
import {
  Buttons,
  CancelButton,
  Container,
  Icon,
  Title,
  Reader,
  Light,
} from './styles';

const RfidDialog: FC = () => {
  const { handleClose, isOpen, handleCodeReceived } = useConnect();

  useGlobalKeyPress(isOpen, handleCodeReceived);

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <Reader>
        <Light>
          <Icon name="rfid" />
        </Light>
      </Reader>
      <Title>Lectura activada</Title>
      <Buttons>
        <CancelButton onClick={handleClose}>Cerrar</CancelButton>
      </Buttons>
    </Container>
  );
};

export default RfidDialog;
