export const TABLE_METRICS = {
  image: '10%',
  name: '18%',
  currentQuantity: '16%',
  baseWeight: '16%',
  addStock: '10%',
  retireStock: '10%',
  setStock: '10%',
  storeMovement: '10%',
};

export const MOBILE_TABLE_METRICS = {
  name: '20%',
  currentQuantity: '20%',
};
