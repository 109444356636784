import { FC, memo } from 'react';
import { FlourContainer, Text } from '../styles';
import './styles.css';

const WhiteFlour: FC = () => (
  <FlourContainer>
    <Text>
      "En el eco del amor, cuidamos con esmero, cada gesto de ternura, un lazo
      sincero. En el halo de tu cuidad, mi alma encuentra abrigo, y en
      respuesta, te ofrezco mi amor junto a una vida contigo. Detrás de la
      armonía, donde el orden se halla, se encuentra el regalo."
    </Text>
    <div className="container-white">
      <div className="glass" />
      <div className="thorns">
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className="glow" />
      <div className="rose-leaves">
        <div />
        <div />
      </div>
      <div className="rose-petals">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className="sparkles">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </FlourContainer>
);

export default memo(WhiteFlour);
