import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import DefaultLogo from 'components/Logo';
import { from } from 'styles/responsive';
import { ZIndex } from 'styles/zIndex';
import { NavLink, NavLinkProps } from 'react-router-dom';
import DefaultLink from 'components/Link';
import { prettyScrollbar } from 'styles/mixins';
import UserLogout from '../UserLogout';

export const SIDEBAR_WIDTH = '13rem';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  display: none;
  height: 100vh;
  padding: 1.5rem;
  position: fixed;
  width: ${SIDEBAR_WIDTH};
  z-index: ${ZIndex.SIDEBAR};

  ${from.tabletLandscape`
    display: initial;
  `};
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoLink = styled(DefaultLink)``;

export const Logo = styled(DefaultLogo)`
  width: 5.875rem;
`;

export const OrderButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 0.875rem;
  height: 1.6rem;
  justify-content: center;
  margin-left: 2.625rem;
  padding: 0;
  width: 1.6rem;
`;

export const Navigation = styled.nav`
  align-items: stretch;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  margin-top: 3rem;
  height: 93%;
`;

export const TopLinks = styled.div`
  flex: 1 1 auto;
  margin-bottom: 1rem;
  ${prettyScrollbar({
    size: '0',
  })};
  overflow-y: scroll;
`;

export const Label = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}0a;
  flex: 1 1 auto;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  padding-bottom: 1.1875rem;
  padding-top: 1rem;
  text-transform: capitalize;
`;

export const Icon = styled(DefaultIcon)`
  flex: 0 0 auto;
  margin-right: 1.25rem;
  margin-top: -0.2188rem;
`;

export const OrdersLink = styled(DefaultLink)``;

export const Link = styled(NavLink)<NavLinkProps>`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.colors.accent};
  }

  &:hover {
    opacity: 0.7;
    &.active {
      opacity: 1;
    }
  }
`;

export const DisabledLink = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.grey900};
  display: flex;
  position: relative;

  ${Icon} {
    color: ${({ theme }) => theme.colors.grey900};
  }
`;

export const DisabledLabel = styled.span`
  background-color: ${({ theme }) => theme.colors.green}8A;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.6rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-transform: uppercase;
  transform: rotate(13deg);
  padding: 0.4rem;
  position: absolute;
  top: 0.8rem;
  right: 0;
`;

export const Logout = styled(UserLogout)`
  margin: 1rem 0;
`;
