import styled from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
`;

export const Date = styled.div`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.date};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.bold};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${from.tabletLandscape`
    font-size: 1rem;
 `}
`;

export const EndSession = styled.div`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.endSession};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.bold};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${from.tabletLandscape`
    font-size: 1rem;
 `}
`;
