import { FC, memo } from 'react';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import { SectionTitle, Header, BackLink, HeaderContent } from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import {
  Container,
  EmptyState,
  Main,
  DateTimeFilterContainer,
  ProductGroup,
} from './styles';

const ProductsByReference: FC = () => {
  const { filterDates, handleOnSearch, isLoading, groupedProducts } =
    useConnect();

  const hasData = groupedProducts.length > 0;

  return (
    <Container>
      <Header>
        <HeaderContent>
          <BackLink to="/organization" />
          <SectionTitle>Productos por referencia</SectionTitle>
        </HeaderContent>
      </Header>
      <DateTimeFilterContainer>
        <DateTimeFilter
          fromDate={filterDates.from}
          toDate={filterDates.to}
          onSearch={handleOnSearch}
        />
      </DateTimeFilterContainer>
      {isLoading && <Loader />}
      {!isLoading && (
        <Main>
          {!hasData && (
            <EmptyState title="No hay productos" iconName="products">
              Debes tener al menos una transacción de un producto
            </EmptyState>
          )}

          {hasData &&
            groupedProducts.map((group) => (
              <ProductGroup key={group.name}>
                <TableHead productName={group.name} />
                {group.products.map((product) => (
                  <Row key={product.id} productStatistics={product} />
                ))}
              </ProductGroup>
            ))}
        </Main>
      )}
    </Container>
  );
};

export default memo(ProductsByReference);
