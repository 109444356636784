import type { FC } from 'react';
import { Navigate, useLocation, Route } from 'react-router-dom';
import { Container, Switch } from './styles';

const PartnerViews: FC = () => {
  const location = useLocation();

  return (
    <Container>
      <Switch location={location}>
        <Route path="*" element={<Navigate to="/" />} />
      </Switch>
    </Container>
  );
};

export default PartnerViews;
