import { FC, memo, useCallback } from 'react';
import {
  Container,
  DoneIcon,
  InfoProduct,
  Label,
  Price,
  Thumbnail,
  Image,
  ImageContainer,
  ReferenceCode,
} from './styles';
import type { Props } from './types';

const SquareFormat: FC<Props> = ({
  activateProductReference,
  isSelected,
  onClick,
  product,
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(product.id);
    }
  }, [product, onClick]);

  return (
    <Container $isSelected={isSelected} onClick={handleClick}>
      {!product.mainImageUrl ? (
        <Thumbnail>
          {isSelected ? <DoneIcon /> : product.abbreviation}
        </Thumbnail>
      ) : (
        <ImageContainer>
          <Image
            style={{ backgroundImage: `url('${product.mainImageUrl}')` }}
          />
        </ImageContainer>
      )}
      <InfoProduct>
        <Label>
          {product.name} ({product.quantity.toFixed(2)})
        </Label>
        {activateProductReference && product.referenceCode && (
          <ReferenceCode>#{product.referenceCode}</ReferenceCode>
        )}
        <Price>{product.price}</Price>
      </InfoProduct>
    </Container>
  );
};

export default memo(SquareFormat);
