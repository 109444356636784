import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CREATE_QUOTA,
  REMOVE_QUOTA,
  UPDATE_QUOTA,
} from 'apollo/mutations/quotas';
import {
  CreateQuota,
  CreateQuotaVariables,
} from 'apollo/generated/CreateQuota';
import {
  UpdateQuota,
  UpdateQuotaVariables,
} from 'apollo/generated/UpdateQuota';
import {
  RemoveQuota,
  RemoveQuotaVariables,
} from 'apollo/generated/RemoveQuota';
import type { QuotaFormFields } from 'model/Quota';

const useQuotaActions = () => {
  const [createQuotaMutation, { loading: isLoadingCreateQuota }] = useMutation<
    CreateQuota,
    CreateQuotaVariables
  >(CREATE_QUOTA);
  const [updateQuotaMutation, { loading: isLoadingUpdateQuota }] = useMutation<
    UpdateQuota,
    UpdateQuotaVariables
  >(UPDATE_QUOTA);
  const [removeQuotaMutation, { loading: isLoadingRemoveQuota }] = useMutation<
    RemoveQuota,
    RemoveQuotaVariables
  >(REMOVE_QUOTA);

  const normalizeFormValues = useCallback(
    (values: QuotaFormFields) => ({
      name: values.name,
      type: values.type,
      value: Number(values.value),
      price: Number(values.price),
    }),
    [],
  );

  const createQuota = useCallback(
    async (data: QuotaFormFields) => {
      const normalizedData = normalizeFormValues(data);
      await createQuotaMutation({
        variables: { data: normalizedData },
      });
    },
    [createQuotaMutation, normalizeFormValues],
  );

  const updateQuota = useCallback(
    async (id: string, data: QuotaFormFields) => {
      const normalizedData = normalizeFormValues(data);
      await updateQuotaMutation({
        variables: { id, data: normalizedData },
      });
    },
    [updateQuotaMutation, normalizeFormValues],
  );

  const deleteQuota = useCallback(
    async (id: string) => {
      await removeQuotaMutation({
        variables: {
          id,
        },
      });
    },
    [removeQuotaMutation],
  );

  return {
    createQuota,
    updateQuota,
    deleteQuota,
    loading:
      isLoadingCreateQuota || isLoadingUpdateQuota || isLoadingRemoveQuota,
  };
};

export default useQuotaActions;
