import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, Icon, IconCircle, Header } from './styles';

const NewPartner: FC = () => {
  const {
    creationPartnerLoading,
    formikRef,
    handleClose,
    handleSelectHostPartner,
    handleSubmit,
    initialValues,
    isOpen,
    selectedPartner,
  } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="users" />
        </IconCircle>
        <Title>Socio nuevo</Title>
      </Header>

      <Form
        onSubmit={handleSubmit}
        onCancel={handleClose}
        onSelectHostPartner={handleSelectHostPartner}
        initialValues={initialValues}
        isLoading={creationPartnerLoading}
        formikRef={formikRef}
        selectedPartner={selectedPartner}
      />
    </Container>
  );
};

export default NewPartner;
