import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { PartnerUsage, UserGender } from 'apollo/generated/globalTypes';
import type { PartnerFormFields } from 'model/Partner';

const useConnect = () => {
  const { close, type, partnerDetailsPayload } = useModal();
  const { partner } = partnerDetailsPayload || {};

  const initialValues = useMemo<PartnerFormFields>(
    () => ({
      address: partner?.address || '',
      avatarImageUrl: partner?.avatarImageUrl || '',
      birthDate: partner?.birthDate || undefined,
      credits: partner?.credits || 0,
      document: partner?.document || '',
      email: partner?.email || '',
      firstName: partner?.firstName || '',
      gender: partner?.gender || UserGender.OTHER,
      hostMemberNum: partner?.hostMemberNum || '0',
      lastName: partner?.lastName || '',
      maxConsumeMonth: partner?.maxConsumeMonth || 0,
      memberNum: partner?.memberNum || '0',
      phoneNumber: partner?.phoneNumber || '',
      usage: partner?.usage || PartnerUsage.PLAYFUL,
    }),
    [partner],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<FormikConfig<PartnerFormFields>['onSubmit']>(
    async (data: PartnerFormFields) => {
      console.log(data);
    },
    [],
  );

  return {
    handleSubmit,
    handleClose,
    initialValues,
    isDeleted: partner?.isDeleted || false,
    isOpen: type === ModalType.PARTNER_DETAILS,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
