import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import AvatarEditor from 'components/AvatarEditor';
import { AddIcon, CreateButton } from 'containers/Common/Header/styles';
import { AvatarContainer, Header, HeaderName, InfoContainer } from './styles';
import { Props } from './types';

const OrganizationHeader: FC<Props> = ({
  initialValues,
  onSubmitAvatar,
  openCreateMenu,
}) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Header>
      <InfoContainer>
        <AvatarContainer>
          <AvatarEditor
            fullName={initialValues.name}
            currentImage={initialValues.logoImageUrl}
            onSubmitImage={onSubmitAvatar}
          />
        </AvatarContainer>
        {fromTabletPortrait && <HeaderName>{initialValues.name}</HeaderName>}
      </InfoContainer>
      <CreateButton onClick={openCreateMenu}>
        <AddIcon />
      </CreateButton>
    </Header>
  );
};

export default memo(OrganizationHeader);
