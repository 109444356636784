import { FC, memo } from 'react';
import type { Props } from './types';

const Camera: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    height={(size * 18) / 22}
    viewBox="0 0 22 18"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.573 0a2.5 2.5 0 012.237 1.382l.808 1.617L19.5 3a2.5 2.5 0 012.495 2.336L22 5.5v10a2.5 2.5 0 01-2.5 2.5h-17A2.5 2.5 0 010 15.5v-10A2.5 2.5 0 012.5 3l1.881-.001.81-1.617A2.5 2.5 0 017.25.006L7.427 0zm0 2H7.427a.5.5 0 00-.447.276L5.895 4.447A1 1 0 015 5H2.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5H17a1 1 0 01-.895-.553l-1.084-2.17A.5.5 0 0014.573 2zM11 5a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6z"
      fill="currentColor"
    />
  </svg>
);

export default memo(Camera);
