import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 30vh;
  width: 100%;
`;

export const Progress = styled(CircularProgress)``;
