import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useOrganizationActions from 'apollo/hooks/organization/useOrganizationActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { CreditConceptFormFields } from 'model/Organization';

const useConnect = () => {
  const { close, type, creditConceptPayload } = useModal();
  const { setOrganizationCreditConcepts, loading } = useOrganizationActions();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo<CreditConceptFormFields>(
    () => ({
      creditConcept: creditConceptPayload?.concept || '',
    }),
    [creditConceptPayload],
  );

  const selectedCreditConcept = useMemo<string>(
    () => creditConceptPayload?.concept || '',
    [creditConceptPayload],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<CreditConceptFormFields>['onSubmit']
  >(
    async ({ creditConcept }: CreditConceptFormFields) => {
      try {
        await setOrganizationCreditConcepts({
          creditConcept,
          creditConceptToRemove: selectedCreditConcept,
        });
        enqueueSnackbar(
          `El concepto se ha ${
            creditConceptPayload?.concept ? 'editado' : 'creado'
          } correctamente`,
          {
            variant: 'success',
          },
        );
        close();
      } catch (e) {
        enqueueSnackbar(
          formatErrors(
            'organization',
            e.message,
            creditConceptPayload?.concept ? 'actualizar' : 'crear',
          ),
          {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          },
        );
      }
    },
    [
      setOrganizationCreditConcepts,
      selectedCreditConcept,
      enqueueSnackbar,
      creditConceptPayload,
      close,
    ],
  );

  return {
    handleClose,
    handleSubmit,
    initialValues,
    isLoading: loading,
    isOpen: type === ModalType.CREDIT_CONCEPT,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
