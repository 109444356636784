import { FC, memo } from 'react';
import { Container, IconsSpace, Name } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Name>Nombre</Name>
      <IconsSpace />
    </Container>
  );
};

export default memo(Row);
