import { gql } from '@apollo/client';

export const PROVIDER_FRAGMENT = gql`
  fragment ProviderData on Provider {
    id
    addedBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    createdAt
    deletedAt
    description
    expenses {
      ... on Expense {
        id
        name
      }
    }
    name
    updatedAt
  }
`;
