import { FC, memo } from 'react';
import { CSVLink } from 'react-csv';
import Loader from 'components/Loader';
import Button from 'components/Button';
import CashierDetailsModal from '../../../Modals/CashierDetails';
import { SectionTitle, Header, BackLink, HeaderContent } from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const CashiersHistory: FC = () => {
  const { cashiers, isLoading, handleOpenDetails, csvInfo } = useConnect();

  const hasCashiers = cashiers.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <BackLink to="/organization" />
            <SectionTitle>Histórico de caja</SectionTitle>
          </HeaderContent>
          <Button variant="light">
            <CSVLink
              data={csvInfo.data}
              headers={csvInfo.headers}
              filename={csvInfo.filename}
              target="_blank"
            >
              Descargar
            </CSVLink>
          </Button>
        </Header>
        <Main>
          {!hasCashiers && (
            <EmptyState title="No hay cajas" iconName="cashier">
              Debes tener al menos un flujo de caja
            </EmptyState>
          )}

          {hasCashiers && (
            <>
              <TableHead />
              {cashiers.map((cashier) => (
                <Row
                  key={cashier.id}
                  cashier={cashier}
                  onOpenCashier={handleOpenDetails}
                />
              ))}
            </>
          )}
        </Main>
      </Container>
      <CashierDetailsModal />
    </>
  );
};

export default memo(CashiersHistory);
