import type { BillingMonthData } from 'apollo/generated/BillingMonthData';
import type { ChartData } from 'apollo/generated/ChartData';
import type { TotalPartnersMetricData } from 'apollo/generated/TotalPartnersMetricData';
import type { ProductRankingData } from 'apollo/generated/ProductRankingData';
import type { MonthProfit } from 'apollo/graphql.types';
import { isAfter, subWeeks } from 'date-fns';

const MONTHS_MAP = {
  '1': 'Enero',
  '2': 'Febrero',
  '3': 'Marzo',
  '4': 'Abril',
  '5': 'Mayo',
  '6': 'Junio',
  '7': 'Julio',
  '8': 'Agosto',
  '9': 'Septiembre',
  '10': 'Octubre',
  '11': 'Noviembre',
  '12': 'Diciembre',
};

export const normalizeChart = (input: ChartData) => ({
  name: input.name || '',
  value: input.value || 0,
});

export type Chart = ReturnType<typeof normalizeChart>;

export const normalizeBillingMetric = (input: BillingMonthData) => ({
  totalMonthCredits: input.totalMonthCredits || 0,
  totalWeekCredits: input.totalWeekCredits || 0,
  chart: input.chart.map(normalizeChart),
});

export const normalizeMonthlyProductsMetric = (input: MonthProfit) => ({
  // @ts-ignore
  month: MONTHS_MAP[input.month || '1'],
  costes: Number((input.costs || 0).toFixed(2)),
  ganancias: Number((input.profits || 0).toFixed(2)),
});

export type MonthlyProductsMetric = ReturnType<
  typeof normalizeMonthlyProductsMetric
>;

export const normalizeMonthlyCreditsMetric = (input: MonthProfit) => ({
  // @ts-ignore
  month: MONTHS_MAP[input.month || '1'],
  gastos: Number((input.costs || 0).toFixed(2)),
  donaciones: Number((input.profits || 0).toFixed(2)),
});

export type MonthlyCreditsMetric = ReturnType<
  typeof normalizeMonthlyCreditsMetric
>;

export const normalizePartnersMetric = (input: TotalPartnersMetricData) => ({
  totalPartners: input.partners || 0,
  totalMonthPartners: input.totalMonthPartners || 0,
  chart: input.chart.map(normalizeChart),
});

export enum ProductRankingState {
  UP = 'UP',
  DOWN = 'DOWN',
  EQUAL = 'EQUAL',
}

export const normalizeProductRanking = (input: ProductRankingData) => {
  const lastPosition = input.lastPosition || 0;
  const position = input.position || 0;
  const createdAt = input.product.createdAt
    ? new Date(input.product.createdAt)
    : new Date();

  let state = ProductRankingState.EQUAL;

  if (lastPosition < position) {
    state = ProductRankingState.DOWN;
  } else if (lastPosition > position) {
    state = ProductRankingState.UP;
  }

  return {
    lastPosition,
    position,
    product: {
      id: input.product.id || '',
      name: input.product.name || '',
      // @ts-ignore
      referenceCode: input.product.referenceCode || '',
      createdAt,
    },
    totalPurchases: input.totalPurchases || 0,
    totalSales: input.totalSales || 0.0,
    isNew: isAfter(createdAt, subWeeks(new Date(), 1)),
    state,
  };
};

export type ProductRanking = ReturnType<typeof normalizeProductRanking>;
