import { FC, memo } from 'react';
import { Formik } from 'formik';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  TextField,
  Icon,
  SelectOptions,
  SelectOptionsTitle,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const Form: FC<Props> = ({
  className,
  creationLoading,
  formikRef,
  id = 'new',
  initialValues,
  onCancel,
  onSelectCategories,
  onSelectProvider,
  onSubmit,
}) => {
  const formId = `expense_${id}`;
  const hasCategories = initialValues?.categoryIds?.length > 0;
  const hasProvider = !!initialValues?.providerName;

  return (
    <Formik
      innerRef={formikRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isValid: formIsValid }) => (
        <Container className={className} onSubmit={handleSubmit}>
          <TextField
            placeholder="Hielo"
            name="name"
            label="Nombre *"
            id={`${formId}_name`}
          />
          <TextField
            placeholder="5"
            type="number"
            name="price"
            label="Precio *"
            id={`${formId}_price`}
          />
          <TextField
            placeholder="870"
            type="number"
            name="quantity"
            label="Cantidad *"
            id={`${formId}_quantity`}
          />
          <SelectOptions onClick={onSelectCategories} $hasItems={hasCategories}>
            {hasCategories ? (
              <SelectOptionsTitle>
                Categorías Seleccionadas ({initialValues.categoryIds.length})
              </SelectOptionsTitle>
            ) : (
              <SelectOptionsTitle>Seleccionar categorías</SelectOptionsTitle>
            )}
            <Icon name="arrowRight" />
          </SelectOptions>
          <SelectOptions onClick={onSelectProvider} $hasItems={hasProvider}>
            {hasProvider ? (
              <SelectOptionsTitle>
                Proveedor Seleccionado ({initialValues.providerName})
              </SelectOptionsTitle>
            ) : (
              <SelectOptionsTitle>Seleccionar proveedor</SelectOptionsTitle>
            )}
            <Icon name="arrowRight" />
          </SelectOptions>
          <Buttons>
            <CreateButton
              type="submit"
              disabled={!formIsValid || !hasProvider}
              isLoading={creationLoading}
            >
              Crear
            </CreateButton>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(Form);
