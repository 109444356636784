import { FC, memo } from 'react';
import NewProviderModal from '../../../Modals/NewProvider';
import {
  BackLink,
  CreateButton,
  Header,
  HeaderContent,
  SectionTitle,
} from '../styles';
import Row from './Row';
import TableHead from './TableHead';
import { Container, EmptyState, Main } from './styles';
import useConnect from './connect';

const OrganizationProviders: FC = () => {
  const {
    employeePermissions,
    providers,
    handleOpenNewProviderModal,
    handleOpenRemoveProviderDialog,
    handleEditProvider,
    handleOpenProviderExpenses,
  } = useConnect();

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <BackLink to="/organization" />
            <SectionTitle>Proveedores</SectionTitle>
          </HeaderContent>
          {employeePermissions.allowCreateProviders && (
            <CreateButton onClick={() => handleOpenNewProviderModal()}>
              Crear
            </CreateButton>
          )}
        </Header>
        <Main>
          {providers.length > 0 ? (
            <>
              <TableHead />
              {providers.map((provider) => (
                <Row
                  addedBy={provider.addedBy.fullName}
                  employeePermissions={employeePermissions}
                  key={provider.id}
                  totalExpenses={provider.expenses.length}
                  name={provider.name}
                  onOpenExpensesModal={() =>
                    handleOpenProviderExpenses(provider.expenses)
                  }
                  onEdit={() => handleEditProvider(provider)}
                  onRemove={() => handleOpenRemoveProviderDialog(provider)}
                />
              ))}
            </>
          ) : (
            <EmptyState
              title="No hay proveedores"
              iconName="cart"
              buttonLabel="Crear Proveedor"
              onClickButton={handleOpenNewProviderModal}
            >
              Crea tu primer proveedor
            </EmptyState>
          )}
        </Main>
      </Container>
      <NewProviderModal />
    </>
  );
};

export default memo(OrganizationProviders);
