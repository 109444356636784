import type { FC } from 'react';
import { Formik } from 'formik';
import Checkbox from 'components/Inputs/Checkbox';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  Divider,
  Container,
  IconCircle,
  Icon,
  Title,
  Form,
  TextField,
  Row,
  GreenIconCircle,
  RequestMsg,
  FormPolicy,
  Link,
  CheckBoxContainer,
} from './styles';
import { initialValues, validationSchema } from './constants';

const NewAccountRequest: FC = () => {
  const { isOpen, handleOnClose, handleSubmit, isLoading, showSendMsg } =
    useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleOnClose}>
      {showSendMsg ? (
        <>
          <GreenIconCircle>
            <Icon name="check" />
          </GreenIconCircle>
          <RequestMsg>
            La solicitud a sido registrada, nos pondremos en contacto lo antes
            posible. Gracias por confiar en Inditas
          </RequestMsg>
          <CancelButton onClick={handleOnClose}>Cerrar</CancelButton>
        </>
      ) : (
        <>
          <IconCircle>
            <Icon name="organization" />
          </IconCircle>
          <Title>Solicitar nueva cuenta</Title>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, isValid }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <TextField
                    id="name"
                    label="Nombre *"
                    name="name"
                    type="text"
                  />
                  <TextField
                    id="phone"
                    label="Telefono *"
                    name="phone"
                    type="number"
                  />
                </Row>
                <TextField
                  id="email"
                  label="Email *"
                  name="email"
                  type="text"
                />
                <br />
                <TextField
                  isMultiline
                  placeholder="Nota..."
                  name="description"
                  label="Nota"
                  id="description"
                  rows={7}
                />
                <Buttons>
                  <AcceptButton
                    type="submit"
                    disabled={!isValid}
                    isLoading={isLoading}
                  >
                    Enviar
                  </AcceptButton>
                </Buttons>
                <Divider />
                <FormPolicy>
                  <CheckBoxContainer>
                    <Checkbox name="privacyPolicy" id="privacyPolicy" />
                    <Link target="_blank" to="/privacy-policy">
                      Política de Privacidad
                    </Link>
                  </CheckBoxContainer>
                  <CheckBoxContainer>
                    <Checkbox name="legalNotice" id="legalNotice" />
                    <Link target="_blank" to="/legal-notice">
                      Aviso Legal
                    </Link>
                  </CheckBoxContainer>
                  <CheckBoxContainer>
                    <Checkbox name="cookiesPolicy" id="cookiesPolicy" />
                    <Link target="_blank" to="/legal-notice">
                      Política de Cookies
                    </Link>
                  </CheckBoxContainer>
                </FormPolicy>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Container>
  );
};

export default NewAccountRequest;
