import styled from 'styled-components';
import { Form } from 'formik';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultSwitch from 'components/Inputs/Switch';

export const Container = styled(Form)``;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2rem;

  ${from.tabletPortrait`
    flex-flow: row-reverse nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const Button = styled(DefaultButton)`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-left: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))``;

export const TextField = styled(DefaultTextField)`
  margin-top: 1rem;
`;

export const Switch = styled(DefaultSwitch)`
  margin-top: 1rem;
`;
