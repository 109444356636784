import { FC, memo } from 'react';

import type { Props } from './types';

const ArrowRight: FC<Props> = ({ size = 7, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    viewBox="0 0 7 10"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1 10l5-5-5-5"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(ArrowRight);
