import { FC, memo } from 'react';
import { formatDateToTableExtended } from 'utils/dates/formatDates';
import { Container, Content, Date } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, date, style }) => {
  return (
    <Container className={className} style={style}>
      <Content>
        <Date>{formatDateToTableExtended(date)}</Date>
      </Content>
    </Container>
  );
};

export default memo(Row);
