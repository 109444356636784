import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { ApiConfig } from 'config';
import { getToken, setToken } from './storage';

const getTimezoneOffsetInSeconds = () =>
  new Date().getTimezoneOffset() * 60 * -1;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      }
    });
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Accept: 'application/json',
    Authorization: `Bearer ${getToken()}`,
    'X-Application-Secret': ApiConfig.API_SECRET,
    timezone: getTimezoneOffsetInSeconds(),
    'app-client': 'web',
  },
}));

const uploadLink = createUploadLink({
  uri: `${ApiConfig.API}/graphql`,
  fetchOptions: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, uploadLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  },
});

// @ts-ignore
window.__SET_AUTH_TOKEN__ = (token: string) => {
  setToken(token);
  window.location.reload();
};
