import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  GET_BILLINGS_METRIC,
  GET_MONTHLY_COLLECTED_CREDITS_PROFIT,
  GET_MONTHLY_PROFIT,
  GET_PARTNERS_METRIC,
  GET_PRODUCTS_RANKING,
} from 'apollo/queries/metrics';
import type { GetBillingsMetric } from 'apollo/generated/GetBillingsMetric';
import {
  normalizeMonthlyCreditsMetric,
  normalizeMonthlyProductsMetric,
  normalizeBillingMetric,
  normalizePartnersMetric,
  normalizeProductRanking,
} from 'model/Metric';
import type { GetPartnersMetric } from 'apollo/generated/GetPartnersMetric';
import type { GetProductsRanking } from 'apollo/generated/GetProductsRanking';
import type {
  GetMonthlyProfitQuery,
  GetMonthlyCollectedCreditsProfitQuery,
} from 'apollo/graphql.types';
import notEmpty from 'utils/notEmpty';

const useMetrics = ({
  fetchPolicy = 'cache-first',
}: {
  fetchPolicy?: QueryHookOptions['fetchPolicy'];
} = {}) => {
  const { loading: GetBillingLoading, data: billingData } =
    useQuery<GetBillingsMetric>(GET_BILLINGS_METRIC, { fetchPolicy });
  const { loading: GetTotalPartnersLoading, data: partnersData } =
    useQuery<GetPartnersMetric>(GET_PARTNERS_METRIC, { fetchPolicy });
  const { loading: GetProductRankingLoading, data: productsRankingData } =
    useQuery<GetProductsRanking>(GET_PRODUCTS_RANKING, {
      fetchPolicy,
    });
  const { loading: GetMonthlyProfitLoading, data: monthlyProfitData } =
    useQuery<GetMonthlyProfitQuery>(GET_MONTHLY_PROFIT, {
      fetchPolicy,
    });
  const {
    loading: GetMonthlyCollectedCreditsProfitLoading,
    data: monthlyCollectedCreditsProfitData,
  } = useQuery<GetMonthlyCollectedCreditsProfitQuery>(
    GET_MONTHLY_COLLECTED_CREDITS_PROFIT,
    {
      fetchPolicy,
    },
  );

  const { billingsMetric } = useMemo(
    () => ({
      billingsMetric: billingData?.billingsMetric
        ? normalizeBillingMetric(billingData.billingsMetric)
        : undefined,
    }),
    [billingData],
  );

  const { partnersMetric } = useMemo(
    () => ({
      partnersMetric: partnersData?.partnersMetric
        ? normalizePartnersMetric(partnersData.partnersMetric)
        : undefined,
    }),
    [partnersData],
  );

  const { productsRanking } = useMemo(
    () => ({
      productsRanking: productsRankingData?.productsRanking
        ? productsRankingData.productsRanking
            .filter(notEmpty)
            .map(normalizeProductRanking)
        : [],
    }),
    [productsRankingData],
  );

  const monthlyProfit = useMemo(
    () =>
      monthlyProfitData?.monthlyProfit
        ? monthlyProfitData.monthlyProfit
            .filter(notEmpty)
            .map(normalizeMonthlyProductsMetric)
        : [],
    [monthlyProfitData],
  );

  const monthlyCollectedCreditsProfit = useMemo(
    () =>
      monthlyCollectedCreditsProfitData?.monthlyCollectedCreditsProfit
        ? monthlyCollectedCreditsProfitData.monthlyCollectedCreditsProfit
            .filter(notEmpty)
            .map(normalizeMonthlyCreditsMetric)
        : [],
    [monthlyCollectedCreditsProfitData],
  );

  return {
    billingsMetric,
    partnersMetric,
    productsRanking,
    monthlyProfit,
    monthlyCollectedCreditsProfit,
    loading:
      GetBillingLoading ||
      GetTotalPartnersLoading ||
      GetProductRankingLoading ||
      GetMonthlyProfitLoading ||
      GetMonthlyCollectedCreditsProfitLoading,
  };
};

export default useMetrics;
