import {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { Props } from './types';

const useLogic = ({ onComplete, length = 4, hasError }: Props) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
  const [isCompleted, setIsCompleted] = useState(false);
  const inputRefs = Array(length)
    .fill(0)
    .map(() => useRef<HTMLInputElement>(null));

  useEffect(() => {
    if (otp.every((digit) => digit !== '')) {
      setIsCompleted(true);
      inputRefs[length - 1].current?.blur();
    } else {
      setIsCompleted(false);
      if (otp[0] === '') {
        inputRefs[0].current?.focus();
      }
    }
  }, [otp, inputRefs, length]);

  useEffect(() => {
    if (hasError) {
      setOtp(Array(length).fill(''));
    }
  }, [hasError, length]);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const { value } = e.target;

      if (isNaN(Number(value))) {
        return;
      }

      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && index < length - 1 && inputRefs[index + 1].current) {
        inputRefs[index + 1].current?.focus();
      }

      if (newOtp.every((digit) => digit !== '')) {
        onComplete(newOtp.join(''));
      }
    },
    [inputRefs, length, otp, onComplete],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>, index: number) => {
      if (
        e.key === 'Backspace' &&
        index > 0 &&
        otp[index] === '' &&
        inputRefs[index - 1].current
      ) {
        inputRefs[index - 1].current?.focus();
      }
    },
    [inputRefs, otp],
  );

  const handleInputPaste = useCallback(
    (e: ClipboardEvent) => {
      e.preventDefault();
      const { clipboardData } = e;
      const pastedData = clipboardData?.getData('text/plain') || '';

      if (/^\d+$/.test(pastedData) && pastedData.length === length) {
        const newOtp = pastedData.split('').slice(0, length);
        setOtp(newOtp);

        if (newOtp.every((digit) => digit !== '')) {
          onComplete(newOtp.join(''));
        }
      }
    },
    [length, onComplete],
  );

  return {
    handleInputChange,
    handleInputPaste,
    handleKeyDown,
    isCompleted,
    otp,
    inputRefs,
  };
};

export default useLogic;
