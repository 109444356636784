export const TABLE_METRICS = {
  addedBy: '10%',
  lastEditionBy: '15%',
  description: '35%',
  createdAt: '20%',
  showNotification: '20%',
};

export const TABLE_MOBILE_METRICS = {
  addedBy: '25%',
  description: '25%',
  createdAt: '25%',
  showNotification: '25%',
};
