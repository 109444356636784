import { useEffect, useRef } from 'react';

const useGlobalKeyPress = (
  isActive: boolean,
  handler: (code: string) => void,
) => {
  const inputBuffer = useRef(''); // Buffer para almacenar la entrada actual
  const lastKeyPressTime = useRef(0); // Tiempo del último evento de teclado
  const isRFIDInput = useRef(false); // Indicador de entrada RFID

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastKeyPressTime.current;

      if (event.key === 'Enter' && isRFIDInput.current) {
        event.preventDefault();
        event.stopPropagation();
        // Procesar el código RFID cuando se presiona Enter después de una entrada RFID válida
        if (inputBuffer.current) {
          handler(inputBuffer.current);
          inputBuffer.current = ''; // Limpiar el buffer después de procesar
          isRFIDInput.current = false; // Reiniciar indicador de entrada RFID
        }
        return;
      }

      if (timeDiff > 30) {
        // Si hay un retraso significativo, limpiar el buffer
        inputBuffer.current = '';
      }

      // Almacenar la entrada actual en el buffer
      inputBuffer.current += event.key;
      lastKeyPressTime.current = currentTime;

      // Marcar como entrada RFID si hay una secuencia rápida de entradas consecutivas
      if (timeDiff < 30) {
        isRFIDInput.current = true;
      }
    };

    if (isActive) {
      window.addEventListener('keypress', handleKeyPress);
    }

    return () => {
      if (isActive) {
        window.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [handler, isActive]);
};

export default useGlobalKeyPress;
