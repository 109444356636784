import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultIcon from 'components/Icon';

export const Container = styled(DefaultModal)`
  ${Content} {
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const Button = styled(DefaultButton)``;

export const IconCircle = styled(DefaultIconCircle)`
  margin-left: auto;
  margin-right: auto;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 20,
}))``;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.grey900};
  font-family: 'Inter';
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  margin-bottom: 2rem;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;

  ${from.tabletPortrait`
    flex-flow: row nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const AcceptButton = styled(DefaultButton).attrs(
  ({ $danger }: { $danger: boolean }) => ({
    variant: $danger ? 'danger' : 'primary',
  }),
)<{ $danger: boolean }>`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-right: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    ${from.tabletPortrait`
      margin-right: 0.5rem;
    `}
  }
`;
