import { FC, memo } from 'react';
import { Formik } from 'formik';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  BackLink,
  Container,
  DeleteButton,
  Header,
  HeaderButtons,
  HeaderInfo,
  HeaderName,
  Row,
  MobileButtons,
  TextField,
  Categories,
  CategoriesTitle,
  CategoriesNames,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const ExpenseForm: FC<Props> = ({
  getSelectedCategories,
  className,
  id = 'new',
  onChange,
  onDelete,
  initialValues,
}) => {
  const formId = `expense_${id}`;

  const fromDesktop = useMediaQuery(from.desktop.query);
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const isCreate = !initialValues?.id;

  return (
    <Formik
      onSubmit={onChange}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Container className={className}>
        <Header>
          <BackLink to="/expenses" />
          {!isCreate && fromTabletPortrait && (
            <HeaderInfo>
              <HeaderName>{initialValues.name}</HeaderName>
            </HeaderInfo>
          )}
          <HeaderButtons>
            {!isCreate && fromTabletPortrait && (
              <DeleteButton onClick={onDelete}>
                Eliminar{fromDesktop ? ' Gasto' : ''}
              </DeleteButton>
            )}
          </HeaderButtons>
        </Header>
        <Row>
          <TextField
            readOnly
            name="name"
            label="Nombre"
            id={`${formId}_name`}
          />
          <TextField
            readOnly
            name="description"
            label="Descripcion"
            id={`${formId}_description`}
          />
        </Row>
        <Row>
          <TextField
            readOnly
            name="price"
            type="number"
            label="Precio (cr)"
            id={`${formId}_price`}
          />
          <TextField
            readOnly
            name="quantity"
            type="number"
            label="Cantidad"
            id={`${formId}_quantity`}
          />
        </Row>

        {getSelectedCategories && (
          <Categories role="group">
            <CategoriesTitle>Categorías</CategoriesTitle>
            <CategoriesNames>{getSelectedCategories()}</CategoriesNames>
          </Categories>
        )}

        {!fromTabletPortrait && !isCreate && (
          <MobileButtons>
            <DeleteButton>Eliminar gasto</DeleteButton>
          </MobileButtons>
        )}
      </Container>
    </Formik>
  );
};

export default memo(ExpenseForm);
