import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import {
  permissionsStateVar,
  organizationConfigVar,
  subscriptionStateVar,
} from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import useFileActions from 'apollo/hooks/file/useFileActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { Props } from './types';

const useLogic = (partnerId: Props['partnerId'], files: Props['files']) => {
  const { removeFile, uploadFileFromS3ToDrive } = useFileActions(partnerId);
  const { openUploadFileDialog, openDialog, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const subscription = useReactiveVar(subscriptionStateVar);
  const { hasDriveIntegration } = useReactiveVar(organizationConfigVar);

  const handleOpenFile = useCallback((fileUrl: string) => {
    window.open(fileUrl, '_blank');
  }, []);

  const handleConfirmRemoveFile = useCallback(
    async (fileId: string) => {
      try {
        await removeFile(fileId);
        enqueueSnackbar('El archivo se ha eliminado correctamente', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('file', e.message, 'eliminar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
      close();
    },
    [removeFile, close, enqueueSnackbar],
  );

  const handleRemoveFile = useCallback(
    (fileId: string) => {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar este fichero y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: () => handleConfirmRemoveFile(fileId),
        title: 'Eliminar archivo',
        variant: 'danger',
      });
    },
    [openDialog, handleConfirmRemoveFile],
  );

  const handleConfirmUploadFileToDrive = useCallback(
    async (fileId: string) => {
      try {
        await uploadFileFromS3ToDrive(fileId);
        enqueueSnackbar('El archivo se ha subido correctamente', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('file', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
      close();
    },
    [uploadFileFromS3ToDrive, close, enqueueSnackbar],
  );

  const handleUploadFileToDrive = useCallback(
    (fileId: string) => {
      openDialog({
        acceptButtonText: 'Subir',
        cancelButtonText: 'Cancelar',
        description:
          'Actualmente este archivo se encuentra en los servidores de Inditas, ¿quieres subirlo a Google Drive?',
        onAccept: () => handleConfirmUploadFileToDrive(fileId),
        title: 'Subir archivo a drive',
      });
    },
    [openDialog, handleConfirmUploadFileToDrive],
  );

  const handleOpenUploadFileDialog = useCallback(() => {
    if (
      !hasDriveIntegration &&
      files?.length >= (subscription.maxUserFiles || 5)
    ) {
      openDialog({
        cancelButtonText: 'Cerrar',
        description:
          'Active su integración con drive o mejore su subscripción actual',
        title: 'No se pueden subir más ficheros',
        iconName: 'uploadFile',
      });
    } else {
      openUploadFileDialog({ partnerId });
    }
  }, [
    files,
    hasDriveIntegration,
    openDialog,
    openUploadFileDialog,
    partnerId,
    subscription,
  ]);

  return {
    employeePermissions,
    handleOpenFile,
    handleRemoveFile,
    handleUploadFileToDrive,
    handleOpenUploadFileDialog,
  };
};

export default useLogic;
