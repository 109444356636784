import { FC, memo } from 'react';
import { Container } from './styles';
import type { Props } from './types';

const formatter = Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 2,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const Price: FC<Props> = ({ children: value, showIcon = true, ...props }) => {
  const parts = formatter.formatToParts(value).map(({ value, type }) => {
    if (type === 'group') {
      return ' ';
    }

    if (type === 'decimal') {
      return ',';
    }

    return value;
  });

  return (
    <Container {...props}>
      {parts.join('')} {showIcon && 'cr'}
    </Container>
  );
};

export default memo(Price);
