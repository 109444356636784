import type { FC } from 'react';
import useConnect from './connect';
import {
  Buttons,
  Container,
  Button,
  Description,
  IconCircle,
  Icon,
  Title,
} from './styles';

const GenerateFileDialog: FC = () => {
  const {
    description,
    handleClose,
    iconName,
    isOpen,
    title,
    disableButton,
    handleOnDownload,
    handleUpload,
  } = useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon name={iconName} />
      </IconCircle>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Buttons>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button
          onClick={handleOnDownload}
          disabled={disableButton}
          isLoading={disableButton}
          variant="light"
        >
          Descargar
        </Button>
        <Button
          onClick={handleUpload}
          disabled={disableButton}
          isLoading={disableButton}
          variant="primary"
        >
          Subir
        </Button>
      </Buttons>
    </Container>
  );
};

export default GenerateFileDialog;
