import { FC, memo } from 'react';

import type { Props } from './types';

const Revert: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    baseProfile="tiny"
    version="1.2"
    viewBox="0 0 24 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={size}
    height={size}
    {...rest}
  >
    <path
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      d="M4 9h3.5c.736 0 1.393.391 1.851 1.001a8.11 8.11 0 0 1 1.191-1.662C9.739 7.516 8.676 7 7.5 7H4a1 1 0 1 0 0 2zm7.685 3.111C12.236 10.454 13.941 9 15.334 9h1.838l-1.293 1.293a.999.999 0 1 0 1.414 1.414L21 8l-3.707-3.707a.999.999 0 1 0-1.414 1.414L17.172 7h-1.838c-2.274 0-4.711 1.967-5.547 4.479l-.472 1.411C8.674 14.816 7.243 16 6.5 16H4a1 1 0 1 0 0 2h2.5c1.837 0 3.863-1.925 4.713-4.479l.472-1.41zm4.194 1.182a.999.999 0 0 0 0 1.414L17.172 16h-2.338c-1.268 0-2.33-.891-2.691-2.108a9.335 9.335 0 0 1-1.09 2.185C11.939 17.239 13.296 18 14.834 18h2.338l-1.293 1.293a.999.999 0 1 0 1.414 1.414L21 17l-3.707-3.707a.999.999 0 0 0-1.414 0z"
    />
  </svg>
);

export default memo(Revert);
