import { Section, SectionName } from './types';

const BaseTags = [
  'eliminar',
  'crear',
  'añadir',
  'actualizar',
  'transacciones',
  'estadisticas',
];

const SECTIONS: Section[] = [
  {
    sectionName: SectionName.PARTNERS,
    title: 'Socios',
    description: 'Gestión y control de los socios',
    tags: [
      'socio',
      'socios',
      'descuento',
      'descuentos',
      'lector',
      'rfid',
      ...BaseTags,
    ],
    sections: [
      {
        link: 'Crear',
        title: 'Crear un socio',
        steps: [
          {
            text: 'Puedes crear un socio en la mayoría de vistas desde el botón de acceso rápido "+" situado en la esquina superior derecha.',
            imageUrl: 'images/support/sections/fast-access.png',
            imageAlt: 'se muestra la ubicación del botón de acceso rápido',
          },
          {
            text: 'Una vez abierta la ventana, seleccionamos "Nuevo socio".',
            imageUrl:
              'images/support/sections/partners/create-partner-option.png',
            imageAlt: 'se muestra la opción de crear un socio',
          },
          {
            text: 'Una vez abierta la ventana, debes rellenar todos los campos obligatorios, seleccionar el socio avalador y pulsas el botón de crear.',
            imageUrl: 'images/support/sections/partners/partner-form.png',
            imageAlt: 'se muestra el formulario de nuevo socio',
          },
        ],
      },
      {
        link: 'Eliminar',
        title: 'Eliminar un socio',
        steps: [
          {
            text: 'Puedes eliminar un socio desde la vista principal del socio pulsando el botón "..." situado en la esquina superior derecha.',
            imageUrl: 'images/support/sections/partners/advanced-menu.png',
            imageAlt: 'se muestra la ubicación del botón de opciones del socio',
          },
          {
            text: 'Una vez abierta la ventana, selecciona "Eliminar socio".',
            imageUrl: 'images/support/sections/partners/remove-button.png',
            imageAlt: 'se muestra la opción de eliminar el socio',
          },
          {
            text: 'Debes confirmar esta acción ya que no es reversible, las transacciones del socio quedarán registradas durante 6 meses.',
            imageUrl: 'images/support/sections/partners/confirm-remove.png',
            imageAlt: 'se muestra la ventana para confirmar la eliminación',
          },
        ],
      },
      {
        link: 'Actualizar',
        title: 'Actualizar un socio',
        steps: [
          {
            text: 'Puedes actualizar un socio desde la vista principal del socio pulsando el botón "Editar".',
            imageUrl: 'images/support/sections/partners/edit-button.png',
            imageAlt: 'se muestra la ubicación del botón de edición del socio',
          },
          {
            text: 'Debes introducir el pin de bloqueo para acceder a los datos sensibles del socio e actualizarlos.',
            imageUrl: 'images/support/sections/partners/block-pin.png',
            imageAlt: 'se muestra la ventana del pin de bloqueo',
          },
          {
            text: 'Una vez desbloqueada la vista, puedes cambiar los datos del socio y pulsar el botón de "Guardar".',
            imageUrl: 'images/support/sections/partners/save-profile.png',
            imageAlt: 'se muestra el formulario de edición del socio',
          },
        ],
      },
      {
        link: 'Descuentos',
        title: 'Asignar descuentos al socio',
        steps: [
          {
            text: 'Puedes asignar descuentos al socio que aparecerán siempre preseleccionados a la hora de asignar una cuota o realizar un pedido. Puedes asignarlos desde la vista principal del socio pulsando el botón "..." situado en la esquina superior derecha.',
            imageUrl: 'images/support/sections/partners/advanced-menu.png',
            imageAlt: 'se muestra la ubicación del botón de opciones del socio',
          },
          {
            text: 'Una vez abierta la ventana, selecciona "Asignar descuentos".',
            imageUrl:
              'images/support/sections/partners/assign-discounts-button.png',
            imageAlt:
              'se muestra la opción de asignación de descuentos al socio',
          },
          {
            text: 'Una vez abierta la ventana, selecciona tantos descuentos como necesites, junto al nombre del descuento se indica si es de tipo cuota o producto.',
            imageUrl: 'images/support/sections/partners/assign-discount.png',
            imageAlt: 'se muestra la vista de asignación de descuentos',
          },
        ],
      },
      {
        link: 'Documentos',
        title: 'Generar y asignar documento al socio',
        steps: [
          {
            text: 'Para generar los documentos creados en la sección de documentos de la organización debes seleccionarlos desde la vista principal del socio pulsando el botón "..." situado en la esquina superior derecha.',
            imageUrl: 'images/support/sections/partners/advanced-menu.png',
            imageAlt: 'se muestra la ubicación del botón de opciones del socio',
          },
          {
            text: 'Una vez abierta la ventana, selecciona "Generar documento".',
            imageUrl:
              'images/support/sections/partners/generate-doc-option.png',
            imageAlt:
              'se muestra la opción de generación de documento al socio',
          },
          {
            text: 'Una vez abierta la ventana, selecciona el documento que deseas generar. Si no ves ninguno debes crear uno en la configuración de la organización.',
            imageUrl: 'images/support/sections/partners/select-document.png',
            imageAlt: 'se muestra la vista de selección de documentos',
          },
          {
            text: 'Una vez seleccionado el documento y rellenado todos los campos opcionales configurados, podrás generarlo.',
            imageUrl: 'images/support/sections/partners/generate-document.png',
            imageAlt: 'se muestra la vista de selección de documentos',
          },
        ],
      },
      {
        link: 'Notas',
        title: 'Notas del socio',
        steps: [
          {
            text: 'Las notas son recordatorios sobre el socio, donde puedes activar una notificación que salta cada vez que entras al perfil del socio. Para crearlas debes navegar a la sección de notas en el perfil del socio.',
            imageUrl: 'images/support/sections/partners/notes.png',
            imageAlt: 'muestra el botón para navegar a la sección de notas',
          },
          {
            text: 'Las notas con notificación saltarán al entrar en el perfil del socio. Puedes desactivarla, eliminarla o incluso editarla.',
            imageUrl: 'images/support/sections/partners/activated-note.png',
            imageAlt: 'muestra una nota activada',
          },
        ],
      },
      {
        link: 'Registros',
        title: 'Registros del socio',
        steps: [
          {
            text: 'En la sección de registros puedes ver las fechas de entrada del socio. Puedes ver ese historial en la sección de registros en el perfil del socio.',
            imageUrl: 'images/support/sections/partners/registers.png',
            imageAlt: 'muestra el botón para navegar a la sección de registros',
          },
          {
            text: 'Para realizar un registro solo debes pulsar el icono de reloj situado debajo del avatar del socio. Al realizar un pedido se realizará un registro automático.',
            imageUrl: 'images/support/sections/partners/apply-register.png',
            imageAlt: 'muestra el botón para realizar un registro',
          },
        ],
      },
      {
        link: 'Transacciones',
        title: 'Transacciones y movimientos del socio',
        steps: [
          {
            text: 'En la sección de transacciones puedes ver todos los movimientos del socio como, compras, movimientos de créditos y cuotas. Puedes aplicar filtro de fechas y tipo de transacción. Puedes ver ese historial en la sección de transacciones en el perfil del socio.',
            imageUrl: 'images/support/sections/partners/transactions.png',
            imageAlt:
              'muestra el botón para navegar a la sección de transacciones',
          },
          {
            text: 'Desde esta sección puedes ver la lista de productos del pedido o incluso realizar acciónes como revertir la transacción.',
            imageUrl: 'images/support/sections/partners/revert-transaction.png',
            imageAlt: 'muestra el botón para revertir una transacción',
          },
        ],
      },
    ],
  },
  {
    sectionName: SectionName.PRODUCTS,
    title: 'Productos',
    description: 'Gestión y control de los productos',
    tags: ['producto', 'productos', ...BaseTags],
    sections: [
      {
        link: 'Crear',
        title: 'Crear un producto',
        steps: [
          {
            text: 'Puedes crear un producto en la mayoría de vistas desde el botón de acceso rápido "+" situado en la esquina superior derecha.',
            imageUrl: 'images/support/sections/fast-access.png',
            imageAlt: 'se muestra la ubicación del botón de acceso rápido',
          },
          {
            text: 'Una vez abierta la ventana, seleccionamos "Nuevo producto".',
            imageUrl:
              'images/support/sections/products/create-product-option.png',
            imageAlt: 'se muestra la opción de crear un producto',
          },
          {
            text: 'Una vez abierta la ventana, debes rellenar todos los campos obligatorios y pulsas el botón de crear.',
            imageUrl: 'images/support/sections/products/product-form.png',
            imageAlt: 'se muestra el formulario de nuevo producto',
          },
        ],
      },
      {
        link: 'Actualizar',
        title: 'Actualizar un producto',
        steps: [
          {
            text: 'Para actualizar un producto debes seleccionarlo pulsando el botón "Editar" junto al buscador en la sección de productos.',
            imageUrl: 'images/support/sections/products/active-edition.png',
            imageAlt:
              'se muestra la ubicación del botón para activar la edición de los productos',
          },
          {
            text: 'En cuanto los productos están en modo "edición" ya puedes seleccionar el producto.',
            imageUrl: 'images/support/sections/products/select-product.png',
            imageAlt:
              'se muestra la lista de productos con opción seleccionable',
          },
          {
            text: 'Una vez en la vista principal, puedes cambiar los datos del producto y pulsar el botón de "Guardar". Recuerda que cambiar el nombre o el precio de compra o venta del producto puede afectar a las estadísticas',
            imageUrl: 'images/support/sections/products/edit-prodcut.png',
            imageAlt: 'se muestra el formulario de edición del producto',
          },
        ],
      },
      {
        link: 'Eliminar',
        title: 'Eliminar un producto',
        steps: [
          {
            text: 'Puedes eliminar un producto desde la vista principal del producto pulsando el botón "..." situado en la esquina superior derecha.',
            imageUrl: 'images/support/sections/products/advanced-menu.png',
            imageAlt:
              'se muestra la ubicación del botón de opciones del producto',
          },
          {
            text: 'Una vez abierta la ventana, selecciona "Eliminar producto".',
            imageUrl: 'images/support/sections/products/remove-button.png',
            imageAlt: 'se muestra la opción de eliminar el producto',
          },
          {
            text: 'Debes confirmar esta acción ya que no es reversible, las transacciones del producto quedarán registradas durante 6 meses.',
            imageUrl: 'images/support/sections/products/confirm-remove.png',
            imageAlt: 'se muestra la ventana para confirmar la eliminación',
          },
        ],
      },
      {
        link: 'Categorías',
        title: 'Gestión de las categorías del producto',
        steps: [
          {
            text: 'Puedes asignar categorías en el momento de creación del producto, pulsando en "Seleccionar Categorías"',
            imageUrl: 'images/support/sections/products/select-categories.png',
            imageAlt:
              'se muestra la ubicación del botón de para asignar categorías al producto desde el formulario de creación',
          },
          {
            text: 'Una vez abierta la ventana de selección de categorías, puedes marcar todas las categorías necesarias y luego hacer clic en "aceptar" cuando termines.',
            imageUrl:
              'images/support/sections/products/selected-categories.png',
            imageAlt:
              'se muestra la vista de categorías disponibles para el producto',
          },
          {
            text: 'Si el producto ya está creado, podemos editar sus categorías desde la vista principal del producto haciendo clic en "Categorías".',
            imageUrl:
              'images/support/sections/products/categories-navigation.png',
            imageAlt:
              'se muestra la opción de navegación a la sección de las categorías del producto',
          },
          {
            text: 'Desde esta vista puedes editar las categorías relacionadas al producto. Para salvar la información debes hacer click en el botón de "Guardar".',
            imageUrl: 'images/support/sections/products/categories-section.png',
            imageAlt: 'se muestra la sección de las categorías del producto',
          },
        ],
      },
      {
        link: 'Transacciones',
        title: 'Transacciones del producto',
        steps: [
          {
            text: 'Podemos visualizar las transacciones desde la vista principal del producto haciendo clic en "Transacciones".',
            imageUrl:
              'images/support/sections/products/transactions-navigation.png',
            imageAlt:
              'se muestra la opción de navegación a la sección de las transacciones del producto',
          },
          {
            text: 'Desde esta vista puedes visualizar las transacciones relacionadas al producto por fechas.',
            imageUrl:
              'images/support/sections/products/transactions-section.png',
            imageAlt: 'se muestra la sección de las transacciones del producto',
          },
          {
            text: 'Se recogen datos como el empleado que ha realizado la acción, el socio al que se ha aplicado, la fecha de creación y la cantidad total. Puedes acceder tanto al perfil del empleado como al del socio haciendo clic en sus nombres. ',
            imageUrl:
              'images/support/sections/products/transactions-details.png',
            imageAlt:
              'se muestra los detalles de las transacciones del producto',
          },
        ],
      },
      {
        link: 'Estadísticas',
        title: 'Estadísticas del producto',
        steps: [],
      },
      {
        link: 'Ajustes',
        title: 'Ajustes del producto',
        steps: [],
      },
    ],
  },
  {
    sectionName: SectionName.ORDERS,
    title: 'Pedidos',
    description: 'Existen varias formas de realizar una venta a un socio.',
    tags: ['pedido', 'pedidos', 'ventas', 'revertir', 'cancelar'],
    sections: [],
  },
  {
    sectionName: SectionName.EMPLOYEES,
    title: 'Empleados',
    description: 'Gestión y control de los empleados',
    tags: ['empleado', 'empleados', ...BaseTags],
    sections: [],
  },
  {
    sectionName: SectionName.CREDITS,
    title: 'Créditos',
    description: 'Existen varias formas de añadir créditos a un socio.',
    tags: ['creditos', 'añadir', 'recargar', 'retirar'],
    sections: [],
  },
  {
    sectionName: SectionName.QUOTAS,
    title: 'Cuotas',
    description: 'Manejar las cuotas de tus socios',
    tags: [
      'cuota',
      'cuotas',
      'eliminar',
      'crear',
      'añadir',
      'actualizar',
      'asignar',
    ],
    sections: [],
  },
  {
    sectionName: SectionName.DISCOUNTS,
    title: 'Descuentos',
    description: 'Manejar los descuentos de tu organización',
    tags: [
      'descuento',
      'descuentos',
      'eliminar',
      'crear',
      'añadir',
      'actualizar',
    ],
    sections: [],
  },
  {
    sectionName: SectionName.CASHIER,
    title: 'Caja',
    description: 'Gestión de caja',
    tags: ['caja', 'cerrar', 'abrir'],
    sections: [],
  },
  {
    sectionName: SectionName.EXPENSES,
    title: 'Gastos',
    description: 'Gestión y control de los gastos',
    tags: ['gasto', 'gastos', 'crear', 'añadir', 'actualizar'],
    sections: [],
  },
  {
    sectionName: SectionName.DOCUMENTS,
    title: 'Documentos',
    description: 'Gestión y control de los documentos',
    tags: [
      'documento',
      'documentos',
      'archivo',
      'archivos',
      'crear',
      'añadir',
      'actualizar',
    ],
    sections: [],
  },
  {
    sectionName: SectionName.MENUS,
    title: 'Catálogo personalizado',
    description: 'Gestión y control de los catálogos de productos',
    tags: ['menu', 'menus', 'catalogo', 'crear', 'diseñar', 'actualizar'],
    sections: [],
  },
  {
    sectionName: SectionName.CONFIGURATION,
    title: 'Configuración',
    description: 'Gestión de la organización',
    tags: [],
    sections: [],
  },
];

export default SECTIONS;
