import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import { Balance, Container, Identifier, State, PartnerNum } from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  const isTabletPortrait = !useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <State>Estado</State>
      <PartnerNum>Nº de socio</PartnerNum>
      {!isTabletPortrait && <Identifier>Cantidad</Identifier>}
      <Balance>Precio</Balance>
    </Container>
  );
};

export default memo(Row);
