import { useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { organizationConfigVar } from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import usePartnerFormConfig from 'apollo/hooks/organization/usePartnerFormConfig';
import usePartnerFormActions from 'apollo/hooks/organization/usePartnerFormActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';

const BASE_URL = `https://www.inditas.com/new/partner`;

const useConnect = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { partnerFormConfig, getPartnerFormConfigLoading } =
    usePartnerFormConfig();
  const { setPartnerFormConfig } = usePartnerFormActions();
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const { openGenerateQRDialog } = useModal();

  const initialValues = useMemo(
    () => ({
      code: partnerFormConfig?.code
        ? `${BASE_URL}/${partnerFormConfig.code}`
        : '',
      isActive: partnerFormConfig?.isActive || false,
    }),
    [partnerFormConfig],
  );

  const handleOnSubmit = useCallback(
    async (data: { code: string; isActive: boolean }) => {
      try {
        await setPartnerFormConfig(data);
        enqueueSnackbar('La configuración se ha guardado correctamente', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('organization', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, setPartnerFormConfig],
  );

  const handleCopyLink = useCallback(
    async (url: string) => {
      try {
        await navigator.clipboard.writeText(url);
        enqueueSnackbar('Se ha copiado el link', {
          variant: 'info',
        });
      } catch (e) {
        enqueueSnackbar('Ha ocurrido un error al copiar el link', {
          variant: 'warning',
        });
      }
    },
    [enqueueSnackbar],
  );

  const handleOpenQRModal = useCallback(async () => {
    openGenerateQRDialog({ value: initialValues.code });
  }, [openGenerateQRDialog, initialValues]);

  return {
    BASE_URL,
    handleCopyLink,
    handleOnSubmit,
    initialValues,
    isLoading: getPartnerFormConfigLoading,
    organizationConfig,
    handleOpenQRModal,
  };
};

export default useConnect;
