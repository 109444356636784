import { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive';
import { BackLink, Header, HeaderInfo, HeaderName, Button } from './styles';
import type { Props } from './types';

const CategoryHeader: FC<Props> = ({ name, onDelete }) => {
  const employeePermissions = useReactiveVar(permissionsStateVar);

  return (
    <>
      <Header>
        <BackLink to="/organization/categories" />
        <HeaderInfo>
          <HeaderName>{name}</HeaderName>
        </HeaderInfo>
        {employeePermissions.allowRemoveCategories && (
          <Button variant="danger" onClick={onDelete}>
            Eliminar
          </Button>
        )}
      </Header>
    </>
  );
};

export default memo(CategoryHeader);
