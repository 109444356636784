import { useCallback } from 'react';
import { CashierQuota } from 'model/Cashier';
import useModal from 'apollo/hooks/useModal';

const useLogic = () => {
  const { openCashierPartners } = useModal();

  const handleShowPartners = useCallback(
    (partners: CashierQuota['partners']) => {
      openCashierPartners({ partners });
    },
    [openCashierPartners],
  );

  return {
    handleShowPartners,
  };
};

export default useLogic;
