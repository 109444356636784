import styled, { css } from 'styled-components';
import DefaultDownIcon from 'components/Icon/Icons/Down';
import DefaultUpIcon from 'components/Icon/Icons/Up';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Billing = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.billing};
  ${TextEllipsis};
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 1 ${TABLE_METRICS.name};
  ${TextEllipsis};
`;

export const Movement = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.movements};
  ${TextEllipsis};
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: right;
  flex: 0 0 ${TABLE_METRICS.date};
  ${TextEllipsis};
`;

export const Chevron = styled.div`
  text-align: right;
  padding-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.chevron};
`;

export const DownIcon = styled(DefaultDownIcon)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const UpIcon = styled(DefaultUpIcon)`
  color: ${({ theme }) => theme.colors.green};
`;
