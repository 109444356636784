import type { FC } from 'react';
import { Formik } from 'formik';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  Container,
  IconCircle,
  Icon,
  Title,
  Form,
  TextField,
  Price,
  Select,
  EmptyConcept,
  EmptyConceptMsg,
  LinkToOrganization,
} from './styles';

const ManageCredits: FC = () => {
  const {
    action,
    concepts,
    currentCredits,
    isOpen,
    handleSubmit,
    handleGoToOrganization,
    isLoading,
    handleClose,
  } = useConnect();

  const isAddAction = action === 'added';

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon name={isAddAction ? 'cr' : 'coin'} />
      </IconCircle>
      <Title>
        Créditos actuales{' '}
        <Price $credits={currentCredits}>{currentCredits}</Price>
      </Title>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ credits: '' as unknown as number }}
        validateOnBlur={false}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <TextField
              key="credits"
              id="credits"
              label={isAddAction ? 'Créditos' : 'Créditos a Retirar'}
              name="credits"
              autoFocus
              type="number"
            />
            {concepts.length > 0 ? (
              <Select
                name="concept"
                label="Concepto"
                id="concept"
                options={concepts}
              />
            ) : (
              <EmptyConcept>
                <EmptyConceptMsg>
                  No hay creado ningún concepto. Puede crear uno desde la{' '}
                  <LinkToOrganization onClick={handleGoToOrganization}>
                    configuración
                  </LinkToOrganization>
                </EmptyConceptMsg>
              </EmptyConcept>
            )}

            <Buttons>
              <CancelButton onClick={handleClose}>Cerrar</CancelButton>
              <AcceptButton
                type="submit"
                disabled={values.credits <= 0 || values.credits > 1000}
                isLoading={isLoading}
              >
                {isAddAction ? 'Añadir' : 'Retirar'}
              </AcceptButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ManageCredits;
