import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import useModal from 'apollo/hooks/useModal';
import { organizationConfigVar } from 'apollo/reactive';
import {
  AddIcon,
  Button,
  Buttons,
  Container,
  CreateButton,
  HeaderBar,
  Icon,
  Logo,
  RfidIcon,
  Title,
  TitleContainer,
} from './styles';
import type { Props } from './types';

const Header: FC<Props> = ({
  buttonText,
  buttonVariant,
  className,
  children,
  title,
  onClickButton,
  buttonSize,
  onReturn,
  showGlobalCreateButton = true,
  showRfidButton = true,
}) => {
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const { openCreateMenu, openRfidDialog } = useModal();
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <HeaderBar>
        <TitleContainer>
          {onReturn && <Icon onClick={onReturn} name="arrowLeft" />}
          <Title>{title}</Title>
        </TitleContainer>
        <Logo />
        <Buttons>
          {onClickButton && buttonText && (
            <Button
              $buttonSize={buttonSize}
              variant={buttonVariant}
              onClick={onClickButton}
            >
              {buttonText}
            </Button>
          )}

          {organizationConfig.activateRfidReader &&
            showRfidButton &&
            fromTabletPortrait && (
              <CreateButton onClick={openRfidDialog}>
                <RfidIcon />
              </CreateButton>
            )}
          {showGlobalCreateButton && (
            <CreateButton onClick={openCreateMenu}>
              <AddIcon />
            </CreateButton>
          )}
        </Buttons>
      </HeaderBar>
      {children}
    </Container>
  );
};

export default Header;
export { HEADER_HEIGHT, HEADER_CHILDREN_MARGIN } from './styles';
