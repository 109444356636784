import Cookie from 'js-cookie';

export const TOKEN_KEY = 'inditastoken';
export const COOKIE_KEY = 'STYXKEY-token';

const cookieOptions = {
  domain: 'inditas.com',
  expires: 365,
};

export const deleteToken = () => {
  Cookie.remove(COOKIE_KEY, cookieOptions);
  return localStorage.removeItem(TOKEN_KEY);
};

export const getToken = () =>
  Cookie.get(COOKIE_KEY) || localStorage.getItem(TOKEN_KEY);

export const setToken = (token: string) => {
  Cookie.set(COOKIE_KEY, token, cookieOptions);
  return localStorage.setItem(TOKEN_KEY, token);
};
