import styled from 'styled-components';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';
import { List as DefaultList } from 'react-virtualized';

export const Container = styled.div``;

export const Content = styled.div``;

export const Main = styled(SectionContainer)`
  padding-top: 1rem;
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const ListContent = styled.div`
  margin-top: 3rem;

  ${from.tabletPortrait`
    margin-top: 1rem;
  `};
`;

export const List = styled(DefaultList)`
  outline: none;
`;
