import { useCallback } from 'react';

import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, storeProductOptionsDialogPayload, type } = useModal();

  const { onAcceptRemove, onAcceptMovement, onAcceptAdd } =
    storeProductOptionsDialogPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleAcceptRemove = useCallback(() => {
    if (!onAcceptRemove) return;
    onAcceptRemove();
  }, [onAcceptRemove]);

  const handleAcceptMovement = useCallback(() => {
    if (!onAcceptMovement) return;
    onAcceptMovement();
  }, [onAcceptMovement]);

  const handleAcceptAdd = useCallback(() => {
    if (!onAcceptAdd) return;
    onAcceptAdd();
  }, [onAcceptAdd]);

  return {
    handleAcceptRemove,
    handleAcceptMovement,
    handleAcceptAdd,
    handleClose,
    isOpen: type === ModalType.STORE_PRODUCT_OPTIONS_DIALOG,
  };
};

export default useConnect;
