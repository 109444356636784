import { makeVar } from '@apollo/client';
import { Provider } from 'model/Provider';

export type ExpenseStateVarData = {
  categoryIds?: string[];
  description?: string;
  name?: string;
  price?: number;
  providerId?: Provider['id'];
  providerName?: Provider['name'];
  quantity?: number;
  taxes?: number;
};

export const emptyExpenseState: ExpenseStateVarData = {
  categoryIds: [],
  description: '',
  name: '',
  price: 0,
  providerId: '',
  providerName: '',
  quantity: 0,
  taxes: 0,
};

export const expenseStateVar = makeVar<ExpenseStateVarData>(emptyExpenseState);

export const resetExpenseStateVar = () => {
  expenseStateVar(emptyExpenseState);
};

export const setExpenseStateVar = (data: Partial<ExpenseStateVarData>) => {
  expenseStateVar({ ...emptyExpenseState, ...data });
};

export const updateExpenseStateVar = (data: Partial<ExpenseStateVarData>) => {
  expenseStateVar({ ...expenseStateVar(), ...data });
};
