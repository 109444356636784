import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useEmployeeActions from 'apollo/hooks/employee/useEmployeeActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { WorkScheduleFormFields } from 'model/Employee';

const useConnect = () => {
  const { close, addWorkSchedulePayload, type } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { addScheduleToEmployee, loading: employeeActionLoading } =
    useEmployeeActions();
  const { employeeId } = addWorkSchedulePayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback(
    async (values: WorkScheduleFormFields) => {
      try {
        if (employeeId) {
          await addScheduleToEmployee({
            employeeId,
            input: values,
          });
          enqueueSnackbar('Se ha creado el tramo horario correctamente', {
            variant: 'success',
          });
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('employee', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [addScheduleToEmployee, employeeId, enqueueSnackbar, handleClose],
  );

  return {
    handleClose,
    handleSubmit,
    isOpen: type === ModalType.ADD_WORK_SCHEDULE,
    isLoading: employeeActionLoading,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
