import styled from 'styled-components';
import { Form } from 'formik';
import DefaultButton from 'components/Button';
import { from } from 'styles/responsive';
import DefaultImageUploader from 'components/Inputs/ImageUploader';

export const Container = styled(Form)``;

export const Section = styled.section`
  margin-bottom: 1.5rem;
`;

export const SectionContent = styled.div`
  display: block;

  ${from.tabletPortrait`
    display: flex;
  `};
`;

export const ImageUploader = styled(DefaultImageUploader)`
  max-height: 16rem;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${from.tabletPortrait`
    flex: 0 1 50%;
    margin-bottom: 0;
    max-width: 20rem;
    width: unset;
  `};
`;

export const SubmitButton = styled(DefaultButton)`
  &&& {
    margin-bottom: 3rem;
    width: 100%;

    ${from.tabletPortrait`
      width: unset;
  `}
  }
`;
