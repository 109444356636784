import styled from 'styled-components';
import DefaultArrowRightIcon from 'components/Icon/Icons/ArrowRight';
import DefaultLink from 'components/Link';

export const Container = styled.div``;

export const ChevronIcon = styled(DefaultArrowRightIcon)`
  width: 1.5rem;
  opacity: 0.7;
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.6rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin-top: 0.3rem;
`;

export const Header = styled(DefaultLink)`
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 2rem;
  justify-content: space-between;
  width: 100%;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
    ${ChevronIcon} {
      opacity: 1;
    }
    ${Subtitle} {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const ExtraTitle = styled.span`
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const Table = styled.div``;
