import type { FC } from 'react';
import { Formik } from 'formik';
import Loader from 'components/Loader';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  Container,
  IconCircle,
  Icon,
  Title,
  Form,
  TextField,
  GreenIconCircle,
  RequestMsg,
} from './styles';
import { validationSchema } from './constants';

const NewRequestTicket: FC = () => {
  const {
    isOpen,
    handleOnClose,
    handleSubmit,
    isLoading,
    showSendMsg,
    getMeLoading,
  } = useConnect();

  if (getMeLoading) {
    return <Loader />;
  }

  return (
    <Container disableFullScreen open={isOpen} onClose={handleOnClose}>
      {showSendMsg ? (
        <>
          <GreenIconCircle>
            <Icon name="check" />
          </GreenIconCircle>
          <RequestMsg>
            La solicitud a sido registrada, nos pondremos en contacto lo antes
            posible. Gracias por confiar en Inditas
          </RequestMsg>
          <CancelButton onClick={handleOnClose}>Cerrar</CancelButton>
        </>
      ) : (
        <>
          <IconCircle>
            <Icon name="help" />
          </IconCircle>
          <Title>Ayudanos a mejorar la plataforma</Title>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ description: '' }}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, isValid }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  placeholder="Nota..."
                  name="description"
                  label="Nota"
                  isMultiline
                  rows={7}
                  id="description"
                />
                <Buttons>
                  <CancelButton onClick={handleOnClose}>Cerrar</CancelButton>
                  <AcceptButton
                    type="submit"
                    disabled={!isValid}
                    isLoading={isLoading}
                  >
                    Enviar
                  </AcceptButton>
                </Buttons>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Container>
  );
};

export default NewRequestTicket;
