import DefaultButton from 'components/Button';
import DefaultTextField from 'components/Inputs/TextField';
import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultLogo from 'components/Logo';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 34.5rem;
  margin: 0 auto;
  padding: 1rem !important;

  ${from.tabletPortrait} {
    padding: 0;
  }
`;

export const Logo = styled(DefaultLogo)`
  width: 15rem;
`;

export const Title = styled.h1`
  color: #000000;
  font-size: 6rem;
  letter-spacing: 0.125rem;
  line-height: 1;
  padding-top: 3.875rem;
  padding-bottom: 2.375rem;
  text-transform: uppercase;
  text-align: center;
  ${from.tabletPortrait} {
    font-size: 3rem;
    padding-top: 3.4375rem;
    padding-bottom: 1.1875rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TextField = styled(DefaultTextField)`
  margin-bottom: 0.5rem;
`;

export const Submit = styled(DefaultButton)`
  &&& {
    margin-top: 0.25rem;
    width: 100%;
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.55;
  margin: 0.5rem;
  text-align: center;
`;
