import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PARTNER_FORM_CONFIG } from 'apollo/queries/organizations';
import { normalizeOrganizationPartnerFormConfig } from 'model/Organization';
import type { GetPartnerFormConfigQuery } from 'apollo/graphql.types';

const usePartnerFormConfig = () => {
  const { data, loading } = useQuery<GetPartnerFormConfigQuery>(
    GET_PARTNER_FORM_CONFIG,
  );

  const partnerFormConfig = useMemo(
    () =>
      data?.getPartnerFormConfig
        ? normalizeOrganizationPartnerFormConfig(data.getPartnerFormConfig)
        : undefined,
    [data],
  );

  return {
    getPartnerFormConfigLoading: loading,
    partnerFormConfig,
  };
};

export default usePartnerFormConfig;
