import { useMemo } from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_PERMISSIONS_GROUP } from 'apollo/queries/permissionsGroup';
import { normalizePermissions } from 'model/PermissionsGroup';
import type {
  GetPermissionsGroupQuery,
  GetPermissionsGroupQueryVariables,
} from 'apollo/graphql.types';

const usePermissionsGroup = ({
  id,
  fetchPolicy = 'cache-and-network',
}: {
  id?: string;
  fetchPolicy?: QueryHookOptions<GetPermissionsGroupQueryVariables>['fetchPolicy'];
}) => {
  const { data, loading, refetch } = useQuery<
    GetPermissionsGroupQuery,
    GetPermissionsGroupQueryVariables
  >(GET_PERMISSIONS_GROUP, {
    fetchPolicy,
    skip: !id,
    variables: { id: id! },
  });

  const permissionsGroup = useMemo(
    () =>
      data?.getPermissionsGroup
        ? normalizePermissions(data.getPermissionsGroup)
        : undefined,
    [data],
  );

  return {
    getPermissionGroupLoading: loading,
    permissionsGroup,
    refetch,
  };
};

export default usePermissionsGroup;
