export enum SectionName {
  PARTNERS = 'partners',
  PRODUCTS = 'products',
  ORDERS = 'orders',
  EMPLOYEES = 'employees',
  CREDITS = 'credits',
  QUOTAS = 'quotas',
  DISCOUNTS = 'discounts',
  CASHIER = 'cashier',
  EXPENSES = 'expenses',
  DOCUMENTS = 'documents',
  CONFIGURATION = 'configuration',
  MENUS = 'menus',
}

export type Section = {
  sectionName: SectionName;
  title: string;
  description: string;
  tags: string[];
  sections: Array<{
    link: string;
    title: string;
    steps: Array<{
      text: string;
      imageUrl: string;
      imageAlt: string;
    }>;
  }>;
};
