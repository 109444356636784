import type { FC } from 'react';
import Loader from 'components/Loader';
import { UserRole } from 'apollo/generated/globalTypes';
import OrganizationHeader from './Header';
import useConnect from './connect';
import { Container, Content, SectionLink, LinkContentTitle } from './styles';

const Organization: FC = () => {
  const {
    employeePermissions,
    handleUpdateAvatar,
    initialValues,
    isLoading,
    openCreateMenu,
    subscription,
    userRole,
    organizationConfig,
  } = useConnect();
  const isAdmin = userRole === UserRole.ADMIN;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <OrganizationHeader
        initialValues={initialValues}
        onSubmitAvatar={handleUpdateAvatar}
        openCreateMenu={openCreateMenu}
      />
      <Content>
        <LinkContentTitle>Productos</LinkContentTitle>
        {(employeePermissions.allowAdjustProducts ||
          employeePermissions.allowShowAdjustProducts) && (
          <>
            <SectionLink
              toLink="/organization/stock"
              title="Ajuste de Stock"
              border="bottom"
            />
            <SectionLink
              toLink="/organization/stock-history"
              title="Histórico de Stock"
              border="bottom"
            />
            <SectionLink
              toLink="/organization/store-history"
              title="Histórico de Almacén"
              border="bottom"
            />
          </>
        )}
        {isAdmin && organizationConfig.activateProductReference && (
          <SectionLink
            toLink="/organization/products-by-reference"
            title="Productos por referencia"
            border="bottom"
          />
        )}

        <LinkContentTitle>Entidades</LinkContentTitle>
        <SectionLink
          toLink="/organization/categories"
          title="Categorías"
          border="bottom"
        />
        {employeePermissions.allowShowQuotas && (
          <SectionLink
            toLink="/organization/quotas"
            title="Cuotas"
            border="bottom"
          />
        )}
        {subscription.allowedDiscounts &&
          employeePermissions.allowShowDiscounts && (
            <SectionLink
              toLink="/organization/discounts"
              title="Descuentos"
              border="bottom"
            />
          )}
        {employeePermissions.allowShowCreditsConcept && (
          <SectionLink
            toLink="/organization/credits-concepts"
            title="Conceptos de donación"
            border="bottom"
          />
        )}
        {subscription.allowedExpenses &&
          employeePermissions.allowShowProviders && (
            <SectionLink
              toLink="/organization/providers"
              title="Proveedores"
              border="bottom"
            />
          )}
        {subscription.allowedGenerateFiles &&
          employeePermissions.allowShowContract && (
            <SectionLink
              toLink="/organization/documents"
              title="Documentos"
              border="bottom"
            />
          )}

        <LinkContentTitle>Transacciones</LinkContentTitle>
        {employeePermissions.allowShowTransactionsHistory && (
          <SectionLink
            toLink="/organization/transactions-history"
            title="Histórico de transacciones"
            border="bottom"
          />
        )}

        {isAdmin && (
          <>
            <LinkContentTitle>Organización</LinkContentTitle>
            <SectionLink
              toLink="/organization/cashier-history"
              title="Histórico de caja"
              border="bottom"
            />
            <SectionLink
              toLink="/organization/menus"
              title="Menus"
              border="bottom"
            />
            <SectionLink
              toLink="/organization/partners-requests"
              title="Solicitudes de nuevos socios"
              border="bottom"
            />
            <SectionLink
              toLink="/organization/permissions"
              title="Permisos"
              border="bottom"
            />
            <SectionLink
              toLink="/organization/new-partner-by-link"
              title="Formulario de socio mediante link"
              border="bottom"
            />
            <SectionLink
              toLink="/organization/configuration"
              title="Configuración"
              border="bottom"
            />
          </>
        )}
      </Content>
    </Container>
  );
};

export default Organization;
