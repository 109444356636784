import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { CANCEL_PURCHASE, CREATE_PURCHASE } from 'apollo/mutations';
import { normalizePurchase } from 'model/Purchase';
import type {
  CreatePurchaseMutation,
  CreatePurchaseMutationVariables,
  CancelPurchaseMutation,
  CancelPurchaseMutationVariables,
  CreatePurchaseDto,
} from 'apollo/graphql.types';

const usePurchaseActions = () => {
  const [createPurchaseMutation, { loading: isLoadingCreatePurchase }] =
    useMutation<CreatePurchaseMutation, CreatePurchaseMutationVariables>(
      CREATE_PURCHASE,
    );
  const [cancelPurchaseMutation, { loading: isLoadingCancelPurchase }] =
    useMutation<CancelPurchaseMutation, CancelPurchaseMutationVariables>(
      CANCEL_PURCHASE,
    );

  const normalizeFormValues = useCallback(
    (orders: CreatePurchaseDto['orders']) =>
      orders.map((order) => ({
        productId: order.productId,
        quantity: Number(order.quantity),
        extraQuantity: order?.extraQuantity ? Number(order.extraQuantity) : 0,
        productPrice: Number(order.productPrice),
        productDiscountName: order.productDiscountName || '',
        productWithoutDiscountPrice: order.productWithoutDiscountPrice
          ? Number(order.productWithoutDiscountPrice)
          : Number(order.productPrice),
      })),
    [],
  );

  const createPurchase = useCallback(
    async ({ partnerId, orders, discountsIds }: CreatePurchaseDto) => {
      const normalizedOrders = normalizeFormValues(orders);
      const { data } = await createPurchaseMutation({
        variables: {
          data: {
            partnerId,
            discountsIds,
            orders: normalizedOrders,
          },
        },
      });

      if (data?.createPurchase) {
        return normalizePurchase(data.createPurchase);
      }
    },
    [createPurchaseMutation, normalizeFormValues],
  );

  const cancelPurchase = useCallback(
    async (purchaseId: string) => {
      await cancelPurchaseMutation({
        variables: { purchaseId },
      });
    },
    [cancelPurchaseMutation],
  );

  return {
    cancelPurchase,
    createPurchase,
    loading: isLoadingCreatePurchase || isLoadingCancelPurchase,
  };
};

export default usePurchaseActions;
export type usePurchaseActionsType = ReturnType<typeof usePurchaseActions>;
