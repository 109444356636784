import { FC, useCallback, useEffect, useState } from 'react';
import { Container, InputContainer, MaterialSwitch, Label } from './styles';
import type { Props } from './types';

const CategorySwitch: FC<Props> = ({
  className,
  isChecked = false,
  onSelect,
  label,
  id,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isChecked) {
      setChecked(true);
    }
  }, [isChecked]);

  const handleChange = useCallback(() => {
    setChecked(!checked);
    onSelect(id);
  }, [checked, id, onSelect]);

  return (
    <Container className={className}>
      <InputContainer onClick={handleChange}>
        <Label $checked={checked}>{label}</Label>
        <MaterialSwitch
          id={label}
          name={label}
          checked={checked}
          onChange={handleChange}
        />
      </InputContainer>
    </Container>
  );
};

export default CategorySwitch;
