import type { FC } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import EventModal from '../../Modals/Event';
import useConnect from './connect';
import { CalendarContainer, Container } from './styles';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
  es: require('date-fns'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Calendar: FC = () => {
  const {
    events,
    employeePermissions,
    isLoading,
    handleOpenNewEventModal,
    handleOnSelectEvent,
  } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <Header
          title="Calendario"
          buttonText={
            employeePermissions.allowCreateCalendars ? 'Crear Evento' : ''
          }
          buttonVariant="primary"
          onClickButton={handleOpenNewEventModal}
        />
        <CalendarContainer>
          <BigCalendar
            events={events}
            localizer={localizer}
            onSelectEvent={handleOnSelectEvent}
            style={{ height: 700 }}
            messages={{
              next: 'Siguiente',
              previous: 'Anterior',
              today: 'Hoy',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
              yesterday: 'Ayer',
              tomorrow: 'Mañana',
              event: 'Evento',
              noEventsInRange: 'No hay eventos en este rango.',
            }}
          />
        </CalendarContainer>
      </Container>
      <EventModal />
    </>
  );
};

export default Calendar;
