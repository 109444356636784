import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, IconCircle, Icon, Header } from './styles';

const NewExpense: FC = () => {
  const {
    creationLoading,
    formikRef,
    handleClose,
    handleOpenCategoriesSelection,
    handleOpenProviderSelection,
    handleSubmit,
    initialValues,
    isOpen,
    providers,
  } = useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="expenses" size={24} />
        </IconCircle>
        <Title>Nuevo gasto</Title>
      </Header>
      <Form
        creationLoading={creationLoading}
        formikRef={formikRef}
        initialValues={initialValues}
        onCancel={handleClose}
        onSelectCategories={handleOpenCategoriesSelection}
        onSelectProvider={handleOpenProviderSelection}
        onSubmit={handleSubmit}
        providers={providers}
      />
    </Container>
  );
};

export default NewExpense;
