import { gql } from '@apollo/client';

export const CHART_FRAGMENT = gql`
  fragment ChartData on Chart {
    name
    value
  }
`;

export const BILLING_MONTH_FRAGMENT = gql`
  fragment BillingMonthData on BillingMonth {
    totalMonthCredits
    totalWeekCredits
    chart {
      ...ChartData
    }
  }
  ${CHART_FRAGMENT}
`;

export const PARTNERS_METRIC_FRAGMENT = gql`
  fragment TotalPartnersMetricData on TotalPartners {
    partners
    totalMonthPartners
    chart {
      ...ChartData
    }
  }
  ${CHART_FRAGMENT}
`;

export const PRODUCT_RANKING_FRAGMENT = gql`
  fragment ProductRankingData on ProductRanking {
    lastPosition
    position
    product {
      ... on Product {
        id
        name
        referenceCode
        createdAt
      }
    }
    totalPurchases
    totalSales
  }
`;
