import { useCallback, useMemo } from 'react';
import useCashiers from 'apollo/hooks/cashier/useCashiers';
import useModal from 'apollo/hooks/useModal';
import {
  cashierHeaders,
  getCashierData,
  getCashierFilename,
} from 'utils/csv/cashier';
import type { Cashier } from 'model/Cashier';

const useConnect = () => {
  const { cashiers, getCashiersLoading } = useCashiers();
  const { openCashierDetails } = useModal();

  const handleOpenDetails = useCallback(
    (cashier: Cashier) => {
      openCashierDetails({ cashier });
    },
    [openCashierDetails],
  );

  const csvInfo = useMemo(() => {
    const data = getCashierData(cashiers);
    const filename = getCashierFilename();

    return { headers: cashierHeaders, data, filename };
  }, [cashiers]);

  return {
    csvInfo,
    isLoading: getCashiersLoading,
    cashiers,
    handleOpenDetails,
  };
};

export default useConnect;
