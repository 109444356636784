import type { FC } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import useConnect from './connect';
import {
  Buttons,
  Button,
  CancelButton,
  Container,
  IconCircle,
  Icon,
  Title,
  SignatureContainer,
  SignContent,
  Signs,
  ResetButton,
} from './styles';

const SignDocumentModal: FC = () => {
  const {
    handleClose,
    isOpen,
    handleOnClick,
    signatureRef,
    hostSignatureRef,
    handleResetSignature,
    handleResetHostSignature,
    hasHostSign,
  } = useConnect();
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const width = ((hasHostSign || !fromTabletPortrait) && 300) || 500;
  const height = 200;

  return (
    <Container open={isOpen} onClose={handleClose}>
      <IconCircle>
        <Icon name="uploadFile" />
      </IconCircle>
      <Signs>
        <SignContent>
          <Title>Firmar del socio</Title>
          <SignatureContainer>
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width, height }}
              penColor="black"
              backgroundColor="white"
            />
          </SignatureContainer>
          {hasHostSign && (
            <ResetButton
              $hasHostSign
              variant="light"
              onClick={handleResetSignature}
            >
              Resetear
            </ResetButton>
          )}
        </SignContent>

        {hasHostSign && (
          <SignContent>
            <Title>Firmar del avalador</Title>
            <SignatureContainer>
              <SignatureCanvas
                ref={hostSignatureRef}
                canvasProps={{ width, height }}
                penColor="black"
                backgroundColor="white"
              />
            </SignatureContainer>
            <ResetButton
              $hasHostSign
              variant="light"
              onClick={handleResetHostSignature}
            >
              Resetear
            </ResetButton>
          </SignContent>
        )}
      </Signs>

      <Buttons>
        <CancelButton onClick={handleClose}>Cerrar</CancelButton>
        {!hasHostSign && (
          <ResetButton
            $hasHostSign={false}
            variant="light"
            onClick={handleResetSignature}
          >
            Resetear
          </ResetButton>
        )}

        <Button onClick={handleOnClick}>Guardar</Button>
      </Buttons>
    </Container>
  );
};

export default SignDocumentModal;
