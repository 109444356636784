import type { FC } from 'react';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import { UserRole } from 'apollo/generated/globalTypes';
import {
  ArrowRightIcon,
  Container,
  Icon,
  Label,
  LabelContent,
  Link,
  Main,
  Navigation,
} from './styles';
import useConnect from './connect';

const MoreView: FC = () => {
  const {
    handleLogout,
    organizationName,
    employeePermissions,
    isLoading,
    userRole,
    subscription,
  } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header title="Más" />
      <Main>
        <Navigation>
          <Link to="/organization">
            <Icon name="organization" />
            <LabelContent>
              <Label>{organizationName}</Label>
              <ArrowRightIcon />
            </LabelContent>
          </Link>
          <Link to="/partners">
            <Icon name="users" />
            <LabelContent>
              <Label>Socios</Label>
              <ArrowRightIcon />
            </LabelContent>
          </Link>
          {subscription.allowedEmployees && userRole === UserRole.ADMIN && (
            <Link to="/employees">
              <Icon name="employees" />
              <LabelContent>
                <Label>Empleados</Label>
                <ArrowRightIcon />
              </LabelContent>
            </Link>
          )}
          {subscription.allowedMetrics && employeePermissions.allowShowMetrics && (
            <Link to="/metrics">
              <Icon name="char" />
              <LabelContent>
                <Label>Métricas</Label>
                <ArrowRightIcon />
              </LabelContent>
            </Link>
          )}
          {subscription.allowedExpenses &&
            employeePermissions.allowShowExpenses && (
              <Link to="/expenses">
                <Icon name="expenses" />
                <LabelContent>
                  <Label>Gastos</Label>
                  <ArrowRightIcon />
                </LabelContent>
              </Link>
            )}
          <Link to="/support">
            <Icon name="help" />
            <LabelContent>
              <Label>Soporte</Label>
              <ArrowRightIcon />
            </LabelContent>
          </Link>
          <Link to="/logout" onClick={handleLogout}>
            <Icon name="logout" />
            <LabelContent>
              <Label>Cerrar sesión</Label>
            </LabelContent>
          </Link>
        </Navigation>
      </Main>
    </Container>
  );
};

export default MoreView;
