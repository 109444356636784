import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  ACTIVATE_TEMPORARY_PARTNER,
  CREATE_TEMPORARY_PARTNER,
  REMOVE_TEMPORARY_PARTNER,
  SET_ORGANIZATION_PARTNER_FORM_CONFIG,
} from 'apollo/mutations/organizations';
import type {
  ActivateTemporaryPartnerMutation,
  ActivateTemporaryPartnerMutationVariables,
  SetOrganizationPartnerFormConfigMutation,
  SetOrganizationPartnerFormConfigMutationVariables,
  CreateTemporaryPartnerMutation,
  CreateTemporaryPartnerMutationVariables,
  RemoveTemporaryPartnerMutation,
  RemoveTemporaryPartnerMutationVariables,
  OrganizationPartnerFormDto,
  ActivateTemporaryPartnerDto,
} from 'apollo/graphql.types';
import type { PartnerFormFieldsFromQR } from 'model/Partner';

const usePartnerFormActions = () => {
  const [
    setPartnerFormConfigMutation,
    { loading: isLoadingSetPartnerFormConfig },
  ] = useMutation<
    SetOrganizationPartnerFormConfigMutation,
    SetOrganizationPartnerFormConfigMutationVariables
  >(SET_ORGANIZATION_PARTNER_FORM_CONFIG);
  const [
    createPartnerRequestMutation,
    { loading: isLoadingCreatePartnerRequest },
  ] = useMutation<
    CreateTemporaryPartnerMutation,
    CreateTemporaryPartnerMutationVariables
  >(CREATE_TEMPORARY_PARTNER);
  const [
    activatePartnerRequestMutation,
    { loading: isLoadingActivatePartnerRequest },
  ] = useMutation<
    ActivateTemporaryPartnerMutation,
    ActivateTemporaryPartnerMutationVariables
  >(ACTIVATE_TEMPORARY_PARTNER);
  const [
    removePartnerRequestMutation,
    { loading: isLoadingRemovePartnerRequest },
  ] = useMutation<
    RemoveTemporaryPartnerMutation,
    RemoveTemporaryPartnerMutationVariables
  >(REMOVE_TEMPORARY_PARTNER);

  const normalizeRequestValues = (data: PartnerFormFieldsFromQR) => ({
    address: data?.address,
    birthDate: data?.birthDate || new Date(),
    document: data?.document,
    email: data?.email,
    firstName: data?.firstName,
    gender: data?.gender,
    lastName: data?.lastName,
    phoneNumber: data?.phoneNumber,
    usage: data?.usage,
  });

  const normalizeFormValuesToActivate = (
    values: ActivateTemporaryPartnerDto,
  ) => ({
    address: values.address,
    birthDate: values.birthDate,
    document: values.document?.toLowerCase()?.trim(),
    email: values.email?.toLowerCase()?.trim(),
    firstName: values.firstName?.toLowerCase()?.trim(),
    gender: values.gender,
    hostMemberNum: values.hostMemberNum || '',
    lastName: values.lastName?.toLowerCase()?.trim(),
    maxConsumeMonth: Number(values.maxConsumeMonth),
    memberNum: values.memberNum || '',
    phoneNumber: values.phoneNumber,
    usage: values.usage,
    rfidCode: values.rfidCode || '',
  });

  const normalizeFormValues = (data: OrganizationPartnerFormDto) => ({
    code: data.code.split('/partner/')[1],
    isActive: data.isActive,
  });

  const setPartnerFormConfig = useCallback(
    async (data: OrganizationPartnerFormDto) => {
      const dto = normalizeFormValues(data);
      await setPartnerFormConfigMutation({
        variables: {
          data: dto,
        },
      });
    },
    [setPartnerFormConfigMutation],
  );

  const createPartnerRequest = useCallback(
    async (code: string, data: PartnerFormFieldsFromQR) => {
      const dto = normalizeRequestValues(data);
      const res = await createPartnerRequestMutation({
        variables: {
          data: { formCode: code, ...dto },
        },
      });

      if (res?.data?.createTemporaryPartner) {
        return res.data.createTemporaryPartner;
      }

      return null;
    },
    [createPartnerRequestMutation],
  );

  const activatePartnerRequest = useCallback(
    async (id: string, data: ActivateTemporaryPartnerDto) => {
      const dto = normalizeFormValuesToActivate(data);
      const res = await activatePartnerRequestMutation({
        variables: {
          id,
          data: dto,
        },
      });

      if (res?.data?.activateTemporaryPartner) {
        return res.data.activateTemporaryPartner.id;
      }

      return null;
    },
    [activatePartnerRequestMutation],
  );

  const removePartnerRequest = useCallback(
    async (id: string) => {
      await removePartnerRequestMutation({
        variables: {
          id,
        },
      });
    },
    [removePartnerRequestMutation],
  );

  return {
    activatePartnerRequest,
    createPartnerRequest,
    setPartnerFormConfig,
    removePartnerRequest,
    partnerFormConfigLoading:
      isLoadingSetPartnerFormConfig ||
      isLoadingCreatePartnerRequest ||
      isLoadingRemovePartnerRequest ||
      isLoadingActivatePartnerRequest,
  };
};

export default usePartnerFormActions;
