import { FC, memo } from 'react';

import type { Props } from './types';

const UploadFile: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11 1.5H5C4.175 1.5 3.5075 2.175 3.5075 3L3.5 15C3.5 15.825 4.1675 16.5 4.9925 16.5H14C14.825 16.5 15.5 15.825 15.5 15V6L11 1.5ZM14 15H5V3H10.25V6.75H14V15ZM6.5 11.2575L7.5575 12.315L8.75 11.13V14.25H10.25V11.13L11.4425 12.3225L12.5 11.2575L9.5075 8.25L6.5 11.2575Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(UploadFile);
