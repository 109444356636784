import { FC, memo } from 'react';
import { Container, Name } from './styles';

const TableHead: FC = () => {
  return (
    <Container>
      <Name>Nombre</Name>
    </Container>
  );
};

export default memo(TableHead);
