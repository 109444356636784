import { useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive';
import { GET_ORGANIZATION_TRANSACTIONS } from 'apollo/queries/organizations';
import { normalizeOrganizationTransactions } from 'model/Transaction';
import type {
  GetOrganizationTransactionsQuery,
  GetOrganizationTransactionsQueryVariables,
} from 'apollo/graphql.types';

const useOrganizationTransactions = (filters?: { from?: Date; to?: Date }) => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { loading, data, refetch } = useQuery<
    GetOrganizationTransactionsQuery,
    GetOrganizationTransactionsQueryVariables
  >(GET_ORGANIZATION_TRANSACTIONS, {
    skip: !employeePermissions.allowShowTransactionsHistory,
    variables: {
      filters: filters
        ? {
            dates: {
              fromDate: filters.from || null,
              toDate: filters.to || null,
            },
          }
        : null,
    },
  });

  const organizationTransactions = useMemo(
    () =>
      data?.organizationTransactions
        ? normalizeOrganizationTransactions(data.organizationTransactions)
        : [],
    [data],
  );

  return {
    loading,
    organizationTransactions,
    refetch,
  };
};

export default useOrganizationTransactions;
