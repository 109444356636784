import type { ProviderData } from 'apollo/generated/ProviderData';

export type ProviderFormFields = Readonly<{
  id?: string;
  description: string;
  name: string;
}>;

export const normalizeProvider = (input: ProviderData) => ({
  // ID
  id: input.id || '',

  // FIELDS
  addedBy: {
    id: input.addedBy.id,
    firstName: input.addedBy.user.firstName,
    lastName: input.addedBy.user.lastName,
    fullName: `${input.addedBy.user.firstName} ${input.addedBy.user.lastName}`,
  },
  createdAt: input.createdAt || new Date(),
  description: input.description || '',
  expenses: (input?.expenses ?? []).map((expense) => ({
    id: expense.id,
    name: expense.name,
  })),
  name: input.name || '',
});

export type Provider = ReturnType<typeof normalizeProvider>;
