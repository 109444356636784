import { FC, memo } from 'react';

import type { Props } from './types';

const Search: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <defs>
      <filter id="a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.040262 0 0 0 0 0.151440 0 0 0 0 0.215806 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g filter="url(#a)" fill="none" fillRule="evenodd">
      <path
        d="M10.47 4a6.467 6.467 0 016.47 6.464c0 1.47-.492 2.826-1.32 3.912l4.027 3.945c.364.356.37.94.013 1.305l-.013.013a.962.962 0 01-1.346 0l-4.028-3.944a6.444 6.444 0 01-3.803 1.234A6.467 6.467 0 014 10.464 6.467 6.467 0 0110.47 4zm0 1.847a4.62 4.62 0 00-4.622 4.617 4.62 4.62 0 004.622 4.618 4.62 4.62 0 004.621-4.618 4.62 4.62 0 00-4.621-4.617z"
        fill="#000"
      />
    </g>
  </svg>
);

export default memo(Search);
